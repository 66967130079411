import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const ArchiveIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 23"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-109.000000, -239.000000)"
          fill={iconColor}
        >
          <g transform="translate(91.000000, 151.000000)">
            <g transform="translate(18.000000, 88.000000)">
              <path d="M3,14 L4,14 L4,20 C4,21.1045695 4.8954305,22 6,22 L20,22 C21.1045695,22 22,21.1045695 22,20 L22,3 C22,1.8954305 21.1045695,1 20,1 L6,1 C4.8954305,1 4,1.8954305 4,3 L4,9 L3,9 L3,3 C3,1.34314575 4.34314575,3.04359188e-16 6,0 L20,0 C21.6568542,-3.04359188e-16 23,1.34314575 23,3 L23,20 C23,21.6568542 21.6568542,23 20,23 L6,23 C4.34314575,23 3,21.6568542 3,20 L3,14 Z"></path>
              <path d="M6.62842712,12 L0.5,12 C0.223857625,12 3.38176876e-17,11.7761424 0,11.5 C-3.38176876e-17,11.2238576 0.223857625,11 0.5,11 L6.79289322,11 L5.35355339,9.56066017 C5.15829124,9.36539803 5.15829124,9.04881554 5.35355339,8.85355339 C5.54881554,8.65829124 5.86539803,8.65829124 6.06066017,8.85355339 L8.18198052,10.9748737 C8.30243142,11.0953246 8.34858005,11.2619411 8.32042642,11.417767 C8.34858005,11.5735929 8.30243142,11.7402093 8.18198052,11.8606602 L6.06066017,13.9819805 C5.86539803,14.1772427 5.54881554,14.1772427 5.35355339,13.9819805 C5.15829124,13.7867184 5.15829124,13.4701359 5.35355339,13.2748737 L6.62842712,12 Z"></path>
              <path d="M16,3 L19,3 C19.5522847,3 20,3.44771525 20,4 L20,19 C20,19.5522847 19.5522847,20 19,20 L16,20 C15.4477153,20 15,19.5522847 15,19 L15,4 C15,3.44771525 15.4477153,3 16,3 Z M16,3.85 L16,19.15 L19,19.15 L19,3.85 L16,3.85 Z M17,6 L18,6 L18,7 L17,7 L17,6 Z M17,8 L18,8 L18,15 L17,15 L17,8 Z M17,16 L18,16 L18,17 L17,17 L17,16 Z"></path>
              <path d="M10,3 L13,3 C13.5522847,3 14,3.44771525 14,4 L14,19 C14,19.5522847 13.5522847,20 13,20 L10,20 C9.44771525,20 9,19.5522847 9,19 L9,4 C9,3.44771525 9.44771525,3 10,3 Z M10,3.85 L10,19.15 L13,19.15 L13,3.85 L10,3.85 Z M11,6 L12,6 L12,7 L11,7 L11,6 Z M11,8 L12,8 L12,15 L11,15 L11,8 Z M11,16 L12,16 L12,17 L11,17 L11,16 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
