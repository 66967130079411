import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleParameterProps } from "domains";
import AbTestArticleParameterApi from "javascripts/api/abTest/articles/parameter";

export const abTestArticleParameterListState = atom<ArticleParameterProps[]>({
  key: "abTestArticleParameterListState",
  default: [],
});

export const useAbTestArticleParameterList = (abTestUid: string) => {
  const [abTestArticleParameterList, setAbTestArticleParameterList] = useRecoilState(
    abTestArticleParameterListState
  );

  useEffect(() => {
    AbTestArticleParameterApi.fetchParameters(abTestUid).then((data) => {
      setAbTestArticleParameterList(data.parameters);
    });
  }, []);

  return [abTestArticleParameterList] as const;
};
