export const OriginalPopupOutlineIcon = ({ width, height }: { width: number; height: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1316 5.11963L13.2852 5.34683"
      stroke="currentColor"
      strokeWidth="1.152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1892 2.55322L12.5684 3.17242"
      stroke="currentColor"
      strokeWidth="1.152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6221 1.60938L10.3965 2.45578"
      stroke="currentColor"
      strokeWidth="1.152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.96155 2.51807H3.91835C2.96155 2.51807 2.18555 3.29407 2.18555 4.25087V12.3373C2.18555 13.2941 2.96155 14.0701 3.91835 14.0701H12.0047C12.9615 14.0701 13.7375 13.2941 13.7375 12.3373V7.52447"
      stroke="currentColor"
      strokeWidth="1.152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.0643 14.07H8.7779C9.4195 14.07 9.9411 13.55 9.9411 12.9068V7.47961C9.9411 6.83801 9.4211 6.31641 8.7779 6.31641H3.3507C2.7091 6.31641 2.1875 6.83641 2.1875 7.47961V10.71"
      stroke="currentColor"
      strokeWidth="1.152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
