import clsx from "clsx";
import { LocalMenuItem } from "components/molecules/core/LocalMenuItem";
import resetStyles from "stylesheets/components/twReset.module.scss";
import type { BaseLocalNavigationProps } from "./type";

export const BaseLocalNavigation = ({ items, selectedId }: BaseLocalNavigationProps) => {
  return (
    <>
      <nav
        className={clsx(
          resetStyles.twReset,
          "w-fit border border-y-0 border-l-0 border-solid border-r-gray-light bg-white px-2 py-4",
          "hidden md:flex",
        )}
      >
        <ul className="flex flex-col gap-2">
          {items.map((item) => (
            <li key={item.id}>
              <LocalMenuItem
                {...item}
                isSelected={item.id === selectedId}
              />
            </li>
          ))}
        </ul>
      </nav>
      <nav
        className={clsx(
          resetStyles.twReset,
          "w-full overflow-x-auto border border-x-0 border-t-0 border-solid border-b-gray-light bg-white px-1",
          "md:hidden",
        )}
      >
        <ul className="flex">
          {items.map((item) => (
            <li key={item.id}>
              <LocalMenuItem
                {...item}
                isSelected={item.id === selectedId}
              />
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};
