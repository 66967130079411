/** ファイルの拡張子を取得するメソッド */
export const getFileExtension = (fileName: string) => {
  const extStartIndex = fileName.lastIndexOf(".");
  return extStartIndex > 0 ? fileName.substring(extStartIndex + 1) : null;
};

/** mp4ファイルであることを判定するメソッド */
export const isVideoUrl = (url: string) => {
  const VALID_EXTENSIONS = ["mp4"];
  const fileExtension = getFileExtension(url);
  return !!fileExtension && VALID_EXTENSIONS.indexOf(fileExtension) !== -1;
};

/** あるファイルのオブジェクトURLを生成するメソッド */
export const createObjectUrlWithExtension = (file: File) => {
  const createObjectURL = (window.URL || window.webkitURL).createObjectURL;
  const fileExtension = getFileExtension(file.name);
  const objectUrl = createObjectURL(file);
  return `${objectUrl}.${fileExtension}`;
};
