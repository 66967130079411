import {
  InformationCircleIcon as InformationCircleIconOutline,
  ChartBarIcon as ChartBarIconOutline,
} from "@heroicons/react/24/outline";
import {
  InformationCircleIcon as InformationCircleIconSolid,
  ChartBarIcon as ChartBarIconSolid,
} from "@heroicons/react/24/solid";
import { OriginalPopupOutlineIcon, OriginalVersionOutlineIcon } from "components/atoms/icons";
import { BaseLocalNavigation as LocalNav } from "components/organisms/core/BaseLocalNavigation";
import { BEYOND_PAGE_OR_VERSION_REGEXP, ICON_SIZE, NAV_IDS } from "../consts";
import type { BeyondPageAndVersionNavigationProps } from "./type";

const getCurrentNavId = (pathName: string): keyof typeof NAV_IDS => {
  if (BEYOND_PAGE_OR_VERSION_REGEXP.info.test(pathName)) return NAV_IDS.info;
  if (BEYOND_PAGE_OR_VERSION_REGEXP.popup.test(pathName)) return NAV_IDS.popup;
  if (BEYOND_PAGE_OR_VERSION_REGEXP.report.test(pathName)) return NAV_IDS.report;

  return NAV_IDS.version;
};

export const BeyondPageAndVersionNavigation = ({
  pathName,
  folderUid,
  abTestUid,
  articleUid,
}: BeyondPageAndVersionNavigationProps) => {
  return (
    <LocalNav
      selectedId={getCurrentNavId(pathName)}
      items={[
        {
          id: NAV_IDS.info,
          title: "基本情報",
          href: `/folders/${folderUid}/ab_tests/${abTestUid}/edit`,
          icon: (
            <InformationCircleIconOutline
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <InformationCircleIconSolid
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.version,
          title: "Version",
          href: `/ab_tests/${abTestUid}/articles${articleUid ? `#${articleUid}` : ""}`,
          icon: (
            <OriginalVersionOutlineIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <OriginalVersionOutlineIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.popup,
          title: "ポップ\nアップ",
          href: `/ab_tests/${abTestUid}/articles/exit_popups`,
          icon: (
            <OriginalPopupOutlineIcon
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <OriginalPopupOutlineIcon
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.report,
          title: "レポート",
          href: `/ab_tests/${abTestUid}/reports`,
          icon: (
            <ChartBarIconOutline
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <ChartBarIconSolid
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
      ]}
    />
  );
};
