import { Box } from "@mui/material";
import { useMember } from "hooks";

export const Content = () => {
  // NOTE: AdminOuterFrameのAuth条件分岐後にuseMemberを呼んでisAdminで判定するのが綺麗だが、
  // hooksのルール的に条件分岐後にはuseMemberを呼べないためContent内で判定
  const memberState = useMember();
  const isFrameVisible =
    memberState.memberAttributes.isAdmin || memberState.memberAttributes.isImpersonation;
  if (!isFrameVisible) {
    return null;
  }
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 99999,
          border: "4px solid #d5b53d",
          width: "100%",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          zIndex: 99999,
          border: "4px solid #d5b53d",
          width: "100%",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          right: 0,
          zIndex: 99999,
          border: "4px solid #d5b53d",
          height: "100%",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          left: 0,
          zIndex: 99999,
          border: "4px solid #d5b53d",
          height: "100%",
        }}
      />
    </>
  );
};
