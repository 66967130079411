import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import axios from "axios";
import { APP } from "const/app";
import { parseBoolean, parseNumber, parseString } from "utils";

export type PlanTypeProps = {
  id: number;
  maximum_user_count: number | null;
  monthly_price_single_month: number | null;
  monthly_price_whole_year: number | null;
  name: string;
  device_split_setting: boolean;
  restore_function: boolean;
};

export type PlanTypeListResponse = PlanTypeProps[];

const planTypeListState = atom<PlanTypeListResponse>({
  key: "PlanTypeListState",
  default: [],
});

const planTypeFetchApi = async (): Promise<PlanTypeListResponse | unknown> => {
  const apiUrl = `${APP.BACKEND_URL}/api/v1/squadbeyond_plans`;
  try {
    const response = await axios.get<PlanTypeListResponse>(apiUrl, { withCredentials: true });
    return response.data;
  } catch (error: unknown) {
    return error;
  }
};

const toNewEntry = (object: unknown): PlanTypeListResponse => {
  if (Array.isArray(object) && object.length > 0) {
    return object.map((item: PlanTypeProps) => {
      return {
        id: parseNumber(item.id),
        maximum_user_count: item.maximum_user_count ? parseNumber(item.maximum_user_count) : null,
        monthly_price_single_month: item.monthly_price_single_month
          ? parseNumber(item.monthly_price_single_month)
          : null,
        monthly_price_whole_year: item.monthly_price_whole_year
          ? parseNumber(item.monthly_price_whole_year)
          : null,
        name: parseString(item.name),
        device_split_setting: parseBoolean(item.device_split_setting),
        restore_function: parseBoolean(item.restore_function),
      };
    });
  } else {
    throw new Error("Invalid response: squadbeyond_plans");
  }
};

export const useFetchPlanTypes = () => {
  const [planTypeList, setPlanTypeList] = useRecoilState(planTypeListState);

  useEffect(() => {
    planTypeFetchApi()
      .then((data) => setPlanTypeList(toNewEntry(data)))
      .catch((e: unknown) => {
        throw new Error(e as string);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [planTypeList] as const;
};
