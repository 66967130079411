import axios from "axios";
import { AbTestDailyReportProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FolderAbTestDailyReportResponse = {
  reports: AbTestDailyReportProps[];
};

class FolderAbTestDailyReportApi extends ApiBase {
  static fetchReports(folderUid: string, params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FolderAbTestDailyReportResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/folders/${folderUid}/ab_tests/daily_reports`, {
          params,
          withCredentials: true,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }
}

export default FolderAbTestDailyReportApi;
