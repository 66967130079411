import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

const ImobileSetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>{t("アイモバイル")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("MCVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるとというパラメーターをもってbeyondページにアクセスした人が計測機能付きリンクをクリックすると、MCVをポストバックします。",
          { value1: "sid", value2: "rid" }
        )}
      </Typography>
      <Typography sx={{ fontSize: "14px", mt: 1 }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるとというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。",
          { value1: "sid", value2: "rid" }
        )}
      </Typography>
    </Box>
  );
};

const LoglySetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>logly</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("MCVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるとというパラメーターをもってbeyondページにアクセスした人が計測機能付きリンクをクリックすると、MCVをポストバックします。",
          { value1: "audience_id", value2: "adv_id" }
        )}
      </Typography>
      <Typography sx={{ fontSize: "14px", mt: 1 }}>CVポストバック条件</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるとというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。",
          { value1: "audience_id", value2: "adv_id" }
        )}
      </Typography>
    </Box>
  );
};

const UzouSetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>UZOU</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("MCVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるとというパラメーターをもってbeyondページにアクセスした人が計測機能付きリンクをクリックすると、MCVをポストバックします。",
          { value1: "uzcid", value2: "beyond_uzmcvid" }
        )}
      </Typography>
      <Typography sx={{ fontSize: "14px", mt: 1 }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "またuzcidとbeyond_uzcvidというパラメーターをもってbeyondページにアクセスした人がCVするとCVをポストバックします。"
        )}
      </Typography>
    </Box>
  );
};

const PoetsSetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>poets</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("MCVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるというパラメーターをもってbeyondページにアクセスした人が計測機能付きリンクをクリックすると、MCVをポストバックします。",
          { value: "sid" }
        )}
      </Typography>
      <Typography sx={{ fontSize: "14px", mt: 1 }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。",
          { value: "sid" }
        )}
      </Typography>
    </Box>
  );
};

const Fam8Setting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>fam∞</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("MCVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるというパラメーターをもってbeyondページにアクセスした人が計測機能付きリンクをクリックすると、MCVをポストバックします。",
          { value: "_xuid" }
        )}
      </Typography>
      <Typography sx={{ fontSize: "14px", mt: 1 }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。",
          { value: "_xuid" }
        )}
      </Typography>
    </Box>
  );
};

const MaioSetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>maio</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("MCVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるとというパラメーターをもってbeyondページにアクセスした人が計測機能付きリンクをクリックすると、MCVをポストバックします。",
          { value1: "sid", value2: "rid" }
        )}
      </Typography>
      <Typography sx={{ fontSize: "14px", mt: 1 }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるとというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。",
          { value1: "sid", value2: "rid" }
        )}
      </Typography>
    </Box>
  );
};

const AkaneSetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>AkaNe</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。",
          { value: "akncv" }
        )}
      </Typography>
    </Box>
  );
};

const ReemoSetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>ReeMo</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。",
          { value: "pbi" }
        )}
      </Typography>
    </Box>
  );
};
const QufooitSetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>Qufooit</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "clickidというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。"
        )}
      </Typography>
    </Box>
  );
};
const GunosySetting: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography sx={{ fontSize: "14px", mb: 1 }}>Gunosy Ads</Typography>
      <Typography sx={{ fontSize: "14px" }}>{t("CVポストバック条件")}</Typography>
      <Typography sx={{ fontSize: "14px" }}>
        {t(
          "広告をクリックすると付与されるとというパラメーターをもってbeyondページにアクセスした人がCVすると、CVをポストバックします。",
          { value1: "cid", value2: "sad" }
        )}
      </Typography>
    </Box>
  );
};

export const BeyondPageMediaPostbackDescription: React.FC<{
  mediaId: string | number | undefined;
}> = ({ mediaId }) => {
  switch (mediaId) {
    case 21:
      return <ImobileSetting />;
    case 19:
      return <LoglySetting />;
    case 25:
      return <UzouSetting />;
    case 36:
      return <PoetsSetting />;
    case 51:
      return <Fam8Setting />;
    case 53:
      return <MaioSetting />;
    case 9:
      return <AkaneSetting />;
    case 48:
      return <ReemoSetting />;
    case 47:
      return <QufooitSetting />;
    case 4:
      return <GunosySetting />;
    default:
      return null;
  }
};
