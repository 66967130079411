import axios from "axios";
import { FolderGroupingProps } from "domains";
import ApiBase from "./apiBase";

type FetchFolderGroupingResponse = FolderGroupingProps[];

class FolderGroupingApi extends ApiBase {
  static fetchList() {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderGroupingResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/folder_groupings`, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }
}

export default FolderGroupingApi;
