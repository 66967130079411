export const searchParentElementByClassName = (currentNode: HTMLElement, className: string) => {
  let targetNode: HTMLElement | null = null;
  let parentElementHolder: HTMLElement | null = currentNode.parentElement;

  while (parentElementHolder) {
    if (parentElementHolder.classList.contains(className)) {
      targetNode = parentElementHolder;
      break;
    }
    parentElementHolder = parentElementHolder.parentElement;
  }
  return targetNode;
};

export const searchParentNodeByClassName = (currentNode: HTMLElement, targetClass: string) => {
  let targetNode: HTMLElement | null = null;
  let parentNodeHolder: ParentNode | null = currentNode.parentNode;

  while (parentNodeHolder) {
    if ((parentNodeHolder as HTMLElement).classList.contains(targetClass)) {
      targetNode = parentNodeHolder as HTMLElement;
      break;
    }
    parentNodeHolder = parentNodeHolder.parentNode;
  }

  return targetNode;
};
