import React from "react";

type Props = {
  width: string;
  height: string;
};

export const AdwordsIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 42"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-199.000000, -699.000000)">
          <g transform="translate(200.000000, 700.000000)">
            <rect
              fill="#FF0000"
              opacity="0"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
            <line
              x1="20"
              y1="6"
              x2="6"
              y2="34"
              stroke="#FDBD02"
              strokeWidth="12"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="20"
              y1="6"
              x2="34"
              y2="34"
              stroke="#397DEA"
              strokeWidth="12"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <circle
              fill="#31A851"
              cx="6"
              cy="34"
              r="6"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};
