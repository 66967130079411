import { useTranslation } from "react-i18next";
import { Storage } from "aws-amplify";
import { useAlert } from "contexts/alert";
import { useMember } from "hooks";
import ArticlePhotoApi from "javascripts/api/articles/photo";
import { createUniqueString, compressImages } from "javascripts/utils";

import JSZip from "jszip";

import btnStyles from "stylesheets/components/partials/btn.module.scss";

import { getFileExtension } from "utils";
import { ZippedImageUploadButtonProps } from "./type";

export const ZippedImageUploadButton = ({ article }: ZippedImageUploadButtonProps) => {
  const { t } = useTranslation();

  const memberState = useMember();
  const alertState = useAlert();

  const validateFileSize = (extension: string | null, fileSize: number) => {
    let enableUpload = true;
    switch (extension) {
      case "mp4":
        if (fileSize > 2000000) enableUpload = false;
        break;
      case "gif":
        if (fileSize > 5000000) enableUpload = false;
        break;
      default:
        if (fileSize > 5000000) enableUpload = false;
    }
    return enableUpload;
  };

  const uploadZipFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const zip = new JSZip();
    compressImages(e.target.files)
      .then((data) => {
        const fileNames: string[] = [];
        const validateFileNames: string[] = [];
        for (const file of data) {
          const enableUpload = validateFileSize(getFileExtension(file.name) ?? null, file.size);
          if (enableUpload) {
            zip.file(file.name, file, { base64: true });
            fileNames.push(file.name);
          } else {
            validateFileNames.push(file.name);
          }
        }
        if (Object.keys(zip.files).length === 0) {
          alertState.showAlerts({
            messages: [
              t(
                "アップロード可能なファイルがありませんでした。ファイルサイズを確認してください。(JPEG/PNG上限: 圧縮後1MB、GIF上限: 5MB, MP4上限: 圧縮後1MB)",
              ),
            ],
            theme: "danger",
          });
          return;
        }
        zip
          .generateAsync({
            type: "blob",
            compression: "DEFLATE",
          })
          .then((body) => {
            const zipName = `upload-images-${createUniqueString()}.zip`;
            const fileName = zipName;
            const albumPhotosKey = encodeURIComponent("upload_zip") + "/";
            const photoKey = albumPhotosKey + fileName;
            const upload = Storage.put(photoKey, body, {
              contentType: "application/zip",
              customPrefix: {
                public: "",
              },
            });
            upload.then(
              () => {
                const params = {
                  photo: {
                    key: photoKey,
                    team_member_uid: memberState.memberAttributes.member?.uid,
                    file_names: fileNames,
                  },
                };
                alertState.showAlerts({
                  messages: [t("画像アップロードの準備をしています。少々お待ちください。")],
                  theme: "info",
                });
                ArticlePhotoApi.sendFileKeyToSquadbeyondFile(article.uid, params)
                  .then(() => {
                    const alertMessage = [
                      t(
                        "画像のアップロードを開始しました。完了通知メールが届くまでお待ちください。",
                      ),
                    ];
                    if (validateFileNames.length > 0) {
                      alertMessage.push(
                        `<div style="">${t(
                          "※アップロードができない画像が含まれていました。",
                        )}。<div>`,
                      );
                    }
                    alertState.showAlerts({ messages: alertMessage, theme: "info" });
                  })
                  .catch(() => {
                    alertState.showAlerts({
                      messages: [t("エラーが発生しました。")],
                      theme: "danger",
                    });
                  });
              },
              () => {
                alertState.showAlerts({
                  messages: [t("に失敗しました。", { value: t("ファイルのアップロード") })],
                  theme: "danger",
                });
              },
            );
          })
          .catch(() => {
            alertState.showAlerts({
              messages: [t("に失敗しました。", { value: t("アップロードファイルの生成") })],
              theme: "danger",
            });
          });
      })
      .catch(() => {
        alertState.showAlerts({ messages: [t("エラーが発生しました。")], theme: "danger" });
      });
  };

  return (
    <>
      <form
        className="hide"
        data-is-hide={true}
      >
        <input
          name="utf8"
          type="hidden"
          value="✓"
        />
        <input
          type="file"
          name="photo"
          multiple
          accept="image/*,video/mp4"
          id={"upload-zip"}
          onChange={(e) => uploadZipFile(e)}
        />
      </form>
      <div
        className={`${btnStyles.btn} ${btnStyles.btnDarkThemePrimary}`}
        onClick={() => document.getElementById("upload-zip")?.click()}
      >
        {t("まとめてアップロードする画像を選択")}
      </div>
    </>
  );
};
