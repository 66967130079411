import i18next from "i18next";

export const FORM_CODE_INPUT = {
  name: "code",
  label: i18next.t("確認コード"),
  errorMessage: i18next.t("を入力してください", { value: i18next.t("確認コード") }),
  required: true,
  sx: { mb: 2 },
  fullWidth: true,
};

export const FORM_EMAIL_INPUT = {
  name: "email",
  type: "email",
  label: i18next.t("登録済みメールアドレス"),
  errorMessage: i18next.t("を入力してください", { value: i18next.t("メールアドレス") }),
  required: true,
  sx: { mb: 2 },
  fullWidth: true,
};

export const FORM_PASSWORD_INPUT = {
  name: "password",
  label: i18next.t("新しいパスワード"),
  errorMessage: i18next.t("を入力してください", { value: i18next.t("パスワード") }),
  required: true,
  sx: { mb: 2 },
  fullWidth: true,
  pattern: {
    value:
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
    message: "パスワードの設定条件を満たしていません",
  },
};
