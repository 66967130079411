import React, { useEffect, useRef } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { Theme } from "components";
import { ArticleHtmlPartProps } from "domains";
import {
  useCreateArticleHtmlPartComment,
  useDeleteArticleHtmlPartComment,
  useUpdateArticleHtmlPartComment,
} from "hooks";
import { removeItemAtIndex, replaceItemAtIndex } from "utils";

type ArticleHtmlPartCommentFormValues = {
  base?: string;
  body: string;
};

export const ArticleHtmlPartCommentPopper: React.FC<{
  anchorEl: null | HTMLElement;
  setAnchorEl: (ele: null | HTMLElement) => void;
  comment?: { id: number; body: string; line: number };
  selectedPart: ArticleHtmlPartProps;
  partId: number;
  index: number | null;
  sourceType: string;
  action: string;
  theme: string;
  popperPosition: { x: number; y: number };
  setPopperPosition: (position: { x: number; y: number }) => void;
  setSelectedPart: (part: ArticleHtmlPartProps) => void;
}> = ({
  anchorEl,
  setAnchorEl,
  comment,
  selectedPart,
  partId,
  index,
  sourceType,
  action,
  theme,
  popperPosition,
  setPopperPosition,
  setSelectedPart,
}) => {
  const { t } = useTranslation();
  const [handleCreateArticleHtmlPartComment] = useCreateArticleHtmlPartComment();
  const [handleUpdateArticleHtmlPartComment, handleUpdateLoading] =
    useUpdateArticleHtmlPartComment();
  const [handleDeleteArticleHtmlPartComment, handleDeleteLoading] =
    useDeleteArticleHtmlPartComment();

  const commentRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;

  const { handleSubmit, register, errors, setValue } = useForm<ArticleHtmlPartCommentFormValues>();

  const open = Boolean(anchorEl);

  useEffect(() => {
    register("body", {
      required: t("を入力してください", { value: t("コメント") }) || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const handleClickAway = () => {
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setValue("body", "");
  };

  const onDrag = (e: DraggableEvent, data: DraggableData) => {
    setPopperPosition({ x: data.lastX, y: data.lastY });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    return handleSubmit(async (data: ArticleHtmlPartCommentFormValues) => {
      handleClose();
      if (comment) {
        const response = await handleUpdateArticleHtmlPartComment(partId, comment.id, {
          article_html_part_comment: {
            body: data.body,
            source_type: sourceType,
            line: index,
          },
        });
        const targetIndex = selectedPart.comments.findIndex((e) => e.id === comment.id);
        setSelectedPart({
          ...selectedPart,
          comments: replaceItemAtIndex(selectedPart.comments, targetIndex, response),
        });
      } else {
        const response = await handleCreateArticleHtmlPartComment(partId, {
          article_html_part_comment: {
            body: data.body,
            source_type: sourceType,
            line: index,
          },
        });
        setSelectedPart({
          ...selectedPart,
          comments: [...selectedPart.comments, response],
        });
      }
    })(event);
  };

  const onDeleteComment = async () => {
    if (!comment) return;
    handleClose();
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await handleDeleteArticleHtmlPartComment(partId, comment.id);
    const targetIndex = selectedPart.comments.findIndex((e) => e.id === comment.id);
    setSelectedPart({
      ...selectedPart,
      comments: removeItemAtIndex(selectedPart.comments, targetIndex),
    });
  };

  return (
    <Theme mode={theme}>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="left"
        style={{ zIndex: 100000 }}
      >
        <Draggable
          nodeRef={commentRef}
          handle={`#code-comment-${index}-handle`}
          onDrag={onDrag}
          position={popperPosition}
        >
          <Paper ref={commentRef}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box
                component="form"
                onSubmit={onSubmit}
                sx={{
                  borderRadius: 2,
                  p: 1,
                  backgroundColor: "background.secondary",
                  width: "300px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Box
                    id={`code-comment-${index}-handle`}
                    sx={{
                      width: "30px",
                      height: "5px",
                      backgroundColor: "grey.500",
                      "&:hover": { cursor: "move" },
                    }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "background.primary",
                      height: "30px",
                      width: "30px",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "primary.main",
                        height: "15px",
                        width: "15px",
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={handleClose}
                    >
                      <IconButton>
                        <ArrowForwardIcon
                          sx={{
                            fontSize: "15px",
                            color: "background.secondary",
                            transform: "rotate(135deg)",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <Typography variant="body2">{t("コメント")}</Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        py: 0.3,
                        px: 0.5,
                        border: "1px solid",
                        textAlign: "center",
                        fontSize: "10px",
                      }}
                    >
                      {sourceType.toUpperCase()}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {action === "create" && (
                      <>
                        {comment ? (
                          <LoadingButton
                            type="submit"
                            loading={handleUpdateLoading}
                            size="small"
                            variant="outlined"
                            sx={{ mr: 1 }}
                            color="primary"
                          >
                            {t("更新")}
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            type="submit"
                            loading={handleDeleteLoading}
                            size="small"
                            variant="outlined"
                            sx={{ mr: 1 }}
                            color="primary"
                          >
                            {t("作成")}
                          </LoadingButton>
                        )}
                      </>
                    )}
                    {comment && action === "create" && (
                      <Box
                        sx={{
                          backgroundColor: "background.primary",
                          height: "30px",
                          width: "30px",
                          borderRadius: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton onClick={onDeleteComment}>
                          <DeleteIcon
                            sx={{ fontSize: "20px" }}
                            color="error"
                          />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    backgroundColor: "background.primary",
                  }}
                >
                  {action === "insert" ? (
                    <Typography
                      variant="body2"
                      sx={{
                        maxHeight: "500px",
                        overflow: "scroll",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {comment ? comment.body : ""}
                    </Typography>
                  ) : (
                    <TextField
                      type="text"
                      name="body"
                      placeholder=""
                      multiline
                      defaultValue={comment ? comment.body : ""}
                      label={`${t("へのコメント", { value: t("行目", { value: index }) })}`}
                      variant="standard"
                      fullWidth
                      maxRows={10}
                      error={!!errors.body}
                      helperText={errors.body?.message}
                      onChange={(e) => setValue("body", e.target.value)}
                    />
                  )}
                </Box>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Draggable>
      </Popper>
    </Theme>
  );
};
