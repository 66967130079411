import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { JobProps } from "domains";
import { useLogging } from "hooks/logging";
import JobApi from "javascripts/api/job";

const mediaListState = atom<JobProps[]>({
  key: "jobListState",
  default: [],
});

export const useJobList = () => {
  const { sendErrorLog } = useLogging();
  const [mediaList, setJobList] = useRecoilState(mediaListState);

  useEffect(() => {
    JobApi.fetchList()
      .then((data) => {
        setJobList(data);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch job",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [mediaList] as const;
};
