import { useTranslation } from "react-i18next";
import { Box, Button, Link, Typography } from "@mui/material";
import { Theme } from "components/Theme";
import { SplitTestSettingInfoForNonBoostSettingProps } from "./type";

export const SplitTestSettingInfoForNonBoostSetting = ({
  abTestUid,
  folderUid,
}: SplitTestSettingInfoForNonBoostSettingProps) => {
  const { t } = useTranslation();
  return (
    <Theme mode="light">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "12px",
          ml: 2,
          mb: 2.4,
          mr: 2,
        }}
      >
        <Typography
          sx={{
            color: "primary.contrastText",
            fontSize: "14px",
            borderRadius: "10px",
            my: 0.5,
            textAlign: "left",
          }}
        >
          {t(
            "Version基本情報から「LP高速表示モード(β)」を有効にすると、出し分け機能が利用できます。",
          )}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Button
            href={`/folders/${folderUid}/ab_tests/${abTestUid}/edit`}
            target="_blank"
            variant="contained"
            disableFocusRipple
            disableRipple
            disabled={!folderUid || !abTestUid}
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
              borderRadius: "0.25rem",
              height: "36px",
              minWidth: "162px",
              whiteSpace: "nowrap",
              fontSize: "14px",
              bolder: "1px solid transparent",
              p: "6px 16px",
              fontWeight: "normal",
              ":hover": {
                bgcolor: "primary.main",
              },
            }}
          >
            {t("基本情報へ移動する")}
          </Button>
          <Link
            href="https://knowledge.squadbeyond.com/high-speed-page-display2"
            target="_blank"
            sx={{
              color: "#2196F3",
              fontSize: "14px",
              fontWeight: "500",
              textDecoration: "none",
              whiteSpace: "nowrap",
              my: 0.5,
              letterSpacing: "0.4px",
            }}
          >
            {t("設定方法を確認")}
          </Link>
        </Box>
      </Box>
    </Theme>
  );
};
