export const downloadCsv = (content, fileName) => {
  const bom  = new Uint8Array([0xEF, 0xBB, 0xBF]);
  const blob = new Blob([bom, content]);
  if (window.navigator.msSaveOrOpenBlob) { // NOTE: For IE
    window.navigator.msSaveBlob(blob, fileName);
  } else {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
};
