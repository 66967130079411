import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import { Typography, Dialog, DialogTitle, DialogContent, Box, TextField } from "@mui/material";
import { TextButton } from "components/atoms";
import { useUpdateFolderGroup } from "hooks";
import type { FolderGroupFormProps, FolderGroupFormValues } from "./type";

export const FolderGroupForm = ({ folderGroup }: FolderGroupFormProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [handleUpdateFolderGroup, loading] = useUpdateFolderGroup();

  const { register, handleSubmit, errors, setValue } = useForm<FolderGroupFormValues>({
    defaultValues: {
      name: folderGroup.name,
    },
  });

  const onSubmit = async (data: FolderGroupFormValues) => {
    const params = {
      folder_group: {
        name: data.name,
      },
    };
    await handleUpdateFolderGroup(folderGroup.id, params);
    handleClose();
  };

  useEffect(() => {
    register("name", {
      required: t("を入力してください", { value: t("グループ名") }) || "",
      maxLength: {
        value: 50,
        message: t("文字まで入力できます", { value: 50 }) || "",
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return (
    <>
      <TextButton
        onClick={handleOpen}
        align="left"
        startIcon={<EditIcon sx={{ width: "20px" }} />}
      >
        <Typography>{t("を変更する", { value: t("グループ名") })}</Typography>
      </TextButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{t("グループ名変更")}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ py: 1 }}
          >
            <Box sx={{ mb: 1 }}>
              <TextField
                type="text"
                label={t("グループ名")}
                placeholder=""
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                defaultValue={folderGroup.name}
                onChange={(e) => setValue("name", e.target.value)}
                fullWidth
                required
              />
            </Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              {t("更新する")}
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
