import { useCallback, useState } from "react";
import { ArticleHtmlPartCommentProps } from "domains";
import ArticleHtmlPartCommentApi from "javascripts/api/articles/htmlParts/comment";

export type HandleCreateArticleHtmlPartCommentProps = (
  partId: number,
  params: {}
) => Promise<ArticleHtmlPartCommentProps>;
export const useCreateArticleHtmlPartComment = () => {
  const [loading, setLoading] = useState(false);
  const handle: HandleCreateArticleHtmlPartCommentProps = useCallback(async (partId, params) => {
    setLoading(true);
    try {
      const response = await ArticleHtmlPartCommentApi.create(partId, params);
      return response;
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  return [handle, loading] as const;
};

export type HandleUpdateArticleHtmlPartCommentProps = (
  partId: number,
  commentId: number,
  params: {}
) => Promise<ArticleHtmlPartCommentProps>;
export const useUpdateArticleHtmlPartComment = () => {
  const [loading, setLoading] = useState(false);
  const handle: HandleUpdateArticleHtmlPartCommentProps = useCallback(
    async (partId, commentId, params) => {
      setLoading(true);
      try {
        const response = await ArticleHtmlPartCommentApi.update(partId, commentId, params);
        return response;
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return [handle, loading] as const;
};

export type HandleDeleteArticleHtmlPartCommentProps = (partId: number, commentId: number) => void;
export const useDeleteArticleHtmlPartComment = () => {
  const [loading, setLoading] = useState(false);
  const handle: HandleDeleteArticleHtmlPartCommentProps = useCallback(async (partId, commentId) => {
    setLoading(true);
    try {
      const response = await ArticleHtmlPartCommentApi.delete(partId, commentId);
      return response;
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, []);

  return [handle, loading] as const;
};
