export const isValidUrl = (str: string) => {
  if (str.includes("kurashiru-webview")) return true;
  if (str.includes("tel:")) return true;

  const pattern = new RegExp(
    "^(http://www.|https://www.|http://|https://)[a-z0-9]+(([-]{1,2}|[.]{1})[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$",
  );

  return !!pattern.test(str);
};
