import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { GoogleAccountApi } from "api/teams/adAccounts/googleAccounts";
import { GoogleAccountApiCreateAccountRequest } from "api/teams/adAccounts/googleAccounts/type";
import { GoogleAccountProps } from "domains";

export const googleAccountListState = atom<GoogleAccountProps[]>({
  key: "googleAccountListState",
  default: [],
});

export const useFetchGoogleAccountList = () => {
  const [list, setList] = useRecoilState(googleAccountListState);

  useEffect(() => {
    GoogleAccountApi.fetchAccounts().then((data) => {
      setList(data.google_accounts || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};

export const useCreateGoogleAccount = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async ({ code, state }: Partial<GoogleAccountApiCreateAccountRequest>) => {
      if (loading) {
        return;
      }
      if (!code || !state) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        await GoogleAccountApi.createAccount({ code, state });
        setLoading(false);
        window.location.href = "/teams/ad_accounts";
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [handle, error] as const;
};

export const useDeleteGoogleAccount = () => {
  const [list, setList] = useRecoilState(googleAccountListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [_error, setError] = useState<any>();

  const handle = useCallback(
    async (id: number) => {
      if (loading) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        await GoogleAccountApi.destroyAccount({ accountId: id });
        const newList = list.filter((d: GoogleAccountProps) => d.id !== id);
        setList(newList);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list]
  );

  return [handle] as const;
};
