import { useTranslation } from "react-i18next";
import NameSettingForm from "components/organisms/folder/form/name-setting-form";
import { prefectures } from "const/selectList";

import formExternalPostSettingStyles from "stylesheets/components/forms/formExternalPostSetting.module.scss";
import { AddressBlock } from "./type";

import "stylesheets/components/forms/blocks/address.css";

const prefectureOptions = prefectures.map((prefecture, index) => (
  <option
    key={index}
    value={prefecture}
  >
    {prefecture}
  </option>
));

const Address = ({
  blockOptions,
  labelStyles,
  badgeStyles,
  noteStyles,
  isNameSetting = false,
  parameters = undefined,
  updateSettingParameter = undefined,
}: AddressBlock) => {
  const { t } = useTranslation();

  return (
    <div className={"addressBlock"}>
      <div className={"addressBlock__labelWrapper"}>
        <label style={labelStyles}>{blockOptions.label}</label>
        {blockOptions.showBadge && (
          <span
            style={badgeStyles}
            className={
              blockOptions.isRequired ? "addressBlock__badgeRequired" : "addressBlock__badge"
            }
          >
            {blockOptions.isRequired ? t("必須") : t("任意")}
          </span>
        )}
      </div>
      <div className={"addressBlock__wrapper"}>
        <div>
          {isNameSetting ? (
            <div className={formExternalPostSettingStyles.nameSettingFormWrapper}>
              <label
                style={labelStyles}
                className={"addressBlock__heading"}
              >
                {t("郵便番号")}
              </label>
              <NameSettingForm
                name="postal_code"
                parameters={parameters}
                updateSettingParameter={updateSettingParameter}
              />
            </div>
          ) : (
            <label
              style={labelStyles}
              className={"addressBlock__heading"}
            >
              {t("郵便番号")}
            </label>
          )}
          <input
            type="text"
            name="folder_form[postal_code]"
            className={"addressBlock__input"}
            required={blockOptions.isRequired || false}
            placeholder={`${t("を入力", { value: t("郵便番号") })}...`}
          />
        </div>
        <div>
          {isNameSetting ? (
            <div className={formExternalPostSettingStyles.nameSettingFormWrapper}>
              <label
                style={labelStyles}
                className={"addressBlock__heading"}
              >
                {t("都道府県")}
              </label>
              <NameSettingForm
                name="address_prefecture"
                parameters={parameters}
                updateSettingParameter={updateSettingParameter}
              />
            </div>
          ) : (
            <label
              style={labelStyles}
              className={"addressBlock__heading"}
            >
              {t("都道府県")}
            </label>
          )}
          <select
            name="folder_form[address_prefecture]"
            className={"addressBlock__selectbox"}
            defaultValue=""
            required={blockOptions.isRequired || false}
          >
            <option
              disabled
              value=""
            >
              {t("選択してください")}
            </option>
            {prefectureOptions}
          </select>
        </div>
      </div>
      <div>
        {isNameSetting ? (
          <div className={formExternalPostSettingStyles.nameSettingFormWrapper}>
            <label
              style={labelStyles}
              className={"addressBlock__heading"}
            >
              {t("市区町村")}
            </label>
            <NameSettingForm
              name="city"
              parameters={parameters}
              updateSettingParameter={updateSettingParameter}
            />
          </div>
        ) : (
          <label
            style={labelStyles}
            className={"addressBlock__heading"}
          >
            {t("市区町村")}
          </label>
        )}
        <input
          type="text"
          name="folder_form[city]"
          className={"addressBlock__input"}
          required={blockOptions.isRequired || false}
          placeholder={`${t("を入力", { value: t("市区町村") })}...`}
        />
      </div>
      <div>
        {isNameSetting ? (
          <div className={formExternalPostSettingStyles.nameSettingFormWrapper}>
            <label
              style={labelStyles}
              className={"addressBlock__heading"}
            >
              {t("番地")}
            </label>
            <NameSettingForm
              name="address"
              parameters={parameters}
              updateSettingParameter={updateSettingParameter}
            />
          </div>
        ) : (
          <label
            style={labelStyles}
            className={"addressBlock__heading"}
          >
            {t("番地")}
          </label>
        )}
        <input
          type="text"
          name="folder_form[address]"
          className={"addressBlock__input"}
          required={blockOptions.isRequired || false}
          placeholder={`${t("を入力", { value: t("番地") })}...`}
        />
      </div>
      <div>
        {isNameSetting ? (
          <div className={formExternalPostSettingStyles.nameSettingFormWrapper}>
            <label
              style={labelStyles}
              className={"addressBlock__heading"}
            >
              {t("建物名・部屋番号")}
            </label>
            <NameSettingForm
              name="building"
              parameters={parameters}
              updateSettingParameter={updateSettingParameter}
            />
          </div>
        ) : (
          <label
            style={labelStyles}
            className={"addressBlock__heading"}
          >
            {t("建物名・部屋番号")}
          </label>
        )}
        <input
          type="text"
          name="folder_form[building]"
          className={"addressBlock__input"}
          placeholder={`${t("を入力", { value: t("建物名・部屋番号") })}...`}
        />
      </div>
      {blockOptions.note && (
        <div
          style={noteStyles}
          className={"addressBlock__note"}
        >
          <div dangerouslySetInnerHTML={{ __html: blockOptions.note }} />
        </div>
      )}
    </div>
  );
};

export default Address;
