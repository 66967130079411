import Compressor from 'compressorjs';

const compressImage = (file, editorVersion=null) => {
  //透過画像の場合jpegにすると白背景になってしまうのでhtml editorのみデフォルトの値をセットする
  let convertSize = editorVersion === 3 ? 5000000 : 1000000;
  let quality = file.size > 200000 ? 0.8 : 1;

  //instaceof Blobがfalseになる場合（ql-editorへのdrag and drop）があるのでその場合はcompressが通らなくなる為blobとして作り直して渡す
  if (!(file instanceof Blob)) {
    return new Promise((resolve, reject) => {
      file.arrayBuffer().then(buffer =>{
        let blob = new Blob([buffer], { type : file.type, name: file.name });
        new Compressor(blob, {
          quality: quality,
          maxWidth: 1600,
          convertSize: convertSize, // 1MBを超えるpngはjpegに変換する
          success(result) {
            resolve(result);
          },
          error(error) {
            reject(error.message);
          },
        });
      });
    });
  } else {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: quality,
        maxWidth: 1600,
        convertSize: convertSize, // 1MBを超えるpngはjpegに変換する
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error.message);
        },
      });
    });
  }
};

export async function compressImages(files, editorVersion=2) {
  const fileTypeRegex = new RegExp('^image\/(jpeg|png|webp|avif)$');
  const nonTargetFiles = [];
  const targetFiles = [];
  Object.keys(files).forEach((key) => {
    const file = files[key];
    if (file.type.match(fileTypeRegex) !== null) {
      targetFiles.push(file);
    } else {
      nonTargetFiles.push(file);
    }
  });
  const compressedFiles = [];
  for (let file of targetFiles) {
    await compressImage(file, editorVersion)
      .then(blob => {
        compressedFiles.push(new File([blob], file.name));
      });
  }
  return compressedFiles.concat(nonTargetFiles);
}
