import { TypeBackground, useTheme } from "@mui/material/styles";
import { BackgroundColorProps, backgroundColorPropsConst, PositionProps } from "domains";

export const useShadowStyles = (props: {
  position: PositionProps;
  backgroundColor: BackgroundColorProps | string;
}) => {
  const theme: { palette: { background: TypeBackground } } = useTheme();

  const isBgColorProps = (value: BackgroundColorProps | string): value is BackgroundColorProps => {
    return backgroundColorPropsConst.includes(value as BackgroundColorProps);
  };

  const bgColor = isBgColorProps(props.backgroundColor)
    ? theme.palette.background[props.backgroundColor]
    : props.backgroundColor;

  const defaultStyle = {
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      height: "10px",
      width: "20px",
      bottom: "0",
      zIndex: "0",
    },
  };
  const customStyle: {
    "&:before, &:after": object;
  } = defaultStyle;
  let style: {
    borderRadius?: string;
    "&:before"?: object;
    "&:after"?: object;
  } = {};
  if (props.position === "bottom") {
    style = {
      borderRadius: "10px 10px 0 0",
      "&:before": {
        left: "-20px",
        borderRadius: "0 0 10px 0",
        boxShadow: `10px 0 0 0 ${bgColor}`,
      },
      "&:after": {
        right: "-20px",
        borderRadius: "0 0 0 10px",
        boxShadow: `-10px 0 0 0 ${bgColor}`,
      },
    };
  } else if (props.position === "top") {
    style = {
      borderRadius: "0 0 10px 10px",
      "&:before": {
        top: "0px",
        right: "-20px",
        transform: "rotate(-180deg)",
        borderRadius: "0 0 10px 0",
        boxShadow: `10px 0 0 0 ${bgColor}`,
      },
      "&:after": {
        top: "0px",
        left: "-20px",
        transform: "rotate(-180deg)",
        borderRadius: "0 0 0 10px",
        boxShadow: `-10px 0 0 0 ${bgColor}`,
      },
    };
  } else if (props.position === "left") {
    style = {
      borderRadius: "0 10px 10px 0",
      "&:before": {
        bottom: "-15px",
        left: "-5px",
        transform: "rotate(-90deg)",
        borderRadius: "0 10px 0 0",
        boxShadow: `10px 0 0 0 ${bgColor}`,
      },
      "&:after": {
        top: "-15px",
        left: "-5px",
        transform: "rotate(-90deg)",
        borderRadius: "10px 0 0 0",
        boxShadow: `-10px 0 0 0 ${bgColor}`,
      },
    };
  } else if (props.position === "right") {
    style = {
      borderRadius: "10px 0 0 10px",
      "&:before": {
        bottom: "-15px",
        right: "-5px",
        transform: "rotate(-90deg)",
        borderRadius: "0 0 10px 0",
        boxShadow: `10px 0 0 0 ${bgColor}`,
      },
      "&:after": {
        top: "-15px",
        right: "-5px",
        transform: "rotate(-90deg)",
        borderRadius: "0 0 0 10px",
        boxShadow: `-10px 0 0 0 ${bgColor}`,
      },
    };
  }

  return { ...customStyle, ...style };
};
