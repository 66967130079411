import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestArticleExitPopupsApi extends ApiBase {

  static fetch(uid) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/${uid}/articles/exit_popups`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static create(uid, params, popupId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(`${baseUrl}/ab_tests/${uid}/articles/exit_popups`, {...params, ...{ id: popupId }}, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static update(uid, popupId, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.patch(`${baseUrl}/ab_tests/${uid}/articles/exit_popups/${popupId}`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroy(uid, popupId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.delete(`${baseUrl}/ab_tests/${uid}/articles/exit_popups/${popupId}`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestArticleExitPopupsApi;
