import React, { FC, Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import {
  AdminOuterFrame,
  AuthRouter,
  DeviceLimitation,
  LoadingSpinner,
  RequireTeam,
  Theme,
  GlobalChat,
} from "components";
import { MIXPANEL_TOKEN } from "const/mixpanel";
import { AuthState, useAuth, useUiStyle, MemberProvider, useMember, useRemoteLogging } from "hooks";
import mixpanel from "mixpanel-browser";

import { ErrorBoundary } from "utils";
import {
  addTrackClickEventListener,
  mixpanelTrackPageView,
} from "utils/tracking/mixpanel-analytics";

export const Navigation: FC = () => {
  const [authState] = useAuth();
  const memberState = useMember();
  const [isNewUiStyle] = useUiStyle();
  useRemoteLogging();

  // Initialize Mixpanel tracking
  const viteMixPanelToken: string = typeof MIXPANEL_TOKEN === "string" ? MIXPANEL_TOKEN : "";

  mixpanel.init(viteMixPanelToken, {
    debug: process.env.NODE_ENV === "development",
  });

  // Identify Mixpanel user & track page views
  useEffect(() => {
    const memberAttributes = memberState.memberAttributes;
    const isTrackTarget =
      memberAttributes.isAdmin === false && memberAttributes.isImpersonation === false;

    // Send to mixpanel, if they are dev user or not admin and impersonation
    // Send events for unuath users & logged in users
    if (
      (process.env.NODE_ENV === "development" || isTrackTarget) &&
      (authState === AuthState.Unauth || memberAttributes.user?.id)
    ) {
      mixpanel.register({ "Ui Style": isNewUiStyle ? "new" : "old" });
      mixpanelTrackPageView();
      addTrackClickEventListener();
    }
  }, [authState, memberState.memberAttributes, isNewUiStyle]);

  const TermsShowPage = lazy(() =>
    import("pages/terms/show").then(({ TermsShowPage }) => ({
      default: TermsShowPage,
    })),
  );
  const PrivacyPage = lazy(() =>
    import("pages/privacy").then(({ PrivacyPage }) => ({
      default: PrivacyPage,
    })),
  );
  const UsersConfirmationPage = lazy(() =>
    import("pages/users/confirmation").then(({ UsersConfirmationPage }) => ({
      default: UsersConfirmationPage,
    })),
  );
  const UsersConfirmationCodePage = lazy(() =>
    import("pages/users/confirmation/code").then(({ UsersConfirmationCodePage }) => ({
      default: UsersConfirmationCodePage,
    })),
  );
  const TeamMemberInvitationEditPage = lazy(() =>
    import("pages/teams/member/invitation/edit").then(({ TeamMemberInvitationEditPage }) => ({
      default: TeamMemberInvitationEditPage,
    })),
  );
  const HomePage = lazy(() => import("pages/home").then(({ HomePage }) => ({ default: HomePage })));
  const UserSignUpPage = lazy(() =>
    import("pages/users/sign-up").then(({ UserSignUpPage }) => ({ default: UserSignUpPage })),
  );
  const UserForgotPasswordPage = lazy(() =>
    import("pages/users/password/forgot").then(({ UserForgotPasswordPage }) => ({
      default: UserForgotPasswordPage,
    })),
  );
  const UserEditPasswordPage = lazy(() =>
    import("pages/users/password/edit").then(({ UserEditPasswordPage }) => ({
      default: UserEditPasswordPage,
    })),
  );
  const UsersTeamsPage = lazy(() =>
    import("pages/users/teams").then(({ UsersTeamsPage }) => ({ default: UsersTeamsPage })),
  );
  const PermissionsPage = lazy(() =>
    import("pages/permissions").then(({ PermissionsPage }) => ({
      default: PermissionsPage,
    })),
  );
  const RedirectionAdAccountYahoo = lazy(() =>
    import("pages/redirections/ad-accounts/yahoo/show").then(({ RedirectionAdAccountYahoo }) => ({
      default: RedirectionAdAccountYahoo,
    })),
  );
  const RedirectionAdAccountFacebook = lazy(() =>
    import("pages/redirections/ad-accounts/facebook/show").then(
      ({ RedirectionAdAccountFacebook }) => ({ default: RedirectionAdAccountFacebook }),
    ),
  );
  const RedirectionsAdAccountsGooglePage = lazy(() =>
    import("pages/redirections/ad-accounts/google").then(
      ({ RedirectionsAdAccountsGooglePage }) => ({
        default: RedirectionsAdAccountsGooglePage,
      }),
    ),
  );
  const RedirectionAdAccountXAdPage = lazy(() =>
    import("pages/redirections/ad-accounts/x-ad").then(({ RedirectionAdAccountXAdPage }) => ({
      default: RedirectionAdAccountXAdPage,
    })),
  );

  const RedirectionsAdAccountsMicrosoftPage = lazy(() =>
    import("pages/redirections/ad-accounts/microsoft").then(
      ({ RedirectionsAdAccountsMicrosoftPage }) => ({
        default: RedirectionsAdAccountsMicrosoftPage,
      }),
    ),
  );
  const InspectionsPage = lazy(() =>
    import("pages/inspections").then(({ InspectionsPage }) => ({
      default: InspectionsPage,
    })),
  );
  const IntroductionsShowPage = lazy(() =>
    import("pages/introductions/show").then(({ IntroductionsShowPage }) => ({
      default: IntroductionsShowPage,
    })),
  );
  const InspectionsFoldersPage = lazy(() =>
    import("pages/inspections/folders").then(({ InspectionsFoldersPage }) => ({
      default: InspectionsFoldersPage,
    })),
  );
  const InspectionsAuthoritiesPage = lazy(() =>
    import("pages/inspections/authorities").then(({ InspectionsAuthoritiesPage }) => ({
      default: InspectionsAuthoritiesPage,
    })),
  );
  const UserEditPage = lazy(() =>
    import("pages/users/edit").then(({ UserEditPage }) => ({
      default: UserEditPage,
    })),
  );
  const AdminPlansPage = lazy(() =>
    import("pages/admin/plans").then(({ AdminPlansPage }) => ({
      default: AdminPlansPage,
    })),
  );
  const AdminPlansNewPage = lazy(() =>
    import("pages/admin/plans/new").then(({ AdminPlansNewPage }) => ({
      default: AdminPlansNewPage,
    })),
  );
  const AdminTeamsMembersPage = lazy(() =>
    import("pages/admin/teams/members").then(({ AdminTeamsMembersPage }) => ({
      default: AdminTeamsMembersPage,
    })),
  );
  const AdminTeamsPlansPaymentsPage = lazy(() =>
    import("pages/admin/teams/plans/payments").then(({ AdminTeamsPlansPaymentsPage }) => ({
      default: AdminTeamsPlansPaymentsPage,
    })),
  );
  const AdminTeamsPage = lazy(() =>
    import("pages/admin/teams").then(({ AdminTeamsPage }) => ({
      default: AdminTeamsPage,
    })),
  );
  const AdminTeamsNewPage = lazy(() =>
    import("pages/admin/teams/new").then(({ AdminTeamsNewPage }) => ({
      default: AdminTeamsNewPage,
    })),
  );
  const AdminProductSearchFormsPage = lazy(() =>
    import("pages/admin/teams/product-search-forms").then(({ AdminProductSearchFormsPage }) => ({
      default: AdminProductSearchFormsPage,
    })),
  );
  const PlansPaymentsNewPage = lazy(() =>
    import("pages/plans/payments/new").then(({ PlansPaymentsNewPage }) => ({
      default: PlansPaymentsNewPage,
    })),
  );
  const TeamsDomainsPage = lazy(() =>
    import("pages/teams/domains").then(({ TeamsDomainsPage }) => ({
      default: TeamsDomainsPage,
    })),
  );
  const TeamsTagsPage = lazy(() =>
    import("pages/teams/tags").then(({ TeamsTagsPage }) => ({
      default: TeamsTagsPage,
    })),
  );
  const TeamsPlansPage = lazy(() =>
    import("pages/teams/plans").then(({ TeamsPlansPage }) => ({
      default: TeamsPlansPage,
    })),
  );
  const TeamsAdAccountsPage = lazy(() =>
    import("pages/teams/ad-accounts").then(({ TeamsAdAccountsPage }) => ({
      default: TeamsAdAccountsPage,
    })),
  );
  const TeamsAspAccountsPage = lazy(() =>
    import("pages/teams/asp-accounts").then(({ TeamsAspAccountsPage }) => ({
      default: TeamsAspAccountsPage,
    })),
  );
  const TeamsProductSearchFormsPage = lazy(() =>
    import("pages/teams/product-search-forms").then(({ TeamsProductSearchFormsPage }) => ({
      default: TeamsProductSearchFormsPage,
    })),
  );
  const AdminArticlesHtmlPartsPage = lazy(() =>
    import("pages/admin-article-html-part").then(({ AdminArticlesHtmlPartsPage }) => ({
      default: AdminArticlesHtmlPartsPage,
    })),
  );
  const ArticlesBulkReplacesPage = lazy(() =>
    import("pages/articles/bulkReplaces").then(({ ArticlesBulkReplacesPage }) => ({
      default: ArticlesBulkReplacesPage,
    })),
  );
  const AbTestsRedirectPagesPage = lazy(() =>
    import("pages/ab-test/redirect-pages").then(({ AbTestsRedirectPagesPage }) => ({
      default: AbTestsRedirectPagesPage,
    })),
  );
  const AbTestsEditPage = lazy(() =>
    import("pages/ab-test/edit").then(({ AbTestsEditPage }) => ({
      default: AbTestsEditPage,
    })),
  );
  const FoldersConversionTagsPage = lazy(() =>
    import("pages/folders/conversion-tags").then(({ FoldersConversionTagsPage }) => ({
      default: FoldersConversionTagsPage,
    })),
  );
  const FoldersOperatorArticlesPage = lazy(() =>
    import("pages/folders/operator-articles").then(({ FoldersOperatorArticlesPage }) => ({
      default: FoldersOperatorArticlesPage,
    })),
  );
  const FoldersOperatorArticlesEditPage = lazy(() =>
    import("pages/folders/operator-articles/edit").then(({ FoldersOperatorArticlesEditPage }) => ({
      default: FoldersOperatorArticlesEditPage,
    })),
  );
  const FoldersFormsShowPage = lazy(() =>
    import("pages/folders/forms/show").then(({ FoldersFormsShowPage }) => ({
      default: FoldersFormsShowPage,
    })),
  );
  const FoldersShowPage = lazy(() =>
    import("pages/folders/index").then(({ FoldersShowPage }) => ({
      default: FoldersShowPage,
    })),
  );
  const FoldersEditPage = lazy(() =>
    import("pages/folders/edit").then(({ FoldersEditPage }) => ({
      default: FoldersEditPage,
    })),
  );
  const FolderFormPage = lazy(() =>
    import("pages/folder-form").then(({ FolderFormPage }) => ({
      default: FolderFormPage,
    })),
  );
  const AbTestsArticlesSplitTestSettingsDevicesPage = lazy(() =>
    import("pages/ab-test/articles/split-test-setting/devices").then(
      ({ AbTestsArticlesSplitTestSettingsDevicesPage }) => ({
        default: AbTestsArticlesSplitTestSettingsDevicesPage,
      }),
    ),
  );
  const AbTestArticleSplitTestSettingParamsPage = lazy(() =>
    import("pages/ab-test/articles/split-test-setting/params").then(
      ({ AbTestArticleSplitTestSettingParamsPage }) => ({
        default: AbTestArticleSplitTestSettingParamsPage,
      }),
    ),
  );
  const AbTestArticleSplitTestSettingHourPage = lazy(() =>
    import("pages/ab-test/articles/split-test-setting/hour").then(
      ({ AbTestArticleSplitTestSettingHourPage }) => ({
        default: AbTestArticleSplitTestSettingHourPage,
      }),
    ),
  );
  const AbTestArticleSplitTestSettingPeriodsPage = lazy(() =>
    import("pages/ab-test/articles/split-test-setting/periods").then(
      ({ AbTestArticleSplitTestSettingPeriodsPage }) => ({
        default: AbTestArticleSplitTestSettingPeriodsPage,
      }),
    ),
  );
  const AbTestsArticlesSplitTestSettingsOsesPage = lazy(() =>
    import("pages/ab-test/articles/split-test-setting/oses").then(
      ({ AbTestsArticlesSplitTestSettingsOsesPage }) => ({
        default: AbTestsArticlesSplitTestSettingsOsesPage,
      }),
    ),
  );
  const AbTestsArticlesSplitTestSettingsCarriersPage = lazy(() =>
    import("pages/ab-test/articles/split-test-setting/carriers").then(
      ({ AbTestsArticlesSplitTestSettingsCarriersPage }) => ({
        default: AbTestsArticlesSplitTestSettingsCarriersPage,
      }),
    ),
  );
  const ExitPopupPage = lazy(() =>
    import("pages/ab-test/articles/exit-popup").then(({ ExitPopupPage }) => ({
      default: ExitPopupPage,
    })),
  );
  const AbTestsArticlesHeatmapsComparisonsPage = lazy(() =>
    import("pages/ab-test/articles/heatmap").then(({ AbTestsArticlesHeatmapsComparisonsPage }) => ({
      default: AbTestsArticlesHeatmapsComparisonsPage,
    })),
  );
  const ArticlePreviewPage = lazy(() =>
    import("pages/ab-test/articles/preview").then(({ ArticlePreviewPage }) => ({
      default: ArticlePreviewPage,
    })),
  );
  const AbTestsArticlesPage = lazy(() =>
    import("pages/ab-test/articles").then(({ AbTestsArticlesPage }) => ({
      default: AbTestsArticlesPage,
    })),
  );
  const AbTestsReportsPage = lazy(() =>
    import("pages/ab-test/articles/reports").then(({ AbTestsReportsPage }) => ({
      default: AbTestsReportsPage,
    })),
  );
  const AddonOptionListPage = lazy(() =>
    import("pages/addon/option-list").then(({ AddonOptionListPage }) => ({
      default: AddonOptionListPage,
    })),
  );
  return (
    <Suspense
      fallback={
        <LoadingSpinner
          iconWrapperSx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
          iconColor={"primary"}
          iconSize={30}
        />
      }
    >
      <Routes>
        <Route
          path="*"
          element={<div />}
        />
        <Route
          path="/terms"
          element={<TermsShowPage />}
        />
        <Route
          path="/privacy"
          element={<PrivacyPage />}
        />
        {/* GET: /users/confirmation */}
        <Route
          path="/users/confirmation"
          element={<UsersConfirmationPage />}
        />
        <Route
          path="/users/confirmation/code/new"
          element={<UsersConfirmationCodePage />}
        />
        <Route
          path="/teams/:team_uid/members/:member_uid/invitations/edit"
          element={<TeamMemberInvitationEditPage />}
        />
        {authState === AuthState.Unauth ? (
          <React.Fragment>
            <Route
              path="/"
              element={<HomePage />}
            />
            <Route
              path="/users/sign_up"
              element={<UserSignUpPage />}
            />
            <Route
              path="/users/forgot_password"
              element={<UserForgotPasswordPage />}
            />
            <Route
              path="/users/password/edit"
              element={<UserEditPasswordPage />}
            />
          </React.Fragment>
        ) : null}
        {authState === AuthState.Auth ? (
          <React.Fragment>
            <Route
              path="/"
              element={<UsersTeamsPage />}
            />
            <Route
              path="/users/teams"
              element={<UsersTeamsPage />}
            />
            <Route
              path="/users/edit"
              element={<UsersTeamsPage />}
            />
          </React.Fragment>
        ) : null}
        {authState === AuthState.AuthToTeam ? (
          <React.Fragment>
            {/* GET: /permissions */}
            <Route
              path="/permissions"
              element={<PermissionsPage />}
            />
            {/* POST: /webhooks/articles/:article_uid/photos/uploaded_messages */}
            {/* POST: /webhooks/articles/:article_uid/photos/fail_upload_messages */}
            {/* POST: /webhooks/sendgrid/trackings */}
            {/* GET: /redirections/ad_accounts/yahoo */}
            <Route
              path="/redirections/ad_accounts/yahoo"
              element={<RedirectionAdAccountYahoo />}
            />
            {/* GET: /redirections/ad_accounts/microsoft */}
            <Route
              path="/redirections/ad_accounts/microsoft"
              element={<RedirectionsAdAccountsMicrosoftPage />}
            />
            {/* GET: /redirections/ad_accounts/facebook */}
            <Route
              path="/redirections/ad_accounts/facebook"
              element={<RedirectionAdAccountFacebook />}
            />
            <Route
              path="/redirections/ad_accounts/google"
              element={<RedirectionsAdAccountsGooglePage />}
            />
            <Route
              path="/redirections/ad_accounts/x"
              element={<RedirectionAdAccountXAdPage />}
            />
            <Route
              path="/inspections"
              element={<InspectionsPage />}
            />
            <Route
              path="/introductions"
              element={<IntroductionsShowPage />}
            />
            <Route
              path="/inspections/folders"
              element={<InspectionsFoldersPage />}
            />
            <Route
              path="/inspections/authorities"
              element={<InspectionsAuthoritiesPage />}
            />
            {/* GET: /users/sign_in */}
            {/* POST: /users/sign_in */}
            {/* DELETE: /users/sign_out */}
            {/* PATCH: /users/password */}
            {/* PUT: /users/password */}
            {/* POST: /users/password */}
            {/* GET: /users/cancel */}
            <Route
              path="/users/edit"
              element={<UserEditPage />}
            />
            {/* PATCH: /users */}
            {/* PUT: /users */}
            {/* DELETE: /users */}
            {/* POST: /users */}
            {/* POST: /users/confirmation */}
            <Route
              path="/admin/plans"
              element={<AdminPlansPage />}
            />
            {/* POST: /admin/plans */}
            <Route
              path="/admin/plans/new"
              element={<AdminPlansNewPage />}
            />
            {/* GET: /admin/plans/:uid/edit */}
            {/* GET: /admin/plans/:uid */}
            {/* PATCH: /admin/plans/:uid */}
            {/* PUT: /admin/plans/:uid */}
            {/* DELETE: /admin/plans/:uid */}
            <Route
              path="/admin/teams/:team_uid/members"
              element={<AdminTeamsMembersPage />}
            />
            <Route
              path="/admin/teams/:team_uid/plans/payments"
              element={<AdminTeamsPlansPaymentsPage />}
            />
            <Route
              path="/admin/teams"
              element={<AdminTeamsPage />}
            />
            {/* POST: /admin/teams */}
            <Route
              path="/admin/teams/new"
              element={<AdminTeamsNewPage />}
            />
            <Route
              path="/admin/product_search_forms"
              element={<AdminProductSearchFormsPage />}
            />
            {/* GET: /admin/articles/html_parts */}
            {/* GET: /admin/articles/html_parts/new */}
            <Route
              path="/plans/:plan_uid/payments/new"
              element={<PlansPaymentsNewPage />}
            />
            {/* POST: /users/teams/:team_uid/sessions */}
            <Route
              path="/users/teams"
              element={<UsersTeamsPage />}
            />
            <Route
              path="/teams/domains"
              element={<TeamsDomainsPage />}
            />
            <Route
              path="/teams/tags"
              element={<TeamsTagsPage />}
            />
            <Route
              path="/teams/plans"
              element={<TeamsPlansPage />}
            />
            {/* GET: /teams/searches */}
            {/* DELETE: /teams/members/sessions */}
            {/* GET: /teams/members */}
            {/* POST: /teams/members */}
            {/* DELETE: /teams/members/:uid */}
            <Route
              path="/teams/ad_accounts"
              element={<TeamsAdAccountsPage />}
            />
            <Route
              path="/teams/asp_accounts"
              element={<TeamsAspAccountsPage />}
            />
            {/* POST: /teams/gunosy_accounts(.:format) */}
            {/* DELETE: /teams/gunosy_accounts/:id */}
            {/* POST: /teams/tiktok_accounts */}
            {/* DELETE: /teams/tiktok_accounts/:id */}
            {/* POST: /teams/smartnews_accounts */}
            {/* DELETE: /teams/smartnews_accounts/:id */}
            <Route
              path="/teams/product_search_forms"
              element={<TeamsProductSearchFormsPage />}
            />
            {/* POST: /teams/:team_uid/members/sessions */}
            {/* GET: /teams/:team_uid/members/sessions/new */}
            {/* POST: /teams/:team_uid/members/:member_uid/invitations/sessions */}
            {/* POST: /teams/:team_uid/members/:member_uid/invitations/resends */}
            {/* PATCH: /teams/:team_uid/members/:uid */}
            {/* PUT: /teams/:team_uid/members/:uid */}
            <Route
              path="/admin/articles/html_parts"
              element={<AdminArticlesHtmlPartsPage />}
            />
            <Route
              path="/articles/bulk_replaces"
              element={<ArticlesBulkReplacesPage />}
            />
            {/* POST: /articles/html_parts */}
            {/* GET: /articles/html_parts/new */}
            {/* GET: /articles/html_parts/:id/edit */}
            {/* GET: /articles/html_parts/:id */}
            {/* PATCH: /articles/html_parts/:id */}
            {/* PUT: /articles/html_parts/:id */}
            {/* GET: /articles/:article_uid/charts */}
            {/* GET: /articles */}
            {/* GET: /articles/:uid/edit */}
            {/* PATCH: /articles/:uid */}
            {/* PUT: /articles/:uid */}
            {/* PATCH: /folders/:folder_uid/subdomain */}
            {/* PUT: /folders/:folder_uid/subdomain */}
            {/* PATCH: /folders/:folder_uid/domain */}
            {/* PUT: /folders/:folder_uid/domain */}
            {/* GET: /folders/:folder_uid/ab_tests/:ab_test_uid/redirect_pages */}
            <Route
              path="/folders/:folder_uid/ab_tests/:ab_test_uid/redirect_pages"
              element={<AbTestsRedirectPagesPage />}
            />
            {/* POST: /folders/:folder_uid/ab_tests */}
            {/* GET: /folders/:folder_uid/ab_tests/:uid/edit */}
            <Route
              path="/folders/:folder_uid/ab_tests/:ab_test_uid/edit"
              element={<AbTestsEditPage />}
            />
            {/* PATCH: /folders/:folder_uid/ab_tests/:uid */}
            {/* PUT: /folders/:folder_uid/ab_tests/:uid */}
            {/* DELETE: /folders/:folder_uid/ab_tests/:uid */}
            <Route
              path="/folders/:folder_uid/conversion_tags"
              element={<FoldersConversionTagsPage />}
            />
            {/* POST: /folders/:folder_uid/conversion_tags */}
            {/* GET: /folders/:folder_uid/conversion_tags/:uid/edit */}
            {/* PATCH: /folders/:folder_uid/conversion_tags/:uid */}
            {/* PUT: /folders/:folder_uid/conversion_tags/:uid */}
            {/* DELETE: /folders/:folder_uid/conversion_tags/:uid */}
            <Route
              path="/folders/:folder_uid/operator_articles"
              element={<FoldersOperatorArticlesPage />}
            />
            <Route
              path="/folders/:folder_uid/operator_articles/:uid/edit"
              element={<FoldersOperatorArticlesEditPage />}
            />
            <Route
              path="/folders/:folder_uid/forms/:uid"
              element={<FoldersFormsShowPage />}
            />
            {/* GET: /folders */}
            <Route
              path="/folders"
              element={<FoldersShowPage />}
            />
            {/* POST: /folders */}
            <Route
              path="/folders/:uid/edit"
              element={<FoldersEditPage />}
            />
            {/* PATCH: /folders/:uid */}
            {/* PUT: /folders/:uid */}
            {/* DELETE: /folders/:uid */}
            <Route
              path="/folders/forms"
              element={<FolderFormPage />}
            />
            <Route
              path="/ab_tests/:ab_test_uid/articles/split_test_settings/devices"
              element={<AbTestsArticlesSplitTestSettingsDevicesPage />}
            />
            <Route
              path="/ab_tests/:ab_test_uid/articles/split_test_settings/params"
              element={<AbTestArticleSplitTestSettingParamsPage />}
            />
            <Route
              path="/ab_tests/:ab_test_uid/articles/split_test_settings/hours"
              element={<AbTestArticleSplitTestSettingHourPage />}
            />
            <Route
              path="/ab_tests/:ab_test_uid/articles/split_test_settings/periods"
              element={<AbTestArticleSplitTestSettingPeriodsPage />}
            />
            <Route
              path="/ab_tests/:ab_test_uid/articles/split_test_settings/oses"
              element={<AbTestsArticlesSplitTestSettingsOsesPage />}
            />
            <Route
              path="/ab_tests/:ab_test_uid/articles/split_test_settings/carriers"
              element={<AbTestsArticlesSplitTestSettingsCarriersPage />}
            />
            <Route
              path="/ab_tests/:ab_test_uid/articles/exit_popups"
              element={<ExitPopupPage />}
            />
            {/* GET: /ab_tests/:ab_test_uid/articles/reports/charts */}
            {/* GET: /ab_tests/:ab_test_uid/articles/reports/parameters */}
            {/* GET: /ab_tests/:ab_test_uid/articles/reports/parameters/charts */}
            {/* GET: /ab_tests/:ab_test_uid/articles/reports/parameters/routes */}
            {/* GET: /ab_tests/:ab_test_uid/articles/reports/parameters/hourly_charts */}
            {/* POST: /ab_tests/:ab_test_uid/articles/parameter/exclusion_settings */}
            {/* DELETE: /ab_tests/:ab_test_uid/articles/parameter/exclusion_settings/:id */}
            {/* GET: /ab_tests/:ab_test_uid/articles/htmls/heatmaps/comparisons */}
            <Route
              path="/ab_tests/:ab_test_uid/articles/htmls/heatmaps/comparisons"
              element={<AbTestsArticlesHeatmapsComparisonsPage />}
            />
            {/* POST: /ab_tests/:ab_test_uid/articles/htmls/heatmaps/comparisons */}
            <Route
              path="/ab_tests/:ab_test_uid/articles/:article_uid/previews"
              element={<ArticlePreviewPage />}
            />
            {/* GET: /ab_tests/:ab_test_uid/articles/:article_uid/charts */}
            {/* GET: /ab_tests/:ab_test_uid/articles/:article_uid/htmls/heatmap/attributes */}
            <Route
              path="/ab_tests/:ab_test_uid/articles"
              element={<AbTestsArticlesPage />}
            />
            <Route
              path="/ab_tests/:ab_test_uid/reports"
              element={<AbTestsReportsPage />}
            />
            <Route
              path="/addon/option-list"
              element={<AddonOptionListPage />}
            />
            <Route
              path="/"
              element={<FoldersShowPage />}
            />
          </React.Fragment>
        ) : null}
      </Routes>
    </Suspense>
  );
};

function App() {
  return (
    <RecoilRoot>
      <ErrorBoundary>
        <BrowserRouter>
          <DeviceLimitation>
            <RequireTeam>
              <AuthRouter>
                <Theme>
                  <MemberProvider>
                    <GlobalChat />
                    <AdminOuterFrame />
                    <Navigation />
                  </MemberProvider>
                </Theme>
              </AuthRouter>
            </RequireTeam>
          </DeviceLimitation>
        </BrowserRouter>
      </ErrorBoundary>
    </RecoilRoot>
  );
}

export default App;
