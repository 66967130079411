import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import ModalDeviceLimitation from "components/molecules/modal-device-limitation";
import { useAuthSignOut } from "hooks";

export const DeviceLimitation: React.FC<{
  children?: React.ReactNode | undefined;
}> = (props) => {
  const [isDeviceLimitation, setIsDeviceLimitation] = useState(false);
  const [handleSignOut] = useAuthSignOut();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError) => {
        if (error.response) {
          const isUnauthorized = error.response.status === 401;
          const isRequireUser =
            hasRequireUser(error.response.data) && error.response.data.require_user;
          const isRequireSignIn = isUnauthorized && isRequireUser;
          const isDeviceLimitation =
            (hasDeviceLimitation(error.response.data) && error.response.data.device_limitation) ||
            isRequireSignIn ||
            false;
          if (isDeviceLimitation) {
            try {
              handleSignOut();
            } catch (e) {
            }
          }
          setIsDeviceLimitation(isDeviceLimitation);
          return Promise.reject(error);
        }
      },
    );
  }, [handleSignOut]);

  if (isDeviceLimitation) {
    return <ModalDeviceLimitation isModalOpen={true} />;
  }

  return <>{props.children}</>;
};

interface HasRequireUser {
  require_user: boolean;
}

const hasRequireUser = (value: unknown): value is HasRequireUser => {
  const record = value as Record<string, unknown>;
  return typeof record.require_user === "boolean";
};

interface HasDeviceLimitation {
  device_limitation: boolean;
}

const hasDeviceLimitation = (value: unknown): value is HasDeviceLimitation => {
  const record = value as Record<string, unknown>;
  return typeof record.device_limitation === "boolean";
};
