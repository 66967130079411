import axios from "axios";
import { FolderFormTypeProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FolderFormTypeResponse = FolderFormTypeProps[];

class FolderFormTypeApi extends ApiBase {
  static fetch() {
    let baseUrl = this.defaultBaseUrl();

    return new Promise<FolderFormTypeResponse>(function (resolve, reject) {
      axios
        .get(baseUrl + "/folders/forms/types", { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderFormTypeApi;
