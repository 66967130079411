import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useForm, ErrorMessage } from 'react-hook-form';

import btnStyles from 'stylesheets/components/partials/btn.module.scss'
import formStyles from 'stylesheets/components/partials/form.module.scss'
import duplicateModalStyles from 'stylesheets/components/editor/duplicateModal.module.scss'
import appModalStyles from 'stylesheets/components/partials/appModal.module.scss';
import dropdownStyles from 'stylesheets/components/partials/dropdown.module.scss'

import { AppModal } from "components";
import ErrorText from 'javascripts/components/Partials/ErrorText';

import duplicateIcon from 'assets/images/duplicate-white.svg'

import {
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Box
} from '@mui/material'
import { useTranslation } from "react-i18next";

const DuplicateToOtherModal = ({ article, abTest, duplicateArticle}) => {
  const { t } = useTranslation();
  const [includeFinishedAbTest, setIncludeFinishedAbTest] = useState(false)
  const abTests = abTest.editor_version === 3 ? abTest.folder.ab_tests.filter(e => e.editor_version === 3) : abTest.folder.ab_tests.filter(e => includeFinishedAbTest ? e : e.ad_status !== 'finished')
  const [destinationAbTestUid, setDestinationAbTestUid] = useState(abTests[0].uid)
  const [isModalOpen, setIsOpen] = useState(false);

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => {
    let params = new FormData()
    params.append('ab_test[link_type]', data.linkType)
    params.append('ab_test[is_inherit_html_tag]', data.isInheritHtmlTag)
    params.append('ab_test[destination_ab_test_uid]', destinationAbTestUid)
    params.append('ab_test[is_inherit_funnel_step]', data.isInheritFunnelStep)
    // NOTE: duplications_controllerが共通の為固定で値を入れる。複数作成に対応する時はこちらの値を任意にする。
    params.append('ab_test[duplicate_count]', 1)
    duplicateArticle(article, params)
    setIsOpen(false)
  };

  const options = useMemo(() => {
    return abTests.map(abTest => {
      return (
        <option key={abTest.id} value={abTest.uid}>{abTest.title}</option>
      )
    })
  }, [includeFinishedAbTest])

  const handleOnChange = (e) => {
    setDestinationAbTestUid('')
    setIncludeFinishedAbTest(e.target.checked)
  }
  
  return (
    <AppModal
      theme="darkTheme"
      width={700}
      isModalOpen={isModalOpen}
      setIsOpen={(e) => setIsOpen(e)}
    >
      <div className={`${dropdownStyles.dropdownChoice}`}>
        {t("別のbeyondページに複製")}
      </div>
      <div className={`${appModalStyles.modalWrapper}`}>
        <div className={appModalStyles.modalHeader}>
          <div className={appModalStyles.left}>
            <div
              data-test={'DuplicateToOtherModal-BtnClose'}
              className={`${btnStyles.btn} ${btnStyles.btnDarkThemeSub} ${btnStyles.btnSmall}`}
              onClick={() => setIsOpen(false) }
            >
              {t("閉じる")}
            </div>
          </div>
          <div className={appModalStyles.center}>
            <div className={appModalStyles.title}>
                {t("別のbeyondページへのVersion複製")}
            </div>
          </div>
          <div className={appModalStyles.right}>
            <div
              className={`${btnStyles.btn} ${btnStyles.btnDarkThemePrimary} ${btnStyles.btnSmall} ${appModalStyles.action}`}
              onClick={handleSubmit(onSubmit)}
            >
              {t("複製する")}
            </div>
          </div>
        </div>
        <div className={appModalStyles.modalContent}>
          <div className={formStyles.formGroup}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <label className={duplicateModalStyles.abTestFormLabel}>
                {t("複製先")}
                {abTest.editor_version === 3 &&
                  <span>{t("※HTMLエディター→beyondエディターは互換性がないため複製先として表示されません")}</span>
                }
              </label>
              <FormGroup sx={{ display: "flex", alignItems: "center", flexDirection: "unset", fontSize: "12px" }}>
                <FormLabel>終了beyondページを</FormLabel>
                <FormControlLabel onChange={handleOnChange} sx={{ borderTop: "none !important" }} componentsProps={{ typography: { sx: { fontSize: "12px" } } }} size="small" control={<Checkbox defaultChecked={includeFinishedAbTest} />} label="含む" />
             </FormGroup>
            </Box>
            <select data-test={'DuplicateToOtherModal-DestinationAbTestUidSelect'} className={`${formStyles.formControl} ${formStyles.darkTheme}`} value={destinationAbTestUid} onChange={(e) => setDestinationAbTestUid(e.target.value)}>
              <option value="">
                {t("選択してください")}
              </option>
              {options}
            </select>
          </div>
          <form data-test={"DuplicateToOtherModal-DestinationArticleForm"} onSubmit={handleSubmit(onSubmit)}>
            <div className={`${formStyles.formGroup}`}>
              <label>{t("リンク")}</label>
              <select
                name='linkType'
                ref={register}
                className={`${formStyles.formControl} ${formStyles.darkTheme} ${errors.linkType ? formStyles.invalid : ''}`}
              >
                <option value='leave_links'>{t("を残す", { value: t("リンク") })}</option>
                <option value='remove_links'>{t("を削除", { value: t("リンク") })}</option>
                <option value='remove_tracking_links'>{t("トラッキングリンクだけ削除")}</option>
              </select>
              <ErrorText text={<ErrorMessage errors={errors} name='linkType' />} size={'small'} />
            </div>
            <div className={formStyles.formGroup}>
              <label>{t("Versionのheadとbodyタグ")}</label>
              <select
                name='isInheritHtmlTag'
                ref={register}
                className={`${formStyles.formControl} ${errors.isInheritHtmlTag ? formStyles.invalid : ''}`}
              >
                <option value="true">{t("引き継ぐ")}</option>
                <option value="false">{t("引き継がない")}</option>
              </select>
              <ErrorText text={<ErrorMessage errors={errors} name='isInheritHtmlTag' />} size={'small'} />
            </div>
            <div className={formStyles.formGroup}>
              <label>{t("ステップ")}</label>
              <select
                name='isInheritFunnelStep'
                ref={register}
                className={`${formStyles.formControl} ${errors.isInheritFunnelStep ? formStyles.invalid : ''}`}
              >

                <option value="true">{t("引き継ぐ")}</option>
                <option value="false">{t("引き継がない")}</option>
              </select>
              <ErrorText text={<ErrorMessage errors={errors} name='isInheritFunnelStep' />} size={'small'} />
            </div>
          </form>
        </div>
      </div>
    </AppModal>
  )
}


DuplicateToOtherModal.propTypes = {
  article: PropTypes.object.isRequired,
  abTest: PropTypes.object.isRequired,
  duplicateArticle: PropTypes.func.isRequired
}

export default DuplicateToOtherModal
