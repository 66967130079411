import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";
import { TeamDomainTagProps } from "domains";
import TeamDomainTagApi from "javascripts/api/teams/domains/tag";

export const domainTagListState = atom<TeamDomainTagProps[]>({
  key: "domainTagListState",
  default: [],
});

export const useDomainTags = (domainId: number) => {
  const [domainTagList, setDomainTagList] = useRecoilState(domainTagListState);
  const [error, setError] = useState<any>();
  useEffect(() => {
    (async () => {
      try {
        const response: TeamDomainTagProps[] = await TeamDomainTagApi.fetchTags(domainId);
        const filteredTags = response.filter(
          (d: TeamDomainTagProps) => d.document_property === "header"
        );
        setDomainTagList(filteredTags);
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainId]);

  return [domainTagList, error] as const;
};

export const useCreateDomainTag = () => {
  const [domainTagList, setDomainTagList] = useRecoilState(domainTagListState);
  const [error, setError] = useState<any>();
  const handle = async (domainId: number, param: string) => {
    try {
      const response: TeamDomainTagProps = await TeamDomainTagApi.create(domainId, param);
      if (response) {
        setDomainTagList([...domainTagList, response]);
      }
      return true;
    } catch (e: unknown) {
      setError(e);
      return false;
    }
  };
  return [handle, error] as const;
};

export const useUpdateDopmainTag = () => {
  const [domainTagList, setDomainTagList] = useRecoilState(domainTagListState);
  const [error, setError] = useState<any>();
  const handle = async (
    domainId: number,
    tagId: number,
    params: {
      tag: {
        name: string;
        body: string;
      };
    }
  ) => {
    try {
      const response: TeamDomainTagProps = await TeamDomainTagApi.update(domainId, tagId, params);
      const list = [...domainTagList];
      const index = list.findIndex((d) => d.id === tagId);
      if (response) {
        list[index] = response;
        setDomainTagList(list);
      }
    } catch (e: unknown) {
      setError(e);
    }
  };
  return [handle, error] as const;
};

export const useDestroyDomainTag = () => {
  const [domainTagList, setDomainTagList] = useRecoilState(domainTagListState);
  const [error, setError] = useState<any>();
  const { t } = useTranslation();
  const handle = async (domainId: number, tagId: number) => {
    const answer = window.confirm(t("を削除します。よろしいですか？", { value: t("タグ") }) || "");
    if (!answer) return;
    try {
      await TeamDomainTagApi.destroy(domainId, tagId);
      const list = domainTagList.filter((element: TeamDomainTagProps) => element.id !== tagId);
      setDomainTagList(list);
    } catch (e: unknown) {
      setError(e);
    }
  };
  return [handle, error] as const;
};
