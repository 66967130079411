import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const ArrowHeadDownIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <path
        d="M6.99922 0.583339C6.64922 0.583339 6.41589 0.816672 6.41589 1.16667V11.4333L4.31589 9.33334C4.08255 9.10001 3.73255 9.10001 3.49922 9.33334C3.38255 9.45001 3.32422 9.62501 3.32422 9.74167C3.32422 9.85834 3.38255 10.0333 3.49922 10.15L6.59089 13.2417C6.70755 13.3583 6.82422 13.4167 6.94089 13.4167C6.99922 13.4167 6.99922 13.4167 7.05755 13.4167C7.17422 13.4167 7.29089 13.3583 7.34922 13.3C7.34922 13.3 7.34922 13.3 7.40755 13.2417L10.4992 10.15C10.7326 9.91667 10.7326 9.56667 10.4992 9.33334C10.2659 9.10001 9.91589 9.10001 9.68255 9.33334L7.58255 11.4333V1.16667C7.58255 0.816672 7.34922 0.583339 6.99922 0.583339Z"
        fill={iconColor}
      />
    </svg>
  );
};
