import axios from "axios";
import { ExitPopupProps } from "domains";
import ApiBase from "./apiBase";

type FetchExitPopupResponse = {
  popups: ExitPopupProps[];
  next_page: number;
};

class ExitPopupApi extends ApiBase {
  static fetch(params = {}) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchExitPopupResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/exit_popups`, { params, withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }
}

export default ExitPopupApi;
