import { deleteRequest, get, patch, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { FolderFormProps, FolderFormShowProps } from "domains";
import { FolderFormContent } from "domains/folder-form-content";

export type WeeklyReceiveContactCount = Record<string, number>;

type FetchFolderFormResponse = {
  forms: FolderFormShowProps[];
  weekly_receive_contact_count: WeeklyReceiveContactCount | null;
  next_page: number | null;
};

const fetch = async (params: any) => {
  const path = `${DEFAULT_BASE_URL}/folders/forms`;
  return get<{}, FetchFolderFormResponse>(params, path);
};

type FetchFormShowResponse = {
  form: FolderFormProps;
  content: FolderFormContent;
  status?: number; // TODO: バックエンド側でパラメータが追加されたらOptionalを解除する
};

export const fetchShow = async (folderUid: string, folderFormUid: string) => {
  const path = `${DEFAULT_BASE_URL}/folders/${folderUid}/forms/${folderFormUid}`;

  return get<{}, FetchFormShowResponse>({}, path);
};

export type CreateFolderFormParams = {
  name: string;
  folder_form_type_id: number;
  folder_form_external_post_setting_parameters: {
    names: string[];
  };
  folder_form_input_content: {
    html: string;
    css: string;
    js: string;
    form_id: number;
    master_style_sheet_attributes: {
      css: string;
    };
  };
  folder_form_complete_content: {
    html: string;
    css: string;
    js: string;
    form_id: number;
    master_style_sheet_attributes: {
      css: string;
    };
  };
};

export const create = async (folderUid: string, params: CreateFolderFormParams) => {
  const path = `${DEFAULT_BASE_URL}/folders/${folderUid}/forms`;

  return post<CreateFolderFormParams, any>(params, path);
};

export type UpdateFolderFormRequest = {
  folder_form: {
    name?: string;
    thanks_url?: string;
    ab_test_cv_tag_assigned?: boolean;
    recaptcha_attached?: boolean;
    confirmation_page_attached?: boolean;
    published?: boolean;
    auto_replied?: boolean;
  };
  folder_form_content?: {
    html: string;
    master_style_sheet_attributes: {
      css: string;
    };
  };
  folder_form_external_post_setting_parameters?: {
    names: string[];
  };
};

export const update = async (
  folderUid: string,
  folderFormUid: string,
  params: UpdateFolderFormRequest
) => {
  const path = `${DEFAULT_BASE_URL}/folders/${folderUid}/forms/${folderFormUid}`;
  return patch<UpdateFolderFormRequest, any>(params, path);
};

export const destroy = async (folderUid: string, folderFormUid: string) => {
  const path = `${DEFAULT_BASE_URL}/folders/${folderUid}/forms/${folderFormUid}`;
  return deleteRequest<{}, any>({}, path);
};

export const FolderFormApi = {
  fetch,
  fetchShow,
  create,
  update,
  destroy,
} as const;
