import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import AbTestArticleParameterDailyReportApi from "javascripts/api/abTest/articles/parameterDailyReport";

export const articleParameterDailyReportListState = atom({
  key: "articleParameterDailyReportListState",
  default: [],
});

// TODO: options, { start, end, includeFunnelSteps }
export const useArticleParameterDailyReportList = (
  abTestUid: string,
  startDate: string,
  endDate: string,
  includeFunnelSteps: boolean
) => {
  const [articleParameterDailyReportList, setArticleParameterDailyReportList] = useRecoilState(
    articleParameterDailyReportListState
  );
  const [loading, _setLoading] = useState(false);

  useEffect(() => {
    if (abTestUid === undefined) {
      return;
    }
    const params = {
      start_date: startDate,
      end_date: endDate,
      include_funnel_steps: includeFunnelSteps,
    };
    AbTestArticleParameterDailyReportApi.fetchReports(abTestUid, params).then((data) => {
      setArticleParameterDailyReportList(data.reports);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid, startDate, endDate, includeFunnelSteps]);

  return [articleParameterDailyReportList, loading] as const;
};
