import { CustomErrorResponse } from "api";
import axios from "axios";
import { DEFAULT_BASE_URL } from "const/app";
import { TeamProps } from "domains";
const fetchTeams = async (): Promise<{ teams: TeamProps[] }> => {
  return new Promise(function (resolve, reject) {
    const path = `${DEFAULT_BASE_URL}/users/teams`;
    axios
      .get<{ teams: TeamProps[] }>(path, { withCredentials: true })
      .then((response) => resolve(response.data))
      .catch((error: CustomErrorResponse) => reject(error.response.data));
  });
};

export const UserTeamApi = {
  fetchTeams,
} as const;
