import React from "react";

type Props = {
  width: string;
  height: string;
};

export const GoogleIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
    >
      <defs>
        <path
          d="M30,15 C30,23.2842712 23.2842712,30 15,30 C6.71572875,30 0,23.2842712 0,15 C0,6.71572875 6.71572875,0 15,0 C19.3988313,0 23.3554183,1.89347313 26.0991789,4.90983734 L22.7694252,7.93688614 C20.8487928,5.82543119 18.0791819,4.5 15,4.5 C9.20101013,4.5 4.5,9.20101013 4.5,15 C4.5,20.7989899 9.20101013,25.5 15,25.5 C19.756531,25.5 23.7743582,22.3372334 25.065182,18 L16.5,18 L16.5,13.5 L30,13.5 L30,15 Z"
          id="google-icon-path-1"
        ></path>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-131.000000, -215.000000)">
          <g transform="translate(42.000000, 190.000000)">
            <g>
              <g transform="translate(89.000000, 21.000000)">
                <g transform="translate(0.000000, 4.000000)">
                  <mask
                    id="google-icon-mask-2"
                    fill="white"
                  >
                    <use href="#google-icon-path-1"></use>
                  </mask>
                  <use
                    fill="#3984FF"
                    href="#google-icon-path-1"
                  ></use>
                  <polygon
                    fill="#FF4131"
                    mask="url(#google-icon-mask-2)"
                    points="-13.5 -10.5 43.5 -10.5 16.5 13.5 13.5 13.5"
                  ></polygon>
                  <polygon
                    fill="#25BA4C"
                    mask="url(#google-icon-mask-2)"
                    points="-13.5 36 34.5 36 12 12 13.5 12"
                  ></polygon>
                  <polygon
                    fill="#FFE500"
                    mask="url(#google-icon-mask-2)"
                    points="-13.5 -12 -13.5 36 13.5 12"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
