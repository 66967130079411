import { useState } from "react";
import { Box, Typography, IconButton, Switch, Menu, MenuItem } from "@mui/material";
import { InspectionFolderIcon, FolderIcon, OptionsIcon } from "components/atoms";
import { useUpdateFolderInspectionSetting, useShadowStyles } from "hooks";
import { isFolderMatchQuery } from "javascripts/utils";
import { DeleteFolder } from "../delete-folder";
import { DownloadReport } from "../download-report";
import { FolderForm } from "../folder-form";
import { MoveFolderGroup } from "../move-folder-group";
import type { FolderComponentProps } from "./type";

export const Folder = ({
  isEditable,
  isInspectable,
  folderGroup,
  folder,
  order,
  updateFolder,
  activeFolder,
  folderGroupList,
  query,
  isFolderGroupMatch,
  isFolderGroupFolderMatch,
  handleDownloadFolderReport,
  handleDownloadFolderReportLoading,
  handleDownloadFolderReportError,
}: FolderComponentProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [handleUpdateInspectionSetting] = useUpdateFolderInspectionSetting();
  const updateInspectionSetting = async () => {
    const enabled = folder.inspection_setting?.enabled || false;
    // NOTE: trueにしたらfalseにはできない
    if (enabled) {
      return;
    }

    const params = {
      inspection_setting: {
        enabled: true,
      },
    };
    await handleUpdateInspectionSetting(folder.uid, params);
  };

  const isActive = activeFolder?.id === folder.id;
  const shadowStyles = useShadowStyles({
    position: "right",
    backgroundColor: "primary",
  });

  const customStyleList = isActive
    ? {
        ml: 1,
        position: "relative",
        backgroundColor: "common.white",
        ...shadowStyles,
      }
    : {};

  // NOTE: 検索対象がグループであれば、グループに属するフォルダも検索対象になる
  const isMatch = isFolderMatchQuery(folder, query);
  // NOTE: 何も検索に引っかからなければ、表示しない
  if (!isMatch && !isFolderGroupMatch && !isFolderGroupFolderMatch) {
    return null;
  }
  // NOTE: フォルダ検索しているので、グループ出しつつ該当のフォルダだけ表示する
  if (!isFolderGroupMatch && isFolderGroupFolderMatch && !isMatch) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        cursor: "pointer",
        py: 1,
        pl: 1,
        ...customStyleList,
      }}
      data-folder-group-id={folderGroup?.id || null}
      data-folder-id={folder.id}
      data-order-id={order?.id || null}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
        onClick={() => updateFolder(folder)}
      >
        <Box>
          {folder.inspection_setting?.enabled ? (
            <InspectionFolderIcon
              color={isActive ? "common" : "primary"}
              width="20px"
              height="15px"
            />
          ) : (
            <FolderIcon
              color={isActive ? "common" : "primary"}
              width="20px"
              height="14px"
            />
          )}
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            ml: 1,
            wordBreak: "break-all",
          }}
        >
          {folder.name}
        </Typography>
      </Box>
      {isInspectable && (
        <Switch
          checked={folder.inspection_setting?.enabled || false}
          onChange={updateInspectionSetting}
          disabled={folder.inspection_setting?.enabled || false}
        />
      )}
      {isEditable && (
        <>
          <IconButton onClick={handleMenuClick}>
            <OptionsIcon
              color={"common"}
              width={"14px"}
              height={"11px"}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            <MenuItem onKeyDown={(e) => e.stopPropagation()}>
              <FolderForm folder={folder} />
            </MenuItem>
            <MenuItem onKeyDown={(e) => e.stopPropagation()}>
              <DownloadReport
                folder={folder}
                handleDownloadFolderReport={handleDownloadFolderReport}
                handleDownloadFolderReportLoading={handleDownloadFolderReportLoading}
                handleDownloadFolderReportError={handleDownloadFolderReportError}
              />
            </MenuItem>
            <MenuItem onKeyDown={(e) => e.stopPropagation()}>
              <MoveFolderGroup
                folder={folder}
                folderGroupList={folderGroupList}
              />
            </MenuItem>
            <MenuItem onKeyDown={(e) => e.stopPropagation()}>
              <DeleteFolder
                folder={folder}
                sx={{ p: 0 }}
              />
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
};
