import { get } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { AffiliateServiceProviderApiFetchAffiliateServiceProvidersResponse } from "./type";

const fetchAffiliateServiceProviders = async () =>
  get<{}, AffiliateServiceProviderApiFetchAffiliateServiceProvidersResponse>(
    {},
    `${DEFAULT_BASE_URL}/affiliate_service_providers`
  );

export const AffiliateServiceProviderApi = {
  fetchAffiliateServiceProviders,
} as const;
