import axios from "axios";
import { AbTestProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderAbTestResponse = {
  ab_tests: AbTestProps[];
};

class FolderAbTestApi extends ApiBase {
  static fetchAbTests(
    folderUid: string,
    reportStartDate = "",
    reportEndDate = "",
    with_product = false
  ) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderAbTestResponse>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/ab_tests`;
      axios
        .get(path, {
          params: {
            report_start_date: reportStartDate,
            report_end_date: reportEndDate,
            with_product: with_product,
          },
          withCredentials: true,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
  static createAbTest(folderUid: string, params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/ab_tests`;
      axios
        .post(path, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderAbTestApi;
