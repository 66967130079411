import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const OptionFilledIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
    >
      <path
        fill={iconColor}
        d="M58,32c0,14.359-11.641,26-26,26S6,46.359,6,32C6,17.641,17.641,6,32,6S58,17.641,58,32z M32,28c-2.209,0-4,1.791-4,4 c0,2.209,1.791,4,4,4s4-1.791,4-4C36,29.791,34.209,28,32,28z M19,28c-2.209,0-4,1.791-4,4c0,2.209,1.791,4,4,4s4-1.791,4-4 C23,29.791,21.209,28,19,28z M45,28c-2.209,0-4,1.791-4,4c0,2.209,1.791,4,4,4s4-1.791,4-4C49,29.791,47.209,28,45,28z"
      ></path>
    </svg>
  );
};
