import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  ShopifyPrivateApplication,
  useCreateShopifyPrivateApplication,
  useDeleteShopifyPrivateApplication,
  useShopifyPrivateApplicationList,
} from "hooks";

type ShopifyPrivateApplicationsProps = {
  isUpdatable: boolean;
  faqLink: string;
};

type FormValues = {
  base?: string;
  host: string;
  password: string;
};

export const ShopifyPrivateApplications = ({
  isUpdatable,
  faqLink,
}: ShopifyPrivateApplicationsProps) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, setError, setValue } = useForm<FormValues>();

  const [isOpen, setIsOpen] = useState(false);
  const [appList] = useShopifyPrivateApplicationList();
  const [createHandle, createError] = useCreateShopifyPrivateApplication();

  const [deleteHandle] = useDeleteShopifyPrivateApplication();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = (app: ShopifyPrivateApplication) => {
    deleteHandle(app);
  };

  const onSubmit = async (data: FormValues) => {
    await createHandle(data.host, data.password);
  };

  useEffect(() => {
    register("host", {
      required: t("を入力してください", { value: t("ホスト") }) || "",
    });
    register("password", {
      required: t("を入力してください", { value: t("パスワード") }) || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (createError) {
      setError("base", "auth", t("に失敗しました。", { value: t("登録") }) || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createError]);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleOpen}
        sx={{
          display: "block",
          width: "130px",
          mx: "auto",
        }}
      >
        {t("アプリ登録")}
      </Button>
      <Dialog
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          Shopify{t("アプリ登録")}
          <Link
            target={"_blank"}
            href={faqLink}
            sx={{
              fontWeight: "normal",
              float: "right",
              mr: 2,
            }}
          >
            {t("連携方法はこちら")}
          </Link>
        </DialogTitle>
        <DialogContent>
          {isUpdatable && (
            <Box>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Box
                  sx={{
                    display: "flex",
                    mb: 1,
                    pt: 1,
                  }}
                >
                  <TextField
                    type="text"
                    label={t("ホスト")}
                    placeholder="xxx.myshopify.com"
                    variant="outlined"
                    error={!!errors.host}
                    helperText={errors.host?.message}
                    onChange={(e) => setValue("host", e.target.value)}
                    sx={{
                      flex: 1,
                      mr: 2,
                    }}
                  />
                  <TextField
                    type="password"
                    label={t("Admin APIのアクセストークン")}
                    variant="outlined"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    onChange={(e) => setValue("password", e.target.value)}
                    sx={{
                      flex: 1,
                      mr: 2,
                    }}
                  />
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    type="submit"
                  >
                    {t("登録")}
                  </Button>
                </Box>
              </Box>
              {errors.base && (
                <Box sx={{ pt: 1, pb: 1 }}>
                  <Typography
                    variant="subtitle2"
                    color="error"
                  >
                    {errors.base?.message}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>

        <DialogTitle>{t("登録済みアプリ一覧")}</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table sx={{ minWidth: "100%" }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("ホスト")}</TableCell>
                  <TableCell>{t("登録日")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appList.map((app) => (
                  <TableRow key={app.id}>
                    <TableCell>{app.host}</TableCell>
                    <TableCell>{app.created_date}</TableCell>
                    <TableCell>
                      {isUpdatable && (
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => handleDelete(app)}
                        >
                          {t("削除")}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};
