import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestExitPopupPhotoAuthenticationApi extends ApiBase {

  static checkAuthetication(abTestUid) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/${abTestUid}/exit_popups/photos/authentications`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestExitPopupPhotoAuthenticationApi;
