import axios, { AxiosRequestConfig } from "axios";
import { generateDeliveryArchitectureErrorMessage } from "utils";

export class CustomError extends Error {
  data: any;
  status?: number;

  constructor(message?: string | string[], data?: any, status?: number) {
    const customMessage = Array.isArray(message) ? message.join("") : message;
    const errorReasons = data?.data?.error_reasons;

    if (errorReasons) {
      const deliveryArchitectureErrorMessage =
        generateDeliveryArchitectureErrorMessage(errorReasons);
      const joinedCustomMessages = `${customMessage}\n\n${deliveryArchitectureErrorMessage}`;
      super(joinedCustomMessages);
    } else {
      super(customMessage);
    }
    this.name = "CustomError";
    this.data = data;
    this.status = status;
  }
}

export type CustomErrorResponse = {
  response: {
    data: any;
    status?: number;
  };
};

export const get = async <T, U>(
  params: T,
  path: string,
  withCredentials = true,
  additionalHeaders: AxiosRequestConfig["headers"] | undefined = {},
): Promise<U> => {
  return new Promise<U>((resolve, reject) => {
    axios
      .get<U>(path, { params, withCredentials, headers: additionalHeaders })
      .then((response) => resolve(response.data))
      .catch((error: CustomErrorResponse) =>
        reject(new CustomError(error.response.data.message, error.response.data)),
      );
  });
};

export const post = async <T, U>(
  params: T,
  path: string,
  withCredentials = true,
  additionalHeaders: AxiosRequestConfig["headers"] | undefined = {},
): Promise<U> => {
  return new Promise<U>((resolve, reject) => {
    axios
      .post<U>(path, params, { withCredentials, headers: additionalHeaders })
      .then((response) => resolve(response.data))
      .catch((error: CustomErrorResponse) =>
        reject(
          new CustomError(error.response.data.message, error.response.data, error.response.status),
        ),
      );
  });
};

export const patch = async <T, U>(params: T, path: string, withCredentials = true): Promise<U> => {
  return new Promise<U>((resolve, reject) => {
    axios
      .patch<U>(path, params, { withCredentials })
      .then((response) => resolve(response.data))
      .catch((error: CustomErrorResponse) =>
        reject(new CustomError(error.response.data.message, error.response.data)),
      );
  });
};

export const put = async <T, U>(
  params: T,
  path: string,
  withCredentials = true,
  additionalHeaders: AxiosRequestConfig["headers"] | undefined = {},
): Promise<U> => {
  return new Promise<any>((resolve, reject) => {
    axios
      .put<U>(path, params, { withCredentials, headers: additionalHeaders })
      .then((response) => resolve(response))
      .catch((error: CustomErrorResponse) =>
        reject(new CustomError(error.response.data.message, error.response.data)),
      );
  });
};

//MEMO: delete is a reserved word. So, we use deleteRequest instead of delete.
export const deleteRequest = async <T, U>(
  params: T,
  path: string,
  withCredentials = true,
  additionalHeaders: AxiosRequestConfig["headers"] | undefined = {},
): Promise<U> => {
  return new Promise<U>((resolve, reject) => {
    axios
      .delete<U>(path, { params, withCredentials, headers: additionalHeaders })
      .then((response) => resolve(response.data))
      .catch((error: CustomErrorResponse) =>
        reject(new CustomError(error.response.data.message, error.response.data)),
      );
  });
};

export * from "./articles/htmlPart";
export * from "./articles/htmlPartCategory";
export * from "./teams/affiliateServiceProviders/connectionSetting";
export * from "./teams/members/affiliateServiceProviders";
export * from "./teams/members/affiliateServiceProviders/role";
export * from "./teams/member";
export * from "./users/settings/email";
export * from "./users/settings/email/confirmation";
export * from "./teams/members/invitations/user";
export * from "./teams/members/loyalty";
export * from "./users/team";
export * from "./users/password";
export * from "./users/registration";
export * from "./users/confirmation";
export * from "./users/emailVerification";
export * from "./abTest/archivedArticles";
export * from "./abTest/unarchivedArticles";
export * from "./stripe/customer-portal-session";
export * from "./squadbeyondToken";
export * from "./beyondDeLp/page";
export * from "./beyondDeLp/deliveryRate";
export * from "./abTest/splitTestSettings/oses";
export * from "./abTest/splitTestSettings/carriers";
