import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleProps } from "domains";
import { useLogging } from "hooks/logging";
import AbTestArticleFunnelStepApi from "javascripts/api/abTest/articles/funnelStep";

export const abTestArticleListWithFunnelStepListState = atom<ArticleProps[]>({
  key: "abTestArticleListWithFunnelStepListState",
  default: [],
});

export const useFetchAbTestArticleListWithFunnelStepList = (abTestUid: string) => {
  const { sendErrorLog } = useLogging();
  const [list, setList] = useRecoilState(abTestArticleListWithFunnelStepListState);

  useEffect(() => {
    AbTestArticleFunnelStepApi.fetchList(abTestUid)
      .then((data) => {
        setList(data.articles);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch abtest article list with funnel step list.",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid]);

  return [list] as const;
};
