import axios from 'axios';
import ApiBase from './apiBase';

class ContactApi extends ApiBase {

  static createContact(params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/contacts`;
      axios.post(path, params, { headers: { 'Content-Type': 'multipart/form-data' } , withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default ContactApi;
