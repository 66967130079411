import { useState, useEffect, useRef, useMemo, Fragment, Children } from 'react';
import ReactDOM from "react-dom";
import PropTypes from 'prop-types'
import { css_beautify, html_beautify } from 'js-beautify'


import articleHtmlPartModalStyles from 'stylesheets/components/article/htmlPart/modal.module.scss'
import resizerStyles from 'stylesheets/components/editor/resizer.module.scss'

import Description from 'javascripts/components/Article/HtmlPart/Description'
import { Resizer } from 'components/atoms/resizer'
import PreviewToolbar from 'javascripts/components/Article/HtmlPart/PreviewToolbar';
import { ItemWithInfoMark } from "components/atoms/item-with-info-mark";

import {
  ArticleHtmlPartHtmlForm,
  ArticleHtmlPartCssForm,
  BeyondEitorReplaceImageDropdown
} from 'components'

import {
  Box,
  IconButton,
  Tooltip
} from "@mui/material"

import CodeIcon from '@mui/icons-material/Code';

import { injectExternalStyles } from 'javascripts/utils';

const beautifyOptions = {
  indent_size: 4,
  end_with_newline: true,
  preserve_newlines: true,
  max_preserve_newlines: 0,
  wrap_line_length: 0,
  wrap_attributes_indent_size: 0,
  unformatted: ["a", "span", "img", "code", "pre", "sub", "sup", "em", "strong", "b", "i", "u", "strike", "big", "small", "pre", "h1", "h2", "h3", "h4", "h5", "h6"]
};
import { useTranslation } from "react-i18next";


function HtmlPartForm({
  selectedPart,
  partHtml,
  partCss,
  handlePartHtml,
  handlePartCss,
  article,
  action,
  partDescription,
  displayEditContentType,
  handleSelectionCondition,
  selection,
  selectionRange,
  displayType,
  articlePhoto,
  fetchArticlePhotos,
  handleArticlePhotos,
  fetchArticlePhotosOnEditor,
  updateArticlePhotoPublishStatus,
  handlePartDescription,
  setSelectedPart
 } ) {
  const { t } = useTranslation();
  const [operateDropDown, changeOperateDropDown] = useState({ type: '' , isShow: false })
  const [isCollapsed, handleIsCollapsed] = useState(false)
  const [operateFontOption, changeOperateFontOption] = useState({ type: '' , isShow: false })
  const [editType, handleEditType] = useState('html')
  const [isFormResize, handleIsFormResize] = useState(false)
  const [isVerticalResize, handleIsVerticalResize] = useState(false)
  const [isAttachOptionComponents, handleIsAttachOptionComponents] = useState(false)
  const [codeType, setCodeType] = useState('custom')

  const htmlEditorRef = useRef()
  const cssEditorRef = useRef()
  const iframeRef = useRef()

  useEffect(() => {
    document.addEventListener('mouseup', function(e) {
      handleIsFormResize(false)
      handleIsVerticalResize(false)
      let partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe')
      if (partHtmlPreviewAreaIframe) partHtmlPreviewAreaIframe.style.pointerEvents = 'all'
    })

    return function cleanup() {
      document.removeEventListener('mouseup', function(e) {
        handleIsFormResize(false)
        handleIsVerticalResize(false)
        let partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe')
        if (partHtmlPreviewAreaIframe) partHtmlPreviewAreaIframe.style.pointerEvents = 'all'
      })

    }
  }, [])

  useEffect(() => {
    if (!htmlEditorRef.current) return
    htmlEditorRef.current.ref.addEventListener('focusout', handleBlur)
  }, [partHtml])
  useEffect(() => {
    if (!cssEditorRef.current) return
    cssEditorRef.current.ref.addEventListener('focusout', handleBlur)
  }, [partCss])

  useEffect(() => {
    let partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe')
    if (!partHtmlPreviewAreaIframe.contentDocument.querySelector('.htmlValue')) return
    if (document.activeElement.id === 'partHtmlPreviewAreaIframe') return
    partHtmlPreviewAreaIframe.contentDocument.querySelector('.htmlValue').innerHTML = partHtml
  }, [partHtml])

  useEffect(() => {
    let partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe')
    if (!partHtmlPreviewAreaIframe.contentDocument.body.querySelector('style')) return
    partHtmlPreviewAreaIframe.contentDocument.body.querySelector('style').innerText = partCss
  }, [partCss])


  const handleBlur = (e) => {
    if (e.currentTarget.classList.contains('articlePartHtmlForm')) {
      clearSelection(htmlEditorRef)
    }
    if (e.currentTarget.classList.contains('articlePartCssForm')) {
      clearSelection(cssEditorRef)
    }
  }


  const clearSelection = (ref) => {
    if (!ref.current.editor.getSelection()) {
      return
    }
    let cursor = ref.current.editor.getCursor()
    let lastLine = ref.current.editor.lastLine()
    if (cursor.line === lastLine) {
      ref.current.editor.setCursor(0, 0, 'silent')
    } else {
      ref.current.editor.setCursor(cursor.line, 0, 'silent')
    }
  }

  const resizeForm = (e) => {
    let htmlForm = document.querySelector(`.${articleHtmlPartModalStyles.partHtmlFormArea}`)
    let htmlFormRect = htmlForm.getBoundingClientRect()
    let cssForm = document.querySelector(`.${articleHtmlPartModalStyles.partCssFormArea}`)
    let cssFormRect = cssForm.getBoundingClientRect()
    let formHeight = Math.floor(cssFormRect.bottom - htmlFormRect.top)
    let htmlFormHeight = Math.floor(e.clientY - htmlFormRect.top) / formHeight * 100
    htmlForm.setAttribute('style', `height: ${htmlFormHeight}%;`)
    cssForm.style.height = `${100 - htmlFormHeight}%`
  }

  const resizeVertical = (e) => {
    let partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe')
    partHtmlPreviewAreaIframe.style.pointerEvents = 'none'
    let partHtmlEditableWrapper = document.querySelector(`.${articleHtmlPartModalStyles.partHtmlEditableWrapper}`)
    let partHtmlEditableWrapperRect = partHtmlEditableWrapper.getBoundingClientRect()
    let partHtmlPreviewArea = document.querySelector(`.${articleHtmlPartModalStyles.partHtmlPreviewArea}`)
    let partFormArea = document.querySelector(`.${articleHtmlPartModalStyles.partFormArea}`)
    let previewAreaWidth = (e.clientX - 70) / (partHtmlEditableWrapperRect.width - 40) * 100
    partHtmlPreviewArea.style.width = `${previewAreaWidth}%`
    partFormArea.style.width = `${100 - previewAreaWidth}%`
  }

  const handleMouseUp = (e) => {
    handleIsCollapsed(selection.isCollapsed)
    handleSelectionCondition()
  }

  const handleMouseMove = (e) => {
    if (!isFormResize) return
    resizeForm(e)
  }

  const handleMouseDown = () => {
    handleIsFormResize(true)
  }

  const handleMouseMoveVerticalResizer = (e) => {
    if (!isVerticalResize) return
    resizeVertical(e)
  }

  const handleMouseDownVerticalResize = () => {
    handleIsVerticalResize(true)
  }

  const handleMouseLeave = () => {
    if (selectionRange && selectionRange.startContainer && !['IMG', 'VIDEO'].includes(selectionRange.startContainer.tagName)) {
      handleSelectionCondition()
      handleIsCollapsed(selection.isCollapsed)
    }
  }
  const handleContentBlur = () => {
    if (selectionRange && selectionRange.startContainer && !['IMG', 'VIDEO'].includes(selectionRange.startContainer.tagName)) {
      let  imageResizerWrapper = document.querySelector(`#partHtmlPreviewAreaIframe`).contentDocument.querySelector(`.${resizerStyles.imageResizable}`);
      if (imageResizerWrapper) imageResizerWrapper.style.display = 'none'
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 8) {
      let blockTagName = ["ADDRESS", "BLOCKQUOTE", "CENTER","DIV", "DL", "FIELDSET"," FORM", "H1", "H2", "H3", "H4", "H5", "H6", "HR", "NOFRAMES", "NOSCRIPT", "OL", "P", "PRE", "TABLE", "UL"]
      if (blockTagName.includes(selection.anchorNode.tagName)) {
        e.preventDefault()
        return
      }
      if (selection.anchorNode && selection.anchorNode.firstChild && selection.anchorNode.firstChild.nodeName === 'BR') {
        e.preventDefault()
      }
      if (e.target.querySelector('.htmlValue').textContent.length === 1) {
        e.preventDefault()
        let text = t('を入力してください', { value: t("文字") })
        selection.anchorNode.nodeValue = text
        selectionRange.selectNode(selectionRange.startContainer)
        return
      }
    }
    //keyCode 13: enter
    if (e.keyCode === 13) {
      e.preventDefault()
    }
    if (e.which === 65 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()
    }
  }

  const handleKeyUp = () => {
    handleSelectionCondition()
    let htmlValue = document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.querySelector('.htmlValue')
    htmlEditorRef.current.editor.setValue(html_beautify(htmlValue.innerHTML, beautifyOptions))
  }

  const handleMouseOut = () => {
    if (selectionRange && selectionRange.startContainer && !['IMG', 'VIDEO'].includes(selectionRange.startContainer.tagName)) {
      handleSelectionCondition()
    }
  }

  //codemirror × mui（主にPopper）のレンダリング問題があるのでメモ化
  //tsに置き換える際に書き換えるの一時的なもの
  const HtmlEditor = useMemo(() => {
    return (
      <ArticleHtmlPartHtmlForm
        selectedPart={selectedPart}
        html={codeType === 'custom' ? partHtml : selectedPart.original_html}
        htmlEditorRef={htmlEditorRef}
        codemirrorTheme="ayu-dark"
        theme="dark"
        action={action}
        codeType={codeType}
        handleHtml={codeType === 'custom' ? (e) => handlePartHtml(e) : undefined}
        setSelectedPart={setSelectedPart}
       />
    )
  }, [partHtml, selectedPart && selectedPart.comments, codeType, displayType, editType])

  const CssEditor = useMemo(() => {
    return (
      <ArticleHtmlPartCssForm
        selectedPart={selectedPart}
        css={codeType === 'custom' ? partCss : selectedPart.original_css}
        codemirrorTheme="ayu-dark"
        theme="dark"
        cssEditorRef={cssEditorRef}
        action={action}
        codeType={codeType}
        setSelectedPart={setSelectedPart}
        handleCss={codeType === 'custom' ? (e) => handlePartCss(e) : undefined}
      />
    )
  }, [partCss, selectedPart && selectedPart.comments, codeType, displayType, editType])


  return (
    <Fragment>
      <div className={articleHtmlPartModalStyles.partHtmlEditableWrapper} onMouseMove={(e) => handleMouseMoveVerticalResizer(e)}>
        <div className={articleHtmlPartModalStyles.partHtmlPreviewArea}>
          <PreviewToolbar
            operateFontOption={operateFontOption}
            operateDropDown={operateDropDown}
            isCollapsed={isCollapsed}
            htmlEditorRef={htmlEditorRef}
            article={article}
            articlePhoto={articlePhoto}
            selection={selection}
            selectionRange={selectionRange}
            changeOperateDropDown={(e) => changeOperateDropDown(e)}
            changeOperateFontOption={(e) => changeOperateFontOption(e)}
            handlePartHtml={(htmlValue) => handlePartHtml(htmlValue)}
            handleArticlePhotos={(data) => handleArticlePhotos(data)}
            fetchArticlePhotos={(searchRange, articleUid) => fetchArticlePhotos(searchRange, articleUid)}
            fetchArticlePhotosOnEditor={() => fetchArticlePhotosOnEditor()}
            updateArticlePhotoPublishStatus={(photoArticleUid, photoId) => updateArticlePhotoPublishStatus(photoArticleUid, photoId)}
          />
          <Iframe
            partHtml={partHtml}
            partCss={partCss}
            iframeRef={iframeRef}
            masterStyleSheet={article.master_style_sheet}
            handleIsAttachOptionComponents={handleIsAttachOptionComponents}
          >
            <>
              <div contentEditable='true'
                className={articleHtmlPartModalStyles.partHtmlPreview}
                onMouseDown={() => handleSelectionCondition()}
                onMouseUp={(e) => handleMouseUp(e)}
                onMouseLeave={(e) => handleMouseLeave(e)}
                onKeyUp={() => handleKeyUp()}
                onKeyDown={(e) => handleKeyDown(e)}
                onBlur={(e) => handleContentBlur(e)}
                onDragStart={(e) => e.preventDefault()}
                onMouseOut={() => handleMouseOut()}
                style={{ padding: '10px', overflow: 'scroll' }}
                dangerouslySetInnerHTML={{__html: `<div class='htmlValue article-body'></div>` + `<style></style>`}}
                id='htmlPartPreview'>
              </div>
              {isAttachOptionComponents &&
                <>
                  <Resizer
                    iframeId={'partHtmlPreviewAreaIframe'}
                    scrollContainer={'#htmlPartPreview'}
                    clickTargetElementClass={articleHtmlPartModalStyles.partHtmlPreview}
                    selection={selection}
                    updateEditorCondition={(targetRange) => handleSelectionCondition(targetRange)}
                    handlePartHtml={(htmlValue) => handlePartHtml(htmlValue)}
                    />
                </>
              }
            </>
          </Iframe>
          {isAttachOptionComponents &&
            <BeyondEitorReplaceImageDropdown
              theme={"darkTheme"}
              article={article}
              articlePhoto={articlePhoto}
              moveAction={true}
              iframeId={'partHtmlPreviewAreaIframe'}
              clickTargetElementSelector={'#htmlPartPreview'}
              selection={selection}
              fetchArticlePhotos={(searchRange, articleUid) => fetchArticlePhotos(searchRange, articleUid)}
              updateEditorCondition={(targetRange) => handleSelectionCondition(targetRange)}
              handleArticlePhotos={(data) => handleArticlePhotos(data)}
              handlePartHtml={(htmlValue) => handlePartHtml(htmlValue)}
              fetchArticlePhotosOnEditor={() => fetchArticlePhotosOnEditor()}
              updateArticlePhotoPublishStatus={(photoArticleUid, photoId) => updateArticlePhotoPublishStatus(photoArticleUid, photoId)}
            />
          }
        </div>
        {displayEditContentType === 'code' ?
          <>
            <div className={articleHtmlPartModalStyles.verticalResizer} onMouseDown={() => handleMouseDownVerticalResize()} onMouseUp={() => handleIsFormResize(false)}><div onMouseDown={() => handleMouseDownVerticalResize()} className={articleHtmlPartModalStyles.verticalLine}></div></div>
            <div className={articleHtmlPartModalStyles.partFormArea} onMouseMove={(e) => handleMouseMove(e)}>
              {displayType === 'splitDisplay' &&
                <div className={articleHtmlPartModalStyles.changeFormTabs}>
                  <div className={`${articleHtmlPartModalStyles.formTab} ${editType === 'html' && articleHtmlPartModalStyles.active}`} onClick={() => handleEditType('html')}>HTML</div>
                  <div className={`${articleHtmlPartModalStyles.formTab} ${editType === 'css' && articleHtmlPartModalStyles.active}`} onClick={() => handleEditType('css')}>CSS</div>
                </div>
              }
              <Box
                sx={{
                  width: "100%",
                  height: displayType === 'allDisplay' ? "50%" : "100%",
                  overflow: "hidden",
                  "&.active": {
                    height: "100% !important"
                  },
                  "& .react-codemirror2": {
                    width: "95%",
                    height: displayType === 'allDisplay' ? "calc(100% - 40px);" : "calc(100% - 10px)",
                  },
                  "&.hide": {
                    opacity: "0",
                    height: "0px !important"
                  },
                }}
                className={`${displayType === 'splitDisplay' && editType === 'css' ? "hide" : ""} ${displayType === 'splitDisplay' && editType === 'html' ? "active" : ""} ${articleHtmlPartModalStyles.partHtmlFormArea}`}
              >
                {displayType === 'allDisplay' &&
                  <div className={`${articleHtmlPartModalStyles.partHtmlEditableTitle}`}>
                    <div className={articleHtmlPartModalStyles.titleLeft}>
                      <ItemWithInfoMark
                        descriptionVerticalPosition={'bottom'}
                        descriptionHorizontalPosition={'rightCenter'}
                        descriptionWidth={200}
                      >
                        <span>HTML{action === "create" ? "" : (codeType === "original" ? "(オリジナル)" : "(カスタム)")}</span>
                        <a href='https://drive.google.com/file/d/1TDMhsFHYBjQ7gOKv72EvpgY1pzZPApr4/view?usp=sharing' target='_blank'>
                          {t("リンク追加方法")}
                        </a>
                      </ItemWithInfoMark>
                      {action === "insert" && selectedPart.original_html &&
                        <Tooltip PopperProps={{ sx: { zIndex: 100000 } }} title={t("オリジナルのソースコードを見ることができます")} arrow>
                          {codeType === 'custom' ?
                            <IconButton onClick={() => setCodeType('original')}>
                              <CodeIcon fontSize="small" color="primary"/>
                            </IconButton>
                            :
                            <IconButton onClick={() => setCodeType('custom')}>
                              <CodeIcon fontSize="small" sx={{ color: "common.white", backgroundColor: "primary.main", borderRadius: "50%" }}/>
                            </IconButton>
                          }
                        </Tooltip>
                      }
                    </div>
                  </div>
                }
                  <Box
                    sx={{
                      backgroundColor: "common.black",
                      height: displayType === 'splitDisplay' ? "calc(100% - 40px)" : "100%",
                    }}
                  >
                    {HtmlEditor}
                </Box>
              </Box>
              {displayType === 'allDisplay' &&
                <div className={articleHtmlPartModalStyles.formResizer} onMouseDown={(e) => handleMouseDown()} onMouseUp={() => handleIsFormResize(false)} ><span onMouseDown={() => handleMouseDown()}></span></div>
              }
              <Box
                sx={{
                  width: "100%",
                  height: displayType === 'allDisplay' ? "50%" : "100%",
                  overflow: "hidden",
                  "&.active": {
                    height: "100% !important"
                  },
                  "& .react-codemirror2": {
                    width: "95%",
                    height: displayType === 'allDisplay' ? "calc(100% - 60px)" : "calc(100% - 10px)"
                  },
                  "&.hide": {
                    opacity: "0",
                    height: "0px !important"
                  },
                }}
                className={`${displayType === 'splitDisplay' && editType === 'html' ? "hide" : ""} ${displayType === 'splitDisplay' && editType === 'css' ? "active" : ""} ${articleHtmlPartModalStyles.partCssFormArea}`}
              >
                {displayType === 'allDisplay' &&
                  <div className={`${articleHtmlPartModalStyles.partHtmlEditableTitle}`}>
                    <div className={articleHtmlPartModalStyles.titleLeft}>
                      CSS{action === "create" ? "" : (codeType === "original" ? `(${t("オリジナル")})` : `(${t("カスタム")})`)}
                      {action === "insert" && selectedPart.original_css &&
                        <Tooltip PopperProps={{ sx: { zIndex: 100000 } }} title={t("オリジナルのソースコードを見ることができます")} arrow>
                          {codeType === 'custom' ?
                            <IconButton onClick={() => setCodeType('original')}>
                              <CodeIcon fontSize="small" color="primary"/>
                            </IconButton>
                            :
                            <IconButton onClick={() => setCodeType('custom')}>
                              <CodeIcon fontSize="small" sx={{ color: "common.white", backgroundColor: "primary.main", borderRadius: "50%" }}/>
                            </IconButton>
                          }
                        </Tooltip>
                      }
                    </div>
                  </div>
                }
                <Box
                  sx={{
                    backgroundColor: "common.black",
                    height: displayType === 'splitDisplay' ? "calc(100% - 40px)" : "100%",
                  }}
                >
                  {CssEditor}
                </Box>
              </Box>
            </div>
          </>
          :
          <Description
            partDescription={partDescription}
            handlePartDescription={handlePartDescription}
          />
        }

      </div>
    </Fragment>
  )
}

const Iframe = ({
  children,
  partHtml,
  partCss,
  iframeRef,
  masterStyleSheet,
  handleIsAttachOptionComponents
}) => {
  const [containerElement, setContainerElement] = useState(document.createElement('div'))

  useEffect(() => {
    const iframe = iframeRef.current.contentWindow || iframeRef.current.contentDocument.document || iframeRef.current.contentDocument;
    iframeRef.current.contentDocument.body.appendChild(containerElement);
    let masterStyles = `<style>${setMasterStyleSheet()}</style>`
    let selectorStyles = `<style>
        .htmlValue video {
          max-width: 100%;
          height: auto !important;
        }
        .htmlValue img {
          max-width: 100%;
          height: auto !important;
        }
      </style>`
    injectExternalStyles(iframe.document)
    let htmlPartPreview = iframe.document.querySelector('#htmlPartPreview')
    iframe.document.head.insertAdjacentHTML('afterbegin', masterStyles)
    iframe.document.head.insertAdjacentHTML('afterbegin', selectorStyles)
    iframe.document.head.insertAdjacentHTML('afterbegin', document.head.innerHTML)
    iframe.document.body.style.backgroundColor = '#ffffff'
    iframe.document.body.style.paddingRight = '15px'
    htmlPartPreview.style.padding = '10px'
    htmlPartPreview.style.overflow = 'scroll'
    iframe.document.querySelector('.htmlValue').innerHTML = partHtml
    iframe.document.body.querySelector('style').innerText = partCss
    iframe.document.addEventListener('mouseover', function() {
      handleIsAttachOptionComponents(true)
    })
  }, [])

  const setMasterStyleSheet = () => {
    return (
        `#htmlPartPreview {
            font-size: ${masterStyleSheet.font_size}px !important;
            font-family: ${masterStyleSheet.font_family} !important;
            color: #${masterStyleSheet.color} !important;
            line-height: ${masterStyleSheet.line_height} !important;
            letter-spacing: ${masterStyleSheet.letter_spacing}px !important;
          }
        #htmlPartPreview  img {
           margin-top: ${masterStyleSheet.img_margin_top}px !important; margin-bottom: ${masterStyleSheet.img_margin_bottom}px !important; display: block !important;
        }
        .sb-fs-10 { font-size: 10px; } .sb-fs-13 { font-size: 13px; } .sb-fs-15 { font-size: 15px; } .sb-fs-17 { font-size: 17px; } .sb-fs-19 { font-size: 19px; } .sb-fs-21 { font-size: 21px; } .sb-fs-23 { font-size: 23px; } .sb-fs-25 { font-size: 25px; } .sb-fs-27 { font-size: 27px; } .sb-fs-29 { font-size: 29px; }
        `
     )
  }

  return (
    <iframe ref={iframeRef} id={'partHtmlPreviewAreaIframe'} className={articleHtmlPartModalStyles.partHtmlPreviewAreaIframe} >
      {ReactDOM.createPortal(Children.only(children), containerElement)}
    </iframe>
  );
}

HtmlPartForm.propTypes = {
  articlePhoto: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired,
  partHtml: PropTypes.string.isRequired,
  partCss: PropTypes.string,
  selection: PropTypes.any,
  selectedRange: PropTypes.any,
  displayType: PropTypes.string.isRequired,
  displayEditContentType: PropTypes.string.isRequired,
  handleSelectionCondition: PropTypes.func.isRequired,
  handlePartHtml: PropTypes.func.isRequired,
  handlePartCss: PropTypes.func.isRequired,
  fetchArticlePhotos: PropTypes.func.isRequired,
  handleArticlePhotos: PropTypes.func.isRequired,
  fetchArticlePhotosOnEditor: PropTypes.func.isRequired,
  updateArticlePhotoPublishStatus: PropTypes.func.isRequired,
}

export default HtmlPartForm
