import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { PhotoProps } from "domains";
import ArticlePhotoApi from "javascripts/api/articles/photo";

export type FetchArticlePhotoListProps = (searchRange: string, articleUid: string) => void;

export type ArticlePhotoListProps = {
  items: Array<PhotoProps>;
  nextPage: number;
};

const articlePhotoListState = atom<ArticlePhotoListProps>({
  key: "articlePhotoListState1",
  default: {
    items: [],
    nextPage: 1,
  },
});

export type HandleFetchArticlePhotoListProps = (
  articleUid: string,
  page: number,
  searchRange: string
) => void;
export const useFetchArticlePhotoList = () => {
  const [articlePhotoList, setArticlePhotoList] = useRecoilState(articlePhotoListState);

  const handle: HandleFetchArticlePhotoListProps = useCallback(
    async (articleUid, page, searchRange) => {
      try {
        const items = page === 1 ? [] : articlePhotoList.items;
        const response = await ArticlePhotoApi.fetchArticlePhotos(articleUid, page, searchRange);
        setArticlePhotoList({
          items: [...items, ...response.photos],
          nextPage: response.next_page,
        });
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articlePhotoList.items]
  );
  return [handle] as const;
};

export const useArticlePhotoList = (articleUid: string, isInitialize = false) => {
  const [articlePhotoList, setArticlePhotoList] = useRecoilState(articlePhotoListState);
  const [handle] = useFetchArticlePhotoList();

  useEffect(() => {
    if (isInitialize) return;
    handle(articleUid, 1, "all");
  }, []);

  return [articlePhotoList, setArticlePhotoList] as const;
};
