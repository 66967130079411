import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { AlertTextForMedia, EyeIcon, Theme } from "components";
import {
  ArticleInspectionRequestProps,
  ArticleInspectionRequestStatusProps,
  ArticleProps,
} from "domains";
import {
  HandleCreateArticleInspectionRequestProps,
  HandleDeleteArticleLatestInspectionRequestProps,
  useArticleLatestInspectionRequest,
  useCreateArticleInspectionRequest,
  useDeleteArticleLatestInspectionRequest,
} from "hooks";
import { fetchInspectionStatuses } from "javascripts/utils";

const inspectionStatuses: ArticleInspectionRequestStatusProps = fetchInspectionStatuses();

type InspectionRequestDescriptionFormValues = {
  base?: string;
  description: string;
};

type InspectionRequestDialogProps = {
  article: ArticleProps;
  selectedArticleLatestInspectionRequest: ArticleInspectionRequestProps;
  onCreateInspectionRequest: HandleCreateArticleInspectionRequestProps;
  updateSelectedArticleInspectionRequest: (request: ArticleInspectionRequestProps) => void;
};

const InspectionRequestDialog: React.FC<InspectionRequestDialogProps> = ({
  article,
  selectedArticleLatestInspectionRequest,
  onCreateInspectionRequest,
  updateSelectedArticleInspectionRequest,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { handleSubmit, register, errors, setValue } =
    useForm<InspectionRequestDescriptionFormValues>();

  useEffect(() => {
    register("description", {
      required: t("を入力してください", { value: t("変更した項目") }) || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  const onSubmit = async (data: InspectionRequestDescriptionFormValues) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await onCreateInspectionRequest(article.uid, data.description);
    updateSelectedArticleInspectionRequest(selectedArticleLatestInspectionRequest);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleClickOpen}
      >
        {t("審査申請する")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="darkGrayishBlue"
                >
                  {t("閉じる")}
                </Button>
              </Box>
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "700",
                    fontSize: "18px",
                  }}
                >
                  {t("審査リクエスト")}
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {t("審査に出す")}
                </Button>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            {errors.base && (
              <Box sx={{ pt: 1, pb: 1 }}>
                <Typography
                  variant="subtitle2"
                  color="error"
                >
                  {errors.base?.message}
                </Typography>
              </Box>
            )}
            <TextField
              label={t("変更した項目")}
              multiline
              rows={10}
              sx={{
                minWidth: "500px",
              }}
              autoFocus
              error={!!errors.description}
              helperText={errors.description?.message}
              onChange={(e) => setValue("description", e.target.value)}
            />
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

type InspectionButtonProps = {
  selectedArticleLatestInspectionRequest: ArticleInspectionRequestProps;
  hasRequest: boolean;
  setAnchorElement: (anchorElement: null | HTMLElement) => void;
};

const InspectionButton: React.FC<InspectionButtonProps> = ({
  selectedArticleLatestInspectionRequest,
  hasRequest,
  setAnchorElement,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const inspectionButtonTheme = {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "unset",
        },
      },
    },
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const getInspectionStatus = (status: string) => {
    return inspectionStatuses[status as keyof ArticleInspectionRequestStatusProps];
  };

  return (
    <ThemeProvider theme={{ ...theme, ...inspectionButtonTheme }}>
      {!hasRequest ||
      ["approved", "rejected"].includes(selectedArticleLatestInspectionRequest.status) ? (
        <Button
          variant="contained"
          color={"primary"}
          onClick={handleClick}
        >
          {t("審査申請前")}
        </Button>
      ) : (
        <Button
          variant="contained"
          color={"primary"}
          onClick={handleClick}
        >
          {getInspectionStatus(selectedArticleLatestInspectionRequest.status)}
        </Button>
      )}
    </ThemeProvider>
  );
};

type InspectionMenuProps = {
  anchorElement: null | HTMLElement;
  article: ArticleProps;
  selectedArticleLatestInspectionRequest: ArticleInspectionRequestProps;
  hasRequest: boolean;
  handleClose: () => void;
  setAnchorElement: (anchorElement: null | HTMLElement) => void;
  onCreateInspectionRequest: HandleCreateArticleInspectionRequestProps;
  onDeleteLatestInspectionRequest: HandleDeleteArticleLatestInspectionRequestProps;
  updateSelectedArticleInspectionRequest: (request: ArticleInspectionRequestProps) => void;
};

const InspectionMenu: React.FC<InspectionMenuProps> = ({
  anchorElement,
  article,
  hasRequest,
  selectedArticleLatestInspectionRequest,
  handleClose,
  setAnchorElement,
  onCreateInspectionRequest,
  onDeleteLatestInspectionRequest,
  updateSelectedArticleInspectionRequest,
}) => {
  const { t } = useTranslation();
  const open = Boolean(anchorElement);
  const theme = useTheme();

  const inspectionMenuTheme = {
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "unset",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            padding: "10px",
            "& .MuiMenuItem-root": {
              padding: "5px 10px",
              pointerEvents: "none",
              cursor: "default",
              ":not(.Mui-selected)": {
                backgroundColor: "transparent",
              },
              "&.Mui-selected": {
                backgroundColor: "#ffa400",
                borderRadius: "5px",
                "& .MuiTypography-root": {
                  fontWeight: "bold",
                },
                "&:after": {
                  content: '"✔︎"',
                },
              },
            },
          },
        },
      },
    },
  };

  const statuses = (
    Object.keys(inspectionStatuses) as (keyof ArticleInspectionRequestStatusProps)[]
  )
    .filter((status) => ["requesting", "inspecting"].includes(status))
    .map((key) => {
      const isSelected = selectedArticleLatestInspectionRequest.status === key;
      return (
        <MenuItem
          key={key}
          selected={isSelected}
          disableRipple
        >
          <EyeIcon
            color={"white"}
            width={"20px"}
            height={"20px"}
          />
          <Typography sx={{ mx: 0.5 }}>{inspectionStatuses[key]}</Typography>
        </MenuItem>
      );
    });

  const deleteLatestInspectionRequest = () => {
    onDeleteLatestInspectionRequest(article.uid);
    updateSelectedArticleInspectionRequest(selectedArticleLatestInspectionRequest);
    setAnchorElement(null);
  };

  return (
    <ThemeProvider theme={{ ...theme, ...inspectionMenuTheme }}>
      <Paper>
        <Menu
          anchorEl={anchorElement}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: "grey.400",
              mb: 0.5,
              fontSize: "12px",
            }}
          >
            {t("審査ステータス")}
          </Typography>
          <MenuItem
            selected={
              !hasRequest ||
              ["approved", "rejected"].includes(selectedArticleLatestInspectionRequest.status)
            }
            disableRipple
          >
            <EyeIcon
              color={"white"}
              width={"20px"}
              height={"20px"}
            />
            <Typography sx={{ mx: 0.5 }}>{t("審査申請前")}</Typography>
          </MenuItem>
          {statuses}
          {(!hasRequest ||
            ["approved", "rejected"].includes(selectedArticleLatestInspectionRequest.status)) && (
            <InspectionRequestDialog
              article={article}
              selectedArticleLatestInspectionRequest={selectedArticleLatestInspectionRequest}
              onCreateInspectionRequest={onCreateInspectionRequest}
              updateSelectedArticleInspectionRequest={updateSelectedArticleInspectionRequest}
            />
          )}
          {!article.is_under_inspection &&
            hasRequest &&
            !["approved", "rejected"].includes(selectedArticleLatestInspectionRequest.status) && (
              <>
                <Button
                  variant="text"
                  color="error"
                  fullWidth
                  onClick={deleteLatestInspectionRequest}
                >
                  {t("審査申請を取り消す")}
                </Button>
                <Typography
                  sx={{ maxWidth: "150px" }}
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="error.main"
                >
                  {t("※審査開始前であれば申請を取り消すことが可能です")}
                </Typography>
              </>
            )}
        </Menu>
      </Paper>
    </ThemeProvider>
  );
};

type InspectionProps = {
  article: ArticleProps;
  mode: string;
  updateSelectedArticleInspectionRequest: (request: ArticleInspectionRequestProps) => void;
};

const Inspection: React.FC<InspectionProps> = ({
  article,
  mode,
  updateSelectedArticleInspectionRequest,
}) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [selectedArticleLatestInspectionRequest] = useArticleLatestInspectionRequest(article.uid);
  const [createInspectionRequest] = useCreateArticleInspectionRequest();
  const [deleteLatestInspectionRequest] = useDeleteArticleLatestInspectionRequest();
  const hasRequest = Object.keys(selectedArticleLatestInspectionRequest).length !== 0;

  useEffect(() => {
    updateSelectedArticleInspectionRequest(selectedArticleLatestInspectionRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArticleLatestInspectionRequest]);

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Box>
      <Theme mode={mode}>
        <InspectionButton
          selectedArticleLatestInspectionRequest={selectedArticleLatestInspectionRequest}
          hasRequest={hasRequest}
          setAnchorElement={setAnchorElement}
        />
      </Theme>
      <Theme mode="dark">
        <InspectionMenu
          anchorElement={anchorElement}
          article={article}
          selectedArticleLatestInspectionRequest={selectedArticleLatestInspectionRequest}
          hasRequest={hasRequest}
          handleClose={handleClose}
          setAnchorElement={setAnchorElement}
          onCreateInspectionRequest={createInspectionRequest}
          onDeleteLatestInspectionRequest={deleteLatestInspectionRequest}
          updateSelectedArticleInspectionRequest={updateSelectedArticleInspectionRequest}
        />
      </Theme>
    </Box>
  );
};

type ReflectionButtonProps = {
  isLoading: boolean;
  reflectionArticleBody: () => void;
  translatedText?: string;
  disabled?: Pick<LoadingButtonProps, "disabled">["disabled"];
};

export const ReflectionButton: React.FC<ReflectionButtonProps> = ({
  isLoading,
  reflectionArticleBody,
  translatedText,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const loadingButtonTheme = {
    components: {
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            boxShadow: "unset",
            "&.Mui-disabled": {
              backgroundColor: "rgba(0, 0, 0, 0.12) !important",
            },
          },
          loadingIndicator: {
            color: "rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={{ ...theme, ...loadingButtonTheme }}>
      <Tooltip
        arrow
        title={<AlertTextForMedia />}
      >
        <LoadingButton
          color="primary"
          loading={isLoading}
          variant="contained"
          onClick={reflectionArticleBody}
          disabled={disabled}
        >
          {!translatedText ? t("更新") : translatedText}
        </LoadingButton>
      </Tooltip>
    </ThemeProvider>
  );
};

type ArticleReflectionProps = {
  article: ArticleProps;
  isLoading: boolean;
  isNotYetReflected: boolean;
  isBodyUpdated: boolean;
  isFolderInspectable: boolean;
  hasInspectionAuthority: boolean;
  reflectionArticleBody: () => void;
  updateSelectedArticleInspectionRequest: (request: ArticleInspectionRequestProps) => void;
};

export const ArticleReflection: React.FC<ArticleReflectionProps> = ({
  article,
  isLoading,
  isNotYetReflected,
  isBodyUpdated,
  isFolderInspectable,
  hasInspectionAuthority,
  reflectionArticleBody,
  updateSelectedArticleInspectionRequest,
}) => {
  const mode = isNotYetReflected || isBodyUpdated ? "dark" : "light";
  const canUpdateWithoutInspection =
    hasInspectionAuthority && !article.is_requesting_inspection && !article.is_under_inspection;

  if (isFolderInspectable && !canUpdateWithoutInspection) {
    return (
      <Inspection
        article={article}
        mode={mode}
        updateSelectedArticleInspectionRequest={updateSelectedArticleInspectionRequest}
      />
    );
  }

  return (
    <Theme mode={mode}>
      <ReflectionButton
        isLoading={isLoading}
        reflectionArticleBody={reflectionArticleBody}
        translatedText=""
      />
    </Theme>
  );
};
