import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Link, Popover, Switch, Tooltip, Typography } from "@mui/material";
import {
  BeyondPageArticleOptionList,
  SplitTestSettingInfoForNonBoostContractor,
  SplitTestSettingInfoForNonBoostSetting,
  Theme,
} from "components";
import { LocalNavigation } from "components/organisms/core/LocalNavigation";
import { useAlert } from "contexts/alert";
import { AbTestProps, ArticleProps, ArticleSplitTestOsAllowListsProps } from "domains";
import {
  useAbTestDeliveryArchitectureSetting,
  useCreateAbTestSplitTestSettingOsesArticleList,
  useDestroyAbTestSplitTestSettingOsesArticleList,
} from "hooks";
import Nav from "javascripts/components/Editor/Nav";
import SaveAnimation, { SaveAnimationHandler } from "javascripts/components/Partials/SaveAnimation";
import ChannelService from "javascripts/services/ChannelService";

const SwitchButton: React.FC<{
  articleUid: string;
  osId: number;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  onCreateOs: Function;
  onDestroyOs: Function;
  isDisabled: boolean;
}> = ({ articleUid, osId, isChecked, setIsChecked, onCreateOs, onDestroyOs, isDisabled }) => {
  const { clearAlerts } = useAlert();

  type Params = {
    os_id: number;
  };
  const handleCheck = useCallback(async (params: Params) => {
    const response = await onCreateOs(articleUid, params);
    if (response) {
      setIsChecked(true);
    }
  }, []);

  const handleUnCheck = useCallback(async (params: Params) => {
    const response = await onDestroyOs(articleUid, params);
    if (response) {
      setIsChecked(false);
    }
  }, []);

  const handleChange = useCallback(() => {
    clearAlerts();
    const params = {
      os_id: osId,
    };
    if (isChecked) {
      handleUnCheck(params);
    } else {
      handleCheck(params);
    }
  }, []);

  return (
    <Switch
      checked={isChecked}
      disabled={isDisabled}
      sx={{
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "primary.contrastText",
        },
      }}
      onChange={handleChange}
    />
  );
};

const Article: React.FC<{
  article: ArticleProps;
  isCanUseBoostMode: boolean;
}> = ({ article }) => {
  const [iosId, androidId] = [1, 2];
  const [isAndroidAllowed, setIsAndroidAllowed] = useState<boolean>(false);
  const [isIosAllowed, setIsIosAllowed] = useState<boolean>(false);
  const { showAlerts } = useAlert();

  const [handleCreateOs, createOsLoading, createOsError] =
    useCreateAbTestSplitTestSettingOsesArticleList();
  const [handleDestroyOs, destroyOsLoading, destroyOsError] =
    useDestroyAbTestSplitTestSettingOsesArticleList();

  useEffect(() => {
    if (createOsError?.message) {
      showAlerts({ messages: [createOsError.message], theme: "danger" });
    }
  }, [createOsError]);

  useEffect(() => {
    if (destroyOsError?.message) {
      showAlerts({
        messages: [destroyOsError.message],
        theme: "danger",
      });
    }
  }, [destroyOsError]);

  useEffect(() => {
    const existsIosAllowlist =
      article.split_test_os_allowlists.find(
        (allowlist: ArticleSplitTestOsAllowListsProps) => allowlist.os_id === iosId,
      ) !== undefined;
    const existsAndroidAllowlist =
      article.split_test_os_allowlists.find(
        (allowlist: ArticleSplitTestOsAllowListsProps) => allowlist.os_id === androidId,
      ) !== undefined;

    setIsIosAllowed(existsIosAllowlist);
    setIsAndroidAllowed(existsAndroidAllowlist);
  }, []);

  const SwitchButtonContent: React.FC<{
    isAllowed: boolean;
    setIsAllowed: React.Dispatch<React.SetStateAction<boolean>>;
    osId: number;
  }> = ({ isAllowed, setIsAllowed, osId }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "46px",
        }}
      >
        <SwitchButton
          articleUid={article.uid}
          osId={osId}
          isChecked={isAllowed}
          setIsChecked={setIsAllowed}
          onCreateOs={handleCreateOs}
          onDestroyOs={handleDestroyOs}
          isDisabled={createOsLoading || destroyOsLoading}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ bgcolor: "gray" }}>
      <Box
        sx={{
          pr: 1.4,
          pb: 2,
          bgcolor: "background.primary",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            bgcolor: "background.secondary",
            borderRadius: "0 10px 10px 0",
          }}
        >
          <SwitchButtonContent
            isAllowed={isAndroidAllowed}
            setIsAllowed={setIsAndroidAllowed}
            osId={androidId}
          />
          <SwitchButtonContent
            isAllowed={isIosAllowed}
            setIsAllowed={setIsIosAllowed}
            osId={iosId}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ArticleTitle: React.FC<{
  title: string;
  abTestRate: number;
  // FIXME
  // eslint-disable-next-line react/display-name
}> = memo(({ title, abTestRate }) => {
  return (
    <Tooltip
      title={<Typography sx={{ fontSize: "15px" }}>{title}</Typography>}
      placement="top"
    >
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            fontSize: "14px",
            height: "46px",
            lineHeight: "46px",
            px: 1.4,
            py: 1,
            boxSizing: "border-box",
            bgcolor: "lightGray",
            borderRadius: "10px 0 0 10px",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              flex: 1,
              color: "gray",
              textAlign: "right",
            }}
          >
            {abTestRate}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
});

const Oses: React.FC<{
  articleList: ArticleProps[];
  abTestUid: string;
  isCanUseBoostMode: boolean;
  folderUid: string | undefined;
}> = ({ articleList, abTestUid, isCanUseBoostMode, folderUid }) => {
  const { t } = useTranslation();
  const [setting, isCloudflareSettingLoading] = useAbTestDeliveryArchitectureSetting(abTestUid);
  const isCloudflareSetting = setting && setting.is_cloudflare;

  if (articleList === null || isCloudflareSettingLoading) {
    return null;
  }

  // FIXME
  // eslint-disable-next-line react/display-name
  const ArticleTitlesHeader: React.FC = memo(() => {
    return (
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            px: 1.4,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              fontSize: "12px",
            }}
          >
            {t("Version")}
          </Typography>
          <Typography
            sx={{
              color: "gray",
              fontSize: "12px",
              textAlign: "right",
            }}
          >
            {t("配信割合")}
          </Typography>
        </Box>
      </Box>
    );
  });

  const DescriptionPopover = ({
    isOpen,
    anchorEl,
    handleClose,
  }: {
    isOpen: boolean;
    anchorEl: HTMLButtonElement | null;
    handleClose: () => void;
  }) => {
    const { t } = useTranslation();

    return (
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: 1.6 }}
      >
        <Box
          sx={{
            width: "444px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            px: 2.4,
            py: 1.6,
            bgcolor: "background.contrast",
            color: "black",
            lineHeight: "140%",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
            }}
          >
            {t("PCでも表示させたい場合")}
          </Typography>
          <IconButton
            onClick={handleClose}
            disableRipple
            disableFocusRipple
            sx={{
              color: "black",
              position: "absolute",
              right: "24px",
              p: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Typography sx={{ fontSize: "14px" }}>
              {t(`モバイルOS別出し分け設定では、モバイルOSのみ指定可能です。`)}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {t(`したがって、"ONにした場合はPCからのアクセスでは表示されない"設定となります。`)}
            </Typography>
          </Box>
          <Typography sx={{ fontSize: "14px" }}>
            {t(
              `同一URLでPCにも配信したい場合、PC用のVersionを用意し、該当のVersionに対しては"OS指定はすべてOFF"で設定してください。`,
            )}
          </Typography>
          <Box>
            <Typography sx={{ fontSize: "14px" }}>
              {t("詳しい設定方法は")}
              <Link
                href="https://knowledge.squadbeyond.com/improve-ad-effect/test-ab-pages/change-lp-byos"
                target="_blank"
                sx={{ color: "#2196F3", textDecoration: "none" }}
              >
                {t("こちら")}
              </Link>
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {t("チャットサポートですぐ確認したい方は")}
              <Link
                onClick={() => ChannelService.showMessenger()}
                sx={{ color: "#2196F3", textDecoration: "none", cursor: "pointer" }}
              >
                {t("こちら")}
              </Link>
            </Typography>
          </Box>
        </Box>
      </Popover>
    );
  };

  // FIXME
  // eslint-disable-next-line react/display-name
  const ArticlesHeader: React.FC = memo(() => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const isOpen = Boolean(anchorEl);

    const { t } = useTranslation();
    const osTitleStyle = {
      color: "primary.contrastText",
      fontSize: "12px",
      fontWeight: "600",
    };

    return (
      <Box
        sx={{
          flex: 1,
          pb: 1.4,
          pr: 2,
          bgcolor: "background.primary",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <Box
          sx={{
            color: "primary.contrastText",
            fontSize: "14px",
            fontWeight: "600px",
            pt: 2,
            pl: 2,
            pb: 1.6,
          }}
        >
          {t("モバイルOS別")}
        </Box>
        {!isCanUseBoostMode ? (
          <SplitTestSettingInfoForNonBoostContractor
            category={"モバイルOS別出し分け"}
            body={"モバイルOS別出し分けの利用希望"}
          />
        ) : !isCloudflareSetting ? (
          <SplitTestSettingInfoForNonBoostSetting
            abTestUid={abTestUid}
            folderUid={folderUid}
          />
        ) : (
          <>
            <Box
              sx={{
                pl: 2,
                pb: 2,
                display: "flex",
                justifyContent: "space-between",
                color: "gray",
                fontSize: "14px",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "14px" }}>
                  {t(
                    "ONに設定したOSからアクセスした場合のみ表示されます。モバイルOS（スマホ、タブレット含む）のみ指定が可能です。",
                  )}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {t("ON指定した場合、PCでアクセスしても表示されなくなります。ご注意ください。")}
                </Typography>
              </Box>
              <Box>
                <Button
                  onClick={handleClick}
                  disableRipple
                  disableFocusRipple
                  sx={{
                    color: "#2196F3",
                    mr: 2,
                    p: 0,
                    fontWeight: "500",
                    lineHeight: "normal",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                >
                  {t("PCでも表示させたい場合")}
                </Button>
              </Box>
              <DescriptionPopover
                isOpen={isOpen}
                anchorEl={anchorEl}
                handleClose={handleClose}
              />
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  textAlign: "center",
                  mr: 1,
                  gap: 1.5,
                }}
              >
                <Typography sx={osTitleStyle}>{t("Android")}</Typography>
                <Typography sx={osTitleStyle}>{t("iOS")}</Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  });

  const articleTitles = articleList.map((article: ArticleProps) => {
    return (
      <ArticleTitle
        key={article.id}
        title={article.memo}
        abTestRate={article.ab_test_article.rate}
      />
    );
  });

  const articles = articleList.map((article: ArticleProps) => {
    return (
      <Article
        key={article.id}
        article={article}
        isCanUseBoostMode={isCanUseBoostMode}
      />
    );
  });

  return (
    <Theme mode={"dark"}>
      <Box
        sx={{
          display: "flex",
          m: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex" }}>
            <ArticleTitlesHeader />
            <ArticlesHeader />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "230px" }}>{articleTitles}</Box>
            <Box
              sx={{
                flex: 1,
                bgcolor: "background.primary",
                minHeight: "300px",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              {isCanUseBoostMode && isCloudflareSetting && articles}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "190px" }}>
          <BeyondPageArticleOptionList
            type={"oses"}
            abTestUid={abTestUid}
          />
        </Box>
      </Box>
    </Theme>
  );
};

export const AbTestArticleSplitTestSettingOsesContainer: React.FC<{
  onFetchAbTest: Function;
  abTest?: AbTestProps;
  abTestUid: string;
  articleList: ArticleProps[];
  isCanUseBoostMode: boolean;
}> = ({ onFetchAbTest, abTest, abTestUid, articleList, isCanUseBoostMode }) => {
  const existsAbTest = abTest && Object.keys(abTest).length > 0;
  const saveAnimationRef = useRef<SaveAnimationHandler>(null);

  useEffect(() => {
    onFetchAbTest(abTestUid);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <LocalNavigation
        pathName={location.pathname}
        abTestUid={abTest?.uid}
        folderUid={abTest?.folder?.uid}
      />
      <Box sx={{ width: 1 }}>
        <SaveAnimation ref={saveAnimationRef} />
        {existsAbTest && (
          <>
            <Nav
              abTest={abTest}
              folder={abTest.folder}
              page={"splitTestSetting"}
              article={null}
            />
            <Oses
              articleList={articleList}
              abTestUid={abTestUid}
              isCanUseBoostMode={isCanUseBoostMode}
              folderUid={abTest.folder?.uid}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
