import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class FolderAbTestMoveApi extends ApiBase {
  static create(folderUid, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(`${baseUrl}/folders/${folderUid}/ab_tests/moves`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default FolderAbTestMoveApi;
