import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestArticleExitPopupReflectionApi extends ApiBase {

  static update(abTestUid, exitPopupId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.patch(`${baseUrl}/ab_tests/${abTestUid}/exit_popups/${exitPopupId}/reflections`, {}, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestArticleExitPopupReflectionApi;
