import { post } from "api";
import { DEFAULT_BASE_URL } from "const/app";

export type CustomerPortalSessionResponse = {
  url: string;
};

const createSession = () => {
  return post<{}, CustomerPortalSessionResponse>(
    {},
    `${DEFAULT_BASE_URL}/stripe/customer_portal_sessions`,
    true,
  );
};

export const StripeCustomerPortalSessionApi = {
  createSession,
} as const;
