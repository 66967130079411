import { useTheme } from "@mui/material/styles";
import { ColorList } from "domains";

export type CustomSvgIconProps = {
  color: ColorList;
};

export const useCustomIconColor = (selectedColor: ColorList): string => {
  const theme = useTheme();

  let color = "";
  if (selectedColor === "common") {
    color = theme.palette.common.black;
  } else if (selectedColor === "white") {
    color = theme.palette.common.white;
  } else if (selectedColor === "grey") {
    color = theme.palette.grey[500];
  } else {
    color = theme.palette[selectedColor].main;
  }
  return color;
};
