import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Box, Link, Typography } from "@mui/material";
import { ClockIcon, CarrierIcon, DeviceIcon, OsIcon, ParameterIcon } from "components";
import { ColorList } from "domains";
import { useMember, useShadowStyles } from "hooks";

const ArticleOptionItem: React.FC<{
  title: string;
  Icon: ReactElement;
  isSelected: boolean;
}> = ({ title, Icon, isSelected }) => {
  const shadowStyles = useShadowStyles({
    position: "left",
    backgroundColor: "primary",
  });
  const selectedItemStyle = isSelected
    ? {
        color: "primary.contrastText",
        backgroundColor: "background.primary",
        borderRadius: "0 10px 10px 0",
        ...shadowStyles,
      }
    : {};

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        pl: 2,
        py: 2,
        ...selectedItemStyle,
      }}
    >
      {Icon}
      <Typography
        component="span"
        sx={{
          pl: 2,
          fontSize: "12px",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export const BeyondPageArticleOptionList: React.FC<{
  type: string;
  abTestUid: string;
}> = ({ type, abTestUid }) => {
  const { t } = useTranslation();
  const { memberAttributes } = useMember();
  const isBygones = !!memberAttributes?.isBygones;
  const optionWrapperTitleStyle = {
    fontSize: "12px",
    my: 1,
    pl: 2,
  };
  const iconStyle: {
    width: string;
    height: string;
    color: ColorList;
    selectedColor: ColorList;
  } = {
    width: "20px",
    height: "20px",
    color: "grey",
    selectedColor: "white",
  };
  const isSelectedOption = (selectedType: string) => (selectedType === type ? true : false);

  return (
    <Box
      sx={{
        position: "sticky",
        top: "18px",
        left: "0",
        zIndex: 9,
        mt: 1.8,
      }}
    >
      <Box>
        <Typography sx={optionWrapperTitleStyle}>{t("Version出し分け")}</Typography>
        <Link
          href={`/ab_tests/${abTestUid}/articles/split_test_settings/devices`}
          underline={"none"}
          color={"none"}
        >
          <ArticleOptionItem
            title={t("デバイス別")}
            Icon={
              <DeviceIcon
                color={isSelectedOption("device") ? iconStyle.selectedColor : iconStyle.color}
                width={iconStyle.width}
                height={iconStyle.height}
              />
            }
            isSelected={isSelectedOption("device")}
          />
        </Link>
        <Link
          href={`/ab_tests/${abTestUid}/articles/split_test_settings/params`}
          underline={"none"}
          color={"none"}
        >
          <ArticleOptionItem
            title={t("パラメーター別")}
            Icon={
              <ParameterIcon
                color={isSelectedOption("params") ? iconStyle.selectedColor : iconStyle.color}
                width={iconStyle.width}
                height={iconStyle.height}
              />
            }
            isSelected={isSelectedOption("params")}
          />
        </Link>
        <Link
          href={`/ab_tests/${abTestUid}/articles/split_test_settings/hours`}
          underline={"none"}
          color={"none"}
        >
          <ArticleOptionItem
            title={t("時間別")}
            Icon={
              <ClockIcon
                color={isSelectedOption("hours") ? iconStyle.selectedColor : iconStyle.color}
                width={iconStyle.width}
                height={iconStyle.height}
              />
            }
            isSelected={isSelectedOption("hours")}
          />
        </Link>
        <Link
          href={`/ab_tests/${abTestUid}/articles/split_test_settings/periods`}
          underline={"none"}
          color={"none"}
        >
          <ArticleOptionItem
            title={t("日付別")}
            Icon={
              <DateRangeIcon
                width={iconStyle.width}
                height={iconStyle.height}
                sx={{
                  color: isSelectedOption("periods") ? "white" : "grey",
                }}
              />
            }
            isSelected={isSelectedOption("periods")}
          />
        </Link>
        <Link
          href={`/ab_tests/${abTestUid}/articles/split_test_settings/oses`}
          underline={"none"}
          color={"none"}
        >
          <ArticleOptionItem
            title={t("モバイルOS別")}
            Icon={
              <OsIcon
                color={isSelectedOption("oses") ? iconStyle.selectedColor : iconStyle.color}
                width={iconStyle.width}
                height={iconStyle.height}
              />
            }
            isSelected={isSelectedOption("oses")}
          />
        </Link>
        {isBygones && (
          <Link
            href={`/ab_tests/${abTestUid}/articles/split_test_settings/carriers`}
            underline={"none"}
            color={"none"}
          >
            <ArticleOptionItem
              title={t("キャリア別")}
              Icon={
                <CarrierIcon
                  color={isSelectedOption("carriers") ? iconStyle.selectedColor : iconStyle.color}
                  width={iconStyle.width}
                  height={iconStyle.height}
                />
              }
              isSelected={isSelectedOption("carriers")}
            />
          </Link>
        )}
      </Box>
    </Box>
  );
};
