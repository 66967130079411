import React from "react";

type Props = {
  width: string;
  height: string;
};

export const LoglyIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-402.000000, -100.000000)">
          <g transform="translate(402.000000, 100.000000)">
            <rect
              fill="#0068B6"
              x="0"
              y="0"
              width="24"
              height="24"
            ></rect>
            <path
              d="M19,14 L18.9148925,14.085 L18.8912959,14.2355932 C18.307732,17.5122825 15.4443848,20 12,20 C8.82936074,20 6.15107217,17.8919961 5.29018925,15.0010432 L6.34165389,15.0006859 C7.16552033,17.3307123 9.38781865,19 12,19 C14.6121555,19 16.8344356,17.3307454 17.6583216,15.0007551 L16,15 L16,14 L19,14 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M6,4 L6,11 L9,11 L9,12 L5,12 L5,4 L6,4 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M16,4 C18.209139,4 20,5.790861 20,8 C20,10.209139 18.209139,12 16,12 C13.790861,12 12,10.209139 12,8 C12,5.790861 13.790861,4 16,4 Z M16,5 C14.3431458,5 13,6.34314575 13,8 C13,9.65685425 14.3431458,11 16,11 C17.6568542,11 19,9.65685425 19,8 C19,6.34314575 17.6568542,5 16,5 Z"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
