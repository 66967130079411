import i18next from "i18next";

// 住所、生年月日などをまとめたもの
export const processedNames = {
  inquery: i18next.t(`お問い合わせ`),
  fullname: i18next.t(`氏名`),
  fullname_ruby: i18next.t(`フリガナ(${i18next.t("氏名")})`),
  name: i18next.t(`姓名`),
  name_ruby: i18next.t(`フリガナ(${i18next.t("姓名")})`),
  email: i18next.t(`メールアドレス`),
  gender: i18next.t(`性別`),
  gender_other_detail: i18next.t(`その他の詳細（${i18next.t("性別")}）`),
  birthday: i18next.t(`生年月日`),
  street_address: i18next.t(`住所`),
  medium: i18next.t(`認知経路`),
  medium_other_detail: i18next.t(`その他の詳細（${i18next.t("認知経路")}）`),
  industry: i18next.t(`業種`),
  industry_other_detail: i18next.t(`その他の詳細（${i18next.t("業種")}）`),
  occupation: i18next.t(`職業`),
  occupation_other_detail: i18next.t(`その他の詳細（${i18next.t("職業")}）`),
  prefecture: i18next.t(`都道府県`),
  prefecture_other_detail: i18next.t(`その他の詳細（${i18next.t("都道府県")}）`),
  employee_num: i18next.t(`従業員数`),
  employee_num_other_detail: i18next.t(`その他の詳細（${i18next.t("従業員数")}）`),
  telephone_number: i18next.t(`電話番号`),
  terms: i18next.t(`利用規約`),
};
