import axios from "axios";
import {
  FodlerFormContactColumnProps,
  FodlerFormContactMasterColumnProps,
  FodlerFormContactUserColumnProps,
} from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderFormContactUserColumnResponse = {
  master_columns: FodlerFormContactMasterColumnProps[];
  default_columns: FodlerFormContactMasterColumnProps[];
  user_master_columns: FodlerFormContactMasterColumnProps[];
  user_columns: FodlerFormContactUserColumnProps[];
  custom_columns: FodlerFormContactColumnProps[];
};

class FolderFormContactUserColumnApi extends ApiBase {
  static fetchColumns(params: { folder_uid: string; form_uid: string }) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderFormContactUserColumnResponse>(function (resolve, reject) {
      axios
        .get(
          `${baseUrl}/folders/forms/contacts/user_columns?folder_uid=${params["folder_uid"]}&form_uid=${params["form_uid"]}`,
          { withCredentials: true }
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }
  static createColumns(params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .post(`${baseUrl}/folders/forms/contacts/user_columns`, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderFormContactUserColumnApi;
