import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { MediaApi } from "api/medias";
import { MediaProps } from "domains";
import { useLogging } from "hooks";

const mediaListState = atom<MediaProps[]>({
  key: "mediaListState",
  default: [],
});

export const useMediaList = () => {
  const { sendErrorLog } = useLogging();
  const [mediaList, setMediaList] = useRecoilState(mediaListState);

  useEffect(() => {
    MediaApi.fetchMedias()
      .then((data) => {
        setMediaList(data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch medias",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [mediaList] as const;
};
