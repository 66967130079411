export const AWS = {
  COGNITO: {
    REGION: "ap-northeast-1",
    IDENTITY_POOL_ID: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
    USER_POOL_ID: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    USER_POOL_CLEINT_ID: import.meta.env.VITE_COGNITO_USER_POOL_CLEINT_ID,
  },
  S3: {
    REGION: "ap-northeast-1",
    BUCKET_NAME: import.meta.env.VITE_BUCKET_NAME,
  },
};
