import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ConversionTagProps } from "domains";
import { useLogging } from "hooks";
import FolderConversionTagApi from "javascripts/api/folders/conversionTag";

export const conversionTagListState = atom<ConversionTagProps[]>({
  key: "conversionTagListState",
  default: [],
});

export const useFolderConversionTagList = (folderUid: string) => {
  const { sendErrorLog } = useLogging();
  const [conversionTagList, setConversionTagList] = useRecoilState(conversionTagListState);

  useEffect(() => {
    if (folderUid === "" || folderUid === null) {
      return;
    }

    FolderConversionTagApi.fetchConversionTags(folderUid)
      .then((data) => {
        setConversionTagList(data.conversion_tags);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch conversion tags",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderUid]);

  return [conversionTagList] as const;
};
