import axios from "axios";
import { FolderProps } from "domains";
import ApiBase from "./apiBase";

type FetchFoldersResponse = {
  folders: FolderProps[];
};

type FetchFolderResponse = FolderProps;

class FolderApi extends ApiBase {
  static fetchFolders(params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFoldersResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/folders`, { params, withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }

  static fetch(uid: string) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/folders/${uid}`, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }

  static createFolders(params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .post(`${baseUrl}/folders`, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static update(uid: string, name: string) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .patch(`${baseUrl}/folders/${uid}`, { folder: { name: name } }, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static destroy(uid: string) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${uid}`;
      axios
        .delete(path, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderApi;
