import { useTranslation } from "react-i18next";
import { useAlert } from "contexts/alert";
import buildPanelStyles from "stylesheets/components/forms/panels/build.module.scss";

import listStyles from "stylesheets/components/partials/list.module.scss";
import { ElementObject } from "utils/convert-html-to-object/type";
import { optionsMap } from "./const";
import { DraggableBlock } from "./draggable-block";
import { BuildPanelProps } from "./type";

export const generateUid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

const BuildPanel = ({
  blocks,
  customBlockLength,
  formRef,
  setBlocks,
  setMovingBlockIndex,
  updateStacks,
}: BuildPanelProps) => {
  const { t } = useTranslation();
  const alertState = useAlert();

  // NOTE：末尾のブロック（送信ボタンを想定）の手前に新規ブロックを追加
  const addBlock = (blockName: keyof typeof optionsMap) => {
    const exceptBLockNameList = [
      "headingParagraph",
      "heading",
      "paragraph",
      "button",
      "submitButton",
    ];
    const targetBlocks = blocks.filter(
      (block) => block.name && !exceptBLockNameList.includes(block.name)
    );
    if (targetBlocks.length === 50 && !exceptBLockNameList.includes(blockName))
      return alertState.showAlerts({
        messages: [t("1つのフォームに追加できるブロックの数は50個までです")],
        theme: "danger",
      });
    const options = Object.assign({}, optionsMap[blockName]);

    const attributesBlock = {
      id: generateUid(),
      name: blockName,
      type: blockName,
      options,
    } as ElementObject; // HACK: Block毎のoptionsの型解決が難しいため指定している

    if (
      Object.keys(customBlockLength).includes(blockName) &&
      "customName" in attributesBlock.options
    ) {
      attributesBlock.options["customName"] = `${blockName}_${
        customBlockLength[blockName as keyof typeof customBlockLength] + 1
      }`;
    }
    let newBlockList: Array<ElementObject> = [
      ...blocks.slice(0, blocks.length - 1),
      attributesBlock,
      blocks[blocks.length - 1],
    ];
    newBlockList = newBlockList.flatMap((d) => (d !== undefined ? [d] : []));

    //プリセットブロックは各フォームに一つだけ追加可能
    const addedPresetBlockNames = newBlockList
      .filter((block) => "type" in block.options && block.options.type === "preset")
      .map((block) => block.name);
    const isDuplicatePresetBlock =
      addedPresetBlockNames.filter(function (x, i, self) {
        return self.indexOf(x) !== self.lastIndexOf(x);
      }).length > 0;
    if (isDuplicatePresetBlock) {
      return alertState.showAlerts({
        messages: [t("1つのフォームに追加できるプリセットブロックは各種一つまでです")],
        theme: "danger",
      });
    }
    updateStacks();
    setBlocks(newBlockList);
    formRef.current?.scrollIntoView({ block: "end" });
  };

  const generateBLockList = (type: "preset" | "custom") => {
    return Object.entries(optionsMap)
      .filter((option) => option.at(1) && "type" in option[1] && option[1].type === type)
      .map(([k, _v]) => {
        return (
          <DraggableBlock
            key={k}
            name={k}
            blocks={blocks}
            addBlock={addBlock}
            setMovingBlockIndex={setMovingBlockIndex}
          />
        );
      });
  };

  return (
    <div className={buildPanelStyles.panel}>
      <div className={buildPanelStyles.title}>{t("ブロックを追加")}</div>
      <div className={buildPanelStyles.heading}>{t("プリセットブロック")}</div>
      <div className={buildPanelStyles.contents}>
        <ul className={`${buildPanelStyles.stackList} ${listStyles.unstyled}`}>
          {generateBLockList("preset")}
        </ul>
      </div>
      <div className={buildPanelStyles.heading}>{t("カスタムブロック")}</div>
      <div className={buildPanelStyles.contents}>
        <ul className={`${buildPanelStyles.stackList} ${listStyles.unstyled}`}>
          {generateBLockList("custom")}
        </ul>
      </div>
    </div>
  );
};

export default BuildPanel;
