import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { TRACKIDS } from "const/trackid";
import { AdStatusChipAbTest } from "./type";

const baseStatusButtonStyle = {
  color: "#000",
  fontSize: "11px",
  fontWeight: 400,
  height: "24px",
  borderRadius: "16px",
  minWidth: "50px",
};

const hoverStyle = {
  "&:hover": {
    backgroundColor: "#dfdfdf",
  },
};

const Preparation: React.FC<{
  abTest: AdStatusChipAbTest;
  editAbTestHandle?: Function;
  clickable?: boolean;
}> = ({ abTest, editAbTestHandle, clickable }) => {
  const { t } = useTranslation();
  const handle = async () => {
    if (editAbTestHandle === undefined) {
      return;
    }
    const params = {
      ab_test: {
        ad_status: "prepared",
      },
    };

    await editAbTestHandle(abTest.uid, params);
  };

  const baseColor = "#dfdfdf";

  return (
    <Button
      sx={{
        ...baseStatusButtonStyle,
        ...hoverStyle,
        border: `1px solid ${baseColor}`,
        backgroundColor: baseColor,
        ":disabled": {
          backgroundColor: baseColor,
          color: "#000",
        },
      }}
      disabled={!clickable}
      onClick={handle}
      data-trackid={TRACKIDS.beyondPageAdStatusChipPreparation}
    >
      {t("準備中")}
    </Button>
  );
};

const Undelivery: React.FC<{
  abTest: AdStatusChipAbTest;
  editAbTestHandle?: Function;
  clickable?: boolean;
}> = ({ abTest, editAbTestHandle, clickable }) => {
  const { t } = useTranslation();
  const handle = async () => {
    if (editAbTestHandle === undefined) {
      return;
    }
    const params = {
      ab_test: {
        ad_status: "undelivered",
      },
    };

    await editAbTestHandle(abTest.uid, params);
  };

  return (
    <Button
      sx={{
        ...baseStatusButtonStyle,
        border: "1px solid #0086ff",
        backgroundColor: "#fff",
        ":disabled": {
          backgroundColor: "#fff",
          color: "#000",
        },
      }}
      disabled={!clickable}
      onClick={handle}
      data-trackid={TRACKIDS.beyondPageAdStatusChipUndelivery}
    >
      {t("未配信")}
    </Button>
  );
};

const Delivery: React.FC<{
  abTest: AdStatusChipAbTest;
  editAbTestHandle?: Function;
  clickable?: boolean;
}> = ({ abTest, editAbTestHandle, clickable }) => {
  const { t } = useTranslation();
  const handle = async () => {
    if (editAbTestHandle === undefined) {
      return;
    }
    const params = {
      ab_test: {
        ad_status: "delivered",
      },
    };

    await editAbTestHandle(abTest.uid, params);
  };

  const baseBgColor = "#0086ff";
  const baseColor = "#fff";

  return (
    <Button
      sx={{
        ...baseStatusButtonStyle,
        ...hoverStyle,
        color: baseColor,
        ":hover": {
          color: baseColor,
        },
        border: `1px solid ${baseBgColor}`,
        backgroundColor: baseBgColor,
        ":disabled": {
          backgroundColor: baseBgColor,
          color: baseColor,
        },
      }}
      disabled={!clickable}
      onClick={handle}
      data-trackid={TRACKIDS.beyondPageAdStatusChipDelivery}
    >
      {t("配信中")}
    </Button>
  );
};

const Stop: React.FC<{
  abTest: AdStatusChipAbTest;
  editAbTestHandle?: Function;
  clickable?: boolean;
}> = ({ abTest, editAbTestHandle, clickable }) => {
  const { t } = useTranslation();
  const handle = async () => {
    if (editAbTestHandle === undefined) {
      return;
    }
    const params = {
      ab_test: {
        ad_status: "stopping",
      },
    };

    await editAbTestHandle(abTest.uid, params);
  };

  const baseColor = "#f8de7e";

  return (
    <Button
      sx={{
        ...baseStatusButtonStyle,
        ...hoverStyle,
        border: `1px solid ${baseColor}`,
        ":disabled": {
          backgroundColor: baseColor,
          color: "#000",
        },
      }}
      disabled={!clickable}
      onClick={handle}
      data-trackid={TRACKIDS.beyondPageAdStatusChipStop}
    >
      {t("停止中")}
    </Button>
  );
};

const Finish: React.FC<{
  abTest: AdStatusChipAbTest;
  editAbTestHandle?: Function;
  clickable?: boolean;
}> = ({ abTest, editAbTestHandle, clickable }) => {
  const { t } = useTranslation();
  const handle = async () => {
    if (editAbTestHandle === undefined) {
      return;
    }
    const params = {
      ab_test: {
        ad_status: "finished",
      },
    };

    await editAbTestHandle(abTest.uid, params);
  };

  const baseColor = "#f8de7e";

  return (
    <Button
      sx={{
        ...baseStatusButtonStyle,
        ...hoverStyle,
        border: `1px solid ${baseColor}`,
        backgroundColor: baseColor,
        ":disabled": {
          backgroundColor: baseColor,
          color: "#000",
        },
      }}
      onClick={handle}
      disabled={!clickable}
      data-trackid={TRACKIDS.beyondPageAdStatusChipFinish}
    >
      {t("終了")}
    </Button>
  );
};

const AdStatusChip: React.FC<{
  abTest: AdStatusChipAbTest;
  clickable: boolean;
}> = ({ abTest, clickable }) => {
  switch (abTest.ad_status) {
    case "prepared":
      return (
        <Preparation
          abTest={abTest}
          clickable={clickable}
        />
      );
    case "undelivered":
      return (
        <Undelivery
          abTest={abTest}
          clickable={clickable}
        />
      );
    case "delivered":
      return (
        <Delivery
          abTest={abTest}
          clickable={clickable}
        />
      );
    case "stopping":
      return (
        <Stop
          abTest={abTest}
          clickable={clickable}
        />
      );
    case "finished":
      return (
        <Finish
          abTest={abTest}
          clickable={clickable}
        />
      );
    default:
      return null;
  }
};

export const BeyondPageAdStatus: React.FC<{
  abTest: AdStatusChipAbTest;
  editAbTestHandle?: Function;
}> = ({ abTest, editAbTestHandle }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (editAbTestHandle === undefined) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickable: boolean = editAbTestHandle != undefined;

  return (
    <>
      <Box onClick={handleClick}>
        <AdStatusChip
          abTest={abTest}
          clickable={clickable}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Typography
          sx={{ fontSize: "12px", mb: 1 }}
          align="center"
        >
          {t("※管理用ステータスのため、実際の配信には影響しません")}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <MenuItem
            onClick={handleClose}
            data-trackid={TRACKIDS.beyondPageAdStatusChipPreparation}
          >
            <Preparation
              abTest={abTest}
              editAbTestHandle={editAbTestHandle}
              clickable={clickable}
            />
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            data-trackid={TRACKIDS.beyondPageAdStatusChipUndelivery}
          >
            <Undelivery
              abTest={abTest}
              editAbTestHandle={editAbTestHandle}
              clickable={clickable}
            />
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            data-trackid={TRACKIDS.beyondPageAdStatusChipDelivery}
          >
            <Delivery
              abTest={abTest}
              editAbTestHandle={editAbTestHandle}
              clickable={clickable}
            />
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            data-trackid={TRACKIDS.beyondPageAdStatusChipStop}
          >
            <Stop
              abTest={abTest}
              editAbTestHandle={editAbTestHandle}
              clickable={clickable}
            />
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            data-trackid={TRACKIDS.beyondPageAdStatusChipFinish}
          >
            <Finish
              abTest={abTest}
              editAbTestHandle={editAbTestHandle}
              clickable={clickable}
            />
          </MenuItem>
        </Box>
      </Menu>
    </>
  );
};
