import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { Quill } from "react-quill";

import {
  ImageDropdownMoveNav,
  ImageDropdownPhotoList,
  ImageDropdownResizeNav,
  ImageDropdownSearchRangeTab,
} from "components/molecules/image-dropdown/parts";
import { useAlert } from "contexts/alert";
import { Theme } from "contexts/theme";
import { PhotoProps } from "domains";
import ArticlePhotoApi from "javascripts/api/articles/photo";

import {
  isFindTargetParentNodeByClassName,
  targetParentNodeByTagName,
  collectError,
  compressImages,
} from "javascripts/utils";
import resizerStyles from "stylesheets/components/editor/resizer.module.scss";
import btnStyles from "stylesheets/components/partials/btn.module.scss";
import imageDropdownStyles from "stylesheets/components/quillEditor/imageDropdown.module.scss";

import { buildPictureElement, getFileExtension } from "utils";

import { ReplaceImageDropdownProps, ReplaceTargetImageType } from "./type";

const Parchment = Quill.import("parchment");

export const BeyondEitorReplaceImageDropdown = ({
  theme = "darkTheme",
  iframeId = null,
  clickTargetElementSelector,
  articlePhoto,
  article,
  moveAction = false,
  resizeAction = false,
  fetchArticlePhotos,
  handleArticlePhotos,
  quillRef,
  updateArticleBody,
  handlePageLoad,
  handlePartHtml,
  updateEditorCondition,
  selection,
  setPosition,
  fetchArticlePhotosOnEditor,
  updateArticlePhotoPublishStatus,
}: ReplaceImageDropdownProps) => {
  const [targetImage, handleTargetImage] = useState<ReplaceTargetImageType>(null);
  const [originalImage, handleOriginalImage] = useState<ReplaceTargetImageType>(null);
  const [isChangeImage, handleIsChangeImage] = useState<boolean>(false);
  const [isHandleScroll, handleIsHandleScroll] = useState<boolean>(true);
  const [displayType, handleDisplayType] = useState<"displayImage" | "displayList">("displayImage");

  const dropdownRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const photoWrapperRef = useRef<HTMLDivElement>(null);
  const expandArrowRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const alertState = useAlert();

  const { t } = useTranslation();

  useEffect(() => {
    if (!iframeId) return;
    const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
    const eventTargetDocument = iframe ? iframe.contentDocument : document;
    if (!eventTargetDocument) return;
    const clickTargetElement = iframeId
      ? eventTargetDocument.querySelector(`${clickTargetElementSelector}`)
      : document.querySelector(`${clickTargetElementSelector}`);
    if (!clickTargetElement) return;
    clickTargetElement.addEventListener("click", openDropDown, true);
    return function cleanup() {
      clickTargetElement.removeEventListener("click", openDropDown, true);
    };
  }, [quillRef === undefined, originalImage === null, isChangeImage]);

  useEffect(() => {
    if (!iframeId) return;
    const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
    const eventTargetDocument = iframeId ? iframe.contentDocument : document;
    if (!eventTargetDocument) return;
    eventTargetDocument.addEventListener("click", handleDocumentClick, true);
    return function cleanup() {
      eventTargetDocument.removeEventListener("click", handleDocumentClick, true);
    };
  }, [isChangeImage]);

  useEffect(() => {
    if (!iframeId) return;
    const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
    const eventTargetDocument = iframeId ? iframe.contentDocument : document;
    if (!eventTargetDocument) return;
    eventTargetDocument.addEventListener("keydown", handleKeyDown);

    return function cleanup() {
      eventTargetDocument.removeEventListener("keydown", handleKeyDown);
    };
  }, [targetImage]);

  useEffect(() => {
    if (!iframeId) return;
    const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
    const eventTargetDocument = iframeId ? iframe.contentDocument : document;
    if (!eventTargetDocument) return;
    eventTargetDocument.addEventListener("scroll", handleScroll, true);

    return function cleanup() {
      eventTargetDocument.removeEventListener("scroll", handleScroll, true);
    };
  }, [targetImage, isChangeImage, isHandleScroll]);

  useEffect(() => {
    if (displayType === "displayImage") return;
    if (articlePhoto.searchRange === "article") {
      handleDisplayType("displayImage");
    }
  }, [articlePhoto.searchRange]);

  const openDropDown = (e: Event) => {
    const target = e.target as HTMLElement;
    if (!iframeId) return;
    const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
    const eventTargetDocument = iframeId ? iframe.contentDocument : document;
    if (!eventTargetDocument) return;
    const clickTargetElement = iframeId
      ? eventTargetDocument.querySelector(`${clickTargetElementSelector}`)
      : document.querySelector(`${clickTargetElementSelector}`);
    if (isFindTargetParentNodeByClassName(e.target, "sb-custom")) return;
    if (!["IMG", "VIDEO"].includes(target.tagName)) return;
    if (originalImage && isChangeImage && targetImage) {
      targetImage.parentNode?.replaceChild(originalImage, targetImage);
      handleIsChangeImage(false);
    }

    if (quillRef) {
      const partBlot = Parchment.find(e.target, true);
      const targetIndex: number = quillRef.getIndex(partBlot);
      quillRef.setSelection(targetIndex + 1, 0, "silent");
    }

    if (updateEditorCondition) {
      if (selection && Object.keys(selection).length !== 0) selection.removeAllRanges();
      // rangeに選んだターゲット(img)をセット
      const range = document.createRange();
      range.selectNode(target);
      //align周りでも使うので、this.props.selection.anchorNodeにセットする
      range.setStart(target, 0);
      range.setEnd(target, 0);
      const documentSelection = document.getSelection();
      if (documentSelection) {
        documentSelection.addRange(range);
      }
      updateEditorCondition(range);
    }

    const picture: HTMLPictureElement = targetParentNodeByTagName(e.target, "PICTURE");
    if (picture) {
      handleTargetImage(picture);
      handleOriginalImage(picture.cloneNode(true) as HTMLPictureElement);
    } else {
      handleTargetImage(target);
      handleOriginalImage(target.cloneNode(true) as HTMLElement);
    }

    const positionValue = localStorage.getItem("replace_image_dropdown_position");
    const sizeValue = localStorage.getItem("replace_image_dropdown_size");
    if (moveAction && positionValue) {
      const position = JSON.parse(positionValue) as { top: string; left: string };
      if (dropdownRef.current && position) {
        const top = position.top.match(/(.+)px/);
        dropdownRef.current.style.top = top && parseInt(top[1]) < 0 ? "0" : position.top;
        const left = position.left.match(/(.+)px/);
        dropdownRef.current.style.left = left && parseInt(left[1]) < 0 ? "0" : position.left;
      }
    } else {
      const targetRect = target.getBoundingClientRect();
      if (!clickTargetElement) return;
      const containerRect = clickTargetElement.getBoundingClientRect();
      const containerHalfHeight = containerRect.height / 2;
      if (!arrowRef.current) return;
      arrowRef.current.classList.remove(imageDropdownStyles.top);
      arrowRef.current.classList.remove(imageDropdownStyles.bottom);
      if (iframeId) {
        arrowRef.current.style.display = "none";
        if (dropdownRef.current && targetRect.bottom < containerHalfHeight) {
          dropdownRef.current.style.top = `0px`;
          dropdownRef.current.style.right = "0";
        }
        if (dropdownRef.current && iframeId === "partHtmlPreviewAreaIframe") {
          const iframe = document.querySelector(`#partHtmlPreviewAreaIframe`) as HTMLIFrameElement;
          const rect = iframe.getBoundingClientRect();
          dropdownRef.current.style.top = `${rect.top}px`;
          dropdownRef.current.style.left = `${rect.right - 60}px`;
        }
      }

      //編集画面上のdropdownの位置調整
      if (setPosition) {
        setPosition(quillRef.getBounds(quillRef.selection.savedRange.index), dropdownRef.current);
      }
    }
    if (resizeAction && sizeValue && dropdownRef.current && dropdownContentRef.current) {
      const size: { width: string; height: string } = JSON.parse(sizeValue);
      dropdownRef.current.style.right = "unset";
      dropdownRef.current.style.maxWidth = "unset";
      dropdownContentRef.current.style.width = size.width;
      dropdownContentRef.current.style.height = size.height;
    }
    if (dropdownRef.current) {
      dropdownRef.current.classList.add(imageDropdownStyles.open);
    }
  };

  const handleScroll = () => {
    if (!targetImage) return;
    if (!isHandleScroll) {
      handleIsHandleScroll(true);
      return;
    }
    if (iframeId === "quillIframe" && dropdownRef.current && originalImage) {
      dropdownRef.current.classList.remove(imageDropdownStyles.open);
      if (isChangeImage) {
        targetImage.parentNode?.replaceChild(originalImage, targetImage);
        handleIsChangeImage(false);
      }
    }
  };

  const restoreOriginalImage = () => {
    if (!targetImage || !originalImage) return;
    if (!isChangeImage) return;
    targetImage.parentNode?.replaceChild(originalImage, targetImage);
    if (originalImage) {
      handleTargetImage(originalImage);
    }
    handleIsChangeImage(false);
  };

  const replaceImage = (photo: PhotoProps["photo"]) => {
    handleIsHandleScroll(false);
    const newTarget: HTMLImageElement | HTMLVideoElement | HTMLPictureElement | undefined | null =
      replaceNode(getFileExtension(photo.url), targetImage, photo);
    if (newTarget) {
      handleTargetImage(newTarget);
    }
    handleIsChangeImage(true);
    if (!iframeId) return;
    const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
    const eventTargetDocument = iframeId ? iframe.contentDocument : document;
    if (!eventTargetDocument) return;
    const resizerWrapper: HTMLDivElement | null = eventTargetDocument.querySelector(
      `.${resizerStyles.imageResizable}`,
    );
    if (resizerWrapper) resizerWrapper.style.display = "none";
    if (setPosition && arrowRef.current) {
      arrowRef.current.style.display = "block";
      arrowRef.current.classList.add(imageDropdownStyles.top);
    }
  };

  const findAnchorTag = (
    target: HTMLImageElement | HTMLVideoElement | HTMLPictureElement | null,
  ) => {
    if (!target) return null;
    const element = Array.from(target.childNodes).find((child) => {
      if (child.constructor.name === "HTMLAnchorElement") {
        return child as HTMLAnchorElement;
      }
    });
    if (element) {
      return element as HTMLAnchorElement;
    } else {
      return null;
    }
  };

  const replaceNode = (
    extension: string | null,
    targetImage: HTMLImageElement | HTMLVideoElement | HTMLPictureElement | null,
    photo: PhotoProps["photo"],
  ) => {
    let newTarget: ReplaceTargetImageType;
    //editorの場合
    if (quillRef && targetImage) {
      let index = quillRef.selection.savedRange.index;
      const parent = targetImage.parentNode as HTMLElement;
      if (parent && parent.tagName === "A") {
        index -= 1;
      }
      const parentNode = targetImage.parentNode;
      //画像からmp4へのreplace
      if (["IMG", "PICTURE"].includes(targetImage.tagName) && extension === "mp4") {
        quillRef.insertEmbed(index, "video", photo.url);
        if (parentNode) {
          newTarget = parentNode.querySelector("video");
          parentNode.removeChild(targetImage);
          return newTarget;
        } else {
          return null;
        }
      }
      //mp4から画像へのreplace
      if (targetImage.tagName === "VIDEO" && extension !== "mp4") {
        quillRef.insertEmbed(index, "picture", photo);
        if (parentNode) {
          const newTarget = parentNode.querySelector("picture");
          parentNode.removeChild(targetImage);
          return newTarget;
        } else {
          return null;
        }
      }
      //mp4からmp4へのreplace
      if (targetImage.tagName === "VIDEO" && extension === "mp4") {
        const videoElement = targetImage as HTMLVideoElement;
        const targetSource = videoElement.querySelector("source");
        if (targetSource) {
          targetSource.src = photo.url;
        } else {
          videoElement.src = photo.url;
        }
        videoElement.load();
        return targetImage;
      }

      quillRef.insertEmbed(index, "picture", photo);
      let height: number | null = null;
      let width: number | null = null;
      if (targetImage.tagName === "IMG") {
        const imageElement = targetImage as HTMLImageElement;
        height = imageElement.height;
        width = imageElement.width;
      } else {
        const oldImage = targetImage.querySelector("img");
        if (oldImage) {
          height = oldImage.height;
          width = oldImage.width;
        }
      }
      if (parent) {
        parent.removeChild(targetImage);
        const newTargetChild = parent.firstChild as HTMLElement;
        if (newTargetChild instanceof Element) {
          const newImg = newTargetChild.querySelector("img");
          if (newImg) {
            if (height) newImg.setAttribute("height", `${height}`);
            if (width) newImg.setAttribute("width", `${width}`);
          }
        }
        return newTargetChild;
      } else {
        return null;
      }
    }

    //widgetの場合
    if (updateEditorCondition) {
      //画像からmp4へのreplace
      const innerAnchorNode = findAnchorTag(targetImage);
      if (targetImage && ["IMG", "PICTURE"].includes(targetImage.tagName) && extension === "mp4") {
        if (!iframeId) return;
        const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
        const iframeDocument = iframe.contentDocument;
        if (iframeDocument) {
          const newTarget = iframeDocument.createElement("video");
          newTarget.setAttribute("allowfullscreen", "true");
          newTarget.setAttribute("autoplay", "true");
          newTarget.setAttribute("controlslist", "nodownload");
          newTarget.setAttribute("loop", "true");
          newTarget.setAttribute("muted", "true");
          newTarget.setAttribute("name", "media");
          newTarget.setAttribute("playsinline", "");
          const source = document.createElement("source");
          source.src = photo.url;
          source.type = "video/mp4";
          newTarget.appendChild(source);
          if (innerAnchorNode) {
            //NOTE: <picture><a href="link"><img/></a></picture>のようなHTML構造でユーザーが作成している場合、
            //      画像置換でaタグが消えてしまうので、既存このようなコードがあった場合のイレギュラー対応
            innerAnchorNode.innerHTML = newTarget.outerHTML;
            targetImage.parentNode?.replaceChild(innerAnchorNode, targetImage);
            return innerAnchorNode;
          }
          targetImage.parentNode?.replaceChild(newTarget, targetImage);
          return newTarget;
        } else {
          return null;
        }
      }

      if (targetImage && targetImage.tagName === "VIDEO") {
        const videoElement = targetImage as HTMLVideoElement;
        //mp4から画像へのreplace
        if (extension !== "mp4") {
          const picture = buildPictureElement(photo);
          videoElement.parentNode?.replaceChild(picture, videoElement);
          return picture;
        }
        //mp4からmp4へのreplace
        if (extension === "mp4") {
          const targetSource = videoElement.querySelector("source");
          if (targetSource) {
            targetSource.src = photo.url;
          } else {
            videoElement.src = photo.url;
          }
          videoElement.load();
          return videoElement;
        }
      }
      //画像から画像へのreplace
      if (targetImage && targetImage.tagName === "IMG") {
        const imageElement = targetImage as HTMLImageElement;
        const picture = buildPictureElement(photo);
        const image = picture.querySelector("img");
        if (image && imageElement.width) image.setAttribute("width", `${imageElement.width}`);
        if (image && imageElement.height) image.setAttribute("height", `${imageElement.height}`);
        if (innerAnchorNode) {
          //NOTE: <picture><a href="link"><img/></a></picture>のようなHTML構造でユーザーが作成している場合、
          //      画像置換でaタグが消えてしまうので、既存このようなコードがあった場合のイレギュラー対応
          innerAnchorNode.innerHTML = picture.outerHTML;
          imageElement.parentNode?.replaceChild(innerAnchorNode, imageElement);
          return innerAnchorNode;
        }
        imageElement.parentNode?.replaceChild(picture, imageElement);
        return picture;
      }
      if (targetImage && targetImage.tagName === "PICTURE") {
        const videoElement = targetImage as HTMLPictureElement;
        const picture = buildPictureElement(photo);
        const image = picture.querySelector("img");
        const oldImage = videoElement.querySelector("img");
        if (image && oldImage && oldImage.width) image.setAttribute("width", `${oldImage.width}`);
        if (image && oldImage && oldImage.height)
          image.setAttribute("height", `${oldImage.height}`);
        if (innerAnchorNode) {
          //NOTE: <picture><a href="link"><img/></a></picture>のようなHTML構造でユーザーが作成している場合、
          //      画像置換でaタグが消えてしまうので、既存このようなコードがあった場合のイレギュラー対応
          innerAnchorNode.innerHTML = picture.outerHTML;
          targetImage.parentNode?.replaceChild(innerAnchorNode, targetImage);
          return innerAnchorNode;
        }
        videoElement.parentNode?.replaceChild(picture, targetImage);
        return picture;
      }
    }
  };

  const decideChangeImage = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.remove(imageDropdownStyles.open);
    }
    if (iframeId === "quillIframe") {
      handlePageLoad(false);
      updateArticleBody();
    } else {
      if (!iframeId) return;
      const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
      const eventTargetDocument = iframeId ? iframe.contentDocument : document;
      if (!eventTargetDocument) return;
      const htmlValueElement = eventTargetDocument.querySelector(".htmlValue");
      if (htmlValueElement) {
        const htmlValue = htmlValueElement.innerHTML;
        handlePartHtml(htmlValue);
      }
    }
    handleIsChangeImage(false);
    handleTargetImage(null);
    handleOriginalImage(null);
  };

  const closeDropDown = () => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.remove(imageDropdownStyles.open);
    }
    restoreOriginalImage();
    handleIsChangeImage(false);
    handleTargetImage(null);
    handleOriginalImage(null);
  };

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files["length"] === 0) return;
    const files = e.target.files;
    ArticlePhotoApi.checkLoyaltyCreateArticlePhoto(article.uid)
      .then((_data) => {
        compressImages(files).then((compressedFiles) => {
          const params = new FormData();
          params.append("article_photo[photos][]", compressedFiles[0]);
          if (files) {
            params.append("original_photo_metadata[][filename]", `${files[0].name}`);
            params.append("original_photo_metadata[][size]", `${files[0].size}`);
          }
          ArticlePhotoApi.createArticlePhoto(article.uid, params)
            .then((data) => {
              const fileInput = document.getElementById(
                `replaceDropDownImage-${clickTargetElementSelector.replace(/(\.|#)/, "")}`,
              );
              if (fileInput && fileInput instanceof HTMLInputElement) {
                fileInput.value = "";
              }
              const errorMessage = [`${t("", { value: t("以下の画像のアップロード") })}\n`];
              if (data.data.error_photo_data.length > 0) {
                for (const errorPhoto of data.data.error_photo_data) {
                  errorMessage.push(`${Object.keys(errorPhoto)}(${Object.values(errorPhoto)})\n`);
                }
                alertState.showAlerts({ messages: errorMessage, theme: "danger" });
                return;
              }
              handleArticlePhotos(data.data.photos[0]);
              handleIsChangeImage(true);
              const newTarget = replaceNode(
                getFileExtension(data.data.photos[0].photo.url),
                targetImage,
                data.data.photos[0].photo,
              );
              if (newTarget instanceof HTMLElement) {
                handleTargetImage(newTarget);
              }
              if (setPosition && arrowRef.current) {
                arrowRef.current.style.display = "block";
                arrowRef.current.classList.add(imageDropdownStyles.top);
                setPosition(
                  quillRef.getBounds(quillRef.selection.savedRange.index),
                  dropdownRef.current,
                );
              }
            })
            .catch((error) => {
              collectError(error);
              alertState.showAlerts({
                messages: [error.message.replace("。", `\n`)],
                theme: "danger",
              });
            });
        });
      })
      .catch((error) => {
        alertState.showAlerts({ messages: [error.message], theme: "danger" });
      });
  };

  const handleDocumentClick = (e: MouseEvent) => {
    let currentNode = e.target as HTMLElement;

    let isInner = false;
    while (currentNode.parentNode) {
      if (currentNode === dropdownRef.current) {
        isInner = true;
        break;
      }
      currentNode = currentNode.parentNode as HTMLElement;
    }
    if (!isInner) {
      if (isChangeImage) {
        restoreOriginalImage();
      }
      if (dropdownRef.current) dropdownRef.current.classList.remove(imageDropdownStyles.open);
      handleTargetImage(null);
    }
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 13 && dropdownRef.current) {
      dropdownRef.current.classList.remove(imageDropdownStyles.open);
    }
    if ([8, 32].indexOf(e.keyCode) >= 0 && dropdownRef.current) {
      if (targetImage) targetImage.remove();
      dropdownRef.current.classList.remove(imageDropdownStyles.open);
    }
  };

  const openUploadForm = () => {
    if (!iframeId) return;
    const iframe = document.querySelector(`#${iframeId}`) as HTMLIFrameElement;
    const eventTargetDocument = iframeId ? iframe.contentDocument : document;
    if (!eventTargetDocument) return;
    const fileInput = document.getElementById(
      `replaceDropDownImage-${clickTargetElementSelector.replace(/(\.|#)/, "")}`,
    );
    if (fileInput) fileInput.click();
    const resizerWrapper: HTMLDivElement | null = eventTargetDocument.querySelector(
      `.${resizerStyles.imageResizable}`,
    );
    if (resizerWrapper) resizerWrapper.style.display = "none";
    restoreOriginalImage();
  };

  const expandImageArea = () => {
    if (dropdownContentRef.current)
      dropdownContentRef.current.classList.toggle(imageDropdownStyles.expand);
    if (expandArrowRef.current) expandArrowRef.current.classList.toggle(imageDropdownStyles.expand);
  };

  return (
    <div
      className={`${imageDropdownStyles.dropdown} ${imageDropdownStyles[theme]}`}
      ref={dropdownRef}
    >
      <div
        className={imageDropdownStyles.dropdownContent}
        ref={dropdownContentRef}
      >
        <div className={imageDropdownStyles.dropdownHeader}>
          <div
            className={imageDropdownStyles.arrow}
            ref={arrowRef}
          />
          {moveAction && (
            <ImageDropdownMoveNav
              article={article}
              dropdownRef={dropdownRef}
              keyName={"replace_image_dropdown_position"}
            />
          )}
          <div className={imageDropdownStyles.titleWrapper}>
            <div className={imageDropdownStyles.title}>
              {t("をアップロード", { value: t("新しい画像") })}
            </div>
            <div
              className={`${btnStyles.btn} ${
                theme === "darkTheme" ? btnStyles.btnDarkThemeSub : btnStyles.btnPrimaryBorder
              } ${btnStyles.btnXsmall}`}
              onClick={() => closeDropDown()}
            >
              閉じる
            </div>
          </div>
          <div className={imageDropdownStyles.selectImageWrapper}>
            <div
              className={`${btnStyles.btn} ${
                theme === "darkTheme" ? btnStyles.btnDarkThemePrimary : btnStyles.btnPrimary
              }`}
              onClick={() => openUploadForm()}
            >
              {t("を選択", { value: t("Versionにアップロードする画像") })}
            </div>
          </div>
          <div className={imageDropdownStyles.titleWrapper}>
            <div className={imageDropdownStyles.title}>{t("から選択", { value: t("一覧") })}</div>
            {!(articlePhoto.searchRange === "article") && (
              <div className={imageDropdownStyles.displayTypeTabWrapper}>
                <div
                  onClick={() => handleDisplayType("displayImage")}
                  className={`${imageDropdownStyles.tab} ${imageDropdownStyles.tabImage} ${
                    displayType === "displayImage" ? imageDropdownStyles.active : ""
                  }`}
                ></div>
                <div
                  onClick={() => handleDisplayType("displayList")}
                  className={`${imageDropdownStyles.tab} ${imageDropdownStyles.tabList} ${
                    displayType === "displayList" ? imageDropdownStyles.active : ""
                  }`}
                ></div>
              </div>
            )}
          </div>
          <ImageDropdownSearchRangeTab
            theme={theme as Theme}
            articlePhoto={articlePhoto}
            fetchArticlePhotos={(searchRange) => fetchArticlePhotos(searchRange, article.uid)}
            fetchArticlePhotosOnEditor={() => fetchArticlePhotosOnEditor()}
          />
        </div>
        <div className={imageDropdownStyles.dropdownBody}>
          <ImageDropdownPhotoList
            article={article}
            articlePhoto={articlePhoto}
            displayType={displayType}
            photoWrapperRef={photoWrapperRef}
            fetchArticlePhotos={(searchRange) => fetchArticlePhotos(searchRange, article.uid)}
            photoOnClick={(photo) => replaceImage(photo)}
            updateArticlePhotoPublishStatus={(photoArticleUid, photoId) =>
              updateArticlePhotoPublishStatus(photoArticleUid, photoId)
            }
          />
          <form
            className="hide"
            data-is-hide={true}
          >
            <input
              name="utf8"
              type="hidden"
              value="✓"
            />
            <input
              type="file"
              name="photo"
              id={`replaceDropDownImage-${clickTargetElementSelector.replace(/(\.|#)/, "")}`}
              onChange={(e) => uploadImage(e)}
            />
          </form>
        </div>
      </div>
      {isChangeImage && (
        <div className={imageDropdownStyles.actionButtons}>
          <div className={imageDropdownStyles.left}>
            <div
              className={`${btnStyles.btn} ${
                theme === "darkTheme" ? btnStyles.btnDarkThemePrimary : btnStyles.btnPrimary
              }`}
              onClick={() => decideChangeImage()}
            >
              {t("入れ替える")}
            </div>
          </div>
          <div className={imageDropdownStyles.center}></div>
          <div className={imageDropdownStyles.right}>
            <div
              className={`${btnStyles.btn} ${
                theme === "darkTheme" ? btnStyles.btnDarkThemeSub : btnStyles.btnPrimaryBorder
              } ${btnStyles.btnXsmall}`}
              onClick={() => restoreOriginalImage()}
            >
              {t("元に戻す")}
            </div>
          </div>
        </div>
      )}
      {resizeAction ? (
        <ImageDropdownResizeNav
          dropdownRef={dropdownRef}
          dropdownContentRef={dropdownContentRef}
          keyName={"replace_image_dropdown_size"}
        />
      ) : (
        <div
          onClick={() => expandImageArea()}
          className={imageDropdownStyles.expandArrow}
          ref={expandArrowRef}
        ></div>
      )}
    </div>
  );
};
