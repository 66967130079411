export const TRACKIDS = {
  editorSideToolsWidgetManagementButton: "editor-side-tools-widget-management-button",
  branchOperationDeliverySettingSwitch: "branch-operation-delivery-setting-switch",
  branchOperationParameterRowOpener: "branch-operation-parameter-row-opener",
  globalNavTeamTag: "global-nav-team-tag",
  globalNavMagicReplace: "global-nav-magic-replace",
  globalNavPartMedia: "global-nav-part-media",
  globalNavPartForm: "global-nav-part-form",
  globalNavInspection: "global-nav-inspection",
  globalNavAdAccount: "global-nav-ad-account",
  globalNavAspAccount: "global-nav-asp-account",
  globalNavDomain: "global-nav-domain",
  editorNavEditor: "editor-nav-editor",
  editorNavReport: "editor-nav-report",
  editorNavDeliveryUrl: "editor-nav-delivery-url",
  editorNavRedirectPage: "editor-nav-redirect-page",
  editorNavSetting: "editor-nav-setting",
  folderNavOperatorArticle: "folder-nav-operator-article",
  settingPageDeliveryUrlParameterDialogOpener: "setting-page-delivery-url-parameter-dialog-opener",
  settingPageDeliveryUrlCopyButton: "setting-page-delivery-url-copy-button",
  beyondBoostEditorDeliveryUrlCopyButton: "beyond-boost-editor-delivery-url-copy-button",
  beyondPageCurrentAdStatus: "beyond-page-current-ad-status",
  beyondPageAdStatusChipPreparation: "beyond-page-ad-status-chip-preparation",
  beyondPageAdStatusChipUndelivery: "beyond-page-ad-status-chip-undelivery",
  beyondPageAdStatusChipDelivery: "beyond-page-ad-status-chip-delivery",
  beyondPageAdStatusChipStop: "beyond-page-ad-status-chip-stop",
  beyondPageAdStatusChipFinish: "beyond-page-ad-status-chip-finish",
  folderCvTagBeyondPageTab: "folder-cv-tag-beyond-page-tab",
  folderCvTagReportTab: "folder-cv-tag-report-tab",
  folderCvTagTab: "folder-cv-tag-tab",
  folderCvTagSettingsTab: "folder-cv-tag-settings-tab",
  folderCvTagBeyondPageTabAddButton: "folder-cv-tag-beyond-page-tab-add-button",
  folderCvTagBeyondPageTabRemoveButton: "folder-cv-tag-beyond-page-tab-remove-button",
  folderCvTagBeyondPageTabEditButton: "folder-cv-tag-beyond-page-tab-edit-button",
  folderCvTagReportTabChartDateSelect: "folder-cv-tag-report-tab-chart-date-select",
  folderCvTagReportTabSummaryDateSelect: "folder-cv-tag-report-tab-summary-date-select",
  folderCvTagReportTabPvButton: "folder-cv-tag-report-tab-pv-button",
  folderCvTagReportTabCvButton: "folder-cv-tag-report-tab-cv-button",
  folderCvTagTabTagAddButton: "folder-cv-tag-tab-tag-add-button",
  folderCvTagTabTagCopyButton: "folder-cv-tag-tab-tag-copy-button",
  folderCvTagTabTagDeleteButton: "folder-cv-tag-tab-tag-delete-button",
  folderCvTagSettingsTabTagDeleteButton: "folder-cv-tag-settings-tab-tag-delete-button",
  signInFormLoginButton: "sign-in-form-login-button",
  signUpFormLink: "sign-up-form-link",
  forgotPasswordFormLink: "forgot-password-form-link",
  resendConfirmationEmailFormLink: "resend-confirmation-email-form-link",
  termsOfServiceLink: "terms-of-service-link",
  privacyPolicyLink: "privacy-policy-link",
  beyondDeLpPageMetaTagCopyButton: "beyond-de-lp-page-meta-tag-copy-button",
};
