import { useState, useMemo, memo } from "react";

import { useTranslation } from "react-i18next";
import { AppModal } from "components";

import { isFolderMatchQuery, isFolderGroupIncludeFolderMatchQuery } from "javascripts/utils";
import folderSelectModalStyles from "stylesheets/components/forms/folderSelectModal.module.scss";
import appModalStyles from "stylesheets/components/partials/appModal.module.scss";
import btnStyles from "stylesheets/components/partials/btn.module.scss";
import formStyles from "stylesheets/components/partials/form.module.scss";
import { FolderSelectModalProps } from "./type";

const FolderSelectModal = memo(
  ({ form, folders, folderGroups, duplicationForm }: FolderSelectModalProps) => {
    const { t } = useTranslation();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectFolderGroup, setSelectFolderGroup] = useState<
      "all" | "ungroup" | "default" | string // NOTE: プロパティを渡されているためきれいなユニオン型にし辛い
    >("default");
    const [folderQuery, setFolderQuery] = useState("");
    const [folderGroupQuery, setFolderGroupQuery] = useState("");
    const [selectedFolderUid, setSelectedFolderUid] = useState("");

    const folderGroupOptions = useMemo(() => {
      return folderGroups
        .filter(
          (group) =>
            isFolderGroupIncludeFolderMatchQuery(group, folderGroupQuery).isFolderGroupMatch
        )
        .map((group) => {
          return (
            <option
              key={group.id}
              value={group.id}
            >
              {group.name}
            </option>
          );
        });
    }, [folderGroups, folderGroupQuery]);

    const folderOptions = useMemo(() => {
      let filteredFoldes = folders.filter(
        (folder) => folder.uid !== form.folder.uid && isFolderMatchQuery(folder, folderQuery)
      );
      const sortValue = ["ungroup", "default"].includes(selectFolderGroup)
        ? selectFolderGroup
        : parseInt(selectFolderGroup);

      if (selectFolderGroup === "ungroup") {
        filteredFoldes = filteredFoldes.filter((folder) => !folder.group);
      }

      if (typeof sortValue === "number") {
        filteredFoldes = filteredFoldes.filter(
          (folder) => folder.group && folder.group.id === parseInt(selectFolderGroup)
        );
      }
      return filteredFoldes.map((folder) => {
        return (
          <option
            key={folder.uid}
            value={folder.uid}
          >
            {folder.name}
          </option>
        );
      });
    }, [selectFolderGroup, folderQuery]);

    return (
      <AppModal
        isModalOpen={modalIsOpen}
        setIsOpen={(e) => setIsOpen(e)}
        width={500}
      >
        <div className={folderSelectModalStyles.trigger}>{t("別のフォルダに複製する")}</div>
        <div className={appModalStyles.modalWrapper}>
          <div className={appModalStyles.modalHeader}>
            <div className={appModalStyles.left}>
              <div
                className={`${btnStyles.btn} ${btnStyles.btnPrimaryBorder} ${btnStyles.btnBackGroundTransparent} ${btnStyles.btnSmall}`}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {t("閉じる")}
              </div>
            </div>
            <div className={appModalStyles.center}>
              <div className={appModalStyles.title}>{t("フォルダ選択")}</div>
            </div>
            <div className={appModalStyles.right}>
              <div
                className={`${btnStyles.btn} ${btnStyles.btnPrimary} ${btnStyles.btnSmall} ${appModalStyles.action}`}
                onClick={(_event) => duplicationForm(selectedFolderUid)}
              >
                {t("複製")}
              </div>
            </div>
          </div>
          <div className={appModalStyles.modalContent}>
            <div className={folderSelectModalStyles.wrapper}>
              <div className={folderSelectModalStyles.title}>
                {t("フォルダグループ")}
                <input
                  onChange={(e) => setFolderGroupQuery(e.target.value)}
                  className={folderSelectModalStyles.search}
                  placeholder={t("フォルダグループ検索") || ""}
                />
              </div>
              <select
                className={formStyles.formControl}
                onChange={(e) => setSelectFolderGroup(e.target.value)}
              >
                <option value={"all"}>{t("全て")}</option>
                <option value={"ungroup"}>{t("グループなし")}</option>
                {folderGroupOptions}
              </select>
            </div>
            <div className={folderSelectModalStyles.wrapper}>
              <div className={folderSelectModalStyles.title}>
                {t("フォルダ")}
                <input
                  onChange={(e) => setFolderQuery(e.target.value)}
                  className={folderSelectModalStyles.search}
                  placeholder={t("フォルダ検索") || ""}
                />
              </div>
              <select
                className={formStyles.formControl}
                onChange={(value) => setSelectedFolderUid(value.target.value)}
              >
                {folderOptions}
              </select>
            </div>
          </div>
        </div>
      </AppModal>
    );
  }
);

FolderSelectModal.displayName = "FolderSelectModal";

export default FolderSelectModal;
