import i18next from "i18next";
export const fetchInspectionStatuses = () => {
  const statuses = {
    all: i18next.t('すべて'),
    requesting: i18next.t('審査待ち'),
    inspecting: i18next.t('審査中'),
    approved: i18next.t('承認済'),
    rejected: i18next.t('非承認'),
  };

  return statuses;
};
