import React from "react";

type Props = {
  width: string;
  height: string;
};

export const ScaleoutIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 24"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="3.0748522%"
          x2="50%"
          y2="96.5398015%"
          id="scaleout-icon-linearGradient-1"
        >
          <stop
            stopColor="#E7E7E7"
            offset="0%"
          ></stop>
          <stop
            stopColor="#A2A2A2"
            offset="29.9725507%"
          ></stop>
          <stop
            stopColor="#F2F2F2"
            offset="58.0772996%"
          ></stop>
          <stop
            stopColor="#F6F6F6"
            offset="63.7800887%"
          ></stop>
          <stop
            stopColor="#D7D7D7"
            offset="72.94394%"
          ></stop>
          <stop
            stopColor="#B8B8B8"
            offset="85.259185%"
          ></stop>
          <stop
            stopColor="#F2F2F2"
            offset="100%"
          ></stop>
        </linearGradient>
        <path
          d="M1.4472136,0.723606798 L22.2111456,11.1055728 C22.7051241,11.3525621 22.9053485,11.9532351 22.6583592,12.4472136 C22.5615955,12.640741 22.404673,12.7976635 22.2111456,12.8944272 L1.4472136,23.2763932 C0.953235098,23.5233825 0.352562058,23.3231581 0.105572809,22.8291796 C0.0361450918,22.6903242 5.70553414e-16,22.5372111 0,22.381966 L0,1.61803399 C-5.11724585e-16,1.06574924 0.44771525,0.618033989 1,0.618033989 C1.1552451,0.618033989 1.30835816,0.654179081 1.4472136,0.723606798 Z"
          id="scaleout-icon-path-2"
        ></path>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-188.000000, -100.000000)">
          <g transform="translate(188.000000, 100.000000)">
            <mask
              id="scaleout-icon-mask-3"
              fill="white"
            >
              <use href="#scaleout-icon-path-2"></use>
            </mask>
            <g>
              <use
                fill="#C1C1C1"
                href="#scaleout-icon-path-2"
              ></use>
              <use
                fill="url(#scaleout-icon-linearGradient-1)"
                href="#scaleout-icon-path-2"
              ></use>
            </g>
            <path
              d="M22.7828878,1 L22.7828878,30.5730056 C14.4007877,30.73796 7.23182448,29.4395055 1.2759982,26.6776423 C-7.65774123,22.5348473 -1.95166016,12.5740213 -1.95166016,14.3991699 C-1.95166016,17.1368929 1.06854868,20.0827637 3.76855469,20.0827637 C6.4685607,20.0827637 8.81005859,18.732666 8.81005859,15.8754883 C8.81005859,13.0183105 5.24038366,11.914514 3.17994648,10.9258593 C1.1195093,9.93720448 -0.366643149,8.41021691 -0.366643149,5.60179194 C-0.366643149,2.79336696 2.31630491,1 3.98822641,1 L3.98822641,1 L22.7828878,1 Z M2.10766602,25.833252 C8.01723289,28.1316617 11.1721808,25.4414762 11.5725098,17.7626953 C8.50789388,20.0244954 5.90657552,21.1553955 3.76855469,21.1553955 C0.561523438,21.1553955 -1.95166016,18.5935059 -1.95166016,16.3151855 C-1.95166016,14.7963053 -6.7566843,22.3856373 2.10766602,25.833252 Z"
              fill="#FFFFFF"
              mask="url(#scaleout-icon-mask-3)"
            ></path>
            <path
              d="M12.7791555,18.2340714 C10.4987039,19.1655491 8.62899509,19.8724484 7.17002898,20.3547693 C8.72904611,19.5005307 9.75952148,17.9509957 9.75952148,15.7498339 C9.75952148,12.2220019 6.04125977,11.0508593 3.99682617,10.2281054 C1.95239258,9.40535146 0.477783203,8.13459951 0.477783203,5.7974413 C0.477783203,3.4602831 3.13989258,1.96785146 4.79882813,1.96785146 L4.79882813,1.96785146 L23.4475098,1.96785146 L23.4475098,26.5783691 C15.1305141,28.3800341 8.01723289,28.1316617 2.10766602,25.833252 C8.01723289,28.1316617 11.5743961,25.5986016 12.7791555,18.2340714 Z"
              fill="#E21717"
              mask="url(#scaleout-icon-mask-3)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
