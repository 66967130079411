import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { FolderFormContactApi, FetchFolderFormContactResponse } from "api/folders/form/contact";
import { FolderFormContactProps, FolderFormContactShowProps } from "domains";
import { Moment } from "moment";
import { replaceItemAtIndex } from "utils";

export type FolderFormContactList = {
  items: Array<FolderFormContactShowProps>;
  nextPage: number | null;
  totalCount: number;
};

export const folderFormContactListState = atom<FolderFormContactList>({
  key: "folderFormContactListState",
  default: {
    items: [],
    nextPage: 1,
    totalCount: 0,
  },
});

export type HandleFetchFolderFormContactListProps = (
  folderUid: string,
  formUid: string,
  params: {
    page: number;
    start_date?: Moment | string;
    end_date?: Moment | string;
    target_tag_ids?: Array<number>;
    support_status?: string;
  }
) => Promise<FetchFolderFormContactResponse | undefined>;
export const useFetchFolderFormContactList = () => {
  const [folderFormContactList, setFolderFormContactList] = useRecoilState(
    folderFormContactListState
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handle: HandleFetchFolderFormContactListProps = useCallback(
    async (folderUid, formUid, params) => {
      setLoading(true);
      try {
        const items = params.page === 1 ? [] : folderFormContactList.items;
        const response = await FolderFormContactApi.fetch(folderUid, formUid, params);
        setFolderFormContactList({
          items: [...items, ...response.contacts],
          nextPage: response.next_page,
          totalCount: response.total_count,
        });
        setLoading(false);
        return response;
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormContactList]
  );
  return [handle, loading] as const;
};
export type HandleUpdateFolderFormContactListProps = (
  folderUid: string,
  formUid: string,
  params: {
    folder_form_contact: {
      [x: string]: string;
    };
    contact_ids: string[] | number[];
  }
) => void;
export const useUpdateFolderFormContactList = () => {
  const [folderFormContactList, setFolderFormContactList] = useRecoilState(
    folderFormContactListState
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handle: HandleUpdateFolderFormContactListProps = useCallback(
    async (folderUid, formUid, params) => {
      setLoading(true);
      try {
        const response: FolderFormContactProps[] = await FolderFormContactApi.update(
          folderUid,
          formUid,
          params
        );
        let items = folderFormContactList.items;
        response.forEach((res) => {
          const index = items.findIndex((contact) => contact.id === res.id);
          items = replaceItemAtIndex(items, index, { ...res });
        });
        setFolderFormContactList({
          ...folderFormContactList,
          items: items,
        });
        setLoading(false);
        return response;
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormContactList]
  );
  return [handle, loading] as const;
};

export const useFolderFormContactList = (folderUid: string, formUid: string) => {
  const [folderFormContactList, setFolderFormContactList] = useRecoilState(
    folderFormContactListState
  );
  const [handle] = useFetchFolderFormContactList();

  useEffect(() => {
    const params = {
      page: 1,
    };
    handle(folderUid, formUid, params);
  }, []);

  return [folderFormContactList, setFolderFormContactList] as const;
};
