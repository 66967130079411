import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { MicrosoftAccountApi } from "api/teams/adAccounts/microsoftAccounts";
import { MicrosoftAccountProps } from "domains";
import { GoogleAccountApiCreateAccountRequest } from "api/teams/adAccounts/googleAccounts/type";

export const microsoftAccountListState = atom<MicrosoftAccountProps[]>({
  key: "microsoftAccountListState",
  default: [],
});

export const useFetchMicrosoftAccountList = () => {
  const [list, setList] = useRecoilState(microsoftAccountListState);

  useEffect(() => {
    MicrosoftAccountApi.fetchAccounts().then((data) => {
      setList(data.microsoft_accounts || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};

export const useCreateMicrosoftAccount = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async ({ code, state }: Partial<GoogleAccountApiCreateAccountRequest>) => {
      if (loading) {
        return;
      }
      if (!code || !state) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        await MicrosoftAccountApi.createAccount({ code, state });
        setLoading(false);
        window.location.href = "/teams/ad_accounts";
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [handle, error] as const;
};

export const useDeleteMicrosoftAccount = () => {
  const [list, setList] = useRecoilState(microsoftAccountListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [_error, setError] = useState<any>();

  const handle = useCallback(
    async (id: number) => {
      if (loading) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        await MicrosoftAccountApi.destroyAccount({ accountId: id });
        const newList = list.filter((d: MicrosoftAccountProps) => d.id !== id);
        setList(newList);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );

  return [handle] as const;
};
