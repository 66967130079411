import { useEffect, useState } from "react";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { CustomError } from "api";
import { TeamTagSettingTagScriptApi } from "api/teams/tagSettings/tagScript";
import { TeamTagSetting, TeamTagSettingTeamTagScript } from "domains";
import { useLogging } from "hooks";

const teamTagScriptsState = atom<TeamTagSettingTeamTagScript[]>({
  key: "teamTagScriptsState",
  default: [],
});

export const useFetchTeamTagScripts = (tagSettingId: number) => {
  const { sendErrorLog } = useLogging();
  const [teamTagScripts, setTeamTagScripts] = useRecoilState(teamTagScriptsState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();

  useEffect(() => {
    const handle = async () => {
      setError(undefined);
      setLoading(true);
      try {
        const result: TeamTagSettingTeamTagScript[] =
          await TeamTagSettingTagScriptApi.fetch(tagSettingId);
        setTeamTagScripts(result);
      } catch (error: unknown) {
        if (error instanceof CustomError) {
          setError(error);
        } else {
          sendErrorLog({
            error,
            message: "Failed to fetch team tag setting",
          });
        }
      } finally {
        setLoading(false);
      }
    };

    handle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagSettingId]);

  return [teamTagScripts, loading, error] as const;
};

export const useUpdateTeamTagScript = () => {
  const { sendErrorLog } = useLogging();
  const setTeamTagScripts = useSetRecoilState(teamTagScriptsState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();

  const handle = async (
    activeTag: TeamTagSetting,
    script: TeamTagSettingTeamTagScript,
    name: string,
    body: string,
    embedType: string,
  ): Promise<TeamTagSettingTeamTagScript[] | undefined> => {
    setError(undefined);
    setLoading(true);
    try {
      const params = {
        tag: {
          name: name,
          body: body,
          embed_type: embedType,
        },
      };
      const result: TeamTagSettingTeamTagScript = await TeamTagSettingTagScriptApi.update(
        activeTag.id,
        script.id,
        params,
      );
      const tagIndex = activeTag.tags.findIndex((tag) => tag.id === script.id);
      const list = [...activeTag.tags];
      list[tagIndex] = result;
      setTeamTagScripts(list);
      // TODO: useFetchTeamTagScriptsが使われていないためlistを返す必要があり、使うようになったらrecoilStateで管理する
      return list;
    } catch (error) {
      if (error instanceof CustomError) {
        setError(error);
      }
      sendErrorLog({
        error,
        message: "Failed to update team tag setting",
      });
    } finally {
      setLoading(false);
    }
  };
  return [handle, loading, error] as const;
};

export const useCreateTeamTagScript = () => {
  const { sendErrorLog } = useLogging();
  const setTeamTagScripts = useSetRecoilState(teamTagScriptsState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();

  const handle = async (tagSetting: TeamTagSetting, type: "head" | "body") => {
    setError(undefined);
    setLoading(true);
    try {
      const params = {
        tag: {
          document_property: "",
        },
      };
      if (type === "head") {
        params.tag.document_property = "header";
      } else {
        params.tag.document_property = "body";
      }
      const result: TeamTagSettingTeamTagScript = await TeamTagSettingTagScriptApi.create(
        tagSetting.id,
        params,
      );
      const list = [result, ...tagSetting.tags];
      setTeamTagScripts(list);
      // TODO: useFetchTeamTagScriptsが使われていないためlistを返す必要があり、使うようになったらrecoilStateで管理する
      return list;
    } catch (error) {
      if (error instanceof CustomError) {
        setError(error);
      } else {
        sendErrorLog({
          error,
          message: "Failed to create team tag setting",
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return [handle, loading, error] as const;
};

export const useDeleteTeamTagScript = () => {
  const { sendErrorLog } = useLogging();
  const setTeamTagScripts = useSetRecoilState(teamTagScriptsState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();

  const handle = async (
    activeTag: TeamTagSetting,
    script: TeamTagSettingTeamTagScript,
  ): Promise<TeamTagSettingTeamTagScript[] | undefined> => {
    setError(undefined);
    setLoading(true);
    try {
      await TeamTagSettingTagScriptApi.destroy(activeTag.id, script.id);
      const list = activeTag.tags.filter((tag) => tag.id !== script.id);
      setTeamTagScripts(list);
      // TODO: useFetchTeamTagScriptsが使われていないためlistを返す必要があり、使うようになったらrecoilStateで管理する
      return list;
    } catch (error) {
      if (error instanceof CustomError) {
        setError(error);
      } else {
        sendErrorLog({
          error,
          message: "Failed to delete team tag setting",
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return [handle, loading, error] as const;
};
