import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class TeamMemberSessionApi extends ApiBase {

  static signOut() {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.delete(`${baseUrl}/teams/members/sessions`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default TeamMemberSessionApi;
