import { ReactNode } from "react";
import {
  ArrowPathIcon as ArrowPathIconOutline,
  ClipboardDocumentIcon as ClipboardDocumentIconOutline,
  FolderIcon as FolderIconOutline,
  ShieldCheckIcon as ShieldCheckIconOutline,
  SwatchIcon as SwatchIconOutline,
} from "@heroicons/react/24/outline";
import {
  FolderIcon as FolderIconSolid,
  ArrowPathIcon as ArrowPathIconSolid,
  SwatchIcon as SwatchIconSolid,
  ShieldCheckIcon as ShieldCheckIconSolid,
  ClipboardDocumentIcon as ClipboardDocumentIconSolid,
} from "@heroicons/react/24/solid";
import { ListItem } from "components/atoms/core/ListItem";
import { ListItemProps } from "components/atoms/core/ListItem/type";
import { ConversionTagSolidIcon } from "components/atoms/icons/conversion-tag-solid-icon";
import type { MenuListTypeKey } from "./type";

type MenuNavigationType = {
  groupId: MenuListTypeKey;
  items: (ListItemProps & { children?: ReactNode; isVisible: boolean })[];
}[];

export const MENU_NAVIGATION_KEYS = [
  "default",
  "folder",
  "cvtag",
  "replacement",
  "media",
  "inspection",
  "form",
  "ad",
  "cv-measurement",
  "team",
  "role",
  "user-settings",
  "plans",
  "payments",
  "bonus",
  "others",
  "logout",
] as const;

export const getMenuNavigation = (
  signOutHandle: () => void,
  handleClickPayments: () => Promise<void>,
  {
    existsMember,
    isAdmin,
    isTeamOwner,
    isInsideMember,
  }: {
    existsMember: boolean;
    isAdmin: boolean;
    isTeamOwner: boolean;
    isInsideMember: boolean;
  },
): MenuNavigationType => [
  {
    groupId: "default",
    items: [],
  },
  {
    groupId: "beyond",
    items: [
      {
        id: "folder",
        icon: <FolderIconOutline />,
        selectedIcon: <FolderIconSolid />,
        description: "ページの制作・設定・管理等はコチラから",
        title: "フォルダ",
        href: "/folders",
        hasChevron: false,
        isVisible: existsMember,
      },
      {
        id: "cvtag",
        icon: (
          <ConversionTagSolidIcon
            width={24}
            height={24}
          />
        ),
        selectedIcon: (
          <ConversionTagSolidIcon
            width={24}
            height={24}
          />
        ),
        description: "必ず使うタグを事前設置。設定漏れ防止",
        title: "一括タグ",
        href: "/teams/tags",
        hasChevron: false,
        isVisible: existsMember,
      },
      {
        id: "replacement",
        icon: <ArrowPathIconOutline />,
        selectedIcon: <ArrowPathIconSolid />,
        description: "ページを横断して画像やURLを一括置換",
        title: "マジック置換",
        href: "/articles/bulk_replaces",
        hasChevron: false,
        isVisible: existsMember,
      },
      {
        id: "media",
        icon: <SwatchIconOutline />,
        selectedIcon: <SwatchIconSolid />,
        description: "LPに検索窓や検索候補を簡単に設置",
        title: "メディア",
        href: "/teams/product_search_forms",
        hasChevron: false,
        isVisible: existsMember,
      },
      {
        id: "inspection",
        icon: <ShieldCheckIconOutline />,
        selectedIcon: <ShieldCheckIconSolid />,
        description: "審査が通過したLPだけを表示可能に",
        title: "審査",
        href: "/inspections",
        hasChevron: false,
        isVisible: existsMember,
      },
      {
        id: "form",
        icon: <ClipboardDocumentIconOutline />,
        selectedIcon: <ClipboardDocumentIconSolid />,
        description: "申し込みフォームを利用し顧客を増やす",
        title: "フォーム",
        href: "/folders/forms",
        hasChevron: false,
        isVisible: existsMember,
      },
    ],
  },
  {
    groupId: "connection",
    items: [
      {
        id: "ad_accounts",
        title: "広告媒体連携",
        description: "広告管理画面の数値を取得できます",
        href: "/teams/ad_accounts",
        hasChevron: false,
        isVisible: existsMember,
      },
      {
        id: "asp_accounts",
        title: "CV計測連携",
        description: "ECカートやCRMのCV数を表示できます",
        href: "/teams/asp_accounts",
        hasChevron: false,
        isVisible: existsMember,
      },
    ],
  },
  {
    groupId: "account",
    items: [
      {
        id: "users_teams",
        title: "所属チーム",
        description: "作業するチームを選択できます。",
        href: "/users/teams",
        hasChevron: false,
        isVisible: true,
      },
      {
        id: "permissions",
        title: "招待/権限",
        description: "チームメンバーを管理できます",
        href: "/permissions",
        hasChevron: false,
        isVisible: existsMember && isTeamOwner,
      },
      {
        id: "users_edit",
        title: "ユーザー設定",
        description: "お客様自身の情報を確認・変更できます",
        href: "/users/edit",
        hasChevron: false,
        isVisible: true,
      },
      {
        id: "plans",
        title: "プラン",
        description: "ご利用中のプラン料金を確認できます",
        href: "/teams/plans",
        hasChevron: false,
        isVisible: existsMember && isTeamOwner,
      },
      {
        id: "payments",
        title: "支払い管理",
        description: "お支払い情報を確認・変更",
        onClick: handleClickPayments,
        hasChevron: false,
        isVisible: existsMember && (isAdmin || (isTeamOwner && isInsideMember)),
      },
      {
        id: "introductions",
        title: "特典",
        description: "ご紹介キャッシュバック等の特典があります",
        href: "/introductions",
        hasChevron: false,
        isVisible: existsMember,
      },
      {
        id: "7",
        title: "その他",
        href: "",
        isVisible: true,
        children: (
          <div className="mt-4 flex flex-col gap-y-2 pl-4">
            {[
              {
                id: "faq",
                title: "FAQ",
                href: "https://knowledge.squadbeyond.com",
                hasChevron: false,
                isVisible: true,
              },
              {
                id: "terms",
                title: "利用規約",
                href: "/terms",
                hasChevron: false,
                isVisible: true,
              },
              {
                id: "privacy",
                title: "プライバシーポリシー",
                href: "/privacy",
                hasChevron: false,
                isVisible: true,
              },
              {
                id: "admin",
                title: "Admin",
                href: "/admin/teams",
                hasChevron: false,
                isVisible: isAdmin,
              },
            ]
              .filter((o) => o.isVisible)
              .map((nav) => (
                <ListItem
                  {...nav}
                  key={nav.id}
                />
              ))}
          </div>
        ),
      },
      {
        id: "8",
        title: "ログアウト",
        hasChevron: false,
        theme: "red",
        isVisible: true,
        onClick: (e) => {
          e.preventDefault();
          signOutHandle();
        },
      },
    ],
  },
];
