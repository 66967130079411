import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { FolderGroupingProps } from "domains";
import { useLogging } from "hooks/logging";
import FolderGroupingApi from "javascripts/api/folderGrouping";

export const folderGroupingListState = atom<FolderGroupingProps[]>({
  key: "folderGroupingListState",
  default: [],
});

// TODO: group作成したら更新する必要がある
export const useFetchFolderGroupingList = () => {
  const { sendErrorLog } = useLogging();
  const [list, setList] = useRecoilState(folderGroupingListState);

  useEffect(() => {
    FolderGroupingApi.fetchList()
      .then((data) => {
        setList(data);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch folder group",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};
