import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { PhotoProps } from "domains";
import FolderArticlePhotoApi from "javascripts/api/folders/articles/photo";

export type ArticlePhoto = {
  items: Array<PhotoProps>;
  nextPage: number;
};

const articlePhotoListState = atom<ArticlePhoto>({
  key: "articlePhotoListState2",
  default: {
    items: [],
    nextPage: 1,
  },
});

export type FetchFolderArticlePhotoListProps = (searchRange: string, folderUid: string) => void;

export type HandleFetchFolderArticlePhotoListProps = (
  folderUid: string,
  params: {
    page: number;
    search_range: string;
  }
) => void;
export const useFetchFolderArticlePhotoList = () => {
  const [articlePhotoList, setArticlePhotoList] = useRecoilState(articlePhotoListState);

  const handle: HandleFetchFolderArticlePhotoListProps = useCallback(
    async (folderUid, params) => {
      try {
        const items = params.page === 1 ? [] : articlePhotoList.items;
        const response = await FolderArticlePhotoApi.fetch(folderUid, params);
        setArticlePhotoList({
          items: [...items, ...response.photos],
          nextPage: response.next_page,
        });
      } catch (e) {}
    },
    [articlePhotoList]
  );
  return [handle] as const;
};

export const useFolderArticlePhotoList = (folderUid: string) => {
  const [articlePhotoList, setArticlePhotoList] = useRecoilState(articlePhotoListState);
  const [handle] = useFetchFolderArticlePhotoList();

  useEffect(() => {
    handle(folderUid, { page: 1, search_range: "all" });
  }, []);

  return [articlePhotoList, setArticlePhotoList] as const;
};
