import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { replaceItemAtIndex, removeItemAtIndex } from 'utils'

import { ArticleHtmlPartApi, ArticleHtmlPartCategoryApi } from 'api'
import TeamMemberFavoriteArticleHtmlPartApi from 'javascripts/api/teams/members/articles/htmlParts/favorite'

import btnStyles from 'stylesheets/components/partials/btn.module.scss'
import formStyles from 'stylesheets/components/partials/form.module.scss'
import listModalStyles from 'stylesheets/components/quillEditor/article/htmlPart/listModal.module.scss'
import appModalStyles from 'stylesheets/components/partials/appModal.module.scss';

import { AppModal } from "components";
import { useTranslation } from "react-i18next";

import Utility from 'javascripts/utility'
import { collectError } from 'javascripts/utils';

function ListModal({
  articleHtmlPartList,
  setAction,
  setSelectedPart,
  setArticleHtmlPartList,
}) {
  const { t } = useTranslation();
  const [partCategories, setPartCategory] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)
  const [parts, setPartList] = useState([])
  const [pageNum, handlePageNum] = useState(1)
  const [isModalOpen, setIsOpen] = useState(false);

  const toggleFavoritePart = (partId) => {
    TeamMemberFavoriteArticleHtmlPartApi.registFavoriteArticlePart(partId)
      .then(data => {
        const partListIndex = parts.findIndex(part => part.id === data['data']['article_html_part_id'])
        parts[partListIndex].is_favorite = !parts[partListIndex].is_favorite
        setPartList([...[], ...parts])
        const index = articleHtmlPartList.items.findIndex(part => part.id === data['data']['article_html_part_id'])
        if (index >= 0) {
          const isFavorite = !articleHtmlPartList.items[index].is_favorite
          setArticleHtmlPartList({ ...articleHtmlPartList, items: replaceItemAtIndex(articleHtmlPartList.items, index, { ...articleHtmlPartList.items[index], is_favorite: isFavorite }) })
        }
      })
      .catch(error => {
        collectError(error)
      })
  }

  const fetchParts = useCallback((pageNum, categoryId=null) => {
    const params = {
      category_id: categoryId,
      include_admin_part: false,
      page: pageNum,
      fetch_favorite_part: false,
    }
    ArticleHtmlPartApi.fetchArticleHtmlParts(params)
      .then(data => {
        handlePageNum(data.next_page)
        let partArray = pageNum === 1 ? [] : parts
        setPartList(partArray.concat(data.html_parts.sort(function(a,b){return b.is_favorite - a.is_favorite})))
        setSelectedCategoryId(categoryId)
      })
      .catch(error => {
        collectError(error)
      })
  }, [pageNum, parts])

  useEffect(() => {
    if (!isModalOpen) return
    fetchCategories()
    fetchParts(1)
  }, [isModalOpen])

  const fetchCategories = () => {
    ArticleHtmlPartCategoryApi.fetchArticleHtmlPartCategories()
      .then(data => {
        setPartCategory(data)
      })
      .catch(error => {
        collectError(error)
    })
  }

  const deleteArticleHtmlPart  = (partId) => {
    if (!window.confirm(t('本当に削除しますか？') || "")) return;
    ArticleHtmlPartApi.deleteArticleHtmlPart(partId)
      .then(data => {
        let deletePartIndex = parts.findIndex(part => part.id === data['data']['id'])
        parts.splice(deletePartIndex, 1)
        setPartList([...[], ...parts])
        const index = articleHtmlPartList.items.findIndex(part => part.id === data['data']['id'])
        if (index >= 0) {
          setArticleHtmlPartList({ ...articleHtmlPartList, items: removeItemAtIndex(articleHtmlPartList.items, index) })
        }
      })
      .catch(error => {
        collectError(error)
      })
  }

  const searchArticleParts = (e) => {
    if (e.keyCode !== 13) return
    const params = {
      category_id: null,
      include_admin_part: false,
      page: 1,
      fetch_favorite_part: false,
      query: e.target.value
    }
    //fetchArticleHtmlParts(categoryId, includeAdminPart, pageNum, isFavorite, query)
    ArticleHtmlPartApi.fetchArticleHtmlParts(params)
      .then(data => {
        //お気に入り順に並べ替え
        data.html_parts.sort(function(a,b){return b.is_favorite - a.is_favorite});
        setPartList(data.html_parts)
        handlePageNum(data.next_page)
      })
      .catch(error => {
        collectError(error)
      })
  }

  const searchClear = () => {
    ArticleHtmlPartApi.fetchArticleHtmlParts(null, false, 1)
      .then(data => {
        //お気に入り順に並べ替え
        data.html_parts.sort(function(a,b){return b.is_favorite - a.is_favorite});
        setPartList(data.html_parts)
        handlePageNum(data.next_page)
        document.querySelector(`.${listModalStyles.searchForm}`).value = ''
      })
      .catch(error => {
        collectError(error)
      })
  }

  const editPart = (part) => {
    setSelectedPart(part)
    setAction('create')
    setIsOpen(false)
  }

  const newPart = () => {
    setSelectedPart(null)
    setAction('create')
    setIsOpen(false)
  }

  const changeCategory = (categoryId) => {
    handlePageNum(1)
    fetchParts(1, categoryId)
  }

  const partLists = () => {
    let partListComponents = []
    for(let part of parts) {
      partListComponents.push(
        <div key={part.id} className={listModalStyles.partList}>
          <div className={listModalStyles.partDetail}>
            <i className={`fas fa-${part.icon}`}></i>
            <div className={listModalStyles.partInfo}>
              <div className={listModalStyles.partName}>
                {part.name}
              </div>
              <span>{t("作成者")}: {part.creator && part.creator.user ? part.creator.user.name : 'Admin'}</span>
              <span>　</span>
              <span>{t("最終更新")}: {Utility.dateHelper(part.updated_at)}</span>
            </div>
          </div>
          <div className={listModalStyles.partListButtons}>
            <div className={listModalStyles.favorite} onClick={(partId) => toggleFavoritePart(part.id)}>{part.is_favorite ? '★' : '☆'}</div>
            {!(part.admin) &&
              <>
                <div className={`${btnStyles.btn} ${btnStyles.btnDarkThemeThinPrimary}`} onClick={() => editPart(part)}>{t("編集")}</div>
                <div className={`${btnStyles.btn} ${btnStyles.btnDarkThemeAlert}`} onClick={() => deleteArticleHtmlPart(part.id)}>{t("削除")}</div>
              </>
            }
          </div>
        </div>
      )
    }
    return partListComponents
  }

  const categoryLists = () => {
    let categoryListComponents = []
    // category['attributes'].id === ’オリジナル’
    for(let category of partCategories) {
      if (category['attributes'].id === 15) {
        categoryListComponents.unshift(
          <li
            key={category['attributes'].id}
            className={ selectedCategoryId === category['attributes'].id ? listModalStyles.active : ''}><span onClick={() => changeCategory(category['attributes'].id)}>{category['attributes'].name}</span></li>
        )
      } else {
        categoryListComponents.push(
          <li
            key={category['attributes'].id}
            className={ selectedCategoryId === category['attributes'].id ? listModalStyles.active : ''}><span onClick={() => changeCategory(category['attributes'].id)}>{category['attributes'].name}</span></li>
        )
      }
    }
    return categoryListComponents
  }


  return (
    <AppModal
      width={1000}
      isModalOpen={isModalOpen}
      theme={'darkTheme'}
      setIsOpen={(e) => setIsOpen(e)}
    >
      <>
        <div className={listModalStyles.trigger} onClick={(e) => setIsOpen(true)}>管理</div>
      </>
      <>
        <div className={`${appModalStyles.modalWrapper}`} data-test={"MasterStyleSheetModal-ModalWrapper"} >
          <div className={appModalStyles.modalHeader}>
            <div className={`${appModalStyles.left} ${appModalStyles.flex}`}>
              <div className={`${btnStyles.btn} ${btnStyles.btnDarkThemeSub} ${btnStyles.btnSmall}`} onClick={(isShow) => setIsOpen(false)}>{t("閉じる")}</div>
            </div>
            <div className={appModalStyles.center}>
              <div className={appModalStyles.title}>
                {t("ウィジェット管理")}
              </div>
            </div>
            <div className={`${appModalStyles.right}`}>

            </div>
          </div>
          <div className={appModalStyles.modalContent}>
            <div className={listModalStyles.bodyHeader}>
              <div className={`${btnStyles.btn} ${btnStyles.btnDarkThemePrimary} ${btnStyles.btnSmall}`} onClick={() => newPart()}>{t("ウィジェット追加")}</div>
              <div className={listModalStyles.searchFormArea}>
                <input type='input'
                  className={`${listModalStyles.searchForm} ${formStyles.formControl}`}
                  placeholder='検索'
                  onKeyDown={(e) => searchArticleParts(e)}
                />
                <div className={listModalStyles.btnClear} onClick={() => searchClear()}>{t("クリア")}</div>
              </div>
            </div>
            <div className={listModalStyles.bodyContent}>
              <div className={listModalStyles.categoryLists}>
                <ul>
                  <li className={!selectedCategoryId ? listModalStyles.active : ''}><span onClick={() => fetchParts(1, '')}>{t("全て")}</span></li>
                  {categoryLists()}
                </ul>
              </div>
              <div className={listModalStyles.partLists}>
                {partLists()}
                {pageNum !== null &&
                  <div className={`${listModalStyles.partList} ${listModalStyles.btnMore}`} onClick={() => fetchParts(pageNum, selectedCategoryId)}>
                    {t("さらに読み込む")}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    </AppModal>
  )
}


ListModal.propTypes = {
  setSelectedPart: PropTypes.func.isRequired,
  setAction: PropTypes.func.isRequired,
}

export default ListModal
