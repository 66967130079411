import { BeyondBoostLocalNavigation } from "./BeyondBoostLocalNavigation";
import { BeyondPageAndVersionNavigation } from "./BeyondPageAndVersionNavigation";
import { BEYOND_PAGE_OR_VERSION_REGEXP, FOLDER_OR_GROUP_REGEXP } from "./consts";
import { FolderAndGroupNavigation } from "./FolderAndGroupNavigation";

import type { LocalNavigationProps } from "./type";

export const LocalNavigation = (props: LocalNavigationProps) => {
  const isTop = props.pathName === "/";
  const isBeyondBoost = props?.editorVersion === 4;
  const isFolderAndGroup = Object.values(FOLDER_OR_GROUP_REGEXP).some((regexp) =>
    regexp.test(props.pathName),
  );
  const isBeyondPageAndVersion = Object.values(BEYOND_PAGE_OR_VERSION_REGEXP).some((regexp) =>
    regexp.test(props.pathName),
  );

  if (isBeyondBoost) return <BeyondBoostLocalNavigation {...props} />;
  if (isBeyondPageAndVersion) return <BeyondPageAndVersionNavigation {...props} />;
  if (isFolderAndGroup || isTop)
    return (
      // NOTE: SPのデザインがないのでSP時は非表示としている
      <div className="hidden md:flex">
        <FolderAndGroupNavigation pathName={props.pathName} />
      </div>
    );
  return null;
};
