import React, { useState, useEffect, useRef } from "react";

import { isFindTargetParentNodeByClassName } from "javascripts/utils";
import resizerStyles from "stylesheets/components/editor/resizer.module.scss";
import imageDropdownStyles from "stylesheets/components/quillEditor/imageDropdown.module.scss";

import { ResizeOption, ResizerProps } from "./type";

export const Resizer = ({
  iframeId = null,
  clickTargetElementClass,
  selection,
  updateEditorCondition,
  scrollContainer = null,
  handlePartHtml,
}: ResizerProps) => {
  const [isImgResize, handleIsImgResize] = useState(false);
  const [resizeOption, handleResizeOption] = useState<ResizeOption>({
    target: null,
    originalWidth: 0,
    originalHeight: 0,
    originalMouseX: 0,
    originalMouseY: 0,
  });

  const resizerWrapperRef = useRef<HTMLDivElement>(null);
  const resizerBodyRef = useRef<HTMLDivElement>(null);

  const eventTargetDocument = document.querySelector<HTMLIFrameElement>(
    `#${iframeId}`
  )?.contentDocument;
  const browserWindow = document.querySelector<HTMLIFrameElement>(`#${iframeId}`)?.contentWindow;
  let clickTargetElement = eventTargetDocument?.querySelector<HTMLDivElement>(
    `.${clickTargetElementClass}`
  );

  useEffect(() => {
    if (!eventTargetDocument) return;
    eventTargetDocument.addEventListener("mouseup", () => {
      if (handlePartHtml) {
        const htmlValue = eventTargetDocument.querySelector(".htmlValue")?.innerHTML ?? "";
        handlePartHtml(htmlValue);
      }
      handleIsImgResize(false);
    });

    return () => {
      eventTargetDocument.removeEventListener("mouseup", () => {
        if (handlePartHtml) {
          const htmlValue = eventTargetDocument.querySelector(".htmlValue")?.innerHTML ?? "";
          handlePartHtml(htmlValue);
        }
        handleIsImgResize(false);
      });
    };
  }, [eventTargetDocument]);

  useEffect(() => {
    if (!eventTargetDocument) return;
    clickTargetElement = eventTargetDocument.querySelector<HTMLDivElement>(
      `.${clickTargetElementClass}`
    );
    clickTargetElement?.addEventListener("click", setResizer);

    return () => {
      clickTargetElement?.removeEventListener("click", setResizer);
    };
  }, [eventTargetDocument, clickTargetElement]);

  useEffect(() => {
    eventTargetDocument?.addEventListener("mousemove", resize);
    return () => {
      eventTargetDocument?.removeEventListener("mousemove", resize);
    };
  }, [isImgResize]);

  useEffect(() => {
    const scrollNode = eventTargetDocument?.querySelector<HTMLDivElement>(`${scrollContainer}`);
    if (scrollNode) {
      scrollNode.addEventListener(
        "scroll",
        () => {
          let ticking = false;
          if (!ticking) {
            requestAnimationFrame(() => {
              ticking = false;
              handleScroll();
            });
            ticking = true;
          }
        },
        { passive: true }
      );
    }
    eventTargetDocument?.addEventListener("keydown", handleKeyDown);

    return () => {
      if (scrollNode) {
        const opts: AddEventListenerOptions & EventListenerOptions = { passive: true };
        (scrollNode as any).removeEventListener(
          "scroll",
          () => {
            let ticking = false;
            if (!ticking) {
              requestAnimationFrame(() => {
                ticking = false;
                handleScroll();
              });
              ticking = true;
            }
          },
          opts
        );
      }
      eventTargetDocument?.removeEventListener("keydown", handleKeyDown);
    };
  }, [resizeOption.target]);

  useEffect(() => {
    eventTargetDocument?.addEventListener("click", handleDocumentClick, true);
    return function cleanup() {
      eventTargetDocument?.removeEventListener("click", handleDocumentClick, true);
    };
  }, []);

  //editor v2 設定
  useEffect(() => {
    const qlAlign = document.querySelector(".ql-align");
    if (qlAlign) {
      eventTargetDocument?.addEventListener("click", function (e) {
        if (!isFindTargetParentNodeByClassName(e.target, "ql-align")) return;
        if (resizerWrapperRef.current) resizerWrapperRef.current.style.display = "none";
      });
    }
  }, []);
  //editor v2 設定

  const handleScroll = () => {
    if (resizeOption.target && resizerWrapperRef.current) {
      const tarImageRect = resizeOption.target.getBoundingClientRect();
      resizerWrapperRef.current.style.top = `${tarImageRect.top}px`;
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!resizeOption.target) return;
    //keyCode {8: delete, 13: enter, 32: backspace}
    if (e.keyCode === 13) {
      if (resizerWrapperRef.current) resizerWrapperRef.current.style.display = "none";
      handleResizeOption({ ...resizeOption, target: null });
    }
    if ([8, 32].indexOf(e.keyCode) >= 0) {
      if (resizeOption.target) resizeOption.target.remove();
      if (resizerWrapperRef.current) resizerWrapperRef.current.style.display = "none";
    }
  };

  const setResizer = (e: MouseEvent) => {
    const target: Element = e.target as Element;
    if (isFindTargetParentNodeByClassName(target, "sb-custom")) return;
    if (["IMG", "VIDEO"].includes(target.tagName)) {
      const imageScale = eventTargetDocument?.querySelector<HTMLInputElement>(
        `.${resizerStyles.imageScale}`
      );
      if (!!selection && selection.rangeCount !== 0) {
        selection.removeAllRanges();
      }
      // rangeに選んだターゲット(img)をセット
      const range = eventTargetDocument?.createRange();

      if (range && e.target) {
        range.selectNode(e.target as Node);
        //align周りでも使うので、selection.anchorNodeにセットする
        range.setStart(e.target as Node, 0);
        range.setEnd(e.target as Node, 0);
        browserWindow?.getSelection()?.addRange(range);
        updateEditorCondition(range);
      }

      const textOptions = document.querySelectorAll<HTMLOptionElement>(".text-option");
      textOptions.forEach((option) => {
        option.style.display = "none";
      });

      const tarImageRect = target.getBoundingClientRect();
      //frameIdがないのはeditor_version v2のみ
      handleResizeOption({
        target: target as HTMLDivElement,
        originalWidth: tarImageRect.width,
        originalHeight: tarImageRect.height,
        originalMouseX: 0,
        originalMouseY: 0,
      });
      if (resizerWrapperRef.current) {
        resizerWrapperRef.current.style.display = "block";
        resizerWrapperRef.current.style.top = `${
          tarImageRect.top + (browserWindow?.scrollY ?? 0)
        }px`;
        resizerWrapperRef.current.style.left = `${tarImageRect.left}px`;
        resizerWrapperRef.current.style.width = `${tarImageRect.width}px`;
        resizerWrapperRef.current.style.height = `${tarImageRect.height}px`;
      }
      if (imageScale) {
        imageScale.innerText = `${Math.floor(tarImageRect.width)} x ${Math.floor(
          tarImageRect.height
        )}`;
      }
    }
  };

  const settingResize = (e: React.MouseEvent<HTMLDivElement>) => {
    resizeOption.originalWidth = parseFloat(
      getComputedStyle(resizerWrapperRef?.current as Element, null)
        .getPropertyValue("width")
        .replace("px", "")
    );
    resizeOption.originalHeight = parseFloat(
      getComputedStyle(resizerWrapperRef?.current as Element, null)
        .getPropertyValue("height")
        .replace("px", "")
    );
    resizeOption.originalMouseX = e.pageX;
    resizeOption.originalMouseY = e.pageY;
    handleResizeOption(Object.assign(resizeOption, resizeOption));
    handleIsImgResize(() => true);
  };

  const resize = (e: MouseEvent) => {
    if (!resizeOption.target || !isImgResize) return;
    const targetElement = eventTargetDocument?.querySelector(`.${clickTargetElementClass}`);
    const imageScaleElement = eventTargetDocument?.querySelector<HTMLInputElement>(
      `.${resizerStyles.imageScale}`
    );

    if (!targetElement) {
      return;
    }

    const computedStyle = window.getComputedStyle(targetElement);
    const properties = {
      right: computedStyle?.getPropertyValue("padding-right"),
      left: computedStyle?.getPropertyValue("padding-left"),
    };
    const regex = /(.+)px/;
    const matches = {
      left: properties.left.match(regex),
      right: properties.right.match(regex),
    };
    const targetElementPaddingRight = matches.right ? parseInt(matches.right[1]) : 0;
    const targetElementPaddingLeft = matches.left ? parseInt(matches.left[1]) : 0;

    const imageMinimumSize = 30;
    const imageMaxSize =
      targetElement.getBoundingClientRect().width -
      targetElementPaddingRight -
      targetElementPaddingLeft -
      10;

    const calcResult = {
      width: resizeOption.originalWidth + (e.pageX - resizeOption.originalMouseX),
      height: resizeOption.originalHeight + (e.pageY - resizeOption.originalMouseY),
    };
    if (calcResult.width > imageMinimumSize) {
      if (resizerWrapperRef.current)
        resizerWrapperRef.current.style.width = `${calcResult.width}px`;
      if (resizeOption.target)
        resizeOption.target.setAttribute("width", calcResult.width.toString());
    }
    const selectedImageHeight = Math.floor(resizeOption.target.getBoundingClientRect().height);

    resizeOption.target.setAttribute("height", selectedImageHeight.toString());

    if (imageMaxSize < calcResult.width) {
      if (resizerWrapperRef.current) resizerWrapperRef.current.style.width = `${imageMaxSize}px`;
      resizeOption.target.setAttribute("width", imageMaxSize.toString());
      calcResult.width = imageMaxSize;
      return;
    }

    if (imageScaleElement)
      imageScaleElement.innerText = `${Math.floor(calcResult.width)} x ${Math.floor(
        selectedImageHeight
      )}`;

    if (resizerWrapperRef.current) {
      resizerWrapperRef.current.style.height = `${selectedImageHeight}px`;
      resizerWrapperRef.current.style.left = `${
        resizeOption.target.getBoundingClientRect().left
      }px`;
    }
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (isFindTargetParentNodeByClassName(e.target, `${imageDropdownStyles.titleWrapper}`)) return;
    const textOptions = document.querySelectorAll<HTMLOptionElement>(".text-option");
    textOptions.forEach((option) => {
      option.style.display = "flex";
    });
    if (resizerWrapperRef.current) resizerWrapperRef.current.style.display = "none";
    resizeOption.target = null;
    handleResizeOption(Object.assign({}, resizeOption));
  };

  return (
    <div
      className={`${resizerStyles.imageResizable}`}
      ref={resizerWrapperRef}
    >
      <div
        className={resizerStyles.resizers}
        ref={resizerBodyRef}
      >
        <div
          className={`${resizerStyles.resizer} ${resizerStyles.bottomRight}`}
          onMouseDown={(e) => settingResize(e)}
        ></div>
      </div>
      <div className={resizerStyles.imageScale}></div>
    </div>
  );
};
