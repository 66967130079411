import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleHtmlPartCategoryApi } from "api";
import { ArticleHtmlPartCategoryProps } from "domains";

export type ArticleHtmlPartCategory = {
  items: Array<ArticleHtmlPartCategoryProps>;
};

const ArticleHtmlPartCategoryState = atom<ArticleHtmlPartCategory>({
  key: "articleHtmlPartCategory",
  default: {
    items: [],
  },
});

export const useArticleHtmlPartCategory = () => {
  const [articleHtmlPartCategoryList, setArticleHtmlPartCategoryList] = useRecoilState(
    ArticleHtmlPartCategoryState
  );

  useEffect(() => {
    if (articleHtmlPartCategoryList.items.length > 0) return;
    ArticleHtmlPartCategoryApi.fetchArticleHtmlPartCategories()
      .then((data) => {
        setArticleHtmlPartCategoryList({
          items: data,
        });
      })
      .catch(() => {});
  }, []);

  return [articleHtmlPartCategoryList] as const;
};
