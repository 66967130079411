import { useTranslation } from "react-i18next";

const OpenLinkNewTabDescription = () => {
  const { t } = useTranslation();
  return (
    <div>
      {t(
        "別タブで開く必要がない場合、別タブで開かないことを推奨します。同じタブで開いた方がCV計測精度を高くできます。",
      )}
    </div>
  );
};

export default OpenLinkNewTabDescription;
