import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { BranchOperationTeamMemberConversionEventTagProps } from "domains";
import { useLogging } from "hooks/logging";
import BranchOperationTeamMemberConversionEventTagApi from "javascripts/api/branchOperations/teamMembers/conversionEventTags/branchOperationUserColumn";

export const branchOperationTeamMemberConversionEventTagListState = atom<
  BranchOperationTeamMemberConversionEventTagProps[]
>({
  key: "branchOperationTeamMemberConversionEventTagListState",
  default: [],
});

export const useBranchOperationTeamMemberConversionEventTagList = () => {
  const [list, setList] = useRecoilState(branchOperationTeamMemberConversionEventTagListState);

  useEffect(() => {
    BranchOperationTeamMemberConversionEventTagApi.fetchColumns().then((data) => {
      setList(data.conversion_event_tags);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};

export const useCreateBranchOperationTeamMemberConversionEventTagList = () => {
  const [_, setList] = useRecoilState(branchOperationTeamMemberConversionEventTagListState);
  const { sendErrorLog } = useLogging();

  const handle = useCallback(async (params: {}) => {
    try {
      const response = await BranchOperationTeamMemberConversionEventTagApi.createColumns(params);
      setList(response.conversion_event_tags);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to create branch operation team member conversion event tag column",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};
