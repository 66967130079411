import React from "react";

type Props = {
  width: string;
  height: string;
};

export const LineIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-234.000000, -99.000000)">
          <g transform="translate(234.000000, 99.000000)">
            <rect
              fill="#00B901"
              x="0"
              y="0"
              width="24"
              height="24"
              rx="4"
            ></rect>
            <path
              d="M12,4 C17.5228475,4 22,7.35786438 22,11.5 C22,14.7871921 19.1802965,17.5804515 15.2559561,18.5934783 C14.087855,19.637643 12.821098,20.4253945 11.4552519,20.9556546 L11.0812878,21.0935977 C10.8205743,21.1847022 10.5353695,21.047207 10.4442649,20.7864935 C10.418365,20.7123757 10.4102814,20.6332009 10.4206684,20.5553783 C10.5163747,19.838317 10.3340174,19.2628984 9.87359631,18.8291227 C5.37198371,18.0987804 2,15.0947286 2,11.5 C2,7.35786438 6.4771525,4 12,4 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M11.9993126,9.12478598 L14,11.9825313 L14,9.5 C14,9.22385763 14.2238576,9 14.5,9 C14.7761424,9 15,9.22385763 15,9.5 L15,13.5 C15,13.7537338 14.8109997,13.9633243 14.5661011,13.9956693 C14.3742305,14.0379382 14.1675072,13.9647356 14.0480428,13.7941226 L12,10.8685313 L12,13.5 C12,13.7761424 11.7761424,14 11.5,14 C11.2238576,14 11,13.7761424 11,13.5 L11,9.5 C11,9.33782114 11.0772137,9.19367651 11.1968848,9.10232229 C11.22589,9.06510758 11.2615518,9.03098436 11.3029484,9.00199818 C11.529151,8.84360942 11.8409239,8.89858339 11.9993126,9.12478598 Z M5.5,9 C5.77614237,9 6,9.22385763 6,9.5 L6,12.9995313 L7.5,13 C7.77614237,13 8,13.2238576 8,13.5 C8,13.7761424 7.77614237,14 7.5,14 L5.5,14 C5.22385763,14 5,13.7761424 5,13.5 L5,9.5 C5,9.22385763 5.22385763,9 5.5,9 Z M9.5,9 C9.77614237,9 10,9.22385763 10,9.5 L10,13.5 C10,13.7761424 9.77614237,14 9.5,14 C9.22385763,14 9,13.7761424 9,13.5 L9,9.5 C9,9.22385763 9.22385763,9 9.5,9 Z M18.5,9 C18.7761424,9 19,9.22385763 19,9.5 C19,9.77614237 18.7761424,10 18.5,10 L17,9.99953133 L17,10.9995313 L18.5,11 C18.7761424,11 19,11.2238576 19,11.5 C19,11.7761424 18.7761424,12 18.5,12 L17,11.9995313 L17,12.9995313 L18.5,13 C18.7761424,13 19,13.2238576 19,13.5 C19,13.7761424 18.7761424,14 18.5,14 L16.5,14 C16.2238576,14 16,13.7761424 16,13.5 L16,9.5 C16,9.22385763 16.2238576,9 16.5,9 L18.5,9 Z"
              fill="#00B900"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
