import { ReactEventHandler, useEffect, useRef } from "react";

import appDropdownStyles from "stylesheets/components/partials/appDropdown.module.scss";
import { AppDropDownProps } from "./type";

export const AppDropdown = ({
  topBottom,
  leftRight,
  control = "click",
  theme = "lightTheme",
  triggerWidth = "",
  triggerHeight = "",
  dropdownHeight = "",
  onAfterOpen = null,
  onAfterClose = null,
  additionalEventComponent = null,
  children,
}: AppDropDownProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const trigger = children[0];
  const body = children[1];
  const isArrowDisplayed =
    topBottom === "center" ||
    leftRight === "center" ||
    leftRight === "leftCenter" ||
    leftRight === "rightCenter" ||
    leftRight === "rightLowerHalf" ||
    leftRight === "leftLowerHalf" ||
    leftRight === "rightUpperHalf" ||
    leftRight === "leftUpperHalf";

  const adjustPosition = () => {
    // NOTE: hover用、top: 20pxなどではmouseLeaveしてしまうので、透明なborderを設置してmouseLeaveしないようにする。
    const transparentBorderStyle = "8px solid transparent";
    const triggerRect = triggerRef.current?.getBoundingClientRect();
    const bodyRect = bodyRef.current?.getBoundingClientRect();

    if (!bodyRef.current) return;

    if (topBottom === "top") {
      bodyRef.current.style.top = "0";
      const marginTop = 0 - (bodyRect?.height || 0);
      bodyRef.current.style.marginTop = `${marginTop}px`;
      bodyRef.current.style.borderBottom = transparentBorderStyle;
      if (isArrowDisplayed && arrowRef.current) {
        arrowRef.current.classList.add(appDropdownStyles.bottom);
        const arrowRect = arrowRef.current.getBoundingClientRect();
        arrowRef.current.style.left = `${(bodyRect?.width || 0) / 2 - arrowRect.width / 2}px`;
      }
    } else if (topBottom === "center") {
      bodyRef.current.style.top = "50%";
      bodyRef.current.style.marginTop = `-${(bodyRect?.height || 0) / 2}px`;
    } else if (topBottom === "bottom") {
      bodyRef.current.style.top = `${triggerRect?.height || 0}px`;
      bodyRef.current.style.borderTop = transparentBorderStyle;
      if (isArrowDisplayed && arrowRef.current) {
        arrowRef.current.classList.add(appDropdownStyles.top);
        const arrowRect = arrowRef.current.getBoundingClientRect();
        arrowRef.current.style.left = `${(bodyRect?.width || 0) / 2 - arrowRect.width / 2}px`;
      }
    }

    if (leftRight === "left") {
      const right = triggerRect?.width || 0;
      bodyRef.current.style.right = `${right}px`;
      bodyRef.current.style.borderRight = transparentBorderStyle;
      if (isArrowDisplayed && arrowRef.current) {
        arrowRef.current.classList.add(appDropdownStyles.right);
      }
    } else if (leftRight === "center") {
      bodyRef.current.style.left = "50%";
      bodyRef.current.style.marginLeft = `-${(bodyRect?.width || 0) / 2}px`;
    } else if (leftRight === "right") {
      const left = triggerRect?.width || 0;
      bodyRef.current.style.left = `${left}px`;
      bodyRef.current.style.borderLeft = transparentBorderStyle;
      if (isArrowDisplayed && arrowRef.current) {
        arrowRef.current.classList.add(appDropdownStyles.left);
      }
    } else if (leftRight === "leftCenter") {
      const arrowRect = arrowRef?.current?.getBoundingClientRect();
      bodyRef.current.style.right = "0";
      bodyRef.current.style.left = "auto";
      if (arrowRef.current) {
        arrowRef.current.style.right = `${
          (triggerRect?.width || 0) / 2 - (arrowRect?.width || 0) / 2
        }px`;
        arrowRef.current.style.left = "auto";
      }
    } else if (leftRight === "rightCenter") {
      const arrowRect = arrowRef?.current?.getBoundingClientRect();
      bodyRef.current.style.right = "auto";
      bodyRef.current.style.left = "0";
      if (arrowRef.current) {
        arrowRef.current.style.right = "auto";
        arrowRef.current.style.left = `${
          (triggerRect?.width || 0) / 2 - (arrowRect?.width || 0) / 2
        }px`;
      }
    } else if (leftRight === "rightLowerHalf") {
      bodyRef.current.style.top = `${(bodyRect?.height || 0) / 2}px`;
      bodyRef.current.style.right = "auto";
      bodyRef.current.style.left = `${triggerRect?.width || 0}px`;
      bodyRef.current.style.borderLeft = transparentBorderStyle;
      if (arrowRef.current) {
        arrowRef.current.style.top = `${(triggerRect?.height || 0) / 4}px`;
        arrowRef.current.style.right = "auto";
        arrowRef.current.style.left = "0";
        if (isArrowDisplayed) {
          arrowRef.current.classList.add(appDropdownStyles.rightLowerHalf);
        }
      }
    } else if (leftRight === "rightUpperHalf") {
      bodyRef.current.style.top = "auto";
      bodyRef.current.style.bottom = "0";
      bodyRef.current.style.right = "auto";
      bodyRef.current.style.left = `${triggerRect?.width || 0}px`;
      bodyRef.current.style.borderLeft = transparentBorderStyle;
      if (arrowRef.current) {
        arrowRef.current.style.top = "auto";
        arrowRef.current.style.bottom = `${(triggerRect?.height || 0) / 4}px`;
        arrowRef.current.style.right = "auto";
        arrowRef.current.style.left = "0";
        if (isArrowDisplayed) {
          arrowRef.current.classList.add(appDropdownStyles.rightUpperHalf);
        }
      }
    } else if (leftRight === "leftLowerHalf") {
      bodyRef.current.style.top = `${(bodyRect?.height || 0) / 2}px`;
      bodyRef.current.style.left = "auto";
      bodyRef.current.style.right = `${triggerRect?.width || 0}px`;
      bodyRef.current.style.borderRight = transparentBorderStyle;
      if (arrowRef.current) {
        arrowRef.current.style.top = `${(triggerRect?.height || 0) / 4}px`;
        arrowRef.current.style.left = "auto";
        arrowRef.current.style.right = "0";
        if (isArrowDisplayed) {
          arrowRef.current.classList.add(appDropdownStyles.leftLowerHalf);
        }
      }
    } else if (leftRight === "leftUpperHalf") {
      bodyRef.current.style.top = "auto";
      bodyRef.current.style.bottom = "0";
      bodyRef.current.style.left = "auto";
      bodyRef.current.style.right = `${triggerRect?.width || 0}px`;
      bodyRef.current.style.borderRight = transparentBorderStyle;
      if (arrowRef.current) {
        arrowRef.current.style.top = "auto";
        arrowRef.current.style.bottom = `${(triggerRect?.height || 0) / 4}px`;
        arrowRef.current.style.left = "auto";
        arrowRef.current.style.right = "0";
        if (isArrowDisplayed) {
          arrowRef.current.classList.add(appDropdownStyles.leftLowerHalf);
        }
      }
    }
  };

  const handleClickDropdown: ReactEventHandler = () => {
    if (control !== "click") {
      return;
    }
    bodyRef?.current?.classList.toggle(appDropdownStyles.open);
    adjustPosition();
    if (onAfterOpen && bodyRef?.current?.classList.contains(appDropdownStyles.open)) {
      if (!onAfterOpen(bodyRef)) bodyRef.current.classList.remove(appDropdownStyles.open);
      return;
    }
    if (onAfterClose && !bodyRef?.current?.classList.contains(appDropdownStyles.open)) {
      onAfterClose(bodyRef);
    }
  };

  const handleMouseOverDropdown: ReactEventHandler = () => {
    if (control !== "hover") {
      return;
    }
    bodyRef?.current?.classList.add(appDropdownStyles.open);
    adjustPosition();
  };

  const handleMouseLeaveDropdown: ReactEventHandler = () => {
    if (control !== "hover") {
      return;
    }
    bodyRef?.current?.classList.remove(appDropdownStyles.open);
  };

  const handleDocumentClick: EventListener = (e) => {
    const clickedElement = e.target as HTMLDivElement;
    if (!clickedElement) return;
    let currentNode = clickedElement;

    let isInner = false;
    while (currentNode.parentNode) {
      if (currentNode === wrapperRef.current) {
        isInner = true;
        break;
      }

      currentNode = currentNode.parentNode as HTMLDivElement;
    }
    if (!isInner) {
      if (bodyRef?.current?.classList.contains(appDropdownStyles.open) && onAfterClose) {
        onAfterClose(bodyRef);
      }
      bodyRef?.current?.classList.remove(appDropdownStyles.open);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick, true);

    if (additionalEventComponent) {
      document
        ?.querySelector(additionalEventComponent)
        ?.ownerDocument.addEventListener("click", handleDocumentClick);
    }
    return function cleanup() {
      document.removeEventListener("click", handleDocumentClick, true);
      if (additionalEventComponent && document.querySelector(additionalEventComponent)) {
        document
          ?.querySelector(additionalEventComponent)
          ?.ownerDocument.removeEventListener("click", handleDocumentClick);
      }
    };
  }, []);

  return (
    <div
      className={`${appDropdownStyles.dropdown} ${appDropdownStyles[theme]}`}
      style={{ width: `${triggerWidth}`, height: `${dropdownHeight}` }}
      ref={wrapperRef}
      onMouseLeave={handleMouseLeaveDropdown}
    >
      <div
        className={appDropdownStyles.trigger}
        style={{ height: `${triggerHeight}` }}
        onClick={handleClickDropdown}
        onMouseOver={handleMouseOverDropdown}
        ref={triggerRef}
      >
        {trigger}
      </div>
      <div
        className={appDropdownStyles.bodyWrapper}
        ref={bodyRef}
      >
        <div className={appDropdownStyles.body}>
          {body}
          <div
            className={appDropdownStyles.arrow}
            ref={arrowRef}
          />
        </div>
      </div>
    </div>
  );
};
