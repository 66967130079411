import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import {
  ArticleHtmlPartApi,
  CreateArticleHtmlPartFromOriginalRequestParams,
  CreateArticleHtmlPartRequestParams,
} from "api";
import { ArticleHtmlPartProps } from "domains";
import { useLogging } from "hooks";
import TeamMemberFavoriteArticleHtmlPartApi from "javascripts/api/teams/members/articles/htmlParts/favorite";
import { removeItemAtIndex, replaceItemAtIndex } from "utils";

export type ArticleHtmlPart = {
  items: Array<ArticleHtmlPartProps>;
  nextPage: number;
};

const articleHtmlPartListState = atom<ArticleHtmlPart>({
  key: "articleHtmlPartListState",
  default: {
    items: [],
    nextPage: 0,
  },
});

type FetchArticleHtmlPartParameterProps = {
  category_id?: string | number | null;
  include_admin_part?: boolean;
  page?: number;
  query?: string;
  fetch_favorite_part?: boolean;
  target_part_ids?: Array<number>;
};

export type FetchArticleHtmlPartListProps = (params: FetchArticleHtmlPartParameterProps) => void;
export const useFetchArticleHtmlPartList = () => {
  const [articleHtmlPartList, setArticleHtmlPartList] = useRecoilState(articleHtmlPartListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, _setError] = useState<any>();
  const { sendErrorLog } = useLogging();

  const handle: FetchArticleHtmlPartListProps = useCallback(
    async (params: FetchArticleHtmlPartParameterProps) => {
      setLoading(true);
      try {
        const isFavorite = params.category_id === "favorite";
        params.fetch_favorite_part = isFavorite;
        const items = params.page === 1 ? [] : articleHtmlPartList.items;
        const response = await ArticleHtmlPartApi.fetchArticleHtmlParts(params);
        setArticleHtmlPartList({
          items: [...items, ...response.html_parts],
          nextPage: response.next_page,
        });
      } catch (e) {
        sendErrorLog({
          error: e,
          message: "Fetch Article Html Part Error",
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleHtmlPartList],
  );

  return [handle, error, loading] as const;
};

export type UpdateArticleHtmlPartProps = (partId: number, params: {}) => Promise<void>;
export const useUpdateArticleHtmlPart = () => {
  const [articleHtmlPartList, setArticleHtmlPartList] = useRecoilState(articleHtmlPartListState);
  const [loading, setLoading] = useState<boolean>(false);
  const { sendErrorLog } = useLogging();

  const handle: UpdateArticleHtmlPartProps = useCallback(
    async (partId: number, params: {}) => {
      setLoading(true);
      try {
        const response = await ArticleHtmlPartApi.updateArticleHtmlPart(params, partId);
        const index = articleHtmlPartList.items.findIndex((part) => part.id === partId);
        setArticleHtmlPartList({
          ...articleHtmlPartList,
          items: replaceItemAtIndex(articleHtmlPartList.items, index, response),
        });
      } catch (e) {
        sendErrorLog({
          error: e,
          message: "Update Article Html Part Error",
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleHtmlPartList],
  );

  return [handle, loading] as const;
};

export type DeleteArticleHtmlPartProps = (partId: number) => void;
export const useDeleteArticleHtmlPart = () => {
  const [articleHtmlPartList, setArticleHtmlPartList] = useRecoilState(articleHtmlPartListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, _setError] = useState<any>();

  const handle: DeleteArticleHtmlPartProps = useCallback(
    async (partId: number) => {
      setLoading(true);
      try {
        await ArticleHtmlPartApi.deleteArticleHtmlPart(partId);
        const index = articleHtmlPartList.items.findIndex((part) => part.id === partId);
        setArticleHtmlPartList({
          ...articleHtmlPartList,
          items: removeItemAtIndex(articleHtmlPartList.items, index),
        });
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleHtmlPartList],
  );

  return [handle, error, loading] as const;
};

export type CreateArticleHtmlPartProps = (
  params: CreateArticleHtmlPartRequestParams,
) => Promise<void>;
export const useCreateArticleHtmlPart = () => {
  const [articleHtmlPartList, setArticleHtmlPartList] = useRecoilState(articleHtmlPartListState);
  const [loading, setLoading] = useState<boolean>(false);
  const { sendErrorLog } = useLogging();

  const handle: CreateArticleHtmlPartProps = useCallback(
    async (params: CreateArticleHtmlPartRequestParams) => {
      setLoading(true);
      try {
        const response = await ArticleHtmlPartApi.createArticleHtmlPart(params);
        setArticleHtmlPartList({
          ...articleHtmlPartList,
          items: [response, ...articleHtmlPartList.items],
        });
      } catch (e) {
        sendErrorLog({
          error: e,
          message: "Create Article Html Part Error",
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleHtmlPartList],
  );

  return [handle, loading] as const;
};

export type CreateArticleHtmlPartFromOriginalProps = (
  params: CreateArticleHtmlPartFromOriginalRequestParams,
) => Promise<void>;
export const useCreateArticleHtmlPartFromOriginal = () => {
  const [articleHtmlPartList, setArticleHtmlPartList] = useRecoilState(articleHtmlPartListState);
  const [loading, setLoading] = useState<boolean>(false);
  const { sendErrorLog } = useLogging();

  const handle: CreateArticleHtmlPartFromOriginalProps = useCallback(
    async (params: CreateArticleHtmlPartFromOriginalRequestParams) => {
      setLoading(true);
      try {
        const response = await ArticleHtmlPartApi.createArticleHtmlPart(params);
        setArticleHtmlPartList({
          ...articleHtmlPartList,
          items: [response, ...articleHtmlPartList.items],
        });
      } catch (e) {
        sendErrorLog({
          error: e,
          message: "Create Article Html Part From Original Error",
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleHtmlPartList],
  );

  return [handle, loading] as const;
};

export type FetchArticleHtmlPartProps = (
  partId: number,
  params?: {},
) => Promise<ArticleHtmlPartProps>;
export const useFetchArticleHtmlPart = () => {
  const handle: FetchArticleHtmlPartProps = useCallback(async (partId, params = {}) => {
    try {
      const response = await ArticleHtmlPartApi.fetchArticleHtmlPart(partId, params);
      return response;
    } catch (e) {}
  }, []);

  return [handle] as const;
};

export const useToggleFavoriteArticleHtmlPart = () => {
  const [articleHtmlPartList, setArticleHtmlPartList] = useRecoilState(articleHtmlPartListState);
  const [error, _setError] = useState<any>();

  const handle = useCallback(
    async (partId: number) => {
      await TeamMemberFavoriteArticleHtmlPartApi.registFavoriteArticlePart(partId)
        .then((data) => {
          const articlePartIndex = articleHtmlPartList.items.findIndex(
            (part) => part.id === data["data"]["article_html_part_id"],
          );
          const isFavorite = !articleHtmlPartList.items[articlePartIndex].is_favorite;
          setArticleHtmlPartList({
            ...articleHtmlPartList,
            items: replaceItemAtIndex(articleHtmlPartList.items, articlePartIndex, {
              ...articleHtmlPartList.items[articlePartIndex],
              is_favorite: isFavorite,
            }),
          });
        })
        .catch(() => {});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [articleHtmlPartList],
  );

  return [handle, error] as const;
};

export const useArticleHtmlPartList = (params?: FetchArticleHtmlPartParameterProps) => {
  const [articleHtmlPartList, setArticleHtmlPartList] = useRecoilState(articleHtmlPartListState);
  const [fetchArticleHtmlPartList, _error, loading] = useFetchArticleHtmlPartList();

  useEffect(() => {
    if (!params || articleHtmlPartList.nextPage !== 0) return;
    fetchArticleHtmlPartList(params);
  }, []);

  return [articleHtmlPartList, setArticleHtmlPartList, fetchArticleHtmlPartList, loading] as const;
};
