const isNumber = (number: unknown): number is number => {
  return typeof number === "number" || number instanceof Number;
};

export const parseNumber = (toParse: unknown): number => {
  if (!toParse || !isNumber(toParse) || isNaN(toParse)) {
    throw new Error(`Field is not set or type is not number: ${toParse}.`);
  }
  return toParse;
};

const isString = (text: unknown): text is string => {
  return typeof text === "string" || text instanceof String;
};

export const parseString = (toParse: unknown): string => {
  if (!toParse || !isString(toParse)) {
    throw new Error(`Field is not set or format is not string: ${toParse}.`);
  }
  return toParse;
};

export const parseBoolean = (toParse: unknown): boolean => {
  if (typeof toParse !== "boolean") {
    throw new Error(`Field is not set or format is not boolean: ${toParse}.`);
  }
  return toParse;
};
