import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const PopupIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 16"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-663.000000, -428.000000)"
          fill={iconColor}
        >
          <g transform="translate(310.000000, 110.000000)">
            <g transform="translate(30.000000, 60.000000)">
              <g transform="translate(20.000000, 59.000000)">
                <g transform="translate(0.000000, 154.000000)">
                  <g transform="translate(1.000000, 22.000000)">
                    <g transform="translate(285.000000, 15.000000)">
                      <path d="M41,8 C42.1045695,8 43,8.8954305 43,10 L43,22 C43,23.1045695 42.1045695,24 41,24 L19,24 C17.8954305,24 17,23.1045695 17,22 L17,10 C17,8.8954305 17.8954305,8 19,8 L41,8 Z M33,20 L27,20 C26.4477153,20 26,20.4477153 26,21 C26,21.5522847 26.4477153,22 27,22 L27,22 L33,22 C33.5522847,22 34,21.5522847 34,21 C34,20.4477153 33.5522847,20 33,20 L33,20 Z M39.1464466,10.4393398 C38.9511845,10.2440777 38.634602,10.2440777 38.4393398,10.4393398 C38.2440777,10.634602 38.2440777,10.9511845 38.4393398,11.1464466 L38.4393398,11.1464466 L38.7928932,11.4998932 L38.4393398,11.8535534 C38.2440777,12.0488155 38.2440777,12.365398 38.4393398,12.5606602 C38.634602,12.7559223 38.9511845,12.7559223 39.1464466,12.5606602 L39.1464466,12.5606602 L39.4998932,12.2068932 L39.8535534,12.5606602 C40.0488155,12.7559223 40.365398,12.7559223 40.5606602,12.5606602 C40.7559223,12.365398 40.7559223,12.0488155 40.5606602,11.8535534 L40.5606602,11.8535534 L40.2068932,11.4998932 L40.5606602,11.1464466 C40.7559223,10.9511845 40.7559223,10.634602 40.5606602,10.4393398 C40.365398,10.2440777 40.0488155,10.2440777 39.8535534,10.4393398 L39.8535534,10.4393398 L39.4998932,10.7928932 Z"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
