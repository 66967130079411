import React from "react";

type Props = {
  width: string;
  height: string;
};

export const CirquaIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-200.000000, -1200.000000)">
          <g transform="translate(200.000000, 1200.000000)">
            <rect
              fill="#333435"
              opacity="0"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
            <path
              d="M13,2 C16.8659932,2 20,5.13400675 20,9 L20,8.999 L20,9 C20,12.8659932 23.1340068,16 27,16 C30.8659932,16 34,12.8659932 34,9 C34,6.83668287 33.0186631,4.90256925 31.4768651,3.61853495 C36.6309606,7.23623069 40,13.2246747 40,20 C40,31.045695 31.045695,40 20,40 C8.954305,40 0,31.045695 0,20 C0,13.2246747 3.36903944,7.23623069 8.52324684,3.61853949 C9.73663358,2.60791357 11.2973239,2 13,2 Z"
              fill="#61B6DC"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
