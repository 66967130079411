import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class TeamMemberFolderGroupFolderOrderApi extends ApiBase {
  static fetch() {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/members/folder_groups/folders/orders`;
      axios.get(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static create(folderGroupId, folderId, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/members/folder_groups/${folderGroupId}/folders/${folderId}/orders`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static update(folderGroupId, folderId, orderId, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/members/folder_groups/${folderGroupId}/folders/${folderId}/orders/${orderId}`;
      axios.patch(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default TeamMemberFolderGroupFolderOrderApi;
