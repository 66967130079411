import { useEffect, useState } from "react";
import { useLocalStorage } from "hooks/local-storage";
import Cookies from "js-cookie";

const COOKIE_ID = "ui_style" as const;

export const useUiStyle = () => {
  const cookieValue = Cookies.get(COOKIE_ID) as "new" | "old" | undefined;
  const [uiStyle, setUiStyleState] = useState<"new" | "old">(cookieValue ?? "old");
  const [uiStyleFromStorage] = useLocalStorage<string>("ui_style", "old");

  const setUiStyle = (value: "new" | "old") => {
    setUiStyleState(value);
    Cookies.set(COOKIE_ID, value, {
      domain: ".squadbeyond.com",
      path: "/",
    });
  };

  useEffect(() => {
    if (!cookieValue) {
      setUiStyle(uiStyleFromStorage as "new" | "old");
      return;
    }
    setUiStyle(cookieValue);
  }, []);

  const isNewUiStyle = uiStyle === "new";
  return [isNewUiStyle, setUiStyle] as const;
};
