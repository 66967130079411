import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class BranchOperationTeamMemberConversionEventTagApi extends ApiBase {
  static fetchColumns() {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function (resolve, reject) {
      axios.get(`${baseUrl}/branch_operations/team_members/conversion_event_tags`, { withCredentials: true })
        .then(response => resolve(response.data))
        .catch(error => reject(error.response));
    });
  }
  static createColumns(params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function (resolve, reject) {
      axios.post(`${baseUrl}/branch_operations/team_members/conversion_event_tags`, params, { withCredentials: true })
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    });
  }
}

export default BranchOperationTeamMemberConversionEventTagApi;
