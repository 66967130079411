/** 空タグから閉じタグを削除する */
export const removeCloseTagInEmptyTag = (html: string) => {
  const emptyTags = [
    "img",
    "br",
    "hr",
    "input",
    "source",
    "meta",
    "link",
    "area",
    "base",
    "col",
    "embed",
    "param",
    "track",
    "wbr",
    "keygen",
  ];

  let copiedHtml = html;
  const endPattern = new RegExp(`<\\s*\\/\\s*(${emptyTags.join("|")}).*?>`, "g");

  const matchEnd = copiedHtml.match(endPattern);

  matchEnd?.forEach((tag) => {
    copiedHtml = copiedHtml.replace(tag, "");
  });

  return copiedHtml;
};
