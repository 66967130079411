import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const DesktopIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 53 46"
      version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-280.000000, -475.000000)"
          fill={iconColor}
        >
          <g transform="translate(250.000000, 80.000000)">
            <g transform="translate(20.000000, 120.000000)">
              <g transform="translate(10.296512, 39.000000)">
                <g transform="translate(0.000000, 220.000000)">
                  <g transform="translate(0.000000, 3.000000)">
                    <path d="M49,13 C50.6568542,13 52,14.3431458 52,16 L52,52 C52,53.6568542 50.6568542,55 49,55 L3,55 C1.34314575,55 2.02906125e-16,53.6568542 0,52 L0,16 C-2.02906125e-16,14.3431458 1.34314575,13 3,13 L49,13 Z M49,15 L3,15 C2.44771525,15 2,15.4477153 2,16 L2,16 L2,48 C2,48.5522847 2.44771525,49 3,49 L3,49 L49,49 C49.5522847,49 50,48.5522847 50,48 L50,48 L50,16 C50,15.4477153 49.5522847,15 49,15 L49,15 Z M17.5,58 L34.5,58 C34.7761424,58 35,58.2238576 35,58.5 C35,58.7761424 34.7761424,59 34.5,59 L17.5,59 C17.2238576,59 17,58.7761424 17,58.5 C17,58.2238576 17.2238576,58 17.5,58 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
