import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import { LoadingButton } from "@mui/lab";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import { TextButton } from "components/atoms";
import { FolderGroupProps } from "domains";
import { useMoveFolder } from "hooks";
import type { MoveFolderGroupProps, MoveFormValues } from "./type";

export const MoveFolderGroup = ({ folder, folderGroupList }: MoveFolderGroupProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [handleMove, loading] = useMoveFolder();

  const { register, handleSubmit, setValue } = useForm<MoveFormValues>();

  const folderGroupOptionList = folderGroupList.map((folderGroup: FolderGroupProps) => {
    return { label: folderGroup.name, id: folderGroup.id };
  });

  const onSubmit = async (data: MoveFormValues) => {
    if (data.folderGroupId === undefined) {
      return;
    }

    await handleMove(data.folderGroupId, folder.id);
  };

  useEffect(() => {
    register("folderGroupId", {
      required: t("を選択してください", { value: t("フォルダグループ") }) || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  return (
    <>
      <TextButton
        onClick={handleOpen}
        align="left"
        startIcon={<DriveFileMoveIcon sx={{ width: "20px" }} />}
      >
        <Typography>{t("グループ移動する")}</Typography>
      </TextButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{t("グループ移動する")}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Autocomplete
              options={folderGroupOptionList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("フォルダグループ")}
                />
              )}
              onChange={(event, value) => setValue("folderGroupId", value?.id)}
              fullWidth
              sx={{ pt: 1, mb: 2 }}
            />
            <LoadingButton
              variant="contained"
              type="submit"
              loading={loading}
              disabled={loading}
            >
              {t("移動する")}
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
