import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form-v7";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Button,
} from "@mui/material";
import { TextButton } from "components/atoms";
import { FolderGroupProps, TeamDomainProps } from "domains";
import { useCreateFolder } from "hooks";
import { NewFolderFormProps, NewFolderFormValues } from "./type";

export const NewFolderForm = ({
  elementType = "text",
  icon = undefined,
  folderGroup,
  folderGroupList,
  thirdPartyDomainList,
  squadbeyondDomainList,
  updateFolder,
}: NewFolderFormProps) => {
  const [open, setOpen] = useState(false);
  const [handleCreateFolder, _loading, createFolderError] = useCreateFolder();
  const { t } = useTranslation();

  const discoverNewsTokyo = "discover-news.tokyo";

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
    setValue,
    watch,
    clearErrors,
  } = useForm<NewFolderFormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      folderGroupId: folderGroup?.id,
      inspectable: "false",
      roleType: "only_one_owner",
      squadbeyondDomainName: discoverNewsTokyo,
      squadbeyondDomainId: "",
      thirdPartyDomainId: "",
    },
  });

  useEffect(() => {
    register("subdomain", {
      maxLength: {
        value: 50,
        message: t("文字まで入力できます", { value: 50 }) || "",
      },
      pattern: {
        value: /^[A-Za-z0-9-]+$/,
        message: t("英数字とハイフンのみ使えます") || "",
      },
    });
    register("squadbeyondDomainName");
    register("squadbeyondDomainId");
    register("thirdPartyDomainId");
    register("folderGroupId");
    register("name", {
      required: t("を入力してください", { value: t("フォルダ名") }) || "",
    });
    register("inspectable");
    register("roleType");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (createFolderError) setError("root", { message: createFolderError.message });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFolderError]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    clearErrors();
  };

  const folderGroupOptionList = useMemo(
    () =>
      folderGroupList.map((folderGroup: FolderGroupProps) => {
        return { label: folderGroup.name, id: folderGroup.id };
      }),
    [folderGroupList]
  );

  const onSubmit = async (data: NewFolderFormValues) => {
    const params = {
      folder: {
        name: data.name,
        inspectable: data.inspectable,
        folder_group_id: data.folderGroupId,
        subdomain: data.subdomain,
        free_domain_name: data.squadbeyondDomainName,
        squadbeyond_domain_id: data.squadbeyondDomainId,
        third_party_domain_id: data.thirdPartyDomainId,
        role_type: data.roleType,
      },
    };
    const response = await handleCreateFolder(params);
    if (response) {
      updateFolder(response);
      handleClose();
    }
  };

  return (
    <>
      {elementType === "text" ? (
        <TextButton
          align="left"
          onClick={handleOpen}
          sx={{ p: 0 }}
          startIcon={icon}
        >
          {t("フォルダ作成")}
        </TextButton>
      ) : (
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{
            minWidth: "30px",
            boxSizing: "border-box",
            padding: 1,
          }}
        >
          {icon}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{t("フォルダ作成")}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              sx={{
                backgroundColor: "background.secondary",
                py: 1,
                px: 2,
                mb: 2,
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    mr: 1,
                    width: "85px",
                  }}
                >
                  https://sb-
                </Typography>
                <TextField
                  type="text"
                  placeholder=""
                  variant="standard"
                  error={!!errors.subdomain}
                  helperText={errors.subdomain?.message}
                  onChange={(e) => {
                    setValue("subdomain", e.target.value, { shouldValidate: true });
                    clearErrors("root");
                  }}
                  fullWidth
                  sx={{ mr: 0.5 }}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    mr: 0.5,
                  }}
                >
                  .
                </Typography>
                <FormControl fullWidth>
                  <InputLabel>{t("Squad beyondドメイン")}</InputLabel>
                  <Select
                    label={t("Squad beyondドメイン")}
                    defaultValue={discoverNewsTokyo}
                    value={watch("squadbeyondDomainName")}
                    onChange={(e) => {
                      setValue("squadbeyondDomainName", e.target.value, { shouldValidate: true });
                      setValue("squadbeyondDomainId", "");
                      setValue("thirdPartyDomainId", "");
                    }}
                  >
                    <MenuItem value={discoverNewsTokyo}>{discoverNewsTokyo}</MenuItem>
                    <MenuItem value="musthave-magazine.tokyo">musthave-magazine.tokyo</MenuItem>
                    <MenuItem value="myown-page.com">myown-page.com</MenuItem>
                    <MenuItem value="newsanything.tokyo">newsanything.tokyo</MenuItem>
                    <MenuItem value="ourpage.jp">ourpage.jp</MenuItem>
                    <MenuItem value="ourservice.jp">ourservice.jp</MenuItem>
                    <MenuItem value="service-info.jp">service-info.jp</MenuItem>
                    <MenuItem value="service-introduction.com">service-introduction.com</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
                <Typography sx={{ fontSize: "14px", mr: 1, width: "85px" }}>
                  {t("もしくは")}
                </Typography>
                <FormControl fullWidth>
                  <InputLabel>{t("登録済みSquad beyondドメイン")}</InputLabel>
                  <Select
                    label="登録済みSquad beyondドメイン"
                    defaultValue={""}
                    value={watch("squadbeyondDomainId")}
                    onChange={(e) => {
                      setValue("squadbeyondDomainId", e.target.value, { shouldValidate: true });
                      setValue("squadbeyondDomainName", "");
                      setValue("thirdPartyDomainId", "");
                    }}
                  >
                    {squadbeyondDomainList
                      .filter((domain: TeamDomainProps) => domain.status === "approved")
                      .map((domain: TeamDomainProps) => (
                        <MenuItem
                          key={domain.id}
                          value={domain.id}
                        >
                          {domain.domain}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", mb: 1, alignItems: "center" }}>
                <Typography sx={{ fontSize: "14px", mr: 1, width: "85px" }}>
                  {t("もしくは")}
                </Typography>
                <FormControl fullWidth>
                  <InputLabel>{t("認証済み独自ドメイン")}</InputLabel>
                  <Select
                    label="認証済み独自ドメイン"
                    defaultValue={""}
                    value={watch("thirdPartyDomainId")}
                    onChange={(e) => {
                      setValue("thirdPartyDomainId", e.target.value, { shouldValidate: true });
                      setValue("squadbeyondDomainId", "");
                      setValue("squadbeyondDomainName", "");
                    }}
                  >
                    {thirdPartyDomainList
                      .filter((domain: TeamDomainProps) => domain.status === "approved")
                      .map((domain: TeamDomainProps) => (
                        <MenuItem
                          key={domain.id}
                          value={domain.id}
                        >
                          {domain.domain}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Autocomplete
                options={folderGroupOptionList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("フォルダグループ")}
                  />
                )}
                defaultValue={{
                  label: folderGroup?.name || "",
                  id: folderGroup?.id || 0,
                }}
                onChange={(event, value) =>
                  setValue("folderGroupId", value?.id, { shouldValidate: true })
                }
                fullWidth
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <TextField
                type="text"
                label={t("フォルダ名")}
                placeholder=""
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                onChange={(e) => setValue("name", e.target.value, { shouldValidate: true })}
                fullWidth
                required
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel>{t("審査")}</InputLabel>
                <Select
                  label="審査"
                  defaultValue={watch("inspectable")}
                  onChange={(e) =>
                    setValue("inspectable", e.target.value, { shouldValidate: true })
                  }
                >
                  <MenuItem value={"false"}>{t("審査しない")}</MenuItem>
                  <MenuItem value={"true"}>{t("審査する")}</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel>{t("メンバー権限")}</InputLabel>
                <Select
                  label="メンバー権限"
                  defaultValue={watch("roleType")}
                  onChange={(e) => setValue("roleType", e.target.value, { shouldValidate: true })}
                >
                  <MenuItem value="only_one_owner">
                    {t("自分だけをフォルダーオーナーにする")}
                  </MenuItem>
                  <MenuItem value="all_member_owner_except_outside_and_guest_member">
                    {t("メンバー全員をフォルダーオーナーにする")}(
                    {t("フリーユーザーと無料招待枠を除く")})
                  </MenuItem>
                  <MenuItem value="all_member_owner_except_guest_member">
                    {t("メンバー全員をフォルダーオーナーにする")}({t("フリーユーザーを除く")})
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || !isValid}
            >
              {t("作成する")}
            </LoadingButton>
            {errors.root && (
              <Box sx={{ pt: 1 }}>
                <Typography
                  variant="subtitle2"
                  color="error"
                >
                  {errors.root?.message}
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
