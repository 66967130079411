import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestConversionEventTagApi extends ApiBase {
  static fetchTags(abTestUid) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/${abTestUid}/conversion_event_tags`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default AbTestConversionEventTagApi;
