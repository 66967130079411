import i18next from "i18next";

export const defaults = [i18next.t("選択肢1"), i18next.t("選択肢2"), i18next.t("選択肢3")];

export const genders = [i18next.t("男性"), i18next.t("女性"), i18next.t("無回答")];

export const prefectures = [
  i18next.t("北海道"),
  i18next.t("青森県"),
  i18next.t("岩手県"),
  i18next.t("宮城県"),
  i18next.t("秋田県"),
  i18next.t("山形県"),
  i18next.t("福島県"),
  i18next.t("茨城県"),
  i18next.t("栃木県"),
  i18next.t("群馬県"),
  i18next.t("埼玉県"),
  i18next.t("千葉県"),
  i18next.t("東京都"),
  i18next.t("神奈川県"),
  i18next.t("新潟県"),
  i18next.t("富山県"),
  i18next.t("石川県"),
  i18next.t("福井県"),
  i18next.t("山梨県"),
  i18next.t("長野県"),
  i18next.t("岐阜県"),
  i18next.t("静岡県"),
  i18next.t("愛知県"),
  i18next.t("三重県"),
  i18next.t("滋賀県"),
  i18next.t("京都府"),
  i18next.t("大阪府"),
  i18next.t("兵庫県"),
  i18next.t("奈良県"),
  i18next.t("和歌山県"),
  i18next.t("鳥取県"),
  i18next.t("島根県"),
  i18next.t("岡山県"),
  i18next.t("広島県"),
  i18next.t("山口県"),
  i18next.t("徳島県"),
  i18next.t("香川県"),
  i18next.t("愛媛県"),
  i18next.t("高知県"),
  i18next.t("福岡県"),
  i18next.t("佐賀県"),
  i18next.t("長崎県"),
  i18next.t("熊本県"),
  i18next.t("大分県"),
  i18next.t("宮崎県"),
  i18next.t("鹿児島県"),
  i18next.t("沖縄県"),
];

export const occupations = [
  i18next.t("会社員"),
  i18next.t("経営者・役員"),
  i18next.t("公務員"),
  i18next.t("自営業・フリーランス"),
  i18next.t("主夫/主婦"),
  i18next.t("アルバイト"),
  i18next.t("学生"),
];

export const industries = [
  i18next.t("IT・通信"),
  i18next.t("小売"),
  i18next.t("メーカー"),
  i18next.t("飲食"),
  i18next.t("医療・福祉・介護"),
  i18next.t("建築・設計・塗装"),
  i18next.t("金融・保険"),
  i18next.t("不動産"),
  i18next.t("運輸"),
  i18next.t("マスコミ・メディア"),
  i18next.t("広告代理店"),
  i18next.t("公的機関"),
  i18next.t("コンサルティング"),
  i18next.t("人材サービス"),
  i18next.t("出版・印刷"),
  i18next.t("教育・スクール"),
  i18next.t("美容・エステ"),
  i18next.t("士業"),
  i18next.t("農業・林業・漁業"),
  i18next.t("電気・ガス・水道"),
  i18next.t("スポーツ・レジャー・観光"),
  i18next.t("冠婚葬祭"),
];

export const mediums = [
  i18next.t("テレビ"),
  i18next.t("ラジオ"),
  i18next.t("新聞"),
  i18next.t("雑誌"),
  i18next.t("インターネット"),
  i18next.t("交通広告・看板"),
  i18next.t("知人・友人"),
];

export const employeeNums = [
  i18next.t(`1～5${i18next.t("人")}`),
  i18next.t(`6～10${i18next.t("人")}`),
  i18next.t(`11～50${i18next.t("人")}`),
  i18next.t(`51～100${i18next.t("人")}`),
  i18next.t(`101～200${i18next.t("人")}`),
  i18next.t(`200～500${i18next.t("人")}`),
  i18next.t(`500～1,000${i18next.t("人")}`),
  i18next.t(`1,000${i18next.t("人")}${i18next.t("以上")}`),
];
