import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const PreferencesIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 22"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-1050.000000, -19.000000)"
          fill={iconColor}
        >
          <path d="M1065,31 C1067.76142,31 1070,33.2385763 1070,36 C1070,38.7614237 1067.76142,41 1065,41 L1055,41 C1052.23858,41 1050,38.7614237 1050,36 C1050,33.2385763 1052.23858,31 1055,31 L1065,31 Z M1065,33 L1055,33 C1053.34315,33 1052,34.3431458 1052,36 C1052,37.6568542 1053.34315,39 1055,39 L1055,39 L1065,39 C1066.65685,39 1068,37.6568542 1068,36 C1068,34.3431458 1066.65685,33 1065,33 L1065,33 Z M1055,34 C1056.10457,34 1057,34.8954305 1057,36 C1057,37.1045695 1056.10457,38 1055,38 C1053.89543,38 1053,37.1045695 1053,36 C1053,34.8954305 1053.89543,34 1055,34 Z M1065,19 C1067.76142,19 1070,21.2385763 1070,24 C1070,26.7614237 1067.76142,29 1065,29 L1055,29 C1052.23858,29 1050,26.7614237 1050,24 C1050,21.2385763 1052.23858,19 1055,19 L1065,19 Z M1065,22 C1063.89543,22 1063,22.8954305 1063,24 C1063,25.1045695 1063.89543,26 1065,26 C1066.10457,26 1067,25.1045695 1067,24 C1067,22.8954305 1066.10457,22 1065,22 Z"></path>
        </g>
      </g>
    </svg>
  );
};
