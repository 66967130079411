import { TeamMemberApiResponseProps } from "domains";
import mixpanel from "mixpanel-browser";

export const generateTrackingBaseData = () => {
  return {
    Path: location.pathname,
    "Anchor Link": location.hash || undefined,
    "Query Parameters": location.search || undefined,
  };
};

export const mixpanelTrackClickEvent = (target: HTMLElement) => {
  const tagName = target.tagName.toLowerCase();
  const eventName = "Click";
  const tagNameList = [
    // Track outgoing links
    { tagName: "a", clickType: "Link" },
    // Track buttons overall
    { tagName: "button", clickType: "Button" },
    // Track checkboxes only
    { tagName: "input", clickType: "Checkbox" },
  ];

  const clickType = tagNameList.find((item) => item.tagName === tagName)?.clickType;
  const trackId = target.getAttribute("data-trackid");
  if (!clickType && !trackId) {
    return;
  }
  if (tagName === "input" && target.getAttribute("type") !== "checkbox") {
    return;
  }

  const clickEventBaseData = {
    "Inner Text": target.innerText,
    "Link Path": target.getAttribute("href"),
    "Track ID": trackId,
    ...generateTrackingBaseData(),
  };

  if (tagName === "input" && target.getAttribute("type") === "checkbox") {
    const label = target.parentElement?.querySelector("label");
    const labelText = label?.innerText;
    const eventCheckboxData = {
      ...clickEventBaseData,
      "Label Text": labelText,
    };
    mixpanel.track(eventName, eventCheckboxData);
    mixpanel.time_event(eventName);
  } else {
    mixpanel.track(eventName, clickEventBaseData);
    mixpanel.time_event(eventName);
  }
};

// Mixpanel analytics events
// Tracking initialized in App.tsx / Navigation
const clickEventListener = (event: Event) => {
  if (!(event.target instanceof HTMLElement)) {
    return;
  }
  mixpanelTrackClickEvent(event.target);
};

export const addTrackClickEventListener = () => {
  document.addEventListener("click", clickEventListener);
};

// Page view tracking
export const mixpanelTrackPageView = ({
  pageName,
  customProps = {},
}: {
  pageName?: string;
  customProps?: {
    [key: string]: string | number | undefined | null | boolean;
  };
} = {}) => {
  mixpanel.track(pageName ? `Page View ${pageName}` : "Page View", {
    Path: location.pathname,
    "Anchor Link": location.hash || undefined,
    "Query Parameters": location.search || undefined,
    ...customProps,
  });
};

const teamPlanName = (teamInviteCount: number | null): string => {
  // If teamInviteCount is null or without much data, return "Unknown"
  if (teamInviteCount === null) {
    return "Unknown";
  }
  switch (true) {
    case teamInviteCount === 1:
      return "Light";
    case teamInviteCount === 4:
      return "Standard";
    case teamInviteCount === 10:
      return "Pro";
    case teamInviteCount > 10:
      return "Enterprise";
    default:
      return "Unknown";
  }
};

export const mixpanelSetUserInfo = (memberAttributes: TeamMemberApiResponseProps): void => {
  const { team, user, member, plan, can_use_exit_popup, can_use_folder_form } = memberAttributes;
  const teamPlan = teamPlanName(plan.team_member_invite_count);

  // User data for event
  const eventTeamData =
    team && team.id
      ? {
          ID: team.id,
          "Created At": team.created_at,
          Plan: {
            Name: teamPlan,
            "Invite Count": plan.team_member_invite_count
              ? plan.team_member_invite_count
              : undefined,
          },
          Options: {
            "Exit Popup": can_use_exit_popup ? true : false,
            "Folder Form": can_use_folder_form ? true : false,
          },
        }
      : undefined;
  const eventMemberData =
    member && member.id
      ? {
          "Created At": member.created_at ? member.created_at : undefined,
          Role: member.role,
          ID: member.id,
        }
      : undefined;
  const eventUserData = {
    ID: user?.id,
  };

  // User data for profile
  const profileUserData = {
    "User ID": user?.id,
  };

  // Regist user info and you can see it in each event in "Events" tab
  // This is called super properties in mixpanel
  mixpanel.register({
    Team: eventTeamData,
    Member: eventMemberData,
    User: eventUserData,
  });

  // Set user profile and you can see it in each user in "Users" tab
  mixpanel.people.set(profileUserData);
};
