// Beyondエディタではiframe対応のためscriptで読み込んでいるが、このcomponentはフォーム単位で使いたい場合に使用するためreact-quillで実装する
import { useEffect } from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import { QuillEditorProps } from "./type";

const QuillEditor = ({
  editorRef,
  setEditorRef,
  value,
  className,
  theme,
  onChange,
  modules,
  placeholder,
}: QuillEditorProps) => {
  useEffect(() => {
    if (!editorRef) return;
    const editor = editorRef.getEditor();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore editorにthemeが無いと怒られるが、代替手段が見つからないため無視する
    const tooltip = editor.theme.tooltip;
    if (tooltip) {
      const input = tooltip.root.querySelector("input[data-link]");
      input.dataset.link = "https://squadbeyond.com";
    }
    const convertDelta = editor.clipboard.convert(value);
    editor.setContents(convertDelta);
  }, [editorRef]);

  return (
    <ReactQuill
      ref={(ref) => setEditorRef(ref)}
      className={className}
      theme={theme}
      onChange={onChange}
      placeholder={placeholder}
      modules={modules}
    />
  );
};

export default QuillEditor;
