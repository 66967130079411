import {
  useRef,
  useState,
  useMemo,
  ChangeEvent,
  ClipboardEventHandler,
  KeyboardEvent,
} from "react";

import { useTranslation } from "react-i18next";
import { useAlert } from "contexts/alert";
import formExternalPostSettingStyles from "stylesheets/components/forms/formExternalPostSetting.module.scss";
import { NameSettingFormProps } from "./type";

const NameSettingForm = ({ name, parameters, updateSettingParameter }: NameSettingFormProps) => {
  const { t } = useTranslation();
  const alertState = useAlert();
  const inputRef = useRef<HTMLInputElement>(null);

  const [isEdit, setIsEdit] = useState(false);

  const names = useMemo(() => {
    if (!parameters) return [];

    const array = Object.keys(parameters).map((key) => {
      if (key === name || !parameters[key]["value"]) return;

      return parameters[key]["value"];
    });
    return array;
  }, [parameters]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    //name属性に空白を入れることはなさそう＆連携ができない原因にもなるのでspaceの場合は入力させない
    if (e.code === "32") {
      e.preventDefault();
      return alertState.showAlerts({
        messages: [t("は入力できません", { value: t("空白") })],
        theme: "danger",
        isDisplayBtnClose: false,
        isAutoClose: true,
      });
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!parameters || !name) return;
    if (e.target.value === parameters[name]["value"]) return setIsEdit(false);
    setIsEdit(true);
  };

  const handleOnPaste: ClipboardEventHandler = (e) => {
    const pastingText = e.clipboardData.getData("text/plain");
    const spaceString = pastingText.match(/( | )+/g);
    if (spaceString) {
      e.preventDefault();
      return alertState.showAlerts({
        messages: [t("は入力できません", { value: t("空白を含むネーム属性") })],
        theme: "danger",
        isDisplayBtnClose: false,
        isAutoClose: true,
      });
    }
  };

  const update = (params: { id: number; value: HTMLInputElement["value"] }) => {
    if (!isEdit || !name) return;
    if (names.includes(params["value"])) {
      return alertState.showAlerts({
        messages: [t("が重複しています", { value: t("ネーム属性") })],
        theme: "danger",
        isDisplayBtnClose: false,
        isAutoClose: true,
      });
    }

    if (!updateSettingParameter) return;
    const promise = updateSettingParameter(params, name);
    promise.then((data) => {
      if (data) {
        setIsEdit(false);
      }
    });
  };

  if (!parameters || !name || !parameters[name]) return null;

  return (
    <>
      <input
        type="text"
        name={name}
        ref={inputRef}
        defaultValue={parameters[name]["value"]}
        placeholder={name}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        onPaste={handleOnPaste}
        className={`${formExternalPostSettingStyles.nameSettingForm} ${
          isEdit ? formExternalPostSettingStyles.editing : ""
        }`}
      />
      <div
        onClick={() => update({ id: parameters[name]["id"], value: inputRef.current?.value || "" })}
        className={`${formExternalPostSettingStyles.btnSave} ${
          isEdit ? formExternalPostSettingStyles.editing : ""
        }`}
      >
        保存
      </div>
    </>
  );
};

export default NameSettingForm;
