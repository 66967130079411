import React from 'react';

import articleHtmlPartModalStyles from 'stylesheets/components/article/htmlPart/modal.module.scss'
import { useTranslation } from "react-i18next";

const Description = ({
  partDescription,
  handlePartDescription
}) => {
  const { t } = useTranslation();

  return (
    <div className={articleHtmlPartModalStyles.descriptionWrapper}>
      <div className={articleHtmlPartModalStyles.title}>
        {t("説明文")}
      </div>
      <textarea
        name="description"
        placeholder={t("一定スクロールで下方中央に固定されるバナーです。") || ""}
        defaultValue={partDescription}
        onChange={(e) => handlePartDescription(e.target.value)}
      />
    </div>
  )
}

export default Description
