import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { AbTestArticleSplitTestSettingOsesApi } from "api";
import { ArticleProps } from "domains";

export const abTestSplitTestSettingOsesArticleListState = atom<ArticleProps[]>({
  key: "abTestSplitTestSettingOsesArticleListState",
  default: [],
});

export const useFetchAbTestSplitTestSettingOsesArticleList = (abTestUid: string) => {
  const [articleList, setArticleList] = useRecoilState(abTestSplitTestSettingOsesArticleListState);
  const [error, setError] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await AbTestArticleSplitTestSettingOsesApi.fetch(abTestUid);
        setArticleList(response.articles);
      } catch (e) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [articleList, error] as const;
};

export const useCreateAbTestSplitTestSettingOsesArticleList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (
      articleUid: string,
      params: {
        os_id: number;
      },
    ) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);
      try {
        await AbTestArticleSplitTestSettingOsesApi.create(articleUid, params);
        setLoading(false);
        return true;
      } catch (e) {
        setLoading(false);
        setError(e);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [handle, loading, error] as const;
};

export const useDestroyAbTestSplitTestSettingOsesArticleList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (
      articleUid: string,
      params: {
        os_id: number;
      },
    ) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);

      try {
        await AbTestArticleSplitTestSettingOsesApi.destroy(articleUid, params);
        setLoading(false);
        return true;
      } catch (e) {
        setLoading(false);
        setError(e);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [handle, loading, error] as const;
};
