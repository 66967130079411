import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  Popover,
  Box
} from '@mui/material';
import {
  useTheme
} from '@mui/material/styles';
import formatClearIcon from 'assets/images/clear-white.svg';
import indentIcon from 'assets/images/indent.svg';
import optionIcon from 'assets/images/option-white.svg';
import alignCenterIcon from 'assets/images/ql-align-center-white.svg';
import alignJustifyIcon from 'assets/images/ql-align-justify-white.svg';
import alignLeftIcon from 'assets/images/ql-align-left-white.svg';
import alignRightIcon from 'assets/images/ql-align-right-white.svg';
import fontFamilyIcon from 'assets/images/text-font-white.svg';
import textIcon from 'assets/images/text_white.svg';
import underlineIcon from 'assets/images/underline-white.svg';
import undoIcon from 'assets/images/undo-white.svg';
import imageIcon from 'assets/images/widget_image_white.svg';
import {
  ColorPicker
} from 'components';
import { AppDropdown } from "components/atoms/app-dropdown";
import { ArticleHtmlPartPreviewToolbarLinkDropDown } from 'components/molecules/article-html-part-preview-toolbar';
import { ImageDropdown } from 'components/molecules/image-dropdown';
import { targetParentNodeByTagName } from 'javascripts/utils';
import { html_beautify } from 'js-beautify';
import PropTypes from 'prop-types';
import rangy from 'rangy/lib/rangy-core.js';
import 'rangy/lib/rangy-classapplier';
import articleHtmlPartModalStyles from 'stylesheets/components/article/htmlPart/modal.module.scss';
import resizerStyles from 'stylesheets/components/editor/resizer.module.scss';
import appDropdownStyles from 'stylesheets/components/partials/appDropdown.module.scss';
import btnStyles from 'stylesheets/components/partials/btn.module.scss';
import editorToolbarStyles from 'stylesheets/components/quillEditor/editorToolbar.module.scss';
import linkDropdownStyles from 'stylesheets/components/quillEditor/linkDropdown.module.scss';
import { isVideoUrl , buildPictureElement, isValidUrl } from 'utils';

const beautifyOptions = {
  indent_size: 4,
  end_with_newline: true,
  preserve_newlines: true,
  max_preserve_newlines: 0,
  wrap_line_length: 0,
  wrap_attributes_indent_size: 0,
  unformatted: ["a", "span", "img", "code", "pre", "sub", "sup", "em", "strong", "b", "i", "u", "strike", "big", "small", "pre", "h1", "h2", "h3", "h4", "h5", "h6"]
};

const PreviewToolbar = ({
  operateFontOption,
  isCollapsed,
  htmlEditorRef,
  article,
  articlePhoto,
  selection,
  selectionRange,
  changeOperateFontOption,
  handlePartHtml,
  handleArticlePhotos,
  fetchArticlePhotos,
  fetchArticlePhotosOnEditor,
  updateArticlePhotoPublishStatus
}) => {
  const { t } = useTranslation();
  const fontSizeRef = useRef();
  const fontSizeUnitRef = useRef();
  const linkFormRef = useRef();
  const checkBoxRef = useRef();
  const targetCheckBoxRef = useRef();
  const invalidTextRef = useRef();
  const partInlineOptionWrapperRef = useRef();

  useEffect(() => {
    rangy.init();
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    const partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe');
    if (partHtmlPreviewAreaIframe) partHtmlPreviewAreaIframe.contentDocument.addEventListener('click', handleDocumentClick);

    return function cleanup(){
      document.removeEventListener('click', handleDocumentClick);
      const partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe');
      if (partHtmlPreviewAreaIframe) partHtmlPreviewAreaIframe.contentDocument.removeEventListener('click', handleDocumentClick);
    };
  }, [operateFontOption]);

  const handleDocumentClick = (e) => {
    let currentNode = e.target;

    let isInner = false;
    while (currentNode.parentNode){
      if (currentNode === partInlineOptionWrapperRef.current) {
        isInner = true;
        break;
      }
      if (currentNode.classList.contains('preview-toolbar-color-picker')) {
        isInner = true;
        break;
      }
      currentNode = currentNode.parentNode;
    }
    if (!isInner) {
      let dropdown = partInlineOptionWrapperRef.current.querySelector(`.${appDropdownStyles.open}`);
      if (dropdown) dropdown.classList.remove(appDropdownStyles.open);
      changeOperateFontOption({ isShow: false });
    }
  };

  const closeDropdown = () => {
    let dropdown = document.querySelector(`.${appDropdownStyles.open}`);
    if (dropdown) dropdown.classList.remove(appDropdownStyles.open);
  };

  const applyBold = () => {
    if (isCollapsed) return;
    document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.execCommand('bold');
    reflectPreviewHtmlToEditor();
    changeOperateFontOption({ isShow: false });
  };

  const applyUnderline = () => {
    if (isCollapsed) return;
    document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.execCommand('underline');
    reflectPreviewHtmlToEditor();
    changeOperateFontOption({ isShow: false });
  };

  const applyFontFamly = (value) => {
    if (isCollapsed) return;
    document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.execCommand('fontName', false, value);
    reflectPreviewHtmlToEditor();
  };

  const applyGumMarker = (color) => {
    const preview = document.querySelector('#partHtmlPreviewAreaIframe').contentWindow;
    let style = `background: linear-gradient(to right, ${color} 50%, transparent 50%) 100% 0% / 200% 70% no-repeat; background: -webkit-linear-gradient(left, ${color}  50%, transparent 50%) 100% 0% / 200% 70% no-repeat;`;
    let applier = rangy.createClassApplier('sb-bg-gum-marker');
    applier.attrExceptions.push('style');
    applier.attrExceptions.push('data-color');
    applier.undoToSelection(preview);
    applier = rangy.createClassApplier('sb-bg-gum-marker', { elementAttributes: { style: style, [`data-color`]: color }});
    applier.applyToSelection(preview);
    reflectPreviewHtmlToEditor();
    closeDropdown();
    changeOperateFontOption({ isShow: false });
  };

  const applyUnderMarkerColor = (value, type) => {
    const borderSize = type === 'widget-sb-bg-under-marker-thin' ? "80%" : "60%";
    let preview = document.querySelector('#partHtmlPreviewAreaIframe').contentWindow;
    let style = `background: rgba(0, 0, 0, 0) linear-gradient(transparent ${borderSize}, ${value} 0%) repeat scroll 0 0;`;
    let applier = rangy.createClassApplier('sb-bg-under-marker');
    applier.attrExceptions.push('style');
    applier.undoToSelection(preview);
    applier = rangy.createClassApplier('sb-bg-under-marker', { elementAttributes: { style: style }});
    applier.applyToSelection(preview);
    reflectPreviewHtmlToEditor();
    closeDropdown();
    changeOperateFontOption({ isShow: false });
  };

  const applyColor = (value, type) => {
    if (isCollapsed) return;
    switch (type) {
      case 'ql-color':
        document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.execCommand('foreColor', false, value);
        break;
      case 'ql-background':
        document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.execCommand('backColor', false, value);
        break;
      default:
        return '';
    }
    reflectPreviewHtmlToEditor();
    closeDropdown();
    changeOperateFontOption({ isShow: false });
  };

  const applyFontSize = (value, unit="px") => {
    if (isCollapsed) return;
    let preview = document.querySelector('#partHtmlPreviewAreaIframe').contentWindow;
    //自由設定を追加前にclass管理をしていたので、消えるように残しておく
    let fontSizeClassNames = ['sb-fs-10', 'sb-fs-13', 'sb-fs-15', 'sb-fs-17', 'sb-fs-19', 'sb-fs-21', 'sb-fs-23', 'sb-fs-25', 'sb-fs-27', 'sb-fs-29', 'sb-fs-free'];
    fontSizeClassNames.forEach(function(name) {
       let applier = '';
      if (name === 'sb-fs-free') {
        applier = rangy.createClassApplier(name);
        //付与したstyleを消すためのoption
        //elementAttributesの値がセットしても付与した値をセットしないと消えないので手動でこれだけ追加すれば正常に置き換えができる
        applier.attrExceptions.push('style');
      } else {
        applier = rangy.createClassApplier(name);
      }
      applier.undoToSelection(preview);
    });
    let applier = rangy.createClassApplier('sb-fs-free', { elementAttributes: { style: `font-size: ${value}${unit};` }});
    applier.applyToSelection(preview);
    reflectPreviewHtmlToEditor();
    changeOperateFontOption(Object.assign(operateFontOption, { type: '', isShow: false }));
  };

  const applyAlign = (alignPosition) => {
    let imgNode = getImgTagFromSectionNode(selectionRange.startContainer);
    if (imgNode) {

      switch (alignPosition) {
        case 'justifyCenter':
          // window.getComputedStyle(imgNode).getPropertyValue('margin-right').match(/(.+)px/)[1]
          imgNode.style.marginLeft = '0';
          imgNode.style.marginRight = '0';
          imgNode.style.margin = '0 auto';
          break;
        case 'justifyLeft':
          imgNode.style.margin = '0';
          imgNode.style.marginLeft = '0';
          imgNode.style.marginRight = 'auto';
          break;
        case 'justifyRight':
          imgNode.style.margin = '0';
          imgNode.style.marginRight = '0';
          imgNode.style.marginLeft = 'auto';
          break;
        default:
          return '';
      }

    } else {
      document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.execCommand(alignPosition);
    }
    reflectPreviewHtmlToEditor();
    changeOperateFontOption({ isShow: false });
  };

  const clearFormat = () => {
    let iframe = document.querySelector('#partHtmlPreviewAreaIframe');
    iframe.contentDocument.execCommand('removeFormat');
    let removeFormatClass = ['sb-fs-10', 'sb-fs-13', 'sb-fs-15', 'sb-fs-17', 'sb-fs-19', 'sb-fs-21', 'sb-fs-23', 'sb-fs-25', 'sb-fs-27', 'sb-fs-29', 'sb-fs-free', 'sb-bg-under-marker', 'sb-bg-gum-marker'];
    removeFormatClass.forEach(function(name) {
      let applier = rangy.createClassApplier(name);
      applier.attrExceptions.push('style');
      applier.attrExceptions.push('data-color');
      applier.undoToSelection(iframe.contentWindow);
    });
    reflectPreviewHtmlToEditor();
  };

  const createLink = () => {
    selection.removeAllRanges();
    selection.addRange(selectionRange.cloneRange());
    //Link新規作成&編集
    if(!selection.rangeCount || selectionRange === null) return;
    let anchorNode = targetParentNodeByTagName(selection.anchorNode, 'A');
    const isInnerLink = linkFormRef.current.value.startsWith('#');
    if (anchorNode || selection.anchorNode.tagName === 'A')  {
      if (!isValidUrl(linkFormRef.current.value) && !isInnerLink) {
        invalidTextRef.current.classList.add(`${linkDropdownStyles.invalid}`);
        return false;
      }

      if (isInnerLink) {
        anchorNode.href = linkFormRef.current.value;
      } else {
        let url = new  URL(linkFormRef.current.value);
        if (url.protocol === 'tel:') {
          if (checkBoxRef.current.checked) {
            anchorNode.setAttribute('data-sb-tracking', 'true');
          } else {
            anchorNode.removeAttribute('data-sb-tracking');
          }
        } else {
          if (checkBoxRef.current.checked) {
            url.searchParams.set('sb_tracking', true);
          } else {
            url.searchParams.delete('sb_tracking');
          }
          anchorNode.removeAttribute('data-sb-tracking');
        }
        anchorNode.href = url;
        if (targetCheckBoxRef.current.checked) {
          anchorNode.setAttribute('target', '_blank');
        } else {
          anchorNode.removeAttribute('target');
        }
      }
    } else if (selection.anchorNode.nodeName === '#text') {
      if (!isValidUrl(linkFormRef.current.value) && !isInnerLink) {
        invalidTextRef.current.classList.add(`${linkDropdownStyles.invalid}`);
        return false;
      }

      let iframe = document.querySelector('#partHtmlPreviewAreaIframe').contentDocument;
      iframe.execCommand('createLink', false, 'add_link');
      let link = iframe.querySelector('a[href="add_link"]');
      if (isInnerLink) {
        link.href = linkFormRef.current.value;
      } else {
        let url = new URL(linkFormRef.current.value);
        if (url.protocol === 'tel:') {
          if (checkBoxRef.current.checked) {
            link.setAttribute('data-sb-tracking', 'true');
          } else {
            link.removeAttribute('data-sb-tracking');
          }
          link.href = url;
        } else {
          if (checkBoxRef.current.checked) {
            url.searchParams.set('sb_tracking', true);
          }
          link.href = url;
        }
        if (targetCheckBoxRef.current.checked) {
          link.setAttribute('target', '_blank');
        }
      }

    } else
    if (getImgTagFromSectionNode(selection.anchorNode)) {
      let targetNode = getImgTagFromSectionNode(selection.anchorNode);
      if (targetNode.tagName === 'IMG') {
        if (targetNode.parentNode && targetNode.parentNode.tagName === "PICTURE") {
          targetNode = targetNode.parentNode;
        }
      }
      const anchorElement = document.createElement('a');
      anchorElement.innerHTML = targetNode.outerHTML;
      if (isInnerLink) {
        anchorElement.setAttribute('href', linkFormRef.current.value);
      } else {
        let url = new URL(linkFormRef.current.value);
        anchorElement.setAttribute('href', url);
        if (targetCheckBoxRef.current.checked) {
          anchorElement.setAttribute('target', "_blank");
        }
        if (url.protocol === 'tel:') {
          if (checkBoxRef.current.checked) {
            anchorElement.setAttribute('data-sb-tracking', 'true');
          } else {
            anchorElement.setAttribute('data-sb-tracking', 'false');
          }

        } else {
          if (checkBoxRef.current.checked) {
            if (!isValidUrl(linkFormRef.current.value)) {
              invalidTextRef.current.classList.add(`${linkDropdownStyles.invalid}`);
              return false;
            }
            url.searchParams.set('sb_tracking', true);
            anchorElement.setAttribute('href', url);
          }
        }
      }
      targetNode.parentNode.replaceChild(anchorElement, targetNode);
    }
    const partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe');
    let imageResizerWrapper = partHtmlPreviewAreaIframe.contentDocument.querySelector(`.${resizerStyles.imageResizable}`);
    let htmlValue = partHtmlPreviewAreaIframe.contentDocument.querySelector('.htmlValue').innerHTML;
    linkFormRef.current.value = '';
    imageResizerWrapper.style.display = 'none';
    handlePartHtml(htmlValue);
    htmlEditorRef.current.editor.setValue(html_beautify(htmlValue, beautifyOptions));
    invalidTextRef.current.classList.remove(`${linkDropdownStyles.invalid}`);
    document.querySelector('.previewToolbarLinkDropdown').querySelector(`.${appDropdownStyles.bodyWrapper}`).classList.remove(appDropdownStyles.open);
  };

  const removeLink = () => {
    if(!selection.rangeCount || selectionRange === null) {
      return;
    }
    let anchorNode = targetParentNodeByTagName(selectionRange.startContainer, 'A');
    if (anchorNode) {
      let div = document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.createElement('div');
      div.innerHTML = anchorNode.innerHTML;
      let anchorNodeClass = anchorNode.className;
      if (div.classList.length > 0) {
        div.classList.add(anchorNodeClass);
      }
      anchorNode.parentNode?.replaceChild(div, anchorNode);
    }
    const partHtmlPreviewAreaIframe = document.querySelector('#partHtmlPreviewAreaIframe');
    let htmlValue = partHtmlPreviewAreaIframe.contentDocument.querySelector('.htmlValue').innerHTML;
    linkFormRef.current.value = '';
    checkBoxRef.current.defaultChecked = true;
    handlePartHtml(htmlValue);
    htmlEditorRef.current.editor.setValue(html_beautify(htmlValue, beautifyOptions));
    invalidTextRef.current.classList.remove(`${linkDropdownStyles.invalid}`);
    document.querySelector('.previewToolbarLinkDropdown').querySelector(`.${appDropdownStyles.bodyWrapper}`).classList.remove(appDropdownStyles.open);
  };

  const insertImage = (photo) => {
    let line = htmlEditorRef.current.editor.doc.getCursor().line;
    let ch = htmlEditorRef.current.editor.doc.getCursor().ch;
    if (isVideoUrl(photo.url)) {
      htmlEditorRef.current.editor.doc.replaceRange(`<video playsinline allowfullscreen="true" autoplay="true" controlslist="nodownload" loop="true" muted="true" name="media"><source src="${photo.url}" type="video/mp4"/></video>`, { line: line, ch: ch });
    } else {
      const picture = buildPictureElement(photo);
      htmlEditorRef.current.editor.doc.replaceRange(picture.outerHTML, { line: line, ch: ch });
    }
    setTimeout(() => {
      htmlEditorRef.current.editor.setValue(html_beautify(htmlEditorRef.current.props.value, beautifyOptions));
    }, 100);
  };
  const getImgTagFromSectionNode = (selectionElement) => {
    if (["PICTURE", "IMG", "VIDEO"].includes(selectionElement.tagName)) return selectionElement;
    let pathElement = selectionElement;
    let ImgNode = null;
    while (pathElement) {
      pathElement = pathElement.firstElementChild;
      if (pathElement && ["PICTURE","IMG", "VIDEO"].includes(selectionElement.tagName)) {
        ImgNode = pathElement;
        break;
      }
    }
    return ImgNode;
  };

  const reflectPreviewHtmlToEditor = () => {
    let previewIframeDoc  = document.querySelector(`.${articleHtmlPartModalStyles.partHtmlPreviewAreaIframe}`).contentDocument;
    let html = previewIframeDoc.querySelector('.htmlValue').innerHTML;
    htmlEditorRef.current.editor.setValue(html_beautify(html, beautifyOptions));
  };

  const handleToolTip = (e, isShow, tooltipName) => {
    let targetRect = e.target.getBoundingClientRect();
    let tooltip = document.querySelector(`.${articleHtmlPartModalStyles.tooltip}`);
    if (isShow) {
      tooltip.innerText = tooltipName;
      tooltip.style.display = 'block';
      tooltip.style.top = `${targetRect.top + 10}px`;
      tooltip.style.left = `${targetRect.left - 60}px`;
    } else {
      tooltip.style.display = 'none';
    }
  };

  const addIndent = () => {
    if (selection.getRangeAt && selection.rangeCount) {
      let br = document.querySelector('#partHtmlPreviewAreaIframe').contentDocument.createElement('br');
      selectionRange.insertNode(br);
    }
    reflectPreviewHtmlToEditor();
  };

  return (
    <div className={articleHtmlPartModalStyles.partInlineOptionWrapper} ref={partInlineOptionWrapperRef}>
      <div className={`${articleHtmlPartModalStyles.tooltip}`}></div>
      <div className={articleHtmlPartModalStyles.partInlineOptions}>
        <div className={`${articleHtmlPartModalStyles.btnAction} fontSize`} onMouseOver={(e) => handleToolTip(e, true, '文字サイズ')} onMouseLeave={(e) => handleToolTip(e, false)} onClick={() => changeOperateFontOption({ type: 'fontSize', isShow: true })}>
          <img alt="" src={textIcon} />
        </div>
        {['fontSize'].includes(operateFontOption.type) && operateFontOption.isShow &&
          <div className={`${articleHtmlPartModalStyles.fontOptionLists} ${articleHtmlPartModalStyles.fontSizeDropDown} fontSize`}>
            <>
              <span className={`${articleHtmlPartModalStyles.font10} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(10)}>10px</span>
              <span className={`${articleHtmlPartModalStyles.font13} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(13)}>13px</span>
              <span className={`${articleHtmlPartModalStyles.font15} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(15)}>15px</span>
              <span className={`${articleHtmlPartModalStyles.font17} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(17)}>17px</span>
              <span className={`${articleHtmlPartModalStyles.font19} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(19)}>19px</span>
              <span className={`${articleHtmlPartModalStyles.font21} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(21)}>21px</span>
              <span className={`${articleHtmlPartModalStyles.font25} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(25)}>25px</span>
              <span className={`${articleHtmlPartModalStyles.font27} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(27)}>27px</span>
              <span className={`${articleHtmlPartModalStyles.font29} ${articleHtmlPartModalStyles.fontSizeTab}`} onClick={() => applyFontSize(29)}>29px</span>
              <>
                <div className={articleHtmlPartModalStyles.freeFontOptionTitle}>{t("自由設定")}</div>
                <div className={articleHtmlPartModalStyles.freeFontSizeForm}>
                  <input type="number" min="0" ref={fontSizeRef} />
                  <select ref={fontSizeUnitRef} defaultValue="px">
                    <option value="px">px</option>
                    <option value="%">%</option>
                    <option value="em">em</option>
                  </select>
                </div>
                <div className={`${btnStyles.btn} ${btnStyles.btnDarkThemePrimary} ${articleHtmlPartModalStyles.btnFontSizeOption}`} onClick={() => applyFontSize(fontSizeRef.current.value, fontSizeUnitRef.current.value)}>{t("適用する")}</div>
              </>
            </>
          </div>
        }
        <div className={`${articleHtmlPartModalStyles.btnAction} ${articleHtmlPartModalStyles.btnBold}`} onClick={() => applyBold()} onMouseOver={(e) => handleToolTip(e, true, t('太字'))} onMouseLeave={(e) => handleToolTip(e, false)}>
        </div>
        <div className={articleHtmlPartModalStyles.btnAction} onClick={() => applyUnderline()} onMouseOver={(e) => handleToolTip(e, true, t('アンダーライン'))} onMouseLeave={(e) => handleToolTip(e, false)}>
          <img alt="" src={underlineIcon} width={24} />
        </div>
        <div className={articleHtmlPartModalStyles.btnAction}>
          <AppDropdown topBottom={'bottom'} leftRight={'center'} control={'click'} theme={'darkTheme'}>
            <img alt="" src={fontFamilyIcon} width={24} onMouseOver={(e) => handleToolTip(e, true, t('フォント'))} onMouseLeave={(e) => handleToolTip(e, false)} />
            <div className={articleHtmlPartModalStyles.fontFamlyList}>
              <span style={ { fontFamily: 'serif' } } onClick={(e) => applyFontFamly('serif')}>serif</span>
              <span style={ { fontFamily: 'sans-serif' } } onClick={(e) => applyFontFamly('sans-serif')}>sans-serif</span>
              <span style={ { fontFamily: 'cursive' } } onClick={(e) => applyFontFamly('cursive')}>cursive</span>
              <span style={ { fontFamily: 'fantasy' } } onClick={(e) => applyFontFamly('fantasy')}>fantasy</span>
              <span style={ { fontFamily: 'monospace' } } onClick={(e) => applyFontFamly('monospace')}>monospace</span>
              <span style={ { fontFamily: t('ヒラギノ角ゴ Pro W3') } } onClick={(e) => applyFontFamly(t('ヒラギノ角ゴ Pro W3'))}>{t("ヒラギノ角ゴ Pro W3")}</span>
            </div>
          </AppDropdown>
        </div>
        <div className={`${articleHtmlPartModalStyles.btnAction} align`}  onClick={() => changeOperateFontOption({ type: 'align', isShow: true })}>
          <img alt="" src={alignLeftIcon} width={24} onMouseOver={(e) => handleToolTip(e, true, t('寄せ'))} onMouseLeave={(e) => handleToolTip(e, false)} />
          {operateFontOption.type === 'align' && operateFontOption.isShow &&
            <div className={articleHtmlPartModalStyles.fontOptionLists}>
              <span className={articleHtmlPartModalStyles.alignIcon} onClick={() => applyAlign("justifyLeft")} ><img alt="" src={alignLeftIcon} /></span>
              <span className={articleHtmlPartModalStyles.alignIcon} onClick={() => applyAlign("justifyCenter")} ><img alt="" src={alignCenterIcon} /></span>
              <span className={articleHtmlPartModalStyles.alignIcon} onClick={() => applyAlign("justifyRight")} ><img alt="" src={alignRightIcon} /></span>
              <span className={articleHtmlPartModalStyles.alignIcon} onClick={() => applyAlign("justifyFull")} ><img alt="" src={alignJustifyIcon} /></span>
            </div>
          }
        </div>
        <div className={`${articleHtmlPartModalStyles.btnAction} ql-color`} onClick={() => changeOperateFontOption({ type: 'ql-color', isShow: true })}>
          <ColorPicker
            className={'preview-toolbar-color-picker'}
            colorProperty={'ql-color'}
            onAppyColor={applyColor}
          >
            <Tooltip PopperProps={{ sx: { zIndex: "100000" } }} title={t("文字色")} arrow>
              <div data-test="ColorPicker-BtnColor" className={`${editorToolbarStyles.btnFontColor}`}></div>
            </Tooltip>
          </ColorPicker>
        </div>
        <div className={`${articleHtmlPartModalStyles.btnAction} ql-background`} onClick={() => changeOperateFontOption({ type: 'ql-background', isShow: true })}>
          <ColorPicker
            className={'preview-toolbar-color-picker'}
            colorProperty={'ql-background'}
            onAppyColor={applyColor}
          >
            <Tooltip PopperProps={{ sx: { zIndex: "100000" } }} title={t("背景色")} arrow>
              <div data-test="ColorPicker-BtnBackGround" className={`${editorToolbarStyles.btnBgColor}`} ></div>
            </Tooltip>
          </ColorPicker>
        </div>
        <div className={articleHtmlPartModalStyles.btnAction} >
          <ImageDropdown
            article={article}
            formId={'previewToolbarPhotoForm'}
            imageIcon={imageIcon}
            articlePhoto={articlePhoto}
            fetchArticlePhotos={(searchRange, articleUid) => fetchArticlePhotos(searchRange, articleUid)}
            fetchArticlePhotosOnEditor={() => fetchArticlePhotosOnEditor()}
            insertImage={(photo) => insertImage(photo)}
            handleArticlePhotos={(data) => handleArticlePhotos(data)}
            updateArticlePhotoPublishStatus={(photoArticleUid, photoId) => updateArticlePhotoPublishStatus(photoArticleUid, photoId)}
            handleToolTip={(e, isShow, tooltipName) => handleToolTip(e, isShow, tooltipName)}
          />
        </div>
        <div className={articleHtmlPartModalStyles.btnAction} >
          <Options
            applyGumMarker={applyGumMarker}
            applyUnderMarkerColor={applyUnderMarkerColor}
          />
        </div>
        <div className={articleHtmlPartModalStyles.btnAction} onClick={() => addIndent()} onMouseOver={(e) => handleToolTip(e, true, '改行追加')} onMouseLeave={(e) => handleToolTip(e, false)}>
          <img alt="" src={indentIcon} width={24}/>
        </div>
        <div className={`${articleHtmlPartModalStyles.btnAction} previewToolbarLinkDropdown`} >
          <ArticleHtmlPartPreviewToolbarLinkDropDown
            selection={selection}
            selectionRange={selectionRange}
            linkFormRef={linkFormRef}
            checkBoxRef={checkBoxRef}
            targetCheckBoxRef={targetCheckBoxRef}
            invalidTextRef={invalidTextRef}
            removeLink={() => removeLink()}
            createLink={() => createLink()}
            handleToolTip={(e, isShow, tooltipName) => handleToolTip(e, isShow, tooltipName)}
          />
        </div>
        <div className={articleHtmlPartModalStyles.btnAction} onClick={() => htmlEditorRef.current.editor.undo()} onMouseOver={(e) => handleToolTip(e, true, t('元に戻す'))} onMouseLeave={(e) => handleToolTip(e, false)}>
          <img alt="" src={undoIcon} width={24}/>
        </div>
        <div className={`${articleHtmlPartModalStyles.btnAction} ${articleHtmlPartModalStyles.btnRedo}`} onClick={() => htmlEditorRef.current.editor.redo()} onMouseOver={(e) => handleToolTip(e, true, t('やり直す'))} onMouseLeave={(e) => handleToolTip(e, false)}>
          <img alt="" src={undoIcon} width={24}/>
        </div>
        <div className={`${articleHtmlPartModalStyles.btnAction} ${articleHtmlPartModalStyles.btnRedo}`} onClick={() => clearFormat()} onMouseOver={(e) => handleToolTip(e, true, t('書式クリア'))} onMouseLeave={(e) => handleToolTip(e, false)}>
          <img alt="" src={formatClearIcon} width={20}/>
        </div>
      </div>
    </div>
  );
};

const Options = ({
  applyGumMarker,
  applyUnderMarkerColor
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Tooltip PopperProps={{ sx: { zIndex: "100000" } }} title={t("オプション")} arrow>
        <img alt="" src={optionIcon} width={24} onClick={handleClick}/>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          zIndex: "99999"
        }}
        PaperProps={{
          sx: {
            backgroundColor: `${theme.palette.background.secondary}`
          }
        }}
      >
        <Box sx={{ p: 1, "&:hover": { cursor: "pointer" } }} onClick={(e) => applyGumMarker('#ffee57')}>{t("伸縮マーカー")}({t("黄色")})</Box>
        <Box sx={{ p: 1, "&:hover": { cursor: "pointer" } }} onClick={(e) => applyGumMarker('#FFDFEF')}>{t("伸縮マーカー")}({t("ピンク")})</Box>
        <ColorPicker
          className={'preview-toolbar-color-picker'}
          colorProperty={'widget-sb-bg-under-marker-normal'}
          onAppyColor={applyUnderMarkerColor}
        >
          <Box sx={{ p: 1, "&:hover": { cursor: "pointer" } }}>{t("アンダーマーカー")}({t("普通")})</Box>
        </ColorPicker>
        <ColorPicker
          className={'preview-toolbar-color-picker'}
          colorProperty={'widget-sb-bg-under-marker-thin'}
          onAppyColor={applyUnderMarkerColor}
        >
          <Box sx={{ p: 1, "&:hover": { cursor: "pointer" } }}>{t("アンダーマーカー")}({t("細い")})</Box>
        </ColorPicker>
      </Popover>
    </div>
  );
};

PreviewToolbar.propTypes = {
  operateFontOption: PropTypes.object.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  htmlEditorRef: PropTypes.any,
  article: PropTypes.object.isRequired,
  articlePhoto: PropTypes.object.isRequired,
  selection: PropTypes.any.isRequired,
  selectionRange: PropTypes.any.isRequired,
  changeOperateFontOption: PropTypes.func.isRequired,
  handlePartHtml: PropTypes.func.isRequired,
  handleArticlePhotos: PropTypes.func.isRequired,
  fetchArticlePhotos: PropTypes.func.isRequired,
  fetchArticlePhotosOnEditor: PropTypes.func.isRequired,
  updateArticlePhotoPublishStatus: PropTypes.func.isRequired
};

export default PreviewToolbar;
