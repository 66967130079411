import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { FolderProps } from "domains";
import { folderListState, useLogging } from "hooks";
import FolderInspectionSettingApi from "javascripts/api/folders/inspectionSetting";

type HandleParams = {
  inspection_setting: {
    enabled: boolean;
  };
};

export const useUpdateFolderInspectionSetting = () => {
  const { t } = useTranslation();
  const { sendErrorLog } = useLogging();
  const [folderList, setFolderList] = useRecoilState(folderListState);

  const handle = useCallback(
    async (folderUid: string, params: HandleParams) => {
      if (
        !window.confirm(
          t("本当に審査フォルダにしますか？審査フォルダにすると後から元に戻すことはできません") ||
            "",
        )
      ) {
        return false;
      }

      try {
        const response = await FolderInspectionSettingApi.update(folderUid, params);
        const newFolderList = folderList.map((folder: FolderProps) => {
          if (folder.id === response.folder_id) {
            return { ...folder, inspection_setting: response };
          } else {
            return folder;
          }
        });
        setFolderList(newFolderList);
        return true;
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to fetch tags",
        });
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderList],
  );

  return [handle] as const;
};
