import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class ArticleInspectionRequestApi extends ApiBase {
  static fetchRequests(articleUid) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/articles/${articleUid}/inspection_requests`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
  static create(articleUid, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(`${baseUrl}/articles/${articleUid}/inspection_requests`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default ArticleInspectionRequestApi;
