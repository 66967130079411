import axios from "axios";
import { PhotoProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderArticlePhotoResponse = {
  photos: PhotoProps[];
  next_page: number;
};

class FolderArticlePhotoApi extends ApiBase {
  static fetch(folderUid: string, params: any) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderArticlePhotoResponse>(function (resolve, reject) {
      axios
        .get(baseUrl + "/folders/" + folderUid + "/articles/" + "/photos", {
          params,
          withCredentials: true,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderArticlePhotoApi;
