import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { AbTestParameterProps } from "domains";
import AbTestParameterApi from "javascripts/api/abTest/parameter";

export const abTestParameterListState = atom<AbTestParameterProps[]>({
  key: "abTestParameterListState",
  default: [],
});

export const useAbTestParameterList = (abTestUid: string) => {
  const [parameterList, setParameterList] = useRecoilState(abTestParameterListState);

  useEffect(() => {
    AbTestParameterApi.fetchParameters(abTestUid).then((data) => {
      setParameterList(data.parameters);
    });
  }, []);

  return [parameterList] as const;
};
