import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const BeyondPageEditorIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-838.000000, -270.000000)"
          fill={iconColor}
          fillRule="nonzero"
        >
          <g transform="translate(150.000000, 50.000000)">
            <g transform="translate(270.000000, 70.000000)">
              <g transform="translate(20.000000, 17.000000)">
                <g transform="translate(0.000000, 33.000000)">
                  <g transform="translate(20.000000, 80.000000)">
                    <g transform="translate(20.494141, 19.250000)">
                      <g transform="translate(357.856402, 0.750000)">
                        <path d="M11.1386719,2.140625 L11.5957031,1.66601563 C11.7128906,1.54101563 11.7744141,1.39941406 11.7802734,1.24121094 C11.7861328,1.08300781 11.7304688,0.9453125 11.6132812,0.828125 L11.4492188,0.65234375 C11.3476562,0.546875 11.2177734,0.501953125 11.0595703,0.517578125 C10.9013672,0.533203125 10.7636719,0.599609375 10.6464844,0.716796875 L10.171875,1.17382813 L11.1386719,2.140625 Z M4.25976562,8.29296875 L5.49023438,7.78320313 L10.7167969,2.55664063 L9.75585938,1.6015625 L4.52929688,6.828125 L4.00195312,8.02929688 C3.96679687,8.10742188 3.98242187,8.17773438 4.04882812,8.24023438 C4.11523438,8.30273438 4.18554688,8.3203125 4.25976562,8.29296875 Z M8.77148438,12.2890625 C9.36132812,12.2890625 9.81933594,12.1220703 10.1455078,11.7880859 C10.4716797,11.4541016 10.6347656,10.9648438 10.6347656,10.3203125 L10.6347656,4.18554688 L9.328125,5.48632813 L9.328125,10.1914063 C9.328125,10.4609375 9.265625,10.6611328 9.140625,10.7919922 C9.015625,10.9228516 8.85742188,10.9882813 8.66601562,10.9882813 L2.09765625,10.9882813 C1.83984375,10.9882813 1.64257812,10.9228516 1.50585938,10.7919922 C1.36914062,10.6611328 1.30078125,10.4609375 1.30078125,10.1914063 L1.30078125,3.76367188 C1.30078125,3.49414063 1.36914062,3.29296875 1.50585938,3.16015625 C1.64257812,3.02734375 1.83984375,2.9609375 2.09765625,2.9609375 L6.85546875,2.9609375 L8.15625,1.65429688 L1.98632812,1.65429688 C1.33789063,1.65429688 0.844726563,1.82226563 0.506835938,2.15820313 C0.168945313,2.49414063 0,2.98242188 0,3.62304688 L0,10.3203125 C0,10.9648438 0.168945313,11.4541016 0.506835938,11.7880859 C0.844726563,12.1220703 1.33789063,12.2890625 1.98632812,12.2890625 L8.77148438,12.2890625 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
