import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { AppDropdown } from "components/atoms/app-dropdown";
import { ZippedImageUploadButton } from 'components/atoms/feature-buttons';
import {
  ImageDropdownMoveNav,
  ImageDropdownPhotoList,
  ImageDropdownResizeNav,
  ImageDropdownSearchRangeTab
} from 'components/molecules/image-dropdown/parts';
import { useAlert } from 'contexts/alert';

import { ArticlePhotoProps } from "domains";
import ArticlePhotoApi from 'javascripts/api/articles/photo';

import { collectError, compressImages } from 'javascripts/utils';

import btnStyles from 'stylesheets/components/partials/btn.module.scss';
import imageDropdownStyles from 'stylesheets/components/quillEditor/imageDropdown.module.scss';

import { ImageDropdownProps } from "./type";

export const ImageDropdown = ({
  article,
  formId,
  imageIcon,
  articlePhoto,
  moveAction=false,
  resizeAction=false,
  fetchArticlePhotos,
  fetchArticlePhotosOnEditor,
  insertImage,
  handleArticlePhotos,
  updateArticlePhotoPublishStatus,
  handleToolTip
}: ImageDropdownProps) => {
  const alertState = useAlert();

  const photoWrapperRef = useRef<HTMLDivElement>(null);
  const expandArrowRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const [displayType, handleDisplayType] = useState<"displayImage" | "displayList">("displayImage");
  const [dropdownAbsolutePosition, handleAbsolutePostion] = useState({ x: 0, y: 0});

  const { t } = useTranslation();

  const onAfterOpen = () => {
    if (dropdownRef.current) {
        const pos = localStorage.getItem('image_dropdown_position');
        const size = localStorage.getItem('image_dropdown_size');
        // NOTE: dropddownがabsoluteなので元の位置を管理しておいて値の調整に使う
        const rect = dropdownRef?.current?.getBoundingClientRect();
        if (rect) {
            dropdownAbsolutePosition.y = - rect.top;
            dropdownAbsolutePosition.x = - rect.left;
        }
        handleAbsolutePostion(dropdownAbsolutePosition);
        if (moveAction && pos) {
            const parsedPos = JSON.parse(pos);
            dropdownRef.current.style.top = parsedPos.top.match(/(.+)px/) && parseInt(parsedPos.top.match(/(.+)px/)[1]) < 0 ? 0 : parsedPos.top;
            dropdownRef.current.style.left = parsedPos.left.match(/(.+)px/)  && parseInt(parsedPos.left.match(/(.+)px/)[1]) < 0 ? 0 : parsedPos.left;
        }
        if (resizeAction && size) {
            const parsedSize = JSON.parse(size) || {};
            dropdownRef.current.style.maxWidth = 'unset';
            if (dropdownContentRef.current) {
                dropdownContentRef.current.style.width = parsedSize.width;
                dropdownContentRef.current.style.height = parsedSize?.height;
            }
        }
        dropdownRef.current.classList.add(imageDropdownStyles.open);
        return true;
    }
    return false;
  };
  
  const onAfterClose = () => {
    if (dropdownRef.current) {
        dropdownRef.current.classList.remove(imageDropdownStyles.open);
    }
  };
  
  useEffect(() => {
    if (displayType === 'displayImage') return;
    if (articlePhoto.searchRange === 'article') {
      handleDisplayType('displayImage');
    }
  }, [articlePhoto.searchRange]);
  
  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (!e.target.files || e.target.files.length === 0) return;
    ArticlePhotoApi.checkLoyaltyCreateArticlePhoto(article.uid)
      .then(() => {
        compressImages(e.target.files, article.ab_test.editor_version)
          .then(files => {
            const params = new FormData();
            files.forEach((file) => {
              params.append('article_photo[photos][]', file);
            });
            ArticlePhotoApi.createArticlePhoto(article.uid, params)
              .then((data: { data: ArticlePhotoProps }) => {
                const formElement = document.getElementById(formId) as HTMLFormElement;
                if (formElement) {
                  formElement.value = "";
                }
                const errorMessage = [`${t("に失敗しました。", { value: t("以下の画像のアップロード") })}\n`];
                if (data.data.error_photo_data && data.data.error_photo_data.length > 0) {
                  for(const errorPhoto of data.data.error_photo_data) {
                    errorMessage.push(`${Object.keys(errorPhoto)}(${Object.values(errorPhoto)})\n`);
                  }
                  alertState.showAlerts({ messages: errorMessage, theme: 'danger'});
                }
                for(const photo of data.data.photos) {
                  insertImage(photo.photo, "uploaded");
                  if (articlePhoto.searchRange !== 'article') {
                    handleArticlePhotos(photo);
                  }
                }
              })
              .catch(error => {
                collectError(error);
                alertState.showAlerts({messages: [error.message], theme: 'danger'});
              });
          });
      })
      .catch(error => {
        alertState.showAlerts({messages: [error.message], theme: 'danger'});
      });
  };
  
  const expandImageArea = () => {
    if (dropdownContentRef.current && expandArrowRef.current) {
      dropdownContentRef.current.classList.toggle(imageDropdownStyles.expand);
      expandArrowRef.current.classList.toggle(imageDropdownStyles.expand);
    }
  };
  
  return (
    <AppDropdown topBottom={'bottom'} leftRight={'left'} control={'click'} theme={'darkTheme'} onAfterOpen={onAfterOpen} onAfterClose={onAfterClose}>
      <div className={imageDropdownStyles.iconWrapper}>
        <img alt="" src={imageIcon} width={20} onMouseOver={(e) => handleToolTip ? handleToolTip(e, true, t('画像')) : ''} onMouseLeave={(e) => handleToolTip ? handleToolTip(e, false) : ''}/>
      </div>
      <div className={`${imageDropdownStyles.dropdown} ${imageDropdownStyles['darkTheme']}`} ref={dropdownRef}>
        <div className={imageDropdownStyles.dropdownContent} ref={dropdownContentRef}>
          <div className={imageDropdownStyles.dropdownHeader}>
            {moveAction &&
              <ImageDropdownMoveNav
                article={article}
                dropdownRef={dropdownRef}
                keyName={'image_dropdown_position'}
                dropdownAbsolutePosition={dropdownAbsolutePosition}
              />
            }
            <div className={imageDropdownStyles.titleWrapper}>
              <div className={imageDropdownStyles.title}>{t("をアップロード", { value: t("新しい画像") })}</div>
            </div>
            <div className={imageDropdownStyles.selectImageWrapper}>
              <div data-test={"EditorToolbarImageDropdown-BtnUploadArticlePhoto"} className={`${btnStyles.btn} ${btnStyles.btnDarkThemePrimary}`} onClick={() => (document.getElementById(formId) as HTMLFormElement).click()}>{t("Versionにアップロードする画像を選択")}({t("4枚まで")})</div>
            </div>
            <div className={imageDropdownStyles.titleWrapper}>
              <div className={imageDropdownStyles.title}>{t("画像をまとめてアップロード　※枚数制限なし・Versionには追加されません（アップロード後にメールでお知らせします）")}</div>
            </div>
            <div className={imageDropdownStyles.selectImageWrapper}>
              <ZippedImageUploadButton
                article={article}
              />
            </div>
            <div className={imageDropdownStyles.titleWrapper}>
              <div className={imageDropdownStyles.title}>{t("から選択", { value: t("一覧") })}</div>
              {!(articlePhoto.searchRange === 'article') &&
                <div className={imageDropdownStyles.displayTypeTabWrapper}>
                  <div
                    onClick={() => handleDisplayType('displayImage')}
                    className={`${imageDropdownStyles.tab} ${imageDropdownStyles.tabImage} ${displayType === 'displayImage' ? imageDropdownStyles.active : ''}`}
                    >
                  </div>
                  <div
                    onClick={() => handleDisplayType('displayList')}
                    className={`${imageDropdownStyles.tab} ${imageDropdownStyles.tabList} ${displayType === 'displayList' ? imageDropdownStyles.active : ''}`}
                    >
                  </div>
                </div>
              }
            </div>
            <ImageDropdownSearchRangeTab
              theme={"darkTheme"}
              articlePhoto={articlePhoto}
              fetchArticlePhotos={(searchRange) => fetchArticlePhotos(searchRange, article.uid)}
              fetchArticlePhotosOnEditor={() => fetchArticlePhotosOnEditor()}
            />
          </div>
          <div className={imageDropdownStyles.dropdownBody} >
            <ImageDropdownPhotoList
              article={article}
              articlePhoto={articlePhoto}
              displayType={displayType}
              photoWrapperRef={photoWrapperRef}
              fetchArticlePhotos={(searchRange) => fetchArticlePhotos(searchRange, article.uid)}
              photoOnClick={(url) => insertImage(url, "uploaded")}
              updateArticlePhotoPublishStatus={(photoArticleUid, photoId) => updateArticlePhotoPublishStatus(photoArticleUid, photoId)}
            />
            <form className="hide" data-is-hide={true}>
              <input name="utf8" type="hidden" value="✓"/>
              <input type="file" name="photo" multiple={true} id={formId} onChange={(e) => uploadImage(e)}/>
            </form>
          </div>
        </div>
        {resizeAction ?
          <ImageDropdownResizeNav
            dropdownRef={dropdownRef}
            dropdownContentRef={dropdownContentRef}
            keyName={'image_dropdown_size'}
          />
          :
          <div onClick={() => expandImageArea()} className={imageDropdownStyles.expandArrow} ref={expandArrowRef}></div>
        }
      </div>
    </AppDropdown>
  );
};