/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form-v7";
import { useTranslation } from "react-i18next";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.bubble.css";

import { AppDropdown } from "components/atoms/app-dropdown";
import QuillEditor from "components/atoms/quill-editor";

import { debounce, createUniqueString, localizeItemName } from "javascripts/utils";
import formSettingStyles from "stylesheets/components/forms/formSetting.module.scss";

import { FormMailBaseSettingFormValues, FormMailBaseSettingProps } from "./type";

const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

const Parchment = Quill.import("parchment");
const Embed = Quill.import("blots/embed");

class BccVariable extends Embed {
  // NOTE: ライブラリ側の型が分からないのでanyにしている
  static create(value: any) {
    const node = super.create(value);
    node.innerHTML = `${value["value"]}`;
    node.setAttribute("data-uid", value["uid"]);
    node.setAttribute("data-params", value["params"]);
    return node;
  }

  // NOTE: ライブラリ側の型が分からないのでanyにしている
  static value(domNode: any) {
    //前後に空白が入る為削除
    if (domNode.firstChild && !domNode.firstChild.tagName) {
      domNode.removeChild(domNode.firstChild);
    }
    if (domNode.lastChild && !domNode.lastChild.tagName) {
      domNode.removeChild(domNode.lastChild);
    }
    //contenteditable内に配置されているのでその中身だけを取らないと重複してしまう
    const spans = domNode.querySelectorAll("span[contenteditable=false]");
    if (spans.length > 0) {
      return {
        value: spans[spans.length - 1].innerHTML,
        uid: domNode.getAttribute("data-uid"),
        params: domNode.getAttribute("data-params"),
      };
    }
    return {
      value: domNode.innerHTML,
      uid: domNode.getAttribute("data-uid"),
      params: domNode.getAttribute("data-params"),
    };
  }
}
BccVariable.blotName = "variable";
BccVariable.className = formSettingStyles.bccContentVariable;
BccVariable.tagName = "span";

Quill.register({
  "formats/variable": BccVariable,
});

const FormMailBaseSetting = ({ form, updateMailSetting }: FormMailBaseSettingProps) => {
  const { t } = useTranslation();

  const { register, formState } = useForm<FormMailBaseSettingFormValues>({ mode: "onChange" });
  const [editorRef, setEditorRef] = useState<ReactQuill | null>(null);
  const [bccSubjectEditorRef, setBccSubjectEditorRef] = useState<ReactQuill | null>(null);

  const modules = {
    toolbar: false,
    clipboard: { matchVisual: false },
  };

  useEffect(() => {
    if (!editorRef) return;
    const variables = editorRef
      .getEditor()
      .root.querySelectorAll(`.${formSettingStyles.bccContentVariable}`);
    variables.forEach((element) => {
      // @ts-ignore MouseEventの型を充てられない旨のエラーが出るが、動いているので一旦無視する
      element.addEventListener("click", setCursor);
    });

    editorRef.getEditor().root.addEventListener("click", setSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef]);

  useEffect(() => {
    if (!bccSubjectEditorRef) return;
    //BCCの件名フォームは改行をできなくしたいのでbindingで制御
    // @ts-ignore bindingsが無いと怒られるが、代替手段が見つからないため無視する
    bccSubjectEditorRef.getEditor().keyboard.bindings[13].unshift({
      key: 13,
      handler: (_e: any) => {
        return false;
      },
    });
    bccSubjectEditorRef.getEditor().root.addEventListener("click", setSelection);

    const variables = bccSubjectEditorRef
      .getEditor()
      .root.querySelectorAll(`.${formSettingStyles.bccContentVariable}`);
    variables.forEach((element) => {
      // @ts-ignore MouseEventの型を充てられない旨のエラーが出るが、動いているので一旦無視する
      element.addEventListener("click", setCursor);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bccSubjectEditorRef]);

  const addVariable = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: string,
    params: string,
    ref: ReactQuill | null,
  ) => {
    e.preventDefault();
    // @ts-ignore editorの型が分からないが、代替手段が見つからないため無視する
    ref?.editor.focus();
    // @ts-ignore
    const range = ref?.editor.getSelection();
    if (range) {
      const uniqueString = createUniqueString();
      // @ts-ignore
      ref?.editor.insertEmbed(
        range.index,
        "variable",
        { value: value, uid: uniqueString, params: params },
        uniqueString,
      );
      // @ts-ignore
      const element = ref?.editor.root.querySelector(`[data-uid="${uniqueString}"]`);
      element.addEventListener("click", setCursor);
      // @ts-ignore
      ref?.editor.setSelection((range.index as number) + 1, 0);
    }
  };

  //MEMO: お問い合わせ項目を最後に追加した場合にうまくcursorがセットされないのでクリックイベントでセットする
  const setSelection = () => {
    // @ts-ignore editorが無いと怒られるが、代替手段が見つからないため無視する
    const ref = editorRef?.editor.hasFocus() ? editorRef : bccSubjectEditorRef;
    // @ts-ignore
    ref?.editor.setSelection(ref?.editor.selection.savedRange);
  };

  const setCursor = (e: React.MouseEvent<Element, MouseEvent>) => {
    // @ts-ignore editorが無いと怒られるが、代替手段が見つからないため無視する
    const ref = editorRef?.editor.hasFocus() ? editorRef : bccSubjectEditorRef;
    const blot = Parchment.find(e.target, true);
    // @ts-ignore
    const index = ref?.editor.getIndex(blot) as number;
    // @ts-ignore
    ref?.editor.setSelection(index + 1, 0);
  };

  const getParameters = () => {
    const div = document.createElement("div");
    div.innerHTML = form?.input.html ?? "";
    const inputs = div.querySelectorAll("[name^=folder_form]");
    const names: string[] = [];
    inputs.forEach((input) => {
      const nameAttribute = input.getAttribute("name");
      const matchedName = nameAttribute?.match(/folder_form\[(.*?)\]/)?.at(1);
      if (!matchedName || names.includes(matchedName)) return;
      names.push(matchedName);
    });
    return names;
  };

  const variables = (ref: ReactQuill | null) => {
    const components = getParameters().map((params) => {
      let name = params;
      if (localizeItemName(name)) {
        name = localizeItemName(name);
      }
      return (
        <div
          key={name}
          className={formSettingStyles.bccVariable}
          onClick={(e) => addVariable(e, name, params, ref)}
        >
          {name}
        </div>
      );
    });
    return components;
  };

  const delayUpdate = debounce(updateMailSetting, 500);

  return (
    <>
      <div className={formSettingStyles.settingTitle}>{t("メール設定")}</div>

      <div className={formSettingStyles.formMailBaseSetting}>
        <div className={formSettingStyles.formWrapper}>
          <div className={formSettingStyles.settingDescription}>
            {t("デフォルトのメール設定を定義します。この設定はメール作成画面で個別編集できます。")}
          </div>
          <div className={formSettingStyles.formTitle}>{t("返信先メールアドレス")}</div>
          <input
            type={"text"}
            className={`${formSettingStyles.textForm} ${
              formState.errors.from_address ? formSettingStyles.invalid : ""
            }`}
            defaultValue={form?.mail_setting.from_address}
            {...register("from_address", {
              required: t("を入力してください", { value: t("メールアドレス") }) || "",
              pattern: {
                value: /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i,
                message: t("を入力してください", { value: t("メールアドレス") }) || "",
              },
              onChange: debounce(
                (event: React.ChangeEvent<HTMLInputElement>) => {
                  updateMailSetting({ folder_form_setting: { from_address: event.target.value } });
                },
                500,
                false,
              ),
            })}
          />
        </div>
        <div className={formSettingStyles.formWrapper}>
          <div className={formSettingStyles.formTitle}>{t("差出人")}</div>
          <input
            type={"text"}
            className={`${formSettingStyles.textForm} ${
              formState.errors.from_name ? formSettingStyles.invalid : ""
            }`}
            defaultValue={form?.mail_setting.from_name}
            {...register("from_name", {
              onChange: debounce(
                (event: React.ChangeEvent<HTMLInputElement>) => {
                  updateMailSetting({ folder_form_setting: { from_name: event.target.value } });
                },
                500,
                false,
              ),
            })}
          />
          <div className={formSettingStyles.formAnnotation}>
            {t("受信者のメーラーに差出人として表示される名前です")}
          </div>
        </div>
        <div className={formSettingStyles.formWrapper}>
          <div className={formSettingStyles.formTitle}>{t("件名")}</div>
          <input
            type={"text"}
            className={`${formSettingStyles.textForm} ${
              formState.errors.subject ? formSettingStyles.invalid : ""
            }`}
            defaultValue={form?.mail_setting.subject}
            {...register("subject", {
              onChange: debounce(
                (event: React.ChangeEvent<HTMLInputElement>) => {
                  updateMailSetting({ folder_form_setting: { subject: event.target.value } });
                },
                500,
                false,
              ),
            })}
          />
        </div>
        <div className={formSettingStyles.formWrapper}>
          <div className={formSettingStyles.formTitle}>{t("署名")}</div>
          <textarea
            className={`${formSettingStyles.textArea} ${
              formState.errors.signature ? formSettingStyles.invalid : ""
            }`}
            rows={10}
            defaultValue={form?.mail_setting.signature ?? ""}
            placeholder={`${t("を入力", { value: t("署名") })}...`}
            {...register("signature", {
              onChange: debounce(
                (event: React.ChangeEvent<HTMLInputElement>) => {
                  updateMailSetting({ folder_form_setting: { signature: event.target.value } });
                },
                500,
                false,
              ),
            })}
          />
          <div className={formSettingStyles.formAnnotation}>
            {t("送信メールに共通で設定される署名です")}
          </div>
        </div>
        <div className={formSettingStyles.formWrapper}>
          <div className={formSettingStyles.formTitle}>BCC</div>
          <input
            type={"text"}
            className={`${formSettingStyles.textForm} ${
              formState.errors.bcc ? formSettingStyles.invalid : ""
            }`}
            defaultValue={form?.mail_setting.bcc}
            {...register("bcc", {
              onChange: debounce(
                (event: React.ChangeEvent<HTMLInputElement>) => {
                  updateMailSetting({ folder_form_setting: { bcc: event.target.value } });
                },
                500,
                false,
              ),
            })}
          />
        </div>
        <div className={formSettingStyles.formWrapper}>
          <div className={formSettingStyles.formTitle}>
            {t("BCC件名")}
            <span className={formSettingStyles.description}>
              {t("※入力しない場合はテンプレート文が送られます")}
            </span>
            <AppDropdown
              topBottom={"center"}
              leftRight={"leftLowerHalf"}
              control={"click"}
              theme={"lightTheme"}
            >
              <div>
                +{t("お問い合わせ項目")}
                <AppDropdown
                  topBottom={"center"}
                  leftRight={"leftLowerHalf"}
                  control={"hover"}
                  theme={"lightTheme"}
                >
                  <div className={formSettingStyles.info}>?</div>
                  <div className={formSettingStyles.infoContent}>
                    {t("フォームに入力された内容をBCCの件名に含めることができます")}
                  </div>
                </AppDropdown>
              </div>
              <div className={formSettingStyles.bccVariableWrapper}>
                {variables(bccSubjectEditorRef)}
              </div>
            </AppDropdown>
          </div>
          <QuillEditor
            editorRef={bccSubjectEditorRef}
            setEditorRef={setBccSubjectEditorRef}
            value={form?.mail_setting.bcc_subject ?? ""}
            className={`${formSettingStyles.autoReplyContentEditor} bcc-subject-setting-quill-editor`}
            theme="bubble"
            placeholder={t("フォームにお問い合わせがありました。") || ""}
            onChange={(_value) =>
              delayUpdate({
                folder_form_setting: {
                  bcc_subject: bccSubjectEditorRef?.getEditor().root.innerHTML,
                },
              })
            }
            modules={modules}
          />
        </div>
        <div className={formSettingStyles.formWrapper}>
          <div className={formSettingStyles.formTitle}>
            {t("BCC自動返信内容")}
            <span className={formSettingStyles.description}>
              {t("※入力しない場合はテンプレート文が送られます")}
            </span>
            <AppDropdown
              topBottom={"center"}
              leftRight={"leftLowerHalf"}
              control={"click"}
              theme={"lightTheme"}
            >
              <div>
                +{t("お問い合わせ項目")}
                <AppDropdown
                  topBottom={"center"}
                  leftRight={"leftLowerHalf"}
                  control={"hover"}
                  theme={"lightTheme"}
                >
                  <div className={formSettingStyles.info}>?</div>
                  <div className={formSettingStyles.infoContent}>
                    {t("フォームに入力された内容をBCCあてのメールに含めることができます")}
                  </div>
                </AppDropdown>
              </div>
              <div className={formSettingStyles.bccVariableWrapper}>{variables(editorRef)}</div>
            </AppDropdown>
          </div>
          <QuillEditor
            editorRef={editorRef}
            setEditorRef={setEditorRef}
            value={form?.mail_setting.bcc_reply_content ?? ""}
            className={`${formSettingStyles.autoReplyContentEditor} auto-reply-bcc-setting-quill-editor`}
            theme="bubble"
            placeholder={`${t("から以下の内容でお問い合わせがありました", {
              value: `『${form?.name}』`,
            })}`}
            onChange={(_value) =>
              delayUpdate({
                folder_form_setting: { bcc_reply_content: editorRef?.getEditor().root.innerHTML },
              })
            }
            modules={modules}
          />
        </div>
      </div>
    </>
  );
};

export default FormMailBaseSetting;
