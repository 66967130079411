import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestExitPopupDuplicationApi extends ApiBase {

  static create(abTestUid, exitPopupId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(`${baseUrl}/ab_tests/${abTestUid}/exit_popups/${exitPopupId}/duplications`,undefined,{ withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestExitPopupDuplicationApi;
