import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestConversionEventTagArticleDailyReportApi extends ApiBase {
  static fetchReports(abTestUid, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/${abTestUid}/conversion_event_tags/articles/daily_reports`, { params, withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default AbTestConversionEventTagArticleDailyReportApi;
