import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import previewToolbarLinkIcon from "assets/images/link_white.svg";

import trackingIcon from "assets/images/tracking-black.svg";

import { AppDropdown } from "components/atoms/app-dropdown";
import OpenLinkNewTabDescription from "components/atoms/open-link-new-tab-description";
import TrackingLinkDescription from "javascripts/components/Partials/TrackingLinkDescription";

import { targetParentNodeByTagName } from "javascripts/utils";
import btnStyles from "stylesheets/components/partials/btn.module.scss";
import checkboxStyles from "stylesheets/components/partials/checkbox.module.scss";
import linkDropdownStyles from "stylesheets/components/quillEditor/linkDropdown.module.scss";

import { ArticleHtmlPartPreviewToolbarLinkDropdownProps } from "./type";

export const ArticleHtmlPartPreviewToolbarLinkDropDown = ({
  selection,
  selectionRange,
  linkFormRef,
  checkBoxRef,
  targetCheckBoxRef,
  removeLink,
  invalidTextRef,
  createLink,
  handleToolTip,
}: ArticleHtmlPartPreviewToolbarLinkDropdownProps) => {
  const { t } = useTranslation();
  const [isLinkSettingActive, setIsLinkSettingActive] = useState(true);

  const checkLinkType = (e: React.ChangeEvent<HTMLInputElement>) => {
    //インナーリンクの場合トラッキングなどは使わないのでdisableにする
    if (e.target.value.startsWith("#")) {
      setIsLinkSettingActive(false);
    } else {
      setIsLinkSettingActive(true);
    }
  };

  const onAfterOpen = () => {
    if (
      !selection ||
      (selection && !selection.anchorNode) ||
      Object.prototype.hasOwnProperty.call(selection, "anchorNode")
    )
      return false;
    if (
      selectionRange &&
      selectionRange.collapsed &&
      !["IMG", "VIDEO"].includes((selectionRange.startContainer as HTMLElement).tagName)
    )
      return false;
    const anchorNode = targetParentNodeByTagName(selectionRange.startContainer, "A");
    if (anchorNode) {
      if (anchorNode.getAttribute("href").startsWith("#")) {
        setIsLinkSettingActive(false);
      } else {
        setIsLinkSettingActive(true);
      }
      if (checkBoxRef.current) {
        checkBoxRef.current.checked =
          (anchorNode.hasAttribute("data-sb-tracking") &&
            anchorNode.getAttribute("data-sb-tracking")) ||
          anchorNode.href.includes("sb_tracking=true");
      }

      if (linkFormRef.current) {
        linkFormRef.current.value = anchorNode.getAttribute("href");
      }
      if (targetCheckBoxRef.current) {
        targetCheckBoxRef.current.checked = anchorNode.hasAttribute("target");
      }
    } else {
      if (checkBoxRef.current) {
        checkBoxRef.current.checked = true;
      }
      if (linkFormRef.current) {
        linkFormRef.current.value = "";
      }
    }
    return true;
  };

  return (
    <AppDropdown
      topBottom={"bottom"}
      leftRight={"center"}
      control={"click"}
      theme={"darkTheme"}
      onAfterOpen={() => onAfterOpen()}
    >
      <>
        <img
          alt=""
          src={previewToolbarLinkIcon}
          onMouseOver={(e) => handleToolTip(e, true, t("リンク"))}
          onMouseLeave={(e) => handleToolTip(e, false)}
        />
      </>
      <div className={linkDropdownStyles.linkDropdownWrapper}>
        <div className={`${linkDropdownStyles.linkDropdownContent}`}>
          <div className={linkDropdownStyles.linkDropdownHeader}>
            <div className={linkDropdownStyles.title}>
              URL<span ref={invalidTextRef}> {t("URLの一部に誤りがあります")}</span>
            </div>
            <div
              className={linkDropdownStyles.btnRemove}
              onMouseDown={() => removeLink()}
            >
              リンク削除
            </div>
          </div>
          <input
            type="text"
            placeholder="https://..."
            className={linkDropdownStyles.linkForm}
            ref={linkFormRef}
            onChange={checkLinkType}
          />
          <div
            className={`${linkDropdownStyles.linkOptionSetting} ${
              isLinkSettingActive ? "" : linkDropdownStyles.disable
            }`}
          >
            <div className={linkDropdownStyles.checkBoxWrapper}>
              <label className={checkboxStyles.checkBoxControl}>
                <input
                  type="checkbox"
                  id={`previewToolbar-trackingCheckBox`}
                  defaultChecked={true}
                  ref={checkBoxRef}
                />
                <div
                  className={`${checkboxStyles.checkbox} ${checkboxStyles.medium} ${checkboxStyles.darkTheme}`}
                ></div>
              </label>
              <img
                alt=""
                src={trackingIcon}
              />
              <label
                className={linkDropdownStyles.checkboxLabel}
                htmlFor={`previewToolbar-trackingCheckBox`}
              >
                {t("計測機能付きリンク")}
              </label>
            </div>
            {}
            <label
              className={linkDropdownStyles.linkDescription}
              htmlFor={`previewToolbar-trackingCheckBox`}
            >
              <TrackingLinkDescription />
            </label>
            <div className={linkDropdownStyles.checkBoxWrapper}>
              <label className={checkboxStyles.checkBoxControl}>
                <input
                  type="checkbox"
                  id={`editorToolbarLinkDropdown-targetCheckBox`}
                  defaultChecked={false}
                  ref={targetCheckBoxRef}
                />
                <div
                  className={`${checkboxStyles.checkbox} ${checkboxStyles.medium} ${checkboxStyles.darkTheme}`}
                ></div>
              </label>
              <label
                className={linkDropdownStyles.checkboxLabel}
                htmlFor={`editorToolbarLinkDropdown-targetCheckBox`}
              >
                {t("リンクを別タブで開く")}
              </label>
            </div>
            <label
              className={linkDropdownStyles.linkDescription}
              htmlFor={`editorToolbarLinkDropdown-targetCheckBox`}
            >
              <OpenLinkNewTabDescription />
            </label>
          </div>
          <div
            className={`${btnStyles.btn} ${btnStyles.btnDarkThemePrimary} ${btnStyles.btnXSmall} ${btnStyles.btnAlignRight}`}
            onMouseDown={() => createLink()}
          >
            {t("リンク追加")}
          </div>
        </div>
      </div>
    </AppDropdown>
  );
};
