import React from "react";

type Props = {
  width: string;
  height: string;
};

export const InstagramIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="0%"
          x2="0%"
          y2="100%"
          id="instagram-icon-linearGradient-1"
        >
          <stop
            stopColor="#AC00FF"
            offset="0%"
          ></stop>
          <stop
            stopColor="#FF356A"
            offset="48.4665329%"
          ></stop>
          <stop
            stopColor="#FFEB31"
            offset="100%"
          ></stop>
        </linearGradient>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-387.000000, -913.000000)"
          fill="url(#instagram-icon-linearGradient-1)"
        >
          <g transform="translate(383.000000, 267.000000)">
            <path d="M12.8722296,646 C14.6552671,646 15.3018396,646.185651 15.9536914,646.534265 C16.6055433,646.88288 17.1171204,647.394457 17.4657346,648.046309 C17.8143488,648.69816 18,649.344733 18,651.12777 L18,654.87223 C18,656.655267 17.8143488,657.30184 17.4657346,657.953691 C17.1171204,658.605543 16.6055433,659.11712 15.9536914,659.465735 C15.3018396,659.814349 14.6552671,660 12.8722296,660 L9.1277704,660 C7.34473292,660 6.69816044,659.814349 6.04630859,659.465735 C5.39445674,659.11712 4.88287959,658.605543 4.53426541,657.953691 C4.18565122,657.30184 4,656.655267 4,654.87223 L4,651.12777 C4,649.344733 4.18565122,648.69816 4.53426541,648.046309 C4.88287959,647.394457 5.39445674,646.88288 6.04630859,646.534265 C6.69816044,646.185651 7.34473292,646 9.1277704,646 L12.8722296,646 Z M13,648 L9,648 C7.34314575,648 6,649.343146 6,651 L6,651 L6,655 C6,656.656854 7.34314575,658 9,658 L9,658 L13,658 C14.6568542,658 16,656.656854 16,655 L16,655 L16,651 C16,649.343146 14.6568542,648 13,648 L13,648 Z M13,649 C14.1045695,649 15,649.895431 15,651 L15,651 L15,655 L14.9945143,655.149262 C14.9181651,656.184122 14.0543618,657 13,657 L13,657 L9,657 L8.85073766,656.994514 C7.81587779,656.918165 7,656.054362 7,655 L7,655 L7,651 L7.00548574,650.850738 C7.08183488,649.815878 7.9456382,649 9,649 L9,649 Z M11,651 C9.8954305,651 9,651.895431 9,653 C9,654.104569 9.8954305,655 11,655 C12.1045695,655 13,654.104569 13,653 C13,651.895431 12.1045695,651 11,651 Z M11,652 C11.5522847,652 12,652.447715 12,653 C12,653.552285 11.5522847,654 11,654 C10.4477153,654 10,653.552285 10,653 C10,652.447715 10.4477153,652 11,652 Z M13.5,650 C13.2238576,650 13,650.223858 13,650.5 C13,650.776142 13.2238576,651 13.5,651 C13.7761424,651 14,650.776142 14,650.5 C14,650.223858 13.7761424,650 13.5,650 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
