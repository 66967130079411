import React, { useState, useMemo, useEffect } from "react";
import { Box, Typography, IconButton, Menu, MenuItem, Collapse } from "@mui/material";
import { NewFolderIcon, OptionsIcon } from "components/atoms";
import { FolderProps } from "domains";
import { sortFoldersByMemberOrder, isFolderGroupIncludeFolderMatchQuery } from "javascripts/utils";
import { FolderGroupFolderList } from "../folder-group-folder-list";
import { NewFolderForm } from "../new-folder-form";
import { DeleteFolderGroup } from "./delete-folder-group";
import { FolderGroupArrowIcon } from "./folder-group-arrow-icon";
import { FolderGroupForm } from "./folder-group-form";
import type { FolderGroupComponentProps } from "./type";

const FolderGroupInner = ({
  isEditable,
  isInspectable,
  folderGroup,
  order,
  updateFolder,
  activeFolder,
  folderGroupFolderOrderList,
  folderGroupList,
  thirdPartyDomainList,
  squadbeyondDomainList,
  query,
  sortOptionList,
  handleDownloadFolderReport,
  handleDownloadFolderReportLoading,
  handleDownloadFolderReportError,
}: FolderGroupComponentProps) => {
  const [isFolderOpen, setIsFolderOpenCore] = useState(false);
  // Collapse + FolderGroupFolderList を render するかどうか。一度 isFolderOpen が true になるまでは render しない。
  // 閉じる際にはアニメーション終了まで描画されている必要があるので、一度 true にした後は true のままにする。
  const [isRenderingFolderGroupFolderList, setIsRenderingFolderGroupFolderList] = useState(false);
  const setIsFolderOpen = (v: boolean) => {
    isRenderingFolderGroupFolderList
      ? setIsFolderOpenCore(v)
      : // 最初は閉じた状態の Collapse をまず描画しなければならないため、遅延させる。
        setTimeout(() => setIsFolderOpenCore(v));
    v && setIsRenderingFolderGroupFolderList(true);
  };

  const folderListWithCount = folderGroup?.folders || [];
  const folderListInOrder = useMemo(() => {
    return sortFoldersByMemberOrder(
      folderListWithCount,
      sortOptionList,
      folderGroupFolderOrderList,
    );
  }, [folderListWithCount, sortOptionList, folderGroupFolderOrderList]);

  // Menu を render するかどうか。一度 handleMenuClick が呼ばれるまでは render しない。
  // 閉じる際にはアニメーション終了まで描画されている必要があるので、一度 true にした後は true のままにする。
  const [isRenderingMenu, setIsRenderingMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsRenderingMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (folderListInOrder.length === 0) {
      return;
    }
    //querySelectorで取得してクリックしているところがあるので、分岐を追加
    if (!event.isTrusted) {
      setIsFolderOpen(true);
    } else {
      setIsFolderOpen(!isFolderOpen);
    }
  };

  useEffect(() => {
    if (!isEditable) {
      return;
    }

    const folderIdList = folderListWithCount.map((folder: FolderProps) => folder.id);
    const isActive = activeFolder ? folderIdList.includes(activeFolder?.id) : undefined;
    if (isActive) {
      setIsFolderOpen(isActive);
    }
  }, [folderListWithCount, activeFolder, isEditable]);

  const matchResults = isFolderGroupIncludeFolderMatchQuery(folderGroup, query);
  if (!matchResults.isFolderGroupFolderMatch && !matchResults.isFolderGroupMatch) {
    return null;
  }
  return (
    <Box
      data-folder-group-id={folderGroup.id}
      data-order-id={order?.id || null}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.common.white}`,
        py: 1,
        pl: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          <FolderGroupArrowIcon
            folderListInOrder={folderListInOrder}
            isFolderOpen={isFolderOpen}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              wordBreak: "break-all",
            }}
          >
            {folderGroup.name}
          </Typography>
        </Box>
        {isEditable && (
          <>
            <Box sx={{ ml: "auto" }}>
              <IconButton onClick={handleMenuClick}>
                <OptionsIcon
                  color={"common"}
                  width={"14px"}
                  height={"11px"}
                />
              </IconButton>
            </Box>
            {isRenderingMenu && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
              >
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                  <FolderGroupForm folderGroup={folderGroup} />
                </MenuItem>
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                  <NewFolderForm
                    icon={
                      <NewFolderIcon
                        color="primary"
                        width="20px"
                        height="15px"
                      />
                    }
                    folderGroup={folderGroup}
                    folderGroupList={folderGroupList}
                    thirdPartyDomainList={thirdPartyDomainList}
                    squadbeyondDomainList={squadbeyondDomainList}
                    updateFolder={updateFolder}
                  />
                </MenuItem>
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                  <DeleteFolderGroup folderGroup={folderGroup} />
                </MenuItem>
              </Menu>
            )}
          </>
        )}
      </Box>
      {isRenderingFolderGroupFolderList && (
        <Collapse in={isFolderOpen}>
          <Box>
            <FolderGroupFolderList
              isEditable={isEditable}
              isInspectable={isInspectable}
              folderGroup={folderGroup}
              folderList={folderListInOrder}
              updateFolder={updateFolder}
              activeFolder={activeFolder}
              folderGroupFolderOrderList={folderGroupFolderOrderList}
              folderGroupList={folderGroupList}
              query={query}
              sortOptionList={sortOptionList}
              isFolderGroupMatch={matchResults.isFolderGroupMatch}
              isFolderGroupFolderMatch={matchResults.isFolderGroupFolderMatch}
              handleDownloadFolderReport={handleDownloadFolderReport}
              handleDownloadFolderReportLoading={handleDownloadFolderReportLoading}
              handleDownloadFolderReportError={handleDownloadFolderReportError}
            />
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

const MemoizedFolderGroupInner = React.memo(FolderGroupInner);

export const FolderGroup = ({ activeFolder, ...props }: FolderGroupComponentProps) => {
  // このグループ内のフォルダではない場合は常に undefined を返す。
  //
  // これにより、このグループ内のフォルダの選択/非選択が切り替わった場合は folder -> undefined もしくはその逆となるので render が発生するが、
  // このグループ内が非選択状態、かつ、他のグループのフォルダが選択された場合は undefined -> undefined となり render が発生しない。
  const activeFolderInGroup = activeFolder
    ? props.folderGroup?.folders?.find((folder) => folder.id === activeFolder?.id)
    : undefined;

  return (
    <MemoizedFolderGroupInner
      {...props}
      activeFolder={activeFolderInGroup}
    />
  );
};
