import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ExitPopupPhotoProps } from "domains";
import AbTestExitPopupPhotoApi from "javascripts/api/abTest/exitPopups/photo";
import AbTestExitPopupPhotoAuthenticationApi from "javascripts/api/abTest/exitPopups/photos/authentication";
import { removeItemAtIndex } from "utils";

export type ExitPopupPhotoListProps = {
  items: Array<ExitPopupPhotoProps>;
  nextPage: number;
};

const exitPopupPhotoListState = atom<ExitPopupPhotoListProps>({
  key: "exitPopupPhotoListState",
  default: {
    items: [],
    nextPage: 1,
  },
});

export type HandleCheckAuthorityExitPopupPhotoProps = (abTestUid: string) => Promise<boolean>;
export const useCheckAuthorityExitPopupPhoto = () => {
  const handle: HandleCheckAuthorityExitPopupPhotoProps = useCallback(async (abTestUid) => {
    try {
      await AbTestExitPopupPhotoAuthenticationApi.checkAuthetication(abTestUid);
      return true;
    } catch (e) {
      return false;
    }
  }, []);

  return [handle] as const;
};

export type HandleCreateExitPopupPhotoProps = (
  abTestUid: string,
  popupId: number,
  photoParams: {}
) => Promise<ExitPopupPhotoProps>;
export const useCreateExitPopupPhoto = () => {
  const [exitPopupPhotoList, setExitPopupPhotoList] = useRecoilState(exitPopupPhotoListState);

  const handle: HandleCreateExitPopupPhotoProps = useCallback(
    async (abTestUid, popupId, photoParams) => {
      try {
        const response = await AbTestExitPopupPhotoApi.create(abTestUid, popupId, photoParams);
        setExitPopupPhotoList({
          ...exitPopupPhotoList,
          items: [response, ...exitPopupPhotoList.items],
        });
        return response;
      } catch (e) {}
    },
    [exitPopupPhotoList]
  );
  return [handle] as const;
};

export type HandleUpdateExitPopupPhotoProps = (
  abTestUid: string,
  popupId: number,
  photoId: number,
  params: {}
) => void;
export const useUpdateExitPopupPhoto = () => {
  const [exitPopupPhotoList, setExitPopupPhotoList] = useRecoilState(exitPopupPhotoListState);

  const handle: HandleUpdateExitPopupPhotoProps = useCallback(
    async (abTestUid, popupId, photoId, params) => {
      try {
        await AbTestExitPopupPhotoApi.update(abTestUid, popupId, photoId, params);
        const index = exitPopupPhotoList.items.findIndex((photo) => photo.id === photoId);
        setExitPopupPhotoList({
          ...exitPopupPhotoList,
          items: removeItemAtIndex(exitPopupPhotoList.items, index),
        });
      } catch (e) {}
    },
    [exitPopupPhotoList]
  );

  return [handle] as const;
};

export type HandleFetchExitPopupPhotoListProps = (
  abTestUid: string,
  params: {
    page: number;
    search_range: string;
  }
) => void;
export const useFetchExitPopupPhotoList = () => {
  const [exitPopupPhotoList, setExitPopupPhotoList] = useRecoilState(exitPopupPhotoListState);

  const handle: HandleFetchExitPopupPhotoListProps = useCallback(
    async (abTestUid, params) => {
      try {
        const items = params.page === 1 ? [] : exitPopupPhotoList.items;
        const response = await AbTestExitPopupPhotoApi.fetch(abTestUid, params);
        setExitPopupPhotoList({
          items: [...items, ...response.photos],
          nextPage: response.next_page,
        });
      } catch (e) {}
    },
    [exitPopupPhotoList]
  );
  return [handle] as const;
};

export const useExitPopupPhotoList = (abTestUid: string) => {
  const [exitPopupPhotoList, setExitPopupPhotoList] = useRecoilState(exitPopupPhotoListState);
  const [handle] = useFetchExitPopupPhotoList();

  useEffect(() => {
    handle(abTestUid, { page: 1, search_range: "all" });
  }, []);

  return [exitPopupPhotoList, setExitPopupPhotoList] as const;
};
