import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ConversionEventTagArticleParameterDailyReportProps } from "domains";
import AbTestConversionEventTagArticleParameterDailyReportApi from "javascripts/api/abTest/conversionEventTags/articles/parameters/dailyReport";

export const abTestArticleParameterConversionEventTagDailyReportListState = atom<
  ConversionEventTagArticleParameterDailyReportProps[]
>({
  key: "abTestArticleParameterConversionEventTagDailyReportListState",
  default: [],
});

export const useAbTestConversionEventTagArticleParameterDailyReportList = (
  abTestUid: string,
  startDate: string,
  endDate: string
) => {
  const [reportList, setReportList] = useRecoilState(
    abTestArticleParameterConversionEventTagDailyReportListState
  );

  useEffect(() => {
    const params = {
      start_date: startDate,
      end_date: endDate,
    };
    AbTestConversionEventTagArticleParameterDailyReportApi.fetchReports(abTestUid, params).then(
      (data) => {
        setReportList(data.daily_reports);
      }
    );
  }, []);

  return [reportList] as const;
};
