import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestArticleDisplayPeriodsApi extends ApiBase {

  static create(articleUid, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/articles/${articleUid}/split_test_display_periods`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static update(articleUid, id, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/articles/${articleUid}/split_test_display_periods/${id}`;
      axios.patch(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroy(articleUid, id) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/articles/${articleUid}/split_test_display_periods/${id}`;
      axios.delete(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default AbTestArticleDisplayPeriodsApi;
