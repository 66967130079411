import axios from "axios";
import { DEFAULT_BASE_URL } from "const/app";
import { ArticleHtmlPartCategoryProps } from "domains";

const fetchArticleHtmlPartCategories = (): Promise<ArticleHtmlPartCategoryProps[]> => {
  return new Promise(function (resolve, reject) {
    axios
      .get<ArticleHtmlPartCategoryProps[]>(`${DEFAULT_BASE_URL}/articles/html_part_categories`, {
        withCredentials: true,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export const ArticleHtmlPartCategoryApi = {
  fetchArticleHtmlPartCategories,
} as const;
