import { FolderFormShowProps } from "domains";
import { FolderFormContent } from "domains/folder-form-content";
import moment from "moment";
import { convertHtmlToObject } from "utils/convert-html-to-object/convert-html-to-object";
import { ElementObject } from "utils/convert-html-to-object/type";

export const convertFormsObjectFormat = (forms: FolderFormShowProps[]) => {
  return forms.map((form) => convertFormObjectFormat(form));
};

export type FormObject = Pick<
  FolderFormShowProps,
  | "id"
  | "uid"
  | "name"
  | "form_type"
  | "thanks_url"
  | "auto_replied"
  | "published"
  | "ab_test_cv_tag_assigned"
  | "recaptcha_attached"
  | "confirmation_page_attached"
  | "contacts"
  | "last_answered_at"
  | "mail_setting"
  | "external_post_setting"
  | "folder"
> & {
  input: Pick<FolderFormContent, "id" | "html" | "tags"> & {
    blocks: ElementObject[];
    general_option: FolderFormContent["master_style_sheet"]["general_option"];
  };
  complete: Pick<FolderFormContent, "id" | "tags"> & {
    blocks: ElementObject[];
    general_option: FolderFormContent["master_style_sheet"]["general_option"];
  };
  reports: {
    date: moment.Moment;
    views: number;
    answers: number;
    exits: number;
  }[];
};

export const convertFormObjectFormat = (form: FolderFormShowProps) => {
  const inputContent = form.contents.filter((content) => content.content_type === "input")[0];
  const completeContent = form.contents.filter((content) => content.content_type === "complete")[0];

  const inputBlocks = convertHtmlToObject(inputContent.html);
  const completeBlocks = convertHtmlToObject(completeContent.html);

  const object: FormObject = {
    id: form.id,
    uid: form.uid,
    name: form.name,
    form_type: form.form_type,
    thanks_url: form.thanks_url,
    auto_replied: form.auto_replied,
    published: form.published,
    ab_test_cv_tag_assigned: form.ab_test_cv_tag_assigned,
    recaptcha_attached: form.recaptcha_attached,
    confirmation_page_attached: form.confirmation_page_attached,
    input: {
      id: inputContent.id,
      blocks: inputBlocks,
      general_option: inputContent.master_style_sheet.general_option,
      html: inputContent.html,
      tags: inputContent.tags,
    },
    complete: {
      id: completeContent.id,
      blocks: completeBlocks,
      general_option: completeContent.master_style_sheet.general_option,
      tags: completeContent.tags,
    },
    contacts: form.contacts,
    last_answered_at: form.last_answered_at,
    mail_setting: form.mail_setting,
    external_post_setting: form.external_post_setting,
    reports: [...Array(8).keys()].map((j) => {
      return {
        date: moment().add(j - 7, "days"),
        views: Math.floor(Math.random() * 10000),
        answers: Math.floor(Math.random() * 10000),
        exits: Math.floor(Math.random() * 10000),
      };
    }),
    folder: form.folder,
  };
  return object;
};
