import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const ClockIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="settings-time"
          transform="translate(-900.000000, -491.000000)"
          fill={iconColor}
        >
          <g
            id="Group-19"
            transform="translate(880.000000, 98.000000)"
          >
            <g
              id="sabumenyu"
              transform="translate(0.000000, 170.000000)"
            >
              <g
                id="Group-7"
                transform="translate(0.000000, 72.000000)"
              >
                <g
                  id="Group-4"
                  transform="translate(0.000000, 120.000000)"
                >
                  <g
                    id="Group-7"
                    transform="translate(20.000000, 31.000000)"
                  >
                    <path
                      d="M9,0 C13.9705627,-9.13077564e-16 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 C4.02943725,18 6.08718376e-16,13.9705627 0,9 C-6.08718376e-16,4.02943725 4.02943725,9.13077564e-16 9,0 Z M9,3 C8.44771525,3 8,3.44771525 8,4 L8,4 L8,8.467 L5.8660254,9.7 C5.42189654,9.95641792 5.24887253,10.5020742 5.44751582,10.9616647 L5.5,11.0660254 C5.77614237,11.544318 6.38773278,11.7081932 6.8660254,11.4320508 L6.8660254,11.4320508 L9.46410162,9.93205081 C9.64175316,9.82948364 9.77602793,9.68063834 9.86048992,9.50953436 C9.94907473,9.36063362 10,9.18626393 10,9 L10,9 L10,4 C10,3.44771525 9.55228475,3 9,3 Z"
                      id="icon_timing"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
