import { FC } from 'react';
import { useTranslation } from "react-i18next";
import {
  Box,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";

export const LineConversionApiDescription: FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography>
        {t("Squad beyondで計測したコンバージョンデータをLINEに送信することができます。")}
        {t("この機能を利用することで、コンバージョン計測や最適化の精度向上が期待できます。")}
      </Typography>
      <List>
        <ListItem sx={{ display: 'list-item' }}>
          <Link
            href="https://www.linebiz.com/jp/login/"
            target="_blank"
          >
            {t("ビジネスマネージャにログインします")}
          </Link>
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <img
            src="/integration/line/line_business_login.png"
            loading="lazy"
          />
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <img
            src="/integration/line/line_business_account_login.png"
            loading="lazy"
          />
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          {t("組織で利用できるLINE Tagから選択します")}
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <img
            src="/integration/line/line_business_line_tags.png"
            loading="lazy"
          />
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          {t("コンバージョンAPIというタブからLINE Tag IDとアクセストークンを取得します。")}
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          <img
            src="/integration/line/line_business_conversion_api.png"
            loading="lazy"
          />
        </ListItem>
        <ListItem sx={{ display: 'list-item' }}>
          {t("取得したLINE Tag IDとアクセストークンをSquad beyondに登録します。")}
        </ListItem>
      </List>
    </Box>
  );
};