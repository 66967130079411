import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import {
  UserSettingEmailApi,
  CustomError,
  UserSettingEmailConfirmationApi,
  UserSettingEmailConfirmationRequest,
  UserSettingEmailRequestParams,
  UserEmailVerificationApi,
} from "api";
import UserApi from "api/users";
import { UserProps } from "domains";
import { useLogging } from "hooks";

export const userState = atom<UserProps>({
  key: "userState",
  default: {} as UserProps,
});

export const useFetchUser = () => {
  const { sendErrorLog } = useLogging();
  const [_user, setUser] = useRecoilState(userState);

  const handle = useCallback(async () => {
    try {
      const response: UserProps = await UserApi.fetch();
      setUser(response);
      return response;
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to fetch user data",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};

export const useUser = () => {
  const [user, setUser] = useRecoilState(userState);
  const [handleFetchUser] = useFetchUser();

  useEffect(() => {
    handleFetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [user, setUser] as const;
};

export const useUpdateUser = () => {
  const [_user, setUser] = useRecoilState(userState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (params: {
      user: {
        company_name: string;
        name: string;
      };
    }) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);
      try {
        const response = await UserApi.update({
          companyName: params.user.company_name,
          name: params.user.name,
        });
        setLoading(false);
        setUser(response);
        return true;
      } catch (e) {
        setError(e);
        setLoading(false);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [handle, loading, error] as const;
};

export const useUpdateUserEmail = () => {
  const { sendErrorLog } = useLogging();
  const [_user, setUser] = useRecoilState(userState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();

  const handle = useCallback(async (params: UserSettingEmailRequestParams) => {
    if (loading) {
      return;
    }

    setError(undefined);
    setLoading(true);
    try {
      const response = await UserSettingEmailApi.update(params);
      setUser(response);
      return true;
    } catch (e: unknown) {
      if (e instanceof CustomError) {
        setError(e);
      }
      sendErrorLog({
        error: e,
        message: "Failed to revert unverified email",
      });
      return false;
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, loading, error] as const;
};

export const useRevertUserUnverifiedEmail = () => {
  const { sendErrorLog } = useLogging();
  const [_user, setUser] = useRecoilState(userState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();

  const handle = useCallback(async () => {
    if (loading) {
      return;
    }

    setError(undefined);
    setLoading(true);
    try {
      const response = await UserSettingEmailConfirmationApi.destroy();
      setUser(response);
      return true;
    } catch (e: unknown) {
      if (e instanceof CustomError) {
        setError(e);
      }
      sendErrorLog({
        error: e,
        message: "Failed to cancel update email",
      });
      return false;
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, loading, error] as const;
};

export const useConfirmUserEmail = () => {
  const [_user, setUser] = useRecoilState(userState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();
  const { sendErrorLog } = useLogging();

  const handle = useCallback(async (params: UserSettingEmailConfirmationRequest) => {
    if (loading) {
      return;
    }

    setError(undefined);
    setLoading(true);
    try {
      const response = await UserSettingEmailConfirmationApi.create(params);
      setLoading(false);
      setUser(response);
      return true;
    } catch (e) {
      if (e instanceof CustomError) {
        setError(e);
      }
      sendErrorLog({
        error: e,
        message: "Failed to confirmation email",
      });
      setLoading(false);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, loading, error] as const;
};

export const useFetchUserEmailVerification = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();
  const { sendErrorLog } = useLogging();

  const handle = useCallback(async () => {
    if (loading) {
      return;
    }

    setError(undefined);
    setLoading(true);
    try {
      const response = await UserEmailVerificationApi.fetch();
      return response.is_email_verified;
    } catch (e) {
      if (e instanceof CustomError) {
        setError(e);
      }
      sendErrorLog({
        error: e,
        message: "Failed to fetch email verification",
      });
      return false;
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, loading, error] as const;
};
