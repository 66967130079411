import { get, patch } from "api";
import { DEFAULT_BASE_URL } from "const/app";

export type AbTestDeliveryArchitectureSettingResponse = {
  is_cloudflare: boolean;
};

const fetch = async (abTestUid: string): Promise<AbTestDeliveryArchitectureSettingResponse> => {
  const path = `${DEFAULT_BASE_URL}/ab_tests/${abTestUid}/delivery_architecture_settings`;
  return get<{}, AbTestDeliveryArchitectureSettingResponse>({}, path);
};

const update = async (
  abTestUid: string,
  isCloudflare: boolean,
): Promise<AbTestDeliveryArchitectureSettingResponse> => {
  const path = `${DEFAULT_BASE_URL}/ab_tests/${abTestUid}/delivery_architecture_settings`;
  const params = { is_cloudflare: isCloudflare };
  return patch<{}, AbTestDeliveryArchitectureSettingResponse>(params, path);
};

export const AbTestDeliveryArchitectureSettingApi = {
  fetch,
  update,
} as const;
