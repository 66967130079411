import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleProps } from "domains";
import AbTestSplitTestSettingHourArticleApi from "javascripts/api/abTest/splitTestSettings/hours/article";

export const abTestSplitTestSettingHourArticleListState = atom<ArticleProps[]>({
  key: "abTestSplitTestSettingHourArticleListState",
  default: [],
});

export const useFetchAbTestSplitTestSettingHourArticleList = (abTestUid: string) => {
  const [articleList, setArticleList] = useRecoilState(abTestSplitTestSettingHourArticleListState);

  useEffect(() => {
    AbTestSplitTestSettingHourArticleApi.fetchList(abTestUid).then((data) => {
      setArticleList(data.articles);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid]);

  return [articleList] as const;
};
