export const isFolderGroupIncludeFolderMatchQuery = (folderGroup, query) => {
  const existsQuery = query !== '';
  if (!existsQuery) {
    return { isFolderGroupFolderMatch: true, isFolderGroupMatch: true };
  }

  const folderNames = folderGroup.folders.map(folder => {
    let s = '';

    let domain = '';
    if (folder.domain) { domain = folder.domain.domain; }
    s = s.concat(domain);
    s = s.concat(` ${folder.name}`);
    return s;
  }).join(' ');

  const isFolderGroupFolderMatch = folderNames.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  const isFolderGroupMatch = folderGroup.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;

  return { isFolderGroupFolderMatch: isFolderGroupFolderMatch, isFolderGroupMatch: isFolderGroupMatch };
};
