import { RichMenuList } from "components/molecules/core/RichMenuList";
import { RichMenuContentsProps } from "./type";

export const RichMenuContents = ({ menuId, groups }: RichMenuContentsProps) => {
  if (menuId === "folder" && groups?.length)
    return (
      <RichMenuList
        groups={groups}
        onViewAllFolders={() => {
          location.href = "/folders";
        }}
      />
    );

  // TODO: コンテンツが用意できたら表示する
  // if (menuId !== "folder" && !!descriptionContent)
  //   return <RichMenuDescription {...descriptionContent} />;

  return <></>;
};
