import { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Quill } from "react-quill";

import duplicateIcon from "assets/images/duplicate-blue.svg";
import trashIcon from "assets/images/trash.svg";
import widgetEditHtmlIcon from "assets/images/widget-edit-html.svg";
import widgetQuickEditIcon from "assets/images/widget-quick-edit.svg";
import { ArticleHtmlPartProps } from "domains";
import {
  injectExternalStyles,
  isFindTargetParentNodeByClassName,
  createUniqueString,
} from "javascripts/utils";

import articleHeaderPhotoStyles from "stylesheets/components/editor/articleHeaderPhoto.module.scss";
import articlePartActionDropdownStyles from "stylesheets/components/quillEditor/articlePartActionDropdown.module.scss";
import { searchParentNodeByClassName } from "utils/element";
import { ArticleHtmlPartActionDropdownProps } from "./type";

const Parchment = Quill.import("parchment");

export const ArticleHtmlPartActionDropdown = ({
  article,
  quillRef,
  selectedPart,
  editorContentLoading,
  setSelectedPart,
  setAction,
  updateArticleBody,
  handlePageLoad,
}: ArticleHtmlPartActionDropdownProps) => {
  const { t } = useTranslation();
  const [isQuickEdit, handleIsQuickEdit] = useState(false);
  const [removeKeyCount, handleRemoveKeyCount] = useState(0);

  const actionDropdownRef = useRef<HTMLDivElement>(null);
  const actionDropdownBodyRef = useRef<HTMLDivElement>(null);
  const actionDropdownArrowRef = useRef<HTMLDivElement>(null);

  const articleHeaderPhotoHeight = useMemo(() => {
    return parseInt(
      document
        .querySelector<HTMLElement>(`.${articleHeaderPhotoStyles.articleHeaderPhoto}`)
        ?.getBoundingClientRect()
        ?.height.toString() ?? "0"
    );
  }, []);

  const selectPartBorder = useMemo(() => {
    return document
      .querySelector<HTMLIFrameElement>("#quillIframe")
      ?.contentDocument?.querySelector<HTMLElement>(
        `.${articlePartActionDropdownStyles.selectPartBorder}`
      );
  }, [editorContentLoading]);

  useEffect(() => {
    if (editorContentLoading) return;
    const articleParts = Array.from<HTMLElement>(
      document
        .querySelector<HTMLIFrameElement>("#quillIframe")
        ?.contentDocument?.querySelectorAll(".sb-custom") ?? []
    );
    for (const part of articleParts) {
      part.addEventListener("click", openDropDown);
      const links = part.querySelectorAll("a");
      //block anchor tag(imgなどを含むもの)のpointer-events: none;での制御ができないのでclick eventを無効にする
      links.forEach((link) => {
        link.addEventListener("click", preventDefault);
      });
    }

    return () => {
      for (const part of articleParts) {
        part.addEventListener("click", openDropDown);
        const links = part.querySelectorAll("a");
        //block anchor tag(imgなどを含むもの)のpointer-events: none;での制御ができないのでclick eventを無効にする
        links.forEach((link) => {
          link.addEventListener("click", preventDefault);
        });
      }
    };
  }, [article.uid, article.updated_timestamp, editorContentLoading]);

  useEffect(() => {
    const quillIframe = document.querySelector<HTMLIFrameElement>("#quillIframe");
    const quillIframeDoc = quillIframe?.contentDocument;
    const editor = quillIframe?.contentDocument?.querySelector<HTMLElement>(".ql-editor");

    quillIframeDoc?.addEventListener("dragstart", closeDropdown);
    document.addEventListener("click", hundleDocumentClick);
    quillIframeDoc?.addEventListener("click", hundleDocumentClick);

    document.addEventListener("mousedown", removeQuickEditIframe);
    quillIframeDoc?.addEventListener("mousedown", removeQuickEditIframe);
    editor?.addEventListener("keydown", handleKeyDown);

    return () => {
      const quillIframe = document.querySelector<HTMLIFrameElement>("#quillIframe");
      const quillIframeDoc = quillIframe?.contentDocument;
      if (quillIframe) {
        quillIframeDoc?.removeEventListener("dragstart", closeDropdown);
        quillIframeDoc?.removeEventListener("click", hundleDocumentClick);
        quillIframeDoc?.removeEventListener("mousedown", removeQuickEditIframe);
        const editor = quillIframeDoc?.querySelector<HTMLElement>(".ql-editor");
        if (editor) editor.removeEventListener("keydown", handleKeyDown);
      }
      document.removeEventListener("click", hundleDocumentClick);
      document.removeEventListener("mousedown", removeQuickEditIframe);
    };
  }, [editorContentLoading]);

  useEffect(() => {
    const quillIframe = document.querySelector<HTMLIFrameElement>("#quillIframe");
    const quillIframeDoc = quillIframe?.contentDocument;
    quillIframeDoc
      ?.querySelector<HTMLDivElement>(".ql-editor")
      ?.addEventListener("scroll", handleScroll);
    return () => {
      const quillIframeDoc = quillIframe?.contentDocument;
      quillIframeDoc?.querySelector(".ql-editor")?.removeEventListener("scroll", handleScroll);
    };
  }, [selectedPart]);

  useEffect(() => {
    const quillIframe = document.querySelector<HTMLIFrameElement>("#quillIframe");
    const quillIframeDoc = quillIframe?.contentDocument;
    const iframe = quillIframeDoc?.querySelector<HTMLIFrameElement>(".quickEditIframe");
    if (!iframe) return;
    if (!iframe.contentWindow) return;
    iframe.contentWindow.document.addEventListener("keydown", (e) => {
      const selection = iframe?.contentDocument?.getSelection();
      const range = selection?.anchorNode ? selection.getRangeAt(0) : null;
      quickEditorIframeHandleKeydown(e, selection ?? null, range);
    });

    return () => {
      const iframe = document
        .querySelector<HTMLIFrameElement>("#quillIframe")
        ?.contentDocument?.querySelector<HTMLIFrameElement>(".quickEditIframe");
      if (!iframe) return;
      if (!iframe.contentWindow) return;
      iframe.contentWindow.document.removeEventListener("keydown", function (e) {
        const selection = iframe?.contentDocument?.getSelection();
        const range = selection?.anchorNode ? selection.getRangeAt(0) : null;
        quickEditorIframeHandleKeydown(e, selection ?? null, range);
      });
    };
  }, [isQuickEdit, removeKeyCount]);

  const closeDropdown = () => {
    actionDropdownBodyRef.current?.classList.remove(articlePartActionDropdownStyles.open);
    if (selectPartBorder) selectPartBorder.style.display = "none";
  };

  const preventDefault = (e: MouseEvent) => {
    const quillIframe = document.querySelector<HTMLIFrameElement>("#quillIframe");
    //editor読み込み直後にanchor tagをクリックするとeditor上部に飛んでしまうので、ここでquillのdisable実行して制御する
    //このeventが走るのはsb-customないのanchorタグなのでopenDropDownが走るので最後に解除する
    (quillIframe?.contentWindow as Window & { quill: Quill })?.quill.disable();
    e.preventDefault();
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    //keyCode {8: delete, 13: enter, 32: backspace}
    if (e.keyCode === 13) {
      actionDropdownBodyRef.current?.classList.remove(articlePartActionDropdownStyles.open);
    }
    if ([8, 32].indexOf(e.keyCode) >= 0) {
      if (selectPartBorder) selectPartBorder.style.display = "none";
      actionDropdownBodyRef.current?.classList.remove(articlePartActionDropdownStyles.open);
    }
  };

  const handleScroll = () => {
    if (selectedPart) {
      actionDropdownBodyRef.current?.classList.remove(articlePartActionDropdownStyles.open);
      if (selectPartBorder) selectPartBorder.style.display = "none";
    }
  };

  const openDropDown = (e: MouseEvent) => {
    const currentTarget = e.currentTarget as HTMLElement;
    const targetRect = currentTarget.getBoundingClientRect();

    if (quillRef) {
      const partBlot = Parchment.find(currentTarget);
      const targetIndex = quillRef.getIndex(partBlot);
      quillRef.setSelection(targetIndex + 1, 0, "silent");
    }

    const targetPartChildren = currentTarget.querySelector<HTMLElement>("[id^=sb-part]")?.children;
    let isFixed = false;
    let isFixedTopBottom = "bottom";
    const btnActionDuplicate = document.querySelector<HTMLElement>(".btnActionDuplicate");
    Array.from(targetPartChildren ?? []).forEach((ele) => {
      let currentNode = ele;
      if (window.getComputedStyle(ele).getPropertyValue("position") === "fixed") {
        isFixed = true;
        if (window.getComputedStyle(ele).getPropertyValue("top") === "0px") {
          isFixedTopBottom = "top";
        }
        return;
      }
      while (currentNode.firstElementChild) {
        if (window.getComputedStyle(currentNode).getPropertyValue("position") === "fixed") {
          isFixed = true;
          if (window.getComputedStyle(currentNode).getPropertyValue("top") === "0px") {
            isFixedTopBottom = "top";
          }
          break;
        }
        currentNode = currentNode.firstElementChild;
      }
    });
    if (btnActionDuplicate) {
      if (isFixed) {
        btnActionDuplicate.style.display = "none";
      } else {
        btnActionDuplicate.style.display = "block";
      }
    }

    const quillEditorContentWrapper = document.querySelector<HTMLElement>(
      ".quillEditorContentWrapper"
    );
    const quillEditorContentWrapperRect = quillEditorContentWrapper?.getBoundingClientRect();
    if (selectPartBorder) {
      selectPartBorder.style.height = `${targetRect.height + 3}px`;
      selectPartBorder.style.width = `${targetRect.width + 3}px`;
      selectPartBorder.style.left = `${targetRect.left - 3}px`;
      if (isFixed) {
        selectPartBorder.style.bottom = `0px`;
        selectPartBorder.style.display = "none";
      } else {
        selectPartBorder.style.top = `${targetRect.top - 3}px`;
        selectPartBorder.style.display = "block";
      }
    }
    if (actionDropdownBodyRef.current) {
      actionDropdownBodyRef.current.classList.add(articlePartActionDropdownStyles.open);

      const rectHeight = quillEditorContentWrapperRect?.height ?? 0;
      if (rectHeight - articleHeaderPhotoHeight < targetRect.height) {
        actionDropdownBodyRef.current.style.top = `${rectHeight / 2}px`;
      } else if (targetRect.top < 0) {
        actionDropdownBodyRef.current.style.top = `-80px`;
      } else {
        actionDropdownBodyRef.current.style.top = `${targetRect.top - 70}px`;
      }
      if (isFixed && isFixedTopBottom === "bottom") {
        actionDropdownBodyRef.current.style.top = `${
          (rectHeight ?? actionDropdownBodyRef.current.style.top) - articleHeaderPhotoHeight - 80
        }px`;
      } else if (isFixed && isFixedTopBottom === "top") {
        actionDropdownBodyRef.current.style.top = `0px`;
      }

      if (actionDropdownArrowRef.current) {
        actionDropdownArrowRef.current.style.top = `50%`;
      }
    }

    setSelectedPart(currentTarget);
    const quillIframe = document.querySelector<HTMLIFrameElement>("#quillIframe");
    if (quillIframe) (quillIframe?.contentWindow as Window & { quill: Quill }).quill.enable();
  };

  const setQuickEditIframe = () => {
    const iframe = document
      .querySelector<HTMLIFrameElement>("#quillIframe")
      ?.contentDocument?.createElement("iframe");
    const editor = document
      .querySelector<HTMLIFrameElement>("#quillIframe")
      ?.contentDocument?.querySelector<HTMLElement>(".ql-editor");
    const targetPart = (selectedPart as HTMLElement).querySelector<HTMLElement>("[id^=sb-part]");
    const targetPartChildren = targetPart?.children;
    let iframeHeight = 0;

    editor?.setAttribute("contenteditable", String(false));

    Array.from(targetPartChildren ?? []).forEach((ele) => {
      const styles = window.getComputedStyle(ele);
      const margin = parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
      iframeHeight += ele.getBoundingClientRect().height + margin;
    });

    iframe?.setAttribute(
      "style",
      `width: 100%; height: ${iframeHeight}px !important; border: 2px solid #0086ff; margin: 0 !important;`
    );
    iframe?.setAttribute("class", "quickEditIframe");
    if (iframe) {
      (selectedPart as HTMLElement).parentNode?.replaceChild(iframe, selectedPart as HTMLElement);
    }
    const contenteditable = (selectedPart as HTMLElement).querySelector<HTMLElement>(
      "[contenteditable=false]"
    );

    if (contenteditable) {
      contenteditable.setAttribute("contenteditable", String(true));
      contenteditable.style.outline = String(0);
    }

    // Note: iframeがappendされるタイミングの関係で、このタイミングでwindowを取得
    const iframeWin = iframe?.contentWindow;
    injectExternalStyles(iframeWin?.document);
    const selectorStyles =
      "<style> span, p, div, h1, h2, h3, h4, h5, h6 {max-width: 100%; margin: 0;}img{max-width: 100% !important; display: block !important; height: auto !important; box-sizing: border-box;}</style>";
    iframeWin?.document.body.setAttribute("style", "margin: 0; background-color: #ffffff;");
    const partDiv = iframeWin?.document.createElement("div");
    const iframeStyle = document
      .querySelector<HTMLIFrameElement>("#quillIframe")
      ?.contentDocument?.querySelector<HTMLStyleElement>("style");
    partDiv?.insertAdjacentHTML("afterbegin", (selectedPart as HTMLElement).outerHTML);
    if (iframeStyle) {
      partDiv?.appendChild(iframeStyle.cloneNode(true));
    }
    partDiv?.setAttribute("style", "padding: 0;");
    if (partDiv) {
      iframeWin?.document.body.appendChild(partDiv);
    }
    iframeWin?.document.head.insertAdjacentHTML("afterbegin", selectorStyles);
    iframeWin?.document.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
      if (e.which === 65 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
      }
    });
    actionDropdownBodyRef.current?.classList.remove(articlePartActionDropdownStyles.open);
    if (selectPartBorder) selectPartBorder.style.display = "none";
    handleIsQuickEdit(true);
    (selectedPart as HTMLElement).removeEventListener("click", openDropDown);

    const links = (selectedPart as HTMLElement).querySelectorAll("a");
    //block anchor tag(imgなどを含むもの)のpointer-events: none;での制御ができないのでclick eventを無効にする
    links.forEach((link) => {
      link.removeEventListener("click", function (e) {
        e.preventDefault();
      });
    });
  };

  const removeQuickEditIframe = () => {
    const quillIframe = document.querySelector<HTMLIFrameElement>("#quillIframe");
    const quillIframeDoc = quillIframe?.contentDocument;
    const editor = quillIframeDoc?.querySelector<HTMLElement>(".ql-editor");
    const quickEditIframe = quillIframeDoc?.querySelector<HTMLIFrameElement>(".quickEditIframe");
    if (!quickEditIframe) return;
    editor?.setAttribute("contenteditable", String(true));
    const editedPart = quickEditIframe.contentWindow?.document.body.querySelector(".sb-custom");
    const part = quillIframeDoc?.createElement("div") ?? null;
    part?.setAttribute("class", "sb-custom");
    if (part) {
      part.innerHTML = editedPart?.querySelector('[contenteditable="true"]')?.innerHTML ?? "";
      quickEditIframe.parentNode?.replaceChild(part, quickEditIframe);
    }
    setSelectedPart(part);
    handleIsQuickEdit(false);
    part?.addEventListener("click", openDropDown);
    const links = part?.querySelectorAll("a") ?? [];
    //block anchor tag(imgなどを含むもの)のpointer-events: none;での制御ができないのでclick eventを無効にする
    Array.from(links).forEach((link) => {
      link.addEventListener("click", function (e) {
        e.preventDefault();
      });
    });
  };

  const quickEditorIframeHandleKeydown = (
    e: KeyboardEvent,
    selection: Selection | null,
    range: Range | null
  ) => {
    if (e.keyCode === 8) {
      const blockTagName = [
        "ADDRESS",
        "BLOCKQUOTE",
        "CENTER",
        "DIV",
        "DL",
        "FIELDSET",
        " FORM",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "HR",
        "NOFRAMES",
        "NOSCRIPT",
        "OL",
        "P",
        "PRE",
        "TABLE",
        "UL",
      ];
      if (
        blockTagName.includes((selection?.anchorNode as HTMLElement | undefined)?.tagName ?? "")
      ) {
        e.preventDefault();
        return;
      }
      if (
        selection?.anchorNode &&
        selection.anchorNode.firstChild &&
        selection.anchorNode.firstChild.nodeName === "BR"
      ) {
        e.preventDefault();
      }
      if (
        removeKeyCount === 0 &&
        ((e.target as HTMLElement).querySelector("[id^=sb-part]")?.textContent ?? "").length === 1
      ) {
        e.preventDefault();
        const text = t("を入力してください", { value: t("文字") });
        if (selection?.anchorNode) {
          selection.anchorNode.nodeValue = text;
        }
        range?.selectNode(range.startContainer);
        handleRemoveKeyCount(1);
        return;
      }

      handleRemoveKeyCount(0);
    }
    if (e.keyCode === 13) {
      e.preventDefault();
    }
    if (e.which === 65 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
    }
  };

  const editArticlePart = (arg: { detail: { uid: string } } | null = null) => {
    let targetPart = null;
    if (arg) {
      targetPart = quillRef.root.querySelector<HTMLElement>(`.${arg.detail.uid}`);
    } else {
      targetPart = (selectedPart as HTMLElement).querySelector<HTMLElement>("[id^=sb-part]");
    }
    const partHtml = targetPart?.innerHTML;
    const styles = Array.from(targetPart?.parentNode?.querySelectorAll("style") ?? []);
    const partCss = styles.length > 0 ? styles[styles.length - 1].innerText : "";
    const sbCustom = targetPart ? searchParentNodeByClassName(targetPart, "sb-custom") : null;
    const partBlot = Parchment.find(sbCustom, true);
    const targetIndex = quillRef.getIndex(partBlot);
    quillRef.setSelection(targetIndex + 1, 0, (Quill as any).sources.SILENT);
    const matchedPart = targetPart?.id.match(/sb-part-(.+)/);
    const partKeyword = matchedPart ? matchedPart[1] : "";
    const id = ["prev", "next"].includes(partKeyword) ? partKeyword : parseInt(partKeyword);
    //nodeとindexは編集時のcreate,destroyに使用する
    const partJson: Partial<ArticleHtmlPartProps> = {
      id: Number(id),
      html: partHtml,
      css: partCss,
      index: targetIndex,
      node: partBlot.domNode,
    };
    setSelectedPart(partJson);
    setAction("insert");

    const url = window.location;
    const pageParams = new URLSearchParams(url.search);
    if (pageParams.has("sb_part_uid")) {
      pageParams.delete("sb_part_uid");
      const path = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }${pageParams.toString() ? `?${pageParams.toString()}` : ""}${
        window.location.hash ? `${window.location.hash}` : ""
      }`;
      window.history.replaceState({ path: path }, "", path);
    }
  };

  useEffect(() => {
    // プレビューから直接widget編集を開くために使うイベント
    // カスタムイベントのため型回避のためにanyを使用
    (document as any).addEventListener("openWidgetEditModal", editArticlePart, { once: true });
  }, []);

  const duplicateArticlePart = () => {
    const part = document.createElement("div");
    const partClass = createUniqueString();
    const clonedPart = (selectedPart as HTMLElement)
      .querySelector<HTMLElement>("[class^=sb-custom-part]")
      ?.cloneNode(true);
    if (clonedPart) {
      part.appendChild(clonedPart);
    }

    const clonedStyle = (selectedPart as HTMLElement).querySelector("style")?.cloneNode(true);
    if (clonedStyle) {
      part.appendChild(clonedStyle);
    }

    const customPart = part.querySelector<HTMLDivElement>("[class^=sb-custom-part]");
    const matchedPartClass = part.innerHTML.match(/.sb-custom-part-(.*?)"/);
    const cloneClass = matchedPartClass ? matchedPartClass[1] : "";
    if (customPart) {
      customPart.className = `sb-custom-part-${partClass}`;
    }

    const partBlot = Parchment.find(selectedPart);
    const targetIndex = quillRef.getIndex(partBlot);
    quillRef.insertText(targetIndex + 2, "\n");
    const regax = new RegExp(cloneClass, "g");
    quillRef.insertEmbed(targetIndex + 2, "sb-custom", part.innerHTML.replace(regax, partClass));
    const insertPart = document
      .querySelector<HTMLIFrameElement>("#quillIframe")
      ?.contentDocument?.querySelector<HTMLElement>(`.sb-custom-part-${partClass}`);
    insertPart?.scrollIntoView({ block: "center" });
    const sbCustom = insertPart ? searchParentNodeByClassName(insertPart, "sb-custom") : null;
    sbCustom?.addEventListener("click", openDropDown);
    const links = sbCustom?.querySelectorAll("a");
    //block anchor tag(imgなどを含むもの)のpointer-events: none;での制御ができないのでclick eventを無効にする
    Array.from(links ?? []).forEach((link) => {
      link.addEventListener("click", function (e) {
        e.preventDefault();
      });
    });
    actionDropdownBodyRef.current?.classList.remove(articlePartActionDropdownStyles.open);
    if (selectPartBorder) {
      selectPartBorder.style.display = "none";
    }
    handlePageLoad(false);
    updateArticleBody();
  };

  const removeArticlePart = () => {
    (selectedPart as HTMLElement).parentNode?.removeChild(selectedPart as HTMLElement);
    actionDropdownBodyRef.current?.classList.remove(articlePartActionDropdownStyles.open);
    if (selectPartBorder) {
      selectPartBorder.style.display = "none";
    }

    setSelectedPart(null);
    (selectedPart as HTMLElement).removeEventListener("click", openDropDown);
    handlePageLoad(false);
    updateArticleBody();
  };

  const hundleDocumentClick = (e: MouseEvent) => {
    if (isFindTargetParentNodeByClassName(e.target, "sb-custom")) return;
    let currentNode = e.target as Node;

    let isInner = false;
    while (currentNode.parentNode) {
      if (currentNode === actionDropdownRef.current) {
        isInner = true;
        break;
      }
      currentNode = currentNode.parentNode;
    }
    if (!isInner) {
      if (actionDropdownBodyRef.current)
        actionDropdownBodyRef.current.classList.remove(articlePartActionDropdownStyles.open);
      if (selectPartBorder) selectPartBorder.style.display = "none";
    }
  };

  const articlePartCopy = () => {
    const iframe = document.getElementById("quillIframe") as HTMLIFrameElement | null;
    const blot = Parchment.find(selectedPart);
    quillRef.setSelection(quillRef.getIndex(blot), 1);
    iframe?.contentDocument?.execCommand("copy");
    actionDropdownBodyRef.current?.classList.remove(articlePartActionDropdownStyles.open);
  };

  return (
    <>
      <div
        className={articlePartActionDropdownStyles.actionDropdown}
        ref={actionDropdownRef}
      >
        <div
          className={articlePartActionDropdownStyles.actionDropdownBody}
          ref={actionDropdownBodyRef}
        >
          <div
            className={articlePartActionDropdownStyles.arrow}
            ref={actionDropdownArrowRef}
          />
          <div
            className={articlePartActionDropdownStyles.actionButton}
            onClick={() => editArticlePart()}
          >
            <img
              alt=""
              src={widgetEditHtmlIcon}
              width={20}
              height={20}
            />
            {t("HTML編集")}
          </div>
          <div
            className={articlePartActionDropdownStyles.actionButton}
            onClick={() => setQuickEditIframe()}
          >
            <img
              alt=""
              src={widgetQuickEditIcon}
              width={20}
              height={20}
            />
            {t("クイック編集")}
          </div>
          <div
            className={`${articlePartActionDropdownStyles.actionButton} btnActionDuplicate`}
            onClick={() => duplicateArticlePart()}
          >
            <img
              alt=""
              src={duplicateIcon}
              width={20}
              height={20}
            />
            {t("すぐ下に複製")}
          </div>
          <div
            className={`${articlePartActionDropdownStyles.actionButton} btnActionDuplicate`}
            onClick={() => articlePartCopy()}
          >
            <img
              alt=""
              src={duplicateIcon}
              width={20}
              height={20}
            />
            {t("widgetコピー")}
          </div>
          <div
            className={articlePartActionDropdownStyles.actionButton}
            onClick={() => removeArticlePart()}
          >
            <img
              alt=""
              src={trashIcon}
              width={20}
              height={20}
            />
            {t("Versionから削除する")}
          </div>
        </div>
      </div>
    </>
  );
};
