import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestUrlSearchApi extends ApiBase {
  static fetch(params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/url_search`, { params, withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default AbTestUrlSearchApi;
