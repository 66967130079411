import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ExitPopupProps } from "domains";
import FolderExitPopupApi from "javascripts/api/folders/exitPopup";

export const folderExitPopupListState = atom<ExitPopupProps[]>({
  key: "folderExitPopupListState",
  default: [],
});

export const useFolderExitPopupList = (folderUid: string) => {
  const [exitPopupList, setExitPopupList] = useRecoilState(folderExitPopupListState);

  useEffect(() => {
    if (folderUid === "" || folderUid === null) {
      return;
    }

    FolderExitPopupApi.fetchList(folderUid).then((data) => {
      setExitPopupList(data.exit_popups);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderUid]);

  return [exitPopupList] as const;
};
