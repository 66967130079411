import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Switch, Tooltip, Typography } from "@mui/material";
import {
  BeyondPageArticleOptionList,
  SplitTestSettingInfoForNonBoostContractor,
  SplitTestSettingInfoForNonBoostSetting,
  Theme,
} from "components";
import { LocalNavigation } from "components/organisms/core/LocalNavigation";
import { useAlert } from "contexts/alert";
import { AbTestProps, ArticleProps, ArticleSplitTestCarrierBlockListsProps } from "domains";

import {
  useAbTestDeliveryArchitectureSetting,
  useCreateAbTestSplitTestSettingCarriersArticleList,
  useDestroyAbTestSplitTestSettingCarriersArticleList,
} from "hooks";
import Nav from "javascripts/components/Editor/Nav";
import SaveAnimation, { SaveAnimationHandler } from "javascripts/components/Partials/SaveAnimation";

const SwitchButton: React.FC<{
  articleUid: string;
  carrierId: number;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  onCreateCarrier: Function;
  onDestroyCarrier: Function;
  isDisabled: boolean;
}> = ({
  articleUid,
  carrierId,
  isChecked,
  setIsChecked,
  onCreateCarrier,
  onDestroyCarrier,
  isDisabled,
}) => {
  const { clearAlerts } = useAlert();

  type Params = {
    carrier_id: number;
  };
  const handleCheck = useCallback(async (params: Params) => {
    const response = await onCreateCarrier(articleUid, params);
    if (response) {
      setIsChecked(false);
    }
  }, []);

  const handleUnCheck = useCallback(async (params: Params) => {
    const response = await onDestroyCarrier(articleUid, params);
    if (response) {
      setIsChecked(true);
    }
  }, []);

  const handleChange = useCallback(() => {
    clearAlerts();
    const params = {
      carrier_id: carrierId,
    };
    if (isChecked) {
      handleCheck(params);
    } else {
      handleUnCheck(params);
    }
  }, []);

  return (
    <Switch
      checked={isChecked}
      disabled={isDisabled}
      sx={{
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "primary.contrastText",
        },
      }}
      onChange={handleChange}
    />
  );
};

const Article: React.FC<{
  article: ArticleProps;
}> = ({ article }) => {
  const [docomoId, auId, softbankId] = [1, 2, 3];
  const [isDocomoBlocked, setIsDocomoBlocked] = useState<boolean>(false);
  const [isAuBlocked, setIsAuBlocked] = useState<boolean>(false);
  const [isSoftbankBlocked, setIsSoftbankBlocked] = useState<boolean>(false);
  const { showAlerts } = useAlert();
  const [handleCreateCarrier, createCarrierLoading, createCarrierError] =
    useCreateAbTestSplitTestSettingCarriersArticleList();
  const [handleDestroyCarrier, destroyCarrierLoading, destroyCarrierError] =
    useDestroyAbTestSplitTestSettingCarriersArticleList();

  useEffect(() => {
    if (createCarrierError?.message) {
      showAlerts({ messages: [createCarrierError.message], theme: "danger" });
    }
  }, [createCarrierError]);

  useEffect(() => {
    if (destroyCarrierError?.message) {
      showAlerts({
        messages: [destroyCarrierError.message],
        theme: "danger",
      });
    }
  }, [destroyCarrierError]);

  useEffect(() => {
    const existsDocomoBlocklist =
      article.split_test_carrier_blocklists.find(
        (blocklist: ArticleSplitTestCarrierBlockListsProps) => blocklist.carrier_id === docomoId,
      ) !== undefined;
    const existsAuBlocklist =
      article.split_test_carrier_blocklists.find(
        (blocklist: ArticleSplitTestCarrierBlockListsProps) => blocklist.carrier_id === auId,
      ) !== undefined;
    const existsSoftbankBlocklist =
      article.split_test_carrier_blocklists.find(
        (blocklist: ArticleSplitTestCarrierBlockListsProps) => blocklist.carrier_id === softbankId,
      ) !== undefined;

    setIsDocomoBlocked(!existsDocomoBlocklist);
    setIsAuBlocked(!existsAuBlocklist);
    setIsSoftbankBlocked(!existsSoftbankBlocklist);
  }, []);

  const SwitchButtonContent: React.FC<{
    isBlocked: boolean;
    setIsBlocked: React.Dispatch<React.SetStateAction<boolean>>;
    carrierId: number;
  }> = ({ isBlocked, setIsBlocked, carrierId }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "46px",
        }}
      >
        <SwitchButton
          articleUid={article.uid}
          carrierId={carrierId}
          isChecked={isBlocked}
          setIsChecked={setIsBlocked}
          onCreateCarrier={handleCreateCarrier}
          onDestroyCarrier={handleDestroyCarrier}
          isDisabled={createCarrierLoading || destroyCarrierLoading}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ bgcolor: "gray" }}>
      <Box
        sx={{
          pr: 1.4,
          pb: 2,
          bgcolor: "background.primary",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            bgcolor: "background.secondary",
            borderRadius: "0 10px 10px 0",
          }}
        >
          <SwitchButtonContent
            isBlocked={isDocomoBlocked}
            setIsBlocked={setIsDocomoBlocked}
            carrierId={docomoId}
          />
          <SwitchButtonContent
            isBlocked={isAuBlocked}
            setIsBlocked={setIsAuBlocked}
            carrierId={auId}
          />
          <SwitchButtonContent
            isBlocked={isSoftbankBlocked}
            setIsBlocked={setIsSoftbankBlocked}
            carrierId={softbankId}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ArticleTitle: React.FC<{
  title: string;
  abTestRate: number;
  // FIXME
  // eslint-disable-next-line react/display-name
}> = memo(({ title, abTestRate }) => {
  return (
    <Tooltip
      title={<Typography sx={{ fontSize: "15px" }}>{title}</Typography>}
      placement="top"
    >
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            fontSize: "14px",
            height: "46px",
            lineHeight: "46px",
            px: 1.4,
            py: 1,
            boxSizing: "border-box",
            bgcolor: "lightGray",
            borderRadius: "10px 0 0 10px",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              flex: 1,
              color: "gray",
              textAlign: "right",
            }}
          >
            {abTestRate}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
});

const Carriers: React.FC<{
  articleList: ArticleProps[];
  abTestUid: string;
  isCanUseBoostMode: boolean;
  folderUid: string | undefined;
}> = ({ articleList, abTestUid, isCanUseBoostMode, folderUid }) => {
  const { t } = useTranslation();
  const [setting, isCloudflareSettingLoading] = useAbTestDeliveryArchitectureSetting(abTestUid);
  const isCloudflareSetting = setting && setting.is_cloudflare;
  if (articleList === null || isCloudflareSettingLoading) {
    return null;
  }

  // FIXME
  // eslint-disable-next-line react/display-name
  const ArticleTitlesHeader: React.FC = memo(() => {
    return (
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            px: 1.4,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              fontSize: "12px",
            }}
          >
            {t("Version")}
          </Typography>
          <Typography
            sx={{
              color: "gray",
              fontSize: "12px",
              textAlign: "right",
            }}
          >
            {t("配信割合")}
          </Typography>
        </Box>
      </Box>
    );
  });

  // FIXME
  // eslint-disable-next-line react/display-name
  const ArticlesHeader: React.FC = memo(() => {
    const { t } = useTranslation();
    const carrierTitleStyle = {
      color: "primary.contrastText",
      fontSize: "12px",
      fontWeight: "600",
    };

    return (
      <Box
        sx={{
          flex: 1,
          pb: 1.4,
          pr: 2,
          bgcolor: "background.primary",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <Box
          sx={{
            color: "primary.contrastText",
            fontSize: "14px",
            fontWeight: "600px",
            pt: 2,
            pl: 2,
            pb: 1.6,
          }}
        >
          {t("キャリア別")}
        </Box>
        {!isCanUseBoostMode ? (
          <SplitTestSettingInfoForNonBoostContractor
            category={"キャリア別出し分け"}
            body={"キャリア別出し分けの利用希望"}
          />
        ) : !isCloudflareSetting ? (
          <SplitTestSettingInfoForNonBoostSetting
            abTestUid={abTestUid}
            folderUid={folderUid}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              textAlign: "center",
            }}
          >
            <Typography sx={carrierTitleStyle}>{t("docomo")}</Typography>
            <Typography sx={carrierTitleStyle}>{t("au")}</Typography>
            <Typography sx={carrierTitleStyle}>{t("SoftBank")}</Typography>
          </Box>
        )}
      </Box>
    );
  });

  const articleTitles = articleList.map((article: ArticleProps) => {
    return (
      <ArticleTitle
        key={article.id}
        title={article.memo}
        abTestRate={article.ab_test_article.rate}
      />
    );
  });

  const articles = articleList.map((article: ArticleProps) => {
    return (
      <Article
        key={article.id}
        article={article}
      />
    );
  });

  return (
    <Theme mode={"dark"}>
      <Box
        sx={{
          display: "flex",
          m: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex" }}>
            <ArticleTitlesHeader />
            <ArticlesHeader />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "230px" }}>{articleTitles}</Box>
            <Box
              sx={{
                flex: 1,
                bgcolor: "background.primary",
                minHeight: "300px",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              {isCanUseBoostMode && isCloudflareSetting && articles}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "190px" }}>
          <BeyondPageArticleOptionList
            type={"carriers"}
            abTestUid={abTestUid}
          />
        </Box>
      </Box>
    </Theme>
  );
};

export const AbTestArticleSplitTestSettingCarriersContainer: React.FC<{
  onFetchAbTest: Function;
  abTest?: AbTestProps;
  abTestUid: string;
  articleList: ArticleProps[];
  isCanUseBoostMode: boolean;
}> = ({ onFetchAbTest, abTest, abTestUid, articleList, isCanUseBoostMode }) => {
  const existsAbTest = abTest && Object.keys(abTest).length > 0;
  const saveAnimationRef = useRef<SaveAnimationHandler>(null);

  useEffect(() => {
    onFetchAbTest(abTestUid);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <LocalNavigation
        pathName={location.pathname}
        abTestUid={abTest?.uid}
        folderUid={abTest?.folder?.uid}
      />
      <Box sx={{ width: 1 }}>
        <SaveAnimation ref={saveAnimationRef} />
        {existsAbTest && (
          <>
            <Nav
              abTest={abTest}
              folder={abTest.folder}
              page={"splitTestSetting"}
              article={null}
            />
            <Carriers
              articleList={articleList}
              abTestUid={abTestUid}
              isCanUseBoostMode={isCanUseBoostMode}
              folderUid={abTest.folder?.uid}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
