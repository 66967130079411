import css from 'stylesheets/externals/index.css?inline'
import 'stylesheets/externals/index.css'

export const injectExternalStyles = (targetDocument=document) => {
  const head = targetDocument.head || targetDocument.getElementsByTagName('head')[0];
  const style = targetDocument.createElement('style');
  style.type = 'text/css';
  style.appendChild(targetDocument.createTextNode(css));
  targetDocument.head.appendChild(style);
};
