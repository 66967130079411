import React from "react";

type Props = {
  width: string;
  height: string;
};

export const YahooIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 37 21"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-388.000000, -702.000000)"
          fill="#FF0027"
        >
          <g transform="translate(311.000000, 582.000000)">
            <path d="M109,125 L114,127 L106,137 L104,136 L109,125 Z M103,137 L106,138.5 L104.5,141 L102,140 L103,137 Z M90,132.454545 L81.4619926,121.587991 C80.2121299,121.467504 78.724799,121.271508 77,121 L77,120 L91,120 L91,121 C89.2462175,121.27607 87.7379509,121.47407 86.4752004,121.594001 L93.2195122,130.02439 L99.9795316,124.616375 C98.7431875,124.518773 97.2500103,124.313314 95.5,124 L95,122.5 L108,122.5 L107,124 C105.536268,124.301614 104.237333,124.503276 103.103195,124.604989 L95,131.461538 L95,138.199622 C96.1665323,138.364402 97.4998657,138.631194 99,139 L99,140 L86,140 L86,139 C87.5001343,138.631194 88.8334677,138.364402 90,138.199622 L90,132.454545 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
