import { isVideoUrl } from "utils";
import type { MediaTagProps } from "./type";

export const MediaTag = ({ value, attributes = {}, className = "" }: MediaTagProps) => {
  if (value.url && isVideoUrl(value.url)) {
    return (
      <video
        autoPlay={true}
        controlsList={"nodownload"}
        playsInline
        loop={true}
        muted={true}
        className={className}
        {...attributes}
      >
        <source
          src={value.url}
          type={"video/mp4"}
        />
      </video>
    );
  }

  return (
    <picture>
      {value.webp_url && (
        <source
          srcSet={value.webp_url}
          type={"image/webp"}
        />
      )}
      {value.avif_url && (
        <source
          srcSet={value.avif_url}
          type={"image/avif"}
        />
      )}
      <img
        src={value.url}
        className={className}
        {...attributes}
      />
    </picture>
  );
};
