import axios from "axios";
import { RedirectPageProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderRedirectPageResponse = {
  redirect_pages: RedirectPageProps[];
};

class FolderRedirectPageApi extends ApiBase {
  static fetchList(folderUid: string) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderRedirectPageResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/folders/${folderUid}/redirect_pages`, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }
}

export default FolderRedirectPageApi;
