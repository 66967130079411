import React from 'react';
import { useTranslation } from "react-i18next";

const TrackingLinkDescription = ({}) => {
  const { t } = useTranslation();
  return (
    <div>
      {t("ページ内CTRやCVRの計測をするために、Click・CVを計測するURLリンクを挿入する際は必ず「計測機能付きリンク」をチェックした状態で追加してください。")}
      {t("同ページ内の遷移や運営者情報など、Click・CVとして計測しないURLリンクは「計測機能付きリンク」のチェックを外してから追加してください。")}
    </div>
  )
}

export default TrackingLinkDescription;
