import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { AffiliateServiceProviderApi } from "api/affiliateServiceProviders";
import { AspProps } from "domains";
import { useLogging } from "hooks/logging";

export const aspListState = atom<AspProps[]>({
  key: "aspListState",
  default: [],
});

export const useAspList = () => {
  const { sendErrorLog } = useLogging();
  const [list, setList] = useRecoilState(aspListState);

  useEffect(() => {
    AffiliateServiceProviderApi.fetchAffiliateServiceProviders()
      .then((data) => {
        setList(data.sort((a, b) => (a.name > b.name ? 1 : -1)));
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch affiliation service provider",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};
