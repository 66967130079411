export const isFindTargetParentNodeByClassName = (currentNode, targetClass) => {
  let isFind = false;
  if (currentNode.classList.contains(targetClass)) return true;

  while (currentNode.parentNode){
    if (currentNode.classList.contains(targetClass)) {
      isFind = true;
      break;
    }
    currentNode = currentNode.parentNode;
  }
  return isFind;
};

export const isChildNodeByClassName = (currentNode, targetClass) => {
  let isFind = false;
  if (currentNode.classList.contains(targetClass)) {
    isFind = true;
  }
  while (currentNode.firstElementChild){
    if (currentNode.firstElementChild.classList.contains(targetClass)) {
      isFind = true;
      break;
    }
    if (currentNode.classList.contains(targetClass)) {
      isFind = true;
      break;
    }
    currentNode = currentNode.firstElementChild;
  }
  return isFind;
};

export const isChildNodeHaveTargetStyle = (currentNode, targetProperty, targetStyle) => {
  let isFind = false;

  while (currentNode.firstElementChild){
    let targetPropertyValue = window.getComputedStyle(currentNode).getPropertyValue(targetProperty);
    if (targetPropertyValue === targetStyle) {
      isFind = true;
      break;
    }
    currentNode = currentNode.firstElementChild;
  }
  return isFind;
};

export const targetParentNodeByTagName = (currentNode, targetSelecor) => {
  if (currentNode.tagName === targetSelecor) return currentNode;
  let targetNode = '';

  while (currentNode.parentNode){
    if (currentNode.tagName === targetSelecor) {
      targetNode = currentNode;
      break;
    }
    currentNode = currentNode.parentNode;
  }
  return targetNode;
};
