import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const TabletIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 53 42"
      version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-280.000000, -365.000000)"
          fill={iconColor}
        >
          <g transform="translate(250.000000, 80.000000)">
            <g transform="translate(20.000000, 120.000000)">
              <g transform="translate(10.296512, 39.000000)">
                <g transform="translate(0.000000, 110.000000)">
                  <g transform="translate(0.000000, 15.889087)">
                    <path d="M49,0.110912703 C50.6568542,0.110912703 52,1.45405845 52,3.1109127 L52,39.1109127 C52,40.767767 50.6568542,42.1109127 49,42.1109127 L3,42.1109127 C1.34314575,42.1109127 2.02906125e-16,40.767767 0,39.1109127 L0,3.1109127 C-2.02906125e-16,1.45405845 1.34314575,0.110912703 3,0.110912703 L49,0.110912703 Z M45,3.1109127 L4,3.1109127 C3.44771525,3.1109127 3,3.55862795 3,4.1109127 L3,4.1109127 L3,38.1109127 C3,38.6631975 3.44771525,39.1109127 4,39.1109127 L4,39.1109127 L45,39.1109127 C45.5522847,39.1109127 46,38.6631975 46,38.1109127 L46,38.1109127 L46,4.1109127 C46,3.55862795 45.5522847,3.1109127 45,3.1109127 L45,3.1109127 Z M49,22.1109127 C49.5522847,22.1109127 50,21.6631975 50,21.1109127 C50,20.558628 49.5522847,20.1109127 49,20.1109127 C48.4477153,20.1109127 48,20.558628 48,21.1109127 C48,21.6631975 48.4477153,22.1109127 49,22.1109127 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
