import React, { useEffect, useMemo, useState, useRef, useCallback, MutableRefObject } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowHeadDownIcon,
  BeyondPageAdStatus,
  BeyondPageEditorIcon,
  BeyondPageMediaPostbackSetting,
  BeyondPageMove,
  ConnectionIcon,
  FolderIcon,
  FunnelIcon,
  MediaIcon,
  OptionsIcon,
  PopupIcon,
  RadarIcon,
  RedirectPageIcon,
  VersionsIcon,
} from "components";
import {
  AbTestProps,
  AbTestReportMaxCountProps,
  AbTestReportMinCountProps,
  AbTestReportProps,
  ArticleProps,
  ConversionTagAbTestProps,
  ConversionTagProps,
  ExitPopupProps,
  FolderGroupProps,
  FolderProps,
  MediaProps,
  RedirectPageProps,
  TeamProps,
} from "domains";
import {
  folderGroupListState,
  folderListState,
  HandleDeleteAbTestProps,
  HandleDuplicateAbTestProps,
  HandleFetchFolderGroupListProps,
  HandleFetchFolderListProps,
  HandleMoveAbTestProps,
  useAbTestDailyReportList,
  useDeleteAbTest,
  useDuplicateAbTest,
  useEditAbTest,
  useFetchFolderGroupList,
  useFetchFolderList,
  useFolderAbTestList,
  useFolderArticleList,
  useFolderConversionTagList,
  useFolderExitPopupList,
  useFolderRedirectPageList,
  useLocalStorage,
  useMember,
  useMoveAbTest,
  useUserTeamList,
} from "hooks";
import i18next from "i18next";
import {
  filterAbTestListByAdStatus,
  filterAbTestListByQuery,
  filterAbTestReportList,
  isColumnLargeNumberWorse,
  pickMaxCountFromAbTestReportList,
  pickMinCountFromAbTestReportList,
  sortAbTestList,
  sumAbTestReportCount,
} from "utils";

const columns: Array<{ label: string; value: string; type: string }> = [
  { label: i18next.t("ステータス"), value: "ad_status", type: "" },
  { label: `${i18next.t("配信金額")}(${i18next.t("円")})`, value: "adSpending", type: "count" },
  { label: `${i18next.t("売上")}(${i18next.t("円")})`, value: "sales", type: "count" },
  { label: "PV", value: "pv", type: "count" },
  { label: "CLICK", value: "click", type: "count" },
  { label: "CTR(%)", value: "ctr", type: "count" },
  { label: "CV", value: "cv", type: "count" },
  { label: "CVR(%)", value: "cvr", type: "count" },
  { label: "CTVR(%)", value: "ctvr", type: "count" },
  { label: `CPA(${i18next.t("円")})`, value: "cpa", type: "count" },
  { label: `MCPA(${i18next.t("円")})`, value: "mcpa", type: "count" },
];

const assignCount = (list: AbTestProps[], abTestReportList: AbTestReportProps[]): AbTestProps[] => {
  const newList = list.map((abTest: AbTestProps) => {
    const report = abTestReportList.find(
      (report: AbTestReportProps) => report.ab_test_id === abTest.id,
    );
    return { ...abTest, count: report };
  });
  return newList;
};

const ConversionTagContent: React.FC<{
  abTest: AbTestProps;
  conversionTagList: ConversionTagProps[];
}> = ({ abTest, conversionTagList }) => {
  const { t } = useTranslation();
  const conversionTag = conversionTagList.find((conversionTag) => {
    const d = conversionTag.conversion_tag_ab_tests.find(
      (d: ConversionTagAbTestProps) => d.ab_test_id === abTest.id,
    );
    return d != undefined;
  });

  if (conversionTag) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <RadarIcon
          color={"common"}
          width={"10px"}
          height={"12px"}
        />
        <Typography sx={{ fontSize: "10px", ml: 0.5 }}>{conversionTag.name}</Typography>
      </Box>
    );
  }

  // NOTE: ASP連携していれば、squadbeyondのCVタグを使わなくても、CV計測が出来る
  if (abTest.count != undefined && abTest.count.conversion_count > 0) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <RadarIcon
          color={"common"}
          width={"10px"}
          height={"12px"}
        />
        <Typography sx={{ fontSize: "10px", ml: 0.5 }}>{t("CV計測済み")}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <RadarIcon
        color={"common"}
        width={"10px"}
        height={"12px"}
      />
      <Typography sx={{ fontSize: "10px", ml: 0.5 }}>{t("CVタグ未設定")}</Typography>
    </Box>
  );
};

const CopyBeyondPageUrl: React.FC<{
  folder: FolderProps;
  abTest: AbTestProps;
}> = ({ folder, abTest }) => {
  const { t } = useTranslation();
  const defaultTitle: string = t("コピーする");
  const [title, setTitle] = useState<string>(defaultTitle);

  const url = folder.domain?.domain ? `https://${folder.domain.domain}/ab/${abTest.uid}` : "";

  const handleCopy = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setTitle(t("コピーしました") || "");
      })
      .catch(() => {
        setTitle(t("コピーに失敗しました") || "");
        throw new Error("Failed to copy Beyond Page URL");
      });
  };

  const handleClickAnchor = () => {
    window.open(url, "_blank");
  };

  return (
    <Box sx={{ px: 1.5, py: 1, width: "100%" }}>
      <Typography sx={{ fontSize: "13px", color: "grey.500", fontWeight: "bold" }}>
        {t("配信URL")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "13px", color: "primary.main" }}>{url}</Typography>
        <Tooltip
          arrow
          title={title}
          placement={"left"}
          onMouseEnter={() => setTitle(defaultTitle)}
        >
          <IconButton
            size="small"
            onClick={handleCopy}
          >
            <FileCopyIcon color="primary" />
          </IconButton>
        </Tooltip>
        <IconButton
          size="small"
          onClick={handleClickAnchor}
        >
          <LaunchIcon color="primary" />
        </IconButton>
      </Box>
    </Box>
  );
};

type DuplicateFormValues = {
  base?: string;
  title?: string;
  uid: string;
  mediaId: string | number | undefined;
  linkType: string;
  archived: boolean;
  deliveryType: string;
  isInheritHtmlTag: boolean;
  isInheritRedirectPage: boolean;
  isInheritExitPopup: boolean;
  isInheritFunnelStep: boolean;
  folderId?: number;
};

const DuplicateBeyondPage: React.FC<{
  isMove: boolean;
  abTest: AbTestProps;
  mediaList: MediaProps[];
  userTeamList?: TeamProps[];
  duplicateAbTest: HandleDuplicateAbTestProps;
  duplicateAbTestLoading: boolean;
  duplicateAbTestError: Error | undefined;
  handleMenuClose: VoidFunction;
  fetchFolderList?: HandleFetchFolderListProps;
  fetchFolderGroupList?: HandleFetchFolderGroupListProps;
}> = ({
  isMove,
  abTest,
  mediaList,
  userTeamList,
  duplicateAbTest,
  duplicateAbTestLoading,
  duplicateAbTestError,
  handleMenuClose,
  fetchFolderList,
  fetchFolderGroupList,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [folderList, setFolderList] = useRecoilState(folderListState);
  const [folderGroupList, setFolderGroupList] = useRecoilState(folderGroupListState);
  const { t } = useTranslation();

  const { register, handleSubmit, errors, setError, setValue, watch } =
    useForm<DuplicateFormValues>({
      defaultValues: {
        title: "",
        uid: "",

        mediaId: abTest.media!.attributes.id,
        linkType: "leave_links",
        archived: false,
        deliveryType: "html_rewriting",
        isInheritHtmlTag: true,
        isInheritRedirectPage: true,
        isInheritExitPopup: true,
        isInheritFunnelStep: true,
        folderId: undefined,
      },
    });

  const onSubmit = (data: DuplicateFormValues) => {
    if (isMove) {
      if (
        !window.confirm(
          t(
            "フォルダを移動する場合、現在利用している下記の設定が無効になります。\r\n改めて設定・設置を行ってください。\r\n設定対象: ドメイン・CVタグ・フォーム",
          ) || "",
        )
      ) {
        return;
      }
    }
    setIsSubmitted(false);
    const params = {
      ab_test: {
        title: data.title,
        uid: data.uid,
        media_id: data.mediaId,
        link_type: data.linkType,
        archived: data.archived,
        delivery_type: data.deliveryType,
        is_inherit_html_tag: data.isInheritHtmlTag,
        is_inherit_redirect_page: data.isInheritRedirectPage,
        is_inherit_exit_popup: data.isInheritExitPopup,
        is_inherit_funnel_step: data.isInheritFunnelStep,
        folder_id: data.folderId,
      },
    };
    // NOTE: nullだとxxxの複製という名前が自動で付与される
    if (params.ab_test.title === "") {
      delete params.ab_test.title;
    }
    duplicateAbTest(abTest.uid, params);
    setIsSubmitted(true);
  };

  useEffect(() => {
    register("title", {
      maxLength: {
        value: 50,
        message: t("文字まで入力できます", { value: 50 }) || "",
      },
    });
    register("uid");
    register("mediaId");
    register("linkType");
    register("archived");
    register("deliveryType");
    register("isInheritHtmlTag");
    register("isInheritRedirectPage");
    register("isInheritExitPopup");
    register("isInheritFunnelStep");
    if (isMove) {
      register("folderId", {
        required: t("を選択してください", { value: t("フォルダ") }) || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (duplicateAbTestError) {
      setError("base", "invalid", duplicateAbTestError.message);
    } else if (isSubmitted && !duplicateAbTestLoading && !duplicateAbTestError) {
      handleClose();
      handleMenuClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateAbTestError, duplicateAbTestLoading, isSubmitted]);

  const resetSelections = () => {
    setFolderList([]);
    setFolderGroupList([]);
  };

  const selectTeam = (teamId: number | string) => {
    if (!isMove || !fetchFolderList || !fetchFolderGroupList) return;
    resetSelections();
    fetchFolderList({ teamId: +teamId, folderScope: "include_outside" });
    fetchFolderGroupList(teamId, "include_outside");
  };

  const selectFolderGroup = (folderGroupId: number | string | undefined) => {
    if (folderGroupId === undefined) {
      return;
    }

    const folderGroup = folderGroupList.find(
      (folderGroup: FolderGroupProps) => folderGroupId === folderGroup.id,
    );
    if (folderGroup != undefined && folderGroup?.folders != undefined) {
      setFolderList(folderGroup.folders);
    }
  };

  const folderGroupOptionList = folderGroupList.map((folderGroup: FolderGroupProps) => {
    return { label: folderGroup.name, id: folderGroup.id };
  });

  const folderOptionList = folderList.map((folder: FolderProps) => {
    return { label: folder.name, id: folder.id };
  });

  useEffect(() => {
    resetSelections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (abTest.editor_version === 4) {
    return null; // NOTE: beyondでLPを作成している場合は複製できない
  }

  return (
    <>
      <Link
        onClick={handleOpen}
        underline="none"
        sx={{ px: 1.5, py: 1, width: "100%" }}
      >
        {isMove ? t("別フォルダへ複製") : t("beyondページ複製")}
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        PaperProps={{
          sx: { backgroundColor: "background.primary" },
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                {t("閉じる")}
              </Button>
            </Box>
            <Box>{isMove ? t("別フォルダへ複製") : t("beyondページ複製")}</Box>
            <Box>
              {duplicateAbTestLoading ? (
                <CircularProgress
                  color="primary"
                  size={30}
                />
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                >
                  {t("複製する")}
                </Button>
              )}
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontSize: "14px", mb: 2 }}>
              {t("を複製します", { value: `『${abTest.title}』` })}
            </Typography>
            <FormControl
              fullWidth
              sx={{ mb: 2 }}
            >
              <TextField
                type="text"
                label={t("beyondページ名")}
                variant="filled"
                error={!!errors.title}
                helperText={errors.title?.message}
                defaultValue={watch("title")}
                onChange={(e) => setValue("title", e.target.value)}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                }}
                size="small"
              />
            </FormControl>

            {isMove && (
              <>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 1,
                    color: "grey.500",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {t("複製先フォルダ")}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "background.secondary",
                    p: 2,
                    mb: 2,
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: "background.primary",
                      },
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel>{t("チーム")}</InputLabel>
                      {userTeamList && (
                        <Select
                          label={t("チーム")}
                          variant="filled"
                          disableUnderline
                          onChange={(e: SelectChangeEvent) => selectTeam(e.target.value)}
                        >
                          {userTeamList.map((team: TeamProps) => (
                            <MenuItem
                              key={team.id}
                              value={team.id}
                            >
                              {team.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                    <Autocomplete
                      options={folderGroupOptionList}
                      renderInput={(params) => (
                        <TextField
                          {...{
                            ...params,
                            InputProps: {
                              ...params.InputProps,
                              ...{ disableUnderline: true },
                            },
                          }}
                          label={t("フォルダグループ")}
                          variant="filled"
                        />
                      )}
                      onChange={(event, value) => selectFolderGroup(value?.id)}
                      fullWidth
                      sx={{ ml: 0.5 }}
                    />
                    <Autocomplete
                      options={folderOptionList}
                      renderInput={(params) => (
                        <TextField
                          {...{
                            ...params,
                            InputProps: {
                              ...params.InputProps,
                              ...{ disableUnderline: true },
                            },
                          }}
                          label={t("フォルダ")}
                          variant="filled"
                        />
                      )}
                      onChange={(event, value) => setValue("folderId", value?.id)}
                      fullWidth
                      sx={{ ml: 0.5 }}
                    />
                  </Box>
                </Box>
              </>
            )}

            <FormControl
              fullWidth
              sx={{ mb: 2 }}
            >
              <TextField
                type="text"
                label={t("配信URL設定 ※後から変更できません、未入力の場合は自動で作成されます")}
                placeholder={t("半角英数字,-,_が使えます") || ""}
                variant="filled"
                error={!!errors.uid}
                helperText={errors.uid?.message}
                onChange={(e) => setValue("uid", e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{t("フォルダードメイン")}/ab/</InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                fullWidth
                size="small"
              />
            </FormControl>

            <Typography
              variant="subtitle1"
              sx={{
                mb: 1,
                color: "grey.500",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("詳細設定")}
            </Typography>
            <Box
              sx={{
                p: 2,
                display: "flex",
                borderRadius: 2,
                backgroundColor: "background.secondary",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  mr: 0.5,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "background.primary",
                    },
                  }}
                >
                  <FormControl
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    <InputLabel>{t("リンク設定")}</InputLabel>
                    <Select
                      label="リンク設定"
                      defaultValue={watch("linkType")}
                      onChange={(e) => setValue("linkType", e.target.value)}
                      size="small"
                      variant="filled"
                      disableUnderline
                    >
                      <MenuItem value="leave_links">{t("を残す", { value: t("リンク") })}</MenuItem>
                      <MenuItem value="remove_links">
                        {t("を削除", { value: t("リンク") })}
                      </MenuItem>
                      <MenuItem value="remove_tracking_links">
                        {t("トラッキングリンクだけ削除")}
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    fullWidth
                    sx={{ mb: 2 }}
                  >
                    <InputLabel>{t("媒体")}</InputLabel>
                    <Select
                      label={t("媒体")}
                      defaultValue={watch("mediaId")}
                      onChange={(e) => setValue("mediaId", e.target.value)}
                      size="small"
                      variant="filled"
                      disableUnderline
                    >
                      {mediaList.map((media: MediaProps) => (
                        <MenuItem
                          key={media.id}
                          value={media.id}
                        >
                          {media.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel>{t("配信タイプ")}</InputLabel>
                    <Select
                      label={t("配信タイプ")}
                      defaultValue={watch("deliveryType")}
                      onChange={(e) => setValue("deliveryType", e.target.value)}
                      size="small"
                      variant="filled"
                      disableUnderline
                    >
                      <MenuItem value={"html_rewriting"}>{t("同一URL配信")}</MenuItem>
                      <MenuItem value={"redirection"}>{t("リダイレクトURL配信")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ width: "50%", ml: 0.5 }}>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 1,
                    color: "grey.500",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {t("引き継ぎ")}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: "background.primary",
                    borderRadius: 2,
                    p: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2">{t("Versionのheadとbodyタグ")}</Typography>
                    <Switch
                      color="primary"
                      checked={watch("isInheritHtmlTag")}
                      onChange={(e) => setValue("isInheritHtmlTag", e.target.checked)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2">{t("中間ページ")}</Typography>
                    <Switch
                      color="primary"
                      checked={watch("isInheritRedirectPage")}
                      onChange={(e) => setValue("isInheritRedirectPage", e.target.checked)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2">{t("ポップアップ")}</Typography>
                    <Switch
                      color="primary"
                      checked={watch("isInheritExitPopup")}
                      onChange={(e) => setValue("isInheritExitPopup", e.target.checked)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2">{t("ステップ")}</Typography>
                    <Switch
                      color="primary"
                      checked={watch("isInheritFunnelStep")}
                      onChange={(e) => setValue("isInheritFunnelStep", e.target.checked)}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="body2">{t("アーカイブ済みVersion")}</Typography>
                    <Switch
                      color="primary"
                      checked={watch("archived")}
                      onChange={(e) => setValue("archived", e.target.checked)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {errors.base &&
              Array.isArray(errors.base.message) &&
              errors.base.message.map((message, index) => (
                <Box
                  key={index}
                  sx={{ pt: 1 }}
                >
                  <Typography
                    variant="subtitle2"
                    color="error"
                  >
                    {message}
                  </Typography>
                </Box>
              ))}
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

const DeleteBeyondPage: React.FC<{
  abTest: AbTestProps;
  editAbTestHandle: Function;
  deleteAbTest: HandleDeleteAbTestProps;
  deleteAbTestLoading: boolean;
}> = ({ abTest, editAbTestHandle, deleteAbTest, deleteAbTestLoading }) => {
  const { t } = useTranslation();
  if (abTest.ad_status === "finished") {
    const handle = () => {
      deleteAbTest(abTest.uid);
    };

    if (deleteAbTestLoading) {
      return (
        <CircularProgress
          color="error"
          size={30}
        />
      );
    }
    return (
      <Link
        sx={{ fontSize: "14px", px: 1.5, py: 1, width: "100%" }}
        underline="none"
        color="error"
        onClick={handle}
      >
        {t("削除")}
      </Link>
    );
  }

  const handle = async () => {
    const params = {
      ab_test: {
        ad_status: "finished",
      },
    };
    await editAbTestHandle(abTest.uid, params);
  };

  return (
    <Link
      sx={{ fontSize: "14px", px: 1.5, py: 1, width: "100%" }}
      underline="none"
      color="error"
      onClick={handle}
    >
      {t("ステータスを終了にする")}
    </Link>
  );
};

const FirstCell: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <TableCell
      sx={{
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
        boxSizing: "border-box",
        zIndex: 9,
      }}
    >
      <Box sx={{ width: "300px", overflow: "hidden" }}>{children}</Box>
    </TableCell>
  );
};

const LastCell: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <TableCell
      sx={{
        position: "sticky",
        right: 0,
        backgroundColor: "#fff",
        boxSizing: "border-box",
        zIndex: 9,
      }}
    >
      {children}
    </TableCell>
  );
};

// TODO: AbTestProps = { ..., onEdit: Function, onMove: Function }のようにまとめる
type RowProps = {
  folder: FolderProps;
  abTest: AbTestProps;
  abTestList: AbTestProps[];
  mediaList: MediaProps[];
  userTeamList: TeamProps[];
  conversionTagList: ConversionTagProps[];
  folderExitPopupList: ExitPopupProps[];
  folderArticleList: ArticleProps[];
  folderRedirectPageList: RedirectPageProps[];
  reportStartDate: string;
  reportEndDate: string;
  abTestReportMaxCount: AbTestReportMaxCountProps;
  abTestReportMinCount: AbTestReportMinCountProps;
  editAbTestHandle: Function;
  fetchFolderList: HandleFetchFolderListProps;
  fetchFolderGroupList: HandleFetchFolderGroupListProps;
  moveAbTest: HandleMoveAbTestProps;
  duplicateAbTest: HandleDuplicateAbTestProps;
  deleteAbTest: HandleDeleteAbTestProps;
  updateSelectedAbTestList: MutableRefObject<(abTest: AbTestProps) => void>;
  resetSelectedAbTestList: VoidFunction;
  deleteAbTestLoading: boolean;
  duplicateAbTestLoading: boolean;
  moveAbTestLoading: boolean;
  duplicateAbTestError: Error | undefined;
  moveAbTestError: Error | undefined;
  isSelected: boolean;
};

const RowInner: React.FC<RowProps> = ({
  folder,
  abTest,
  abTestList,
  mediaList,
  userTeamList,
  conversionTagList,
  folderExitPopupList,
  folderArticleList,
  folderRedirectPageList,
  abTestReportMaxCount,
  abTestReportMinCount,
  reportStartDate,
  reportEndDate,
  editAbTestHandle,
  fetchFolderList,
  fetchFolderGroupList,
  moveAbTest,
  duplicateAbTest,
  deleteAbTest,
  updateSelectedAbTestList,
  resetSelectedAbTestList,
  deleteAbTestLoading,
  duplicateAbTestLoading,
  moveAbTestLoading,
  duplicateAbTestError,
  moveAbTestError,
  isSelected,
}) => {
  const { t } = useTranslation();
  const memberState = useMember();
  const isBygones = !!memberState.memberAttributes.isBygones;
  const [isHover, setIsHover] = useState(false);
  const abTestCount = abTestList.length;
  const exitPopupCount = folderExitPopupList.filter(
    (popup: ExitPopupProps) => popup.ab_test_id === abTest.id,
  ).length;
  const articleList = folderArticleList.filter(
    (article: ArticleProps) => article.ab_test.id === abTest.id,
  );
  const articleCount = articleList.filter(
    (article: ArticleProps) => !article.archived && article.funnel_step === null,
  ).length;
  const stepCount = articleList.reduce((r: number, article: ArticleProps) => {
    if (article.funnel_step != null) {
      r += 1;
    }
    return r;
  }, 0);
  const redirectPageCount = folderRedirectPageList.filter(
    (redirectPage: RedirectPageProps) => redirectPage.ab_test_id === abTest.id,
  ).length;

  const countList = columns
    .filter((column) => column.type === "count")
    .map((column) => {
      const minCount = abTestReportMinCount[column.value];
      const maxCount = abTestReportMaxCount[column.value];

      const count = abTest.editor_version === 4 ? "-" : abTest.count?.[column.value] || 0;

      const isMin = minCount === count && count != 0 && abTestCount > 1;
      const isMax = maxCount === count && count != 0 && abTestCount > 1;
      if (isMin) {
        return (
          <TableCell
            key={column.value}
            align="center"
          >
            <Chip
              label={count.toLocaleString("en-US")}
              color={!isColumnLargeNumberWorse(column.value) ? "error" : "success"}
              size="small"
              sx={{
                backgroundColor: !isColumnLargeNumberWorse(column.value)
                  ? "error.light"
                  : "success.light",
                fontSize: "12px",
              }}
            />
          </TableCell>
        );
      }

      if (isMax) {
        return (
          <TableCell
            key={column.value}
            align="center"
          >
            <Chip
              label={count.toLocaleString("en-US")}
              color={isColumnLargeNumberWorse(column.value) ? "error" : "success"}
              size="small"
              sx={{
                backgroundColor: isColumnLargeNumberWorse(column.value)
                  ? "error.light"
                  : "success.light",
                fontSize: "12px",
              }}
            />
          </TableCell>
        );
      }

      return (
        <TableCell
          key={column.value}
          sx={{ fontSize: "12px" }}
          align="center"
        >
          {count.toLocaleString("en-US")}
        </TableCell>
      );
    });

  const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorMenuEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  const [openMedia, setOpenMedia] = useState(false);

  // X は Bygones のみで表示だがダイアログ表示ボタンとの兼ね合いがあるので、ここで制御する。
  const isShowXConnectionButton = isBygones && abTest.media_id === 3;
  const isShowConnectionButton =
    isShowXConnectionButton ||
    [1, 2, 4, 6, 8, 9, 11, 12, 13, 19, 21, 25, 36, 39, 47, 48, 51, 53].some(
      (x) => x === abTest.media_id,
    );

  const isBeyondBoost = abTest.editor_version === 4;

  return (
    <TableRow>
      <FirstCell>
        <Box
          sx={{ display: "flex" }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Box>
            <Box sx={{ display: "flex", mb: 0.5 }}>
              <Checkbox
                size="small"
                sx={{ p: 0, mr: 0.5 }}
                onChange={() => updateSelectedAbTestList.current(abTest)}
                checked={isSelected}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  wordBreak: "break-all",
                }}
              >
                {abTest.title}
              </Typography>
            </Box>
            <Box
              sx={{
                height: "20px",
                display: "flex",
                alignItems: "center",
                mb: 0.5,
              }}
            >
              {isBeyondBoost ? (
                <Link
                  sx={{ fontSize: "12px" }}
                  underline="none"
                  href={`/ab_tests/${abTest.uid}/articles`}
                >
                  {t("Version")}
                </Link>
              ) : isHover ? (
                <>
                  <Link
                    sx={{ fontSize: "12px", mr: 1 }}
                    underline="none"
                    href={`/ab_tests/${abTest.uid}/articles/htmls/heatmaps/comparisons`}
                  >
                    {t("ヒートマップ")}
                  </Link>
                  <Link
                    sx={{ fontSize: "12px", mr: 1 }}
                    underline="none"
                    href={`/ab_tests/${abTest.uid}/reports?start_date=${reportStartDate}&end_date=${reportEndDate}`}
                  >
                    {t("レポート")}
                  </Link>
                  <Link
                    sx={{ fontSize: "12px" }}
                    underline="none"
                    href={`/ab_tests/${abTest.uid}/articles`}
                  >
                    {t("Version")}
                  </Link>
                </>
              ) : (
                <>
                  <Box sx={{ display: "flex", mr: 1, alignItems: "center" }}>
                    <Box sx={{ mr: 0.5 }}>
                      <MediaIcon
                        mediaId={abTest.media_id}
                        width={"10px"}
                        height={"10px"}
                      />
                    </Box>
                    <Typography sx={{ fontSize: "11px" }}>
                      {abTest.media?.attributes.name ?? ""}{" "}
                    </Typography>
                  </Box>
                  <Box>
                    <ConversionTagContent
                      abTest={abTest}
                      conversionTagList={conversionTagList}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {isBeyondBoost ? (
                <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                  <BeyondPageEditorIcon
                    color={"warning"}
                    width="13px"
                    height="13px"
                  />
                  <Typography sx={{ fontSize: "12px", ml: 0.5 }}>beyondでLP</Typography>
                </Box>
              ) : (
                <>
                  <Tooltip
                    title={t("アーカイブ以外のVersion数")}
                    arrow
                  >
                    <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                      <VersionsIcon
                        color={articleCount > 0 ? "warning" : "grey"}
                        width="13px"
                        height="16px"
                      />
                      <Typography sx={{ fontSize: "12px", ml: 0.5 }}>{articleCount}</Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={t("ファネルのステップ数")}
                    arrow
                  >
                    <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                      <FunnelIcon
                        color={stepCount > 0 ? "warning" : "grey"}
                        width="13px"
                        height="14px"
                      />
                      <Typography sx={{ fontSize: "12px", ml: 0.5 }}>{stepCount}</Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={t("ポップアップ数")}
                    arrow
                  >
                    <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                      <PopupIcon
                        color={exitPopupCount > 0 ? "warning" : "grey"}
                        width="15px"
                        height="9px"
                      />
                      <Typography sx={{ fontSize: "12px", ml: 0.5 }}>{exitPopupCount}</Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={
                      abTest.editor_version === 3 ? t("HTMLエディター") : t("beyondエディター")
                    }
                    arrow
                  >
                    <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                      <BeyondPageEditorIcon
                        color={"warning"}
                        width="13px"
                        height="13px"
                      />
                      <Typography sx={{ fontSize: "12px", ml: 0.5 }}>
                        {abTest.editor_version === 3 ? "H" : "b"}
                      </Typography>
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={t("中間ページ数")}
                    arrow
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <RedirectPageIcon
                        color={redirectPageCount > 0 ? "warning" : "grey"}
                        width="19px"
                        height="14px"
                      />
                      <Typography sx={{ fontSize: "12px", ml: 0.5 }}>
                        {redirectPageCount}
                      </Typography>
                    </Box>
                  </Tooltip>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </FirstCell>
      <TableCell align="center">
        <BeyondPageAdStatus
          abTest={abTest}
          editAbTestHandle={editAbTestHandle}
        />
      </TableCell>
      {/* {!isBeyondBoost && countList} */}
      {countList}
      <LastCell>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={handleMenuClick}
              sx={{
                width: "30px",
                height: "30px",
                mr: 1,
                backgroundColor: "background.default",
              }}
            >
              <OptionsIcon
                color={"common"}
                width={"14px"}
                height={"11px"}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", minWidth: "40px" }}>
            {isShowConnectionButton && (
              <IconButton
                onClick={() => setOpenMedia(true)}
                sx={{
                  width: "30px",
                  height: "30px",
                  mr: 1,
                  backgroundColor: "background.default",
                }}
              >
                <ConnectionIcon
                  color={"common"}
                  width={"14px"}
                  height={"14px"}
                />
              </IconButton>
            )}
          </Box>
        </Box>

        <Dialog
          open={openMedia}
          onClose={() => setOpenMedia(false)}
          fullWidth={true}
          maxWidth={"sm"}
          PaperProps={{
            sx: { backgroundColor: "background.primary" },
          }}
        >
          <DialogTitle>{t("CV/MCV連携")}</DialogTitle>
          <DialogContent>
            <BeyondPageMediaPostbackSetting
              abTest={abTest}
              handleDialogClose={() => setOpenMedia(false)}
            />
          </DialogContent>
        </Dialog>
        <Menu
          anchorEl={anchorMenuEl}
          open={open}
          onClose={handleMenuClose}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          MenuListProps={{
            sx: { backgroundColor: "background.primary" },
          }}
        >
          <MenuItem
            sx={{ fontSize: "14px", p: 0 }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <Link
              href={`folders/${folder.uid}/ab_tests/${abTest.uid}/edit`}
              underline="none"
              sx={{ px: 1.5, py: 1, width: "100%" }}
            >
              {t("を編集する", { value: t("基本情報") })}
            </Link>
          </MenuItem>
          {folder.domain != null && (
            <MenuItem sx={{ fontSize: "14px", p: 0 }}>
              <CopyBeyondPageUrl
                folder={folder}
                abTest={abTest}
              />
            </MenuItem>
          )}
          {abTest.editor_version !== 4 && (
            <MenuItem sx={{ fontSize: "14px", p: 0, width: "100%", display: "block" }}>
              <BeyondPageMove
                resetSelectedAbTestList={resetSelectedAbTestList}
                targetAbTestList={[abTest]}
                userTeamList={userTeamList}
                fetchFolderList={fetchFolderList}
                fetchFolderGroupList={fetchFolderGroupList}
                moveAbTest={moveAbTest}
                moveAbTestLoading={moveAbTestLoading}
                moveAbTestError={moveAbTestError}
              >
                <Link
                  underline="none"
                  sx={{ px: 1.5, py: 1, width: "100%", display: "block" }}
                >
                  {t("フォルダ移動")}
                </Link>
              </BeyondPageMove>
            </MenuItem>
          )}
          <MenuItem
            sx={{ fontSize: "14px", p: 0 }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <DuplicateBeyondPage
              isMove={true}
              abTest={abTest}
              mediaList={mediaList}
              userTeamList={userTeamList}
              duplicateAbTest={duplicateAbTest}
              duplicateAbTestLoading={duplicateAbTestLoading}
              duplicateAbTestError={duplicateAbTestError}
              handleMenuClose={handleMenuClose}
              fetchFolderList={fetchFolderList}
              fetchFolderGroupList={fetchFolderGroupList}
            />
          </MenuItem>
          <MenuItem
            sx={{ fontSize: "14px", p: 0 }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <DuplicateBeyondPage
              isMove={false}
              abTest={abTest}
              mediaList={mediaList}
              duplicateAbTest={duplicateAbTest}
              duplicateAbTestLoading={duplicateAbTestLoading}
              duplicateAbTestError={duplicateAbTestError}
              handleMenuClose={handleMenuClose}
            />
          </MenuItem>
          <MenuItem sx={{ fontSize: "14px", p: 0 }}>
            <DeleteBeyondPage
              abTest={abTest}
              editAbTestHandle={editAbTestHandle}
              deleteAbTest={deleteAbTest}
              deleteAbTestLoading={deleteAbTestLoading}
            />
          </MenuItem>
        </Menu>
      </LastCell>
    </TableRow>
  );
};

const MemoizedRowInner = React.memo(RowInner);

// メモ化による render 最適化のために値の前処理を行う。
// 本体は RowInner にて定義されている。
const Row: React.FC<
  Omit<RowProps, "updateSelectedAbTestList" | "isSelected"> & {
    selectedAbTestList: AbTestProps[];
    updateSelectedAbTestList: (abTest: AbTestProps) => void;
  }
> = ({ selectedAbTestList, ...props }) => {
  // 選択状態が変わるごとに変更されるが、これは回避できないので Ref により変更比較をバイパスする。
  // (コンポーネント内で呼び出す関数の変更はされなければならないので、memo の比較関数変更によるバイパスは不可)
  const updateSelectedAbTestList = useRef(props.updateSelectedAbTestList);
  useEffect(() => {
    updateSelectedAbTestList.current = props.updateSelectedAbTestList;
  }, [props.updateSelectedAbTestList]);

  // 選択状態の判断を上層で行うことにより、選択状態の変更がない場合の再 render を回避する。
  const isSelected = selectedAbTestList.some((x) => x.id === props.abTest.id);

  return (
    <MemoizedRowInner
      {...props}
      updateSelectedAbTestList={updateSelectedAbTestList}
      isSelected={isSelected}
    />
  );
};

const BulkMenuList: React.FC<{
  resetSelectedAbTestList: VoidFunction;
  selectedAbTestList: AbTestProps[];
  userTeamList: TeamProps[];
  fetchFolderList: HandleFetchFolderListProps;
  fetchFolderGroupList: HandleFetchFolderGroupListProps;
  moveAbTest: HandleMoveAbTestProps;
  moveAbTestLoading: boolean;
  moveAbTestError: Error | undefined;
}> = ({
  resetSelectedAbTestList,
  selectedAbTestList,
  userTeamList,
  fetchFolderList,
  fetchFolderGroupList,
  moveAbTest,
  moveAbTestLoading,
  moveAbTestError,
}) => {
  const { t } = useTranslation();
  const count = selectedAbTestList.length;

  if (count === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "60px",
        width: "100%",
        zIndex: 99,
        color: "info.contrastText",
        backgroundColor: "info.light",
        p: 2,
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontSize: "14px", fontWeight: "bold", mr: 2 }}>
        {count}
        {t("件選択")}
      </Typography>
      <BeyondPageMove
        resetSelectedAbTestList={resetSelectedAbTestList}
        targetAbTestList={selectedAbTestList}
        userTeamList={userTeamList}
        fetchFolderList={fetchFolderList}
        fetchFolderGroupList={fetchFolderGroupList}
        moveAbTestLoading={moveAbTestLoading}
        moveAbTestError={moveAbTestError}
        moveAbTest={moveAbTest}
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
        >
          {t("一括フォルダ移動")}
        </Button>
      </BeyondPageMove>
    </Box>
  );
};

const OrderArrow: React.FC<{
  order: string;
  orderBy: string;
  columnValue: string;
}> = ({ order, orderBy, columnValue }) => {
  if (orderBy != columnValue) {
    return null;
  }

  return (
    <Box sx={{ ml: 0.5, transform: order === "asc" ? "rotate(180deg)" : "none" }}>
      <ArrowHeadDownIcon
        color={"common"}
        width={"10px"}
        height={"10px"}
      />
    </Box>
  );
};

export const BeyondPageList: React.FC<{
  folder: FolderProps;
  reportStartDate: string;
  reportEndDate: string;
  query: string;
  adStatus: string;
  mediaList: MediaProps[];
  folderListRef: React.RefObject<HTMLDivElement>;
}> = ({ folder, reportStartDate, reportEndDate, query, adStatus, mediaList, folderListRef }) => {
  const [abTestReportList] = useAbTestDailyReportList(folder.uid, reportStartDate, reportEndDate);
  const [abTestList, _setAbTestList, abTestFolder, abTestListLoading] = useFolderAbTestList(folder);
  const [editAbTestHandle] = useEditAbTest();
  const [fetchFolderList] = useFetchFolderList();
  const [moveAbTest, moveAbTestLoading, moveAbTestError] = useMoveAbTest();
  const [duplicateAbTest, duplicateAbTestLoading, duplicateAbTestError] = useDuplicateAbTest();
  const [deleteAbTest, deleteAbTestLoading] = useDeleteAbTest();
  const [userTeamList] = useUserTeamList();
  const [conversionTagList] = useFolderConversionTagList(folder.uid);
  const [fetchFolderGroupList] = useFetchFolderGroupList();
  const [folderExitPopupList] = useFolderExitPopupList(folder.uid);
  const [folderArticleList] = useFolderArticleList(folder.uid);
  const [folderRedirectPageList] = useFolderRedirectPageList(folder.uid);

  const [beyondPageListOrder, setBeyondPageListOrder] = useLocalStorage<{
    name: string;
    order: string;
  }>("beyond_page_list_order", { name: "title", order: "desc" });

  useEffect(() => {
    // 「お気に入り」機能を削除するタイミングで、すでにlocalStorageに入っている「お気に入り」順は「タイトル」順に変更する
    if (beyondPageListOrder.name === "favorite") {
      setBeyondPageListOrder({ name: "title", order: beyondPageListOrder.order });
      setOrder("title");
      setOrderBy(beyondPageListOrder.order);
    }
  }, []);

  const [order, setOrder] = useState<string>(beyondPageListOrder.order);
  const [orderBy, setOrderBy] = useState<string>(beyondPageListOrder.name);
  const [selectedAbTestList, setSelectedAbTestList] = useState<AbTestProps[]>([]);

  const { t } = useTranslation();

  const updateOrder = (orderBy: string) => {
    const newOrder = order === "desc" && beyondPageListOrder.name === orderBy ? "asc" : "desc";
    const newOrderBy = order === "asc" && beyondPageListOrder.name === orderBy ? "title" : orderBy;
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setBeyondPageListOrder({ name: newOrderBy, order: newOrder });
  };

  const updateSelectedAbTestList = (target: AbTestProps) => {
    const abTest = selectedAbTestList.find((d: AbTestProps) => d.id === target.id);
    if (abTest === undefined) {
      setSelectedAbTestList([target, ...selectedAbTestList]);
    } else {
      const list = selectedAbTestList.filter((d: AbTestProps) => d.id != target.id);
      setSelectedAbTestList(list);
    }
  };

  const filteredByAdStatus: AbTestProps[] = useMemo(
    () => filterAbTestListByAdStatus(abTestList, adStatus),
    [abTestList, adStatus],
  );
  const filteredByQuery: AbTestProps[] = useMemo(
    () => filterAbTestListByQuery(filteredByAdStatus, query),
    [filteredByAdStatus, query],
  );
  const listWithCount: AbTestProps[] = useMemo(
    () => assignCount(filteredByQuery, abTestReportList),
    [filteredByQuery, abTestReportList],
  );
  const listInOrder: AbTestProps[] = useMemo(
    () => sortAbTestList(listWithCount, order, orderBy),
    [listWithCount, order, orderBy],
  );

  const filteredAbTestReportList = useMemo(
    () => filterAbTestReportList(listInOrder, abTestReportList),
    [listInOrder, abTestReportList],
  );
  const abTestReportMinCount = useMemo(
    () => pickMinCountFromAbTestReportList(filteredAbTestReportList),
    [filteredAbTestReportList],
  );
  const abTestReportMaxCount = useMemo(
    () => pickMaxCountFromAbTestReportList(filteredAbTestReportList),
    [filteredAbTestReportList],
  );
  const abTestReportTotalCount = useMemo(
    () => sumAbTestReportCount(filteredAbTestReportList),
    [filteredAbTestReportList],
  );

  // 全選択
  const fillSelectedAbTestList = () => {
    setSelectedAbTestList(listInOrder);
  };

  // 全解除
  const resetSelectedAbTestList = useCallback(() => {
    setSelectedAbTestList([]);
  }, [setSelectedAbTestList]);

  // 全選択チェックボックス変更
  const handleChangeBulkCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) fillSelectedAbTestList();
    else resetSelectedAbTestList();
  };

  // 全選択チェックボックス選択状態
  const isSelectedBulkCheckbox =
    selectedAbTestList.length === listInOrder.length && listInOrder.length > 0;

  useEffect(() => {
    setSelectedAbTestList([]);
  }, [folder]);

  //最初にクリックしたフォルダのデータ取得が重い場合に他のフォルダをクリックするとbeyondページ一覧が上書きされるので、
  //リクエスト中は他のフォルダを選択できないようにする
  useEffect(() => {
    if (!folderListRef.current) {
      return;
    }
    if (abTestListLoading) {
      folderListRef.current.style.opacity = "0.5";
      folderListRef.current.style.pointerEvents = "none";
    } else {
      folderListRef.current.style.opacity = "1";
      folderListRef.current.style.pointerEvents = "all";
    }
  }, [abTestListLoading, folderListRef]);

  return (
    <Paper
      sx={{ overflowX: "scroll", mb: 2, position: "relative" }}
      elevation={0}
    >
      {abTestListLoading || !abTestFolder ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: 5,
          }}
        >
          <CircularProgress
            color="primary"
            size={30}
          />
        </Box>
      ) : (
        <>
          <BulkMenuList
            resetSelectedAbTestList={resetSelectedAbTestList}
            selectedAbTestList={selectedAbTestList}
            userTeamList={userTeamList}
            fetchFolderList={fetchFolderList}
            fetchFolderGroupList={fetchFolderGroupList}
            moveAbTest={moveAbTest}
            moveAbTestLoading={moveAbTestLoading}
            moveAbTestError={moveAbTestError}
          />
          <TableContainer sx={{ maxHeight: "100vh" }}>
            <Table stickyHeader>
              <TableHead
                sx={{
                  ".MuiTableCell-root": {
                    "&:first-child, &:last-child": {
                      zIndex: 10,
                    },
                  },
                }}
              >
                <TableRow>
                  <FirstCell>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => updateOrder("title")}
                    >
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "#444",
                          fontWeight: "bold",
                        }}
                      >
                        {t("タイトル")}
                      </Typography>
                      <OrderArrow
                        order={order}
                        orderBy={orderBy}
                        columnValue={"title"}
                      />
                    </Box>
                  </FirstCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.value}
                      align="center"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          whiteSpace: "nowrap",
                          cursor: "pointer",
                        }}
                        onClick={() => updateOrder(column.value)}
                      >
                        <Typography
                          sx={{
                            fontSize: "11px",
                            color: "#444",
                            fontWeight: "bold",
                          }}
                        >
                          {column.label}
                        </Typography>
                        <OrderArrow
                          order={order}
                          orderBy={orderBy}
                          columnValue={column.value}
                        />
                      </Box>
                    </TableCell>
                  ))}
                  <LastCell>
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        sx={{
                          fontSize: "11px",
                          color: "#444",
                          fontWeight: "bold",
                          ml: "auto",
                          width: "40px",
                        }}
                      >
                        {t("CV連携")}
                      </Typography>
                    </Box>
                  </LastCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <FirstCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        size="small"
                        sx={{ p: 0, mr: 0.5 }}
                        onChange={handleChangeBulkCheckbox}
                        checked={isSelectedBulkCheckbox}
                      />
                      <Box sx={{ mr: 0.5 }}>
                        <FolderIcon
                          color="common"
                          width="15px"
                          height="12px"
                        />
                      </Box>
                      <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        {abTestFolder.name}
                      </Typography>
                    </Box>
                  </FirstCell>
                  <TableCell />
                  {columns
                    .filter((column) => column.type === "count")
                    .map((column) => (
                      <TableCell
                        key={column.value}
                        sx={{ fontSize: "12px" }}
                        align="center"
                      >
                        {abTestReportTotalCount?.[column.value]?.toLocaleString("en-US")}
                      </TableCell>
                    ))}
                  <LastCell />
                </TableRow>
                {listInOrder.map((abTest: AbTestProps) => (
                  <Row
                    key={abTest.id}
                    folder={abTestFolder}
                    abTest={abTest}
                    abTestList={listInOrder}
                    selectedAbTestList={selectedAbTestList}
                    mediaList={mediaList}
                    userTeamList={userTeamList}
                    conversionTagList={conversionTagList}
                    folderExitPopupList={folderExitPopupList}
                    folderArticleList={folderArticleList}
                    folderRedirectPageList={folderRedirectPageList}
                    abTestReportMaxCount={abTestReportMaxCount}
                    abTestReportMinCount={abTestReportMinCount}
                    reportStartDate={reportStartDate}
                    reportEndDate={reportEndDate}
                    editAbTestHandle={editAbTestHandle}
                    fetchFolderList={fetchFolderList}
                    fetchFolderGroupList={fetchFolderGroupList}
                    moveAbTest={moveAbTest}
                    duplicateAbTest={duplicateAbTest}
                    deleteAbTest={deleteAbTest}
                    updateSelectedAbTestList={updateSelectedAbTestList}
                    resetSelectedAbTestList={resetSelectedAbTestList}
                    deleteAbTestLoading={deleteAbTestLoading}
                    duplicateAbTestLoading={duplicateAbTestLoading}
                    moveAbTestLoading={moveAbTestLoading}
                    duplicateAbTestError={duplicateAbTestError}
                    moveAbTestError={moveAbTestError}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Paper>
  );
};
