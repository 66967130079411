export const sortFoldersByMemberOrder = (folders, sortOptions, folderOrders) => {
  const list = [...folders];

  // TODO: sortOptions.name === 'custom'であれば、order by id ascしたあとで、folderOrdersを適用する
  list.sort((a, b) => {
    if (sortOptions.name === 'custom') {
      return a.id - b.id; // NOTE: Only asc
    } else if (sortOptions.name === 'name') {
      if (sortOptions.desc) {
        if (b[sortOptions.name] < a[sortOptions.name]) { return -1; }
        if (b[sortOptions.name] > a[sortOptions.name]) { return 1; }
        return 0;
      } else {
        if (a[sortOptions.name] < b[sortOptions.name]) { return -1; }
        if (a[sortOptions.name] > b[sortOptions.name]) { return 1; }
        return 0;
      }
    } else {
      if (sortOptions.desc) {
        return b.count[sortOptions.name] - a.count[sortOptions.name];
      } else {
        return a.count[sortOptions.name] - b.count[sortOptions.name];
      }
    }
  });

  if (sortOptions.name !== 'custom') {
    return list;
  }

  const r = [];
  for (let i = 0; i < list.length; i++) {
    r[i] = null;
  }
  folderOrders.forEach((order) => {
    const folder = list.find(element => element.id === order.folder_id);
    if (folder) {
      const existsFolder = r[order.position - 1] !== null;
      if (existsFolder) {
        const listFromResult = r.slice(order.position - 1, r.length);
        const nullIndex = listFromResult.findIndex(d => d === null);
        if (nullIndex === -1) {
          r.push(folder);
        } else {
          r[order.position -1 + nullIndex] = folder;
        }
      } else {
        r[order.position - 1] = folder;
      }
      const index = list.findIndex(element => element.id === order.folder_id);
      // Note: Remove folder from list
      list.splice(index, 1);
    }
  });
  // NOTE: undefinedも取得するためにfor
  for (let i = 0; i < r.length; i++) {
    if (r[i] === null || r[i] === undefined) {
      r[i] = list[0];
      list.splice(0, 1);
    }
  }

  return r.filter(d => !!d);
};
