import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box, IconButton, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import {
  BeyondPageArticleOptionList,
  SplitTestSettingInfoForNonBoostContractor,
  SplitTestSettingInfoForNonBoostSetting,
  Theme,
} from "components";
import { useAlert } from "contexts/alert";
import { AbTestProps, ArticleProps, ArticleSplitTestDisplayPeriodProps } from "domains";
import {
  HandleCreateAbTestSplitTestSettingPeriods,
  HandleDeleteAbTestSplitTestSettingPeriods,
  HandleUpdateAbTestSplitTestSettingPeriods,
  useMember,
  useLocalStorage,
  useAbTestDeliveryArchitectureSetting,
} from "hooks";
import Nav from "javascripts/components/Editor/Nav";
import Litepicker from "litepicker";
import moment, { Moment } from "moment";

const displayTextColor = "#6dd400";
const displayOffTextColor = "#fa6400";

const DeletePeriod: React.FC<{
  articleUid: string;
  period: ArticleSplitTestDisplayPeriodProps;
  onDeletePeriod: Function;
  periodTextColor: string;
}> = ({ articleUid, period, onDeletePeriod, periodTextColor }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleDeletePeriod = async () => {
    await onDeletePeriod(articleUid, period.id);
    handleMenuClose();
  };

  return (
    <Box>
      <IconButton
        onClick={handleMenuClick}
        sx={{
          width: "30px",
          height: "30px",
        }}
      >
        <MoreHorizIcon
          sx={{ color: periodTextColor }}
          fontSize="small"
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeletePeriod}>
          <Typography>{t("削除する")}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const DateSelect: React.FC<{
  article: ArticleProps;
  period: ArticleSplitTestDisplayPeriodProps;
  onUpdatePeriod: HandleUpdateAbTestSplitTestSettingPeriods;
  onDeletePeriod: HandleDeleteAbTestSplitTestSettingPeriods;
}> = ({ article, period, onUpdatePeriod, onDeletePeriod }) => {
  const { t } = useTranslation();
  const inputStartDateRef = useRef<HTMLInputElement>();
  const inputEndDateRef = useRef<HTMLInputElement>();
  const { clearAlerts } = useAlert();
  const [startTextFieldWidth, setStartTextFieldWidth] = useState("");
  const [endTextFieldWidth, setEndTextFieldWidth] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startMoment, setStartMoment] = useState<Moment | null>(null);
  const [endMoment, setEndMoment] = useState<Moment | null>(null);
  const [isDisplay, setIsDisplay] = useState(period.displayed);
  const periodColor = isDisplay ? "#204000" : "#4b1f01";
  const periodTextColor = isDisplay ? displayTextColor : displayOffTextColor;
  const [language] = useLocalStorage("language", "ja");

  useEffect(() => {
    updateFormatDateText(moment(period.started_on), moment(period.ended_on));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startMoment && endMoment) {
      const params = {
        split_test_display_periods: {
          started_on: startMoment.format("YYYY-MM-DD"),
          ended_on: endMoment.format("YYYY-MM-DD"),
          displayed: isDisplay,
        },
      };
      onUpdatePeriod(article.uid, period.id, params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMoment, endMoment]);

  const updateDateText = (startDate: string, endDate: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const updateFormatDateText = (start: Moment, end: Moment) => {
    const currentYear = moment().format("YYYY年");
    const startYear = start.format("YYYY年");
    const endYear = end.format("YYYY年");
    const startMonth = start.format("MM月");
    const endMonth = end.format("MM月");
    const startDay = start.format("DD日");
    const endDay = end.format("DD日");
    // startとendのyearが現在の年の場合かつ同じ年の場合、yearを表示しない
    const startYearText = startYear === currentYear && startYear === endYear ? "" : startYear;
    const endYearText = startYearText ? endYear : "";
    // startとendのmonthが現在の年の場合かつ同じ月の場合、endのmonthを表示しない
    const startMonthText = startMonth.replace(/^0/, "");
    const endMonthText =
      endYear === currentYear && startMonth === endMonth ? "" : endMonth.replace(/^0/, "");
    const startDayText = startDay.replace(/^0/, "");
    const endDayText = endDay.replace(/^0/, "");
    if (inputStartDateRef.current !== undefined && inputEndDateRef.current !== undefined) {
      inputStartDateRef.current.value = `${startYearText}${startMonthText}${startDayText}`;
      inputEndDateRef.current.value = `${endYearText}${endMonthText}${endDayText}`;
    }
    updateDateText(
      `${startYearText}${startMonthText}${startDayText}`,
      `${endYearText}${endMonthText}${endDayText}`,
    );
  };

  useEffect(() => {
    if (inputStartDateRef.current === undefined || inputEndDateRef.current === undefined) {
      return;
    }
    new Litepicker({
      element: inputStartDateRef.current,
      elementEnd: inputEndDateRef.current,
      singleMode: false,
      lang: language,
      format: "YYYY年MM月DD日",
      startDate: period.started_on,
      endDate: period.ended_on,
      minDate: moment().format("YYYY月MM日DD"),
      tooltipText: { one: t("日~.単日", { value: "" }), other: t("日~.期間", { value: "" }) },
      setup: (picker) => {
        picker.on("selected", (start, end) => {
          clearAlerts();
          setStartMoment(start);
          setEndMoment(end);
        });
        picker.on("hide", () => {
          if (inputStartDateRef.current !== undefined && inputEndDateRef.current !== undefined) {
            updateFormatDateText(
              moment(inputStartDateRef.current.value, "YYYY年MM月DD日"),
              moment(inputEndDateRef.current.value, "YYYY年MM月DD日"),
            );
          }
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStartDateRef, inputEndDateRef]);

  useEffect(() => {
    const startDateTextWidth = (startDate.length + 1) * 12;
    setStartTextFieldWidth(`${startDateTextWidth}px`);
    const endDateTextWidth = (endDate.length + 1) * 12;
    setEndTextFieldWidth(`${endDateTextWidth}px`);
  }, [startDate, endDate]);

  const DisplayIcon = () => {
    if (isDisplay) {
      return <VisibilityIcon sx={{ color: periodTextColor, ml: 0.2 }} />;
    }
    return <VisibilityOffIcon sx={{ color: periodTextColor, ml: 0.2 }} />;
  };

  const handleDiaplayIconClick = () => {
    setIsDisplay(!isDisplay);
    const params = {
      split_test_display_periods: {
        started_on: period.started_on,
        ended_on: period.ended_on,
        displayed: !isDisplay,
      },
    };
    onUpdatePeriod(article.uid, period.id, params);
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: periodColor,
        justifyContent: "between",
        borderRadius: "10px 10px",
        alignItems: "center",
        mr: 1,
        height: "35px",
      }}
    >
      <TextField
        sx={{
          ml: 1,
          color: periodTextColor,
          display: "inline-block",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent",
              height: "30px",
              top: 1,
            },
            "& .MuiOutlinedInput-input": {
              px: 0,
              width: startTextFieldWidth,
              textAlign: "center",
              caretColor: periodColor,
              color: periodTextColor,
            },
          },
        }}
        size="small"
        value={startDate}
        inputRef={inputStartDateRef}
      />
      <Typography
        sx={{
          color: "white",
          mr: endDate ? 0 : 1,
        }}
      >
        〜
      </Typography>
      <TextField
        sx={{
          mr: 0.5,
          display: endDate ? "inline-block" : "none",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent",
              height: "30px",
              top: 1,
            },
            "& .MuiOutlinedInput-input": {
              px: 0,
              width: endTextFieldWidth,
              textAlign: "center",
              caretColor: periodColor,
              color: periodTextColor,
            },
          },
        }}
        size="small"
        value={endDate}
        inputRef={inputEndDateRef}
      />
      <IconButton
        sx={{
          display: "flex",
          alignItems: "center",
          width: "30px",
          height: "30px",
        }}
        onClick={() => handleDiaplayIconClick()}
      >
        <DisplayIcon />
      </IconButton>
      <Box
        sx={{
          mr: 0.4,
          display: "flex",
          alignItems: "center",
        }}
      >
        <DeletePeriod
          articleUid={article.uid}
          period={period}
          onDeletePeriod={onDeletePeriod}
          periodTextColor={periodTextColor}
        />
      </Box>
    </Box>
  );
};

const Article: React.FC<{
  article: ArticleProps;
  onCreatePeriod: HandleCreateAbTestSplitTestSettingPeriods;
  createPeriodError?: Error;
  onUpdatePeriod: HandleUpdateAbTestSplitTestSettingPeriods;
  onDeletePeriod: HandleDeleteAbTestSplitTestSettingPeriods;
  isCloudflareSetting: boolean | null;
  isCanUseBoostMode: boolean | null | undefined;
}> = ({
  article,
  onCreatePeriod,
  createPeriodError,
  onUpdatePeriod,
  onDeletePeriod,
  isCloudflareSetting,
  isCanUseBoostMode,
}) => {
  const { showAlerts, clearAlerts } = useAlert();
  const [sortedPeriods, setSortedPeriods] = useState<ArticleSplitTestDisplayPeriodProps[]>([]);

  useEffect(() => {
    const periods = article.split_test_display_periods;
    // split_test_display_periods配列をstarted_on順にソート
    const clonePeriods =
      periods.length > 0
        ? [...periods].sort((a, b) => {
            const aDate = moment(a.started_on);
            const bDate = moment(b.started_on);
            // startの日付が同じ場合はendが早い方を先に表示
            if (aDate.isSame(bDate)) {
              const aEndDate = moment(a.ended_on);
              const bEndDate = moment(b.ended_on);
              return aEndDate.isBefore(bEndDate) ? -1 : 1;
            }
            return aDate.isBefore(bDate) ? -1 : 1;
          })
        : [];
    setSortedPeriods(clonePeriods);
  }, [article.split_test_display_periods]);

  useEffect(() => {
    if (createPeriodError?.message) {
      showAlerts({
        messages: [createPeriodError.message],
        theme: "danger",
      });
    } else {
      clearAlerts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPeriodError]);

  const periodList =
    !isCanUseBoostMode || !isCloudflareSetting
      ? []
      : sortedPeriods.map((period: ArticleSplitTestDisplayPeriodProps) => {
          return (
            <DateSelect
              key={period.id}
              article={article}
              period={period}
              onUpdatePeriod={onUpdatePeriod}
              onDeletePeriod={onDeletePeriod}
            />
          );
        });

  const addDateListSelect = () => {
    const lastPeriod = sortedPeriods[sortedPeriods.length - 1];
    const params = {
      split_test_display_periods: {
        // 配信する・しないどちらかの期間が１つでも設定してあれば、期間の最終日プラス１日を基準に１年間をcreate
        started_on: lastPeriod
          ? moment(lastPeriod.ended_on).add(1, "d").format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
        ended_on: lastPeriod
          ? moment(lastPeriod.ended_on).add(1, "d").add(1, "y").format("YYYY-MM-DD")
          : moment().add(1, "y").format("YYYY-MM-DD"),
        displayed: true,
      },
    };
    onCreatePeriod(article.uid, params);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          flex: 1,
          pr: 1.4,
          pb: 2,
          bgcolor: "background.primary",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flex: 1,
            bgcolor: "background.default",
            height: "46px",
            px: 1,
            borderRadius: "0px 10px 10px 0px",
            maxWidth: "calc(100vw - 535px)",
            overflowX: "auto",
            scrollBehavior: "smooth",
          }}
        >
          {periodList}
        </Box>
      </Box>
      {!isCanUseBoostMode || !isCloudflareSetting ? null : (
        <Box
          sx={{
            display: "flex",
            bgcolor: "black",
            height: "25px",
            width: "25px",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "5px",
            mt: 0.8,
            mr: 1.4,
          }}
        >
          <AddBoxIcon
            sx={{
              color: "orange",
              borderRadius: "5px",
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
            onClick={addDateListSelect}
          />
        </Box>
      )}
    </Box>
  );
};

const ArticleTitle: React.FC<{
  title: string;
  abTestRate: number;
  // FIXME
  // eslint-disable-next-line react/display-name
}> = memo(({ title, abTestRate }) => {
  return (
    <Tooltip
      title={<Typography sx={{ fontSize: "15px" }}>{title}</Typography>}
      placement="top"
    >
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            fontSize: "14px",
            height: "46px",
            lineHeight: "46px",
            px: 1.4,
            py: 1,
            boxSizing: "border-box",
            bgcolor: "lightGray",
            borderRadius: "10px 0 0 10px",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              flex: 1,
              color: "gray",
              textAlign: "right",
            }}
          >
            {abTestRate}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
});

// FIXME
// eslint-disable-next-line react/display-name
const ArticleTitlesHeader: React.FC = memo(() => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "230px",
        pb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          px: 1.4,
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            flex: 3,
            fontSize: "12px",
          }}
        >
          {t("Version")}
        </Typography>
        <Typography
          sx={{
            color: "gray",
            fontSize: "12px",
            textAlign: "right",
          }}
        >
          {t("配信割合")}
        </Typography>
      </Box>
    </Box>
  );
});

const ArticlesHeader: React.FC<{
  allPeriods: ArticleSplitTestDisplayPeriodProps[];
  notDisplayedPeriodTexts: string[];
  isCloudflareSetting: boolean | null;
  isCanUseBoostMode: boolean | null | undefined;
  abTest: AbTestProps;
}> = ({ allPeriods, notDisplayedPeriodTexts, isCloudflareSetting, abTest, isCanUseBoostMode }) => {
  const { t } = useTranslation();
  const headerTextStyle = {
    color: "gray",
    fontSize: "14px",
  };
  const notSetPeriodsNotification =
    allPeriods.length === 0 ? (
      <Box
        sx={{
          pl: 2,
          pt: 1,
          color: "error.main",
        }}
      >
        <Typography fontSize={"14px"}>
          {t("期間の設定がない場合は日付別機能は適用されません。")}
        </Typography>
      </Box>
    ) : null;

  const notDisplayedPeriodsNotification =
    notDisplayedPeriodTexts.length > 0 ? (
      <Box
        sx={{
          pl: 2,
          color: "error.main",
        }}
      >
        <Box sx={{ pt: 1 }}>
          <Typography fontSize={"14px"}>
            {t("下記期間に配信されるVersionがありません。")}
          </Typography>
          <Typography fontSize={"14px"}>
            {t("配信割合が「1」以上のVersionに配信期間を設定してください。")}
          </Typography>
        </Box>
        <Box sx={{ pt: 0.5 }}>
          {notDisplayedPeriodTexts.map((text, index) => (
            <Typography
              key={index}
              fontSize={"14px"}
              sx={{ fontWeight: "600" }}
            >
              {text}
            </Typography>
          ))}
        </Box>
      </Box>
    ) : null;

  const headerMessages = !isCanUseBoostMode ? (
    <SplitTestSettingInfoForNonBoostContractor
      category={"日付別出し分け"}
      body={"日付別出し分けの利用希望"}
    />
  ) : !isCloudflareSetting ? (
    <SplitTestSettingInfoForNonBoostSetting
      abTestUid={abTest.uid}
      folderUid={abTest.folder?.uid}
    />
  ) : (
    <>
      <Box sx={{ pl: 2 }}>
        <Typography sx={headerTextStyle}>
          {t("バージョンごとに設定をすることでより効率的なBeyondページの見せ方ができます。")}
        </Typography>
        <Typography sx={headerTextStyle}>配信期間の指定は二種類あり</Typography>
        <Typography sx={{ ...headerTextStyle, alignItems: "center" }}>
          <Box
            component={"span"}
            sx={{ color: displayTextColor }}
          >
            <VisibilityIcon
              fontSize="small"
              sx={{
                display: "flex",
                float: "left",
                mt: 0.1,
                mr: 0.2,
              }}
            />
          </Box>
          <Box
            component={"span"}
            sx={{
              color: displayTextColor,
              display: "flex",
              float: "left",
            }}
          >
            配信する
          </Box>
          <Box
            component={"span"}
            sx={{
              display: "flex",
              float: "left",
            }}
          >
            と
          </Box>
          <Box
            component={"span"}
            sx={{ color: displayOffTextColor }}
          >
            <VisibilityOffIcon
              fontSize="small"
              sx={{
                display: "flex",
                float: "left",
                mt: 0.1,
                mr: 0.2,
              }}
            />
            配信しない
          </Box>
          です。
        </Typography>
        <Typography sx={headerTextStyle}>
          {t(
            "プラスボタンを押すと、Versionに設定されている最も遅い期間の次の日から１年間の配信期間が追加されます。",
          )}
        </Typography>
      </Box>
      {notSetPeriodsNotification ? notSetPeriodsNotification : notDisplayedPeriodsNotification}
    </>
  );

  return (
    <Box
      sx={{
        flex: 1,
        pb: 1.4,
        pr: 2,
        bgcolor: "background.primary",
        borderRadius: "10px 10px 0px 0px",
      }}
    >
      <Box
        sx={{
          color: "primary.contrastText",
          fontSize: "14px",
          fontWeight: "600px",
          pt: 2,
          pl: 2,
          pb: 1.6,
        }}
      >
        {t("日付別")}
      </Box>
      {headerMessages}
    </Box>
  );
};

const Periods: React.FC<{
  articleList: ArticleProps[];
  abTest: AbTestProps;
  onCreatePeriod: HandleCreateAbTestSplitTestSettingPeriods;
  createPeriodError?: Error;
  onUpdatePeriod: HandleUpdateAbTestSplitTestSettingPeriods;
  onDeletePeriod: HandleDeleteAbTestSplitTestSettingPeriods;
  isCloudflareSetting: boolean | null;
}> = ({
  articleList,
  abTest,
  onCreatePeriod,
  createPeriodError,
  onUpdatePeriod,
  onDeletePeriod,
  isCloudflareSetting,
}) => {
  if (articleList === null) {
    return null;
  }

  const [notDisplayedPeriodTexts, setNotDisplayedPeriodText] = useState<string[]>([]);
  const [allPeriods, setAllPeriods] = useState<ArticleSplitTestDisplayPeriodProps[]>([]);
  const { memberAttributes } = useMember();
  const isCanUseBoostMode = memberAttributes.isCanUseBoostMode;

  useEffect(() => {
    const displayedPeriods = [] as ArticleSplitTestDisplayPeriodProps[];
    const allPeriods = [] as ArticleSplitTestDisplayPeriodProps[];
    // 配信割合が０より大きいVersionの配信する期間のみ抽出
    articleList.map((article: ArticleProps) => {
      article.split_test_display_periods.map((period: ArticleSplitTestDisplayPeriodProps) => {
        allPeriods.push(period);
        period.displayed &&
          Number(article.ab_test_article.rate) > 0 &&
          displayedPeriods.push(period);
      });
    });
    setAllPeriods(allPeriods);
    // 配信しない期間を算出して、テキストとして表示
    if (allPeriods.length > 0 && displayedPeriods.length === 0) {
      setNotDisplayedPeriodText([`${moment().format("YYYY年MM月DD日")}〜`]);
    } else {
      // 配信開始順にソート
      const sortedDisplaeydPeriods = [...displayedPeriods].sort((a, b) => {
        const aDate = moment(a.started_on);
        const bDate = moment(b.started_on);
        // 配信開始の日付が同じ場合は配信終了が早い方を先に並べる
        if (aDate.isSame(bDate)) {
          const aEndDate = moment(a.ended_on);
          const bEndDate = moment(b.ended_on);
          return aEndDate.isBefore(bEndDate) ? -1 : 1;
        }
        return aDate.isBefore(bDate) ? -1 : 1;
      });
      let targetPeriod = {} as ArticleSplitTestDisplayPeriodProps;
      // 期間が完全に重複している配信期間を取り除く
      const filteredDisplayPeriods = [...sortedDisplaeydPeriods].filter((period, index) => {
        if (index === 0) {
          targetPeriod = period;
          return true;
        } else {
          const targetPeriodStart = moment(targetPeriod.started_on);
          const targetPeriodEnd = moment(targetPeriod.ended_on);
          if (
            moment(period.started_on).isBetween(targetPeriodStart, targetPeriodEnd, null, "[]") &&
            moment(period.ended_on).isBetween(targetPeriodStart, targetPeriodEnd, null, "[]")
          ) {
            return false;
          } else {
            targetPeriod = period;
            return true;
          }
        }
      });
      // 配信しない期間の算出条件
      // 1. 本日と最も早い配信期間の開始までに間が空いている
      // 2. 対象の配信期間の終了と次の配信期間の開始に間が空いている
      // 3. 最も遅い配信期間の終了後
      const notDisplayedPeriods = [] as string[];
      filteredDisplayPeriods.map((period: ArticleSplitTestDisplayPeriodProps, index: number) => {
        if (index === 0 && moment().startOf("day").isBefore(moment(period.started_on))) {
          const text = moment().startOf("day").isSame(moment(period.started_on).subtract(1, "day"))
            ? `${moment().format("YYYY年MM月DD日")}`
            : `${moment().format("YYYY年MM月DD日")}〜${moment(period.started_on)
                .subtract(1, "d")
                .format("YYYY年MM月DD日")}`;
          notDisplayedPeriods.push(text);
        }
        if (index === filteredDisplayPeriods.length - 1) {
          notDisplayedPeriods.push(
            `${moment(period.ended_on).add(1, "d").format("YYYY年MM月DD日")}〜`,
          );
        } else {
          const nextPeriod = filteredDisplayPeriods[index + 1];
          const periodEnd = moment(period.ended_on);
          const nextPeriodStart = moment(nextPeriod.started_on);
          const periodEndNextDay = moment(period.ended_on).add(1, "d");
          const nextPeriodStartPrevDay = moment(nextPeriod.started_on).subtract(1, "d");
          if (periodEnd !== nextPeriodStart && periodEndNextDay.isBefore(nextPeriodStart)) {
            const text = periodEndNextDay.isSame(nextPeriodStartPrevDay)
              ? `${periodEndNextDay.format("YYYY年MM月DD日")}`
              : `${periodEndNextDay.format("YYYY年MM月DD日")}〜${nextPeriodStartPrevDay.format(
                  "YYYY年MM月DD日",
                )}`;
            notDisplayedPeriods.push(text);
          }
        }
      });
      setNotDisplayedPeriodText(notDisplayedPeriods);
    }
  }, [articleList]);

  const articleTitles = articleList.map((article: ArticleProps) => {
    return (
      <ArticleTitle
        key={article.id}
        title={article.memo}
        abTestRate={article.ab_test_article.rate}
      />
    );
  });

  const articles = articleList.map((article: ArticleProps) => {
    return (
      <Article
        key={article.id}
        article={article}
        onCreatePeriod={onCreatePeriod}
        createPeriodError={createPeriodError}
        onUpdatePeriod={onUpdatePeriod}
        onDeletePeriod={onDeletePeriod}
        isCloudflareSetting={isCloudflareSetting}
        isCanUseBoostMode={isCanUseBoostMode}
      />
    );
  });

  const periodsContent = (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ display: "flex" }}>
        <ArticleTitlesHeader />
        <ArticlesHeader
          allPeriods={allPeriods}
          notDisplayedPeriodTexts={notDisplayedPeriodTexts}
          isCloudflareSetting={isCloudflareSetting}
          abTest={abTest}
          isCanUseBoostMode={isCanUseBoostMode}
        />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "230px" }}>{articleTitles}</Box>
        <Box
          sx={{
            flex: 1,
            bgcolor: "background.primary",
            minHeight: "450px",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          {articles}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Theme mode={"dark"}>
      <Box
        sx={{
          display: "flex",
          m: 2,
        }}
      >
        {periodsContent}
        <Box sx={{ width: "190px" }}>
          <BeyondPageArticleOptionList
            type={"periods"}
            abTestUid={abTest.uid}
          />
        </Box>
      </Box>
    </Theme>
  );
};

export const AbTestArticleSplitTestSettingPeriodsContainer: React.FC<{
  abTest?: AbTestProps;
  abTestUid: string;
  onFetchAbTest: Function;
  articleList: ArticleProps[];
  onCreatePeriod: HandleCreateAbTestSplitTestSettingPeriods;
  createPeriodError?: Error;
  onUpdatePeriod: HandleUpdateAbTestSplitTestSettingPeriods;
  updatePeriodError?: Error;
  onDeletePeriod: HandleDeleteAbTestSplitTestSettingPeriods;
}> = ({
  abTest,
  abTestUid,
  onFetchAbTest,
  articleList,
  onCreatePeriod,
  createPeriodError,
  onUpdatePeriod,
  updatePeriodError,
  onDeletePeriod,
}) => {
  const existsAbTest = abTest && Object.keys(abTest).length > 0;
  const { showAlerts, clearAlerts } = useAlert();
  const [setting, isCloudflareSettingLoading] = useAbTestDeliveryArchitectureSetting(abTestUid);
  const isCloudflareSetting = setting && setting.is_cloudflare;

  useEffect(() => {
    if (updatePeriodError?.message) {
      showAlerts({
        messages: [updatePeriodError.message],
        theme: "danger",
      });
    } else {
      clearAlerts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePeriodError]);

  useEffect(() => {
    onFetchAbTest(abTestUid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {existsAbTest && !isCloudflareSettingLoading && (
        <>
          <Nav
            abTest={abTest}
            folder={abTest.folder}
            page={"splitTestSetting"}
            article={null}
          />
          <Theme mode="dark">
            <Periods
              abTest={abTest}
              articleList={articleList}
              onCreatePeriod={onCreatePeriod}
              createPeriodError={createPeriodError}
              onUpdatePeriod={onUpdatePeriod}
              onDeletePeriod={onDeletePeriod}
              isCloudflareSetting={isCloudflareSetting}
            />
          </Theme>
        </>
      )}
    </>
  );
};
