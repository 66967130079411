import IconAlignCenter from "assets/images/align_center_currentColor.svg";
import IconAlignLeft from "assets/images/align_left_currentColor.svg";
import IconAlignRight from "assets/images/align_right_currentColor.svg";
import i18next from "i18next";

export const TEXT_ALIGN_MAP = {
  left: { text: i18next.t("左揃え"), icon: IconAlignLeft },
  center: { text: i18next.t("中央揃え"), icon: IconAlignCenter },
  right: { text: i18next.t("右揃え"), icon: IconAlignRight },
} as const;

export const COLOR_KEY_MAP = {
  background: i18next.t("背景"),
  text: i18next.t("タイトル"),
  note: i18next.t("注釈"),
  border: i18next.t("枠線（全体）"),
  textColor: i18next.t("ボタンテキスト"),
  backgroundColor: i18next.t("ボタン背景"),
} as const;

export const FONT_SIZE_KEY_MAP = {
  label: i18next.t("ラベル"),
  badge: i18next.t("バッジ"),
  note: i18next.t("注釈"),
  button: i18next.t("ボタン"),
} as const;

export const CHARACTER_WITH_OPTIONS = {
  half: i18next.t("半角数字のみ"),
  full: i18next.t("全角数字のみ"),
  halfFull: i18next.t("半角数字・全角数字"),
} as const;
