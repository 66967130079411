import ReactModal from "react-modal";
import appModalStyles from "stylesheets/components/partials/appModal.module.scss";
import type { AppModalProps } from "./type";

export const AppModal = ({
  children,
  setIsOpen,
  isModalOpen,
  width = 500,
  widthUnit = "px",
  onAfterOpen,
  onAfterClose,
  theme = "lightTheme",
}: AppModalProps) => {
  const trigger = children[0];
  const body = children[1];
  const customStyle = { maxWidth: `${width}${widthUnit}` };
  ReactModal.setAppElement("#root");

  function toggleModal() {
    setIsOpen(!isModalOpen);
  }

  return (
    <>
      <div
        className={appModalStyles.trigger}
        onClick={toggleModal}
      >
        {trigger}
      </div>
      <ReactModal
        isOpen={isModalOpen}
        className={`${appModalStyles.modal} ${appModalStyles[theme]}`}
        overlayClassName={appModalStyles.overlay}
        onRequestClose={() => setIsOpen(false)}
        style={{ content: customStyle }}
        onAfterOpen={onAfterOpen}
        onAfterClose={onAfterClose}
      >
        {body}
      </ReactModal>
    </>
  );
};
