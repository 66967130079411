import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const FolderIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 19"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-140.000000, -146.000000)"
          fill={iconColor}
          fillRule="nonzero"
        >
          <g transform="translate(100.000000, 0.000000)">
            <g transform="translate(20.000000, 83.000000)">
              <g transform="translate(0.000000, 27.000000)">
                <path d="M43.644,42.14 L43.6445312,41.2910156 C43.6445312,40.2239583 43.3688151,39.4182943 42.8173828,38.8740234 C42.2659505,38.3297526 41.4495443,38.0576172 40.3681641,38.0576172 L30.7861328,38.0576172 C30.4352214,38.0576172 30.1362305,38.0110677 29.8891602,37.9179688 C29.6420898,37.8248698 29.382487,37.6673177 29.1103516,37.4453125 L28.5195312,36.9511719 C28.2832031,36.764974 28.0594076,36.6145833 27.8481445,36.5 C27.6368815,36.3854167 27.4059245,36.3048503 27.1552734,36.2583008 C26.9046224,36.2117513 26.6074219,36.1884766 26.2636719,36.1884766 L23.3740234,36.1884766 C22.4501953,36.1884766 21.7304688,36.4534505 21.2148438,36.9833984 C20.6992188,37.5133464 20.4414062,38.3046875 20.4414062,39.3574219 L20.4414062,42.1396484 L43.644,42.14 Z M20.4414062,51.7431641 C20.4414062,52.8173828 20.7171224,53.6248372 21.2685547,54.1655273 C21.819987,54.7062174 22.6363932,54.9765625 23.7177734,54.9765625 L40.6474609,54.9765625 C41.5927734,54.9765625 42.3286133,54.7044271 42.8549805,54.1601562 C43.3813477,53.6158854 43.6445312,52.8102214 43.6445312,51.7431641 L43.644,43.547 L20.4414062,43.546875 L20.4414062,51.7431641 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
