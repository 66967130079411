import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useLogging } from "hooks/logging";
import TeamMemberFolderGroupOrderApi from "javascripts/api/teams/members/folderGroups/order";

export const teamMemberFolderGroupOrderListState = atom({
  key: "teamMemberFolderGroupOrderListState",
  default: [],
});

export const useTeamMemberFolderGroupOrderList = () => {
  const { sendErrorLog } = useLogging();
  const [list, setList] = useRecoilState(teamMemberFolderGroupOrderListState);

  useEffect(() => {
    TeamMemberFolderGroupOrderApi.fetch()
      .then((data) => {
        setList(data);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch team member folder group order",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};

export const useUpdateTeamMemberFolderGroupOrder = () => {
  const { sendErrorLog } = useLogging();
  const [_, setList] = useRecoilState(teamMemberFolderGroupOrderListState);

  const handle = useCallback(async (folderGroupId: number, orderId: number, params: {}) => {
    try {
      const response = await TeamMemberFolderGroupOrderApi.update(folderGroupId, orderId, params);
      setList(response);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to update team member folder group order",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};

export const useCreateTeamMemberFolderGroupOrder = () => {
  const { sendErrorLog } = useLogging();
  const [_, setList] = useRecoilState(teamMemberFolderGroupOrderListState);

  const handle = useCallback(async (folderGroupId: number, params: {}) => {
    try {
      const response = await TeamMemberFolderGroupOrderApi.create(folderGroupId, params);
      setList(response);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to create team member folder group order",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};
