import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const CarrierIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={iconColor}
    >
      <path d="M9 20.25C9 19.8358 9.33579 19.5 9.75 19.5H12.25C12.6642 19.5 13 19.8358 13 20.25C13 20.6642 12.6642 21 12.25 21H9.75C9.33579 21 9 20.6642 9 20.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8C5 6.34315 6.34315 5 8 5H14C15.6569 5 17 6.34315 17 8V20C17 21.6569 15.6569 23 14 23H8C6.34315 23 5 21.6569 5 20V8ZM9 6.5V7.25C9 7.66421 9.33579 8 9.75 8H12.25C12.6642 8 13 7.66421 13 7.25V6.5H14C14.8284 6.5 15.5 7.17157 15.5 8V20C15.5 20.8284 14.8284 21.5 14 21.5H8C7.17157 21.5 6.5 20.8284 6.5 20V8C6.5 7.17157 7.17157 6.5 8 6.5H9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.55 4.2002C16.55 3.78598 16.8858 3.4502 17.3 3.4502C19.095 3.4502 20.55 4.90527 20.55 6.7002C20.55 7.11441 20.2143 7.4502 19.8 7.4502C19.3858 7.4502 19.05 7.11441 19.05 6.7002C19.05 5.7337 18.2665 4.9502 17.3 4.9502C16.8858 4.9502 16.55 4.61441 16.55 4.2002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.05 1.7002C17.05 1.28598 17.3858 0.950195 17.8 0.950195C20.6995 0.950195 23.05 3.3007 23.05 6.2002C23.05 6.61441 22.7143 6.9502 22.3 6.9502C21.8858 6.9502 21.55 6.61441 21.55 6.2002C21.55 4.12913 19.8711 2.4502 17.8 2.4502C17.3858 2.4502 17.05 2.11441 17.05 1.7002Z"
      />
    </svg>
  );
};
