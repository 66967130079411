export const debounce = (cb, timer, focusout = true) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    if (!focusout) {
      window.addEventListener('focusout', (event) => {
        clearTimeout(timeout);
      });
    }
    timeout = setTimeout(() => cb.apply(context, args), timer);
  };
};

/** 
 * 上記debounceの場合、 
 * 関数をメモ化しないとtimerのアドレスがクリアされて動作しないため
 * 簡単に利用できるdebounceを新たに定義
 * 
 * There is a new debounce hook: “hooks/debounce"
 * 新しいデバウンスフックもあります："hooks/debounce"
 * 
 * */
