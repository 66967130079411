import { useEffect } from "react";
import { useDrag } from "react-dnd";
import buildPanelStyles from "stylesheets/components/forms/panels/build.module.scss";
import { optionsMap } from "../const";
import { DraggableBlockProps } from "./type";

export const DraggableBlock = ({
  name,
  blocks,
  addBlock,
  setMovingBlockIndex,
}: DraggableBlockProps) => {
  // プリセットブロックは各フォームに一つだけ追加可能
  const addedPresetBlockNames = blocks
    .filter((block) => "type" in block.options && block.options.type === "preset")
    .map((block) => block.name);

  const [{ isDragging }, drag] = useDrag({
    type: "formBlock",
    item: { name },
    end: () => setMovingBlockIndex(null),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const option = (() => optionsMap[name as keyof typeof optionsMap])();

  useEffect(() => {
    if (isDragging) setMovingBlockIndex(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging]);

  return (
    <li
      ref={drag}
      key={name}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={`${addedPresetBlockNames.includes(name) ? buildPanelStyles.disable : ""}`}
      onClick={() => addBlock(name as keyof typeof optionsMap)}
    >
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
      >
        <use xlinkHref={`${option.icon}#content`} />
      </svg>
      <span>{"blockName" in option ? option.blockName : option.label}</span>
    </li>
  );
};
