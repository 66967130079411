import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class ArticlePhotoApi extends ApiBase {

  static checkLoyaltyCreateArticlePhoto(articleUid) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(baseUrl + '/articles/' + articleUid + '/photos/authentications', { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static createArticlePhoto(articleUid, params) {
    let baseUrl = this.fileAppDefaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(baseUrl + '/articles/' + articleUid + '/photos',  params, { headers: { 'Content-Type': 'multipart/form-data' }, withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static fetchArticlePhotos(articleUid, pageNum, searchRange) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(baseUrl + '/articles/' + articleUid + '/photos',  { params: { article_photo: { page: pageNum, search_range: searchRange } } , withCredentials: true})
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static sendFileKeyToSquadbeyondFile(articleUid, params) {
    let baseUrl = this.fileAppDefaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(baseUrl + '/articles/' + articleUid + '/bulk_photos',  params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default ArticlePhotoApi;
