import axios from "axios";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderFormPhotoAuthenticationProps = {
  status: number;
};

class FolderFormPhotoAuthenticationApi extends ApiBase {
  static checkAuthority(folderUid: string, formUid: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderFormPhotoAuthenticationProps>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/forms/${formUid}/photos/authentications`;
      axios
        .get(path, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderFormPhotoAuthenticationApi;
