import { DELIVERY_ARCHITECTURE_ERROR_MESSAGES } from "const/deliveryArchitecture/deliveryArchitectureErrorMessages";

type DeliveryArchitectureErrorIndex = keyof typeof DELIVERY_ARCHITECTURE_ERROR_MESSAGES;

export const generateDeliveryArchitectureErrorMessage = (
  errorReasons: DeliveryArchitectureErrorIndex[],
) => {
  if (errorReasons) {
    const messages = errorReasons.map((reasonIndex: number) => {
      const reasonIndexString = reasonIndex.toString() as unknown as DeliveryArchitectureErrorIndex;
      return DELIVERY_ARCHITECTURE_ERROR_MESSAGES[reasonIndexString];
    });
    const messagesWithLineBreak = messages.map((message: string) => `${message}\n`);
    return messagesWithLineBreak.join("");
  }
  return "";
};
