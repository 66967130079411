import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const OsIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={iconColor}
    >
      <path d="M17.2237 10.0829L19.3489 6.93516C19.5336 6.66165 19.4609 6.28684 19.1874 6.10213C18.9139 5.91748 18.5391 5.99023 18.3544 6.26368L16.1595 9.51466C14.8921 8.93626 13.4835 8.61393 11.9999 8.61393C10.5164 8.61393 9.10779 8.93626 7.84041 9.51472L5.64546 6.26368C5.46081 5.99023 5.08594 5.91748 4.81249 6.10213C4.53898 6.28684 4.46629 6.66158 4.65093 6.93516L6.77615 10.0829C4.10799 11.7142 2.2593 14.5549 2 17.8434H22C21.7406 14.5549 19.8919 11.7143 17.2237 10.0829ZM7.91835 14.8139C7.14518 14.8139 6.51836 14.1871 6.51836 13.4139C6.51836 12.6407 7.14518 12.0139 7.91835 12.0139C8.69153 12.0139 9.31835 12.6407 9.31835 13.4139C9.31835 14.1871 8.69153 14.8139 7.91835 14.8139ZM16.0816 14.8139C15.3084 14.8139 14.6816 14.1871 14.6816 13.4139C14.6816 12.6407 15.3084 12.0139 16.0816 12.0139C16.8548 12.0139 17.4816 12.6407 17.4816 13.4139C17.4816 14.1871 16.8548 14.8139 16.0816 14.8139Z" />
    </svg>
  );
};
