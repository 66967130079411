import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleProps, ArticleSplitTestDisplayPeriodProps } from "domains";
import AbTestArticleDisplayPeriodsApi from "javascripts/api/abTest/articles/displayPeriods";
import AbTestArticleSplitTestSettingPeriodsApi from "javascripts/api/abTest/articles/splitTestSettings/periods";

export const abTestSplitTestSettingPeriodsArticleListState = atom<ArticleProps[]>({
  key: "abTestSplitTestSettingPeriodsArticleListState",
  default: [],
});

export const useAbTestSplitTestSettingPeriodsArticleList = (abTestUid: string) => {
  const [articleList, setArticleList] = useRecoilState(
    abTestSplitTestSettingPeriodsArticleListState,
  );
  const [error, setError] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await AbTestArticleSplitTestSettingPeriodsApi.fetch(abTestUid);
        setArticleList(response.articles);
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid]);

  return [articleList, error] as const;
};

type AbTestSplitTestSettingPeriodsParams = {
  split_test_display_periods: {
    started_on: string;
    ended_on: string;
    displayed: boolean;
  };
};

export type HandleCreateAbTestSplitTestSettingPeriods = (
  articleUid: string,
  params: AbTestSplitTestSettingPeriodsParams,
) => void;
export const useCreateAbTestSplitTestSettingPeriods = () => {
  const [articleList, setArticleList] = useRecoilState(
    abTestSplitTestSettingPeriodsArticleListState,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleCreateAbTestSplitTestSettingPeriods = async (articleUid, params) => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const response = await AbTestArticleDisplayPeriodsApi.create(articleUid, params);
      if (response) {
        const cloneArticleList = [...articleList];
        const index = cloneArticleList.findIndex((d: ArticleProps) => d.uid === articleUid);
        const updatedPeriods = [...articleList[index].split_test_display_periods, response];
        const updatedArticle = {
          ...articleList[index],
          split_test_display_periods: updatedPeriods,
        };
        cloneArticleList[index] = updatedArticle;
        setArticleList(cloneArticleList);
      }
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setError(e);
    }
  };

  return [handle, loading, error] as const;
};

export type HandleUpdateAbTestSplitTestSettingPeriods = (
  articleUid: string,
  periodId: number,
  params: AbTestSplitTestSettingPeriodsParams,
) => void;
export const useUpdateAbTestSplitTestSettingPeriods = () => {
  const [articleList, setArticleList] = useRecoilState(
    abTestSplitTestSettingPeriodsArticleListState,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleUpdateAbTestSplitTestSettingPeriods = async (
    articleUid,
    periodId,
    params,
  ) => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const response = await AbTestArticleDisplayPeriodsApi.update(articleUid, periodId, params);
      if (response) {
        const cloneArticleList = [...articleList];
        const index = cloneArticleList.findIndex((d: ArticleProps) => d.uid === articleUid);
        const clonePeriods = [...cloneArticleList[index].split_test_display_periods];
        const filteredPeriod = clonePeriods.filter(
          (d: ArticleSplitTestDisplayPeriodProps) => d.id !== periodId,
        );
        const updatedPeriods = [...filteredPeriod, response];
        const updatedArticle = {
          ...articleList[index],
          split_test_display_periods: updatedPeriods,
        };
        cloneArticleList[index] = updatedArticle;
        setArticleList(cloneArticleList);
      }
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setError(e);
    }
  };

  return [handle, loading, error] as const;
};

export type HandleDeleteAbTestSplitTestSettingPeriods = (
  articleUid: string,
  periodId: number,
) => void;
export const useDeleteAbTestSplitTestSettingPeriods = () => {
  const [articleList, setArticleList] = useRecoilState(
    abTestSplitTestSettingPeriodsArticleListState,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleDeleteAbTestSplitTestSettingPeriods = async (articleUid, periodId) => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await AbTestArticleDisplayPeriodsApi.destroy(articleUid, periodId);
      const cloneArticleList = [...articleList];
      const index = cloneArticleList.findIndex((d: ArticleProps) => d.uid === articleUid);
      const filteredPeriods = cloneArticleList[index].split_test_display_periods.filter(
        (d: ArticleSplitTestDisplayPeriodProps) => d.id !== periodId,
      );
      cloneArticleList[index] = {
        ...cloneArticleList[index],
        split_test_display_periods: filteredPeriods,
      };
      setArticleList(cloneArticleList);
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setError(e);
    }
  };

  return [handle, loading, error] as const;
};
