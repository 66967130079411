import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";
import { FolderGroupingProps, FolderProps } from "domains";
import { folderGroupingListState } from "hooks";
import FolderApi from "javascripts/api/folder";
import FolderGroupFolderMoveApi from "javascripts/api/folderGroups/folders/move";
import FolderReportDownloadApi from "javascripts/api/folders/reports/download";
import { downloadCsv } from "javascripts/utils";

export const folderListState = atom<FolderProps[]>({
  key: "folderListState",
  default: [],
});

export type HandleFetchFolderListProps = (options: {
  teamId?: number;
  folderScope: string;
}) => void;
export const useFetchFolderList = () => {
  const [folderList, setFolderList] = useRecoilState(folderListState);
  const [loading, setLoading] = useState<boolean>(false);

  const handle: HandleFetchFolderListProps = useCallback(
    async (options) => {
      if (loading) {
        return;
      }
      setLoading(true);

      // TODO: ここで組み立てるのではなく、組み立て済みのoptions(params)を渡す
      const params: { [key: string]: string | number } = {};
      params.folder_scope = options.folderScope;
      if (options.teamId !== undefined) {
        params.team_id = options.teamId;
      }
      try {
        const response = await FolderApi.fetchFolders(params);
        setFolderList(response.folders);
        setLoading(false);
      } catch (e) {
        setFolderList([]);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading],
  );

  return [handle, folderList, loading] as const;
};

export type HandleFetchFolderProps = (uid: string) => void;
export const useFetchFolder = () => {
  const [folder, setFolder] = useState<FolderProps>({} as FolderProps);
  const handle: HandleFetchFolderProps = useCallback(async (uid) => {
    try {
      const response = await FolderApi.fetch(uid);
      setFolder(response);
      return response;
    } catch (e) {
      return false;
    }
  }, []);

  return [handle, folder, setFolder] as const;
};

export const useFolderList = (options: { teamId?: number; folderScope: string }) => {
  const [folderList, setFolderList] = useRecoilState(folderListState);
  const [handle, , loading] = useFetchFolderList();

  useEffect(() => {
    handle(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [folderList, setFolderList, loading] as const;
};

export const useUpdateFolder = () => {
  const [folderList, setFolderList] = useRecoilState(folderListState);
  const [loading, setLoading] = useState<boolean>(false);

  // TODO: params: {}
  const handle = useCallback(
    async (folderUid: string, name: string) => {
      if (loading) {
        return;
      }
      setLoading(true);

      try {
        const response = await FolderApi.update(folderUid, name);

        const newFolderList = folderList.map((d: FolderProps) => {
          if (d.uid === folderUid) {
            return { ...d, ...response };
          } else {
            return d;
          }
        });
        setFolderList(newFolderList);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderList],
  );

  return [handle, loading] as const;
};

export const useMoveFolder = () => {
  const [folderList, _setFolderList] = useRecoilState(folderListState);
  const [folderGroupingList, setFolderGroupingList] = useRecoilState(folderGroupingListState);
  const [loading, setLoading] = useState<boolean>(false);

  const handle = useCallback(
    async (folderGroupId: number, folderId: number) => {
      if (loading) {
        return;
      }
      setLoading(true);

      try {
        const response = await FolderGroupFolderMoveApi.update(folderGroupId, folderId);

        const newFolderGroupingList = folderGroupingList.filter(
          (grouping: FolderGroupingProps) => grouping.folder_id !== folderId,
        );
        setFolderGroupingList([...newFolderGroupingList, response.folder_grouping]);

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderList, folderGroupingList],
  );

  return [handle, loading] as const;
};

export const useCreateFolder = () => {
  const [folderList, setFolderList] = useRecoilState(folderListState);
  const [folderGroupingList, setFolderGroupingList] = useRecoilState(folderGroupingListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (params: {}) => {
      if (loading) {
        return;
      }
      setLoading(true);

      try {
        const response = await FolderApi.createFolders(params);
        setFolderList([...folderList, response.folder]);
        if (response.folder_grouping !== null) {
          setFolderGroupingList([...folderGroupingList, response.folder_grouping]);
        }
        return response.folder;
      } catch (e) {
        setError(e);
        return null;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderList, folderGroupingList],
  );

  return [handle, loading, error] as const;
};

export const useDeleteFolder = () => {
  const { t } = useTranslation();
  const [folderList, setFolderList] = useRecoilState(folderListState);
  const [loading, setLoading] = useState<boolean>(false);

  const handle = useCallback(
    async (folderUid: string) => {
      if (
        !window.confirm(
          t("本当に削除しますか？削除を実行すると後から元に戻すことはできません") || "",
        )
      ) {
        return;
      }

      if (loading) {
        return;
      }
      setLoading(true);

      try {
        await FolderApi.destroy(folderUid);
        const newFolderList = folderList.filter((folder: FolderProps) => folder.uid !== folderUid);
        setFolderList(newFolderList);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderList],
  );

  return [handle, loading] as const;
};

export type HandleDownloadFolderReportProps = (
  folder: FolderProps,
  params: { category: string; start_date: string; end_date: string },
) => Promise<void>;
export const useDownloadFolderReport = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleDownloadFolderReportProps = useCallback(async (folder, params) => {
    if (loading) {
      return;
    }

    setError(undefined);
    setLoading(true);

    try {
      const response = await FolderReportDownloadApi.create(folder.uid, params);
      downloadCsv(response, `${folder.name}_${params.category}_${Date.now()}.csv`);
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, loading, error] as const;
};
