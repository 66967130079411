import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { AbTestParameterDailyReportProps } from "domains";
import AbTestParameterDailyReportApi from "javascripts/api/abTest/parameterDailyReport";

export const abTestParameterDailyReportListState = atom<AbTestParameterDailyReportProps[]>({
  key: "abTestParameterDailyReportListState",
  default: [],
});

export const useAbTestParameterDailyReportList = (
  abTestUid: string,
  startDate: string,
  endDate: string
) => {
  const [reportList, setReportList] = useRecoilState(abTestParameterDailyReportListState);

  useEffect(() => {
    const params = {
      start_date: startDate,
      end_date: endDate,
    };
    AbTestParameterDailyReportApi.fetchReports(abTestUid, params)
      .then((data) => {
        setReportList(data.parameter_daily_reports);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid, startDate, endDate]);

  return [reportList] as const;
};
