import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Palette, useTheme } from "@mui/material/styles";
import Codemirror from "codemirror";
import { ArticleHtmlPartCommentPopper } from "components";
import { CodeMirrorEditor } from "components/atoms/code-mirror-editor";
import { ArticleHtmlPartProps } from "domains";

export const ArticleHtmlPartCssForm: React.FC<{
  selectedPart: ArticleHtmlPartProps;
  css: string;
  cssEditorRef: any;
  handleCss?: (css: string) => void;
  theme: string;
  codemirrorTheme: string;
  action: string;
  codeType?: string;
  setSelectedPart: (part: ArticleHtmlPartProps) => void;
}> = ({
  selectedPart,
  css,
  cssEditorRef,
  handleCss,
  codemirrorTheme,
  theme,
  action,
  codeType = "custom",
  setSelectedPart,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popperPosition, setPopperPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });
  const [line, setLine] = useState<number | null>(null);
  const muiTheme: { palette: Palette } = useTheme();
  const cssOptions = {
    mode: "css",
    theme: codemirrorTheme,
    lineNumbers: true,
    autoCloseTags: true,
    autoCloseBrackets: true,
    lineWrapping: true,
    placeholder: t("を入力してください", { value: "CSS" }),
    tabSize: 4,
    gutters: ["CodeMirror-lint-markers"],
    lint: true,
    readOnly: codeType === "original",
  };

  useEffect(() => {
    if (!selectedPart) return;
    if (!cssEditorRef.current) return;
    if (!selectedPart.id) return;
    cssEditorRef.current.editor.on("renderLine", setBubble);
    cssEditorRef.current.editor.refresh();

    return function cleanup() {
      if (!cssEditorRef.current) return;
      cssEditorRef.current.editor.off("renderLine", setBubble);
    };
  }, [selectedPart && selectedPart.comments]);

  const setBubble = (cm: {}, line: Codemirror.LineHandle, ele: Element) => {
    const number: number = cssEditorRef.current.editor.getLineNumber(line);
    const index = number !== null ? number + 1 : null;
    if (ele.querySelector(`#comment-bubble-${index ? index : ""}`)) return;
    const comment = selectedPart.comments
      ? selectedPart.comments.find((e) => e.source_type === "css" && e.line === index)
      : undefined;
    ele.setAttribute("style", "position: relative; width: 90%; word-break: break-all;");
    ele.setAttribute("data-index", index ? `${index}` : "");
    const bubbleWrapper = document.createElement("div");
    bubbleWrapper.setAttribute("id", `comment-bubble-${index}`);
    bubbleWrapper.setAttribute(
      "style",
      `margin-left: auto; max-height: 18.5px; position: absolute; right: 0; top: 0;`
    );
    ele.appendChild(bubbleWrapper);
    const div = document.createElement("div");
    const bubbleStyle = `
      display: inline-block;
      width: 17px;
      text-align: center;
      color: rgb(255, 255, 255);
      height: 15px;
      background-color: ${muiTheme.palette.primary.main};
      border-radius: 1px;
      opacity: 0;
      font-weight: bold;
      `;
    div.setAttribute("style", bubbleStyle);
    const arrow = document.createElement("div");
    const arrowStyle = ` position: absolute;
      display: block;
      z-index: 1;
      border-style: solid;
      border-color: ${muiTheme.palette.primary.main} transparent;
      border-width: 5px 5px 0 0;
      bottom: 0px;
      left: 0px;
      `;
    arrow.setAttribute("style", arrowStyle);
    div.appendChild(arrow);
    if (!comment && action === "create") {
      ele.addEventListener("mouseenter", function () {
        div.style.setProperty("opacity", "1");
      });
      ele.addEventListener("mouseleave", function () {
        div.style.setProperty("opacity", "0");
      });
      const icon = document.createElement("div");
      const iconStyle = `display: flex;
      align-items: center;
      justify-content: center;
      height: 15px;`;
      icon.setAttribute("style", iconStyle);
      icon.innerText = "+";
      div.appendChild(icon);
      arrow.style.bottom = "-3px";
    }
    if (comment) {
      div.style.setProperty("opacity", "1");
    }
    div.onmousedown = function (e) {
      setPopperPosition({ x: e.x - 340, y: e.y });
      e.preventDefault();
      setLine(index);
      setAnchorEl(bubbleWrapper as HTMLElement);
      e.stopPropagation();
    };
    bubbleWrapper.appendChild(div);
  };

  const handleCssEditorKeyUp = (editor: {}, event: React.KeyboardEvent) => {
    // NOTE: optionがうまく動かない部分があるので一部自前で実装(closeCharacters)
    //       https://codemirror.net/doc/manual.html#addon_show-hint
    const cursor = cssEditorRef.current.editor.getCursor();
    const line = cssEditorRef.current.editor.getLine(cursor.line);
    if (
      [8, 32, 13, 37, 38, 39, 40].includes(event.keyCode) ||
      // eslint-disable-next-line no-useless-escape
      /[\s()\[\]{};:>,]/.test(line.charAt(cursor.ch - 1))
    ) {
      return;
    }
    cssEditorRef.current.editor.showHint({ completeSingle: false });
  };

  return (
    <>
      <CodeMirrorEditor
        codeMirrorRef={cssEditorRef}
        value={css}
        className={"articlePartCssForm"}
        options={cssOptions}
        onBeforeChange={(editor: {}, data: {}, value: string) => handleCss && handleCss(value)}
        onChange={(editor: {}, data: {}, value: string) => handleCss && handleCss(value)}
        onKeyUp={handleCssEditorKeyUp}
      />
      {selectedPart && selectedPart.id && (
        <ArticleHtmlPartCommentPopper
          key={`css-${line}`}
          index={line}
          selectedPart={selectedPart}
          partId={selectedPart.id}
          comment={
            selectedPart.comments && line
              ? selectedPart.comments.find((e) => e.source_type === "css" && e.line === line)
              : undefined
          }
          sourceType={"css"}
          action={action}
          theme={theme}
          setSelectedPart={setSelectedPart}
          anchorEl={anchorEl}
          popperPosition={popperPosition}
          setPopperPosition={setPopperPosition}
          setAnchorEl={setAnchorEl}
        />
      )}
    </>
  );
};
