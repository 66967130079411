import { MenuList } from "components/molecules/core/MenuList";
import { useClientRect } from "hooks/client-rect";
import { useScreenSize } from "hooks/screen-size";
import { RichMenuContents } from "../RichMenuContents";
import { DESCRIPTIONS, RICH_MENU_CLASS_NAME, WRAPPER_CLASS_NAME } from "./const";
import { useRichMenuHover } from "./hook";
import { RichMenuProps } from "./type";

// NOTE: 現状立地メニュー内の画像が未確定、「最近見た」の仕様が不明のため非表示にしている
// Currently, the images in the location menu are undecided, and the specifications for 'Recently Viewed' are unclear, so they have been hidden.
// TODO: リッチ化の仕様確定、実装完了後に削除する
// Remove after the specifications for enrichment are finalized and implementation is complete.

export const RichMenu = ({
  absolute = false,
  isOpen = false,
  navigationType,
  groups,
  anchor = "left",
}: RichMenuProps) => {
  const { height: windowHeight } = useScreenSize();
  const { rectState, clientRectRef } = useClientRect({ enabled: isOpen });

  const { hoveredMenuId, setHoveredMenuId } = useRichMenuHover({ containerRef: clientRectRef });

  const descriptionContent = DESCRIPTIONS.find((desc) => desc.id === hoveredMenuId);

  if (!isOpen) return <></>;

  return (
    <div className={WRAPPER_CLASS_NAME({ anchor, absolute })}>
      <div
        className={RICH_MENU_CLASS_NAME()}
        style={{
          maxHeight: `calc(
          ${windowHeight}px -
          ${rectState.y}px -
          1rem)`,
          boxShadow:
            "0px 40px 40px 0px rgba(0, 85, 195, 0.10), 0px 16px 24px 0px rgba(0, 85, 195, 0.08)",
        }}
        ref={clientRectRef}
      >
        <MenuList
          navigationType={navigationType}
          onMouseEnter={(id) => setHoveredMenuId(id)}
        />
        <RichMenuContents
          menuId={hoveredMenuId}
          descriptionContent={descriptionContent}
          groups={groups}
        />
      </div>
    </div>
  );
};
