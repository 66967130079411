export const isColumnLargeNumberWorse = (columnName: string): boolean => {
  const list: { [key: string]: boolean } = {
    cpa: true,
    mcpa: true,
    fver: true,
    sver: true,
    fsver: true,
  };
  return list[columnName] || false;
};
