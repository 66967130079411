import { useEffect, useState, useRef, Children } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'

import btnStyles from 'stylesheets/components/partials/btn.module.scss'
import abTestArticleStyles from 'stylesheets/components/editor/abTestArticle.module.scss'

import versionGrayBlackIcon from 'assets/images/version-gray-black.svg'
import versionBlackIcon from 'assets/images/version-black.svg'
import versionOrangeIcon from 'assets/images/version-orange.svg'

import AbTestArticleApi from 'javascripts/api/abTestArticle'

import Sortable from 'sortablejs';

import { collectError, debounce } from 'javascripts/utils';

import {
  ArticleListOptions,
  ArticleReflection
} from "components"
import { useTranslation } from "react-i18next";

const UnArchivedArticleListItem = ({
  article,
  parentArticle,
  abTest,
  articleListType,
  updateArticleBody,
  updateArticleMemo,
  currentArticle,
  isLoading,
  editorContentLoading,
  isSaveDraftRequesting,
  reflectionArticleBody,
  updateDeliveryRate,
  setArticle,
  handleSelectArticleUids,
  selectArticleUids,
  handleArticles,
  handleSelectedArticle,
  folderInspectionSetting,
  hasInspectionAuthority,
  updateSelectedArticleInspectionRequest,
  duplicateArticle,
  updateArticleArchivedStatus,
  handleArticlesListType,
 }) => {

  // NOTE: FunnelStepも含めて更新がされているがどうか
  let isReflectParentArticleFunnelStep = parentArticle && parentArticle.funnel_steps.filter(step => (step.article.reflected_at === null && step.article.character_count !== 0) || (step.article.reflected_at && step.article.body_updated_at > step.article.reflected_at)).length > 0
  let abTestArticlesList = document.querySelector(`.${abTestArticleStyles.abTestArticlesLists}`)

  useEffect(() => {
    if (!abTestArticlesList) return
    Sortable.create(abTestArticlesList, {
      animation: 100,
      fallbackTolerance: 5,
      preventOnFilter: false,
      onEnd: function (evt) {
        if (evt.newIndex === evt.oldIndex) return;
        let abTestArticleId = evt.item.dataset.id
        let params = new FormData()
        params.append('ab_test_article[position]', evt.newIndex + 1)
        let path = window.location.pathname.split('/')
        let fetchAbTestPath = '/' + path[1] + '/' + path[2]
        AbTestArticleApi.updateAbTestArticle(fetchAbTestPath, abTestArticleId, params)
          .then(data => {
            let articles = data.filter(article => article.archived === false);
            handleArticles({ unArchived: articles })
          })
          .catch(error => {
            collectError(error)
          })
      }
    });
  }, [abTestArticlesList])

  const isFolderInspectable = folderInspectionSetting.enabled || false;
  const rateFormRef = useRef()

  const displayPreview = () => {
    updateArticleBody()
    window.open(window.location.pathname + `/${article.uid}/previews`, `preview-article-${article.uid}`)
  }

  const upRateValue = (abTestArticlId) =>  {
    rateFormRef.current.value++;
    let rate = rateFormRef.current.value;
    updateDeliveryRate(rate, rateFormRef, abTestArticlId);
  }

  const downRateValue = (abTestArticlId) => {
    if (rateFormRef.current.value <= 0) return;
    rateFormRef.current.value--;
    let rate = rateFormRef.current.value;
    updateDeliveryRate(rate, rateFormRef, abTestArticlId);
  }

  return (
    <div style={{ pointerEvents: editorContentLoading ? "none" : "all" }} data-id={article.ab_test_article.id} data-article-uid={article.uid}>
      {currentArticle.uid === article.uid && abTestArticleStyles.active ?
        <>
          {article.ab_test.editor_version === 2 &&
            <ActiveComponent
              article={article}
              parentArticle={parentArticle}
              articleListType={articleListType}
              selectArticleUids={selectArticleUids}
              currentArticle={currentArticle}
              rateFormRef={rateFormRef}
              isLoading={isLoading}
              isReflectParentArticleFunnelStep={isReflectParentArticleFunnelStep}
              abTest={abTest}
              editorContentLoading={editorContentLoading}
              isSaveDraftRequesting={isSaveDraftRequesting}
              handleSelectArticleUids={(e) => handleSelectArticleUids(e)}
              setArticle={(e, articleUid) => setArticle(e, articleUid)}
              upRateValue={(abTestArticlId) => upRateValue(abTestArticlId)}
              downRateValue={(abTestArticlId) => downRateValue(abTestArticlId)}
              updateArticleMemo={(e) => updateArticleMemo(e)}
              reflectionArticleBody={() => reflectionArticleBody()}
              displayPreview={() => displayPreview()}
              updateDeliveryRate={(e, ref, abTestArticlId) => updateDeliveryRate(e, ref, abTestArticlId)}
              isFolderInspectable={isFolderInspectable}
              hasInspectionAuthority={hasInspectionAuthority}
              updateSelectedArticleInspectionRequest={(request) => updateSelectedArticleInspectionRequest(request)}
              duplicateArticle={duplicateArticle}
              updateArticleArchivedStatus={updateArticleArchivedStatus}
              handleArticlesListType={handleArticlesListType}
            />
          }
          {article.ab_test.editor_version === 3 &&
            <LpEditorActiveComponent
              article={article}
              parentArticle={article}
              articleListType={articleListType}
              selectArticleUids={selectArticleUids}
              currentArticle={currentArticle}
              rateFormRef={rateFormRef}
              isLoading={isLoading}
              abTest={abTest}
              editorContentLoading={editorContentLoading}
              isSaveDraftRequesting={isSaveDraftRequesting}
              handleSelectArticleUids={(e) => handleSelectArticleUids(e)}
              setArticle={(e, articleUid) => setArticle(e, articleUid)}
              upRateValue={(abTestArticlId) => upRateValue(abTestArticlId)}
              downRateValue={(abTestArticlId) => downRateValue(abTestArticlId)}
              updateArticleMemo={(e) => updateArticleMemo(e)}
              reflectionArticleBody={() => reflectionArticleBody()}
              displayPreview={() => displayPreview()}
              updateDeliveryRate={(e, ref, abTestArticlId) => updateDeliveryRate(e, ref, abTestArticlId)}
              isFolderInspectable={isFolderInspectable}
              hasInspectionAuthority={hasInspectionAuthority}
              updateSelectedArticleInspectionRequest={(request) => updateSelectedArticleInspectionRequest(request)}
              duplicateArticle={duplicateArticle}
              updateArticleArchivedStatus={updateArticleArchivedStatus}
              handleArticlesListType={handleArticlesListType}
            />
          }
        </>
        :
        <NormalComponent
          article={article}
          articleListType={articleListType}
          selectArticleUids={selectArticleUids}
          rateFormRef={rateFormRef}
          handleSelectArticleUids={(e) => handleSelectArticleUids(e)}
          setArticle={(e, articleUid) => setArticle(e, articleUid)}
          upRateValue={(abTestArticlId) => upRateValue(abTestArticlId)}
          downRateValue={(abTestArticlId) => downRateValue(abTestArticlId)}
          updateDeliveryRate={(e, ref, abTestArticlId) => updateDeliveryRate(e, ref, abTestArticlId)}
        />
      }
    </div>
  );
}

const LpEditorActiveComponent = ({
  article,
  parentArticle,
  articleListType,
  selectArticleUids,
  currentArticle,
  rateFormRef,
  isLoading,
  abTest,
  editorContentLoading,
  isSaveDraftRequesting,
  handleSelectArticleUids,
  setArticle,
  upRateValue,
  downRateValue,
  updateArticleMemo,
  reflectionArticleBody,
  displayPreview,
  updateDeliveryRate,
  isFolderInspectable,
  hasInspectionAuthority,
  updateSelectedArticleInspectionRequest,
  duplicateArticle,
  updateArticleArchivedStatus,
  handleArticlesListType,
}) => {
  let delayUpdate = debounce(updateArticleMemo, 500)
  let delayRateUpdate = debounce(updateDeliveryRate, 500)
  const isNotYetReflected = currentArticle.reflected_at === null && currentArticle.body_updated_at !== null;
  const isBodyUpdated     =  currentArticle.reflected_at !== null &&  currentArticle.body_updated_at > currentArticle.reflected_at;
  return (
    <div data-test={"ArticleList-CurrentArticle"} className={`${abTestArticleStyles.abTestArticle}  ${abTestArticleStyles.darkTheme} ${currentArticle.uid === parentArticle.uid && abTestArticleStyles.active}`}>
      <div className={`${abTestArticleStyles.abTestArticleContent}`}>
        {articleListType === 'selectUnArchivedArticles' ?
          <input data-test={"ArticleList-ArticleCheckBox"} type="checkbox" value={article.uid} className={'articleCheckbox'} checked={selectArticleUids.includes(article.uid)} onChange={(e) => handleSelectArticleUids(e)}/>
          :
          <>
            {(isBodyUpdated || isNotYetReflected) ?
              <img alt="" src={versionOrangeIcon}/>
              :
              <img alt="" src={versionBlackIcon}/>
            }
          </>
        }
        <div className={abTestArticleStyles.abTestArticleMemo}>
          <input
            data-test="ArticleList-InputMemo"
            type="text"
            defaultValue={article.memo}
            name='memo'
            onChange={(e) => delayUpdate(e.target.value)}
            />
        </div>
          <div className={`${abTestArticleStyles.abTestDeliveryRateFormWrapper} deliveryRateForm`}>
            <input
              data-test={'ArticleList-DeriveryRateForm'}
              ref={rateFormRef}
              type='number'
              min={0}
              className={`${abTestArticleStyles.abTestDeliveryRateForm} deliveryRateForm`}
              defaultValue={article.ab_test_article.rate}
              onChange={(e) => delayRateUpdate(e.target.value, rateFormRef,  article.ab_test_article.id)}
            />
              <div className={`${abTestArticleStyles.spinButtons} deliveryRateForm`}>
                <div data-test={"ArticleList-DeriveryUpRateForm"} className={`${abTestArticleStyles.spinButtonArrow} ${abTestArticleStyles.spinButtonUp} deliveryRateForm`}
                  onClick={() => upRateValue(article.ab_test_article.id)}>
                  <div className={`${abTestArticleStyles.up} deliveryRateForm`}>
                  </div>
                </div>
                <div data-test={"ArticleList-DeriveryDownRateForm"} className={`${abTestArticleStyles.spinButtonArrow} ${abTestArticleStyles.spinButtonDown} deliveryRateForm`}
                  onClick={() => downRateValue(article.ab_test_article.id)}>
                  <div className={`${abTestArticleStyles.down} deliveryRateForm`}>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <div className={abTestArticleStyles.articleButtons}>
        <ArticleListOptions
          article={currentArticle}
          abTest={abTest}
          isNotYetReflected={isNotYetReflected}
          isBodyUpdated={isBodyUpdated}
          duplicateArticle={duplicateArticle}
          updateArticleArchivedStatus={updateArticleArchivedStatus}
          handleArticlesListType={handleArticlesListType}
        />
        {!editorContentLoading &&
          <div style={isSaveDraftRequesting ? { opacity: "0.5", pointerEvents: "none" } : {}}>
            <ArticleReflection
              article={currentArticle}
              isLoading={isLoading}
              isNotYetReflected={isNotYetReflected}
              isBodyUpdated={isBodyUpdated}
              isFolderInspectable={isFolderInspectable}
              hasInspectionAuthority={hasInspectionAuthority}
              reflectionArticleBody={reflectionArticleBody}
              updateSelectedArticleInspectionRequest={(request) => updateSelectedArticleInspectionRequest(request)}
            />
          </div>
        }
      </div>
      <div className={abTestArticleStyles.previewIframeWrapper}>
        <PreviewIframe
        >
          <></>
        </PreviewIframe>
      </div>
    </div>
  )
}

const PreviewIframe = ({ children }) => {
  const [containerElement, setContainerElement] = useState(document.createElement('div'))

  const iframeRef = useRef()

  const setFolderFormIframeHeight = (e) => {
    if (e.origin === window.location.origin) {
      let forms = e.target.document.body.querySelectorAll(`[data-sb-form-uid="${e.data['uid']}"]`)

      Array.from(forms).forEach((form, i) => {
        let height = e.data['height']
        form.querySelector('iframe').height = height
      });
    }
  }

  const setMessageEvent = () => {
    iframeRef.current.contentWindow.addEventListener('message', setFolderFormIframeHeight, false)
  }

  return (
      <iframe id={'articleListPreview'} onLoad={setMessageEvent} ref={iframeRef} className={abTestArticleStyles.articleListPreview}>
        {ReactDOM.createPortal(Children.only(children), containerElement)}
      </iframe>
    )
}

const ActiveComponent = ({
  article,
  parentArticle,
  articleListType,
  selectArticleUids,
  currentArticle,
  rateFormRef,
  isLoading,
  editorContentLoading,
  isSaveDraftRequesting,
  isReflectParentArticleFunnelStep,
  abTest,
  handleSelectArticleUids,
  setArticle,
  upRateValue,
  downRateValue,
  updateArticleMemo,
  reflectionArticleBody,
  displayPreview,
  updateDeliveryRate,
  isFolderInspectable,
  hasInspectionAuthority,
  updateSelectedArticleInspectionRequest,
  duplicateArticle,
  handleArticlesListType,
  updateArticleArchivedStatus,
}) => {
  let delayUpdate = debounce(updateArticleMemo, 500)
  let delayRateUpdate = debounce(updateDeliveryRate, 500)

  const isNotYetReflected = currentArticle.reflected_at === null && currentArticle.body_updated_at !== null;
  const isBodyUpdated     =  currentArticle.reflected_at !== null &&  currentArticle.body_updated_at > currentArticle.reflected_at;

return  (
    <div data-test={"ArticleList-CurrentArticle"} className={`${abTestArticleStyles.abTestArticle} ${currentArticle.uid === parentArticle.uid && abTestArticleStyles.active}`}>
      <div className={abTestArticleStyles.abTestArticleContent}>
        {articleListType === 'selectUnArchivedArticles' ?
          <input data-test={"ArticleList-ArticleCheckBox"} type="checkbox" value={article.uid} className={'articleCheckbox'} checked={selectArticleUids.includes(article.uid)} onChange={(e) => handleSelectArticleUids(e)}/>
          :
          <>
            {(isBodyUpdated || isNotYetReflected) || isReflectParentArticleFunnelStep ?
              <img alt="" src={versionOrangeIcon}/>
              :
              <img alt="" src={versionGrayBlackIcon}/>
            }
          </>
        }
        <div className={abTestArticleStyles.abTestArticleMemo}>
          <input
            data-test="ArticleList-InputMemo"
            type="text"
            defaultValue={article.memo}
            name='memo'
            onChange={(e) => delayUpdate(e.target.value)}
            />
        </div>
        <div className={`${abTestArticleStyles.abTestDeliveryRateFormWrapper} deliveryRateForm`}>
          <input
            data-test={'ArticleList-DeriveryRateForm'}
            ref={rateFormRef}
            type='number'
            min={0}
            className={`${abTestArticleStyles.abTestDeliveryRateForm} deliveryRateForm`}
            defaultValue={article.ab_test_article.rate}
            onChange={(e) => delayRateUpdate(e.target.value, rateFormRef,  article.ab_test_article.id)}
            />
            <div className={`${abTestArticleStyles.spinButtons} deliveryRateForm`}>
              <div data-test={"ArticleList-DeriveryUpRateForm"} className={`${abTestArticleStyles.spinButtonArrow} ${abTestArticleStyles.spinButtonUp} deliveryRateForm`}
                onClick={() => upRateValue(article.ab_test_article.id)}>
                <div className={`${abTestArticleStyles.up} deliveryRateForm`}>
                </div>
              </div>
              <div data-test={"ArticleList-DeriveryDownRateForm"} className={`${abTestArticleStyles.spinButtonArrow} ${abTestArticleStyles.spinButtonDown} deliveryRateForm`}
                onClick={() => downRateValue(article.ab_test_article.id)}>
                <div className={`${abTestArticleStyles.down} deliveryRateForm`}>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div className={abTestArticleStyles.articleButtons}>
        <ArticleListOptions
          article={currentArticle}
          abTest={abTest}
          isNotYetReflected={isNotYetReflected}
          isBodyUpdated={isBodyUpdated}
          duplicateArticle={duplicateArticle}
          updateArticleArchivedStatus={updateArticleArchivedStatus}
          handleArticlesListType={handleArticlesListType}
        />
        {!editorContentLoading &&
          <div style={isSaveDraftRequesting ? { opacity: "0.5", pointerEvents: "none" } : {}}>
            <ArticleReflection
              article={currentArticle}
              isLoading={isLoading}
              isNotYetReflected={isNotYetReflected}
              isBodyUpdated={isBodyUpdated}
              isFolderInspectable={isFolderInspectable}
              hasInspectionAuthority={hasInspectionAuthority}
              reflectionArticleBody={reflectionArticleBody}
              updateSelectedArticleInspectionRequest={(request) => updateSelectedArticleInspectionRequest(request)}
            />
          </div>
        }
      </div>
    </div>
  )
}

const NormalComponent = ({
  article,
  articleListType,
  rateFormRef,
  setArticle,
  selectArticleUids,
  handleSelectArticleUids,
  upRateValue,
  downRateValue,
  updateDeliveryRate,
}) => {
  const { t } = useTranslation();
  // NOTE: funnel_stepを持っているarticleは配列にはあるが表示させない
  if (article.funnel_step) return null
  // NOTE: FunnelStepも含めて更新がされているがどうか
  const isReflectParentArticleFunnelStep = article && article.funnel_steps.filter(step => (step.article.reflected_at === null && step.article.body_updated_at !== null) || (step.article.reflected_at && step.article.body_updated_at > step.article.reflected_at)).length > 0
  const isNotYetReflected = article.reflected_at === null && article.body_updated_at !== null;
  const isBodyUpdated     =  article.reflected_at !== null &&  article.body_updated_at > article.reflected_at;
  let delayRateUpdate = debounce(updateDeliveryRate, 500)
  return (
    <div onClick={(e, articleUid) => setArticle(e, article)} className={`${abTestArticleStyles.abTestArticle} ${(isBodyUpdated || isNotYetReflected) || isReflectParentArticleFunnelStep ? abTestArticleStyles.notReflect : ''}`}>
      <div data-test={"ArticleList-Article"}  className={'articleListAnchor'} key={article.id}  >
        <div className={abTestArticleStyles.abTestArticleContent}>
          {articleListType === 'selectUnArchivedArticles' ?
            <input data-test={"ArticleList-ArticleCheckBox"} type="checkbox" value={article.uid} className={'articleCheckbox'} checked={selectArticleUids.includes(article.uid)} onChange={(e) => handleSelectArticleUids(e)}/>
            :
            <>
              {(isBodyUpdated || isNotYetReflected) || isReflectParentArticleFunnelStep ?
                <img alt="" src={versionOrangeIcon}/>
                :
                <img alt="" src={versionGrayBlackIcon}/>
              }
            </>
          }
          <div className={abTestArticleStyles.abTestArticleMemo}>
            <span data-test={'ArticleList-Memo'}>{article.memo}</span>
            {(isBodyUpdated || isNotYetReflected) || isReflectParentArticleFunnelStep ?
               <div data-test={'ArticleList-NotReflectDescription'} className={abTestArticleStyles.notReflectDescription}>{t("変更が反映されていません")}</div>
               :
               ''
            }
          </div>
            <div className={`${abTestArticleStyles.abTestDeliveryRateFormWrapper} deliveryRateForm`}>
              <input
                data-test={'ArticleList-DeriveryRateForm'}
                ref={rateFormRef}
                type='number'
                min={0}
                className={`${abTestArticleStyles.abTestDeliveryRateForm} deliveryRateForm`}
                defaultValue={article.ab_test_article.rate}
                onChange={(e) => delayRateUpdate(e.target.value, rateFormRef,  article.ab_test_article.id)}
              />
                <div className={`${abTestArticleStyles.spinButtons} deliveryRateForm`}>
                  <div data-test={"ArticleList-DeriveryUpRateForm"}  className={`${abTestArticleStyles.spinButtonArrow} ${abTestArticleStyles.spinButtonUp} deliveryRateForm`}
                    onClick={() => upRateValue(article.ab_test_article.id)}>
                    <div className={` ${abTestArticleStyles.up} deliveryRateForm`}>
                    </div>
                  </div>
                  <div data-test={"ArticleList-DeriveryDownRateForm"}  className={`${abTestArticleStyles.spinButtonArrow} ${abTestArticleStyles.spinButtonDown} deliveryRateForm`}
                    onClick={() => downRateValue(article.ab_test_article.id)}>
                    <div className={`${abTestArticleStyles.down} deliveryRateForm`}>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

UnArchivedArticleListItem.propTypes = {
  article: PropTypes.object.isRequired,
  abTest: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  editorContentLoading: PropTypes.bool.isRequired,
  selectArticleUids: PropTypes.array.isRequired,
  setArticle: PropTypes.func.isRequired,
  updateArticleMemo: PropTypes.func.isRequired,
  currentArticle: PropTypes.any.isRequired,
  reflectionArticleBody: PropTypes.func.isRequired,
  updateDeliveryRate: PropTypes.func.isRequired,
  updateArticleBody: PropTypes.func.isRequired,
  handleSelectArticleUids: PropTypes.func.isRequired,
  duplicateArticle: PropTypes.func.isRequired,
  updateArticleArchivedStatus: PropTypes.func.isRequired,
  handleArticlesListType: PropTypes.func.isRequired,
}

export default UnArchivedArticleListItem
