import { deleteRequest, get, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import {
  MicrosoftAccountApiOauthRequest,
  MicrosoftAccountApiOauthResponse,
  MicrosoftAccountApiCreateAccountRequest,
  MicrosoftAccountApiCreateAccountResponse,
  MicrosoftAccountApiDestroyAccountRequest,
  MicrosoftAccountApiDestroyAccountResponse,
  MicrosoftAccountApiFetchAccountsResponse,
} from "./type";

const fetchAccounts = () =>
  get<{}, MicrosoftAccountApiFetchAccountsResponse>(
    {},
    `${DEFAULT_BASE_URL}/teams/ad_accounts/microsoft_accounts`,
  );

// フォームから直接リクエストせず、
// oauth でリダイレクトバックされた時に得られる code と state を使ってリクエストする。
const createAccount = (params: MicrosoftAccountApiCreateAccountRequest) =>
  post<MicrosoftAccountApiCreateAccountRequest, MicrosoftAccountApiCreateAccountResponse>(
    params,
    `${DEFAULT_BASE_URL}/teams/ad_accounts/microsoft_accounts`,
  );

const destroyAccount = (params: MicrosoftAccountApiDestroyAccountRequest) =>
  deleteRequest<
    MicrosoftAccountApiDestroyAccountRequest,
    MicrosoftAccountApiDestroyAccountResponse
  >(params, `${DEFAULT_BASE_URL}/teams/ad_accounts/microsoft_accounts/${params.accountId}`);

// フォームから入力した値を使って oauth 用リダイレクト url を取得する。
const oauth = async (params: MicrosoftAccountApiOauthRequest) =>
  post<MicrosoftAccountApiOauthRequest, MicrosoftAccountApiOauthResponse>(
    params,
    `${DEFAULT_BASE_URL}/teams/ad_accounts/microsoft_accounts/oauth_url`,
  );

export const MicrosoftAccountApi = {
  fetchAccounts,
  createAccount,
  destroyAccount,
  oauth,
} as const;
