import axios from "axios";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderInspectionSettingResponse = {
  id: number;
  folder_id: number;
  enabled: boolean;
} | null;

class FolderInspectionSettingApi extends ApiBase {
  static fetch(folderUid: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderInspectionSettingResponse>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/inspection_setting`;
      axios
        .get(path, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static update(folderUid: string, params: any) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/inspection_setting`;
      axios
        .patch(path, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderInspectionSettingApi;
