import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class UserSessionApi extends ApiBase {

  static create(email, password) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/users/session`;
      axios.post(path, { email: email, password: password }, { withCredentials: true })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  static update(params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/users/session`;
      axios.put(path, params, { withCredentials: true })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

  static destroy() {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/users/session`;
      axios.delete(path, { withCredentials: true })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
    });
  }

}

export default UserSessionApi;
