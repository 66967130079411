import { useCallback, useState } from "react";
import { CustomerPortalSessionResponse, StripeCustomerPortalSessionApi } from "api";

export const useCreateStripeCustomerPortalSession = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(async () => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      const response: CustomerPortalSessionResponse =
        await StripeCustomerPortalSessionApi.createSession();
      return response;
    } catch (e) {
      setError(e);
      return null;
    } finally {
      setLoading(false);
    }
  }, [loading]);

  return [handle, loading, error] as const;
};
