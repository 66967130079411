import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import { ColorPicker } from "components";
import i18next from "i18next";
import editPanelStyles from "stylesheets/components/forms/panels/edit.module.scss";
import inputTextStyles from "stylesheets/components/partials/inputText.module.scss";
import listStyles from "stylesheets/components/partials/list.module.scss";
import editorToolbarStyles from "stylesheets/components/quillEditor/editorToolbar.module.scss";
import { SubmitButtonObject } from "utils/convert-html-to-object/type";
import { SubmitButtonEditProps } from "./type";

// constsにまとめる
const colorKeyMap = {
  textColor: i18next.t("ボタンテキスト"),
  backgroundColor: i18next.t("ボタン背景"),
} as const;

export const SubmitButtonEdit = ({
  activeBlockId,
  blocks,
  finishEdit,
  updateBlocks,
  isConfirmationPageAttached,
}: SubmitButtonEditProps) => {
  const { t } = useTranslation();
  const activeBlockOptions = blocks.find((block) => block.id === activeBlockId)?.options;
  const [options, setOptions] = useState<Partial<SubmitButtonObject["options"]>>(
    activeBlockOptions ? (activeBlockOptions as SubmitButtonObject["options"]) : {}
  );

  useEffect(() => {
    //同じ種類のblockを選択した時に情報が切り替わらないのでuseEffectで切り替える
    const opt = blocks.find((block) => block.id === activeBlockId)?.options;
    opt && setOptions({ ...(opt as SubmitButtonObject["options"]) });
  }, [activeBlockId]);

  const labelName = t("送信ボタン");

  const updateOptions = (key: string, value: string) => {
    const updatedOptions = { ...options, [key]: value };
    setOptions(updatedOptions);
    return updateBlocks(updatedOptions as SubmitButtonObject["options"]);
  };

  const updateColor = (color: string, colorProperty: string | undefined) => {
    if (!colorProperty) return;
    updateOptions(colorProperty, color);
  };

  const colorOptionList = Object.keys(colorKeyMap).map((_key) => {
    const key = _key as keyof typeof colorKeyMap;
    return (
      <div key={key}>
        <ColorPicker
          defaultColor={options[key]?.toString() || ""}
          colorProperty={key}
          onAppyColor={updateColor}
        >
          <li className={editPanelStyles.flexLeftWrapper}>
            <div
              data-test="ColorPicker-BtnColor"
              className={`${editorToolbarStyles.btnFontColor}`}
            ></div>
            <label>{colorKeyMap[key]}</label>
          </li>
        </ColorPicker>
      </div>
    );
  });

  return (
    <div className={editPanelStyles.panel}>
      <div className={editPanelStyles.header}>
        <div className={editPanelStyles.title}>{t("を編集", { value: labelName })}</div>
        <button onClick={finishEdit}>{t("完了")}</button>
      </div>
      <div className={editPanelStyles.heading}>{t("ブロック設定")}</div>
      <div className={editPanelStyles.contents}>
        <ul className={`${listStyles.unstyled} ${editPanelStyles.stackList}`}>
          <li key="blockType">
            <div className={editPanelStyles.heading}>{t("ブロックタイプ")}</div>
            <div className={editPanelStyles.blockType}>{labelName}</div>
          </li>
          <li key="text">
            <label className={editPanelStyles.heading}>{t("ボタンテキスト")}</label>
            {isConfirmationPageAttached ? (
              <Tooltip
                placement="bottom"
                arrow
                title="基本設定の入力内容を確認するがONになっている場合は、テキストが固定になります"
              >
                <input
                  type="text"
                  disabled={isConfirmationPageAttached}
                  value={t("を確認する", { value: t("入力内容") }) || ""}
                  className={`${inputTextStyles.inputText} ${inputTextStyles.full} ${editPanelStyles.inputText}`}
                />
              </Tooltip>
            ) : (
              <input
                type="text"
                value={options.text}
                onChange={(e) => updateOptions("text", e.target.value)}
                className={`${inputTextStyles.inputText} ${inputTextStyles.full} ${editPanelStyles.inputText}`}
              />
            )}
          </li>
        </ul>
        <div className={editPanelStyles.heading}>{t("スタイル")}</div>
        <ul className={`${listStyles.unstyled} ${editPanelStyles.partitionList}`}>
          {colorOptionList}
        </ul>
        <div className={editPanelStyles.heading}>{t("角丸調整")}</div>
        <ul className={`${listStyles.unstyled} ${editPanelStyles.partitionList}`}>
          <li>
            <input
              type="number"
              value={options.borderRadius}
              onChange={(e) => updateOptions("borderRadius", e.target.value)}
              className={`${inputTextStyles.inputText} ${inputTextStyles.full} ${editPanelStyles.inputText}`}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
