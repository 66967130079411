import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class FolderGroupFolderMoveApi extends ApiBase {
  static update(folderGroupId, folderId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.patch(`${baseUrl}/folder_groups/${folderGroupId}/folders/${folderId}/moves`, undefined, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default FolderGroupFolderMoveApi;
