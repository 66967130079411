import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class UserConfirmationCodeApi extends ApiBase {
  static create(params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/users/confirmation/code`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default UserConfirmationCodeApi;
