import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { ArchiveIcon, DuplicateIcon, OptionFilledIcon, Theme } from "components";
import { AbTestProps, ArticleProps } from "domains";
import DuplicateModal from "javascripts/components/Editor/AbTest/DuplicateModal";
import DuplicateToOtherModal from "javascripts/components/Editor/AbTest/DuplicateToOtherModal";

type ArticleListOptionsProps = {
  article: ArticleProps;
  abTest: AbTestProps;
  isNotYetReflected: boolean;
  isBodyUpdated: boolean;
  duplicateArticle: (article: ArticleProps, params: object) => void;
  updateArticleArchivedStatus: (abTestUid: string, isArchived: boolean, articleUid: string) => void;
  handleArticlesListType: (type: string) => void;
};

export const ArticleListOptions: React.FC<ArticleListOptionsProps> = ({
  article,
  abTest,
  isBodyUpdated,
  isNotYetReflected,
  duplicateArticle,
  updateArticleArchivedStatus,
  handleArticlesListType,
}) => {
  const { t } = useTranslation();
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };

  const mode = isBodyUpdated || isNotYetReflected ? "dark" : "light";
  return (
    <>
      <ButtonBase
        sx={{
          width: "30px",
          height: "30px",
          backgroundColor: "#ececec",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={handleClick}
      >
        <Theme mode={mode}>
          <OptionFilledIcon
            width={"18px"}
            height={"18px"}
            color={"primary"}
          />
        </Theme>
      </ButtonBase>
      <Theme mode="dark">
        <Menu
          anchorEl={anchorElement}
          open={open}
          onClose={handleClose}
          MenuListProps={{ sx: { backgroundColor: "background.primary" } }}
        >
          <MenuItem sx={{ p: 1.5 }}>
            <ListItemIcon>
              <DuplicateIcon
                width={"25px"}
                height={"25px"}
                color={"white"}
              />
            </ListItemIcon>
            <ListItemText>
              <DuplicateModal
                article={article}
                duplicateArticle={duplicateArticle}
              />
            </ListItemText>
          </MenuItem>
          <MenuItem sx={{ p: 1.5 }}>
            <ListItemIcon>
              <DuplicateIcon
                width={"25px"}
                height={"25px"}
                color={"white"}
              />
            </ListItemIcon>
            <ListItemText>
              {abTest && article && (
                <DuplicateToOtherModal
                  article={article}
                  abTest={abTest}
                  duplicateArticle={duplicateArticle}
                />
              )}
            </ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ p: 1.5, color: "error.main" }}
            onClick={() => updateArticleArchivedStatus(abTest.uid, true, article.uid)}
          >
            <ListItemIcon>
              <ArchiveIcon
                width={"25px"}
                height={"25px"}
                color={"error"}
              />
            </ListItemIcon>
            <ListItemText>{t("アーカイブする")}</ListItemText>
          </MenuItem>
          <MenuItem
            sx={{ p: 1.5, color: "error.main" }}
            onClick={() => handleArticlesListType("selectUnArchivedArticles")}
          >
            <ListItemIcon>
              <ArchiveIcon
                width={"25px"}
                height={"25px"}
                color={"error"}
              />
            </ListItemIcon>
            <ListItemText>{t("選択してアーカイブする")}</ListItemText>
          </MenuItem>
        </Menu>
      </Theme>
    </>
  );
};
