import i18next from "i18next";

export const FORM_NAME_INPUT = {
  name: "name",
  label: i18next.t("名前~.人"),
  errorMessage: i18next.t("を入力してください", { value: i18next.t("名前~.人") }),
  sx: { mb: 2 },
  fullWidth: true,
  required: true,
};

export const FORM_COMPANY_NAME_INPUT = {
  name: "companyName",
  label: i18next.t("会社名"),
  errorMessage: i18next.t("を入力してください", { value: i18next.t("会社名") }),
  sx: { mb: 2 },
  fullWidth: true,
  required: true,
};

export const FORM_EMAIL_INPUT = {
  name: "email",
  label: i18next.t("メールアドレス"),
  errorMessage: i18next.t("を入力してください", { value: i18next.t("メールアドレス") }),
  disabled: false,
  required: true,
  sx: { mb: 2 },
  fullWidth: true,
};

export const FORM_PASSWORD_INPUT = {
  name: "password",
  label: i18next.t("パスワード"),
  errorMessage: i18next.t("を入力してください", { value: i18next.t("パスワード") }),
  sx: { mb: 2 },
  fullWidth: true,
  required: true,
  pattern: {
    value:
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/,
    message: "パスワードの設定条件を満たしていません",
  },
};
