import React from "react";

type Props = {
  width: string;
  height: string;
};

export const PopinIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-88.000000, -103.000000)"
          fill="#1F3B95"
        >
          <g transform="translate(88.272759, 103.017000)">
            <path d="M3.32208066,0.175433856 L8.96974443,3.81696955 C9.12775605,3.91885326 9.32956482,3.92348504 9.49208433,3.82895794 L15.4550456,0.360688501 C15.6181461,0.265823467 15.8207595,0.27085228 15.978953,0.373691752 L17.8097176,1.563847 C18.0412383,1.71435545 18.1069117,2.02405123 17.9564032,2.2555719 C17.9183108,2.31416793 17.8683571,2.36412729 17.8097654,2.40222645 L9.49980874,7.80576345 C9.33407559,7.91353118 9.12040708,7.91353118 8.95467393,7.80576345 L0.644678849,2.40220147 C0.413175331,2.25166665 0.347537231,1.94196338 0.498072051,1.71045986 C0.536166209,1.65187586 0.586117507,1.60192754 0.64470377,1.56383686 L2.77858605,0.176463564 C2.94373167,0.0690918348 3.15652937,0.0686886672 3.32208066,0.175433856 Z"></path>
            <path
              d="M3.32208066,11.1754339 L8.96974443,14.8169695 C9.12775605,14.9188533 9.32956482,14.923485 9.49208433,14.8289579 L15.4550456,11.3606885 C15.6181461,11.2658235 15.8207595,11.2708523 15.978953,11.3736918 L17.8097176,12.563847 C18.0412383,12.7143554 18.1069117,13.0240512 17.9564032,13.2555719 C17.9183108,13.3141679 17.8683571,13.3641273 17.8097654,13.4022265 L9.49980874,18.8057635 C9.33407559,18.9135312 9.12040708,18.9135312 8.95467393,18.8057635 L0.644678849,13.4022015 C0.413175331,13.2516666 0.347537231,12.9419634 0.498072051,12.7104599 C0.536166209,12.6518759 0.586117507,12.6019275 0.64470377,12.5638369 L2.77858605,11.1764636 C2.94373167,11.0690918 3.15652937,11.0686887 3.32208066,11.1754339 Z"
              transform="translate(9.227241, 14.991500) scale(1, -1) translate(-9.227241, -14.991500) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
