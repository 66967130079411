import axios from "axios";
import { ArticleProps } from "domains";
import ApiBase from "./apiBase";

type FetchArticlesResponse = ArticleProps[];
type FetchArticleResponse = ArticleProps;

class ArticleApi extends ApiBase {
  static fetchArticles(pathname: string, teamUid?: string | null) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchArticlesResponse>(function (resolve, reject) {
      axios
        .get(baseUrl + pathname + `${teamUid ? `?team_uid=${teamUid}` : ""}`, {
          withCredentials: true,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }

  static fetchArticle(pathname: string, articleUid: string, teamUid?: string | null) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchArticleResponse>(function (resolve, reject) {
      axios
        .get(baseUrl + pathname + "/" + articleUid + `${teamUid ? `?team_uid=${teamUid}` : ""}`, {
          withCredentials: true,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static createNewArticle(pathname: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .post(baseUrl + "/" + pathname + "/", undefined, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static updateArticleMemo(articleUid: string, memo: string) {
    let baseUrl = this.defaultBaseUrl();
    ///api/v1/ab_tests/:ab_test_uid/articles/:uid
    return new Promise<any>(function (resolve, reject) {
      axios
        .patch(
          baseUrl + "/articles/" + articleUid,
          { articles: { memo: memo } },
          { withCredentials: true }
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static update(articleUid: string, params: any) {
    let baseUrl = this.defaultBaseUrl();
    ///api/v1/ab_tests/:ab_test_uid/articles/:uid
    return new Promise<any>(function (resolve, reject) {
      axios
        .patch(baseUrl + "/articles/" + articleUid, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static changeEditingAuthority(pathname: string, articleUid: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .patch(
          baseUrl + "/articles/" + articleUid + "/writers",
          { uid: articleUid },
          { withCredentials: true }
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static capture(articleUid: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .post(baseUrl + "/articles/" + articleUid + "/capture", {}, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default ArticleApi;
