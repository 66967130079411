import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CustomTabs, MediaIcon, SearchField, Theme } from "components";
import { AbTestProps, FolderProps } from "domains";
import { debounce } from "javascripts/utils";

type BeyondPageListByStatusProps = {
  folder: Pick<FolderProps, "uid"> | null;
  currentAbTest: Pick<AbTestProps, "id" | "uid" | "title" | "media_id" | "ad_status">;
  abTests: Pick<AbTestProps, "id" | "uid" | "title" | "media_id" | "ad_status">[];
};

type AbTestStatus = {
  value: string;
  label: string;
};

type AbTestsPerStatus = {
  [key in AbTestStatus["value"]]: BeyondPageListByStatusProps["abTests"];
};

// FIXME
// eslint-disable-next-line react/display-name
export const BeyondPageListByStatus: React.FC<BeyondPageListByStatusProps> = React.memo(
  ({ folder, currentAbTest, abTests }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [abTestTitleQuery, setAbTestTitleQuery] = useState<string>("");
    const [activeAbTestStatus, setActiveAbTestStatus] = useState<string>("except_finished");
    const abTestStatuses: AbTestStatus[] = [
      { label: t("終了以外"), value: "except_finished" },
      { label: t("準備中"), value: "prepared" },
      { label: t("未配信"), value: "undelivered" },
      { label: t("配信中"), value: "delivered" },
      { label: t("停止中"), value: "stopping" },
      { label: t("終了"), value: "finished" },
    ];

    const delayedUpdateAbTestTitleQuery = useCallback(
      debounce(
        (event: React.ChangeEvent<HTMLInputElement>) => setAbTestTitleQuery(event.target.value),
        500
      ),
      []
    );

    const filterdAbTests = useMemo(() => {
      return abTests.filter((abTest) => {
        return String(abTest.title).toLowerCase().indexOf(abTestTitleQuery.toLowerCase()) !== -1;
      });
    }, [abTests, abTestTitleQuery]);

    const abTestsPerStatus: AbTestsPerStatus = abTestStatuses.reduce(
      (result: AbTestsPerStatus, status: AbTestStatus) => {
        result[status.value] = [];
        return result;
      },
      {}
    );

    filterdAbTests.forEach((abTest) => {
      abTestsPerStatus[abTest.ad_status].push(abTest);
      if (abTest.ad_status !== "finished") {
        abTestsPerStatus["except_finished"].push(abTest);
      }
    });

    const redirectToSettingPage = (abTest: { uid: string }) => {
      window.location.href = `/folders/${folder?.uid}/ab_tests/${abTest.uid}/edit`;
    };

    const abTestListItems = abTestsPerStatus[activeAbTestStatus].map((abTest) => {
      return (
        <ListItemButton
          key={abTest.id}
          selected={currentAbTest.uid === abTest.uid}
          onClick={() => redirectToSettingPage(abTest)}
          sx={{ borderBottom: `1px solid ${theme.palette.secondary.main}` }}
        >
          <MediaIcon
            mediaId={abTest.media_id}
            width={"15px"}
            height={"15px"}
          />
          <ListItemText
            primary={abTest.title}
            primaryTypographyProps={{
              ml: 1,
              fontSize: "14px",
            }}
          />
        </ListItemButton>
      );
    });

    return (
      <Theme>
        <Box>
          <CustomTabs
            shadowPosition="top"
            tabAttributes={abTestStatuses}
            backgroundColor="primary"
            tabsProps={{
              orientation: "horizontal",
            }}
            defaultValue={activeAbTestStatus}
            onValueChange={setActiveAbTestStatus}
          />
          <Box sx={{ p: 1 }}>
            <SearchField
              fullWidth
              placeholder={t("beyondページ検索") || ""}
              InputProps={{
                sx: {
                  borderRadius: "20px",
                  fontSize: "13px",
                  height: "40px",
                },
              }}
              onChange={delayedUpdateAbTestTitleQuery}
            />
          </Box>
          <List sx={{ p: 0 }}>{abTestListItems}</List>
        </Box>
      </Theme>
    );
  }
);
