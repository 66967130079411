import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";
import { AbTestProps, FolderProps } from "domains";
import { useLogging } from "hooks";
import AbTestApi from "javascripts/api/abTest";
import AbTestDuplicationApi from "javascripts/api/abTest/duplication";
import AbTestUrlSearchApi from "javascripts/api/abTest/urlSearch";
import FolderAbTestApi from "javascripts/api/folders/abTest";
import FolderAbTestMoveApi from "javascripts/api/folders/abTests/move";

export const abTestListState = atom<AbTestProps[]>({
  key: "abTestListState",
  default: [],
});

export const abTestFolderState = atom<FolderProps | undefined>({
  key: "abTestFolderState",
  default: undefined,
});

export const useFolderAbTestList = (folder: FolderProps) => {
  const [abTestList, setAbTestList] = useRecoilState(abTestListState);
  // 成功・失敗に関わらず、abTestList の fetch 完了と同時に変更されるようにする。
  const [abTestFolder, setAbTestFolder] = useRecoilState(abTestFolderState);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    setAbTestFolder(undefined);

    FolderAbTestApi.fetchAbTests(folder.uid)
      .then((data) => {
        setAbTestList(data.ab_tests);
      })
      .finally(() => {
        setLoading(false);
        setAbTestFolder(folder);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder.uid]);

  return [abTestList, setAbTestList, abTestFolder, loading] as const;
};

export const useCreateAbTest = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const { sendErrorLog } = useLogging();

  const handle = useCallback(async (folderUid: string, params: {}) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      const response = await FolderAbTestApi.createAbTest(folderUid, params);
      return response;
    } catch (e) {
      setError(e);
      sendErrorLog({
        error: e,
        message: "Create Ab Test Error",
      });
      return null;
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, error, loading] as const;
};

export const useEditAbTest = () => {
  const [abTestList, setAbTestList] = useRecoilState(abTestListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const { sendErrorLog } = useLogging();

  const handle = useCallback(
    async (abTestUid: string, params: {}) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);
      try {
        const response = await AbTestApi.updateAbTest(`/ab_tests/${abTestUid}`, params);
        const newList = abTestList.map((abTest: AbTestProps) => {
          if (response.id === abTest.id) {
            return { ...abTest, ...response };
          } else {
            return { ...abTest };
          }
        });
        setAbTestList(newList);
        return response;
      } catch (e) {
        setError(e);
        sendErrorLog({
          error: e,
          message: "Create Ab Test Error",
        });
        return null;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [abTestList, loading],
  );

  return [handle, loading, error] as const;
};

export type HandleDeleteAbTestProps = (abTestUid: string) => void;
export const useDeleteAbTest = () => {
  const { t } = useTranslation();
  const [abTestList, setAbTestList] = useRecoilState(abTestListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [_error, setError] = useState<any>();

  const handle: HandleDeleteAbTestProps = useCallback(
    async (abTestUid) => {
      if (
        !window.confirm(
          t("本当に削除しますか？削除を実行すると後から元に戻すことはできません") || "",
        )
      ) {
        return;
      }

      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);
      try {
        await AbTestApi.destroyAbTest(abTestUid);
        const newList = abTestList.filter((abTest: AbTestProps) => abTest.uid != abTestUid);
        setAbTestList(newList);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    },
    [abTestList, loading, setAbTestList, t],
  );

  return [handle, loading] as const;
};

export type HandleMoveAbTestProps = (folderUid: string, params: {}) => void;
export const useMoveAbTest = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleMoveAbTestProps = useCallback(async (folderUid, params) => {
    if (loading) {
      return;
    }

    setError(undefined);
    setLoading(true);
    try {
      await FolderAbTestMoveApi.create(folderUid, params);
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, loading, error] as const;
};

type DuplicateAbTestParameterProps = {
  ab_test: {
    title?: string;
    uid: string;
    media_id?: number | string;
    link_type: string;
    archived: boolean;
    delivery_type: string;
    is_inherit_html_tag: boolean;
    is_inherit_redirect_page: boolean;
    is_inherit_exit_popup: boolean;
    is_inherit_funnel_step: boolean;
    folder_id?: number;
  };
};
export type HandleDuplicateAbTestProps = (
  abTestUid: string,
  params: DuplicateAbTestParameterProps,
) => void;
export const useDuplicateAbTest = () => {
  const [abTestList, setAbTestList] = useRecoilState(abTestListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleDuplicateAbTestProps = useCallback(
    async (abTestUid, params) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);
      try {
        const abTest: AbTestProps = await AbTestDuplicationApi.create(abTestUid, params);
        if (!params.ab_test.folder_id) {
          setAbTestList([abTest, ...abTestList]);
        }
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [abTestList, loading],
  );

  return [handle, loading, error] as const;
};

export const useSearchAbTestUrl = () => {
  const [loading, setLoading] = useState(false);
  const [abTest, setAbTest] = useState<AbTestProps>({} as AbTestProps);

  const handle = async (params: {}) => {
    try {
      setLoading(true);
      const response = await AbTestUrlSearchApi.fetch(params);
      setAbTest(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return [handle, abTest, setAbTest, loading] as const;
};

export const useFetchAbTest = () => {
  const [loading, setLoading] = useState(false);
  const [abTest, setAbTest] = useState<AbTestProps | undefined>(undefined);

  const handle = async (abTestUid: string) => {
    try {
      setLoading(true);
      const path = `/ab_tests/${abTestUid}`;
      const response = await AbTestApi.fetchAbTest(path);
      setAbTest(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return [handle, abTest, setAbTest, loading] as const;
};
