import { Link } from "@mui/material";
import { AnnouncementTooltip } from "components";
import { CustomSvgIconProps, useShadowStyles } from "hooks";
import { EditorNavIconLinkProps } from "./type";

export const EditorNavIconLink = ({
  theme,
  path,
  isSelected,
  IconComponent,
  iconWidth,
  iconHeight,
  trackId,
  announcementText,
}: EditorNavIconLinkProps) => {
  const { linkStyles, iconStyles } = generateStylesByTheme(theme, isSelected);

  return (
    <AnnouncementTooltip
      tooltipText={announcementText}
      placement="top"
    >
      <Link
        href={path}
        sx={linkStyles}
        data-trackid={trackId}
      >
        <IconComponent
          color={iconStyles.color}
          width={iconWidth}
          height={iconHeight}
        />
      </Link>
    </AnnouncementTooltip>
  );
};

const generateStylesByTheme = (
  theme: EditorNavIconLinkProps["theme"],
  isSelected: EditorNavIconLinkProps["isSelected"],
) => {
  let selectedBgColor: string;
  let shadowBgColor = "";
  let selectedIconColor: CustomSvgIconProps["color"];
  const unselectedIconColor: CustomSvgIconProps["color"] = "primary";

  switch (theme) {
    case "lightTheme":
      selectedBgColor = "background.secondary";
      shadowBgColor = "secondary";
      selectedIconColor = "common";
      break;
    case "darkTheme":
      selectedBgColor = "background.contrast";
      shadowBgColor = "contrast";
      selectedIconColor = "white";
      break;
    case "grayTheme":
      selectedBgColor = "background.default";
      shadowBgColor = "default";
      selectedIconColor = "common";
      break;
    default:
      throw new Error(`Unknown theme: ${theme}`);
  }

  const shadowStyles = useShadowStyles({
    position: "bottom",
    backgroundColor: shadowBgColor,
  });
  const selectedItemStyle = isSelected
    ? {
        ...shadowStyles,
        bgcolor: selectedBgColor,
        borderRadius: "20px 20px 0 0",
      }
    : {};

  const linkStyles = {
    width: "60px",
    height: "60px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...selectedItemStyle,
  };
  const iconStyles = {
    color: isSelected ? selectedIconColor : unselectedIconColor,
  };

  return { linkStyles, iconStyles };
};
