import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import {
  CreateFolderFormPhotoParams,
  CreateFolderFormPhotoResponse,
  FolderFormPhotoApi,
} from "api/folders/form/photo";
import { FolderFormPhotoProps } from "domains";
import { useLogging } from "hooks";
import FolderFormPhotoAuthenticationApi from "javascripts/api/folders/forms/photos/authentication";
import { compressImages } from "javascripts/utils";
import { removeItemAtIndex } from "utils";

export type FolderFormPhoto = {
  items: Array<FolderFormPhotoProps>;
  nextPage: number | null;
};

const folderFormPhotoListState = atom<FolderFormPhoto>({
  key: "folderFormPhotoListState",
  default: {
    items: [],
    nextPage: 1,
  },
});

export type HandleCheckAuthorityFolderFormPhotoProps = (
  folderUid: string,
  formUid: string,
) => Promise<boolean>;

export const useCheckAuthorityFolderFormPhoto = () => {
  const handle: HandleCheckAuthorityFolderFormPhotoProps = useCallback(
    async (folderUid, formUid) => {
      try {
        await FolderFormPhotoAuthenticationApi.checkAuthority(folderUid, formUid);
        return true;
      } catch (e) {
        return false;
      }
    },
    [],
  );

  return [handle] as const;
};

export type HandleUpdateFolderFormPhotoProps = (
  folderUid: string,
  formUid: string,
  photoId: number,
) => void;

export const useUpdateFolderFormPhoto = () => {
  const { sendErrorLog } = useLogging();
  const [folderFormPhotoList, setFolderFormPhotoList] = useRecoilState(folderFormPhotoListState);

  const handle: HandleUpdateFolderFormPhotoProps = useCallback(
    async (folderUid, formUid, photoId) => {
      try {
        const response = await FolderFormPhotoApi.update(folderUid, formUid, photoId, {
          folder_form_photo: { published: false },
        });
        const index = folderFormPhotoList.items.findIndex((item) => item.id === response.id);
        setFolderFormPhotoList({
          ...folderFormPhotoList,
          items: removeItemAtIndex(folderFormPhotoList.items, index),
        });
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to update folder form photo",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormPhotoList],
  );

  return [handle] as const;
};

export type HandleFetchFolderFormPhotoListProps = (
  folderUid: string,
  formUid: string,
  params: {
    page: number;
    search_range: string;
  },
) => void;
export const useFetchFolderFormPhotoList = () => {
  const { sendErrorLog } = useLogging();
  const [folderFormPhotoList, setFolderFormPhotoList] = useRecoilState(folderFormPhotoListState);

  const handle: HandleFetchFolderFormPhotoListProps = useCallback(
    async (folderUid, formUid, params) => {
      try {
        const items = params.page === 1 ? [] : folderFormPhotoList.items;
        const response = await FolderFormPhotoApi.fetch(folderUid, formUid, params);
        setFolderFormPhotoList({
          items: [...items, ...response.photos],
          nextPage: response.next_page,
        });
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to update folder form photo",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormPhotoList.items, setFolderFormPhotoList],
  );
  return [handle] as const;
};

export type HandleCreateFolderFormPhotoListProps = (
  folderUid: string,
  formUid: string,
  params: {
    event?: React.ChangeEvent<HTMLInputElement>;
    photo_id?: string;
  },
) => Promise<CreateFolderFormPhotoResponse | undefined>;
export const useCreateFolderFormPhoto = () => {
  const { sendErrorLog } = useLogging();
  const [folderFormPhotoList, setFolderFormPhotoList] = useRecoilState(folderFormPhotoListState);

  const handle: HandleCreateFolderFormPhotoListProps = useCallback(
    async (folderUid, formUid, params) => {
      try {
        // NOTE: FormDataは専用interfaceなので中身を定義するため拡張した型をあてる
        const photoParams = new FormData() as CreateFolderFormPhotoParams;
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty("event")) {
          if (params.event) {
            const files = await compressImages(params.event.target.files);
            photoParams.append("folder_form_photos[photo]", files[0]);
          }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty("photo_id")) {
          if (params.photo_id) {
            photoParams.append("folder_form_photos[photo_id]", params.photo_id);
          }
        }
        const response = await FolderFormPhotoApi.create(folderUid, formUid, photoParams);
        setFolderFormPhotoList({
          ...folderFormPhotoList,
          items: [response, ...folderFormPhotoList.items],
        });
        return response;
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to create folder form photo",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormPhotoList],
  );

  return [handle] as const;
};

export const useFolderFormPhotoList = (folderUid: string, formUid: string) => {
  const [folderFormPhotoList, setFolderFormPhotoList] = useRecoilState(folderFormPhotoListState);
  const [handle] = useFetchFolderFormPhotoList();

  useEffect(() => {
    handle(folderUid, formUid, { page: 1, search_range: "all" });
  }, []);

  return [folderFormPhotoList, setFolderFormPhotoList] as const;
};
