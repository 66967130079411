import { deleteRequest, get, patch, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import {
  CreateMemberApiResponse,
  DeleteMemberApiResponse,
  TeamMemberApiResponseProps,
  TeamMemberListApiResponse,
  UpdateMemberApiResponse,
} from "domains/team-member-attributes";

export const fetchMembers = async (): Promise<TeamMemberListApiResponse> =>
  get<{}, TeamMemberListApiResponse>({}, `${DEFAULT_BASE_URL}/teams/members`);

export const fetchMember = async (): Promise<TeamMemberApiResponseProps> =>
  get<{}, TeamMemberApiResponseProps>({}, `${DEFAULT_BASE_URL}/teams/member`);

// TODO: リクエストパラメーターの型を定義。呼び出し元を修正するときに対応
export const createMember = (params: any): Promise<CreateMemberApiResponse> =>
  post<any, CreateMemberApiResponse>(params, `${DEFAULT_BASE_URL}/teams/members`);

// TODO: リクエストパラメーターの型を定義。呼び出し元を修正するときに対応
export const updateMember = (memberUid: string, params: any): Promise<UpdateMemberApiResponse> =>
  patch<any, UpdateMemberApiResponse>(params, `${DEFAULT_BASE_URL}/teams/members/${memberUid}`);

export const destroyMember = (memberUid: string): Promise<DeleteMemberApiResponse> =>
  deleteRequest<{}, DeleteMemberApiResponse>({}, `${DEFAULT_BASE_URL}/teams/members/${memberUid}`);

export * as TeamMemberApi from "./member";
