/* eslint-disable @typescript-eslint/ban-ts-comment */
// TODO: componentになるfunctionをutilsで呼び出すのはよろしくない、utilsに置く

import { CSSProperties } from "react";
import branchIcon from "assets/images/branch.svg";
import bussinessIcon from "assets/images/bussiness.svg";
import buttonIcon from "assets/images/button.svg";
import calendarIcon from "assets/images/calendar.svg";
import checkIcon from "assets/images/check.svg";
import documentIcon from "assets/images/document.svg";
import headingIcon from "assets/images/heading2.svg";
import homeIcon from "assets/images/home.svg";
import identityIcon from "assets/images/identity.svg";
import linkIcon from "assets/images/link.svg";
import mailIcon from "assets/images/mail.svg";
import paragraphIcon from "assets/images/paragraph.svg";
import phoneIcon from "assets/images/phone.svg";
import singleTextIcon from "assets/images/single-text.svg";
import textareaIcon from "assets/images/textarea.svg";
import userIcon from "assets/images/user.svg";

import { generateUid } from "components/organisms/folder/form/edit/side-panel/build-panel";
import i18next from "i18next";
import { BLOCK_NAME_KEYS } from "./const";
import {
  AddressObject,
  BirthdayObject,
  ButtonObject,
  CalendarObject,
  CheckBoxObject,
  CommonBlock,
  ElementObject,
  EmailObject,
  EmployeeNumObject,
  FullNameObject,
  GenderObject,
  HeadingObject,
  HeadingParagraphObject,
  ImageAttributeValue,
  ImageObject,
  IndustryObject,
  InqueryObject,
  LinkObject,
  MediumObject,
  NameObject,
  OccupationObject,
  ParagraphObject,
  PhoneObject,
  PrefectureObject,
  RadioObject,
  SelectBoxObject,
  SubmitButtonObject,
  TermLinkObject,
  TermsObject,
  TextAreaObject,
  TextObject,
} from "./type";

export const convertHtmlToObject = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  const targetBlocks = div.querySelectorAll(`.blockWrapper`);

  const elementObjects: ElementObject[] = [];
  targetBlocks.forEach((block) => {
    const name = block.getAttribute("data-name");
    const object = convert(name as BLOCK_NAME_KEYS, block);
    if (!object) return;

    elementObjects.push(object);
  });

  return elementObjects;
};

const convert = (name: BLOCK_NAME_KEYS | null, block: Element) => {
  const wrapper = block.querySelector<HTMLElement>(`[class^=${name}Block]`);
  const labelWrapper = wrapper?.querySelector<HTMLElement>(`[class^=${name}Block__labelWrapper]`);
  const input = wrapper?.querySelector<HTMLInputElement | HTMLTextAreaElement>(
    `[class^=${name}Block__input]`
  );

  const label = labelWrapper ? labelWrapper.querySelector("label") : null;
  const commonBlocks: CommonBlock = { wrapper, labelWrapper, label, input };

  switch (name) {
    case "headingParagraph":
      return convertHeadingParagraph(name, block);
    case "submitButton":
      return convertSubmitButton(name, block);
    case "button":
      return convertButton(name, block);
    case "fullname":
      return convertFullname(name, commonBlocks);
    case "name":
      return convertName(name, commonBlocks);
    case "email":
      return convertEmail(name, commonBlocks);
    case "phoneNumber":
      return convertPhoneNumber(name, commonBlocks);
    case "inquery":
      return convertInquery(name, commonBlocks);
    case "gender":
      return convertGender(name, commonBlocks);
    case "birthday":
      return convertBirthday(name, commonBlocks);
    case "address":
      return convertAddress(name, commonBlocks);
    case "prefecture":
      return convertPrefecture(name, commonBlocks);
    case "occupation":
      return convertOccupation(name, commonBlocks);
    case "industry":
      return convertIndustry(name, commonBlocks);
    case "medium":
      return convertMedium(name, commonBlocks);
    case "employeeNum":
      return convertEmployeeNum(name, commonBlocks);
    case "text":
      return convertText(name, commonBlocks);
    case "textarea":
      return convertTextarea(name, commonBlocks);
    case "selectbox":
      return convertSelectbox(name, commonBlocks);
    case "radio":
      return convertRadio(name, commonBlocks);
    case "checkbox":
      return convertCheckbox(name, commonBlocks);
    case "heading":
      return convertHeading(name, commonBlocks);
    case "paragraph":
      return convertParagraph(name, commonBlocks);
    case "terms":
      return convertTerms(name, commonBlocks);
    case "termsLink":
      return convertTermsLink(name, commonBlocks);
    case "calendar":
      return convertCalendar(name, commonBlocks);
    case "image":
      return convertImage(name, commonBlocks);
    case "link":
      return convertLink(name, commonBlocks);
    default:
      return undefined;
  }
};

export const getStyleValue = (
  element: HTMLElement | null | undefined,
  styleName: string
): string | number | undefined => {
  // @ts-ignore  FIME: styleNameの型エラーが取れない
  if (element.style[styleName].match(/(.+)px/)) {
    // @ts-ignore
    return parseInt(element.style[styleName].match(/(.+)px/)[1]);
  }
  // @ts-ignore
  return element.style[styleName];
};

const castStringToBoolean = (value: string) => {
  return value.toLowerCase() === "true";
};

export const commonOption = (
  block: HTMLElement | null,
  labelWrapper: HTMLElement | null | undefined,
  name: string | null
) => {
  const span = labelWrapper?.querySelector(`span`);
  const note = block?.querySelector(`[class^=${name}Block__note]`);

  const showBadge = span ? true : false;
  const isRequired = span && span.classList.contains(`${name}Block__badgeRequired`);

  const option = {
    note: note ? note.innerHTML : "",
    isRequired: isRequired,
    showBadge: showBadge,
  };
  return option;
};

const convertSubmitButton = (name: string | null, block: Element) => {
  const button = block.querySelector<HTMLDivElement>(`[class^=${name}Block]`);
  const object: SubmitButtonObject = {
    id: generateUid(),
    name,
    type: "submitButton",
    options: {
      label: i18next.t("送信ボタン"),
      icon: buttonIcon,
      text: button?.innerText ?? "",
      textColor: getStyleValue(button, "color") as CSSProperties["textAlign"],
      backgroundColor: getStyleValue(button, "background"),
      borderRadius: getStyleValue(button, "borderRadius")
        ? getStyleValue(button, "borderRadius")
        : 4,
    },
  };
  return object;
};

const convertHeadingParagraph = (name: string | null, block: Element) => {
  const heading = block.querySelector<HTMLDivElement>(`[class^=${name}Block__heading]`);
  const paragraph = block.querySelector<HTMLDivElement>(`[class^=${name}Block__paragraph]`);

  const object: HeadingParagraphObject = {
    id: generateUid(),
    name,
    type: "headingParagraph",
    options: {
      heading: heading?.innerText,
      label: i18next.t("見出しと段落"),
      paragraph: paragraph?.innerHTML,
      headingFontSize: getStyleValue(heading, "fontSize"),
      paragraphFontSize: getStyleValue(paragraph, "fontSize"),
      textAlign: getStyleValue(heading, "textAlign") as CSSProperties["textAlign"],
      type: "custom",
      icon: headingIcon,
    },
  };
  return object;
};

const convertHeading = (name: string | null, commonBlocks: CommonBlock) => {
  const heading =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelector<HTMLElement>(`[class^=${name}Block__heading]`);

  const object: HeadingObject = {
    id: generateUid(),
    name: name,
    type: "heading",
    options: {
      label: i18next.t("見出し"),
      type: "custom",
      icon: headingIcon,
      heading: heading?.innerText,
      headingFontSize: getStyleValue(heading, "fontSize"),
      textAlign: getStyleValue(heading, "textAlign") as CSSProperties["textAlign"],
    },
  };
  return object;
};

const convertParagraph = (name: string | null, commonBlocks: CommonBlock) => {
  const paragraph =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelector<HTMLParagraphElement>(
      `[class^=${name}Block__paragraph]`
    );

  const object: ParagraphObject = {
    id: generateUid(),
    name: name,
    type: "paragraph",
    options: {
      label: i18next.t("段落"),
      type: "custom",
      icon: paragraphIcon,
      paragraph: paragraph?.innerHTML,
      paragraphFontSize: getStyleValue(paragraph, "fontSize"),
      textAlign: getStyleValue(paragraph, "textAlign") as CSSProperties["textAlign"],
    },
  };

  return object;
};

const convertText = (name: string | null, commonBlocks: CommonBlock) => {
  const customName =
    commonBlocks["input"] && commonBlocks["input"]?.name
      ? commonBlocks["input"].name.match(/folder_form\[(.*)\]/)
      : "";

  const object: TextObject = {
    id: generateUid(),
    name,
    type: "text",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "custom",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: singleTextIcon,
      placeholder: commonBlocks["input"]?.placeholder,
      customName: customName ? customName[1] : "",
    },
  };

  return object;
};

const convertTextarea = (name: string | null, commonBlocks: CommonBlock) => {
  const customName =
    commonBlocks["input"] && commonBlocks["input"].name
      ? commonBlocks["input"].name.match(/folder_form\[(.*)\]/)
      : "";

  const object: TextAreaObject = {
    id: generateUid(),
    name,
    type: "textarea",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "custom",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: textareaIcon,
      placeholder: commonBlocks["input"]?.placeholder,
      customName: customName ? customName[1] : "",
      rows: (commonBlocks["input"] as HTMLTextAreaElement).rows,
    },
  };

  return object;
};

const convertSelectbox = (name: string | null, commonBlocks: CommonBlock) => {
  const select = commonBlocks["wrapper"]?.querySelector<HTMLSelectElement>(
    `[class^=${name}Block__selectbox]`
  );
  const customName = select?.name.match(/folder_form\[(.*)\]/);
  const showOther = commonBlocks["wrapper"]?.querySelector(
    `input[name^="folder_form[${i18next.t("その他の詳細")}"]`
  )
    ? true
    : false;
  const options = commonBlocks["wrapper"]?.querySelectorAll(`option`) ?? [];
  const selectOptions = Array.from(options).flatMap((option, i) => {
    //「選択してください」は直置きしてあるのでスキップ
    if (i === 0) return [];
    if (option.getAttribute("data-is-other")) return [];
    return { label: option.innerText, value: option.innerText };
  });

  const object: SelectBoxObject = {
    id: generateUid(),
    name,
    type: "selectbox",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "custom",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: checkIcon,
      selectOptions,
      showOther,
      customName: customName ? customName[1] : "",
    },
  };

  return object;
};

const convertRadio = (name: string | null, commonBlocks: CommonBlock) => {
  const customName =
    commonBlocks["input"] && commonBlocks["input"].name
      ? commonBlocks["input"].name.match(/folder_form\[(.*)\]/)
      : "";
  const detail = commonBlocks["wrapper"]?.querySelector(
    `input[name^="folder_form[${i18next.t("その他の詳細")}"]`
  );
  const showOther = detail ? true : false;
  const lists =
    commonBlocks["wrapper"]?.querySelectorAll<HTMLLIElement>(`[class^=${name}Block__listItem]`) ??
    [];
  const column = commonBlocks["wrapper"]?.getAttribute("data-column") ?? undefined;
  const radioOptions = Array.from(lists).flatMap((list) => {
    if (list.getAttribute("data-is-other")) return [];
    return { label: list.innerText, value: list.innerText };
  });

  const object: RadioObject = {
    id: generateUid(),
    name,
    type: "radio",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "custom",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: userIcon,
      radioOptions,
      showOther,
      customName: customName ? customName[1] : "",
      column,
    },
  };

  return object;
};

const convertCheckbox = (name: string | null, commonBlocks: CommonBlock) => {
  const customName =
    commonBlocks["input"] && commonBlocks["input"].name
      ? commonBlocks["input"].name.match(/folder_form\[(.*)\]\[\]/)
      : "";
  const detail = commonBlocks["wrapper"]?.querySelector(
    `input[name^="folder_form[${i18next.t("その他の詳細")}"]`
  );
  const showOther = detail ? true : false;
  const lists =
    commonBlocks["wrapper"]?.querySelectorAll<HTMLLIElement>(`[class^=${name}Block__listItem]`) ??
    [];
  const column = commonBlocks["wrapper"]?.getAttribute("data-column") ?? undefined;

  const checkboxOptions = Array.from(lists).flatMap((list) => {
    if (list.getAttribute("data-is-other")) return [];
    return { label: list.innerText, value: list.innerText };
  });

  const object: CheckBoxObject = {
    id: generateUid(),
    name,
    type: "checkbox",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "custom",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: userIcon,
      checkboxOptions,
      showOther,
      customName: customName ? customName[1] : "",
      column,
    },
  };

  return object;
};

const convertButton = (name: string | null, block: Element) => {
  const button = block.querySelector<HTMLAnchorElement>(`[class^=${name}Block]`);

  const object: ButtonObject = {
    id: generateUid(),
    name: name,
    type: "button",
    options: {
      label: i18next.t("ボタン"),
      type: "custom",
      icon: buttonIcon,
      text: button?.innerText,
      url: button?.href,
      textColor: getStyleValue(button, "color") as CSSProperties["textAlign"],
      backgroundColor: getStyleValue(button, "background"),
      borderRadius: getStyleValue(button, "borderRadius")
        ? getStyleValue(button, "borderRadius")
        : 4,
    },
  };

  return object;
};

const convertFullname = (name: string | null, commonBlocks: CommonBlock) => {
  const fullnameInput =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelector<HTMLInputElement>(`input[name="folder_form[${name}]"]`);
  const fullnameRubyInput =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelector<HTMLInputElement>(
      `input[name="folder_form[${name}_ruby]"]`
    );
  const hasInputRuby =
    commonBlocks && commonBlocks["wrapper"]?.querySelector(`[class^=${name}Block__heading]`)
      ? true
      : false;

  const object: FullNameObject = {
    id: generateUid(),
    name,
    type: "fullName",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      label: (commonBlocks && commonBlocks["label"]?.innerText) ?? "",
      type: "preset",
      icon: userIcon,
      placeholderName: fullnameInput
        ? fullnameInput.placeholder
        : `${i18next.t("を入力", { value: i18next.t("氏名") })}...`,
      placeholderRuby: fullnameRubyInput
        ? fullnameRubyInput.placeholder
        : `${i18next.t("を入力", { value: i18next.t("氏名(フリガナ)") })}...`,
      hasInputRuby,
    },
  };

  return object;
};

const convertName = (name: string | null, commonBlocks: CommonBlock) => {
  const lastNameInput =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelector<HTMLInputElement>(`input[name="folder_form[lastname]"]`);
  const firstNameInput =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelector<HTMLInputElement>(
      `input[name="folder_form[firstname]"]`
    );
  const lastNameRubyInput =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelector<HTMLInputElement>(
      `input[name="folder_form[lastname_ruby]"]`
    );
  const firstNameRubyInput =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelector<HTMLInputElement>(
      `input[name="folder_form[firstname_ruby]"]`
    );
  const hasInputRuby =
    commonBlocks &&
    commonBlocks["wrapper"]?.querySelectorAll(`[class^=${name}Block__inputs]`).length === 2
      ? true
      : false;

  const object: NameObject = {
    id: generateUid(),
    name,
    type: "name",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      label: commonBlocks["label"]?.innerText ?? "",
      type: "preset",
      placeholderLastName: lastNameInput
        ? lastNameInput.placeholder
        : `${i18next.t("を入力", { value: i18next.t("姓") })}...`,
      placeholderFirstName: firstNameInput
        ? firstNameInput.placeholder
        : `${i18next.t("を入力", { value: i18next.t("名") })}...`,
      placeholderLastNameRuby: lastNameRubyInput
        ? lastNameRubyInput.placeholder
        : `${i18next.t("を入力", { value: i18next.t("セイ") })}...`,
      placeholderFirstNameRuby: firstNameRubyInput
        ? firstNameRubyInput.placeholder
        : `${i18next.t("を入力", { value: i18next.t("メイ") })}...`,
      icon: userIcon,
      hasInputRuby,
    },
  };

  return object;
};

const convertEmail = (name: string | null, commonBlocks: CommonBlock) => {
  const hasReconfirm =
    commonBlocks && commonBlocks["wrapper"]?.querySelector(`[class^=${name}Block__heading]`)
      ? true
      : false;

  const object: EmailObject = {
    id: generateUid(),
    name: name,
    type: "email",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: mailIcon,
      placeholder: commonBlocks["input"]?.placeholder ?? "",
      hasReconfirm,
    },
  };

  return object;
};

const convertPhoneNumber = (name: string | null, commonBlocks: CommonBlock) => {
  const hasHyphen = commonBlocks["input"]?.getAttribute("data-has-hyphen");
  const characterWidth = commonBlocks["input"]?.getAttribute("data-character-width");

  const object: PhoneObject = {
    id: generateUid(),
    name,
    type: "phone",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: phoneIcon,
      placeholder: commonBlocks["input"]?.placeholder ?? "",
      characterWidth: characterWidth ?? "",
      hasHyphen: castStringToBoolean(hasHyphen ?? ""),
    },
  };

  return object;
};

const convertInquery = (name: string | null, commonBlocks: CommonBlock) => {
  const object: InqueryObject = {
    id: generateUid(),
    name,
    type: "inquery",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: documentIcon,
      placeholder: commonBlocks["input"]?.placeholder ?? "",
      defaultValue: commonBlocks["input"]?.defaultValue ?? "",
      rows: (commonBlocks["input"] as HTMLTextAreaElement)?.rows,
    },
  };

  return object;
};

const convertGender = (name: string | null, commonBlocks: CommonBlock) => {
  const showOther = commonBlocks["wrapper"]?.querySelector(
    `input[name="folder_form[gender_other_detail]"]`
  )
    ? true
    : false;
  const lists =
    commonBlocks["wrapper"]?.querySelectorAll<HTMLLIElement>(`[class^=${name}Block__listItem]`) ??
    [];
  const genderOptions = Array.from(lists).flatMap((list) => {
    if (list.getAttribute("data-is-other")) return [];
    return { label: list.innerText, value: list.innerText };
  });

  const object: GenderObject = {
    id: generateUid(),
    name,
    type: "gender",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: identityIcon,
      genderOptions,
      showOther,
    },
  };

  return object;
};

const convertBirthday = (name: string | null, commonBlocks: CommonBlock) => {
  const object: BirthdayObject = {
    id: generateUid(),
    name,
    type: "birthday",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: identityIcon,
    },
  };

  return object;
};

const convertAddress = (name: string | null, commonBlocks: CommonBlock) => {
  const object: AddressObject = {
    id: generateUid(),
    name,
    type: "address",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: homeIcon,
    },
  };

  return object;
};

const convertPrefecture = (name: string | null, commonBlocks: CommonBlock) => {
  const showOther = commonBlocks["wrapper"]?.querySelector(
    `input[name="folder_form[prefecture_other_detail]"]`
  )
    ? true
    : false;
  const options = commonBlocks["wrapper"]?.querySelectorAll<HTMLOptionElement>(`option`) ?? [];
  const prefectureOptions = Array.from(options).flatMap((option, i) => {
    //「選択してください」は直置きしてあるのでスキップ
    if (i === 0) return [];
    return { label: option.innerText, value: option.innerText };
  });

  const object: PrefectureObject = {
    id: generateUid(),
    name,
    type: "prefecture",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: homeIcon,
      prefectureOptions,
      showOther,
    },
  };

  return object;
};

const convertOccupation = (name: string | null, commonBlocks: CommonBlock) => {
  const detail = commonBlocks["wrapper"]?.querySelector<HTMLSelectElement>(
    `input[name="folder_form[occupation_other_detail]"]`
  );
  const showOther = !!detail;
  const isRequiredDetail = detail ? detail.required : false;
  const lists =
    commonBlocks["wrapper"]?.querySelectorAll<HTMLLIElement>(`[class^=${name}Block__listItem]`) ??
    [];
  const occupationOptions = Array.from(lists).flatMap((list) => {
    if (list.getAttribute("data-is-other")) return [];
    return { label: list.innerText, value: list.innerText };
  });

  const object: OccupationObject = {
    id: generateUid(),
    name,
    type: "occupation",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: identityIcon,
      occupationOptions,
      showOther,
      isRequiredDetail,
    },
  };

  return object;
};

const convertIndustry = (name: string | null, commonBlocks: CommonBlock) => {
  const showOther = commonBlocks["wrapper"]?.querySelector(
    `input[name="folder_form[industry_other_detail]"]`
  )
    ? true
    : false;
  const options = commonBlocks["wrapper"]?.querySelectorAll(`option`) ?? [];
  const industryOptions = Array.from(options).flatMap((option, i) => {
    //「選択してください」は直置きしてあるのでスキップ
    if (i === 0) return [];
    return { label: option.innerText, value: option.innerText };
  });

  const object: IndustryObject = {
    id: generateUid(),
    name,
    type: "industry",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: bussinessIcon,
      industryOptions,
      showOther,
    },
  };

  return object;
};

const convertMedium = (name: string | null, commonBlocks: CommonBlock) => {
  const detail = commonBlocks["wrapper"]?.querySelector<HTMLSelectElement>(
    `input[name="folder_form[medium_other_detail]"]`
  );
  const showOther = !!detail;
  const isRequiredDetail = detail ? detail.required : false;
  const lists =
    commonBlocks["wrapper"]?.querySelectorAll<HTMLLIElement>(`[class^=${name}Block__listItem]`) ??
    [];
  const mediumOptions = Array.from(lists).flatMap((list) => {
    if (list.getAttribute("data-is-other")) return [];
    return { label: list.innerText, value: list.innerText };
  });

  const object: MediumObject = {
    id: generateUid(),
    name,
    type: "medium",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: userIcon,
      mediumOptions,
      showOther,
      isRequiredDetail,
    },
  };

  return object;
};

const convertEmployeeNum = (name: string | null, commonBlocks: CommonBlock) => {
  const showOther = commonBlocks["wrapper"]?.querySelector(
    `input[name="folder_form[employee_num_other_detail]"]`
  )
    ? true
    : false;
  const options = commonBlocks["wrapper"]?.querySelectorAll(`option`) ?? [];
  const employeeNumOptions = Array.from(options).flatMap((option, i) => {
    //「選択してください」は直置きしてあるのでスキップ
    if (i === 0) return [];
    return { label: option.innerText, value: option.innerText };
  });

  const object: EmployeeNumObject = {
    id: generateUid(),
    name,
    type: "employeeNum",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: branchIcon,
      employeeNumOptions,
      showOther,
    },
  };

  return object;
};

const convertTerms = (name: string | null, commonBlocks: CommonBlock) => {
  const terms = commonBlocks["wrapper"]?.querySelector<HTMLElement>(`[class^=${name}Block__terms]`);

  const object: TermsObject = {
    id: generateUid(),
    name,
    type: "terms",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: checkIcon,
      terms: terms?.innerHTML ?? "",
    },
  };

  return object;
};

const convertTermsLink = (name: string | null, commonBlocks: CommonBlock) => {
  const link = commonBlocks["wrapper"]?.querySelector<HTMLAnchorElement>(
    `[class^=${name}Block__termsLink]`
  );

  const object: TermLinkObject = {
    id: generateUid(),
    name,
    type: "termsLink",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "preset",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: checkIcon,
      link: link?.href ?? "",
      linkText: link?.innerText ?? "",
    },
  };

  return object;
};

const convertCalendar = (name: string | null, commonBlocks: CommonBlock) => {
  const customName =
    commonBlocks["input"] && commonBlocks["input"]?.name
      ? commonBlocks["input"]?.name.match(/folder_form\[(.*)\]/)
      : "";

  const object: CalendarObject = {
    id: generateUid(),
    name,
    type: "calendar",
    options: {
      ...commonOption(commonBlocks["wrapper"], commonBlocks["labelWrapper"], name),
      type: "custom",
      label: commonBlocks["label"]?.innerText ?? "",
      icon: calendarIcon,
      customName: customName ? customName[1] : "",
    },
  };

  return object;
};

const convertImage = (name: string | null, commonBlocks: CommonBlock) => {
  const image = commonBlocks["wrapper"]?.querySelector<HTMLImageElement>(`[class^=${name}Block]`);
  const isVideo = image?.tagName === "VIDEO" ? true : false;
  const link = commonBlocks["wrapper"]?.querySelector<HTMLAnchorElement>(
    `[class^=${name}Block__anchor]`
  );
  const webp = commonBlocks["wrapper"]?.querySelector<HTMLImageElement>(`[type="image/webp"]`);
  const avif = commonBlocks["wrapper"]?.querySelector<HTMLImageElement>(`[type="image/avif"]`);

  const width = image ? image.dataset.width : "400px";
  const height = image ? image.dataset.height : "auto";
  const widthUnit = image ? image.dataset.widthUnit : "px";
  const heightUnit = image ? image.dataset.heightUnit : "";

  let value: ImageAttributeValue = {
    url: isVideo ? image?.querySelector("source")?.src : image?.src,
  };
  if (webp) value = { ...value, webp_url: webp.srcset };
  if (avif) value = { ...value, avif_url: avif.srcset };

  const object: ImageObject = {
    id: generateUid(),
    name,
    type: "image",
    options: {
      type: "custom",
      height,
      heightUnit,
      width,
      widthUnit,
      isSetAnchor: link,
      isSetHeightNumber: height !== "auto",
      value,
      href: (link && link.getAttribute("href")) ?? "",
      icon: calendarIcon,
    },
  };

  return object;
};

const convertLink = (name: string | null, commonBlocks: CommonBlock) => {
  const text = commonBlocks["wrapper"]?.querySelector<HTMLAnchorElement>(
    `[class^=${name}Block__text]`
  );

  const object: LinkObject = {
    id: generateUid(),
    name,
    type: "link",
    options: {
      label: i18next.t("リンク"),
      type: "custom",
      icon: linkIcon,
      text: text?.innerText,
      href: commonBlocks["wrapper"]?.getAttribute("href") ?? "",
      textFontSize: getStyleValue(text, "fontSize"),
      textAlign: getStyleValue(text, "textAlign") as CSSProperties["textAlign"],
      textColor: getStyleValue(text, "color") as CSSProperties["textAlign"],
    },
  };

  return object;
};

// ElementObjectからHeadingParagraphObjectを判別するType Guard
export const isHeadingParagraphObject = (
  object: ElementObject
): object is HeadingParagraphObject => {
  return object.type === "headingParagraph";
};

// ElementObjectからHeadingObjectを判別するType Guard
export const isHeadingObject = (object: ElementObject): object is HeadingObject => {
  return object.type === "heading";
};

// ElementObjectからSubmitButtonObjectを判別するType Guard
export const isSubmitButtonObject = (object: ElementObject): object is SubmitButtonObject => {
  return object.type === "submitButton";
};

// ElementObjectからParagraphObjectを判別するType Guard
export const isParagraphObject = (object: ElementObject): object is ParagraphObject => {
  return object.type === "paragraph";
};

// ElementObjectからTextObjectを判別するType Guard
export const isTextObject = (object: ElementObject): object is TextObject => {
  return object.type === "text";
};

// ElementObjectからTextAreaObjectを判別するType Guard
export const isTextAreaObject = (object: ElementObject): object is TextAreaObject => {
  return object.type === "textarea";
};

// ElementObjectからSelectObjectを判別するType Guard
export const isSelectObject = (object: ElementObject): object is SelectBoxObject => {
  return object.type === "select";
};

// ElementObjectからRadioButtonsObjectを判別するType Guard
export const isRadioButtonsObject = (object: ElementObject): object is RadioObject => {
  return object.type === "radio";
};

// ElementObjectからCheckboxObjectを判別するType Guard
export const isCheckboxObject = (object: ElementObject): object is CheckBoxObject => {
  return object.type === "checkbox";
};

// ElementObjectからButtonObjectを判別するType Guard
export const isButtonObject = (object: ElementObject): object is ButtonObject => {
  return object.type === "button";
};

// ElementObjectからFullNameObjectを判別するType Guard
export const isFullNameObject = (object: ElementObject): object is FullNameObject => {
  return object.type === "fullName";
};

// ElementObjectからNameObjectを判別するType Guard
export const isNameObject = (object: ElementObject): object is NameObject => {
  return object.type === "name";
};

// ElementObjectからEmailObjectを判別するType Guard
export const isEmailObject = (object: ElementObject): object is EmailObject => {
  return object.type === "email";
};

// ElementObjectからPhoneObjectを判別するType Guard
export const isPhoneObject = (object: ElementObject): object is PhoneObject => {
  return object.type === "phone";
};

// ElementObjectからInqueryObjectを判別するType Guard
export const isInqueryObject = (object: ElementObject): object is InqueryObject => {
  return object.type === "inquery";
};

// ElementObjectからGenderObjectを判別するType Guard
export const isGenderObject = (object: ElementObject): object is GenderObject => {
  return object.type === "gender";
};

// ElementObjectからBirthdayObjectを判別するType Guard
export const isBirthdayObject = (object: ElementObject): object is BirthdayObject => {
  return object.type === "birthday";
};

// ElementObjectからAddressObjectを判別するType Guard
export const isAddressObject = (object: ElementObject): object is AddressObject => {
  return object.type === "address";
};

// ElementObjectからPrefectureObjectを判別するType Guard
export const isPrefectureObject = (object: ElementObject): object is PrefectureObject => {
  return object.type === "prefecture";
};

// ElementObjectからOccupationObjectを判別するType Guard
export const isOccupationObject = (object: ElementObject): object is OccupationObject => {
  return object.type === "occupation";
};

// ElementObjectからIndustryObjectを判別するType Guard
export const isIndustryObject = (object: ElementObject): object is IndustryObject => {
  return object.type === "industry";
};

// ElementObjectからMediumObjectを判別するType Guard
export const isMediumObject = (object: ElementObject): object is MediumObject => {
  return object.type === "medium";
};

// ElementObjectからEmployeeNumObjectを判別するType Guard
export const isEmployeeObject = (object: ElementObject): object is EmployeeNumObject => {
  return object.type === "employeeNum";
};

// ElementObjectからTermsObjectを判別するType Guard
export const isTermsObject = (object: ElementObject): object is TermsObject => {
  return object.type === "terms";
};

// ElementObjectからTermsLinkObjectを判別するType Guard
export const isTermsLinkObject = (object: ElementObject): object is TermLinkObject => {
  return object.type === "termsLink";
};

// ElementObjectからCalendarObjectを判別するType Guard
export const isCalendarObject = (object: ElementObject): object is CalendarObject => {
  return object.type === "calendar";
};

// ElementObjectからImageObjectを判別するType Guard
export const isImageObject = (object: ElementObject): object is ImageObject => {
  return object.type === "image";
};
