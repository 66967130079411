import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";

export const AlertTextForMedia = () => {
  const { t } = useTranslation();
  return (
    <Typography sx={{ fontSize: "14px" }}>
      {t(
        "一部の広告媒体の仕様で、媒体側が保管したキャッシュを配信している場合が報告されています。 この場合、Squad beyondで配信比率を変更しても、媒体側のキャッシュ更新タイミングの影響を受け、配信比率設定の更新反映までに時間がかかる場合があります。",
      )}
      <Link
        href="https://knowledge.squadbeyond.com/tiktok-specification"
        target="_blank"
        rel="noopener"
        sx={{ color: "primary.main" }}
      >
        {t("詳しくはこちら")}
      </Link>
    </Typography>
  );
};
