import { useState } from "react";

import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { CONTACT_DESTINATION_TYPE_NAME, ADDON_CONTACT_MENTION_TEXT } from "hooks";
import ContactApi from "javascripts/api/contact";

import { collectError } from "javascripts/utils";

import { ButtonContactProps } from "./type";

export const SplitTestSettingContactButton = ({ category, body }: ButtonContactProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("");

  const handleClick = () => {
    setStatus("sending");
    if (status === "sending") {
      return;
    }

    const params = new FormData();
    params.append("contact[category]", category);
    params.append("contact[body]", `${ADDON_CONTACT_MENTION_TEXT}${body}`);
    params.append("contact[destination_type]", CONTACT_DESTINATION_TYPE_NAME.ADDON_CONTACT);
    ContactApi.createContact(params)
      .then(() => {
        setStatus("sent");
      })

      .catch((error) => {
        collectError(error);
        setStatus("");
      });
  };

  const baseStyle = {
    display: "block",
    fontSize: "12px",
    border: "1px solid transparent",
    padding: "0.375rem 1.5rem",
    verticalAlign: "middle",
    color: "primary.main",
    textAlign: "center",
    lineHeight: 1.5,
    borderRadius: "0.25rem",
    backgroundColor: "background.paper",
    fontWeight: "normal",
    "&:hover": {
      bgcolor: "background.paper",
    },
  };

  if (status === "sent") {
    return <Box sx={{ ...baseStyle, opacity: "0.5" }}>{t("担当者に問い合わせました")}</Box>;
  }

  if (status === "sending") {
    return <Box sx={{ ...baseStyle, opacity: "0.5" }}>{t("問い合わせ中")}...</Box>;
  }

  return (
    <Button
      sx={baseStyle}
      disableRipple
      disableFocusRipple
      onClick={handleClick}
    >
      {t("担当者に問い合わせをする")}
    </Button>
  );
};
