/**認証情報をcookieに生成する */
export const generateSessionCookieValue = ({
  key,
  value,
  properties = {},
}: {
  key: string;
  value: string;
  properties?: {
    domain?: string;
    path?: string;
    secure?: boolean;
    sameSite?: "strict" | "lax" | "none";
    "max-age"?: number;
  };
}) => {
  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 1);

  const defaultProperties = {
    domain: ".squadbeyond.com",
    path: "/",
    secure: true,
    sameSite: "strict",
    // Note: 1ヶ月間有効
    expires: expiryDate,
  } as const;

  const propertiesStr = Object.entries({ ...defaultProperties, ...properties })
    .map(([key, value]) => `${key}=${value}`)
    .join("; ");

  return `${key}=${value}; ${propertiesStr}`;
};

/**LocalStorageから認証情報を抜き出す*/
export const getCognitoLocalStorageParams = () => {
  const keywords = ["CognitoIdentityServiceProvider.", "amplify-signin-with-hostedUI"];
  return (
    Object.keys(localStorage)
      .map((key) => {
        return { key, value: localStorage.getItem(key) };
      })
      // Note: null, undefinedを除外しつつ型矯正
      .filter((param): param is { key: string; value: string } => {
        return !!param.value;
      })
      .filter((param) => {
        return keywords.some((keyword) => param.key.includes(keyword));
      })
  );
};