import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class UserTeamSessionApi extends ApiBase {

  static signIn(teamUid) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(`${baseUrl}/users/teams/${teamUid}/sessions`,undefined, { withCredentials: true })
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    });
  }
}

export default UserTeamSessionApi;
