import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { TextButton } from "components/atoms";
import { DateListSelect } from "components/molecules/date-list-select";
import moment from "moment";
import { DownloadReportFormValues, DownloadReportProps } from "./type";

export const DownloadReport = ({
  folder,
  handleDownloadFolderReport,
  handleDownloadFolderReportLoading,
  handleDownloadFolderReportError,
}: DownloadReportProps) => {
  // TODO: ココにhandleDownloadFolderReportの呼び出しを持ってくる

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { register, handleSubmit, errors, setError, clearError, setValue, watch } =
    useForm<DownloadReportFormValues>({
      defaultValues: {
        startDate: moment().format("YYYY-MM-DD"), // TODO: dayjsに置き換えたい
        endDate: moment().format("YYYY-MM-DD"),
        category: "beyond_page",
      },
    });
  const eixtsFormErrors = Object.keys(errors).length > 0;

  const updateDateList = (startDate: string, endDate: string) => {
    const dateDiff = Math.abs(moment(startDate).diff(moment(endDate), "days"));
    if (dateDiff > 31) {
      setError(
        "base",
        "invalid",
        `${t("日~.単日", { value: 30 })}${t("以内の範囲で選択してください")}` || ""
      );
    } else {
      clearError("base");
    }

    setValue("startDate", startDate);
    setValue("endDate", endDate);
  };

  const onSubmit = async (data: DownloadReportFormValues) => {
    if (!handleDownloadFolderReport) return;
    const params = {
      category: data.category,
      start_date: data.startDate,
      end_date: data.endDate,
    };
    await handleDownloadFolderReport(folder, params);
  };

  useEffect(() => {
    register("category", { required: true });
    register("startDate", { required: true });
    register("endDate", { required: true });
  }, [register]);

  useEffect(() => {
    if (handleDownloadFolderReportError) {
      setError("base", "invalid", t("に失敗しました。", { value: t("ダウンロード") }) || "");
    } else {
      clearError("base");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDownloadFolderReportError]);

  return (
    <>
      <TextButton
        onClick={handleOpen}
        align="left"
        startIcon={<DownloadIcon sx={{ width: "20px" }} />}
      >
        <Typography>{t("ダウンロード")}</Typography>
      </TextButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>
          {t("レポートのダウンロード")} - {folder.name}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ py: 1 }}
          >
            <Box sx={{ mb: 1 }}>
              <DateListSelect
                startDate={watch("startDate")}
                endDate={watch("endDate")}
                updateDateList={updateDateList}
              />
              <FormHelperText>
                {t("までダウンロードできます", { value: t("日~.単日", { value: 31 }) })}
              </FormHelperText>
            </Box>
            <RadioGroup sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Radio
                    checked={watch("category") === "beyond_page"}
                    onChange={() => setValue("category", "beyond_page")}
                  />
                }
                label="beyondページ"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={watch("category") === "version"}
                    onChange={() => setValue("category", "version")}
                  />
                }
                label="Version"
              />
            </RadioGroup>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={handleDownloadFolderReportLoading}
              disabled={eixtsFormErrors || handleDownloadFolderReportLoading}
            >
              {t("ダウンロード")}
            </LoadingButton>

            {errors.base && (
              <Box sx={{ pt: 1 }}>
                <Typography
                  variant="subtitle2"
                  color="error"
                >
                  {errors.base?.message}
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
