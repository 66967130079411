import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import { TextButton } from "components/atoms";
import { useDeleteFolderGroup } from "hooks";
import type { DeleteFolderGroupProps } from "./type";

export const DeleteFolderGroup = ({ folderGroup }: DeleteFolderGroupProps) => {
  const { t } = useTranslation();
  const [handleDelete, loading] = useDeleteFolderGroup();
  const handleClick = async () => {
    await handleDelete(folderGroup.id);
  };

  return (
    <TextButton
      onClick={handleClick}
      sx={{ color: "error.main" }}
      align="left"
      startIcon={
        <DeleteIcon
          sx={{ width: "20px" }}
          color="error"
        />
      }
    >
      <Typography>{loading ? `${t("削除中")}...` : t("グループのみ削除する")}</Typography>
    </TextButton>
  );
};
