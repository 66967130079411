import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class TeamMemberLoyalityApi extends ApiBase {

  static fetchTeamMemberLoyality(folder) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(baseUrl + '/teams/members/loyalties', { loyalty: { folder: folder } }, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default TeamMemberLoyalityApi;
