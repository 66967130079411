import { forwardRef, useCallback } from "react";
import { ListItem } from "components/atoms/core/ListItem";
import { ListItemProps } from "components/atoms/core/ListItem/type";
import { useAuthSignOut, useCreateStripeCustomerPortalSession, useMember } from "hooks";
import { ListItemDropDown } from "../ListItemDropDown";
import { getMenuNavigation } from "./const";
import { MenuKindKey, MenuListProps } from "./type";

export const MenuList = forwardRef<HTMLDivElement, MenuListProps>(
  ({ navigationType, onMouseEnter }, ref) => {
    // TODO: ドメインをコンポーネントから分離させる

    const memberState = useMember();
    const memberAttributes = memberState.memberAttributes;

    if (!memberAttributes.isLoaded) return null;

    const existsMember = !!memberAttributes.existsMember;
    const isAdmin = !!memberAttributes.isAdmin;
    const isTeamOwner = !!memberAttributes.isTeamOwner;
    const isInsideMember = !memberAttributes.member?.outside;

    const [handleSignOut] = useAuthSignOut();

    const [handle] = useCreateStripeCustomerPortalSession();
    const handleClickPayments = useCallback(async () => {
      const response = await handle();
      if (response?.url) {
        window.open(response.url, "_blank");
      }
    }, [handle]);

    const [menuNavigationItems] = getMenuNavigation(() => handleSignOut(), handleClickPayments, {
      existsMember,
      isAdmin,
      isTeamOwner,
      isInsideMember,
    }).filter((nav) => nav.groupId === navigationType);

    return (
      <nav
        className="flex flex-col gap-y-4 overflow-auto p-4"
        ref={ref}
      >
        {menuNavigationItems.items
          .filter((item) => item.isVisible)
          .map((item) => {
            if (item.children) {
              return (
                <ListItemDropDown
                  {...(item as ListItemProps)}
                  key={item.id}
                  onMouseEnter={() => onMouseEnter(item.id as MenuKindKey)}
                >
                  {item.children}
                </ListItemDropDown>
              );
            }

            return (
              <ListItem
                key={item.id}
                size="large"
                onMouseEnter={() => onMouseEnter(item.id as MenuKindKey)}
                {...item}
              />
            );
          })}
      </nav>
    );
  },
);

MenuList.displayName = "MenuListSlot";
