import { deleteRequest, get, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import {
  GoogleAccountApiCreateAccountRequest,
  GoogleAccountApiCreateAccountResponse,
  GoogleAccountApiDestroyAccountRequest,
  GoogleAccountApiDestroyAccountResponse,
  GoogleAccountApiFetchAccountsResponse,
} from "./type";

const fetchAccounts = () =>
  get<{}, GoogleAccountApiFetchAccountsResponse>(
    {},
    `${DEFAULT_BASE_URL}/teams/ad_accounts/google_accounts`
  );

const createAccount = (params: GoogleAccountApiCreateAccountRequest) =>
  post<GoogleAccountApiCreateAccountRequest, GoogleAccountApiCreateAccountResponse>(
    params,
    `${DEFAULT_BASE_URL}/teams/ad_accounts/google_accounts`
  );

const destroyAccount = (params: GoogleAccountApiDestroyAccountRequest) =>
  deleteRequest<GoogleAccountApiDestroyAccountRequest, GoogleAccountApiDestroyAccountResponse>(
    params,
    `${DEFAULT_BASE_URL}/teams/ad_accounts/google_accounts/${params.accountId}`
  );

export const GoogleAccountApi = {
  fetchAccounts,
  createAccount,
  destroyAccount,
} as const;
