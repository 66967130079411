import { CHANNEL_IO_PUBLIC_KEY } from "const/channelio";
import { useMember } from "hooks";
import ChannelService from "javascripts/services/ChannelService";

const ChannelTalk = () => {
  const memberState = useMember();

  if (!memberState.memberAttributes.existsMember) {
    return null;
  }

  const profile = {
    referrer: window.location.href,
    team_id: memberState.memberAttributes.team?.id,
    team_name: memberState.memberAttributes.team?.name,
    user_id: memberState.memberAttributes.user?.id,
    name: memberState.memberAttributes.user?.name,
    email: memberState.memberAttributes.user?.email,
    company_name: memberState.memberAttributes.user?.company_name,
    role: memberState.memberAttributes.member?.role,
    team_tier: memberState.memberAttributes.team_tier,
    user_tier: memberState.memberAttributes.user_tier,
  };

  ChannelService.boot({
    pluginKey: CHANNEL_IO_PUBLIC_KEY,
    memberId: memberState.memberAttributes.user?.id,
    profile: profile,
  });

  return null;
};

export default ChannelTalk;
