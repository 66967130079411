import axios from "axios";
import { AbTestProps } from "domains";
import ApiBase from "./apiBase";

type FetchAbTest = AbTestProps;

class AbTestApi extends ApiBase {
  static fetchAbTest(pathname: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchAbTest>(function (resolve, reject) {
      axios
        .get(baseUrl + pathname, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static updateAbTest(pathname: string, params: any) {
    let baseUrl = this.defaultBaseUrl();

    return new Promise<any>(function (resolve, reject) {
      axios
        .patch(baseUrl + pathname, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static destroyAbTest(abTestUid: string) {
    let baseUrl = this.defaultBaseUrl();

    return new Promise<any>(function (resolve, reject) {
      axios
        .delete(`${baseUrl}/ab_tests/${abTestUid}`, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default AbTestApi;
