import { useEffect, useState } from "react";
import { Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AnnouncementTooltipProps } from "./types";

export const AnnouncementTooltip = ({
  children,
  tooltipText,
  placement,
  isOpen,
}: AnnouncementTooltipProps) => {
  const theme = useTheme();
  const [isTooltipOpen, setIsTooltipOpen] = useState(true);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setIsTooltipOpen(false);
      }, 5000);
    }
  }, [isOpen]);

  if (!tooltipText) {
    return <>{children}</>;
  }

  const boxShadowStyle = {
    boxShadow: "0px 8px 16px 0px rgba(0, 85, 195, 0.10), 0px 0px 2px 0px rgba(0, 85, 195, 0.10);",
  };

  return (
    <Tooltip
      arrow
      open={isOpen === undefined ? isTooltipOpen : isOpen}
      onOpen={() => setIsTooltipOpen(true)}
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            ...boxShadowStyle,
            bgcolor: theme.palette.background.default,
            "& .MuiTooltip-arrow": {
              color: theme.palette.background.default,
            },
          },
        },
      }}
      title={
        <Typography
          sx={{
            bgcolor: theme.palette.background.default,
            fontSize: "12px",
            color: theme.palette.secondary.contrastText,
          }}
        >
          {tooltipText}
        </Typography>
      }
    >
      {children}
    </Tooltip>
  );
};
