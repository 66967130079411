import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation, Trans } from "react-i18next";
import { useRecoilState } from "recoil";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { AbTestProps, FolderGroupProps, FolderProps, TeamProps } from "domains";
import {
  abTestListState,
  folderGroupListState,
  folderListState,
  HandleFetchFolderGroupListProps,
  HandleFetchFolderListProps,
  HandleMoveAbTestProps,
} from "hooks";

type MoveFormValues = {
  base?: string;
  folderId: number | string | undefined;
};

export const BeyondPageMove: React.FC<{
  resetSelectedAbTestList: VoidFunction;
  targetAbTestList: AbTestProps[];
  userTeamList: TeamProps[];
  fetchFolderList: HandleFetchFolderListProps;
  fetchFolderGroupList: HandleFetchFolderGroupListProps;
  moveAbTest: HandleMoveAbTestProps;
  moveAbTestLoading: boolean;
  moveAbTestError: Error | undefined;
  children: React.ReactNode;
}> = ({
  resetSelectedAbTestList,
  targetAbTestList,
  userTeamList,
  fetchFolderList,
  fetchFolderGroupList,
  moveAbTest,
  moveAbTestLoading,
  moveAbTestError,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [folderList, setFolderList] = useRecoilState(folderListState);
  const [folderGroupList, setFolderGroupList] = useRecoilState(folderGroupListState);
  const [abTestList, setAbTestList] = useRecoilState(abTestListState);

  const { register, handleSubmit, errors, setError, setValue } = useForm<MoveFormValues>();

  const { t } = useTranslation();

  const resetSelections = () => {
    setFolderList([]);
    setFolderGroupList([]);
  };

  const selectTeam = (teamId: number | string) => {
    resetSelections();
    fetchFolderList({ teamId: +teamId, folderScope: "include_outside" });
    fetchFolderGroupList(teamId, "include_outside");
  };

  const selectFolderGroup = (folderGroupId: number | string | undefined) => {
    if (folderGroupId === undefined) {
      return;
    }

    const folderGroup = folderGroupList.find(
      (folderGroup: FolderGroupProps) => folderGroupId === folderGroup.id
    );
    if (folderGroup != undefined && folderGroup?.folders != undefined) {
      setFolderList(folderGroup.folders);
    }
  };

  const onSubmit = (data: MoveFormValues) => {
    if (data.folderId === undefined) {
      return;
    }
    if (
      !window.confirm(
        t(
          "フォルダを移動する場合、現在利用している下記の設定が無効になります。\r\n改めて設定・設置を行ってください。\r\n設定対象: ドメイン・CVタグ・フォーム"
        ) || ""
      )
    ) {
      return;
    }

    const folder = folderList.find((folder: FolderProps) => folder.id === data.folderId);
    if (!folder) {
      return;
    }

    setIsSubmitted(false);
    const articleUidList = targetAbTestList.map((abTest: AbTestProps) => abTest.uid);
    const params = {
      move: {
        ab_test_uids: articleUidList,
      },
    };
    moveAbTest(folder.uid, params);
    setIsSubmitted(true);
  };

  const folderGroupOptionList = folderGroupList.map((folderGroup: FolderGroupProps) => {
    return { label: folderGroup.name, id: folderGroup.id };
  });

  const folderOptionList = folderList.map((folder: FolderProps) => {
    return { label: folder.name, id: folder.id };
  });

  useEffect(() => {
    resetSelections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    register("folderId", {
      required: t("を選択してください", { value: t("フォルダ") }) || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register]);

  useEffect(() => {
    if (moveAbTestError) {
      setError("base", "invalid", t("に失敗しました。", { value: t("移動") }) || "");
    } else if (isSubmitted && !moveAbTestLoading && !moveAbTestError) {
      const articleUidList = targetAbTestList.map((abTest: AbTestProps) => abTest.uid);
      const newList = abTestList.filter(
        (abTest: AbTestProps) => !articleUidList.includes(abTest.uid)
      );
      setAbTestList(newList);
      resetSelectedAbTestList();
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveAbTestError, moveAbTestLoading, isSubmitted]);

  return (
    <>
      <Box onClick={handleOpen}>{children}</Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        PaperProps={{
          sx: { backgroundColor: "background.primary" },
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                {t("閉じる")}
              </Button>
            </Box>
            <Box>{t("フォルダ移動")}</Box>
            <Box>
              {moveAbTestLoading ? (
                <CircularProgress
                  color="primary"
                  size={30}
                />
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                >
                  {t("移動する")}
                </Button>
              )}
            </Box>
          </DialogTitle>
          <DialogTitle>{t("フォルダ移動")}</DialogTitle>
          <DialogContent>
            <Typography sx={{ color: "error.light", fontSize: "14px", mb: 1 }}>
              {t(
                "フォルダを移動するとドメインが変わるため、配信URLを閲覧できなくなります。ご注意ください。"
              )}
            </Typography>
            <Trans i18nKey={"を移動したいチームとフォルダを選択してください"}>
              <List sx={{ maxHeight: "115px", overflowY: "scroll", pt: 0 }}>
                {targetAbTestList.map((abTest: AbTestProps) => (
                  <ListItem
                    key={abTest.id}
                    dense={true}
                    sx={{ fontSize: "13px", px: 0 }}
                  >
                    {abTest.title}
                  </ListItem>
                ))}
              </List>
              <Typography sx={{ fontSize: "14px", mb: 2 }}>
                を移動したいチームとフォルダを選択してください
              </Typography>
            </Trans>
            <Typography
              variant="subtitle1"
              sx={{
                mb: 1,
                color: "grey.500",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {t("移動先フォルダ")}
            </Typography>
            <Box
              sx={{
                backgroundColor: "background.secondary",
                p: 2,
                mb: 2,
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>{t("チーム")}</InputLabel>
                  {userTeamList && (
                    <Select
                      label={t("チーム")}
                      onChange={(e: SelectChangeEvent) => selectTeam(e.target.value)}
                      variant="filled"
                      disableUnderline
                    >
                      {userTeamList.map((team: TeamProps) => (
                        <MenuItem
                          key={team.id}
                          value={team.id}
                        >
                          {team.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
                <Autocomplete
                  options={folderGroupOptionList}
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        InputProps: {
                          ...params.InputProps,
                          ...{ disableUnderline: true },
                        },
                      }}
                      label={t("フォルダグループ")}
                      variant="filled"
                    />
                  )}
                  onChange={(event, value) => selectFolderGroup(value?.id)}
                  fullWidth
                  sx={{ ml: 0.5 }}
                />
                <Autocomplete
                  options={folderOptionList}
                  renderInput={(params) => (
                    <TextField
                      {...{
                        ...params,
                        InputProps: {
                          ...params.InputProps,
                          ...{ disableUnderline: true },
                        },
                      }}
                      label={t("フォルダ")}
                      variant="filled"
                    />
                  )}
                  onChange={(event, value) => setValue("folderId", value?.id)}
                  fullWidth
                  sx={{ ml: 0.5 }}
                />
                {errors.base && (
                  <Box sx={{ pt: 1 }}>
                    <Typography
                      variant="subtitle2"
                      color="error"
                    >
                      {errors.base?.message}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};
