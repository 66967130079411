import { useState } from "react";
import { ListItem } from "components/atoms/core/ListItem";
import { RichMenu } from "components/organisms/core/RichMenu";
import { ANIMATION_PREVENT_CLICK_TIME } from "const/animationPreventClickTime";
import { useScreenSize } from "hooks/screen-size";
import { useRichMenuDialog } from "../hooks";
import { NAVIGATION_LIST_CLASS_NAME } from "./const";

import type { NavigationListUIProps } from "./type";

export const NavigationListUI = ({
  items,
  mediaType = "desktop",
  groups,
}: NavigationListUIProps) => {
  const { media } = useScreenSize();
  const { richMenuState, noCloseRefs, toggleDialog, closeDialog } = useRichMenuDialog();
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false);

  return (
    <nav>
      <ul className={NAVIGATION_LIST_CLASS_NAME({ media: mediaType || media })}>
        {items.map((item, index) => (
          <li key={item.id}>
            <div
              className="relative"
              data-dropdown-id={item.navigationType}
              ref={(el) => (noCloseRefs.current[index] = el)}
              onMouseEnter={() => toggleDialog(item.navigationType)}
              onMouseLeave={() => closeDialog()}
              onAnimationStart={() => setIsAnimationInProgress(true)}
              onAnimationEnd={() =>
                setTimeout(() => {
                  setIsAnimationInProgress(false);
                }, ANIMATION_PREVENT_CLICK_TIME)
              }
            >
              <ListItem
                title={item.title}
                isSelected={item.isSelected}
                hasChevron={item.hasChevron}
                hasNotification={item.hasNotification}
                {...(item.href
                  ? { href: item.href }
                  : {
                      onClick: () => !isAnimationInProgress && toggleDialog(item.navigationType),
                    })}
                size="medium"
              />
              <RichMenu
                isOpen={
                  richMenuState.key !== "default" &&
                  richMenuState.key === item.navigationType &&
                  richMenuState.isOpen
                }
                navigationType={item.navigationType}
                groups={groups}
                absolute
              />
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};
