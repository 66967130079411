import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, Link } from "@mui/material";
import axios, { AxiosError } from "axios";

const Content: React.FC<{ isRequireTeam: boolean }> = ({ isRequireTeam }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isRequireTeam}
      fullWidth={true}
      maxWidth={"xs"}
      PaperProps={{
        sx: { backgroundColor: "background.primary" },
      }}
    >
      <DialogTitle>{t("チーム選択が必要です")}</DialogTitle>
      <DialogContent>
        <Trans
          i18nKey={"チーム選択画面でチームを選択してください"}
          components={{
            anchor: (
              <Link
                href="/users/teams"
                underline="none"
              ></Link>
            ),
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export const RequireTeam: React.FC<{
  children?: React.ReactNode | undefined;
}> = (props) => {
  const [isRequireTeam, setIsRequireTeam] = useState(false);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.response) {
          const isUnauthorized = error.response.status === 401;
          const isRequireTeam =
            hasRequireTeam(error.response.data) && error.response.data.require_team;
          const isNotTeamSelectionPage = location.pathname !== "/users/teams";
          setIsRequireTeam(isUnauthorized && isRequireTeam && isNotTeamSelectionPage);
          return Promise.reject(error);
        }
      }
    );
  }, []);

  return (
    <>
      <Content isRequireTeam={isRequireTeam} />
      {props.children}
    </>
  );
};

interface HasRequireTeam {
  require_team: boolean;
}

const hasRequireTeam = (value: unknown): value is HasRequireTeam => {
  const record = value as Record<string, unknown>;
  return typeof record.require_team === "boolean";
};
