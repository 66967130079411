import React, { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, TextField, Tooltip, Typography } from "@mui/material";
import { BeyondPageArticleOptionList, Theme } from "components";
import { useAlert } from "contexts/alert";
import { AbTestProps, ArticleParameterAllowListProps, ArticleProps } from "domains";
import Nav from "javascripts/components/Editor/Nav";

const Parameter: React.FC<{
  abTestUid: string;
  param: string;
  article: ArticleProps;
  parameter: ArticleParameterAllowListProps;
  onDestroyParam: Function;
  destroyParamError?: Error;
}> = ({ abTestUid, article, param, parameter, onDestroyParam, destroyParamError }) => {
  const { setError } = useForm();

  useEffect(() => {
    if (destroyParamError) {
      setError("base", "invalid", destroyParamError.message);
    } else {
      setError("base", "invalid", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destroyParamError]);

  const isAddedParam = param === parameter.parameter;

  const handleDelete = useCallback(async () => {
    await onDestroyParam(abTestUid, article.uid, parameter.id);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: isAddedParam ? "error.main" : "primary.main",
        px: 1,
        borderRadius: "14px",
        height: "28px",
        lineHeight: "28px",
        alignItems: "center",
        mr: 1,
      }}
    >
      <Typography
        sx={{
          color: "primary.contrastText",
          margin: "0px",
          fontSize: "14px",
          whiteSpace: "nowrap",
        }}
      >
        {parameter.parameter}
      </Typography>
      <CancelIcon
        sx={{
          ml: 1,
          color: "primary.contrastText",
          cursor: "pointer",
        }}
        onClick={handleDelete}
      />
    </Box>
  );
};

const Article: React.FC<{
  abTestUid: string;
  article: ArticleProps;
  onCreateParam: Function;
  createParamError?: Error;
  onDestroyParam: Function;
  destroyParamError?: Error;
}> = ({
  abTestUid,
  article,
  onCreateParam,
  createParamError,
  onDestroyParam,
  destroyParamError,
}) => {
  const { t } = useTranslation();
  const { showAlerts, clearAlerts } = useAlert();
  const [parameterAllowList, setParameterAllowList] = useState<ArticleParameterAllowListProps[]>(
    article.parameter_allowlists,
  );

  const { register, handleSubmit, errors, setError, setValue, watch } = useForm<{
    param: string;
    base?: string;
  }>({
    defaultValues: {
      param: "",
    },
  });

  const sameStringParam = parameterAllowList.find(
    (parameter: ArticleParameterAllowListProps) => parameter.parameter === watch("param"),
  );

  useEffect(() => {
    register("param");
  }, [register]);

  useEffect(() => {
    if (createParamError) {
      showAlerts({
        messages: [createParamError.message],
        theme: "danger",
      });
      setError("base", "invalid", createParamError.message);
    } else {
      setError("base", "invalid", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createParamError]);

  useEffect(() => {
    setValue("param", "");
    setError("param", "message", "");
    clearAlerts();
    setParameterAllowList(article.parameter_allowlists);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  useEffect(() => {
    setError("param", "message", "");
    clearAlerts();
    if (sameStringParam) {
      setError("param", "message", t("追加済み") || "");
    }
    window.addEventListener("beforeunload", handleBeforeunload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("param")]);

  const handleBeforeunload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (watch("param") !== "") {
        e.preventDefault();
        e.returnValue = t("保存していないパラメーターがあります");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch("param")],
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue("param", e.target.value);
  };

  const onSubmit = async (data: { param: string }) => {
    clearAlerts();
    setError("param", "message", "");
    if (data.param.length === 0) {
      return;
    }
    if (sameStringParam) {
      setError("param", "message", t("追加済み") || "");
    }
    const params = {
      parameter: watch("param"),
    };
    await onCreateParam(abTestUid, article.uid, params);
  };

  const ParameterAllowListContent: React.FC = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          maxWidth: "calc(100vw - 474px - 150px)",
          mr: 0.5,
          ml: 0.5,
          overflowX: "auto",
          scrollBehavior: "smooth",
        }}
      >
        {parameterAllowList.map((parameter: ArticleParameterAllowListProps) => (
          <Parameter
            key={parameter.id}
            abTestUid={abTestUid}
            article={article}
            param={watch("param")}
            parameter={parameter}
            onDestroyParam={onDestroyParam}
            destroyParamError={destroyParamError}
          />
        ))}
      </Box>
    );
  };

  const errorHelperTextStyle = errors.param?.message
    ? {
        top: "14px",
      }
    : {};

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          flex: 1,
          pr: 1.4,
          pb: 2,
          bgcolor: "background.primary",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flex: 1,
            bgcolor: "background.default",
            height: "46px",
            px: 1,
            borderRadius: "0px 10px 10px 0px",
          }}
        >
          {parameterAllowList.length !== 0 && <ParameterAllowListContent />}
          <TextField
            fullWidth
            name="param"
            type="text"
            placeholder="utm_creative=xxx"
            value={watch("param")}
            onChange={handleChange}
            onKeyUp={(e) => {
              if (e.key !== "Enter" || e.code !== "Enter") {
                return;
              }
              handleSubmit(onSubmit)();
            }}
            sx={{
              flex: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "background.default",
                },
              },
              "& .MuiOutlinedInput-input": {
                height: "0px",
              },
              ...errorHelperTextStyle,
            }}
            error={!!errors.param?.message}
            helperText={errors.param?.message}
            FormHelperTextProps={{
              sx: {
                fontSize: "15px",
                fontWeight: 550,
                bgcolor: "background.default",
                width: "70px",
                textAlign: "center",
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ArticleTitle: React.FC<{
  title: string;
  abTestRate: number;
  // FIXME
  // eslint-disable-next-line react/display-name
}> = memo(({ title, abTestRate }) => {
  return (
    <Tooltip
      title={<Typography sx={{ fontSize: "15px" }}>{title}</Typography>}
      placement="top"
    >
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            fontSize: "14px",
            height: "46px",
            lineHeight: "46px",
            px: 1.4,
            py: 1,
            boxSizing: "border-box",
            bgcolor: "lightGray",
            borderRadius: "10px 0 0 10px",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              flex: 1,
              color: "gray",
              textAlign: "right",
            }}
          >
            {abTestRate}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
});

const Params: React.FC<{
  articleList: ArticleProps[];
  onCreateParam: Function;
  createParamError?: Error;
  onDestroyParam: Function;
  destroyParamError?: Error;
  abTestUid: string;
}> = ({
  articleList,
  onCreateParam,
  createParamError,
  onDestroyParam,
  destroyParamError,
  abTestUid,
}) => {
  const { t } = useTranslation();
  if (articleList === null) {
    return null;
  }

  // FIXME
  // eslint-disable-next-line react/display-name
  const ArticleTitlesHeader: React.FC = memo(() => {
    return (
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            px: 1.4,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              fontSize: "12px",
            }}
          >
            {t("Version")}
          </Typography>
          <Typography
            sx={{
              color: "gray",
              fontSize: "12px",
              textAlign: "right",
            }}
          >
            {t("配信割合")}
          </Typography>
        </Box>
      </Box>
    );
  });

  // FIXME
  // eslint-disable-next-line react/display-name
  const ArticlesHeader: React.FC = memo(() => {
    const headerTextStyle = {
      color: "gray",
      fontSize: "14px",
    };
    return (
      <Box
        sx={{
          flex: 1,
          pb: 1.4,
          pr: 2,
          bgcolor: "background.primary",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <Box
          sx={{
            color: "primary.contrastText",
            fontSize: "14px",
            fontWeight: "600px",
            pt: 2,
            pl: 2,
            pb: 1.6,
          }}
        >
          {t("パラメーター別")}
        </Box>
        <Box sx={{ pl: 2 }}>
          <Typography sx={headerTextStyle}>
            {t("登録したパラメーターが配信URLにあるとき、該当するVersionを表示します。")}
          </Typography>
          <Typography sx={headerTextStyle}>
            {t("パラメーターを登録していないVersionは常に表示されます。")}
          </Typography>
          <Typography sx={headerTextStyle}>{t("完全一致")}: utm_creative=sb_banner_01</Typography>
          <Typography sx={headerTextStyle}>{t("前方一致")}: utm_creative=*</Typography>
          <Typography sx={headerTextStyle}>{t("後方一致")}: *=sb_banner_01</Typography>
          <Typography sx={headerTextStyle}>{t("部分一致")}: utm_*=sb_banner_*</Typography>
        </Box>
      </Box>
    );
  });

  const articleTitles = articleList.map((article: ArticleProps) => {
    return (
      <ArticleTitle
        key={article.id}
        title={article.memo}
        abTestRate={article.ab_test_article.rate}
      />
    );
  });

  const articles = articleList.map((article: ArticleProps) => {
    return (
      <Article
        key={article.id}
        abTestUid={abTestUid}
        article={article}
        onCreateParam={onCreateParam}
        createParamError={createParamError}
        onDestroyParam={onDestroyParam}
        destroyParamError={destroyParamError}
      />
    );
  });

  return (
    <Theme mode={"dark"}>
      <Box
        sx={{
          display: "flex",
          m: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex" }}>
            <ArticleTitlesHeader />
            <ArticlesHeader />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "230px" }}>{articleTitles}</Box>
            <Box
              sx={{
                flex: 1,
                bgcolor: "background.primary",
                minHeight: "300px",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              {articles}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "190px" }}>
          <BeyondPageArticleOptionList
            type={"params"}
            abTestUid={abTestUid}
          />
        </Box>
      </Box>
    </Theme>
  );
};

export const AbTestArticleSplitTestSettingParamsContainer: React.FC<{
  onFetchAbTest: Function;
  abTest?: AbTestProps;
  abTestUid: string;
  articleList: ArticleProps[];
  onCreateParam: Function;
  createParamError?: Error;
  onDestroyParam: Function;
  destroyParamError?: Error;
}> = ({
  onFetchAbTest,
  abTest,
  abTestUid,
  articleList,
  onCreateParam,
  createParamError,
  onDestroyParam,
  destroyParamError,
}) => {
  const existsAbTest = abTest && Object.keys(abTest).length > 0;

  useEffect(() => {
    onFetchAbTest(abTestUid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {existsAbTest && (
        <>
          <Nav
            abTest={abTest}
            folder={abTest.folder}
            page={"splitTestSetting"}
            article={null}
          />
          <Params
            articleList={articleList}
            onCreateParam={onCreateParam}
            createParamError={createParamError}
            onDestroyParam={onDestroyParam}
            destroyParamError={destroyParamError}
            abTestUid={abTestUid}
          />
        </>
      )}
    </>
  );
};
