import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const ParameterIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="settings-time"
          transform="translate(-900.000000, -372.000000)"
          fill={iconColor}
        >
          <g
            id="Group-19"
            transform="translate(880.000000, 98.000000)"
          >
            <g
              id="sabumenyu"
              transform="translate(0.000000, 170.000000)"
            >
              <g
                id="Group-7"
                transform="translate(0.000000, 72.000000)"
              >
                <g id="Group-3">
                  <g
                    id="Group-18"
                    transform="translate(20.000000, 32.000000)"
                  >
                    <path
                      d="M5,12 C6.1045695,12 7,12.8954305 7,14 C7,15.1045695 6.1045695,16 5,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 C-1.3527075e-16,12.8954305 0.8954305,12 2,12 L5,12 Z M14,12 C15.1045695,12 16,12.8954305 16,14 C16,15.1045695 15.1045695,16 14,16 L11,16 C9.8954305,16 9,15.1045695 9,14 C9,12.8954305 9.8954305,12 11,12 L14,12 Z M16,6 C17.1045695,6 18,6.8954305 18,8 C18,9.1045695 17.1045695,10 16,10 L13,10 C11.8954305,10 11,9.1045695 11,8 C11,6.8954305 11.8954305,6 13,6 L16,6 Z M7,6 C8.1045695,6 9,6.8954305 9,8 C9,9.1045695 8.1045695,10 7,10 L2,10 C0.8954305,10 1.3527075e-16,9.1045695 0,8 C-1.3527075e-16,6.8954305 0.8954305,6 2,6 L7,6 Z M6,0 C7.1045695,-2.02906125e-16 8,0.8954305 8,2 C8,3.1045695 7.1045695,4 6,4 L2,4 C0.8954305,4 1.3527075e-16,3.1045695 0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L6,0 Z M14,0 C15.1045695,-2.02906125e-16 16,0.8954305 16,2 C16,3.1045695 15.1045695,4 14,4 L12,4 C10.8954305,4 10,3.1045695 10,2 C10,0.8954305 10.8954305,2.02906125e-16 12,0 L14,0 Z"
                      id="icon_parameters"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
