import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleProps } from "domains";
import AbTestArticleSplitTestSettingDeviceApi from "javascripts/api/abTest/articles/splitTestSettings/device";
import ArticleSplitTestDeviceBlocklistApi from "javascripts/api/articles/splitTestSettings/devices/blocklist";

export const abTestSplitTestSettingDevicesArticleListState = atom<ArticleProps[]>({
  key: "abTestSplitTestSettingDevicesArticleListState",
  default: [],
});

export const useFetchAbTestSplitTestSettingDevicesArticleList = (abTestUid: string) => {
  const [articleList, setArticleList] = useRecoilState(
    abTestSplitTestSettingDevicesArticleListState
  );
  const [error, setError] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await AbTestArticleSplitTestSettingDeviceApi.fetch(abTestUid);
        setArticleList(response.articles);
      } catch (e) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [articleList, error] as const;
};

export const useCreateAbTestSplitTestSettingDevicesArticleList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (
      articleUid: string,
      params: {
        device_id: number;
      }
    ) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);
      try {
        await ArticleSplitTestDeviceBlocklistApi.create(articleUid, params);
        setLoading(false);
        return true;
      } catch (e) {
        setLoading(false);
        setError(e);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [handle, loading, error] as const;
};

export const useDestroyAbTestSplitTestSettingDevicesArticleList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (
      articleUid: string,
      params: {
        device_id: number;
      }
    ) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);

      try {
        await ArticleSplitTestDeviceBlocklistApi.destroy(articleUid, params);
        setLoading(false);
        return true;
      } catch (e) {
        setLoading(false);
        setError(e);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [handle, loading, error] as const;
};
