import axios from "axios";
import { DEFAULT_BASE_URL } from "const/app";
import {
  UserApiFetchUserResponse,
  UserApiUpdateUserParams,
  UserApiUpdateUserResponse,
} from "./type";

const fetch = async (): Promise<UserApiFetchUserResponse> => {
  return axios
    .get<UserApiFetchUserResponse>(`${DEFAULT_BASE_URL}/user`, { withCredentials: true })
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

const update = async (params: UserApiUpdateUserParams): Promise<UserApiUpdateUserResponse> => {
  return axios
    .patch<UserApiUpdateUserResponse>(
      `${DEFAULT_BASE_URL}/user`,
      {
        user: {
          company_name: params.companyName,
          name: params.name,
        },
      },
      { withCredentials: true }
    )
    .then((response) => response.data)
    .catch((error) => error.response.data);
};

export default {
  fetch,
  update,
};
