import { get, post, deleteRequest, patch } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { TeamTagSettingTeamTagScript } from "domains";

type TeamTagSettingTagScriptFetchResponse = TeamTagSettingTeamTagScript[];

type TeamTagSettingTagScriptCreateRequestParam = {
  tag: {
    document_property: string;
  };
};

type TeamTagSettingTagScriptCreateResponse = TeamTagSettingTeamTagScript;

type TeamTagSettingTagScriptUpdateRequestParam = {
  tag: Pick<TeamTagSettingTeamTagScript, "name" | "body" | "embed_type">;
};

type TeamTagSettingTagScriptUpdateResponse = TeamTagSettingTeamTagScript;

type TeamTagSettingTagScriptDeleteResponse = {};

const fetch = (id: number) => {
  const path = `${DEFAULT_BASE_URL}/teams/tag_settings/${id}/tags`;
  return get<{}, TeamTagSettingTagScriptFetchResponse>({}, path);
};

const create = (id: number, params: TeamTagSettingTagScriptCreateRequestParam) => {
  const path = `${DEFAULT_BASE_URL}/teams/tag_settings/${id}/tags`;
  return post<TeamTagSettingTagScriptCreateRequestParam, TeamTagSettingTagScriptCreateResponse>(
    params,
    path
  );
};

const update = (
  settingId: number,
  id: number,
  params: TeamTagSettingTagScriptUpdateRequestParam
) => {
  const path = `${DEFAULT_BASE_URL}/teams/tag_settings/${settingId}/tags/${id}`;
  return patch<TeamTagSettingTagScriptUpdateRequestParam, TeamTagSettingTagScriptUpdateResponse>(
    params,
    path
  );
};

const destroy = (settingId: number, id: number) => {
  const path = `${DEFAULT_BASE_URL}/teams/tag_settings/${settingId}/tags/${id}`;
  return deleteRequest<{}, TeamTagSettingTagScriptDeleteResponse>({}, path);
};

export const TeamTagSettingTagScriptApi = {
  fetch,
  create,
  update,
  destroy,
};
