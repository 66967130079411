import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useLogging } from "hooks/logging";
import TeamMemberFolderOrderApi from "javascripts/api/teams/members/folders/order";

export const teamMemberFolderOrderListState = atom({
  key: "teamMemberFolderOrderListState",
  default: [],
});

export const useTeamMemberFolderOrderList = () => {
  const { sendErrorLog } = useLogging();
  const [list, setList] = useRecoilState(teamMemberFolderOrderListState);

  useEffect(() => {
    TeamMemberFolderOrderApi.fetch()
      .then((data) => {
        setList(data);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch team member folder order",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};

export const useUpdateTeamMemberFolderOrder = () => {
  const { sendErrorLog } = useLogging();
  const [_, setList] = useRecoilState(teamMemberFolderOrderListState);

  const handle = useCallback(async (folderId: number, orderId: number, params: {}) => {
    try {
      const response = await TeamMemberFolderOrderApi.update(folderId, orderId, params);
      setList(response);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to update team member folder order",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};

export const useCreateTeamMemberFolderOrder = () => {
  const { sendErrorLog } = useLogging();
  const [_, setList] = useRecoilState(teamMemberFolderOrderListState);

  const handle = useCallback(async (folderId: number, params: {}) => {
    try {
      const response = await TeamMemberFolderOrderApi.create(folderId, params);
      setList(response);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to create team member folder order",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};
