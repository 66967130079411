import React from "react";

type Props = {
  width: string;
  height: string;
};

export const ZucksIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 24"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-326.000000, -99.000000)">
          <g transform="translate(326.607670, 99.578000)">
            <path
              d="M4.718,16.146 L9.392,18.844 L9.392,22.845 L1.391,18.225 L4.718,16.146 Z M16.32,15.999 L19.784,17.999 L11.392,22.845 L11.392,18.845 L16.32,15.999 Z M0,6.577 L3.464,8.577 L3.464,14.571 L0,16.736 L0,6.577 Z M20.784,6.107 L20.784,16.266 L17.32,14.267 L17.32,8.272 L20.784,6.107 Z M9.392,1.42108547e-14 L9.392,4 L4.465,6.844 L1.001,4.844 L9.392,1.42108547e-14 Z"
              fill="#063658"
            ></path>
            <polygon
              fill="#E36528"
              points="11.392 -1.46549439e-14 19.393 4.619 16.065 6.698 11.392 3.999"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};
