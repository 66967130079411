export const yearOptions = [...Array(100).keys()].map((i) => {
  const year = new Date().getFullYear() - i;
  return (
    <option
      key={year}
      value={year}
    >
      {year}
    </option>
  );
});

export const monthOptions = [...Array(12).keys()].map((i) => {
  return (
    <option
      key={i + 1}
      value={i + 1}
    >
      {i + 1}
    </option>
  );
});

export const dayOptions = [...Array(31).keys()].map((i) => {
  return (
    <option
      key={i + 1}
      value={i + 1}
    >
      {i + 1}
    </option>
  );
});
