import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { RedirectPageProps } from "domains";
import FolderRedirectPageApi from "javascripts/api/folders/redirectPage";

export const folderRedirectPageListState = atom<RedirectPageProps[]>({
  key: "folderRedirectPageListState",
  default: [],
});

export const useFolderRedirectPageList = (folderUid: string) => {
  const [redirectPageList, setRedirectPageList] = useRecoilState(folderRedirectPageListState);

  useEffect(() => {
    if (folderUid === "" || folderUid === null) {
      return;
    }

    FolderRedirectPageApi.fetchList(folderUid).then((data) => {
      setRedirectPageList(data.redirect_pages);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderUid]);

  return [redirectPageList] as const;
};
