import { get, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import {
  TeamAffiliateServiceProviderConnectionSettingResponse,
  TeamAffiliateServiceProviderConnectionSettingChild,
} from "domains";

export const fetchSettings = (): Promise<TeamAffiliateServiceProviderConnectionSettingResponse> =>
  get<{}, TeamAffiliateServiceProviderConnectionSettingResponse>(
    {},
    `${DEFAULT_BASE_URL}/teams/affiliate_service_providers/connection_settings`
  );

export const createAspConnectionSetting = (
  affiliateServiceProviderId: number
): Promise<TeamAffiliateServiceProviderConnectionSettingChild> =>
  post<{}, TeamAffiliateServiceProviderConnectionSettingChild>(
    {},
    `${DEFAULT_BASE_URL}/teams/affiliate_service_providers/${affiliateServiceProviderId}/connection_settings`
  );

export * as TeamAffiliateServiceProviderConnectionSettingApi from "./connectionSetting";
