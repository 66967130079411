import { useState } from "react";

export function useLocalStorage<T>(key: string, initialValue: T): [T, typeof setValue] {
  const [storedValue, setStoredValue] = useState<T>((): T => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T): void => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
    }
  };

  return [storedValue, setValue];
}
