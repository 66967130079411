import { get } from "api";
import { DEFAULT_BASE_URL } from "const/app";

export type UserEmailVerificationResponse = {
  is_email_verified: boolean;
};

const fetch = async (): Promise<UserEmailVerificationResponse> => {
  const ENDPOINT = `${DEFAULT_BASE_URL}/users/email_verification`;
  return get<{}, UserEmailVerificationResponse>({}, ENDPOINT);
};

export const UserEmailVerificationApi = {
  fetch,
} as const;
