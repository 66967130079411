import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class FolderExitPopupApi extends ApiBase {
  static fetchList(folderUid) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function (resolve, reject) {
      axios.get(`${baseUrl}/folders/${folderUid}/exit_popups`, { withCredentials: true})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response));
    });
  }
}

export default FolderExitPopupApi;
