import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestArticleSplitTestSettingParamsApi extends ApiBase {

  static fetch(uid) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
    axios.get(`${baseUrl}/ab_tests/${uid}/articles/split_test_settings/parameters`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default AbTestArticleSplitTestSettingParamsApi;
