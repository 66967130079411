import React from "react";
import { Tab, TabProps, Tabs, TabsProps } from "@mui/material";
import { PaletteColor, ThemeProvider, TypeBackground, useTheme } from "@mui/material/styles";
import { BackgroundColorProps, PositionProps } from "domains";
import { useShadowStyles } from "hooks";

type CustomTabsProps = TabsProps & {
  tabsProps?: TabsProps;
  shadowPosition: PositionProps;
  tabAttributes: Array<TabProps>;
  onValueChange?: (value: TabProps["value"]) => void;
  backgroundColor: BackgroundColorProps;
};

export const CustomTabs: React.FC<CustomTabsProps> = (props) => {
  const [value, setValue] = React.useState(props.defaultValue);
  const shadowStyles = useShadowStyles({
    position: props.shadowPosition,
    backgroundColor: props.backgroundColor,
  });
  const theme: {
    palette: { primary: PaletteColor; background: TypeBackground };
  } = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number | string) => {
    setValue(newValue);
    props.onValueChange?.(newValue);
  };

  const customTabsTheme = {
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "transparent !important",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              ...{
                overflow: "visible",
                fontWeight: "bold",
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.background[props.backgroundColor],
              },
              ...shadowStyles,
            },
          },
        },
      },
    },
  };

  const tabComponents = props.tabAttributes
    .filter((attributes) => attributes["value"] && attributes["label"])
    .map((attributes) => {
      return (
        <Tab
          key={attributes["value"]}
          label={attributes["label"]}
          value={attributes["value"]}
          disableRipple={attributes["disableRipple"]}
        />
      );
    });

  return (
    <ThemeProvider theme={{ ...theme, ...customTabsTheme }}>
      <Tabs
        {...props.tabsProps}
        value={value}
        onChange={handleChange}
      >
        {tabComponents}
      </Tabs>
    </ThemeProvider>
  );
};
