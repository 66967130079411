// NODE: recordとかで型指定するとすると型補完が効かなくなるのでそれぞれ指定
export const APP = {
  URL: import.meta.env.VITE_FRONTEND_URL as string | undefined,
  NEW_FRONTEND_URL: import.meta.env.VITE_NEW_FRONTEND_URL as string | undefined,
  BACKEND_URL: import.meta.env.VITE_BACKEND_URL as string | undefined,
  GRAPHQL_API_URL: import.meta.env.VITE_GRAPHQL_API_URL as string | undefined,
  LEGACY_GRAPHQL_API_URL: import.meta.env.VITE_LEGACY_GRAPHQL_API_URL as string | undefined,
  WEB_SOCKET_ENDPOINT: import.meta.env.VITE_WEB_SOCKET_ENDPOINT as string | undefined,
  FILE_API_URL: import.meta.env.VITE_SQUADBEYOND_FILE_URL as string | undefined,
  ASSET_HOST: import.meta.env.VITE_SQUADBEYOND_ASSET_HOST as string | undefined,
  BEYOND_BOOST_API_URL: `${import.meta.env.VITE_BEYOND_BOOST_API_URL}`,
  ADDON_ASSETS_URL: import.meta.env.VITE_ADDON_ASSETS_URL as string | undefined,
};

// throw an error if any of the required environment variables are missing
const appObject = APP as Record<string, string | undefined>;
for (const key of Object.keys(APP)) {
  if (appObject[key] === undefined) {
    // eslint-disable-next-line no-console
    console.warn(`Missing required environment variable: ${key}`);
  }
}

export const DEV_ENV = import.meta.env.DEV;

export const IS_PRODUCTION_MODE = import.meta.env.MODE === "production";

export const DEFAULT_BASE_URL: string =
  typeof APP.BACKEND_URL === "string" ? `${APP.BACKEND_URL}/api/v1` : "";

export const DEFAULT_BASE_V2_URL: string =
  typeof APP.BACKEND_URL === "string" ? `${APP.BACKEND_URL}/api/v2` : "";

export const DEFAULT_FILE_API_URL: string =
  typeof APP.FILE_API_URL === "string" ? `${APP.FILE_API_URL}/api/v1` : "";
