import React from "react";

type Props = {
  width: string;
  height: string;
};

export const NoinIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-500.000000, -1001.000000)"
          fill="#A5A5A5"
        >
          <g transform="translate(367.000000, 267.000000)">
            <g transform="translate(0.000000, 647.000000)">
              <path d="M143.154172,87 C144.49145,87 144.97638,87.1392384 145.465269,87.4006991 C145.954157,87.6621597 146.33784,88.0458426 146.599301,88.5347314 C146.860762,89.0236203 147,89.5085497 147,90.8458278 L147,97.1541722 C147,98.4914503 146.860762,98.9763797 146.599301,99.4652686 C146.33784,99.9541574 145.954157,100.33784 145.465269,100.599301 C144.97638,100.860762 144.49145,101 143.154172,101 L136.845828,101 C135.50855,101 135.02362,100.860762 134.534731,100.599301 C134.045843,100.33784 133.66216,99.9541574 133.400699,99.4652686 C133.139238,98.9763797 133,98.4914503 133,97.1541722 L133,90.8458278 C133,89.5085497 133.139238,89.0236203 133.400699,88.5347314 C133.66216,88.0458426 134.045843,87.6621597 134.534731,87.4006991 C135.02362,87.1392384 135.50855,87 136.845828,87 L143.154172,87 Z M135,92.5 L134,92.5 L134,95.5 L135,95.5 L135,94 L136,95.5 L137,95.5 L137,92.5 L136,92.5 L136,94 L135,92.5 Z M144,92.5 L143,92.5 L143,95.5 L144,95.5 L144,94 L145,95.5 L146,95.5 L146,92.5 L145,92.5 L145,94 L144,92.5 Z M139,92.5 C138.171573,92.5 137.5,93.1715729 137.5,94 C137.5,94.8284271 138.171573,95.5 139,95.5 C139.828427,95.5 140.5,94.8284271 140.5,94 C140.5,93.1715729 139.828427,92.5 139,92.5 Z M142,92.5 L141,92.5 L141,95.5 L142,95.5 L142,92.5 Z M139,93.5 C139.276142,93.5 139.5,93.7238576 139.5,94 C139.5,94.2761424 139.276142,94.5 139,94.5 C138.723858,94.5 138.5,94.2761424 138.5,94 C138.5,93.7238576 138.723858,93.5 139,93.5 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
