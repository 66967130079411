import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { AbTestDailyReportProps } from "domains";
import { useLogging } from "hooks/logging";
import AbTestAdDailyReportApi from "javascripts/api/abTest/adDailyReport";

export const abTestAdDailyReportListState = atom<AbTestDailyReportProps[]>({
  key: "abTestAdDailyReportListState",
  default: [],
});

export const useAbTestAdDailyReportList = (
  abTestUid: string,
  startDate: string,
  endDate: string,
) => {
  const { sendErrorLog } = useLogging();
  const [reportList, setReportList] = useRecoilState(abTestAdDailyReportListState);

  useEffect(() => {
    if (abTestUid === "" || abTestUid === null) {
      return;
    }

    const params = {
      start_date: startDate,
      end_date: endDate,
    };
    AbTestAdDailyReportApi.fetchReports(abTestUid, params)
      .then((data) => {
        setReportList(data.reports);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch abtest ad daily report",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid, startDate, endDate]);

  return [reportList] as const;
};
