import React from "react";

type Props = {
  width: string;
  height: string;
};

export const TiktokIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 25"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-650.000000, -700.000000)">
          <g transform="translate(650.000000, 700.000000)">
            <path
              d="M11.5,17.5 L11.5,1.5 L15.5,1.5 C15.5,4.26142375 17.7385763,6.5 20.5,6.5 L20.5,10.5 C18.6498534,10.5 16.930098,9.94172834 15.5,8.98445096 L15.5,17.5 C15.5,21.3659932 12.3659932,24.5 8.5,24.5 C4.63400675,24.5 1.5,21.3659932 1.5,17.5 C1.5,13.6340068 4.63400675,10.5 8.5,10.5 L8.5,14.5 C6.84314575,14.5 5.5,15.8431458 5.5,17.5 C5.5,19.1568542 6.84314575,20.5 8.5,20.5 C10.1568542,20.5 11.5,19.1568542 11.5,17.5 Z"
              fill="#FF004F"
            ></path>
            <path
              d="M10,16 L10,0 L14,0 C14,2.76142375 16.2385763,5 19,5 L19,9 C17.1498534,9 15.430098,8.44172834 14,7.48445096 L14,16 C14,19.8659932 10.8659932,23 7,23 C3.13400675,23 0,19.8659932 0,16 C0,12.1340068 3.13400675,9 7,9 L7,13 C5.34314575,13 4,14.3431458 4,16 C4,17.6568542 5.34314575,19 7,19 C8.65685425,19 10,17.6568542 10,16 Z"
              fill="#00F7EF"
            ></path>
            <path
              d="M14.2289011,1.5 C14.5823369,2.62529826 15.3228947,3.57916095 16.2944933,4.20550668 C16.9208391,5.17710525 17.8747017,5.9176631 19,6.27109886 L19,9 C17.1498534,9 15.430098,8.44172834 14,7.48445096 L14,16 C14,19.8659932 10.8659932,23 7,23 C5.44967544,23 4.01706435,22.4960087 2.85707024,21.6429298 C2.00399125,20.4829356 1.5,19.0503246 1.5,17.5 C1.5,14.1488814 3.85481799,11.34776 7,10.6610898 L7,13 C5.34314575,13 4,14.3431458 4,16 C4,17.2166504 4.72424604,18.2641441 5.76512857,18.7348714 C6.23585594,19.775754 7.28334961,20.5 8.5,20.5 C10.1568542,20.5 11.5,19.1568542 11.5,17.5 L11.5,1.5 L14.2289011,1.5 Z"
              fill="#000000"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
