import React, { useCallback, useEffect, useState } from "react";
import { ChromePicker, ColorResult, GithubPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { Box, Button, Popover } from "@mui/material";
import { TypeBackground, useTheme } from "@mui/material/styles";
import { useLocalStorage } from "hooks";

type ColorPickerProps = {
  children?: React.ReactNode | undefined;
  className?: string;
  defaultColor: string;
  colorProperty?: string;
  onAppyColor: (color: string, colorProperty: string | undefined, colorType?: string) => void;
};
export const ColorPicker: React.FC<ColorPickerProps> = ({
  children,
  className,
  defaultColor = "#000",
  colorProperty,
  onAppyColor,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<ColorResult>({
    hex: defaultColor,
    hsl: {
      h: 0,
      s: 0,
      l: 0,
    },
    rgb: {
      r: 0,
      g: 0,
      b: 0,
    },
  });
  const [colorHistory, setColorHistory] = useLocalStorage<Array<string>>(
    "sb_color_picker_history",
    []
  );
  const updateColor = useCallback((color: ColorResult) => setColor(color), []);
  const { hex } = color || {};
  const theme: { palette: { background: TypeBackground } } = useTheme();

  useEffect(() => {
    setColor({
      ...color,
      hex: defaultColor,
    });
  }, [defaultColor]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const defaultColors = [
    "#000000",
    "#ffffff",
    "#bbbbbb",
    "#888888",
    "#444444",
    "#e60000",
    "#facccc",
    "#f06666",
    "#a10000",
    "#5c0000",
    "#ff9900",
    "#ffebcc",
    "#ffc266",
    "#b26b00",
    "#663d00",
    "#ffff00",
    "#ffffcc",
    "#fffff66",
    "#b2b200",
    "#666600",
    "#008a00",
    "#cce8cc",
    "#66B966",
    "#006100",
    "#003700",
    "#0066cc",
    "#cce0f5",
    "#66a3e0",
    "#0047b2",
    "#002966",
    "#9933ff",
    "#ebd6ff",
    "#c285ff",
    "#6b24b2",
    "#3d140a",
    "#0000ff",
    "#ff0000",
    "#ff00ff",
    "#fa57cc",
    "#fae1f0",
  ];

  const colorType = defaultColors.includes(hex) ? "default" : "free";

  const apply = () => {
    onAppyColor(hex, colorProperty, colorType);
    if (colorType === "free") {
      const maxHistoryLength = 10;
      if (colorHistory.length === maxHistoryLength) {
        colorHistory.splice(0, 1);
        colorHistory.push(hex);
      } else {
        colorHistory.push(hex);
      }
      setColorHistory(colorHistory);
    }
    handleClose();
  };

  return (
    <>
      <Box onClick={handleClick}>{children}</Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          zIndex: "100000",
        }}
        className={className}
      >
        <Box
          sx={{
            width: "235px",
            ".chrome-picker": {
              width: "235px !important",
              boxShadow: "none !important",
              borderRadius: "2px 2px 0 0 !important",
              background: `${theme.palette.background.secondary} !important`,
            },
            ".github-picker": {
              boxShadow: "none !important",
              border: "none !important",
              width: "235px !important",
              boxSizing: "border-box",
              borderRadius: 0,
              background: `${theme.palette.background.secondary} !important`,
            },
          }}
        >
          <ChromePicker
            color={hex}
            onChange={updateColor}
            disableAlpha
          />
          <GithubPicker
            triangle={"hide"}
            color={hex}
            onChange={updateColor}
            colors={defaultColors}
          />
          {colorHistory.length !== 0 && (
            <Box sx={{ backgroundColor: "background.secondary", pt: 1 }}>
              <GithubPicker
                triangle={"hide"}
                color={hex}
                onChange={updateColor}
                colors={colorHistory}
              />
            </Box>
          )}
          <Box
            sx={{
              p: 1,
              backgroundColor: "background.secondary",
              borderRadius: "0 0 2px 2px  !important",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={apply}
            >
              {t("適用する")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
