import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const EditorIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 19"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-752.000000, -20.000000)"
          fill={iconColor}
        >
          <g>
            <g transform="translate(720.000000, 0.000000)">
              <path d="M48,20 C49.1045695,20 50,20.8954305 50,22 L50,25.583 L50.6886797,24.8956118 L52.1026797,26.3106118 L49.9993422,28.4136118 L49.999,25.586 L40.1715729,35.4142136 C39.8110889,35.7746975 39.7833594,36.3419286 40.0883843,36.7342198 L40.1715729,36.8284271 C40.5320568,37.1889111 41.0992879,37.2166406 41.4915791,36.9116157 L41.5857864,36.8284271 L50,28.413 L50,37 C50,38.1045695 49.1045695,39 48,39 L34,39 C32.8954305,39 32,38.1045695 32,37 L32,22 C32,20.8954305 32.8954305,20 34,20 L48,20 Z M38.4993422,31 L35.4993422,31 C35.2231998,31 34.9993422,31.2238576 34.9993422,31.5 C34.9993422,31.7761424 35.2231998,32 35.4993422,32 L35.4993422,32 L38.4993422,32 C38.7754846,32 38.9993422,31.7761424 38.9993422,31.5 C38.9993422,31.2238576 38.7754846,31 38.4993422,31 L38.4993422,31 Z M40.4993422,29 L35.4993422,29 C35.2231998,29 34.9993422,29.2238576 34.9993422,29.5 C34.9993422,29.7761424 35.2231998,30 35.4993422,30 L35.4993422,30 L40.4993422,30 C40.7754846,30 40.9993422,29.7761424 40.9993422,29.5 C40.9993422,29.2238576 40.7754846,29 40.4993422,29 L40.4993422,29 Z M42.4993422,27 L35.4993422,27 C35.2231998,27 34.9993422,27.2238576 34.9993422,27.5 C34.9993422,27.7761424 35.2231998,28 35.4993422,28 L35.4993422,28 L42.4993422,28 C42.7754846,28 42.9993422,27.7761424 42.9993422,27.5 C42.9993422,27.2238576 42.7754846,27 42.4993422,27 L42.4993422,27 Z M53.8994949,23.1005051 C54.2900192,23.4910294 54.2900192,24.1241943 53.8994949,24.5147186 L52.8106797,25.6036118 L51.3956797,24.1886118 L52.4852814,23.1005051 C52.8758057,22.7099808 53.5089706,22.7099808 53.8994949,23.1005051 Z M40.9993422,23 L35.9993422,23 C35.4470574,23 34.9993422,23.4477153 34.9993422,24 C34.9993422,24.5522847 35.4470574,25 35.9993422,25 L35.9993422,25 L40.9993422,25 C41.5516269,25 41.9993422,24.5522847 41.9993422,24 C41.9993422,23.4477153 41.5516269,23 40.9993422,23 L40.9993422,23 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
