import { patch } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { UserProps } from "domains";

export type UserSettingEmailRequestParams = {
  user: {
    email: string;
  };
};

const update = async (params: UserSettingEmailRequestParams) => {
  const path = `${DEFAULT_BASE_URL}/users/settings/email`;
  return patch<UserSettingEmailRequestParams, UserProps>(params, path);
};

export const UserSettingEmailApi = {
  update,
} as const;
