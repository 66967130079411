import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ConversionEventTagArticleDailyReportProps } from "domains";
import AbTestConversionEventTagArticleDailyReportApi from "javascripts/api/abTest/conversionEventTags/articles/dailyReport";

export const abTestArticleConversionEventTagDailyReportListState = atom<
  ConversionEventTagArticleDailyReportProps[]
>({
  key: "abTestArticleConversionEventTagDailyReportListState",
  default: [],
});

export const useAbTestConversionEventTagArticleDailyReportList = (
  abTestUid: string,
  startDate: string,
  endDate: string
) => {
  const [reportList, setReportList] = useRecoilState(
    abTestArticleConversionEventTagDailyReportListState
  );

  useEffect(() => {
    const params = {
      start_date: startDate,
      end_date: endDate,
    };
    AbTestConversionEventTagArticleDailyReportApi.fetchReports(abTestUid, params).then((data) => {
      setReportList(data.daily_reports);
    });
  }, []);

  return [reportList] as const;
};
