import React, { ReactNode } from "react";
import { ErrorPanel } from "components";
import { collectMessage } from "javascripts/utils";
import { SendErrorLog, useLogging } from "../hooks";

type Props = { children?: ReactNode; sendErrorLog: SendErrorLog };

class ErrorBoundaryInner extends React.Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: { name: string; message: string }) {
    this.props.sendErrorLog({ error });
    collectMessage(`name: ${error.name}. message: ${error.message}`);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPanel />;
    }

    return this.props.children;
  }
}

function ErrorBoundary(props: { children?: ReactNode }) {
  const { sendErrorLog } = useLogging();

  return <ErrorBoundaryInner sendErrorLog={sendErrorLog}>{props.children}</ErrorBoundaryInner>;
}

export { ErrorBoundary };
