import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { AbTestArticleParameterExclusionSettingProps } from "domains";
import { useLogging } from "hooks/logging";
import AbTestArticleParameterExclusionSettingApi from "javascripts/api/abTest/articles/parameterExclusionSetting";
import AbTestParameterExclusionSettingApi from "javascripts/api/abTest/parameterExclusionSetting";

export const abTestArticleParameterExclusionSettingListState = atom<
  AbTestArticleParameterExclusionSettingProps[]
>({
  key: "abTestArticleParameterExclusionSettingListState",
  default: [],
});

export const useAbTestParameterExclusionSettingList = (abTestUid: string) => {
  const { sendErrorLog } = useLogging();
  const [abTestParameterExclusionSettingList, setAbTestParameterExclusionSettingList] =
    useRecoilState(abTestArticleParameterExclusionSettingListState);

  useEffect(() => {
    try {
      AbTestParameterExclusionSettingApi.fetchSettings(abTestUid).then((data) => {
        setAbTestParameterExclusionSettingList(data.settings);
      });
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to fetch abtest parameter exclusion setting list.",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [abTestParameterExclusionSettingList] as const;
};

export const useCreateAbTestArticleParameterExclusionSetting = () => {
  const [
    abTestArticleParameterExclusionSettingList,
    setAbTestArticleParameterExclusionSettingList,
  ] = useRecoilState(abTestArticleParameterExclusionSettingListState);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const handle = useCallback(
    async (abTestUid: string, articleUid: string, parameter: string) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);

      try {
        const params = {
          parameter: parameter,
        };
        const response = await AbTestArticleParameterExclusionSettingApi.createSetting(
          abTestUid,
          articleUid,
          params,
        );
        setAbTestArticleParameterExclusionSettingList([
          ...abTestArticleParameterExclusionSettingList,
          response,
        ]);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [abTestArticleParameterExclusionSettingList],
  );

  return [handle, error, loading] as const;
};

export const useDeleteAbTestArticleParameterExclusionSetting = () => {
  const [
    abTestArticleParameterExclusionSettingList,
    setAbTestArticleParameterExclusionSettingList,
  ] = useRecoilState(abTestArticleParameterExclusionSettingListState);
  const [_error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  const handle = useCallback(
    async (abTestUid: string, articleUid: string, settingId: number) => {
      if (loading) {
        return;
      }

      setError(undefined);
      setLoading(true);

      try {
        await AbTestArticleParameterExclusionSettingApi.destroySetting(
          abTestUid,
          articleUid,
          settingId,
        );
        const newList = abTestArticleParameterExclusionSettingList.filter(
          (setting: AbTestArticleParameterExclusionSettingProps) => setting.id != settingId,
        );
        setAbTestArticleParameterExclusionSettingList(newList);
        setLoading(false);
      } catch (e) {
        setError(e);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [abTestArticleParameterExclusionSettingList],
  );

  return [handle, loading] as const;
};
