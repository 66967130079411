import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { TeamDomainApi } from "api/teams/domains";
import { TeamDomainProps } from "domains";

export const thirdPartyDomainListState = atom<TeamDomainProps[]>({
  key: "thirdPartyDomainListState",
  default: [],
});

export const squadbeyondDomainListState = atom<TeamDomainProps[]>({
  key: "squadbeyondDomainListState",
  default: [],
});

export const ownedDomainListState = atom<TeamDomainProps[]>({
  key: "ownedDomainListState",
  default: [],
});

export const useTeamDomainList = () => {
  const [thirdPartyDomainList, setThirdPartyDomainList] = useRecoilState(thirdPartyDomainListState);
  const [squadbeyondDomainList, setSquadbeyondDomainList] = useRecoilState(
    squadbeyondDomainListState
  );
  const [_error, setError] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await TeamDomainApi.fetchDomains();
        setThirdPartyDomainList(response.team_domains);
        setSquadbeyondDomainList(response.team_squadbeyond_domains);
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [thirdPartyDomainList, squadbeyondDomainList] as const;
};

export const useOwendDomainList = () => {
  const [ownedDomainList, setOwnedDomainList] = useRecoilState(ownedDomainListState);
  const [error, setError] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await TeamDomainApi.fetchDomains();
        setOwnedDomainList(response.owned_domains);
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [ownedDomainList, error] as const;
};

export const useCreateDomain = () => {
  const [ownedDomainList, setOwnedDomainList] = useRecoilState(ownedDomainListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const handle = async (params: {
    team_domain: {
      domain: string;
    };
  }) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      const response: TeamDomainProps = await TeamDomainApi.createDomain(params);
      if (response) {
        setOwnedDomainList([response, ...ownedDomainList]);
        setLoading(false);
      }
      return true;
    } catch (e: unknown) {
      setLoading(false);
      setError(e);
      return false;
    }
  };

  return [handle, loading, error] as const;
};

export const useDestroyDomain = () => {
  const [ownedDomainList, setOwnedDomainList] = useRecoilState(ownedDomainListState);
  const [error, setError] = useState<any>();
  const handle = async (
    domainId: number,
    params: {
      confirmation_text: string;
    }
  ) => {
    try {
      await TeamDomainApi.destroyDomain({ domainId: domainId.toString(), params });
      const filteredDomainList = ownedDomainList.filter((element) => element.id !== domainId);
      setOwnedDomainList(filteredDomainList);
    } catch (e: unknown) {
      setError(e);
    }
  };
  return [handle, error] as const;
};

export const useUpdateDomain = () => {
  const [ownedDomainList, setOwnedDomainList] = useRecoilState(ownedDomainListState);
  const [error, setError] = useState<any>();
  const handle = async (
    domainId: number,
    params: {
      team_domain: {
        description: string;
      };
    }
  ) => {
    try {
      const response: TeamDomainProps = await TeamDomainApi.updateDomain({
        domainId: domainId.toString(),
        params,
      });
      const list = [...ownedDomainList];
      const index = list.findIndex((d) => d.id === domainId);
      if (response) {
        list[index] = response;
      }
      setOwnedDomainList(list);
    } catch (e: unknown) {
      setError(e);
    }
  };
  return [handle, error] as const;
};
