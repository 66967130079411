import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class ArticleLatestInspectionRequestApi extends ApiBase {
  static fetchRequest(articleUid) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/articles/${articleUid}/latest_inspection_request`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static delete(articleUid) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.delete(`${baseUrl}/articles/${articleUid}/latest_inspection_request`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default ArticleLatestInspectionRequestApi;
