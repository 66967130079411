import { SVGProps } from "react";

export const Logo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_404_3295)">
        <path
          d="M8.81242 19.2518L15.6946 12.4154C18.0725 10.0533 21.9246 10.0533 24.3027 12.4154C26.6851 14.7821 26.6851 18.6226 24.3026 20.9893L17.4205 27.8256L15.6946 26.0881L22.5767 19.2518C23.9951 17.8428 23.9951 15.5619 22.5767 14.1529C21.1538 12.7394 18.8434 12.7394 17.4205 14.1529L10.5383 20.9892L8.81242 19.2518Z"
          fill="#131415"
        />
        <path
          d="M1.92959 12.414C4.30761 10.0519 8.15969 10.0519 10.5377 12.414C11.0174 12.8906 11.02 13.6659 10.5434 14.1457C10.0777 14.6146 9.32663 14.6277 8.84493 14.1832L8.81176 14.1515C7.38884 12.738 5.07844 12.738 3.65551 14.1515C2.23712 15.5604 2.23712 17.8414 3.65549 19.2504C5.06246 20.648 7.33703 20.6637 8.76346 19.2975L8.81176 19.2504C9.29155 18.7738 10.0668 18.7764 10.5434 19.2562C11.02 19.7359 11.0174 20.5112 10.5377 20.9878C8.15969 23.35 4.30761 23.35 1.92957 20.9879C-0.452879 18.6212 -0.452879 14.7807 1.92959 12.414Z"
          fill="#131415"
        />
        <path
          d="M8.80632 26.0938C9.27209 25.625 10.0231 25.6118 10.5048 26.0563L10.538 26.0881C11.9449 27.4856 14.2195 27.5013 15.646 26.1352L15.6942 26.0881C16.174 25.6115 16.9493 25.6141 17.4259 26.0938C17.9025 26.5736 17.8999 27.3489 17.4202 27.8255C15.0422 30.1877 11.1901 30.1877 8.8121 27.8255C8.33232 27.3489 8.32973 26.5736 8.80632 26.0938Z"
          fill="#131415"
        />
        <path
          d="M22.5758 19.2502L29.4579 12.4138C31.8359 10.0517 35.688 10.0517 38.066 12.4138C40.4485 14.7805 40.4485 18.621 38.066 20.9877L31.1838 27.824C30.704 28.3006 29.9287 28.298 29.4521 27.8182C28.9755 27.3384 28.9781 26.5631 29.4579 26.0865L36.3401 19.2502C37.7585 17.8412 37.7585 15.5602 36.3401 14.1513C34.9171 12.7378 32.6067 12.7378 31.1838 14.1513L24.3017 20.9876C23.8219 21.4642 23.0466 21.4616 22.57 20.9819C22.0934 20.5021 22.096 19.7268 22.5758 19.2502Z"
          fill="#0091FF"
        />
        <path
          d="M15.6969 12.4142C18.0748 10.0521 21.9269 10.0521 24.305 12.4142C26.6874 14.7809 26.6874 18.6214 24.305 20.9881C23.8252 21.4647 23.0499 21.4621 22.5733 20.9823C22.1075 20.5134 22.0994 19.7623 22.5471 19.2836L22.579 19.2506C23.9974 17.8416 23.9974 15.5606 22.579 14.1517C21.1721 12.7541 18.8975 12.7384 17.4711 14.1046L17.4228 14.1517C16.943 14.6283 16.1677 14.6257 15.6911 14.1459C15.2145 13.6661 15.2171 12.8908 15.6969 12.4142Z"
          fill="#0091FF"
        />
        <path
          d="M24.3039 26.0884C25.7269 27.5018 28.0373 27.5018 29.4602 26.0884L31.1861 27.8258C28.8081 30.188 24.956 30.188 22.578 27.8258L24.3039 26.0884Z"
          fill="#0091FF"
        />
        <path
          d="M15.6933 12.4159C18.0713 10.0538 21.9234 10.0538 24.3014 12.4159C26.6838 14.7826 26.6838 18.6231 24.3014 20.9898C23.8216 21.4664 23.0463 21.4638 22.5697 20.984C22.1039 20.5152 22.0958 19.764 22.5435 19.2853L22.5754 19.2524C23.9938 17.8434 23.9938 15.5624 22.5755 14.1534C21.1525 12.74 18.8421 12.74 17.4192 14.1534C16.0167 15.5466 16.001 17.7922 17.3719 19.2045L17.4192 19.2524C17.899 19.729 17.9015 20.5043 17.4249 20.984C16.9483 21.4638 16.173 21.4664 15.6932 20.9898C13.3108 18.6231 13.3108 14.7826 15.6933 12.4159Z"
          fill="#0091FF"
        />
        <path
          d="M22.57 26.0938C23.0358 25.625 23.7868 25.6118 24.2685 26.0563L24.3017 26.0881C25.7086 27.4856 27.9832 27.5013 29.4096 26.1352L29.4579 26.0881C29.9377 25.6115 30.713 25.6141 31.1896 26.0938C31.6662 26.5736 31.6636 27.3489 31.1838 27.8255C28.8058 30.1877 24.9538 30.1877 22.5758 27.8255C22.096 27.3489 22.0934 26.5736 22.57 26.0938Z"
          fill="#0091FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_404_3295">
          <rect
            width="40"
            height="40"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
