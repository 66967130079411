import {
  FolderIcon as FolderIconOutline,
  ArrowPathIcon as ArrowPathIconOutline,
  SwatchIcon as SwatchIconOutline,
  ShieldCheckIcon as ShieldCheckIconOutline,
  ClipboardDocumentListIcon as ClipboardDocumentListIconOutline,
} from "@heroicons/react/24/outline";
import {
  FolderIcon as FolderIconSolid,
  ArrowPathIcon as ArrowPathIconSolid,
  SwatchIcon as SwatchIconSolid,
  ShieldCheckIcon as ShieldCheckIconSolid,
  ClipboardDocumentListIcon as ClipboardDocumentListIconSolid,
} from "@heroicons/react/24/solid";
import { ConversionTagSolidIcon } from "components/atoms/icons";
import { BaseLocalNavigation as LocalNav } from "components/organisms/core/BaseLocalNavigation";
import { FOLDER_OR_GROUP_REGEXP, ICON_SIZE, NAV_IDS } from "../consts";
import type { FolderAndGroupNavigationProps } from "./type";

const getCurrentNavId = (pathName: string): keyof typeof NAV_IDS => {
  if (FOLDER_OR_GROUP_REGEXP.tags.test(pathName)) return NAV_IDS.tags;
  if (FOLDER_OR_GROUP_REGEXP.bulkReplace.test(pathName)) return NAV_IDS.bulkReplaces;
  if (FOLDER_OR_GROUP_REGEXP.media.test(pathName)) return NAV_IDS.media;
  if (FOLDER_OR_GROUP_REGEXP.inspection.test(pathName)) return NAV_IDS.inspections;
  if (FOLDER_OR_GROUP_REGEXP.form.test(pathName)) return NAV_IDS.forms;

  return NAV_IDS.folders;
};

export const FolderAndGroupNavigation = ({ pathName }: FolderAndGroupNavigationProps) => {
  return (
    <LocalNav
      selectedId={getCurrentNavId(pathName)}
      items={[
        {
          id: NAV_IDS.folders,
          title: "フォルダ",
          href: "/folders",
          icon: (
            <FolderIconOutline
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <FolderIconSolid
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.tags,
          title: "一括タグ",
          href: "/teams/tags",
          icon: (
            <ConversionTagSolidIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <ConversionTagSolidIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.bulkReplaces,
          title: "マジック\n置換",
          href: "/articles/bulk_replaces",
          icon: (
            <ArrowPathIconOutline
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <ArrowPathIconSolid
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.media,
          title: "メディア",
          href: "/teams/product_search_forms",
          icon: (
            <SwatchIconOutline
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <SwatchIconSolid
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.inspections,
          title: "審査",
          href: "/inspections",
          icon: (
            <ShieldCheckIconOutline
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <ShieldCheckIconSolid
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.forms,
          title: "フォーム",
          href: "/folders/forms",
          icon: (
            <ClipboardDocumentListIconOutline
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <ClipboardDocumentListIconSolid
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
      ]}
    />
  );
};
