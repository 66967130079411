import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class ArticleHtmlPartCommentApi extends ApiBase {
  static create(partId, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/articles/html_parts/${partId}/comments`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static update(partId, commentId, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/articles/html_parts/${partId}/comments/${commentId}`;
      axios.patch(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static delete(partId, commentId) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/articles/html_parts/${partId}/comments/${commentId}`;
      axios.delete(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default ArticleHtmlPartCommentApi;
