export const OriginalVersionOutlineIcon = ({
  width,
  height,
}: {
  width: number;
  height: number;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.099 4.68457L8.29102 6.71337V14.8798L13.099 12.9134V4.68457Z"
      stroke="currentColor"
      strokeWidth="1.152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4041 4.33594V2.90234L5.59766 4.92954V13.0975L6.94486 12.5455"
      stroke="currentColor"
      strokeWidth="1.152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.70839 2.55372V1.12012L2.90039 3.14732V11.3153L4.24919 10.7633"
      stroke="currentColor"
      strokeWidth="1.152"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
