import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { AdProps } from "domains";
import AbTestAdApi from "javascripts/api/abTest/ad";

export const adListState = atom<AdProps[]>({
  key: "adListState",
  default: [],
});

export const useAbTestAdList = (abTestUid: string) => {
  const [adList, setAdList] = useRecoilState(adListState);

  useEffect(() => {
    AbTestAdApi.fetchAds(abTestUid).then((data) => {
      data.ads.map((ad: AdProps) => {
        const url = new URL(ad.click_url);
        const urlParams = new URLSearchParams(url.search);
        const parameterList: string[] = [];
        urlParams.forEach((value, key) => {
          const parameter = `${key}=${value}`.toLowerCase();
          parameterList.push(parameter);
        });
        ad.parameterList = parameterList;
      });
      setAdList(data.ads);
    });
  }, []);

  return [adList] as const;
};
