import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { MagnifierIcon } from "components";

export const SearchField = ({ ...props }: TextFieldProps) => {
  return (
    <TextField
      type="search"
      {...props}
      InputProps={{
        ...{
          startAdornment: (
            <InputAdornment position="start">
              <MagnifierIcon
                color="primary"
                width={"16px"}
                height={"16px"}
              />
            </InputAdornment>
          ),
        },
        ...props.InputProps,
      }}
      onChange={props.onChange}
    ></TextField>
  );
};
