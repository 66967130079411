import { AbTestProps } from "domains";

export const filterAbTestListByQuery = (list: AbTestProps[], query: string): AbTestProps[] => {
  if (query === "") {
    return list;
  }
  return list.filter(
    (abTest: AbTestProps) =>
      `${abTest.title} ${abTest.media?.attributes?.name}`
        .toLowerCase()
        .indexOf(query.toLowerCase()) !== -1,
  );
};

export const filterAbTestListByAdStatus = (
  list: AbTestProps[],
  adStatus: string,
): AbTestProps[] => {
  if (adStatus === "exceptFinished") {
    return list.filter((abTest: AbTestProps) => abTest.ad_status !== "finished");
  } else {
    return list.filter((abTest: AbTestProps) => abTest.ad_status === adStatus);
  }
};

export const sortAbTestList = (
  list: AbTestProps[],
  order: string,
  orderBy: string,
): AbTestProps[] => {
  const newList = [...list];
  const isString = ["title", "ad_status"].includes(orderBy);

  newList.sort((prev: AbTestProps, current: AbTestProps): number => {
    let result = 0;

    if (isString) {
      const prevValue = prev[orderBy as keyof typeof prev];
      const currentValue = current[orderBy as keyof typeof current];
      if (order == "asc") {
        if (prevValue! < currentValue!) {
          return -1;
        }
        if (prevValue! > currentValue!) {
          return 1;
        }
        return 0;
      } else {
        if (currentValue! < prevValue!) {
          return -1;
        }
        if (currentValue! > prevValue!) {
          return 1;
        }
        return 0;
      }
    } else {
      result = (prev?.count?.[orderBy] || 0) - (current?.count?.[orderBy] || 0);
      if (order == "asc") {
        return result;
      } else {
        return -result;
      }
    }
  });

  return newList;
};
