import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestArticleParameterAllowlistApi extends ApiBase {

  static create(abTestUid, articleUid, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/ab_tests/${abTestUid}/articles/${articleUid}/parameter_allowlists`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroy(abTestUid, articleUid, id) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/ab_tests/${abTestUid}/articles/${articleUid}/parameter_allowlists/${id}`;
      axios.delete(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestArticleParameterAllowlistApi;
