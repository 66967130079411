import { deleteRequest, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { UserProps } from "domains";

export type UserSettingEmailConfirmationRequest = {
  user: {
    code: string;
    is_current_email_verified: boolean;
  };
};

const create = async (params: UserSettingEmailConfirmationRequest) => {
  const path = `${DEFAULT_BASE_URL}/users/settings/email/confirmation`;
  return post<UserSettingEmailConfirmationRequest, UserProps>(params, path);
};

const destroy = async () => {
  const path = `${DEFAULT_BASE_URL}/users/settings/email/confirmation`;
  return deleteRequest<undefined, UserProps>(undefined, path);
};

export const UserSettingEmailConfirmationApi = {
  create,
  destroy,
} as const;
