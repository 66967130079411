import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useLocalStorage } from "hooks/local-storage";
import { useTeamMember } from "hooks/team-member";
import Cookies from "js-cookie";

const STORAGE_KEY = "recent_page";
const UNKNOWN_TEAM_ID = "unknown";

type RecentPage = {
  title: string;
  href: string;
  type: "folder" | "beyond_page";
  uid: string;
  id: string;
  teamId: string;
};
const recentPageState = atom<RecentPage[]>({
  key: "recentPageState",
  default: [] as RecentPage[],
});

const trimArrayToMaxLength = (arr: RecentPage[], maxLength = 30): RecentPage[] => {
  return arr.length > maxLength ? arr.slice(0, maxLength) : arr;
};

const isSamePage = (a: RecentPage, b: RecentPage) =>
  a.href === b.href && a.type === b.type && a.uid === b.uid;

const useRecentPageStorage = (key: string) => {
  const cookieValueStr = Cookies.get(key) || undefined;
  const cookieValue = cookieValueStr ? (JSON.parse(cookieValueStr) as RecentPage[]) : undefined;
  const [storedValue, setStoredValue] = useLocalStorage<RecentPage[]>(key, []);

  // if (!cookieValue?.length) {
  //   Cookies.set(key, storedValue, {
  //     domain: ".squadbeyond.com",
  //     path: "/",
  //   });
  // }

  const value: RecentPage[] = cookieValue?.length ? cookieValue : storedValue;
  const setValue = (newValue: RecentPage[]) => {
    setStoredValue(newValue);
    // Cookies.remove(key);
    // Cookies.set(key, newValue, {
    //   domain: ".squadbeyond.com",
    //   path: "/",
    // });
  };

  return [value, setValue] as const;
};

const refreshCookie = () => {
  const cookieValueStr = Cookies.get();
  const keys = Object.keys(cookieValueStr);
  const recentPageKeys = keys.filter((key) => key.includes(STORAGE_KEY));
  recentPageKeys.forEach((key) =>
    Cookies.remove(key, {
      domain: ".squadbeyond.com",
      path: "/",
    }),
  );
};

export const useRecentPage = (): readonly [
  RecentPage[],
  (value: Omit<RecentPage, "teamId">) => void,
] => {
  refreshCookie();
  const { team } = useTeamMember();
  const teamId = team?.id?.toString() || UNKNOWN_TEAM_ID;
  const [storedValue, setStoredValue] = useRecentPageStorage(`${STORAGE_KEY}_${teamId}`);
  const [_state, setState] = useRecoilState(recentPageState);
  const state = _state.filter((o) => o.id);

  const updateState = (value: RecentPage[]) => {
    setState(trimArrayToMaxLength(value));
    setStoredValue(trimArrayToMaxLength(value));
  };

  useEffect(() => {
    if (!storedValue.length) {
      updateState(storedValue);
      return;
    }
    updateState(storedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const update = (value: Omit<RecentPage, "teamId">) => {
    const v = { ...value, teamId: teamId } as RecentPage;
    if (storedValue.length === 0) {
      updateState([v]);
      return;
    }
    const filteredValue = storedValue.filter((o) => !isSamePage(o, v));
    if (!filteredValue.length) return;
    updateState([v, ...filteredValue]);
  };

  return [state, update] as const;
};
