import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { useLogging } from "hooks/logging";
import ShopifyPrivateApplicationApi from "javascripts/api/teams/shopifyPrivateApplication";

// TODO: Change to ShopifyPrivateApplicationProps
export type ShopifyPrivateApplication = {
  id: number;
  host: string;
  created_date: string;
};

const shopifyPrivateApplicationListState = atom<ShopifyPrivateApplication[]>({
  key: "shopifyPrivateApplicationListState",
  default: [],
});

export const useShopifyPrivateApplicationList = () => {
  const { sendErrorLog } = useLogging();
  const [appList, setAppList] = useRecoilState(shopifyPrivateApplicationListState);

  useEffect(() => {
    ShopifyPrivateApplicationApi.fetchAccounts()
      .then((data) => {
        setAppList(data.shopify_private_applications);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch shopify account",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [appList] as const;
};

export const useCreateShopifyPrivateApplication = () => {
  const [appList, setAppList] = useRecoilState(shopifyPrivateApplicationListState);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (host: string, password: string) => {
      try {
        const params = {
          host: host,
          password: password,
        };
        const response = await ShopifyPrivateApplicationApi.createAccount(params);
        setAppList([response, ...appList]);
        return true;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appList],
  );

  return [handle, error] as const;
};

export const useDeleteShopifyPrivateApplication = () => {
  const { sendErrorLog } = useLogging();
  const [appList, setAppList] = useRecoilState(shopifyPrivateApplicationListState);

  const handle = (app: ShopifyPrivateApplication) => {
    ShopifyPrivateApplicationApi.destroyAccount(app.id)
      .then(() => {
        const list = appList.filter((d) => d.id != app.id);
        setAppList(list);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to destroy shopify account",
        });
      });
  };

  return [handle];
};
