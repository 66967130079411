import { useEffect, useMemo } from "react";
import { atom, useRecoilState } from "recoil";
import { AbTestDailyReportProps } from "domains";
import { abTestListState } from "hooks";
import FolderAbTestDailyReportApi from "javascripts/api/folders/abTests/dailyReport";
import { sumUpAbTestDailyReportList } from "utils";

export const abTestDailyReportListState = atom<AbTestDailyReportProps[]>({
  key: "abTestDailyReportListState",
  default: [],
});

export const useAbTestDailyReportList = (folderUid: string, startDate: string, endDate: string) => {
  const [abTestList] = useRecoilState(abTestListState);
  const [abTestDailyReportList, setAbTestDailyReportList] = useRecoilState(
    abTestDailyReportListState
  );

  useEffect(() => {
    if (folderUid === "" || folderUid === null) {
      return;
    }

    const params = {
      start_date: startDate,
      end_date: endDate,
    };
    FolderAbTestDailyReportApi.fetchReports(folderUid, params)
      .then((data) => {
        setAbTestDailyReportList(data.reports);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderUid, startDate, endDate]);

  const abTestReportList = useMemo(
    () => sumUpAbTestDailyReportList(abTestList, abTestDailyReportList),
    [abTestList, abTestDailyReportList]
  );

  return [abTestReportList] as const;
};
