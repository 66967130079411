import React from "react";

type Props = {
  width: string;
  height: string;
};

export const AkaneIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 22"
    >
      <defs>
        <polygon
          id="akene-icon-path-1"
          points="12 0 23.4126782 8.29179607 19.053423 21.7082039 4.94657697 21.7082039 0.587321804 8.29179607"
        ></polygon>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-134.000000, -100.000000)">
          <g transform="translate(134.000000, 100.000000)">
            <mask
              id="akane-icon-mask-2"
              fill="white"
            >
              <use href="#akene-icon-path-1"></use>
            </mask>
            <use
              fill="#FAD7A0"
              href="#akene-icon-path-1"
            ></use>
            <polygon
              fill="#E65582"
              mask="url(#akane-icon-mask-2)"
              points="12 0 26 0 28 7 12 12"
            ></polygon>
            <polygon
              fill="#9FC8EB"
              mask="url(#akane-icon-mask-2)"
              points="25 30 26 30 28 7 12 12"
            ></polygon>
            <polygon
              fill="#A587BE"
              mask="url(#akane-icon-mask-2)"
              points="25 30 -1 30 12 12"
            ></polygon>
            <polygon
              fill="#D7E6C3"
              mask="url(#akane-icon-mask-2)"
              transform="translate(4.000000, 6.000000) scale(-1, 1) translate(-4.000000, -6.000000) "
              points="-4 0 10 0 12 7 -4 12"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};
