import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useLocalStorage } from "hooks";
import Litepicker from "litepicker";

export const DateListSelect: React.FC<{
  startDate: string;
  endDate: string;
  updateDateList: (startDate: string, endDate: string) => void;
  disabled?: boolean;
}> = ({ startDate, endDate, updateDateList, disabled }) => {
  const inputStartDateRef = useRef();
  const inputEndDateRef = useRef();
  const [language] = useLocalStorage("language", "ja");
  const { t } = useTranslation();

  useEffect(() => {
    if (inputStartDateRef.current === undefined || inputEndDateRef.current === undefined) {
      return;
    }
    new Litepicker({
      element: inputStartDateRef.current,
      elementEnd: inputEndDateRef.current,
      singleMode: false,
      lang: language,
      format: "YYYY/MM/DD",
      maxDate: new Date(),
      tooltipText: { one: t("日~.単日", { value: "" }), other: t("日~.期間", { value: "" }) },
      setup: (picker) => {
        picker.on("selected", (date1, date2) => {
          updateDateList(date1.format("YYYY-MM-DD"), date2.format("YYYY-MM-DD"));
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStartDateRef, inputEndDateRef]);

  return (
    <>
      <TextField
        sx={{ width: "120px", mr: 1 }}
        inputProps={{
          sx: { fontSize: "14px" },
        }}
        label={t("開始日")}
        variant="outlined"
        size="small"
        value={startDate}
        inputRef={inputStartDateRef}
        disabled={disabled}
      />
      <TextField
        sx={{ width: "120px" }}
        inputProps={{
          sx: { fontSize: "14px" },
        }}
        label={t("終了日")}
        variant="outlined"
        size="small"
        value={endDate}
        inputRef={inputEndDateRef}
        disabled={disabled}
      />
    </>
  );
};
