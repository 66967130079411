import axios from "axios";
import { BranchOperationColumnProps } from "domains";
import ApiBase from "./apiBase";

type FetchBranchOperationUserColumnResponse = {
  master_columns: BranchOperationColumnProps[];
  default_columns: BranchOperationColumnProps[];
  user_columns: BranchOperationColumnProps[];
};

class BranchOperationUserColumnApi extends ApiBase {
  static fetchColumns() {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchBranchOperationUserColumnResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/branch_operation_user_columns`, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }
  static createColumns(params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .post(`${baseUrl}/branch_operation_user_columns`, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default BranchOperationUserColumnApi;
