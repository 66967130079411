import React from "react";

type Props = {
  width: string;
  height: string;
};

export const BuzzvideoIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 26"
    >
      <defs>
        <linearGradient
          x1="100%"
          y1="50%"
          x2="0%"
          y2="50%"
          id="linearGradient-1"
        >
          <stop
            stopColor="#FFF001"
            offset="0%"
          ></stop>
          <stop
            stopColor="#FFF001"
            stopOpacity="0"
            offset="100%"
          ></stop>
        </linearGradient>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-694.000000, -700.000000)">
          <g transform="translate(694.000000, 700.000000)">
            <circle
              fill="url(#linearGradient-1)"
              cx="11"
              cy="11"
              r="11"
            ></circle>
            <ellipse
              fill="#FFF001"
              cx="11"
              cy="13"
              rx="11"
              ry="10"
            ></ellipse>
            <circle
              fill="url(#linearGradient-1)"
              transform="translate(11.000000, 15.000000) scale(-1, 1) translate(-11.000000, -15.000000) "
              cx="11"
              cy="15"
              r="11"
            ></circle>
            <path
              d="M9.48564293,8.82535718 L15.4265169,12.1258427 C15.9093006,12.3940559 16.0832444,13.0028592 15.8150312,13.4856429 C15.7243423,13.648883 15.589757,13.7834684 15.4265169,13.8741573 L9.48564293,17.1746428 C9.0028592,17.442856 8.39405591,17.2689122 8.12584272,16.7861285 C8.0433105,16.6375705 8,16.4704298 8,16.3004855 L8,9.69951446 C8,9.14722971 8.44771525,8.69951446 9,8.69951446 C9.16994425,8.69951446 9.33708493,8.74282496 9.48564293,8.82535718 Z"
              fill="#010001"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
