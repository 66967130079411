import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ConversionEventTagProps } from "domains";
import AbTestConversionEventTagApi from "javascripts/api/abTest/conversionEventTag";

export const abTestConversionEventTagListState = atom<ConversionEventTagProps[]>({
  key: "abTestConversionEventTagListState",
  default: [],
});

export const abTestCustomConversionEventTagListState = atom<ConversionEventTagProps[]>({
  key: "abTestCustomConversionEventTagListState",
  default: [],
});

export const useAbTestConversionEventTagList = (abTestUid: string) => {
  const [abTestCustomConversionEventTagList, setAbTestCustomConversionEventTagList] =
    useRecoilState(abTestCustomConversionEventTagListState);

  useEffect(() => {
    AbTestConversionEventTagApi.fetchTags(abTestUid)
      .then((data) => {
        const newList = data.conversion_event_tags.filter(
          (tag: ConversionEventTagProps) => !tag.original_counted
        );
        setAbTestCustomConversionEventTagList(newList);
      })
      .catch(() => {});
  }, []);

  return [abTestCustomConversionEventTagList] as const;
};
