import { forwardRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { BrandLogo } from "components/molecules/core/BrandLogo";
import { NavigationListUI } from "components/organisms/core/GlobalNavigation/NavigationListUi";
import { GlobalSidebarProps } from "./type";

export const GlobalSidebar = forwardRef<HTMLElement, GlobalSidebarProps>(
  ({ items, onClose, isOpen }, ref) => {
    if (!isOpen) return <></>;

    return (
      <aside
        className="absolute left-0 top-0 flex h-full w-60 flex-col bg-white px-4 py-2"
        // FIXME: tailwindのスタイルが効かないため一時的にstyleで指定
        style={{
          zIndex: 999,
          borderStyle: "solid",
          borderWidth: 1,
          borderTopStyle: "none",
          borderBottomStyle: "none",
          borderLeftStyle: "none",
          borderColor: "#EDEEF0",
        }}
        ref={ref}
      >
        <div className="flex items-center justify-between">
          <BrandLogo />
          <button onClick={onClose}>
            <XMarkIcon
              height={24}
              width={24}
            />
          </button>
        </div>
        <NavigationListUI
          items={items}
          mediaType="mobile"
        />
      </aside>
    );
  },
);

GlobalSidebar.displayName = "GlobalSidebar";
