/**
 * 小数点第一位までを考慮した平均の演算
 * @param counts 数値の配列
 * @returns {number} 平均値（四捨五入し、小数点第一位まで考慮した値）
 */
export const average = (counts: number[]) => {
  if (counts.length === 0) {
    return 0;
  }

  const num = counts.reduce((a, b) => a + b) / counts.length;
  return Math.round(num * 10) / 10;
};

/**
 * 小数点を考慮したパーセンテージの演算
 * @param numerator 分子
 * @param denominator 分母
 * @param decimal_point 考慮する小数点
 * @returns {number} パーセンテージ（四捨五入した値）
 */
export const percentage = (numerator?: number, denominator?: number, decimal_point = 2) => {
  if (numerator === undefined || denominator === undefined) {
    return 0;
  }
  if (numerator === 0 || denominator === 0) {
    return 0;
  }
  const percentage = (numerator / denominator) * 100;
  const r = 10 ** decimal_point;
  let result = Math.round(percentage * r) / r;
  if (result < 0) {
    result = 0;
  }
  return result;
};

/**
 * 小数点を考慮した商の演算
 * @param numerator 分子
 * @param denominator 分母
 * @param decimal_point 考慮する小数点
 * @returns {number} 商（四捨五入した値）
 */
export const divide = (numerator?: number, denominator?: number, decimalPoint = 0) => {
  if (numerator === undefined || denominator === undefined) {
    return 0;
  }
  if (numerator === 0 || denominator === 0) {
    return 0;
  }
  const percentage = numerator / denominator;
  const r = 10 ** decimalPoint;
  return Math.round(percentage * r) / r;
};

/**
 * 中央値を取得する関数
 * @param counts 数値の配列
 * @returns {number} 中央値
 */
export const median = (counts: number[]) => {
  if (counts.length === 0) {
    return 0;
  }

  const mid = Math.floor(counts.length / 2);
  const nums = [...counts].sort((a, b) => a - b);
  return counts.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};
