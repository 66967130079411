import { useState } from "react";

import { useTranslation } from "react-i18next";
import { Theme } from "contexts/theme";
import { CONTACT_DESTINATION_TYPE_NAME, ADDON_CONTACT_MENTION_TEXT } from "hooks";
import ContactApi from "javascripts/api/contact";

import { collectError } from "javascripts/utils";
import btnStyles from "stylesheets/components/partials/btn.module.scss";

import { ButtonContactProps } from "./type";

export const ContactButton = ({
  category,
  body,
  theme = "lightTheme",
  destinationType = CONTACT_DESTINATION_TYPE_NAME.CONTACT,
}: ButtonContactProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState("");

  const handleClick = () => {
    setStatus("sending");
    if (status === "sending") {
      return;
    }
    const params = new FormData();
    params.append("contact[category]", category);
    params.append(
      "contact[body]",
      destinationType === CONTACT_DESTINATION_TYPE_NAME.ADDON_CONTACT
        ? `${ADDON_CONTACT_MENTION_TEXT}${body}`
        : body,
    );
    params.append("contact[destination_type]", destinationType);
    ContactApi.createContact(params)
      .then(() => {
        setStatus("sent");
      })

      .catch((error) => {
        collectError(error);
        setStatus("");
      });
  };

  const btnColor = (theme: Theme) => {
    let colorStyles = "";
    switch (theme) {
      case "lightTheme":
        colorStyles = btnStyles.btnPrimary;
        break;
      case "darkTheme":
        colorStyles = btnStyles.btnDarkThemePrimary;
        break;
      default:
        colorStyles = btnStyles.btnPrimary;
    }
    return colorStyles;
  };

  if (status === "sent") {
    return (
      <div className={`${btnStyles.btn} ${btnColor(theme)} ${btnStyles.disable}`}>
        {t("担当者に問い合わせました")}
      </div>
    );
  }

  if (status === "sending") {
    return (
      <div className={`${btnStyles.btn} ${btnColor(theme)} ${btnStyles.disable}`}>
        {t("問い合わせ中")}...
      </div>
    );
  }

  return (
    <div
      className={`${btnStyles.btn} ${btnColor(theme)} ${btnStyles.btnPrimary}`}
      onClick={handleClick}
    >
      {t("担当者に問い合わせをする")}
    </div>
  );
};
