import { useEffect } from "react";
import { Box } from "@mui/material";
import { FolderProps, TeamMemberFolderGroupFolderOrderProps } from "domains";
import {
  useUpdateTeamMemberFolderGroupFolderOrder,
  useCreateTeamMemberFolderGroupFolderOrder,
} from "hooks";
import Sortable from "sortablejs";
import { Folder } from "../folder";
import type { FolderGroupFolderListProps } from "./type";

export const FolderGroupFolderList = ({
  isEditable,
  isInspectable,
  folderGroup,
  folderList,
  updateFolder,
  activeFolder,
  folderGroupFolderOrderList,
  folderGroupList,
  query,
  sortOptionList,
  isFolderGroupMatch,
  isFolderGroupFolderMatch,
  handleDownloadFolderReport,
  handleDownloadFolderReportLoading,
  handleDownloadFolderReportError,
}: FolderGroupFolderListProps) => {
  const [handleUpdateFolderGroupFolderOrder] = useUpdateTeamMemberFolderGroupFolderOrder();
  const [handleCreateFolderGroupFolderOrder] = useCreateTeamMemberFolderGroupFolderOrder();

  useEffect(() => {
    if (sortOptionList.name !== "custom") {
      return;
    }

    const element: HTMLElement | null = document.body.querySelector(
      `#ts-sortableFolderGroupFolderList_${folderGroup.id}`,
    );
    if (element !== null) {
      Sortable.create(element, {
        onEnd: async (e: Sortable.SortableEvent) => {
          // e.item.style.transform = null;
          const folderGroupId = e.item.dataset.folderGroupId;
          const folderId = e.item.dataset.folderId;
          if (folderGroupId === undefined || folderId === undefined) {
            return;
          }
          const orderId = e.item.dataset.orderId;
          if (e.oldIndex === undefined || e.newIndex === undefined) {
            return;
          }
          const oldPosition = e.oldIndex + 1;
          const newPosition = e.newIndex + 1;
          if (oldPosition === newPosition) {
            return;
          }
          const params = {
            old_position: oldPosition,
            new_position: newPosition,
          };
          if (orderId) {
            await handleUpdateFolderGroupFolderOrder(
              parseInt(folderGroupId),
              parseInt(folderId),
              parseInt(orderId),
              params,
            );
          } else {
            await handleCreateFolderGroupFolderOrder(
              parseInt(folderGroupId),
              parseInt(folderId),
              params,
            );
          }
        },
      });
    }
    return () => {
      // sortable.destroy();
    };
  }, [sortOptionList]);

  const list = folderList.map((folder: FolderProps) => {
    const order = folderGroupFolderOrderList.find((d: TeamMemberFolderGroupFolderOrderProps) => {
      return d.folder_group_id === folderGroup.id && d.folder_id === folder.id;
    });

    return (
      <Folder
        key={folder.id}
        isEditable={isEditable}
        isInspectable={isInspectable}
        folderGroup={folderGroup}
        folder={folder}
        order={order}
        updateFolder={updateFolder}
        activeFolder={activeFolder}
        folderGroupList={folderGroupList}
        query={query}
        isFolderGroupMatch={isFolderGroupMatch}
        isFolderGroupFolderMatch={isFolderGroupFolderMatch}
        handleDownloadFolderReport={handleDownloadFolderReport}
        handleDownloadFolderReportLoading={handleDownloadFolderReportLoading}
        handleDownloadFolderReportError={handleDownloadFolderReportError}
      />
    );
  });

  return (
    <Box
      id={`ts-sortableFolderGroupFolderList_${folderGroup.id}`}
      sx={{ mt: 1 }}
    >
      {list}
    </Box>
  );
};
