import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestAdDailyReportApi extends ApiBase {

  static fetchReports(uid, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/${uid}/ad_daily_reports`, { params, withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestAdDailyReportApi;
