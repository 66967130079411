import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleBodyProps } from "domains";
import ArticleBodyApi from "javascripts/api/articles/body";

export const articleConcatBodyState = atom<ArticleBodyProps>({
  key: "articleConcatBodyState",
  default: {} as ArticleBodyProps,
});

export type HandleFetchArticleConcatBodyProps = (articleUid: string) => void;
export const useFetchArticleConcatBody = () => {
  const [body, setBody] = useRecoilState(articleConcatBodyState);
  const handle: HandleFetchArticleConcatBodyProps = useCallback(async (articleUid) => {
    try {
      const response = await ArticleBodyApi.fetchConcatBody(articleUid);
      setBody({ body: response });
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, body, setBody] as const;
};

export const useArticleConcatBody = (articleUid: string) => {
  const [body, setBody] = useRecoilState(articleConcatBodyState);
  const [handle] = useFetchArticleConcatBody();

  useEffect(() => {
    handle(articleUid);
  }, []);

  return [body, setBody] as const;
};
