import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class ArticleSplitTestDeviceBlocklistApi extends ApiBase {

  static create(articleUid, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/articles/${articleUid}/split_test_settings/devices/blocklists`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroy(articleUid, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/articles/${articleUid}/split_test_settings/devices/blocklists`;
      axios.delete(path, { params, withCredentials: true})
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default ArticleSplitTestDeviceBlocklistApi;
