import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { ExitPopupProps } from "domains";
import { useLogging } from "hooks";
import AbTestArticleExitPopupApi from "javascripts/api/abTest/articles/exitPopup";
import AbTestExitPopupDuplicationApi from "javascripts/api/abTest/exitPopups/duplication";
import AbTestArticleExitPopupReflectionApi from "javascripts/api/abTest/exitPopups/reflection";
import AdminExitPopupApi from "javascripts/api/admin/exitPopup";
import ExitPopupApi from "javascripts/api/exitPopup";
import ExitPopupPresetApi from "javascripts/api/exitPopups/preset";
import { removeItemAtIndex, replaceItemAtIndex } from "utils";

export const exitPopupListState = atom<{
  popups: Array<ExitPopupProps>;
}>({
  key: "exitPopupListState",
  default: {
    popups: [],
  },
});

export type PresetExitPopupListProps = {
  items: Array<ExitPopupProps>;
  nextPage: number;
};
export const presetExitPopupListState = atom<PresetExitPopupListProps>({
  key: "presetExitPopupListState",
  default: {
    items: [],
    nextPage: 1,
  },
});

export type CreatedExitPopupListProps = {
  items: Array<ExitPopupProps>;
  nextPage: number;
};
export const createdExitPopupListState = atom<CreatedExitPopupListProps>({
  key: "createdExitPopupListState",
  default: {
    items: [],
    nextPage: 1,
  },
});

export type HandleFetchExitPopupListProps = (abTestUid: string) => void;
export const useFetchExitPopupList = () => {
  const { sendErrorLog } = useLogging();
  const [_exitPopupList, setExitPopupList] = useRecoilState(exitPopupListState);

  const handle: HandleFetchExitPopupListProps = useCallback(async (abTestUid) => {
    try {
      const response = await AbTestArticleExitPopupApi.fetch(abTestUid);
      setExitPopupList({
        popups: response.exit_popups,
      });
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to fetch abtest article exit popup",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};

export type HandleCreateExitPopupProps = (abTestUid: string, params: {}, popupId?: number) => void;
export const useCreateExitPopup = () => {
  const { sendErrorLog } = useLogging();
  const [exitPopupList, setExitPopupList] = useRecoilState(exitPopupListState);

  const handle: HandleCreateExitPopupProps = useCallback(
    async (abTestUid, params, popupId) => {
      try {
        const response = await AbTestArticleExitPopupApi.create(abTestUid, params, popupId);
        setExitPopupList({
          ...exitPopupList,
          popups: [response, ...exitPopupList.popups],
        });
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to create abtest article exit popup",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exitPopupList],
  );

  return [handle] as const;
};

export type HandleUpdateExitPopupProps = (
  abTestUid: string,
  popupId: number,
  params: {},
) => Promise<ExitPopupProps>;
export const useUpdateExitPopup = () => {
  const [exitPopupList, setExitPopupList] = useRecoilState(exitPopupListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleUpdateExitPopupProps = useCallback(
    async (abTestUid, popupId, params) => {
      setLoading(true);
      setError(undefined);
      try {
        const response = await AbTestArticleExitPopupApi.update(abTestUid, popupId, params);
        const index = exitPopupList.popups.findIndex((popup) => popup.id === popupId);
        setExitPopupList({
          ...exitPopupList,
          popups: replaceItemAtIndex(exitPopupList.popups, index, {
            ...response,
          }),
        });
        return response;
      } catch (e) {
        setError(e);
        return false;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exitPopupList],
  );

  return [handle, loading, error] as const;
};

export type handleDeleteExitPopupProps = (abTestUid: string, popupId: number) => Promise<boolean>;
export const useDeleteExitPopup = () => {
  const [exitPopupList, setExitPopupList] = useRecoilState(exitPopupListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: handleDeleteExitPopupProps = useCallback(
    async (abTestUid, popupId) => {
      setLoading(true);
      setError(undefined);
      try {
        await AbTestArticleExitPopupApi.destroy(abTestUid, popupId);
        const index = exitPopupList.popups.findIndex((popup) => popup.id === popupId);
        setExitPopupList({
          ...exitPopupList,
          popups: removeItemAtIndex(exitPopupList.popups, index),
        });
        return true;
      } catch (e) {
        setError(e);
        return false;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exitPopupList],
  );

  return [handle, loading, error] as const;
};

export type HandleDuplicateExitPopup = (abTestUid: string, popupId: number) => void;
export const useDuplicateExitPopup = () => {
  const { sendErrorLog } = useLogging();
  const [exitPopupList, setExitPopupList] = useRecoilState(exitPopupListState);

  const handle: HandleDuplicateExitPopup = useCallback(
    async (abTestUid, popupId) => {
      try {
        const response = await AbTestExitPopupDuplicationApi.create(abTestUid, popupId);
        setExitPopupList({
          ...exitPopupList,
          popups: [response, ...exitPopupList.popups],
        });
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to create abtest exit popup duplication",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exitPopupList],
  );

  return [handle] as const;
};

export type HandleReflectionExitPopupProps = (
  abTestUid: string,
  popupId: number,
) => Promise<ExitPopupProps>;
export const useReflectionExitPopup = () => {
  const [exitPopupList, setExitPopupList] = useRecoilState(exitPopupListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleReflectionExitPopupProps = useCallback(
    async (abTestUid, popupId) => {
      setLoading(true);
      setError(undefined);
      try {
        const response = await AbTestArticleExitPopupReflectionApi.update(abTestUid, popupId);
        const index = exitPopupList.popups.findIndex((popup) => popup.id === popupId);
        setExitPopupList({
          ...exitPopupList,
          popups: replaceItemAtIndex(exitPopupList.popups, index, {
            ...response,
          }),
        });
        return response;
      } catch (e) {
        setError(e);
        return false;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exitPopupList],
  );

  return [handle, loading, error] as const;
};

export type HandleCreatePresetExitPopupProps = (params: {}) => void;
export const useCreatePresetExitPopup = () => {
  const [presetExitPopupList, setPresetExitPopupList] = useRecoilState(presetExitPopupListState);
  const [loading, setLoading] = useState<boolean>(false);

  const handle: HandleCreatePresetExitPopupProps = useCallback(
    async (params) => {
      setLoading(true);
      try {
        const response = await AdminExitPopupApi.create(params);
        setPresetExitPopupList({
          ...presetExitPopupList,
          items: [response, ...presetExitPopupList.items],
        });
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [presetExitPopupList],
  );

  return [handle, loading] as const;
};

export type HandleUpdatePresetExitPopupProps = (
  abTestUid: string,
  popupId: number,
  params: {},
) => Promise<ExitPopupProps>;
export const useUpdatePresetExitPopup = () => {
  const [presetExitPopupList, setPresetExitPopupList] = useRecoilState(presetExitPopupListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleUpdatePresetExitPopupProps = useCallback(
    async (abTestUid, popupId, params) => {
      setLoading(true);
      setError(undefined);
      try {
        const response = await AdminExitPopupApi.update(abTestUid, popupId, params);
        const index = presetExitPopupList.items.findIndex((popup) => popup.id === popupId);
        setPresetExitPopupList({
          ...presetExitPopupList,
          items: replaceItemAtIndex(presetExitPopupList.items, index, {
            ...response,
          }),
        });
        return response;
      } catch (e) {
        setError(e);
        return false;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [presetExitPopupList],
  );

  return [handle, loading, error] as const;
};

export type HandleDeletePresetExitPopupProps = (
  abTestUid: string,
  popupId: number,
) => Promise<boolean>;
export const useDeletePresetExitPopup = () => {
  const [presetExitPopupList, setPresetExitPopupList] = useRecoilState(presetExitPopupListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle: HandleDeletePresetExitPopupProps = useCallback(
    async (abTestUid, popupId) => {
      setLoading(true);
      setError(undefined);
      try {
        await AdminExitPopupApi.destroy(abTestUid, popupId);
        const index = presetExitPopupList.items.findIndex((popup) => popup.id === popupId);
        setPresetExitPopupList({
          ...presetExitPopupList,
          items: removeItemAtIndex(presetExitPopupList.items, index),
        });
        return true;
      } catch (e) {
        setError(e);
        return false;
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [presetExitPopupList],
  );

  return [handle, loading, error] as const;
};

export type FetchExitPopupListParamterProps = {
  ab_test_uid?: string;
  page?: number;
  query?: string;
  where_condition?: {
    parent_id?: {} | null;
  };
  popup_type?: string;
};

export type HandleFetchPresetExitPopupListProps = (params: FetchExitPopupListParamterProps) => void;
export const useFetchPresetExitPopupList = () => {
  const { sendErrorLog } = useLogging();
  const [presetExitPopupList, setPresetExitPopupList] = useRecoilState(presetExitPopupListState);

  const handle: HandleFetchPresetExitPopupListProps = useCallback(
    async (params) => {
      try {
        const items = params.page === 1 ? [] : presetExitPopupList.items;
        const response = await ExitPopupPresetApi.fetch(params);
        setPresetExitPopupList({
          items: [...items, ...response.popups],
          nextPage: response.next_page,
        });
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to fetch exit popup preset",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [presetExitPopupList],
  );

  return [handle] as const;
};

export type HandleFetchCreatedExitPopupListProps = (
  params: FetchExitPopupListParamterProps,
) => void;
export const useFetchCreatedExitPopupList = () => {
  const { sendErrorLog } = useLogging();
  const [createdExitPopupList, setCreatedExitPopupList] = useRecoilState(createdExitPopupListState);

  const handle: HandleFetchCreatedExitPopupListProps = useCallback(
    async (params) => {
      try {
        const items = params.page === 1 ? [] : createdExitPopupList.items;
        const response = await ExitPopupApi.fetch(params);
        setCreatedExitPopupList({
          items: [...items, ...response.popups],
          nextPage: response.next_page,
        });
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to fetch exit popup",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createdExitPopupList],
  );

  return [handle] as const;
};

export const useExitPopupList = (abTestUid: string) => {
  const [exitPopupList, setExitPopupList] = useRecoilState(exitPopupListState);
  const [handle] = useFetchExitPopupList();

  useEffect(() => {
    handle(abTestUid);
  }, []);

  return [exitPopupList, setExitPopupList] as const;
};

export const usePresetExitPopupList = (abTestUid?: string, isInitialize = false) => {
  const [presetExitPopupList, setPresetExitPopupList] = useRecoilState(presetExitPopupListState);
  const [handle] = useFetchPresetExitPopupList();

  useEffect(() => {
    if (isInitialize) return;
    handle({ ab_test_uid: abTestUid, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid, isInitialize]);

  return [presetExitPopupList, setPresetExitPopupList] as const;
};

export const useCreatedExitPopupList = (
  abTestUid?: string,
  teamId?: number,
  isInitialize = false,
) => {
  const [createdExitPopupList, setCreatedExitPopupList] = useRecoilState(createdExitPopupListState);
  const [handle] = useFetchCreatedExitPopupList();

  useEffect(() => {
    if (isInitialize) return;
    handle({
      ab_test_uid: abTestUid,
      page: 1,
      where_condition: { parent_id: { not: null } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid, isInitialize]);

  return [createdExitPopupList, setCreatedExitPopupList] as const;
};
