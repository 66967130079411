import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AdminExitPopupApi extends ApiBase {

  static create(params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/admin/exit_popups/`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static update(uid, popupId, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.patch(`${baseUrl}/admin/exit_popups/${popupId}`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroy(uid, popupId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.delete(`${baseUrl}/admin/exit_popups/${popupId}`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default AdminExitPopupApi;
