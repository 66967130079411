export const ConversionTagSolidIcon = ({ width, height }: { width: number; height: number }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.97443 7.91691L11.0912 9.04171L9.96643 10.1585M6.02403 10.1441L4.90723 9.01931L6.03203 7.90251M8.48483 7.27051L7.51203 10.7905"
        stroke="currentColor"
        strokeWidth="1.152"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6896 9.03042C13.6896 12.1728 11.1424 14.72 8.00002 14.72C4.85763 14.72 2.31042 12.1728 2.31042 9.03042C2.31042 5.88802 4.85763 3.34082 8.00002 3.34082C11.1424 3.34082 13.6896 5.88802 13.6896 9.03042Z"
        stroke="currentColor"
        strokeWidth="1.152"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33118 1.27979H8.66878"
        stroke="currentColor"
        strokeWidth="1.152"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33118 2.61914H8.66878"
        stroke="currentColor"
        strokeWidth="1.152"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.27979V2.61899"
        stroke="currentColor"
        strokeWidth="1.152"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
