import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestExitPopupPhotoApi extends ApiBase {

  static fetch(abTestUid, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/${abTestUid}/exit_popups/photos`, { params: params, withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
  static create(abTestUid, exitPopupId, params) {
    let baseUrl = this.fileAppDefaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(`${baseUrl}/ab_tests/${abTestUid}/exit_popups/${exitPopupId}/photos`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
  static update(abTestUid, exitPopupId, photoId, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.patch(`${baseUrl}/ab_tests/${abTestUid}/exit_popups/${exitPopupId}/photos/${photoId}`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
  static destroy(abTestUid, exitPopupId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.delete(`${baseUrl}/ab_tests/${abTestUid}/exit_popups/${exitPopupId}/photos`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestExitPopupPhotoApi;
