import { Box } from "@mui/material";
import { ArrowBottomIcon, InspectionAllIcon, InspectionFewIcon } from "components/atoms";
import type { FolderProps } from "domains";
import type { FolderGroupArrowIconProps } from "./type";

export const FolderGroupArrowIcon = ({
  folderListInOrder,
  isFolderOpen,
}: FolderGroupArrowIconProps) => {
  const existsFolderList = folderListInOrder.length > 0;
  if (!existsFolderList) {
    return null;
  }

  // NOTE: For arrow icon
  const inspectableFolderCount = folderListInOrder.filter(
    (folder: FolderProps) => folder.inspection_setting?.enabled || false
  ).length;
  const folderCount = folderListInOrder.length;

  const defaultIcon = (
    <Box
      sx={{
        transform: isFolderOpen ? "rotate(180deg)" : "rotate(0deg)",
        transition: "all .3s",
        width: "20px",
        height: "20px",
      }}
    >
      <ArrowBottomIcon
        color={"primary"}
        width={"20px"}
        height={"20px"}
      />
    </Box>
  );
  let icon = defaultIcon;
  if (inspectableFolderCount === folderCount) {
    icon = (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "-10px",
            right: 0,
          }}
        >
          <InspectionAllIcon
            width="8px"
            height="10px"
          />
        </Box>
        {defaultIcon}
      </Box>
    );
  } else if (inspectableFolderCount !== 0 && inspectableFolderCount !== folderCount) {
    icon = (
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "-10px",
            right: 0,
          }}
        >
          <InspectionFewIcon
            width="8px"
            height="10px"
          />
        </Box>
        {defaultIcon}
      </Box>
    );
  }

  return <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>{icon}</Box>;
};
