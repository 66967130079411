import React from "react";

type Props = {
  width: string;
  height: string;
};

export const BypassIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-200.000000, -940.000000)">
          <g transform="translate(200.000000, 940.000000)">
            <rect
              fill="#333435"
              opacity="0"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
            <g
              transform="translate(5.000000, 1.000000)"
              stroke="#EF8200"
              strokeWidth="2"
            >
              <line
                x1="27"
                y1="13"
                x2="35"
                y2="13"
              ></line>
              <circle
                cx="24"
                cy="13"
                r="3"
              ></circle>
              <line
                x1="22"
                y1="15"
                x2="19"
                y2="18"
              ></line>
              <line
                x1="8"
                y1="23"
                x2="8"
                y2="39"
              ></line>
              <circle
                cx="15"
                cy="23"
                r="7"
              ></circle>
              <line
                x1="4"
                y1="12"
                x2="10"
                y2="18"
              ></line>
              <line
                x1="2"
                y1="6"
                x2="10"
                y2="14"
              ></line>
              <line
                x1="-1.06858966e-14"
                y1="-1.42108547e-13"
                x2="12"
                y2="12"
              ></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
