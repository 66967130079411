export const DELIVERY_ARCHITECTURE_ERROR_MESSAGES = {
  1: "リダイレクト配信なので使えない",
  2: "配信割合が1以上のversionが複数あるので使えない",
  3: "端末出し分けあるので使えない",
  4: "時間出し分けあるので使えない",
  5: "日付出し分けあるので使えない",
  6: "パラメーター出し分けあるので使えない",
  7: "訪問回数別あるので使えない",
  8: "配信自動最適化あるので使えない",
  9: "フォームあるので使えない",
  10: "ポップアップあるので使えない",
  11: "ドメインないので使えない",
};
