import React, { useState, useEffect } from "react";

import imageDropdownStyles from "stylesheets/components/quillEditor/imageDropdown.module.scss";
import { ImageDropdownResizeNavProps } from "./type";

export const ImageDropdownResizeNav = ({
  dropdownRef,
  dropdownContentRef,
  keyName,
}: ImageDropdownResizeNavProps) => {
  const [dropdownResizeAttributes, handleDropdownResizeAttributes] = useState({
    originalWidth: 0,
    originalHeight: 0,
    originalMouseX: 0,
    originalMouseY: 0,
  });
  const [isDropdownResize, handleIsDropdownResize] = useState(false);

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);

    return function cleanup() {
      document.removeEventListener("mouseup", handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownResize]);

  useEffect(() => {
    document.addEventListener("mousemove", resizeDropdown);

    return function cleanup() {
      document.removeEventListener("mousemove", resizeDropdown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropdownResize]);

  const handleMouseUp = () => {
    if (!isDropdownResize) return;
    const size = {
      width: dropdownContentRef.current?.style.width,
      height: dropdownContentRef.current?.style.height,
    };
    localStorage.setItem(keyName, JSON.stringify(size));
    handleIsDropdownResize(false);
  };

  const setResizeAttributes = (e: MouseEvent | React.MouseEvent<HTMLDivElement>) => {
    if (dropdownRef.current !== null) {
      dropdownResizeAttributes.originalWidth = parseFloat(
        getComputedStyle(dropdownRef.current, null).getPropertyValue("width").replace("px", "")
      );
      dropdownResizeAttributes.originalHeight = parseFloat(
        getComputedStyle(dropdownRef.current, null).getPropertyValue("height").replace("px", "")
      );
    }
    dropdownResizeAttributes.originalMouseX = e.pageX;
    dropdownResizeAttributes.originalMouseY = e.pageY;
    handleDropdownResizeAttributes(
      Object.assign(dropdownResizeAttributes, dropdownResizeAttributes)
    );
    handleIsDropdownResize(true);
  };

  const resizeDropdown = (e: MouseEvent) => {
    if (!isDropdownResize) return;
    const maxResizeHorizontal = window.innerWidth;
    const maxResizeVertical = window.innerHeight;
    const targetRect = dropdownRef.current?.getBoundingClientRect();
    const imageMinimumWidth = 500;
    const imageMinimumHeight = 300;
    const width =
      dropdownResizeAttributes.originalWidth + (e.pageX - dropdownResizeAttributes.originalMouseX);
    const height =
      dropdownResizeAttributes.originalHeight + (e.pageY - dropdownResizeAttributes.originalMouseY);

    const content = dropdownContentRef.current;
    const container = dropdownRef.current;

    if (content) {
      if (e.pageX + 10 >= maxResizeHorizontal) {
        return (content.style.width = `${maxResizeHorizontal - (targetRect?.left ?? 0)}px`);
      }
      if (e.pageY + 10 >= maxResizeVertical) {
        return (content.style.height = `${maxResizeVertical - (targetRect?.top ?? 0) - 25}px`);
      }
      if (width > imageMinimumWidth) {
        content.style.width = `${width}px`;
      }
      if (height > imageMinimumHeight) {
        content.style.height = `${height}px`;
      }
    }

    if (container) {
      container.style.maxWidth = "unset";
      container.style.right = "unset";
    }
  };

  return (
    <div className={imageDropdownStyles.resizeNav}>
      <div
        className={imageDropdownStyles.resizeIcon}
        onMouseDown={(e) => setResizeAttributes(e)}
      ></div>
    </div>
  );
};
