import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const RedirectPageArrowIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 19"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-990.000000, -20.000000)"
          fill={iconColor}
        >
          <path d="M1001.5,20.6653641 C1001.84626,20.6653641 1002.18186,20.785161 1002.44986,21.0044282 L1011,28 L1002.44986,34.9955718 C1001.80869,35.5201632 1000.86366,35.4256598 1000.33906,34.7844925 C1000.1198,34.5164993 1000,34.1808994 1000,33.8346359 L1000.00043,31.0116751 C995.569205,31.2192351 991.856213,34.1735467 990.525599,38.210465 C990.185087,37.2026156 990,36.1229225 990,35 C990,29.4771525 994.477153,25 1000,25 L1000,24.9993641 L1000,22.1653641 C1000,21.336937 1000.67157,20.6653641 1001.5,20.6653641 Z"></path>
        </g>
      </g>
    </svg>
  );
};
