import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestParameterExclusionSettingApi extends ApiBase {

  static fetchSettings(abTestUid) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/ab_tests/${abTestUid}/parameter_exclusion_settings`;
      axios.get(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestParameterExclusionSettingApi;
