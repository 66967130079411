import axios from 'axios';
import { APP } from "const/app";
import i18next from 'locales/i18next';
axios.interceptors.request.use((config) => {
  config.params = config.params || {};
  config.params['locale'] = i18next.language;
  config.params['referer'] = window.location.href.split('?')[0];
  return config;
});


class ApiBase {
  static defaultBaseUrl() {
    // return '/api/v1';
    return `${APP.BACKEND_URL}/api/v1`;
  }

  static fileAppDefaultBaseUrl() {
    return `${APP.FILE_API_URL}/api/v1`;
  }
}

export default ApiBase;
