import { useEffect, useState, useCallback } from "react";
import { AbTestDeliveryArchitectureSettingApi } from "api/abTest/deliveryArchitectureSetting";
import { AbTestDeliveryArchitectureSettingProps } from "domains";
import { useLogging, useMember } from "hooks";

export const useAbTestDeliveryArchitectureSetting = (abTestUid: string | undefined) => {
  const { sendErrorLog } = useLogging();
  const { memberAttributes } = useMember();
  const [setting, setSetting] = useState<AbTestDeliveryArchitectureSettingProps | null>({
    is_cloudflare: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!memberAttributes.isCanUseBoostMode || !abTestUid) {
      setLoading(false);
      return;
    }
    setLoading(true);

    AbTestDeliveryArchitectureSettingApi.fetch(abTestUid)
      .then((data) => {
        setSetting(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        sendErrorLog({
          error,
          message: "Failed to fetch abtest delivery architecture setting",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid, memberAttributes.isCanUseBoostMode]);

  return [setting, loading] as const;
};

export const useUpdateAbTestDeliveryArchitectureSetting = () => {
  const { sendErrorLog } = useLogging();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handle = useCallback(async (abTestUid: string, isCloudflare: boolean) => {
    try {
      setLoading(true);
      setError(undefined);

      await AbTestDeliveryArchitectureSettingApi.update(abTestUid, isCloudflare);
      setIsSuccess(true);
      setLoading(false);
    } catch (error) {
      setIsSuccess(false);
      setLoading(false);
      setError(error);
      sendErrorLog({
        error,
        message: "Failed to update abtest delivery architecture setting",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, loading, error, isSuccess] as const;
};
