import { AuthState, useAuth } from "hooks";
import { Content } from "./content";

export const AdminOuterFrame = () => {
  const [authState] = useAuth();
  if (![AuthState.Auth, AuthState.AuthToTeam].includes(authState)) {
    return null;
  }
  return <Content />;
};
