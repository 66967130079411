import { removeCloseTagInEmptyTag } from "./util";

/**
 * バックエンドから返却されるHTML(fetchArticleConcatBody)の内、不要なものを取り除くhooks
 * 本来バックエンドで処理したいが、バックエンドのライブラリの関係上手出しができないため
 * フロントで処理する
 */
export const useSanitizeConcatHtml = () => {
  const sanitizeHtml = (h: string) => {
    let html = h;
    html = removeCloseTagInEmptyTag(html);
    return html;
  };

  return {
    /** htmlから構文エラーを取り除く */
    sanitizeHtml,
  };
};
