import axios from "axios";
import { AdProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchAbTestAdResponse = {
  ads: AdProps[];
};

class AbTestAdApi extends ApiBase {
  static fetchAds(uid: string) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchAbTestAdResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/ab_tests/${uid}/ads`, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default AbTestAdApi;
