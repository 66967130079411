import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.bubble.css";

import { AppDropdown, ToggleSwitch } from "components/atoms";
import QuillEditor from "components/atoms/quill-editor";
import { debounce, localizeItemName, createUniqueString } from "javascripts/utils";
import formSettingStyles from "stylesheets/components/forms/formSetting.module.scss";

import { FormAutoReplySettingProps } from "./type";

const AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

const Parchment = Quill.import("parchment");
const Embed = Quill.import("blots/embed");

class BccVariable extends Embed {
  // FIXME: ライブラリ側の型が分からないのでanyにしている
  static create(value: any) {
    const node = super.create(value);
    node.innerHTML = `${value["value"]}`;
    node.setAttribute("data-uid", value["uid"]);
    node.setAttribute("data-params", value["params"]);
    return node;
  }

  // FIXME: ライブラリ側の型が分からないのでanyにしている
  static value(domNode: any) {
    //前後に空白が入る為削除
    if (domNode.firstChild && !domNode.firstChild.tagName) {
      domNode.removeChild(domNode.firstChild);
    }
    if (domNode.lastChild && !domNode.lastChild.tagName) {
      domNode.removeChild(domNode.lastChild);
    }
    //contenteditable内に配置されているのでその中身だけを取らないと重複してしまう
    const spans = domNode.querySelectorAll("span[contenteditable=false]");
    if (spans.length > 0) {
      return {
        value: spans[spans.length - 1].innerHTML,
        uid: domNode.getAttribute("data-uid"),
        params: domNode.getAttribute("data-params"),
      };
    }
    return {
      value: domNode.innerHTML,
      uid: domNode.getAttribute("data-uid"),
      params: domNode.getAttribute("data-params"),
    };
  }
}
BccVariable.blotName = "variable";
BccVariable.className = formSettingStyles.bccContentVariable;
BccVariable.tagName = "span";

Quill.register({
  "formats/variable": BccVariable,
});

const FormAutoReplySetting = ({
  form,
  updateForm,
  updateMailSetting,
}: FormAutoReplySettingProps) => {
  const { t } = useTranslation();

  const [editorRef, setEditorRef] = useState<ReactQuill | null>(null);

  useEffect(() => {
    if (!editorRef) return;
    const variables = editorRef
      .getEditor()
      .root.querySelectorAll(`.${formSettingStyles.bccContentVariable}`);
    variables.forEach((element) => {
      element.addEventListener("click", setCursor);
    });

    editorRef.getEditor().root.addEventListener("click", setSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef]);

  const addVariable = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    value: string,
    params: string
  ) => {
    e.preventDefault();
    editorRef?.getEditor().focus();
    const range = editorRef?.getEditor().getSelection();
    if (range) {
      const uniqueString = createUniqueString();
      editorRef
        ?.getEditor()
        .insertEmbed(
          range.index,
          "variable",
          { value: value, uid: uniqueString, params: params },
          "user"
        );

      const element = editorRef?.getEditor().root.querySelector(`[data-uid="${uniqueString}"]`);
      element?.addEventListener("click", setCursor);
      editorRef?.getEditor().setSelection(range.index + 1, 0);
    }
  };

  //MEMO: お問い合わせ項目を最後に追加した場合にうまくcursorがセットされないのでクリックイベントでセットする
  const setSelection = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore editorが無いと怒られるが、代替手段が見つからないため無視する
    editorRef?.editor.setSelection(editorRef?.editor.selection.savedRange);
  };

  const setCursor = (e: Event) => {
    const blot = Parchment.find(e.target, true);
    const index = editorRef?.getEditor().getIndex(blot) ?? 0;
    editorRef?.getEditor().setSelection(index + 1, 0);
  };

  const getParameters = () => {
    const div = document.createElement("div");
    div.innerHTML = form?.input.html ?? "";
    const inputs = div.querySelectorAll("[name^=folder_form]");
    const names: string[] = [];
    inputs.forEach((input) => {
      const nameAttribute = input.getAttribute("name");
      const matchedName = nameAttribute?.match(/folder_form\[(.*?)\]/)?.at(1);
      if (!matchedName || names.includes(matchedName)) return;
      names.push(matchedName);
    });
    return names;
  };

  const variables = () => {
    const components = getParameters().map((params) => {
      let name = params;
      if (localizeItemName(name)) {
        name = localizeItemName(name);
      }
      return (
        <div
          key={name}
          className={formSettingStyles.bccVariable}
          onClick={(e) => addVariable(e, name, params)}
        >
          {name}
        </div>
      );
    });
    return components;
  };

  const modules = {
    toolbar: {
      container: [["link"], ["clean"]],
    },
    clipboard: { matchVisual: false },
  };

  useEffect(() => {
    if (!editorRef) return;
    const editor = editorRef.getEditor();
    editor.root.style.borderRadius = "10px";
    editor.root.style.height = "400px";
    editor.root.style.minHeight = "400px";
  }, [editorRef]);

  const delayUpdate = debounce(updateMailSetting, 1000);
  return (
    <div className={formSettingStyles.formAutoReplySetting}>
      <div className={formSettingStyles.settingTitle}>{t("自動返信メール")}</div>
      <div className={formSettingStyles.formWrapperHeader}>
        {t("自動返信メール")}
        <ToggleSwitch
          defaultState={form?.auto_replied}
          theme={"blue"}
          checkedLabel={"ON"}
          uncheckedLabel={"OFF"}
          type={"labelOnMark"}
          willCheckEvent={() => {
            const r = new Promise<void>((resolve, _reject) => {
              updateForm({ folder_form: { auto_replied: true } });
              resolve();
            });
            return r;
          }}
          willUncheckEvent={() => {
            const r = new Promise<void>((resolve, _reject) => {
              updateForm({ folder_form: { auto_replied: false } });
              resolve();
            });
            return r;
          }}
        />
      </div>
      <div
        className={`${formSettingStyles.formWrapper} ${
          form?.auto_replied ? "" : formSettingStyles.disable
        }`}
      >
        <div className={formSettingStyles.formTitle}>
          {t("本文")}
          <AppDropdown
            topBottom={"center"}
            leftRight={"leftLowerHalf"}
            control={"click"}
            theme={"lightTheme"}
          >
            <div>
              +{t("お問い合わせ項目")}
              <AppDropdown
                topBottom={"center"}
                leftRight={"leftLowerHalf"}
                control={"hover"}
                theme={"lightTheme"}
              >
                <div className={formSettingStyles.info}>?</div>
                <div className={formSettingStyles.infoContent}>
                  {t("フォームに入力された内容を自動返信メールの本文に含めることができます")}
                </div>
              </AppDropdown>
            </div>
            <div className={formSettingStyles.bccVariableWrapper}>{variables()}</div>
          </AppDropdown>
        </div>
        <QuillEditor
          editorRef={editorRef}
          setEditorRef={setEditorRef}
          value={form?.mail_setting.auto_reply_content ?? ""}
          className={`${formSettingStyles.autoReplyContentEditor} auto-reply-setting-quill-editor`}
          theme="bubble"
          onChange={(value) => delayUpdate({ folder_form_setting: { auto_reply_content: value } })}
          modules={modules}
          placeholder={undefined}
        />
      </div>
    </div>
  );
};

export default FormAutoReplySetting;
