import i18next from "i18next";
import type { ColumnProps } from "./type";

export const COLUMNS: ColumnProps[] = [
  {
    key: "adSpending",
    value: i18next.t("配信金額"),
    unit: i18next.t("円"),
    description: i18next.t("配信金額"),
  },
  { key: "pv", value: "PV", unit: "", description: `${i18next.t("PV数")}` },
  {
    key: "click",
    value: "CLICK",
    unit: "",
    description: i18next.t("計測機能付きリンククリック数"),
  },
  {
    key: "ctr",
    value: "CTR",
    unit: "%",
    description: `${i18next.t("クリック率")}`,
  },
  { key: "cv", value: "CV", unit: "", description: `${i18next.t("CV数")}` },
  {
    key: "cvr",
    value: "CVR",
    unit: "%",
    description: `${i18next.t("コンバージョン率 = CV / CLICK")}`,
  },
  { key: "ctvr", value: "CTVR", unit: "%", description: "CV / PV" },
  {
    key: "cpa",
    value: "CPA",
    unit: i18next.t("円"),
    description: `${i18next.t("CVあたりの費用 = 配信金額 / CV")}`,
  },
  {
    key: "mcpa",
    value: "MCPA",
    unit: i18next.t("円"),
    description: `${i18next.t("クリックあたりの費用 = 配信金額 / CLICK")}`,
  },
  {
    key: "fver",
    value: "FVER",
    unit: "%",
    description: i18next.t("ファーストビュー離脱"),
  },
  { key: "sver", value: "SVER", unit: "%", description: i18next.t("セカンドビュー離脱") },
  {
    key: "fsver",
    value: "FSVER",
    unit: "%",
    description: i18next.t("ファーストビュー&セカンドビュー離脱"),
  },
  {
    key: "oar",
    value: "OAR",
    unit: "%",
    description: i18next.t("オファー到達率 ※最初の広告リンクに到達した率"),
  },
];

export const ACTIVE_COLUMN_COLOR = {
  first: "#00a6ff",
  second: "#ffa400",
};
