import axios from "axios";
import { JobProps } from "domains";
import ApiBase from "./apiBase";

type FetchJobsProps = JobProps[];

class JobApi extends ApiBase {
  static fetchList(params?: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchJobsProps>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/jobs`, { params, withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default JobApi;
