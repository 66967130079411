import { useCallback, useMemo } from "react";
import { generateSessionCookieValue, getCognitoLocalStorageParams } from "./util";

/**認証情報をcookieにコピー、削除するhooks。新アプリ（dashboard）にて認証情報を共通するために使用する。*/
export const useAuthCookieManagement = () => {
  /**cookieから認証情報のキーを抜き出す*/
  const getCognitoCookieKeys = useCallback(() => {
    //Note: CognitoIdentityServiceProvider[any]= を抜き出す正規表現
    const regex = /(CognitoIdentityServiceProvider\.|amplify-signin-with-hostedUI)[^=]*=/g;
    return document.cookie.match(regex)?.map((key) => key.replace("=", "")) ?? [];
  }, []);

  /**cookie上に認証情報があるか */
  const isEmpty = useMemo(() => {
    const cookies = getCognitoCookieKeys();
    return cookies.length === 0;
  }, [getCognitoCookieKeys]);

  /**LocalStorage上にある認証情報をcookieにコピーする */
  const copySessionToCookie = useCallback(() => {
    const cognitoLocalStorageParams = getCognitoLocalStorageParams();
    cognitoLocalStorageParams.forEach((params) => {
      document.cookie = generateSessionCookieValue(params);
    });
  }, []);

  /**cookie上にある認証情報を削除する */
  const removeSessionFromCookie = useCallback(() => {
    const cognitoCookieKeys = getCognitoCookieKeys();
    cognitoCookieKeys.forEach((key) => {
      document.cookie = generateSessionCookieValue({
        key,
        value: "",
        properties: {
          "max-age": 0,
        },
      });
    });
  }, [getCognitoCookieKeys]);

  return {
    /**LocalStorage上にある認証情報をcookieにコピーする */
    copySessionToCookie,
    /**cookie上にある認証情報を削除する */
    removeSessionFromCookie,
    /**cookie上に認証情報があるか */
    isEmpty,
  };
};