import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleProps } from "domains";
import FolderArticleApi from "javascripts/api/folders/article";

export const folderArticleListState = atom<ArticleProps[]>({
  key: "folderArticleListState",
  default: [],
});

export const useFolderArticleList = (folderUid: string) => {
  const [articleList, setArticleList] = useRecoilState(folderArticleListState);

  useEffect(() => {
    if (folderUid === "" || folderUid === null) {
      return;
    }

    FolderArticleApi.fetchList(folderUid).then((data) => {
      setArticleList(data.articles);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderUid]);

  return [articleList] as const;
};
