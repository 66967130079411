import axios from "axios";
import { FolderGroupProps } from "domains";
import ApiBase from "./apiBase";

export type FetchFolderGroupsProps = {
  folder_groups: FolderGroupProps[];
};

class FolderGroupApi extends ApiBase {
  static fetchFolderGroups(params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderGroupsProps>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/folder_groups`, {
          params: { folder_group_scope: "except_outside", ...params },
          withCredentials: true,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }

  static create(params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .post(`${baseUrl}/folder_groups`, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static update(id: number, params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      axios
        .patch(`${baseUrl}/folder_groups/${id}`, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static destroy(id: number) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function (resolve, reject) {
      const path = `${baseUrl}/folder_groups/${id}`;
      axios
        .delete(path, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderGroupApi;
