import axios from 'axios';
import { useSanitizeConcatHtml } from 'hooks';
import ApiBase from 'javascripts/api/apiBase';

class ArticleBodyApi extends ApiBase {

  static fetchConcatBody(articleUid) {
    let baseUrl = this.defaultBaseUrl();
    const {sanitizeHtml} = useSanitizeConcatHtml()
    return new Promise(function(resolve, reject) {
      axios.get(baseUrl + '/articles/' + articleUid + '/bodies', { withCredentials: true })
      .then(response => resolve(sanitizeHtml(response.data)))
      .catch(error => reject(error.response.data));
    });
  }

  static updateArticleBody(articleUid, body) {
    let baseUrl = this.defaultBaseUrl();
    ///api/v1/ab_tests/:ab_test_uid/articles/:article_uid/bodies/:id
    return new Promise(function(resolve, reject) {
        axios.patch(baseUrl + '/articles/' + articleUid + '/bodies', {
          article: { body: body }
        }, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default ArticleBodyApi;
