import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestArticleFunnelStepApi extends ApiBase {
  static fetchList(abTestUid) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/${abTestUid}/articles/funnel_steps`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestArticleFunnelStepApi;
