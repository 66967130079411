import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const RadarIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 25"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-71.000000, -227.000000)">
          <g transform="translate(20.000000, 70.000000)">
            <g transform="translate(51.000000, 157.000000)">
              <path
                d="M12,0.8 L10.2,1.2 L10.2,4.06392412 C10.8187022,3.64197273 11.4636969,3.76664059 11.8485281,4.15147186 C12.0656854,4.36862915 12.2,4.66862915 12.2,5 C12.2,5.17869027 12.1609432,5.34825835 12.0909201,5.50066604 C12.2908276,5.57340543 12.4892303,5.6517786 12.6858341,5.73573079 C14.6239552,6.56333175 16.2913486,7.89214552 17.4553817,9.55504996 C18.5517405,11.1212768 19.2,12.9847257 19.2,15 C19.2,17.5405098 18.1702549,19.8405098 16.5053824,21.5053824 C14.8405098,23.1702549 12.5405098,24.2 10,24.2 C7.45949015,24.2 5.15949015,23.1702549 3.49461761,21.5053824 C1.82974508,19.8405098 0.8,17.5405098 0.8,15 C0.8,12.9665964 1.45951483,11.0871752 2.57667998,9.56430786 C3.7226814,8.00213232 5.34968747,6.81464039 7.23982927,6.22100733 C7.44552524,6.1564048 7.65268557,6.09935419 7.86064865,6.04976782 C7.82050635,5.86991338 7.80013555,5.67174635 7.80011922,5.45770379 C7.80007859,4.92531072 7.92598152,4.49111671 8.16886284,4.19262634 C8.54112029,3.73513843 9.18982896,3.66040374 9.8,4.05873024 L9.8,4.05873024 L9.8,1.2 L8,1.2 L12,0.8 Z"
                stroke={iconColor}
                strokeWidth="1.6"
              ></path>
              <path
                d="M11.1346561,10.120891 C11.4013892,10.1923619 11.5596804,10.4665304 11.4882095,10.7332634 L9.28824763,18.9436329 C9.21677672,19.210366 8.94260824,19.3686572 8.67587519,19.2971863 C8.40914214,19.2257154 8.25085089,18.9515469 8.3223218,18.6848139 L10.5222837,10.4744444 C10.5937546,10.2077113 10.8679231,10.0494201 11.1346561,10.120891 Z M13.1464466,12.0107509 L15.267767,14.1320713 C15.3936026,14.2579069 15.4383441,14.4341279 15.4019916,14.5957931 C15.4385253,14.7578885 15.3938578,14.9345693 15.267767,15.0606602 L13.1464466,17.1819805 C12.9511845,17.3772427 12.634602,17.3772427 12.4393398,17.1819805 C12.2440777,16.9867184 12.2440777,16.6701359 12.4393398,16.4748737 L14.3178932,14.5963043 L12.4393398,12.7178577 C12.2440777,12.5225956 12.2440777,12.2060131 12.4393398,12.0107509 C12.634602,11.8154888 12.9511845,11.8154888 13.1464466,12.0107509 Z M7.56066017,12.0107509 C7.73422652,12.1843173 7.75351167,12.4537417 7.61851562,12.6486098 L7.56066017,12.7178577 L5.68168312,14.5963043 L7.56066017,16.4748737 C7.73422652,16.6484401 7.75351167,16.9178645 7.61851562,17.1127326 L7.56066017,17.1819805 C7.38709382,17.3555469 7.11766942,17.374832 6.92280128,17.239836 L6.85355339,17.1819805 L4.73223305,15.0606602 C4.60639744,14.9348246 4.56165589,14.7586036 4.5980084,14.5969384 C4.56878146,14.467262 4.59152325,14.328251 4.66634756,14.2132752 L4.73223305,14.1320713 L6.85355339,12.0107509 C7.04881554,11.8154888 7.36539803,11.8154888 7.56066017,12.0107509 Z"
                fill={iconColor}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
