import React from "react";

type Props = {
  width: string;
  height: string;
};

export const ImobileIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-280.000000, -100.000000)">
          <g transform="translate(280.000000, 100.000000)">
            <path
              d="M5.72000027,8.5800004 C5.72000027,14.8910685 9.80832567,20.247257 15.4810248,22.1446142 C14.2252084,22.6202098 12.8629432,22.8800011 11.4400005,22.8800011 C5.1218627,22.8800011 0,17.7581384 0,11.4400005 C0,6.22753682 3.4860678,1.82928787 8.25392465,0.44953244 C6.65624372,2.75842714 5.72000027,5.55987808 5.72000027,8.5800004 Z"
              id="Combined-Shape"
              fill="#C3D832"
            ></path>
            <path
              d="M10.4632923,1.79437763 C13.5488012,0.0129582278 17.9313549,1.69537943 20.7889035,5.55779959 C18.7070998,5.5024525 16.6558514,6.18022664 15.0467803,7.62904082 C12.0062377,10.3667576 11.5387176,14.962483 13.6788131,18.6223521 C12.0039881,17.6269278 10.4405779,16.0550358 9.27621063,14.0382925 C6.51202533,9.25058311 7.04349991,3.7687957 10.4632923,1.79437763 Z"
              id="Combined-Shape"
              fill="#F5BA1B"
            ></path>
            <path
              d="M17.9173602,8.61525201 C19.9461684,8.40201566 21.8674879,10.3476536 22.599514,13.1486662 C22.5335604,13.1267947 22.467424,13.1071997 22.4005016,13.0892679 C19.7305036,12.3738441 16.8203726,14.576757 15.900542,18.0096115 C15.7815296,18.4537717 15.7016669,18.89676 15.6585987,19.3329936 C14.9689763,18.3034228 14.4809636,16.9615774 14.3235017,15.4634278 C13.9520125,11.9289442 15.5610378,8.86291147 17.9173602,8.61525201 Z"
              id="Combined-Shape"
              fill="#EF7D1B"
            ></path>
            <path
              d="M20.2339707,20.7401047 C21.4186216,20.7401047 22.3789708,19.459639 22.3789708,17.8801045 C22.3789708,16.3005701 21.4186216,15.0201044 20.2339707,15.0201044 C19.0493199,15.0201044 18.0889706,16.3005701 18.0889706,17.8801045 C18.0889706,19.459639 19.0493199,20.7401047 20.2339707,20.7401047 Z"
              fill="#E7221A"
              transform="translate(20.233971, 17.880105) rotate(45.000000) translate(-20.233971, -17.880105) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
