import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleParameterAllowListProps, ArticleProps } from "domains";
import AbTestArticleParameterAllowlistApi from "javascripts/api/abTest/articles/parameterAllowlist";
import AbTestArticleSplitTestSettingParamsApi from "javascripts/api/abTest/articles/splitTestSettings/params";

export const abTestSplitTestSettingParamsArticleListState = atom<ArticleProps[]>({
  key: "abTestSplitTestSettingParamsArticleListState",
  default: [],
});

export const useFetchAbTestSplitTestSettingParamsArticleList = (abTestUid: string) => {
  const [articleList, setArticleList] = useRecoilState(
    abTestSplitTestSettingParamsArticleListState
  );
  const [error, setError] = useState<any>();

  useEffect(() => {
    (async () => {
      try {
        const response = await AbTestArticleSplitTestSettingParamsApi.fetch(abTestUid);
        setArticleList(response.articles);
      } catch (e: unknown) {
        setError(e);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid]);

  return [articleList, error] as const;
};

export const useCreateAbTestSplitTestSettingParamsArticleList = () => {
  const [articleList, setArticleList] = useRecoilState(
    abTestSplitTestSettingParamsArticleListState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = async (
    abTestUid: string,
    articleUid: string,
    params: {
      parameter: string;
    }
  ) => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const response: ArticleParameterAllowListProps =
        await AbTestArticleParameterAllowlistApi.create(abTestUid, articleUid, params);
      if (response) {
        const index = articleList.findIndex((d) => d.uid === articleUid);
        const updatedAllowList = [...articleList[index].parameter_allowlists, response];
        const updatedArticle = {
          ...articleList[index],
          parameter_allowlists: updatedAllowList,
        };
        const cloneArticleList = [...articleList];
        cloneArticleList[index] = updatedArticle;
        setArticleList(cloneArticleList);
      }
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setError(e);
    }
  };

  return [handle, loading, error] as const;
};

export const useDestroyAbTestSplitTestSettingParamsArticleList = () => {
  const [articleList, setArticleList] = useRecoilState(
    abTestSplitTestSettingParamsArticleListState
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = async (abTestUid: string, articleUid: string, parameterId: number) => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await AbTestArticleParameterAllowlistApi.destroy(abTestUid, articleUid, parameterId);
      const index = articleList.findIndex((d) => d.uid === articleUid);
      const filteredAllowList = articleList[index].parameter_allowlists.filter(
        (element: ArticleParameterAllowListProps) => element.id !== parameterId
      );
      const updatedArticle = {
        ...articleList[index],
        parameter_allowlists: filteredAllowList,
      };
      const cloneArticleList = [...articleList];
      cloneArticleList[index] = updatedArticle;
      setArticleList(cloneArticleList);
      setLoading(false);
    } catch (e: unknown) {
      setLoading(false);
      setError(e);
    }
  };

  return [handle, loading, error] as const;
};
