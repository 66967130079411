import clsx from "clsx";
import { tv } from "tailwind-variants";

export const MENUITEM_CONTAINER_CLASS_NAME = tv({
  base: clsx(
    "group flex min-w-max flex-col items-center justify-center gap-y-1 p-4",
    "md:h-12 md:w-12 md:rounded md:p-0 md:outline-none",
  ),
  variants: {
    isSelected: {
      true: clsx(
        "!border !border-x-0 !border-t-0 !border-solid !border-b-black",
        "md:bg-primary-50 md:!border-none",
      ),
      false: "",
    },
    isDisabled: {
      true: "cursor-not-allowed",
      false: clsx(
        "hover:bg-gray-extraLight",
        "focus-visible:bg-primary-50 focus-visible:ring-primary-600 focus-visible:ring-1 focus-visible:ring-offset-2",
      ),
    },
  },
});

export const MENUITEM_ICON_CLASS_NAME = tv({
  base: clsx("text-gray-dark hidden", "md:flex"),
  variants: {
    isSelected: {
      true: "text-black md:text-primary-600",
      false: "",
    },
    isDisabled: {
      true: "md:text-opacity-20",
      false: clsx(
        "md:group-active:text-primary-600 group-active:text-black",
        "md:group-focus-visible:text-primary-600 group-focus-visible:text-black",
      ),
    },
  },
});

export const MENUITEM_TITLE_CLASS_NAME = tv({
  base: clsx(
    "text-gray !text-s text-center font-medium leading-none",
    "md:whitespace-pre-line md:!text-[9px]",
  ),
  variants: {
    isSelected: {
      true: "text-black md:text-primary-600",
      false: "",
    },
    isDisabled: {
      true: "text-opacity-20",
      false: clsx(
        "md:group-active:text-primary-600 group-active:text-black",
        "md:group-focus-visible:text-primary-600 group-focus-visible:text-black",
      ),
    },
  },
});
