import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { BranchOperationColumnProps } from "domains";
import BranchOperationUserColumnApi from "javascripts/api/branchOperationUserColumn";

export const branchOperationDefaultColumnListState = atom<BranchOperationColumnProps[]>({
  key: "branchOperationDefaultColumnListState",
  default: [],
});

export const branchOperationMasterColumnListState = atom<BranchOperationColumnProps[]>({
  key: "branchOperationMasterColumnListState",
  default: [],
});

export const branchOperationUserColumnListState = atom<BranchOperationColumnProps[]>({
  key: "branchOperationUserColumnListState",
  default: [],
});

export const useBranchOperationUserColumnList = () => {
  const [branchOperationDefaultColumnList, setBranchOperationDefaultColumnList] = useRecoilState(
    branchOperationDefaultColumnListState
  );
  const [branchOperationUserMasterList, setBranchOperationMasterColumnList] = useRecoilState(
    branchOperationMasterColumnListState
  );
  const [branchOperationUserColumnList, setBranchOperationUserColumnList] = useRecoilState(
    branchOperationUserColumnListState
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      BranchOperationUserColumnApi.fetchColumns().then((data) => {
        setBranchOperationDefaultColumnList(data.default_columns);
        setBranchOperationMasterColumnList(data.master_columns);
        if (data.user_columns.length === 0) {
          setBranchOperationUserColumnList(data.default_columns);
        } else {
          setBranchOperationUserColumnList(data.user_columns);
        }
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    branchOperationDefaultColumnList,
    branchOperationUserMasterList,
    branchOperationUserColumnList,
    loading,
  ] as const;
};

export const useUpdateBranchOperationUserColumnList = () => {
  const [_branchOperationUserColumnList, setBranchOperationUserColumnList] = useRecoilState(
    branchOperationUserColumnListState
  );
  const [_loading, setLoading] = useState(false);

  const handle = useCallback(async (params?: any) => {
    try {
      setLoading(true);
      const response = await BranchOperationUserColumnApi.createColumns(params);
      setBranchOperationUserColumnList(response.user_columns);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};
