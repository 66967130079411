import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { DocomoAccountApi } from "api/teams/adAccounts/docomoAccounts";
import { DocomoAccountApiCreateAccountRequest } from "api/teams/adAccounts/docomoAccounts/type";
import { DocomoAccountProps } from "domains";

export const docomoAccountListState = atom<DocomoAccountProps[]>({
  key: "docomoAccountListState",
  default: [],
});

export const useFetchDocomoAccountList = () => {
  const [list, setList] = useRecoilState(docomoAccountListState);

  useEffect(() => {
    DocomoAccountApi.fetchAccounts().then((data) => {
      setList(data.docomo_ad_accounts || []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};

export const useCreateDocomoAccount = () => {
  const [list, setList] = useRecoilState(docomoAccountListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async ({ login_id, password }: Partial<DocomoAccountApiCreateAccountRequest>) => {
      if (loading) {
        return;
      }
      if (!login_id || !password) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        const response = await DocomoAccountApi.createAccount({ login_id, password });
        if (response !== undefined) {
          setList([response, ...list]);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [handle, error] as const;
};

export const useDeleteDocomoAccount = () => {
  const [list, setList] = useRecoilState(docomoAccountListState);
  const [loading, setLoading] = useState<boolean>(false);
  const [_error, setError] = useState<any>();

  const handle = useCallback(
    async (id: number) => {
      if (loading) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        await DocomoAccountApi.destroyAccount({ accountId: id });
        const newList = list.filter((d: DocomoAccountProps) => d.id !== id);
        setList(newList);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list]
  );

  return [handle] as const;
};
