import { useState, useEffect, useContext, ReactNode } from "react";
import { useFetchTeamMember } from "hooks";
import { MemberContext } from "./context";
import { MemberState } from "./type";

const useMember = () => useContext(MemberContext);

const useEffectMember = () => {
  const [member] = useFetchTeamMember();
  const [memberState, setMemberState] = useState<MemberState>({});

  useEffect(() => {
    const existsMember = Object.keys(member).length > 0;
    if (!existsMember) {
      return;
    }

    const result: MemberState = {
      isLoaded: true,
      team: member.team,
      existsTeam: member.team_signed_in,
      user: member.user,
      existsUser: member.user_signed_in,
      member: member.member,
      existsMember: member.member_signed_in,
      plan: member.plan,
      loyalties: member.loyalties,
      isAdmin: member.admin,
      isCanUseExitPopup: member.can_use_exit_popup,
      isCanUseFolderForm: member.can_use_folder_form,
      isCanUseBoostMode: member.can_use_boost_mode,
      isCanUseFileCapacity: member.can_use_file_capacity,
      isCanUseAttendant: member.can_use_attendant,
      isCanUseDatabaseLinkage: member.can_use_database_linkage,
      isCanUseBeyondDeLp: member.can_use_beyond_de_lp,
      isPlanPurchaser: member.isPlanPurchaser || false,
      isImpersonation: member.impersonation || false,
      team_tier: member.team_tier,
      user_tier: member.user_tier,
      isBygones: member.team && member.team.id === 1,
      isDemoTeam: member.team && member.team.id === 3,
      isSquadbeyondTeam: member.team && member.team.id === 84,
      isSquadAspTeam: member.team && member.team.id === 85,
      isTeamOwner: member.member && member.member.role === "owner",
      isTeamMember: member.member && member.member.role === "member",
    };

    setMemberState(result);
  }, [member]);

  return [memberState, setMemberState];
};

const MemberProvider = ({ children }: { children: ReactNode }) => {
  const [memberState] = useEffectMember();

  return (
    <MemberContext.Provider
      value={{
        memberAttributes: { ...memberState },
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export { MemberProvider, useMember };
