import { InformationCircleIcon as InformationCircleIconOutline } from "@heroicons/react/24/outline";
import { InformationCircleIcon as InformationCircleIconSolid } from "@heroicons/react/24/solid";
import { OriginalVersionOutlineIcon } from "components/atoms/icons";
import { BaseLocalNavigation as LocalNav } from "components/organisms/core/BaseLocalNavigation";
import { BEYOND_PAGE_OR_VERSION_REGEXP, ICON_SIZE, NAV_IDS } from "../consts";
import type { BeyondBoostLocalNavigationProps } from "./type";

const getCurrentNavId = (pathName: string): keyof typeof NAV_IDS => {
  if (BEYOND_PAGE_OR_VERSION_REGEXP.info.test(pathName)) return NAV_IDS.info;

  return NAV_IDS.version;
};

export const BeyondBoostLocalNavigation = ({
  pathName,
  folderUid,
  abTestUid,
  articleUid,
}: BeyondBoostLocalNavigationProps) => {
  return (
    <LocalNav
      selectedId={getCurrentNavId(pathName)}
      items={[
        {
          id: NAV_IDS.info,
          title: "基本情報",
          href: `/folders/${folderUid}/ab_tests/${abTestUid}/edit`,
          icon: (
            <InformationCircleIconOutline
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <InformationCircleIconSolid
              height={ICON_SIZE}
              width={ICON_SIZE}
            />
          ),
        },
        {
          id: NAV_IDS.version,
          title: "Version",
          href: `/ab_tests/${abTestUid}/articles${articleUid ? `#${articleUid}` : ""}`,
          icon: (
            <OriginalVersionOutlineIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          selectedIcon: (
            <OriginalVersionOutlineIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
        },
      ]}
    />
  );
};
