import axios from "axios";
import { FolderDailyReportProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderDailyReportResponse = {
  daily_reports: FolderDailyReportProps[];
};

class FolderDailyReportApi extends ApiBase {
  static fetchReports(params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderDailyReportResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/folders/daily_reports`, { params, withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }
}

export default FolderDailyReportApi;
