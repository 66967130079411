import React from "react";
import { Box } from "@mui/material";
import {
  AdcorsaIcon,
  AdwordsIcon,
  AkaneIcon,
  AmebaIcon,
  BuzzvideoIcon,
  BypassIcon,
  CirquaIcon,
  Co3Icon,
  DocomoIcon,
  FacebookIcon,
  GoogleIcon,
  GunosyIcon,
  ImobileIcon,
  InstagramIcon,
  LineIcon,
  LoglyIcon,
  NoinIcon,
  NosnsIcon,
  PopinIcon,
  ScaleoutIcon,
  SmartnewsIcon,
  TiktokIcon,
  YahooIcon,
  ZucksIcon,
  MicrosoftIcon,
  XIcon,
} from "components";

export const MediaIcon: React.FC<{
  mediaId: string | number | undefined;
  width: string;
  height: string;
}> = ({ mediaId, width, height }) => {
  switch (mediaId) {
    case 9:
      return (
        <AkaneIcon
          width={width}
          height={height}
        />
      );
    case 38:
      return (
        <BuzzvideoIcon
          width={width}
          height={height}
        />
      );
    case 1:
      return (
        <FacebookIcon
          width={width}
          height={height}
        />
      );
    case 6:
    case 13:
      return (
        <GoogleIcon
          width={width}
          height={height}
        />
      );
    case 4:
      return (
        <GunosyIcon
          width={width}
          height={height}
        />
      );
    case 21:
      return (
        <ImobileIcon
          width={width}
          height={height}
        />
      );
    case 2:
      return (
        <InstagramIcon
          width={width}
          height={height}
        />
      );
    case 8:
      return (
        <LineIcon
          width={width}
          height={height}
        />
      );
    case 19:
      return (
        <LoglyIcon
          width={width}
          height={height}
        />
      );
    case 45:
      return (
        <NoinIcon
          width={width}
          height={height}
        />
      );
    case 10:
      return (
        <PopinIcon
          width={width}
          height={height}
        />
      );
    case 42:
      return (
        <ScaleoutIcon
          width={width}
          height={height}
        />
      );
    case 28:
      return (
        <SmartnewsIcon
          width={width}
          height={height}
        />
      );
    case 39:
      return (
        <TiktokIcon
          width={width}
          height={height}
        />
      );
    case 3:
      return (
        <Box
          sx={{
            backgroundColor: "#000",
            width: "15px",
            height: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XIcon
            width={width}
            height={height}
          />
        </Box>
      );
    case 5:
    case 12:
      return (
        <YahooIcon
          width={width}
          height={height}
        />
      );
    case 11:
      return (
        <ZucksIcon
          width={width}
          height={height}
        />
      );
    case 18:
    case 32:
      return (
        <AmebaIcon
          width={width}
          height={height}
        />
      );
    case 43:
      return (
        <DocomoIcon
          width={width}
          height={height}
        />
      );
    case 46:
      return (
        <AdcorsaIcon
          width={width}
          height={height}
        />
      );
    case 7:
      return (
        <AdwordsIcon
          width={width}
          height={height}
        />
      );
    case 24:
      return (
        <BypassIcon
          width={width}
          height={height}
        />
      );
    case 44:
      return (
        <CirquaIcon
          width={width}
          height={height}
        />
      );
    case 49:
      return (
        <Co3Icon
          width={width}
          height={height}
        />
      );
    case 57:
      return (
        <MicrosoftIcon
          width={width}
          height={height}
        />
      );
    default:
      return (
        <NosnsIcon
          width={width}
          height={height}
        />
      );
  }
};
