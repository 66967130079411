export const isFolderMatchQuery = (folder, query) => {
  const existsQuery = query !== '';
  if (!existsQuery) { return true; }

  const existsFolderGroup = folder.folder_group_id !== undefined;

  let s = '';
  let domain = '';
  if (folder.domain) { domain = folder.domain.domain; }
  s = s.concat(domain);
  s = s.concat(` ${folder.name}`);

  const isMatchQuery = s.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  return isMatchQuery;
};
