import React from "react";

type Props = {
  width: string;
  height: string;
};

export const NosnsIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
    >
      <g
        id="folder-list"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="list-folder"
          transform="translate(-398.000000, -352.000000)"
        >
          <g
            id="group"
            transform="translate(315.000000, 171.000000)"
          >
            <g
              id="item"
              transform="translate(22.000000, 155.000000)"
            >
              <g
                id="nosnsicon"
                transform="translate(61.000000, 26.000000)"
              >
                <circle
                  id="Oval"
                  fill="#9B9B9B"
                  cx="14"
                  cy="14"
                  r="14"
                ></circle>
                <polygon
                  id="Polygon"
                  fill="#FFFFFF"
                  points="14 7 20 10 20 18 14 21 8 18 8 10"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#9B9B9B"
                  opacity="0.5"
                  points="8 10 14 13 14 21 8 18"
                ></polygon>
                <polygon
                  id="Rectangle"
                  fill="#F2F2F2"
                  transform="translate(17.000000, 15.500000) scale(-1, 1) translate(-17.000000, -15.500000) "
                  points="14 10 20 13 20 21 14 18"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
