import Button from "@mui/material/Button";
import { TextButtonProps } from "./type";

export const TextButton = ({ align = "center", ...textButtonProps }: TextButtonProps) => {
  return (
    <Button
      variant="text"
      style={{ width: "100%" }}
      sx={{
        ...textButtonProps.sx,
        ...(align == "left" ? { display: "flex", justifyContent: "left" } : {}),
      }}
      {...textButtonProps}
    >
      {textButtonProps.children}
    </Button>
  );
};
