import axios from "axios";
import { DEFAULT_BASE_URL } from "const/app";
import { ArticleHtmlPartProps } from "domains";

const fetchArticleHtmlParts = (params: any): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${DEFAULT_BASE_URL}/articles/html_parts`, { params, withCredentials: true })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

const fetchArticleHtmlPart = (partId: number, params: any): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${DEFAULT_BASE_URL}/articles/html_parts/${partId}`, { params, withCredentials: true })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export type CreateArticleHtmlPartRequestParams = {
  article_html_part: {
    article_html_part_category_id: number | string;
    name: string;
    html: string;
    css?: string;
    description?: string;
    icon: string;
    admin: boolean;
  };
};

export type CreateArticleHtmlPartFromOriginalRequestParams = {
  article_html_part: {
    name: string;
    html: string;
    css?: string;
    description?: string;
    original_html_part_id: number;
  };
};

const createArticleHtmlPart = (
  params: CreateArticleHtmlPartRequestParams | CreateArticleHtmlPartFromOriginalRequestParams
): Promise<ArticleHtmlPartProps> => {
  return new Promise(function (resolve, reject) {
    axios
      .post<ArticleHtmlPartProps>(`${DEFAULT_BASE_URL}/articles/html_parts`, params, {
        withCredentials: true,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

const updateArticleHtmlPart = (params: any, partId: number): Promise<any> => {
  return new Promise(function (resolve, reject) {
    axios
      .patch(`${DEFAULT_BASE_URL}/articles/html_parts/${partId}`, params, { withCredentials: true })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

const deleteArticleHtmlPart = (partId: number) => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(`${DEFAULT_BASE_URL}/articles/html_parts/${partId}`, { withCredentials: true })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export const ArticleHtmlPartApi = {
  fetchArticleHtmlParts,
  fetchArticleHtmlPart,
  createArticleHtmlPart,
  updateArticleHtmlPart,
  deleteArticleHtmlPart,
} as const;
