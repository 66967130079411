import { patch, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";

export type UserPasswordResetRequestParams = {
  user: {
    email: string;
  };
};


const create = async (params: UserPasswordResetRequestParams) => {
  const path = `${DEFAULT_BASE_URL}/users/password`;
  return post<UserPasswordResetRequestParams, boolean>(params, path);
};


export type UserUpdatePasswordRequestParams = {
  user: {
    code: string;
    email: string;
    password: string;
  };
};


const update = async (params: UserUpdatePasswordRequestParams) => {
  const path = `${DEFAULT_BASE_URL}/users/password`;
  return patch<UserUpdatePasswordRequestParams, boolean>(params, path);
};

export const UserPasswordApi = {
  create,
  update,
} as const;
