import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestArticleParameterExclusionSettingApi extends ApiBase {

  static createSetting(abTestUid, articleUid, params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/ab_tests/${abTestUid}/articles/${articleUid}/parameter_exclusion_settings`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroySetting(abTestUid, articleUid, id) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/ab_tests/${abTestUid}/articles/${articleUid}/parameter_exclusion_settings/${id}`;
      axios.delete(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default AbTestArticleParameterExclusionSettingApi;
