import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import zodJaJson from "./ja/zod.json";
import jaJson from "./ja.json";
import zodZhTWJson from "./zh-TW/zod.json";
import zhTWJson from "./zh-TW.json";

const getLanguage: () => string = () => {
  try {
    const item = window.localStorage.getItem("language");
    return item ? JSON.parse(item) : "ja";
  } catch (error) {
    return "ja";
  }
};

const language = getLanguage();

// NOTE: test実行時にも初期化できるようにするため、exportしておく
export const initI18n = () => {
  i18n.use(initReactI18next).init({
    resources: {
      "zh-TW": {
        translation: zhTWJson as object,
        zod: zodZhTWJson as object,
      },
      ja: {
        translation: jaJson as object,
        zod: zodJaJson as object,
      },
    },
    lng: language,
    keySeparator: "~.",
    nsSeparator: false,
    fallbackLng: false,
    returnEmptyString: false,
    interpolation: { escapeValue: false },
  });
};

initI18n();

export default i18n;
