import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { useLogging } from "hooks/logging";
import TeamMemberFolderGroupFolderOrderApi from "javascripts/api/teams/members/folderGroups/folders/order";

export const teamMemberFolderGroupFolderOrderListState = atom({
  key: "teamMemberFolderGroupFolderOrderListState",
  default: [],
});

export const useTeamMemberFolderGroupFolderOrderList = () => {
  const { sendErrorLog } = useLogging();
  const [list, setList] = useRecoilState(teamMemberFolderGroupFolderOrderListState);

  useEffect(() => {
    TeamMemberFolderGroupFolderOrderApi.fetch()
      .then((data) => {
        setList(data);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch team member folder group folder order",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list] as const;
};

export const useUpdateTeamMemberFolderGroupFolderOrder = () => {
  const { sendErrorLog } = useLogging();
  const [_, setList] = useRecoilState(teamMemberFolderGroupFolderOrderListState);

  const handle = useCallback(
    async (folderGroupId: number, folderId: number, orderId: number, params: {}) => {
      try {
        const response = await TeamMemberFolderGroupFolderOrderApi.update(
          folderGroupId,
          folderId,
          orderId,
          params,
        );
        setList(response);
      } catch (error) {
        sendErrorLog({
          error,
          message: "Failed to update team member folder group folder order",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [handle] as const;
};

export const useCreateTeamMemberFolderGroupFolderOrder = () => {
  const { sendErrorLog } = useLogging();
  const [_, setList] = useRecoilState(teamMemberFolderGroupFolderOrderListState);

  const handle = useCallback(async (folderGroupId: number, folderId: number, params: {}) => {
    try {
      const response = await TeamMemberFolderGroupFolderOrderApi.create(
        folderGroupId,
        folderId,
        params,
      );
      setList(response);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to create team member folder group folder order",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};
