import axios from 'axios';
import ApiBase from './apiBase';

class AbTestArticleApi extends ApiBase {

  static updateAbTestArticle(pathname, abTestArticleId, params) {
    let baseUrl = this.defaultBaseUrl();

    return new Promise(function(resolve, reject) {
      axios.patch(baseUrl + pathname + '/ab_test_articles/' + abTestArticleId, params,{ withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default AbTestArticleApi;
