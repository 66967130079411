import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { FolderFormTypeProps } from "domains";
import { useLogging } from "hooks";
import FolderFormTypeApi from "javascripts/api/folders/forms/type";

const folderFormTypeListState = atom<FolderFormTypeProps[]>({
  key: "folderFormTypeListState",
  default: [],
});

export const useFolderFormTypeList = () => {
  const { sendErrorLog } = useLogging();
  const [folderFormTypeList, setFolderFormTypeList] = useRecoilState(folderFormTypeListState);

  useEffect(() => {
    FolderFormTypeApi.fetch()
      .then((data) => {
        setFolderFormTypeList(data);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch form type",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [folderFormTypeList] as const;
};
