import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { SplitTestSettingContactButton } from "components/atoms";
import { Theme } from "components/Theme";
import { SplitTestSettingInfoForNonBoostContractorProps } from "./type";

export const SplitTestSettingInfoForNonBoostContractor = ({
  category,
  body,
}: SplitTestSettingInfoForNonBoostContractorProps) => {
  const { t } = useTranslation();
  return (
    <Theme mode="light">
      <Box
        sx={{
          display: "flex",
          flexFlow: "column",
          gap: "12px",
          ml: 2,
          mb: 2.4,
        }}
      >
        <Typography
          sx={{
            color: "primary.contrastText",
            fontSize: "14px",
            borderRadius: "10px",
          }}
        >
          {t("高速化モード利用（有料）でご利用可能です。")}
        </Typography>
        <Box sx={{ display: "flex", gap: "24px" }}>
          <Button
            href="/addon/option-list#boostMode"
            target="_blank"
            variant="contained"
            disableFocusRipple
            disableRipple
            sx={{
              bgcolor: "primary.main",
              color: "primary.contrastText",
              borderRadius: "0.25rem",
              fontSize: "12px",
              bolder: "1px solid transparent",
              p: "0.375rem 1.5rem",
              fontWeight: "normal",
              ":hover": {
                bgcolor: "primary.main",
              },
            }}
          >
            {t("高速化モードの詳細を確認する")}
          </Button>
          <SplitTestSettingContactButton
            category={category}
            body={body}
          />
        </Box>
      </Box>
    </Theme>
  );
};
