import React from "react";

type Props = {
  width: string;
  height: string;
};

export const Co3Icon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <defs>
        <path
          d="M8,0 C10.180165,0 12.156641,0.872096477 13.5996075,2.28646897 C12.584366,3.95114441 12,5.90732921 12,8 C12,10.0926708 12.584366,12.0488556 13.5988189,13.7142754 C12.156641,15.1279035 10.180165,16 8,16 C3.581722,16 1.86517468e-14,12.418278 1.86517468e-14,8 C1.86517468e-14,3.581722 3.581722,0 8,0 Z"
          id="co3-path-1"
        ></path>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-200.000000, -1920.000000)">
          <g transform="translate(200.000000, 1920.000000)">
            <rect
              fill="#333435"
              opacity="0"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
            <g transform="translate(0.000000, 12.000000)">
              <mask
                id="co3-mask-2"
                fill="white"
              >
                <use href="#co3-path-1"></use>
              </mask>
              <g></g>
              <circle
                stroke="#F8B62B"
                strokeWidth="3"
                mask="url(#co3-mask-2)"
                cx="8"
                cy="8"
                r="6.5"
              ></circle>
            </g>
            <circle
              stroke="#47ADEC"
              strokeWidth="3"
              cx="23"
              cy="20"
              r="6.5"
            ></circle>
            <g
              transform="translate(33.056885, 12.056152)"
              fill="#106AAB"
              fillRule="nonzero"
            >
              <path d="M3.16357422,8.13720703 C5.11328125,8.13720703 6.35400391,7.19726562 6.35400391,5.72558594 L6.35400391,5.71484375 C6.35400391,4.68896484 5.60742188,4.04443359 4.44726562,3.92626953 L4.44726562,3.88867188 C5.32275391,3.71142578 6.04785156,3.09375 6.04785156,2.12158203 L6.04785156,2.11083984 C6.04785156,0.843261719 4.91992188,0 3.15283203,0 C1.42871094,0 0.290039062,0.934570312 0.187988281,2.38476562 L0.182617188,2.45996094 L1.97119141,2.45996094 L1.9765625,2.40625 C2.03027344,1.83691406 2.47070312,1.46630859 3.15283203,1.46630859 C3.81884766,1.46630859 4.21630859,1.82080078 4.21630859,2.37939453 L4.21630859,2.39013672 C4.21630859,2.93798828 3.75439453,3.29785156 2.99707031,3.29785156 L2.16992188,3.29785156 L2.16992188,4.63525391 L3.0078125,4.63525391 C3.8671875,4.63525391 4.35058594,4.97900391 4.35058594,5.60205078 L4.35058594,5.61279297 C4.35058594,6.1875 3.89404297,6.59033203 3.16357422,6.59033203 C2.42773438,6.59033203 1.95507812,6.20898438 1.890625,5.69335938 L1.87988281,5.63427734 L0,5.63427734 L0.00537109375,5.70410156 C0.118164062,7.17041016 1.33203125,8.13720703 3.16357422,8.13720703 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
