import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class TeamMemberFavoriteArticleHtmlPartApi extends ApiBase {

  static registFavoriteArticlePart(articlePartId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
        axios.patch(baseUrl + '/teams/members/articles/html_parts/favorites', {
          article_html_part: { id: articlePartId }
        }, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default TeamMemberFavoriteArticleHtmlPartApi;
