import { deleteRequest, get, patch, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import {
  TeamDomainApiCreateDomainRequest,
  TeamDomainApiCreateDomainResponse,
  TeamDomainApiDestroyDomainRequest,
  TeamDomainApiDestroyDomainResponse,
  TeamDomainApiFetchDomainsResponse,
  TeamDomainApiUpdateDomainRequest,
  TeamDomainApiUpdateDomainResponse,
} from "./type";

const BASE_ENDPOINT = `${DEFAULT_BASE_URL}/teams/domains` as const;

export const fetchDomains = async () =>
  get<{}, TeamDomainApiFetchDomainsResponse>({}, BASE_ENDPOINT);

export const createDomain = async (params: TeamDomainApiCreateDomainRequest) =>
  post<TeamDomainApiCreateDomainRequest, TeamDomainApiCreateDomainResponse>(params, BASE_ENDPOINT);

export const updateDomain = async ({ domainId, params }: TeamDomainApiUpdateDomainRequest) =>
  patch<TeamDomainApiUpdateDomainRequest["params"], TeamDomainApiUpdateDomainResponse>(
    params,
    `${BASE_ENDPOINT}/${domainId}`
  );

export const destroyDomain = async ({ domainId, params }: TeamDomainApiDestroyDomainRequest) =>
  deleteRequest<TeamDomainApiDestroyDomainRequest["params"], TeamDomainApiDestroyDomainResponse>(
    params,
    `${BASE_ENDPOINT}/${domainId}`
  );

export * as TeamDomainApi from ".";
