import axios from "axios";
import { ArticleProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderArticleResponse = {
  articles: ArticleProps[];
};

class FolderArticleApi extends ApiBase {
  static fetchList(folderUid: string) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderArticleResponse>(function (resolve, reject) {
      axios
        .get(`${baseUrl}/folders/${folderUid}/articles`, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }
}

export default FolderArticleApi;
