import React from "react";
import { jaJP } from "@mui/material/locale";
import { createTheme, ThemeProvider, Theme as OrgTheme } from "@mui/material/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends OrgTheme {}
}

declare module "@mui/material/styles" {
  interface TypeBackground {
    primary: string;
    secondary: string;
    default: string;
    contrast: string;
  }

  interface Palette {
    inspection: Palette["primary"];
    darkGrayishBlue: Palette["primary"];
    background: Palette["background"];
    disabled: Palette["primary"];
    newDesign: Palette["primary"];
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    inspection: true;
    darkGrayishBlue: true;
    newDesign: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    inspection: true;
    darkGrayishBlue: true;
    newDesign: true;
  }
}

export const fetchPalette = (mode: string) => {
  let palette = {};
  if (mode == "light") {
    palette = {
      mode: mode,
      primary: {
        main: "#0086ff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#ececec",
        contrastText: "#000",
      },
      success: {
        main: "#2e7d32",
        light: "#1fd400",
        dark: "#1b5e20",
        contrastText: "#fff",
      },
      error: {
        main: "#d32f2f",
        light: "#ff0000",
        dark: "#c62828",
        contrastText: "#fff",
      },
      red: {
        main: "#FF533C",
      },
      inspection: {
        main: "#694c3a",
        contrastText: "#fa6400",
      },
      darkGrayishBlue: {
        main: "#454647",
        contrastText: "#ffa400",
      },
      background: {
        default: "#fff",
        paper: "#fff",
        primary: "#fff",
        secondary: "#ececec",
        contrast: "#000",
      },
      disabled: {
        main: "#B3BBC4",
        contrastText: "#000",
      },
      // TODO: rename this to something more meaningful
      newDesign: {
        main: "#F4F4F4",
        contrastText: "#ffffff",
      },
    };
  } else {
    palette = {
      mode: mode,
      primary: {
        main: "#ffa400",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#5c4e31",
        contrastText: "#ffa400",
      },
      inspection: {
        main: "#694c3a",
        contrastText: "#fa6400",
      },
      darkGrayishBlue: {
        main: "#454647",
        contrastText: "#ffa400",
      },
      background: {
        default: "#000",
        paper: "#000",
        primary: "#454647",
        secondary: "#373838",
        contrast: "#ffffff",
      },
      disabled: {
        main: "#B3BBC4",
        contrastText: "#000",
      },
      newDesign: {
        main: "#F4F4F4",
        contrastText: "#ffffff",
      },
    };
  }

  return palette;
};

type ThemeProps = {
  mode?: string;
  children: React.ReactNode;
};

export const Theme: React.FC<ThemeProps> = ({ mode = "light", children }) => {
  const palette = fetchPalette(mode);

  const theme = createTheme(
    {
      palette: { ...palette },

      typography: {
        fontFamily: ["Hiragino Sans", "Helvetica Neue", "Arial", "sans-serif"].join(","),
        button: {
          textTransform: "none",
        },
      },
      spacing: 10,
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              boxShadow: "none",
              borderRadius: "10px",
              fontWeight: "bold",
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              borderRadius: "20px",
              backgroundImage: "none",
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              fontSize: "16px",
              fontWeight: "bold",
              padding: "20px",
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              padding: "0 20px 20px 20px",
            },
          },
        },
      },
    },
    jaJP,
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
