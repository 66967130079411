import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class TeamMemberFolderInspectionSettingApi extends ApiBase {
  static fetch(folderId) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/members/folders/${folderId}/inspection_settings`;
      axios.get(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default TeamMemberFolderInspectionSettingApi;
