import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { UserTeamApi } from "api";
import { TeamProps } from "domains";
import { useLogging } from "hooks";
import TeamApi from "javascripts/api/team";
import TeamMemberTeamApi from "javascripts/api/teams/members/team";

const memberTeamListState = atom<TeamProps[]>({
  key: "memberTeamListState",
  default: [],
});

const userTeamListState = atom<TeamProps[]>({
  key: "userTeamListState",
  default: [],
});

// NOTE: useMemberTeamListはteamログイン必須、useUserTeamListはユーザーログイン必須
// TODO: useMemberTeamListを消す
export const useMemberTeamList = () => {
  const { sendErrorLog } = useLogging();
  const [teams, setTeams] = useRecoilState(memberTeamListState);

  useEffect(() => {
    TeamMemberTeamApi.fetchTeams()
      .then((data) => {
        setTeams(data.teams);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch teams at useMemberTeamList",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [teams] as const;
};

export const useUserTeamList = () => {
  const [teamList, setTeamList] = useRecoilState(userTeamListState);
  const [loading, setLoading] = useState<boolean>(true);
  const { sendErrorLog } = useLogging();

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await UserTeamApi.fetchTeams();
        setTeamList(response.teams);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        sendErrorLog({
          error: e,
          message: "Failed to fetch teams",
        });
      }
    };
    void fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [teamList, loading] as const;
};

export const useUpdateTeam = () => {
  const [teamList, setTeamList] = useRecoilState(userTeamListState);
  const [loading, setLoading] = useState<boolean>(false);
  const handle = useCallback(
    async (params: {
      team: {
        name: string;
      };
    }) => {
      if (loading) {
        return;
      }
      setLoading(true);
      try {
        const response: TeamProps = await TeamApi.update(params);
        const newTeamList = teamList.map((team: TeamProps) => {
          if (team.id === response.id) {
            return { ...team, ...response };
          } else {
            return team;
          }
        });
        setTeamList(newTeamList);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamList],
  );

  return [handle, loading] as const;
};
