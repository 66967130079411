import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { FolderDailyReportProps } from "domains";
import FolderDailyReportApi from "javascripts/api/folders/dailyReport";

export const folderDailyReportListState = atom<FolderDailyReportProps[]>({
  key: "folderDailyReportListState",
  default: [],
});

export const useFolderDailyReportList = (startDate: string, endDate: string) => {
  const [list, setList] = useRecoilState(folderDailyReportListState);

  useEffect(() => {
    const params = {
      start_date: startDate,
      end_date: endDate,
    };
    FolderDailyReportApi.fetchReports(params)
      .then((data) => {
        setList(data.daily_reports);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return [list] as const;
};
