import branchIcon from "assets/images/branch.svg";
import bussinessIcon from "assets/images/bussiness.svg";
import buttonIcon from "assets/images/button.svg";
import calendarIcon from "assets/images/calendar.svg";
import checkIcon from "assets/images/check.svg";
import documentIcon from "assets/images/document.svg";
import headingParagraphIcon from "assets/images/heading-paragraph.svg";
import headingIcon from "assets/images/heading2.svg";
import homeIcon from "assets/images/home.svg";
import identityIcon from "assets/images/identity.svg";
import imageIcon from "assets/images/image-black.svg";
import linkIcon from "assets/images/link.svg";
import mailIcon from "assets/images/mail.svg";
import mapIcon from "assets/images/map.svg";
import multipulCheckIcon from "assets/images/multipul-check.svg";
import paragraphIcon from "assets/images/paragraph.svg";
import phoneIcon from "assets/images/phone.svg";
import singleTextIcon from "assets/images/single-text.svg";
import textareaIcon from "assets/images/textarea.svg";
import userIcon from "assets/images/user.svg";
import {
  defaults,
  prefectures,
  genders,
  occupations,
  industries,
  mediums,
  employeeNums,
} from "const/selectList";
import i18next from "i18next";
import {
  AddressObject,
  BirthdayObject,
  ButtonObject,
  CalendarObject,
  CheckBoxObject,
  EmailObject,
  EmployeeNumObject,
  FullNameObject,
  GenderObject,
  HeadingObject,
  HeadingParagraphObject,
  ImageObject,
  IndustryObject,
  InqueryObject,
  LinkObject,
  MediumObject,
  NameObject,
  OccupationObject,
  ParagraphObject,
  PhoneObject,
  PrefectureObject,
  RadioObject,
  SelectBoxObject,
  SubmitButtonObject,
  TermLinkObject,
  TermsObject,
  TextAreaObject,
  TextObject,
} from "utils/convert-html-to-object/type";
import { generateOptions } from "./util";

const commonOption = { note: "", showBadge: true, isRequired: true };

// NOTE: 実態の近くに定義した方がわかりやすいのでここに定義
type OptionsMap = {
  fullname: FullNameObject["options"];
  name: NameObject["options"];
  email: EmailObject["options"];
  phoneNumber: PhoneObject["options"];
  inquery: InqueryObject["options"];
  gender: GenderObject["options"];
  button: ButtonObject["options"];
  birthday: BirthdayObject["options"];
  address: AddressObject["options"];
  prefecture: PrefectureObject["options"];
  occupation: OccupationObject["options"];
  industry: IndustryObject["options"];
  medium: MediumObject["options"];
  employeeNum: EmployeeNumObject["options"];
  headingParagraph: HeadingParagraphObject["options"];
  heading: HeadingObject["options"];
  paragraph: ParagraphObject["options"];
  text: TextObject["options"];
  textarea: TextAreaObject["options"];
  selectbox: SelectBoxObject["options"];
  radio: RadioObject["options"];
  checkbox: CheckBoxObject["options"];
  submitButton: SubmitButtonObject["options"];
  calendar: CalendarObject["options"];
  image: ImageObject["options"];
  link: LinkObject["options"];
  terms: TermsObject["options"] & { blockName: string };
  termsLink: TermLinkObject["options"] & { blockName: string };
};

export const optionsMap: OptionsMap = {
  fullname: {
    ...commonOption,
    type: "preset",
    label: i18next.t("氏名"),
    placeholderName: `${i18next.t("を入力", { value: i18next.t("氏名") })}...`,
    placeholderRuby: `${i18next.t("を入力", { value: i18next.t("氏名(フリガナ)") })}...`,
    icon: userIcon,
    hasInputRuby: true,
  },
  name: {
    ...commonOption,
    type: "preset",
    label: i18next.t("氏名（姓名別）"),
    placeholderLastName: `${i18next.t("を入力", { value: i18next.t("姓") })}...`,
    placeholderFirstName: `${i18next.t("を入力", { value: i18next.t("名") })}...`,
    placeholderLastNameRuby: `${i18next.t("を入力", { value: i18next.t("姓(フリガナ)") })}...`,
    placeholderFirstNameRuby: `${i18next.t("を入力", { value: i18next.t("名(フリガナ)") })}...`,
    icon: userIcon,
    hasInputRuby: true,
  },
  email: {
    ...commonOption,
    type: "preset",
    label: i18next.t("メールアドレス"),
    icon: mailIcon,
    placeholder: `${i18next.t("を入力", { value: i18next.t("メールアドレス") })}...`,
    hasReconfirm: false,
  },
  phoneNumber: {
    ...commonOption,
    type: "preset",
    label: i18next.t("電話番号"),
    icon: phoneIcon,
    placeholder: `${i18next.t("を入力", { value: i18next.t("電話番号") })}...`,
    note: i18next.t("半角数字のみ、ハイフンありで入力します"),
    characterWidth: "half",
    hasHyphen: true,
  },
  inquery: {
    ...commonOption,
    type: "preset",
    label: i18next.t("お問い合わせ内容"),
    icon: documentIcon,
    placeholder: "",
    defaultValue: "",
    rows: 10,
  },
  gender: {
    ...commonOption,
    type: "preset",
    label: i18next.t("性別"),
    icon: identityIcon,
    genderOptions: generateOptions(genders),
    showOther: false,
  },
  birthday: { ...commonOption, type: "preset", label: i18next.t("生年月日"), icon: identityIcon },
  address: { ...commonOption, type: "preset", label: i18next.t("住所"), icon: homeIcon },
  prefecture: {
    ...commonOption,
    type: "preset",
    label: i18next.t("都道府県"),
    icon: mapIcon,
    prefectureOptions: generateOptions(prefectures),
    showOther: false,
  },
  occupation: {
    ...commonOption,
    type: "preset",
    label: i18next.t("職業"),
    icon: identityIcon,
    occupationOptions: generateOptions(occupations),
    showOther: false,
    isRequiredDetail: false,
  },
  industry: {
    ...commonOption,
    type: "preset",
    label: i18next.t("業種"),
    icon: bussinessIcon,
    industryOptions: generateOptions(industries),
    showOther: false,
  },
  medium: {
    ...commonOption,
    type: "preset",
    label: i18next.t("認知経路"),
    icon: userIcon,
    mediumOptions: generateOptions(mediums),
    showOther: false,
    isRequiredDetail: false,
  },
  employeeNum: {
    ...commonOption,
    type: "preset",
    label: i18next.t("従業員数"),
    icon: branchIcon,
    employeeNumOptions: generateOptions(employeeNums),
    showOther: false,
  },
  terms: {
    ...commonOption,
    type: "preset",
    label: i18next.t("規約の同意"),
    blockName: `${i18next.t("規約の同意")}(${i18next.t("直接入力")})`,
    icon: checkIcon,
    terms: i18next.t("ここに規約内容を入力します。"),
    note: i18next.t("規約にご同意の上、チェックしてください"),
  },
  termsLink: {
    ...commonOption,
    type: "preset",
    label: i18next.t("規約の同意"),
    blockName: `${i18next.t("規約の同意")}（${i18next.t("リンク設置")}）`,
    icon: checkIcon,
    link: "#",
    linkText: i18next.t("利用規約"),
    note: i18next.t("規約にご同意の上、チェックしてください"),
  },
  text: {
    ...commonOption,
    type: "custom",
    label: i18next.t("単一行テキスト"),
    icon: singleTextIcon,
    placeholder: `${i18next.t("を入力", { value: i18next.t("単一行テキスト") })}...`,
    customName: "",
  },
  textarea: {
    ...commonOption,
    type: "custom",
    label: i18next.t("複数行テキスト"),
    icon: textareaIcon,
    placeholder: `${i18next.t("を入力", { value: i18next.t("複数行テキスト") })}...`,
    customName: "",
    rows: 10,
  },
  selectbox: {
    ...commonOption,
    type: "custom",
    label: `${i18next.t("単一選択")}（${i18next.t("セレクトボックス")}）`,
    icon: checkIcon,
    selectOptions: generateOptions(defaults),
    showOther: false,
    customName: "",
  },
  radio: {
    ...commonOption,
    type: "custom",
    label: `${i18next.t("単一選択")}（${i18next.t("ラジオボタン")}）`,
    icon: checkIcon,
    radioOptions: generateOptions(defaults),
    showOther: false,
    column: "2",
    customName: "",
  },
  checkbox: {
    ...commonOption,
    type: "custom",
    label: `${i18next.t("複数選択")}（${i18next.t("チェックボックス")}）`,
    icon: multipulCheckIcon,
    checkboxOptions: generateOptions(defaults),
    showOther: false,
    column: "2",
    customName: "",
  },
  headingParagraph: {
    label: i18next.t("見出しと段落"),
    type: "custom",
    icon: headingParagraphIcon,
    heading: `${i18next.t("を入力", { value: i18next.t("見出し") })}...`,
    paragraph: `${i18next.t("を入力", { value: i18next.t("段落") })}...`,
    textAlign: "left",
    headingFontSize: 23,
    paragraphFontSize: 16,
  },
  heading: {
    label: i18next.t("見出し"),
    type: "custom",
    icon: headingIcon,
    heading: `${i18next.t("を入力", { value: i18next.t("見出し") })}...`,
    textAlign: "left",
    headingFontSize: 23,
  },
  paragraph: {
    label: i18next.t("段落"),
    type: "custom",
    icon: paragraphIcon,
    paragraph: `${i18next.t("を入力", { value: i18next.t("段落") })}...`,
    textAlign: "left",
    paragraphFontSize: 16,
  },
  button: {
    label: i18next.t("ボタン"),
    type: "custom",
    icon: buttonIcon,
    text: i18next.t("ボタンテキスト"),
    url: "#",
    textColor: "#fff",
    backgroundColor: "#0086FF",
    borderRadius: 4,
  },
  submitButton: {
    label: i18next.t("送信ボタン"),
    text: i18next.t("送信"),
    icon: "",
    textColor: "#fff",
    backgroundColor: "#0086FF",
    borderRadius: 4,
  },
  calendar: {
    ...commonOption,
    type: "custom",
    label: i18next.t("日付入力"),
    icon: calendarIcon,
    customName: "",
  },
  image: {
    ...commonOption,
    label: i18next.t("画像"),
    type: "custom",
    icon: imageIcon,
    value: { url: "" },
    isSetAnchor: undefined, //false,
    href: "",
    width: 100,
    height: "auto",
    widthUnit: "%",
    heightUnit: "px",
    isSetHeightNumber: false,
  },
  link: {
    ...commonOption,
    label: i18next.t("リンク"),
    type: "custom",
    icon: linkIcon,
    href: "",
    text: i18next.t("リンク"),
    textAlign: "left",
    textFontSize: 16,
    textColor: "#0086FF",
  },
} as const;
