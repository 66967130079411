import { Box, CircularProgress, CircularProgressProps, SxProps } from "@mui/material";

export const LoadingSpinner = ({
  iconWrapperSx,
  iconColor,
  iconSize,
}: {
  iconWrapperSx?: SxProps;
  iconColor: CircularProgressProps["color"];
  iconSize: CircularProgressProps["size"];
}) => {
  return (
    <Box sx={iconWrapperSx}>
      <CircularProgress
        color={iconColor}
        size={iconSize}
      />
    </Box>
  );
};
