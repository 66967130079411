import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const OptionsIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 3 13"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-187.000000, -125.000000)"
          fill={iconColor}
        >
          <g transform="translate(20.000000, 101.000000)">
            <g>
              <path d="M168.5,27 C167.671573,27 167,26.3284271 167,25.5 C167,24.6715729 167.671573,24 168.5,24 C169.328427,24 170,24.6715729 170,25.5 C170,26.3284271 169.328427,27 168.5,27 Z M168.5,32 C167.671573,32 167,31.3284271 167,30.5 C167,29.6715729 167.671573,29 168.5,29 C169.328427,29 170,29.6715729 170,30.5 C170,31.3284271 169.328427,32 168.5,32 Z M168.5,37 C167.671573,37 167,36.3284271 167,35.5 C167,34.6715729 167.671573,34 168.5,34 C169.328427,34 170,34.6715729 170,35.5 C170,36.3284271 169.328427,37 168.5,37 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
