import { deleteRequest, get, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { SplitTestSettingsCarriersResponse } from "./type";

const fetch = async (abTestUid: string): Promise<SplitTestSettingsCarriersResponse> => {
  const ENDPOINT = `${DEFAULT_BASE_URL}/ab_tests/${abTestUid}/articles/split_test_settings/carriers`;
  return get<{}, SplitTestSettingsCarriersResponse>({}, ENDPOINT);
};

const create = async (articleUid: string, params: { carrier_id: number }): Promise<void> => {
  const ENDPOINT = `${DEFAULT_BASE_URL}/articles/${articleUid}/split_test_settings/carriers/blocklists`;
  return post(params, ENDPOINT);
};

const destroy = async (articleUid: string, params: { carrier_id: number }): Promise<void> => {
  const ENDPOINT = `${DEFAULT_BASE_URL}/articles/${articleUid}/split_test_settings/carriers/blocklists`;
  return deleteRequest(params, ENDPOINT);
};

export const AbTestArticleSplitTestSettingCarriersApi = {
  fetch,
  create,
  destroy,
} as const;
