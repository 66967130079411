import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const FunnelIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 14"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-598.000000, -271.000000)"
          fill={iconColor}
          fillRule="nonzero"
        >
          <g transform="translate(150.000000, 50.000000)">
            <g transform="translate(270.000000, 70.000000)">
              <g transform="translate(20.000000, 17.000000)">
                <g transform="translate(0.000000, 33.000000)">
                  <g transform="translate(20.000000, 80.000000)">
                    <g transform="translate(20.494141, 19.250000)">
                      <g transform="translate(117.755859, 1.750000)">
                        <path d="M5.749,9.12975 L5.74912623,13.4902344 C5.71787623,13.4707031 5.6876028,13.4433594 5.65830592,13.4082031 C5.62900905,13.3730469 5.60264186,13.3359375 5.57920436,13.296875 L3.703,10.01875 L5.749,9.12975 Z M6.516,9.29475 L8.478,10.15375 L6.68662623,13.2851562 C6.65928248,13.3242188 6.63096217,13.3613281 6.6016653,13.3964844 C6.57236842,13.4316406 6.54404811,13.4589844 6.51670436,13.4785156 L6.516,9.29475 Z M6.516,6.29475 L9.852,7.75375 L8.978,9.27975 L6.516,8.20275 L6.516,6.29475 Z M5.749,6.12975 L5.749,8.03975 L3.203,9.14675 L2.328,7.61675 L5.749,6.12975 Z M6.516,3.29475 L11.225,5.35475 L10.352,6.88075 L6.516,5.20275 L6.516,3.29475 Z M5.749,3.12975 L5.749,5.03975 L1.828,6.74475 L0.953,5.21475 L5.749,3.12975 Z M6.51670436,0.59375 C6.59092311,0.6171875 6.66514186,0.643554688 6.73936061,0.672851562 C6.81357936,0.702148438 6.89170436,0.734375 6.97373561,0.76953125 L11.9249075,2.89648438 C12.0967825,2.97070312 12.2032278,3.08398438 12.2442434,3.23632812 C12.285259,3.38867188 12.2549856,3.5546875 12.1534231,3.734375 L11.725,4.48175 L6.516,2.20275 L6.51670436,0.59375 Z M5.74912623,0.60546875 L5.749,2.03975 L0.453,4.34275 L0.112407484,3.74609375 C0.00693873355,3.56640625 -0.0243112664,3.40039062 0.0186574836,3.24804688 C0.0616262336,3.09570312 0.167094984,2.98242188 0.335063734,2.90820312 L5.29795436,0.775390625 C5.37998561,0.740234375 5.45713405,0.708007812 5.52939967,0.678710938 C5.6016653,0.649414062 5.67490748,0.625 5.74912623,0.60546875 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
