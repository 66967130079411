import { Controlled as CodeMirror } from "react-codemirror2";
import { CodeMirrorEditorProps } from "./type";

// TODO: 全てこのpartial componentを使うようにしたい

export const CodeMirrorEditor = ({ ...props }: CodeMirrorEditorProps) => {
  const codemirrorArguments = {
    ref: props.codeMirrorRef,
  };
  return (
    <CodeMirror
      {...codemirrorArguments}
      {...props}
    />
  );
};
