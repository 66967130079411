import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const MagnifierIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(-243.000000, -280.000000)"
          stroke={iconColor}
        >
          <g transform="translate(207.000000, 168.000000)">
            <g transform="translate(20.000000, 100.000000)">
              <path d="M23,12.5 C19.4101491,12.5 16.5,15.4101491 16.5,19 C16.5,22.5898509 19.4101491,25.5 23,25.5 C24.4716033,25.5 25.8672137,25.0101652 27.0017576,24.1224956 L27.3504338,23.8496906 L27.6634501,24.1627696 L31.0606602,27.5606602 C31.0606602,27.5606602 31.0606602,27.5606602 31.0606908,27.5606908 L27.3520579,23.8527014 L27.6911313,23.4992678 C28.8448493,22.2966881 29.5,20.7005144 29.5,19 C29.5,15.4101491 26.5898509,12.5 23,12.5 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
