import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import {
  BeyondPageAdStatus,
  BeyondPageListByStatus,
  EditorIcon,
  MediaIcon,
  PreferencesIcon,
  RedirectPageArrowIcon,
} from "components";
import { AppDropdown } from "components/atoms/app-dropdown";
import { TRACKIDS } from "const/trackid";
import { AbTestProps } from "domains";
import { useLogging, useMember } from "hooks";
import FolderAbTestApi from "javascripts/api/folders/abTest";
import TeamMemberLoyalityApi from "javascripts/api/teams/members/loyalty";

import Cookies from "js-cookie";
import navStyles from "stylesheets/components/editor/nav.module.scss";

import { EditorNavIconLink } from "./NavIconLink";
import { NavProps } from "./type";

const Nav = ({
  abTest,
  folder = null,
  page,
  theme = "lightTheme",
  article = null,
  hiddenTab = false,
}: NavProps) => {
  const { t } = useTranslation();
  const { sendErrorLog } = useLogging();
  const [folderLoyalty, setFolderLoyalty] = useState<any[]>([]);
  const currentTeamUid = Cookies.get("js-current-team-uid");

  useEffect(() => {
    if (folder) {
      fetchTeamMemberLoyality(folder);
    }
  }, []);

  const fetchTeamMemberLoyality = (folder: NonNullable<NavProps["folder"]>) => {
    TeamMemberLoyalityApi.fetchTeamMemberLoyality(folder)
      .then((data) => {
        setFolderLoyalty(data["data"]);
      })
      .catch((error) => {
        sendErrorLog(error);
      });
  };

  const [abTests, setAbTests] = useState<AbTestProps[]>([]);

  const fetchABTestData = () => {
    if (abTests.length) {
      return;
    }

    FolderAbTestApi.fetchAbTests(folder?.uid ?? "")
      .then((data) => {
        setAbTests(data.ab_tests);
      })
      .catch((error) => {
        sendErrorLog(error);
      });
  };

  const handleDropdownClick = () => {
    fetchABTestData();
    return true;
  };

  const memberState = useMember();
  const isFolderScopeOutside = memberState.memberAttributes.team?.id !== folder?.team_id;

  return (
    <>
      <div
        className={`${navStyles.navArticleWrapper} ${!currentTeamUid ? navStyles.inactive : ""} ${
          navStyles[theme]
        }`}
      >
        <div className={navStyles.navArticleContainer}>
          <div className={`${navStyles.navArticleItems} ${navStyles.actionItems}`}>
            <a
              href={`/folders?uid=${folder?.uid}&folder_scope=${
                isFolderScopeOutside ? "only_outside" : ""
              }`}
              className={navStyles.back}
            />
            <AppDropdown
              topBottom={"bottom"}
              leftRight={"rightCenter"}
              control={"click"}
              onAfterOpen={handleDropdownClick}
            >
              <div className={navStyles.folderIcon} />
              <BeyondPageListByStatus
                folder={folder}
                currentAbTest={abTest}
                abTests={abTests}
              />
            </AppDropdown>
          </div>
          <div className={`${navStyles.navArticleItems} ${navStyles.currentAbTest}`}>
            <div className={navStyles.title}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 0.5 }}>
                <MediaIcon
                  mediaId={abTest.media_id}
                  width={"15px"}
                  height={"15px"}
                />
                <Typography sx={{ ml: 1, fontSize: "14px" }}>{abTest.title}</Typography>
                <Box sx={{ ml: 1 }}>
                  <BeyondPageAdStatus abTest={abTest} />
                </Box>
              </Box>
              <div className={navStyles.folderNameWrapper}>
                <div className={navStyles.folderName}>{folder?.name}</div>
              </div>
            </div>
          </div>
          {hiddenTab || (
            <div className={`${navStyles.navArticleItems} ${navStyles.links}`}>
              <div className={navStyles.linksContainer}>
                {folderLoyalty.length !== 0 && folderLoyalty.includes("ab_tests/articles") && (
                  <AppDropdown
                    topBottom={"bottom"}
                    leftRight={"center"}
                    control={"hover"}
                  >
                    <EditorNavIconLink
                      theme={theme}
                      path={`/ab_tests/${abTest.uid}/articles${article ? `#${article.uid}` : ""}`}
                      isSelected={page === "editor"}
                      IconComponent={EditorIcon}
                      iconWidth="23px"
                      iconHeight="19px"
                      trackId={TRACKIDS.editorNavEditor}
                    />
                    <div className={navStyles.iconDescription}>{t("Version編集")}</div>
                  </AppDropdown>
                )}
                <AppDropdown
                  topBottom={"bottom"}
                  leftRight={"center"}
                  control={"hover"}
                >
                  <EditorNavIconLink
                    theme={theme}
                    path={`/ab_tests/${abTest.uid}/articles/split_test_settings/devices`}
                    isSelected={page === "splitTestSetting"}
                    IconComponent={PreferencesIcon}
                    iconWidth="20px"
                    iconHeight="22px"
                  />
                  <div className={navStyles.iconDescription}>
                    Version
                    <br />
                    {t("オプション設定")}
                  </div>
                </AppDropdown>

                {folderLoyalty.length !== 0 &&
                  folderLoyalty.includes("folders/ab_tests/redirect_pages") && (
                    <AppDropdown
                      topBottom={"bottom"}
                      leftRight={"center"}
                      control={"hover"}
                    >
                      <EditorNavIconLink
                        theme={theme}
                        path={`/folders/${folder?.uid}/ab_tests/${abTest.uid}/redirect_pages`}
                        isSelected={page === "redirectPage"}
                        IconComponent={RedirectPageArrowIcon}
                        iconWidth="23px"
                        iconHeight="19px"
                        trackId={TRACKIDS.editorNavRedirectPage}
                      />
                      <div className={navStyles.iconDescription}>{t("中間ページ")}</div>
                    </AppDropdown>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Nav;
