import { get } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { MediaApiFetchMediasRequest, MediaApiFetchMediasResponse } from "./type";

export const fetchMedias = async (params?: MediaApiFetchMediasRequest) =>
  get<MediaApiFetchMediasRequest | {}, MediaApiFetchMediasResponse>(
    params || {},
    `${DEFAULT_BASE_URL}/medias`
  );

export * as MediaApi from ".";
