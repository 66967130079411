export const FOLDER_OR_GROUP_REGEXP = {
  folder: /^\/folders(\/.*)?$/,
  tags: /^\/teams\/tags(\/.*)?$/,
  form: /^\/folders\/forms(\/.*)?$/,
  bulkReplace: /^\/articles\/bulk_replaces(\/.*)?$/,
  media: /^\/teams\/product_search_forms?$/,
  inspection: /^\/inspections(?!\/authorities)(\/.*)?$/,
} as const;

export const BEYOND_PAGE_OR_VERSION_REGEXP = {
  info: /^\/folders\/[^/]+\/ab_tests\/[^/]+\/edit$/,
  redirectPage: /^\/folders\/[^/]+\/ab_tests\/[^/]+\/redirect_pages$/,
  version: /^\/ab_tests(\/.*)?$/,
  popup: /^\/ab_tests\/[^/]+\/articles\/exit_popups(\/.*)?$/,
  report: /^\/ab_tests\/[^/]+\/(reports|articles\/htmls\/heatmaps\/comparisons)(\/.*)?$/,
} as const;

export const NAV_IDS = {
  forms: "forms",
  tags: "tags",
  bulkReplaces: "bulkReplaces",
  media: "media",
  inspections: "inspections",
  folders: "folders",
  info: "info",
  version: "version",
  popup: "popup",
  report: "report",
} as const;

export const ICON_SIZE = 16;
