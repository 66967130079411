import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Link, Typography } from "@mui/material";
import { TeamAffiliateServiceProviderConnectionSettingApi } from "api";
import { ShopifyPrivateApplications } from "components";
import {
  TeamAffiliateServiceProviderConnectionSettingChild,
  TeamMemberAffiliateServiceProviderResponse,
  TeamMemberAffiliateServiceProviderRoleResponse,
} from "domains";
import aspContactStyles from "stylesheets/components/team/aspAccount/aspContact.module.scss";

const FaqLinkContent: React.FC<{ title: string; faqLink: string }> = ({ title, faqLink }) => {
  const { t } = useTranslation();
  return (
    <Typography component="span">
      <Typography sx={{ fontSize: "15px" }}>{title}</Typography>
      {faqLink !== "" && (
        <Link
          target={"_blank"}
          href={faqLink}
        >
          {t("連携方法はこちら")}
        </Link>
      )}
    </Typography>
  );
};

const BtnContact: React.FC<{
  asp: TeamMemberAffiliateServiceProviderResponse;
  connectionSettings: TeamAffiliateServiceProviderConnectionSettingChild[];
  setConnectionSettings: React.Dispatch<
    React.SetStateAction<TeamAffiliateServiceProviderConnectionSettingChild[]>
  >;
  role: TeamMemberAffiliateServiceProviderRoleResponse | undefined;
  isTeamOwner: boolean;
}> = ({ asp, connectionSettings, setConnectionSettings, role, isTeamOwner }) => {
  const { t } = useTranslation();
  const [isSending, setIsSending] = useState(false);

  const setting = connectionSettings.find((d) => d.affiliate_service_provider_id === asp.id);
  const existsSetting = setting !== undefined;
  const isApplied = existsSetting && setting.status === "applied";
  const isConnected = existsSetting && setting.status === "connected";

  const idsUnderDevelopment = [47, 54];
  const isUnderDevelopment = idsUnderDevelopment.includes(asp.id);

  const isShopify = asp.id === 45;

  const isAspUpdatable = role != undefined && role.status === "member";
  const isUpdatable = isAspUpdatable || isTeamOwner;

  const create = () => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    TeamAffiliateServiceProviderConnectionSettingApi.createAspConnectionSetting(asp.id)
      .then((data) => {
        setConnectionSettings([...connectionSettings, data]);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  if (isShopify) {
    return (
      <ShopifyPrivateApplications
        isUpdatable={isUpdatable}
        faqLink={asp.faq_link}
      />
    );
  } else if (isApplied) {
    return (
      <div className={aspContactStyles.applied}>
        <FaqLinkContent
          title={isUnderDevelopment ? t("要望を受け取りました") : t("連携待ち")}
          faqLink={asp.faq_link}
        />
      </div>
    );
  } else if (isConnected) {
    return <div className={aspContactStyles.connected}>{t("連携済み")}</div>;
  } else if (isSending) {
    return (
      <Button
        variant="contained"
        sx={{
          display: "block",
          width: "130px",
          mx: "auto",
        }}
      >
        {t("申請中")}...
      </Button>
    );
  } else if (isUpdatable) {
    return (
      <Button
        variant="contained"
        sx={{
          display: "block",
          width: "130px",
          mx: "auto",
        }}
        onClick={create}
      >
        {isUnderDevelopment ? (
          <Typography
            component="span"
            variant="body2"
          >
            {t("連携要望を出す")}
          </Typography>
        ) : (
          <Typography
            component="span"
            variant="body2"
          >
            {t("連携を申請する")}
          </Typography>
        )}
      </Button>
    );
  }

  return null;
};

const Contact: React.FC<{
  asp: TeamMemberAffiliateServiceProviderResponse;
  connectionSettings: TeamAffiliateServiceProviderConnectionSettingChild[];
  setConnectionSettings: React.Dispatch<
    React.SetStateAction<TeamAffiliateServiceProviderConnectionSettingChild[]>
  >;
  aspRoles: TeamMemberAffiliateServiceProviderRoleResponse[];
  isTeamOwner: boolean;
}> = ({ asp, connectionSettings, setConnectionSettings, aspRoles, isTeamOwner }) => {
  const role = aspRoles.find((role) => role.affiliate_service_provider_id === asp.id);

  return (
    <div className={aspContactStyles.contact}>
      <div className={aspContactStyles.name}>{asp.name}</div>
      <div className={aspContactStyles.action}>
        <BtnContact
          asp={asp}
          connectionSettings={connectionSettings}
          setConnectionSettings={setConnectionSettings}
          role={role}
          isTeamOwner={isTeamOwner}
        />
      </div>
    </div>
  );
};

const AspContactBeforeMemoize: React.FC<{
  items: TeamMemberAffiliateServiceProviderResponse[];
  connectionSettings: TeamAffiliateServiceProviderConnectionSettingChild[];
  setConnectionSettings: React.Dispatch<
    React.SetStateAction<TeamAffiliateServiceProviderConnectionSettingChild[]>
  >;
  aspRoles: TeamMemberAffiliateServiceProviderRoleResponse[];
  isTeamOwner: boolean;
}> = ({ items, connectionSettings, setConnectionSettings, aspRoles, isTeamOwner }) => {
  const list = items.map((asp) => {
    return (
      <Contact
        key={asp.id}
        asp={asp}
        connectionSettings={connectionSettings}
        setConnectionSettings={setConnectionSettings}
        aspRoles={aspRoles}
        isTeamOwner={isTeamOwner}
      />
    );
  });

  return (
    <>
      <div className={aspContactStyles.contacts}>{list}</div>
    </>
  );
};

export const AspContact = React.memo(AspContactBeforeMemoize) as typeof AspContactBeforeMemoize;
