import { get, patch, post } from "api";
import { DEFAULT_BASE_URL, DEFAULT_FILE_API_URL } from "const/app";
import { FolderFormPhotoProps } from "domains";

type FetchFolderFormPhotoParams = {
  page?: number;
  search_range?: string;
};

type FetchFolderFormPhotoResponse = {
  photos: FolderFormPhotoProps[];
  next_page: number | null;
};

const fetch = (folderUid: string, formUid: string, params: FetchFolderFormPhotoParams) => {
  const path = `${DEFAULT_BASE_URL}/folders/${folderUid}/forms/${formUid}/photos`;

  return get<FetchFolderFormPhotoParams, FetchFolderFormPhotoResponse>(params, path);
};

export interface CreateFolderFormPhotoParams extends FormData {
  folder_form_photos?: {
    photo?: File;
    photo_id?: number;
    published?: boolean;
  };
}

export type CreateFolderFormPhotoResponse = FolderFormPhotoProps;

const create = (folderUid: string, formUid: string, params: CreateFolderFormPhotoParams) => {
  const path = `${DEFAULT_FILE_API_URL}/folders/${folderUid}/forms/${formUid}/photos`;

  return post<CreateFolderFormPhotoParams, CreateFolderFormPhotoResponse>(params, path, true, {
    "Content-Type": "multipart/form-data",
  });
};

type UpdateFolderFormPhotoUpdateParams = {
  folder_form_photo: {
    published: boolean;
  };
};

type UpdateFolderFormPhotoResponse = FolderFormPhotoProps;

const update = (
  folderUid: string,
  formUid: string,
  photoId: number,
  params: UpdateFolderFormPhotoUpdateParams
) => {
  const path = `${DEFAULT_BASE_URL}/folders/${folderUid}/forms/${formUid}/photos/${photoId}`;

  return patch<UpdateFolderFormPhotoUpdateParams, UpdateFolderFormPhotoResponse>(params, path);
};

export const FolderFormPhotoApi = {
  fetch,
  create,
  update,
} as const;
