import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const DeviceIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 14"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="settings-time"
          transform="translate(-900.000000, -313.000000)"
          fill={iconColor}
        >
          <g
            id="Group-19"
            transform="translate(880.000000, 98.000000)"
          >
            <g
              id="sabumenyu"
              transform="translate(0.000000, 170.000000)"
            >
              <path
                d="M30.5,58 C30.7761424,58 31,58.2238576 31,58.5 C31,58.7761424 30.7761424,59 30.5,59 L24.5,59 C24.2238576,59 24,58.7761424 24,58.5 C24,58.2238576 24.2238576,58 24.5,58 L30.5,58 Z M37,51 C37.5522847,51 38,51.4477153 38,52 L38,58 C38,58.5522847 37.5522847,59 37,59 L34,59 C33.4477153,59 33,58.5522847 33,58 L33,52 C33,51.4477153 33.4477153,51 34,51 L37,51 Z M35.5,57 C35.2238576,57 35,57.2238576 35,57.5 C35,57.7761424 35.2238576,58 35.5,58 C35.7761424,58 36,57.7761424 36,57.5 C36,57.2238576 35.7761424,57 35.5,57 Z M34,45 C34.5522847,45 35,45.4477153 35,46 L35,50 L34,50 C32.9456382,50 32.0818349,50.8158778 32.0054857,51.8507377 L32,52 L32,57 L21,57 C20.4477153,57 20,56.5522847 20,56 L20,46 C20,45.4477153 20.4477153,45 21,45 L34,45 Z M27.5,55 C27.2238576,55 27,55.2238576 27,55.5 C27,55.7761424 27.2238576,56 27.5,56 C27.7761424,56 28,55.7761424 28,55.5 C28,55.2238576 27.7761424,55 27.5,55 Z"
                id="icon_devices"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
