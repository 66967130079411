import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Link, TextField, Typography, Collapse } from "@mui/material";
import { BeyondPageMediaPostbackDescription, LineConversionApiDescription } from "components";
import { AbTestProps } from "domains";
import { useEditAbTest, useMember } from "hooks";
import { XConversionApiDescription } from "../x-conversion-api-description";

const checkKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
  if (e.code === "Enter") e.preventDefault();
};

type TiktokSettingFormValues = {
  base?: string;
  tiktokPixelCode: string;
  tiktokAccessToken: string;
};

const TiktokSetting: React.FC<{
  abTest: AbTestProps;
  handleDialogClose: Function;
}> = ({ abTest, handleDialogClose }) => {
  const { t } = useTranslation();
  const [handleEditAbTest, editAbTestLoading, editAbTestError] = useEditAbTest();

  const { register, handleSubmit, errors, setError, setValue, watch } =
    useForm<TiktokSettingFormValues>({
      defaultValues: {
        tiktokPixelCode: abTest?.tiktok_events_api_setting?.pixel_code || "",
        tiktokAccessToken: abTest?.tiktok_events_api_setting?.access_token,
      },
    });

  const onSubmit = async (data: TiktokSettingFormValues) => {
    const params: { [key: string]: string | number | undefined | null } = {
      tiktok_pixel_code: data.tiktokPixelCode,
      tiktok_access_token: data.tiktokAccessToken,
    };
    await handleEditAbTest(abTest?.uid, { ab_test: params });
    if (handleDialogClose !== undefined) {
      handleDialogClose();
    }
  };

  useEffect(() => {
    register("tiktokPixelCode");
    register("tiktokAccessToken");
  }, [register]);

  useEffect(() => {
    if (editAbTestError) {
      setError("base", "invalid", editAbTestError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAbTestError]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => checkKeyDown(e)}
    >
      <Box sx={{ pt: 1 }}>
        <TextField
          type="text"
          label="Tiktok pixel code"
          placeholder=""
          variant="outlined"
          error={!!errors.tiktokPixelCode}
          helperText={errors.tiktokPixelCode?.message}
          defaultValue={watch("tiktokPixelCode")}
          onChange={(e) => setValue("tiktokPixelCode", e.target.value)}
          required
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          type="text"
          label="Tiktok access token"
          placeholder=""
          variant="outlined"
          error={!!errors.tiktokAccessToken}
          helperText={errors.tiktokAccessToken?.message}
          defaultValue={watch("tiktokAccessToken")}
          onChange={(e) => setValue("tiktokAccessToken", e.target.value)}
          required
          fullWidth
        />
        <Box sx={{ mb: 1 }}>
          <Link
            href="https://knowledge.squadbeyond.com/tiktok"
            target="_blank"
            sx={{ fontSize: "12px", fontWeight: "normal" }}
            component={Button}
          >
            {t("の取得手順について", { value: "Tiktok access token" })}
          </Link>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={editAbTestLoading}
          disabled={editAbTestLoading}
        >
          {t("更新する")}
        </LoadingButton>
        {errors.base && (
          <Box sx={{ pt: 1 }}>
            <Typography
              variant="subtitle2"
              color="error"
            >
              {errors.base?.message}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

type FacebookSettingFormValues = {
  base?: string;
  facebookPixelId: number;
  facebookAccessToken: string;
};

const FacebookSetting: React.FC<{
  abTest: AbTestProps;
  handleDialogClose: Function;
}> = ({ abTest, handleDialogClose }) => {
  const { t } = useTranslation();
  const [handleEditAbTest, editAbTestLoading, editAbTestError] = useEditAbTest();

  const { register, handleSubmit, errors, setError, setValue, watch } =
    useForm<FacebookSettingFormValues>({
      defaultValues: {
        facebookPixelId: abTest?.facebook_conversion_api_setting?.pixel_id || 0,
        facebookAccessToken: abTest?.facebook_conversion_api_setting?.access_token || "",
      },
    });

  const onSubmit = async (data: FacebookSettingFormValues) => {
    const params: { [key: string]: string | number | undefined | null } = {
      facebook_pixel_id: data.facebookPixelId,
      facebook_access_token: data.facebookAccessToken,
    };
    await handleEditAbTest(abTest?.uid, { ab_test: params });
    if (handleDialogClose !== undefined) {
      handleDialogClose();
    }
  };

  useEffect(() => {
    register("facebookPixelId");
    register("facebookAccessToken");
  }, [register]);

  useEffect(() => {
    if (editAbTestError) {
      setError("base", "invalid", editAbTestError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAbTestError]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => checkKeyDown(e)}
      data-testid="facebook-setting"
    >
      <Box sx={{ pt: 1 }}>
        <TextField
          type="number"
          label="Facebook pixel id"
          placeholder=""
          variant="outlined"
          error={!!errors.facebookPixelId}
          helperText={errors.facebookPixelId?.message}
          defaultValue={watch("facebookPixelId")}
          onChange={(e) => setValue("facebookPixelId", +e.target.value)}
          required
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          type="text"
          label="Facebook access token"
          placeholder=""
          variant="outlined"
          error={!!errors.facebookAccessToken}
          helperText={errors.facebookAccessToken?.message}
          defaultValue={watch("facebookAccessToken")}
          onChange={(e) => setValue("facebookAccessToken", e.target.value)}
          required
          fullWidth
        />
        <Box sx={{ mb: 1 }}>
          <Link
            href="https://knowledge.squadbeyond.com/facebook%E3%81%AE%E3%83%9D%E3%82%B9%E3%83%88%E3%83%90%E3%83%83%E3%82%AF%E6%A9%9F%E8%83%BD%E3%81%A7%E4%BD%BF%E3%81%86%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%83%88%E3%83%BC%E3%82%AF%E3%83%B3%E3%81%AF%E3%81%A9%E3%82%8C%E3%81%A7%E3%81%99%E3%81%8B"
            target="_blank"
            sx={{ fontSize: "12px", fontWeight: "normal" }}
            component={Button}
          >
            {t("の取得手順について", { value: "Facebook access token" })}
          </Link>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={editAbTestLoading}
          disabled={editAbTestLoading}
        >
          {t("更新する")}
        </LoadingButton>
        {errors.base && (
          <Box sx={{ pt: 1 }}>
            <Typography
              variant="subtitle2"
              color="error"
            >
              {errors.base?.message}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

type LineSettingFormValues = {
  base?: string;
  lineTagId: string;
  lineAccessToken: string;
  lineEventName: string;
};

const LineSetting: React.FC<{
  abTest: AbTestProps;
  handleDialogClose: Function;
}> = ({ abTest, handleDialogClose }) => {
  const { t } = useTranslation();
  const [handleEditAbTest, editAbTestLoading, editAbTestError] = useEditAbTest();
  const [isOpenLineDescription, setIsOpenLineDescription] = useState(false);

  const { register, handleSubmit, errors, setError, setValue, watch } =
    useForm<LineSettingFormValues>({
      defaultValues: {
        lineTagId: abTest?.line_conversion_api_setting?.line_tag_id || "",
        lineAccessToken: abTest?.line_conversion_api_setting?.access_token || "",
        lineEventName: abTest?.line_conversion_api_setting?.event_name || "",
      },
    });

  const onSubmit = async (data: LineSettingFormValues) => {
    const params: { [key: string]: string } = {
      line_tag_id: data.lineTagId,
      line_access_token: data.lineAccessToken,
      line_event_name: data.lineEventName,
    };
    await handleEditAbTest(abTest?.uid, { ab_test: params });
    if (handleDialogClose !== undefined) {
      handleDialogClose();
    }
  };

  useEffect(() => {
    register("lineTagId");
    register("lineAccessToken");
    register("lineEventName");
  }, [register]);

  useEffect(() => {
    if (editAbTestError) {
      setError("base", "invalid", editAbTestError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAbTestError]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => checkKeyDown(e)}
      data-testid="line-setting"
    >
      <Box sx={{ pt: 1 }}>
        <TextField
          type="string"
          label="LINE tag id"
          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
          variant="outlined"
          error={!!errors.lineTagId}
          helperText={errors.lineTagId?.message}
          defaultValue={watch("lineTagId")}
          onChange={(e) => setValue("lineTagId", e.target.value)}
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          type="text"
          label="LINE access token"
          placeholder=""
          variant="outlined"
          error={!!errors.lineAccessToken}
          helperText={errors.lineAccessToken?.message}
          defaultValue={watch("lineAccessToken")}
          onChange={(e) => setValue("lineAccessToken", e.target.value)}
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          type="text"
          label="LINE event name"
          placeholder={`${t("デフォルトのevent名はConversionになります")}`}
          variant="outlined"
          error={!!errors.lineEventName}
          helperText={errors.lineEventName?.message}
          defaultValue={watch("lineEventName")}
          onChange={(e) => setValue("lineEventName", e.target.value)}
          fullWidth
          sx={{ mb: 1 }}
        />
      </Box>
      <LoadingButton
        variant="contained"
        type="submit"
        loading={editAbTestLoading}
        disabled={editAbTestLoading}
      >
        {t("更新する")}
      </LoadingButton>
      {errors.base && (
        <Box sx={{ pt: 1 }}>
          <Typography
            variant="subtitle2"
            color="error"
          >
            {errors.base?.message}
          </Typography>
        </Box>
      )}
      <Button
        sx={{
          fontSize: "13px",
          fontWeight: "normal",
          display: "block",
          mt: 1,
        }}
        size="small"
        onClick={() => setIsOpenLineDescription(!isOpenLineDescription)}
      >
        {t("LINE Conversion連携に必要な情報の取得手順について")}
      </Button>
      <Collapse in={isOpenLineDescription}>
        <Box
          sx={{
            mb: 2,
            backgroundColor: "background.secondary",
            borderRadius: "10px",
            p: 2,
          }}
        >
          <LineConversionApiDescription />
        </Box>
      </Collapse>
    </Box>
  );
};

const GoogleSetting: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <p>{t("CVポストバック条件")}</p>
      <p>
        {t(
          "CVデータが含まれたスプレッドシートをGoogle管理画面にアップロードすることで、CVがポストバックされます。",
        )}
        <br />（
        {t(
          "CVデータは、広告をクリックすると付与されるgclidというパラメータをもってbeyondページにアクセスした人が、CVするとスプレッドシートに反映されます",
        )}
        ）
      </p>
      {/* TODO: ボタン機能実装時に文言のコメントアウト解除 */}
      {/* <p>
        スプレッドシートの共有がまだない方は、下記の申請ボタンを押してください。
      </p>
      <LoadingButton
          variant="contained"
          type="submit"
          loading={false}
          disabled={false}
        >
          連携を申請する
        </LoadingButton> */}
    </Box>
  );
};

const YahooSearchSetting: React.FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <p>{t("CVポストバック条件")}</p>
      <p>
        {t(
          "CVデータが含まれたスプレッドシートをYahoo管理画面にアップロードすることで、CVがポストバックされます。",
        )}
        <br />（
        {t(
          "CVデータは、広告をクリックすると付与されるyclidというパラメータをもってbeyondページにアクセスした人が、CVするとスプレッドシートに反映されます",
        )}
        ）
      </p>
      {/* TODO: ボタン機能実装時に文言のコメントアウト解除 */}
      {/* <p>
        スプレッドシートの共有がまだない方は、下記の申請ボタンを押してください。
      </p>
      <LoadingButton
          variant="contained"
          type="submit"
          loading={false}
          disabled={false}
        >
          連携を申請する
        </LoadingButton> */}
    </Box>
  );
};

type XSettingFormValues = {
  base?: string;
  xPixelId: string;
  xEventId: string;
};

const XSetting: React.FC<{
  abTest: AbTestProps;
  handleDialogClose: Function;
}> = ({ abTest, handleDialogClose }) => {
  const { t } = useTranslation();
  const [handleEditAbTest, editAbTestLoading, editAbTestError] = useEditAbTest();
  const [isOpenXDescription, setIsOpenXDescription] = useState(false);

  const { register, handleSubmit, errors, setError, setValue, watch } = useForm<XSettingFormValues>(
    {
      defaultValues: {
        xPixelId: abTest?.x_conversion_api_setting?.pixel_id || "",
        xEventId: abTest?.x_conversion_api_setting?.event_id || "",
      },
    },
  );

  const onSubmit = async (data: XSettingFormValues) => {
    const params: { [key: string]: string } = {
      x_pixel_id: data.xPixelId,
      x_event_id: data.xEventId,
    };
    await handleEditAbTest(abTest?.uid, { ab_test: params });
    if (handleDialogClose !== undefined) {
      handleDialogClose();
    }
  };

  useEffect(() => {
    register("xPixelId");
    register("xEventId");
  }, [register]);

  useEffect(() => {
    if (editAbTestError) {
      setError("base", "invalid", editAbTestError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAbTestError]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => checkKeyDown(e)}
      data-testid="x-setting"
    >
      <Box sx={{ pt: 1 }}>
        <TextField
          type="string"
          label="X Pixel ID"
          placeholder="xxxxx"
          variant="outlined"
          error={!!errors.xPixelId}
          helperText={errors.xPixelId?.message}
          defaultValue={watch("xPixelId")}
          onChange={(e) => setValue("xPixelId", e.target.value)}
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          type="string"
          label="X Event ID"
          placeholder="xxxxx"
          variant="outlined"
          error={!!errors.xEventId}
          helperText={errors.xEventId?.message}
          defaultValue={watch("xEventId")}
          onChange={(e) => setValue("xEventId", e.target.value)}
          fullWidth
          sx={{ mb: 1 }}
        />
      </Box>
      <LoadingButton
        variant="contained"
        type="submit"
        loading={editAbTestLoading}
        disabled={editAbTestLoading}
      >
        {t("更新する")}
      </LoadingButton>
      {errors.base && (
        <Box sx={{ pt: 1 }}>
          <Typography
            variant="subtitle2"
            color="error"
          >
            {errors.base?.message}
          </Typography>
        </Box>
      )}
      <Button
        sx={{
          fontSize: "13px",
          fontWeight: "normal",
          display: "block",
          mt: 1,
        }}
        size="small"
        onClick={() => setIsOpenXDescription(!isOpenXDescription)}
      >
        {t("X Conversion連携に必要な情報の取得手順について")}
      </Button>
      <Collapse in={isOpenXDescription}>
        <Box
          sx={{
            mb: 2,
            backgroundColor: "background.secondary",
            borderRadius: "10px",
            p: 2,
          }}
        >
          <XConversionApiDescription />
        </Box>
      </Collapse>
    </Box>
  );
};

export const BeyondPageMediaPostbackSetting: React.FC<{
  abTest: AbTestProps;
  handleDialogClose: Function;
}> = ({ abTest, handleDialogClose }) => {
  switch (abTest.media_id) {
    case 1:
    case 2:
      return (
        <FacebookSetting
          abTest={abTest}
          handleDialogClose={handleDialogClose}
        />
      );
    case 8:
      return (
        <LineSetting
          abTest={abTest}
          handleDialogClose={handleDialogClose}
        />
      );
    case 39:
      return (
        <TiktokSetting
          abTest={abTest}
          handleDialogClose={handleDialogClose}
        />
      );
    case 3:
      return (
        <XSetting
          abTest={abTest}
          handleDialogClose={handleDialogClose}
        />
      );
    case 21:
    case 19:
    case 25:
    case 36:
    case 51:
    case 53:
    case 9:
    case 48:
    case 47:
    case 4:
      return <BeyondPageMediaPostbackDescription mediaId={abTest.media_id} />;
    // GDN
    case 6:
      return <GoogleSetting />;
    // google search
    case 13:
      return <GoogleSetting />;
    case 12:
      return <YahooSearchSetting />;
    case 5:
      return <YahooYdnConvSetting abTest={abTest} />;
    default:
      return null;
  }
};

type YahooYdnConvFormValues = {
  base?: string;
  yahooYdnConvIo: string;
  yahooYdnConvLabel: string;
  yahooYdnConvValue: string;
  yahooYdnConvAppId: string;
};

const YahooYdnConvSetting: React.FC<{
  abTest: AbTestProps;
}> = ({ abTest }) => {
  const memberState = useMember();
  const isBygones = !!memberState.memberAttributes.isBygones;
  if (!isBygones) {
    return null;
  }

  const { t } = useTranslation();
  const [handleEditAbTest, editAbTestLoading, editAbTestError] = useEditAbTest();

  const { register, handleSubmit, errors, setError, setValue, watch } =
    useForm<YahooYdnConvFormValues>({
      defaultValues: {
        yahooYdnConvIo: abTest?.yahoo_ydn_conversion_api_setting?.yahoo_ydn_conv_io || "",
        yahooYdnConvLabel: abTest?.yahoo_ydn_conversion_api_setting?.yahoo_ydn_conv_label || "",
        yahooYdnConvValue: abTest?.yahoo_ydn_conversion_api_setting?.yahoo_ydn_conv_value || "",
        yahooYdnConvAppId: abTest?.yahoo_ydn_conversion_api_setting?.app_id || "",
      },
    });

  const onSubmit = async (data: YahooYdnConvFormValues) => {
    const params: { [key: string]: string } = {
      yahoo_ydn_conv_io: data.yahooYdnConvIo,
      yahoo_ydn_conv_label: data.yahooYdnConvLabel,
      yahoo_ydn_conv_value: data.yahooYdnConvValue,
      yahoo_ydn_conv_app_id: data.yahooYdnConvAppId,
    };
    await handleEditAbTest(abTest?.uid, { ab_test: params });
  };

  useEffect(() => {
    register("yahooYdnConvIo");
    register("yahooYdnConvLabel");
    register("yahooYdnConvValue");
    register("yahooYdnConvAppId");
  }, [register]);

  useEffect(() => {
    if (editAbTestError) {
      setError("base", "invalid", editAbTestError.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAbTestError]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => checkKeyDown(e)}
      data-testid="yahoo-ydn-setting"
    >
      <Box sx={{ pt: 1 }}>
        <TextField
          type="text"
          label="AppID"
          placeholder=""
          variant="outlined"
          error={!!errors.yahooYdnConvAppId}
          helperText={errors.yahooYdnConvAppId?.message}
          defaultValue={watch("yahooYdnConvAppId")}
          onChange={(e) => setValue("yahooYdnConvAppId", e.target.value)}
          required
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          type="string"
          label="yahoo_ydn_conv_io"
          placeholder=""
          variant="outlined"
          error={!!errors.yahooYdnConvIo}
          helperText={errors.yahooYdnConvIo?.message}
          defaultValue={watch("yahooYdnConvIo")}
          onChange={(e) => setValue("yahooYdnConvIo", e.target.value)}
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          type="text"
          label="yahoo_ydn_conv_label"
          placeholder=""
          variant="outlined"
          error={!!errors.yahooYdnConvLabel}
          helperText={errors.yahooYdnConvLabel?.message}
          defaultValue={watch("yahooYdnConvLabel")}
          onChange={(e) => setValue("yahooYdnConvLabel", e.target.value)}
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          type="text"
          label="yahoo_ydn_conv_value"
          placeholder=""
          variant="outlined"
          error={!!errors.yahooYdnConvValue}
          helperText={errors.yahooYdnConvValue?.message}
          defaultValue={watch("yahooYdnConvValue")}
          onChange={(e) => setValue("yahooYdnConvValue", e.target.value)}
          fullWidth
          sx={{ mb: 1 }}
        />
      </Box>
      <LoadingButton
        variant="contained"
        type="submit"
        loading={editAbTestLoading}
        disabled={editAbTestLoading}
      >
        {t("更新する")}
      </LoadingButton>
      {errors.base && (
        <Box sx={{ pt: 1 }}>
          <Typography
            variant="subtitle2"
            color="error"
          >
            {errors.base?.message}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
