import axios from "axios";
import { FolderFormContactTagProps } from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderFormContactTagResponse = {
  tags: FolderFormContactTagProps[];
  next_page: number | null;
};

class FolderFormContactTagApi extends ApiBase {
  static fetch(folderUid: string, params: any) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderFormContactTagResponse>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/forms/contacts/tags`;
      axios
        .get(path, { params, withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  }

  static create(folderUid: string, params: any) {
    const baseUrl = this.defaultBaseUrl();
    const path = `${baseUrl}/folders/${folderUid}/forms/contacts/tags`;
    return new Promise<any>(function (resolve, reject) {
      axios
        .post(path, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static destroy(folderUid: string, tagId: number) {
    const baseUrl = this.defaultBaseUrl();
    const path = `${baseUrl}/folders/${folderUid}/forms/contacts/tags/${tagId}`;
    return new Promise<any>(function (resolve, reject) {
      axios
        .delete(path, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderFormContactTagApi;
