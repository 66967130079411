import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";
import { FolderGroupingProps, FolderGroupProps } from "domains";
import { folderGroupingListState } from "hooks";
import FolderGroupApi from "javascripts/api/folderGroup";

export const folderGroupListState = atom<FolderGroupProps[]>({
  key: "folderGroupListState",
  default: [],
});

export type HandleFetchFolderGroupListProps = (
  teamId?: number | string,
  scope?: string,
  isIncludeFolders?: boolean
) => void;
export const useFetchFolderGroupList = () => {
  const [_folderGroupList, setFolderGroupList] = useRecoilState(folderGroupListState);
  const [loading, setLoading] = useState<boolean>(false);

  const handle: HandleFetchFolderGroupListProps = useCallback(
    async (teamId, scope, isIncludeFolders = true) => {
      if (loading) {
        return;
      }
      setLoading(true);

      try {
        const response = await FolderGroupApi.fetchFolderGroups({
          team_id: teamId,
          folder_group_scope: scope,
          include_folders: isIncludeFolders,
        });
        setFolderGroupList(response.folder_groups);
        setLoading(false);
      } catch (e) {
        setFolderGroupList([]);
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [handle, loading] as const;
};

export const useFolderGroupList = (args?: { isIncludeFolders?: boolean }) => {
  const [folderGroupList, setFolderGroupList] = useRecoilState(folderGroupListState);
  const [handle, loading] = useFetchFolderGroupList();

  useEffect(() => {
    handle(undefined, undefined, args?.isIncludeFolders);
  }, []);

  return [folderGroupList, setFolderGroupList, loading] as const;
};

export const useUpdateFolderGroup = () => {
  const [folderGroupList, setFolderGroupList] = useRecoilState(folderGroupListState);
  const [loading, setLoading] = useState<boolean>(false);

  const handle = useCallback(async (folderGroupId: number, params: {}) => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      const response = await FolderGroupApi.update(folderGroupId, params);
      const newList = folderGroupList.map((d: FolderGroupProps) => {
        if (d.id === folderGroupId) {
          return { ...d, ...response };
        } else {
          return d;
        }
      });
      setFolderGroupList(newList);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, []);

  return [handle, loading] as const;
};

export const useDeleteFolderGroup = () => {
  const { t } = useTranslation();
  const [folderGroupList, setFolderGroupList] = useRecoilState(folderGroupListState);
  const [folderGroupingList, setFolderGroupingList] = useRecoilState(folderGroupingListState);
  const [loading, setLoading] = useState<boolean>(false);

  const handle = useCallback(
    async (folderGroupId: number) => {
      if (
        !window.confirm(
          t("本当に削除しますか？削除を実行すると後から元に戻すことはできません") || ""
        )
      ) {
        return;
      }

      if (loading) {
        return;
      }
      setLoading(true);

      try {
        await FolderGroupApi.destroy(folderGroupId);

        const newFolderGroupList = folderGroupList.filter((folderGroup: FolderGroupProps) => {
          return folderGroup.id !== folderGroupId;
        });
        setFolderGroupList(newFolderGroupList);

        const newFolderGroupingList = folderGroupingList.filter((grouping: FolderGroupingProps) => {
          return grouping.folder_group_id !== folderGroupId;
        });
        setFolderGroupingList(newFolderGroupingList);

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderGroupList, folderGroupingList]
  );

  return [handle, loading] as const;
};

export const useCreateFolderGroup = () => {
  const [folderGroupList, setFolderGroupList] = useRecoilState(folderGroupListState);
  const [loading, setLoading] = useState<boolean>(false);

  const handle = useCallback(
    async (params: {}) => {
      if (loading) {
        return;
      }
      setLoading(true);

      try {
        const response = await FolderGroupApi.create(params);
        setFolderGroupList([...folderGroupList, response]);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderGroupList]
  );

  return [handle, loading] as const;
};
