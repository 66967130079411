import axios from "axios";
import {
  AbTestProps,
  ConversionEventTagProps,
  ConversionTagAbTestProps,
  FolderProps,
} from "domains";
import ApiBase from "javascripts/api/apiBase";

type FetchFolderConversionTagResponse = {
  folder: FolderProps;
  conversion_tags: {
    id: number;
    name: string;
    uid: string;
    conversion_tag_ab_tests: ConversionTagAbTestProps[];
    tracking_reports: any[];
    event_tags: ConversionEventTagProps[];
  }[];
};

type FetchEditConversionTagResponse = {
  conversion_tag_ab_tests: ConversionTagAbTestProps[];
  unselected_ab_tests: AbTestProps[];
};

class FolderConversionTagApi extends ApiBase {
  static fetchConversionTags(folderUid: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchFolderConversionTagResponse>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/conversion_tags`;
      axios
        .get(path, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static createConversionTag(folderUid: string, params: any) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/conversion_tags`;
      axios
        .post(path, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static editConversionTag(folderUid: string, uid: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<FetchEditConversionTagResponse>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/conversion_tags/${uid}/edit`;
      axios
        .get(path, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static updateConversionTag(folderUid: string, uid: string, params: any) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<any>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/conversion_tags/${uid}`;
      axios
        .patch(path, params, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }

  static destroyConversionTag(folderUid: string, uid: string) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise<{}>(function (resolve, reject) {
      const path = `${baseUrl}/folders/${folderUid}/conversion_tags/${uid}`;
      axios
        .delete(path, { withCredentials: true })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.data));
    });
  }
}

export default FolderConversionTagApi;
