import "./index.css";

import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import "locales/i18next";

import { AWS } from "const/aws";
import App from "./App";

Amplify.configure({
  Auth: {
    identityPoolId: AWS.COGNITO.IDENTITY_POOL_ID,
    region: AWS.COGNITO.REGION,
    userPoolId: AWS.COGNITO.USER_POOL_ID,
    userPoolWebClientId: AWS.COGNITO.USER_POOL_CLEINT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: AWS.S3.BUCKET_NAME,
      region: AWS.S3.REGION,
    },
  },
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
