import { useState, useEffect } from "react";

import imageDropdownStyles from "stylesheets/components/quillEditor/imageDropdown.module.scss";
import { ImageDropdownMoveNavProps } from "./type";

export const ImageDropdownMoveNav = ({
  article,
  dropdownRef,
  keyName,
  dropdownAbsolutePosition,
}: ImageDropdownMoveNavProps) => {
  const [isDropdownMove, handleIsDropdownMove] = useState(false);
  const [initialDropdownOffset, setInitialDropdownOffset] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: 0, y: 0 });

  const dropdown = dropdownRef.current;

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);

    return function cleanup() {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDropdownMove]);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);

    return function cleanup() {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDropdownMove]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (dropdown) {
      dropdown.classList.add(`${imageDropdownStyles.moving}`);
      setInitialDropdownOffset({
        x: dropdown.offsetLeft - e.clientX,
        y: dropdown.offsetTop - e.clientY,
      });
    }

    handleIsDropdownMove(true);
  };

  const handleMouseUp = () => {
    if (!isDropdownMove) return;
    dropdown?.classList.remove(`${imageDropdownStyles.moving}`);
    const position = { top: dropdown?.style.top, left: dropdown?.style.left };
    localStorage.setItem(keyName, JSON.stringify(position));
    handleIsDropdownMove(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!dropdown || !isDropdownMove) return;

    const position: { x: number | undefined; y: number | undefined } = {
      x: e.clientX + (initialDropdownOffset.x ?? 0),
      y: e.clientY + (initialDropdownOffset.y ?? 0),
    };
    dropdown.style.top = `${position.y}px`;
    dropdown.style.left = `${position.x}px`;
    const maxMoveHorizontal = window.innerWidth;
    const maxMoveVertical = window.innerHeight;
    const targetRect = dropdown.getBoundingClientRect();
    if (article.ab_test.editor_version === 3) {
      if (targetRect.top <= 0) {
        dropdown.style.top = `${dropdownAbsolutePosition?.y}px`;
        position.y = dropdownAbsolutePosition?.y;
      }
      if (targetRect.left <= 0) {
        dropdown.style.left = `${dropdownAbsolutePosition?.x}px`;
        position.x = dropdownAbsolutePosition?.x;
      }
      if (targetRect.bottom >= maxMoveVertical) {
        dropdown.style.top = `${
          maxMoveVertical - targetRect.height + (dropdownAbsolutePosition?.y ?? 0)
        }px`;
        position.y = maxMoveVertical;
      }
      if (targetRect.right >= maxMoveHorizontal) {
        dropdown.style.left = `${
          maxMoveHorizontal - targetRect.width + (dropdownAbsolutePosition?.x ?? 0)
        }px`;
        position.x = maxMoveHorizontal;
      }
    } else {
      if (targetRect.top <= 0) {
        dropdown.style.top = `0px`;
        position.x = 0;
      }
      if (targetRect.left <= 0) {
        dropdown.style.left = `0px`;
        position.y = 0;
      }
      if (targetRect.right >= maxMoveHorizontal) {
        dropdown.style.left = `${maxMoveHorizontal - targetRect.width}px`;
        position.x = maxMoveHorizontal;
      }
      if (targetRect.bottom >= maxMoveVertical) {
        dropdown.style.top = `${maxMoveVertical - targetRect.height}px`;
        position.y = maxMoveHorizontal;
      }
    }
    setInitialDropdownOffset(position);
  };

  return (
    <div
      className={imageDropdownStyles.dropdownMoveNav}
      onMouseDown={(e) => handleMouseDown(e)}
    >
      <span></span>
    </div>
  );
};
