import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import AbTestArticleReportApi from "javascripts/api/abTest/articles/report";

export const articleDailyReportListState = atom({
  key: "articleDailyReportListState",
  default: [],
});

// TODO: options, { start, end, includeFunnelSteps }
export const useArticleDailyReportList = (
  abTestUid: string,
  startDate: string,
  endDate: string,
  includeFunnelSteps: boolean
) => {
  const [articleDailyReportList, setArticleDailyReportList] = useRecoilState(
    articleDailyReportListState
  );
  const [loading, _setLoading] = useState(false);

  useEffect(() => {
    if (abTestUid === undefined) {
      return;
    }
    const params = {
      start_date: startDate,
      end_date: endDate,
      include_funnel_steps: includeFunnelSteps,
    };
    AbTestArticleReportApi.fetchReports(abTestUid, params).then((data) => {
      setArticleDailyReportList(data.reports);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abTestUid, startDate, endDate, includeFunnelSteps]);

  return [articleDailyReportList, loading] as const;
};
