import akaneIcon from 'assets/images/icons/akane.svg';
import facebookIcon from 'assets/images/icons/facebook.svg';
import googleIcon from 'assets/images/icons/google.svg';
import imobileIcon from 'assets/images/icons/imobile.svg';
import lineIcon from 'assets/images/icons/line.svg';
import logifyIcon from 'assets/images/icons/logify.svg';
import nosnsiconIcon from 'assets/images/icons/nosnsicon.svg';
import popinIcon from 'assets/images/icons/popin.svg';
import scaleoutIcon from 'assets/images/icons/scaleout.svg';
import smartnewsIcon from 'assets/images/icons/smartnews.svg';
import tiktokIcon from 'assets/images/icons/tiktok.svg';
import twitterIcon from 'assets/images/icons/twitter.svg';
import yahooIcon from 'assets/images/icons/yahoo.svg';
import zucksIcon from 'assets/images/icons/zucks.svg';

import i18next from 'i18next';

class Utility {
    static dateHelper(unixtime) {
      let date = new Date(unixtime * 1000);

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      return `${year}${i18next.t("年")}${month}${i18next.t("月")}${day}${i18next.t("日~.単日", { value: "" })}`;
    }

    static dateMonthHelper(unixtime) {
        let date = new Date(unixtime * 1000);

        let year = date.getFullYear();
        let month = date.getMonth() + 1;

        return `${year}-${month}`;
    }

    static dateTimeHelper(unixtime) {
        let date = new Date(unixtime * 1000);

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day   = date.getDate();
        let hour  = ( date.getHours()   < 10 ) ? '0' + date.getHours()   : date.getHours();
        let min   = ( date.getMinutes() < 10 ) ? '0' + date.getMinutes() : date.getMinutes();
        let sec   = ( date.getSeconds() < 10 ) ? '0' + date.getSeconds() : date.getSeconds();

        return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
    }

    static truncate(str, len){
      return str.length <= len ? str: (str.substr(0, len)+"...");
    }

    static mediaIconHelper(mediaName) {
      switch (mediaName) {
        case 'GDN':
          return googleIcon;
          break;
        case 'Akane':
          return akaneIcon;
          break;
        // case 'TopBuzzVideo':
        //   return buzzbideoIcon
          break;
        case 'Facebook':
          return facebookIcon;
          break;
        // case 'Gunosy Ads':
        //   return gnousyIcon
          break;
        case i18next.t('アイモバイル'):
          return imobileIcon;
          break;
        case 'LAP(LINE Ad Platform)':
          return lineIcon;
          break;
        case 'logly':
          return logifyIcon;
          break;
        case 'AdAsia':
          return nosnsiconIcon;
          break;
        case 'POPIN':
          return popinIcon;
          break;
        case 'ScaleOut':
          return scaleoutIcon;
          break;
        case 'Twitter':
          return twitterIcon;
          break;
        case 'TikTok':
          return tiktokIcon;
          break;
        case 'SmartNews':
          return smartnewsIcon;
          break;
        case 'YDN':
          return yahooIcon;
          break;
        case 'Zucks':
          return zucksIcon;
          break;
        default:

      }
    }

    static evaluationStarHelper(evaluation) {
      switch (evaluation) {
        case 1:
          return '★☆☆☆☆';
        case 2:
          return '★★☆☆☆';
        case 3:
          return '★★★☆☆';
        case 4:
          return '★★★★☆';
        case 5:
          return '★★★★★';
          break;
        default:
          return '☆☆☆☆☆';

      }
    }

    static searchFieldChoicePlacHolderHelper(fieldName) {
      switch (fieldName) {
        case 'カテゴリ':
          return 'ダイエット,スキンケア,ニオイケア';
        case 'ジャンル':
          return 'サプリメント,機能性表示食品,ドリンク';
        default:
          return '';

      }
    }

  static teamDomainStatusHelper(status) {
    let name;
    let color;
    switch (status) {
      case 'pending':
        name = '審査待ち';
        color = '#000';
        break;
      case 'approved':
        name = '承認';
        color = '#7ed321';
        break;
      case 'declined':
        name = '審査落ち';
        color = '#d04602';
        break;
      default:
        name = '';
        color = '#000';
    }
    return { name, color };
  }
}



export default Utility;
