import React from "react";

type Props = {
  width: string;
  height: string;
};

export const SmartnewsIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <defs>
        <rect
          id="smartnews-icon-path-1"
          x="0"
          y="0"
          width="24"
          height="24"
          rx="4"
        ></rect>
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-602.000000, -701.000000)">
          <g transform="translate(602.000000, 701.000000)">
            <mask
              id="smartnews-icon-mask-2"
              fill="white"
            >
              <use href="#smartnews-icon-path-1"></use>
            </mask>
            <rect
              stroke="#C2D1DB"
              x="0.5"
              y="0.5"
              width="23"
              height="23"
              rx="4"
            ></rect>
            <rect
              fill="#FF645B"
              mask="url(#smartnews-icon-mask-2)"
              x="0"
              y="0"
              width="6"
              height="4"
            ></rect>
            <rect
              fill="#FFC73D"
              mask="url(#smartnews-icon-mask-2)"
              x="6"
              y="0"
              width="6"
              height="4"
            ></rect>
            <rect
              fill="#54E470"
              mask="url(#smartnews-icon-mask-2)"
              x="12"
              y="0"
              width="6"
              height="4"
            ></rect>
            <rect
              fill="#0FBBE7"
              mask="url(#smartnews-icon-mask-2)"
              x="18"
              y="0"
              width="6"
              height="4"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="13"
              y="7"
              width="8"
              height="7"
              rx="1"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="3"
              y="7"
              width="8"
              height="1"
              rx="0.5"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="3"
              y="9"
              width="8"
              height="1"
              rx="0.5"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="3"
              y="11"
              width="8"
              height="1"
              rx="0.5"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="3"
              y="13"
              width="8"
              height="1"
              rx="0.5"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="3"
              y="16"
              width="8"
              height="5"
              rx="1"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="13"
              y="16"
              width="8"
              height="1"
              rx="0.5"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="13"
              y="18"
              width="8"
              height="1"
              rx="0.5"
            ></rect>
            <rect
              fill="#C2D1DB"
              mask="url(#smartnews-icon-mask-2)"
              x="13"
              y="20"
              width="8"
              height="1"
              rx="0.5"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
