import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { FolderFormContactTagProps } from "domains";
import FolderFormContactTagApi from "javascripts/api/folders/forms/contacts/tag";
import { removeItemAtIndex } from "utils";

export type FolderFormContactTagList = {
  items: Array<FolderFormContactTagProps>;
  nextPage: number | null;
};

const folderFormTagListState = atom<FolderFormContactTagList>({
  key: "folderFormTagListState",
  default: {
    items: [],
    nextPage: 1,
  },
});

export type HandleFetchFolderFormContactTagListProps = (
  folderUid: string,
  params: {
    page: number | null;
    query: string;
  },
) => void;
export const useFetchFolderFormContactTagList = () => {
  const [folderFormContactTagList, setFolderFormContactTagList] =
    useRecoilState(folderFormTagListState);

  const handle: HandleFetchFolderFormContactTagListProps = useCallback(
    async (folderUid, params) => {
      try {
        const items = params.page === 1 ? [] : folderFormContactTagList.items;
        const response = await FolderFormContactTagApi.fetch(folderUid, params);
        setFolderFormContactTagList({
          items: [...items, ...response.tags],
          nextPage: response.next_page,
        });
        return response;
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormContactTagList],
  );
  return [handle] as const;
};

export type HandleCreateFolderFormContactTagProps = (folderUid: string, params: {}) => void;
export const useCreateFolderFormContactTag = () => {
  const [folderFormContactTagList, setFolderFormContactTagList] =
    useRecoilState(folderFormTagListState);
  const [error, setError] = useState<any>();

  const handle: HandleCreateFolderFormContactTagProps = useCallback(
    async (folderUid, params) => {
      setError(undefined);
      try {
        const response = await FolderFormContactTagApi.create(folderUid, params);
        setFolderFormContactTagList({
          ...folderFormContactTagList,
          items: [response, ...folderFormContactTagList.items],
        });
      } catch (e) {
        setError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormContactTagList],
  );

  return [handle, error] as const;
};

export type HandleDeleteFolderFormContactTagProps = (folderUid: string, tagId: number) => void;
export const useDeleteFolderFormContactTag = () => {
  const [folderFormContactTagList, setFolderFormContactTagList] =
    useRecoilState(folderFormTagListState);

  const handle: HandleDeleteFolderFormContactTagProps = useCallback(
    async (folderUid, tagId) => {
      try {
        await FolderFormContactTagApi.destroy(folderUid, tagId);
        const index = folderFormContactTagList.items.findIndex((tag) => tag.id === tagId);
        setFolderFormContactTagList({
          ...folderFormContactTagList,
          items: removeItemAtIndex(folderFormContactTagList.items, index),
        });
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormContactTagList],
  );

  return [handle] as const;
};

export const useFolderFormContactTagList = (folderUid: string) => {
  const [folderFormContactTagList, setFolderFormContactTagList] =
    useRecoilState(folderFormTagListState);
  const [handle] = useFetchFolderFormContactTagList();

  useEffect(() => {
    const params = {
      page: 1,
      query: "",
    };
    handle(folderUid, params);
  }, []);

  return [folderFormContactTagList, setFolderFormContactTagList] as const;
};
