import { useEffect } from "react";
import { atom, useRecoilValue } from "recoil";
import { JSErrors } from "@newrelic/browser-agent/features/jserrors";
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";
import { CustomError } from "api";
import { NEWRELIC } from "../../const/newrelic";
import { useMember } from "../team-member";

export const newRelicAgentState = atom({
  key: "newRelicAgentState",
  // ref: https://github.com/newrelic/newrelic-browser-agent?tab=readme-ov-file#instantiating-the-agent
  default: new BrowserAgent({
    info: {
      applicationID: NEWRELIC.APPLICATION_ID,
      licenseKey: NEWRELIC.LICENSE_KEY,
    },
    features: [JSErrors],
  }),
  dangerouslyAllowMutability: true,
});

export const useRemoteLogging = () => {
  const {
    memberAttributes: { user, team },
  } = useMember();
  const browserAgent = useRecoilValue(newRelicAgentState);

  useEffect(() => {
    browserAgent.setCustomAttribute("userId", user?.id || 0);
    browserAgent.setCustomAttribute("teamId", team?.id || 0);
  }, [user, team]);
};

export type SendLogProps = {
  error?: unknown;
  message?: string;
  customProps?: {
    [key: string]: unknown;
  };
};

type BeyondCustomError = {
  device_limitation?: boolean;
  require_user?: boolean;
  require_team?: boolean;
  code?: number;
};

export const useLogging = () => {
  const browserAgent = useRecoilValue(newRelicAgentState);

  const sendErrorLog = ({ error, message, customProps }: SendLogProps) => {
    let normalizedError = null;
    let errorCustomProps = {};
    if (error) {
      if (error instanceof Error) {
        normalizedError = error;
      }
      if (error instanceof CustomError) {
        if (error.data) {
          errorCustomProps = {
            ...error.data,
            //NOTE: sendErrorLogのargumentのmessageと名前が被ってnewrelic側に表示されないので名前を変更する
            backendErrorMessage: error.data?.message,
          };
        }
      }
      normalizedError = error ? new Error(message) : new Error("Unknown error");
    }
    const isDeviceLimitation = !!(error as BeyondCustomError)?.device_limitation;
    const isRequireUser = !!(error as BeyondCustomError)?.require_user;
    const isRequireTeam = !!(error as BeyondCustomError)?.require_team;

    const excludedErrorCodes = [401, 403];
    const errorCode = (error as BeyondCustomError)?.code;
    const isAuthError =
      typeof errorCode === "number" ? excludedErrorCodes.includes(errorCode) : false;

    const isSendLog = !isDeviceLimitation && !isRequireUser && !isRequireTeam && !isAuthError;
    if (isSendLog) {
      const extendCustomProps: SendLogProps["customProps"] = {
        ...customProps,
        ...errorCustomProps,
        message,
      };
      if (!normalizedError) return;
      // ref: https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/
      browserAgent.noticeError(normalizedError, extendCustomProps);
    }
  };

  return { sendErrorLog };
};

export type SendErrorLog = ReturnType<typeof useLogging>["sendErrorLog"];
