import { memo } from "react";

import brushIcon from "assets/images/brush_current_color.svg";
import gridIcon from "assets/images/grid_current_color.svg";
import sidePanelStyles from "stylesheets/components/forms/sidePanel.module.scss";
import { TabItemProps } from "./type";

const TabItem = memo(({ mode, activeMode, setActiveMode, setActiveBlockId }: TabItemProps) => {
  const tabClassName = mode === activeMode ? sidePanelStyles.tabActive : sidePanelStyles.tab;
  const icon = mode === "build" ? gridIcon : brushIcon;

  const onClick = () => {
    setActiveMode(mode);
    setActiveBlockId("");
  };

  return (
    <li
      key={mode}
      onClick={() => onClick()}
      className={tabClassName}
    >
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
      >
        <use xlinkHref={`${icon}#content`} />
      </svg>
    </li>
  );
});

TabItem.displayName = "TabItem";

export default TabItem;
