import { useCallback, useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { ArticleProps } from "domains";
import { useLogging } from "hooks";
import ArticleApi from "javascripts/api/article";

export const articleState = atom<ArticleProps>({
  key: "articleState",
  default: {} as ArticleProps,
});
export const articleListState = atom<ArticleProps[]>({
  key: "articleListState",
  default: [],
});

export type HandleFetchArticleProps = (
  abTestPath: string,
  articleUid: string,
  teamUid?: string,
) => void;
export const useFetchArticle = () => {
  const { sendErrorLog } = useLogging();
  const [_article, setArticle] = useRecoilState(articleState);
  const handle: HandleFetchArticleProps = useCallback(async (abTestPath, articleUid) => {
    try {
      const response = await ArticleApi.fetchArticle(abTestPath, articleUid);
      setArticle(response);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to fetch article",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};

export type HandleUpdateArticleProps = (articleUid: string, params: {}) => void;
export const useUpdateArticle = () => {
  const { sendErrorLog } = useLogging();
  const [_article, setArticle] = useRecoilState(articleState);
  const handle: HandleUpdateArticleProps = useCallback(async (articleUid, params) => {
    try {
      const response = await ArticleApi.update(articleUid, params);
      setArticle(response);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to update article",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle] as const;
};

export const useArticle = (abTestPath: string, articleUid: string, teamUid?: string) => {
  const [article, setArticle] = useRecoilState(articleState);
  const [fetchArticle] = useFetchArticle();

  useEffect(() => {
    fetchArticle(abTestPath, articleUid, teamUid);
  }, []);

  return [article, setArticle] as const;
};

export type HandleFetchArticlesProps = (path: string) => void;
export const useFetchArticles = (_path: string) => {
  const { sendErrorLog } = useLogging();
  const [_articles, setArticles] = useRecoilState(articleListState);
  const handle: HandleFetchArticlesProps = useCallback(async (path) => {
    try {
      const response = await ArticleApi.fetchArticles(path);
      setArticles(response);
    } catch (error) {
      sendErrorLog({
        error,
        message: "Failed to fetch articles",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [handle];
};

export const useArticles = (path: string) => {
  const [articles, setArticles] = useRecoilState(articleListState);
  const [handle] = useFetchArticles(path);

  useEffect(() => {
    handle(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [articles, setArticles] as const;
};
