import React from "react";
import { CustomSvgIconProps, useCustomIconColor } from "hooks";

type Props = CustomSvgIconProps & {
  width: string;
  height: string;
};

export const DuplicateIcon: React.FC<Props> = ({ color, width, height }) => {
  const iconColor = useCustomIconColor(color);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 23"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-112.000000, -179.000000)">
          <g transform="translate(91.000000, 151.000000)">
            <g transform="translate(21.000000, 28.000000)">
              <path
                d="M5,5 L5,6 L2,6 C1.44771525,6 1,6.44771525 1,7 L1,21 C1,21.5522847 1.44771525,22 2,22 L12,22 C12.5522847,22 13,21.5522847 13,21 L13,18 L14,18 L14,21 C14,22.1045695 13.1045695,23 12,23 L2,23 C0.8954305,23 1.3527075e-16,22.1045695 0,21 L0,7 C-1.3527075e-16,5.8954305 0.8954305,5 2,5 L5,5 Z"
                fill={iconColor}
              ></path>
              <path
                d="M18.5,5.20710678 L13.7928932,0.5 L7,0.5 C6.17157288,0.5 5.5,1.17157288 5.5,2 L5.5,16 C5.5,16.8284271 6.17157288,17.5 7,17.5 L17,17.5 C17.8284271,17.5 18.5,16.8284271 18.5,16 L18.5,5.20710678 Z"
                stroke={iconColor}
              ></path>
              <path
                d="M14,1.00042725 L14,4 C14.0003205,4.55215962 14.4478405,4.99982302 14.9999999,5.00032043 L17.9991456,5.00128146 L19,6.00256348 L14.9999998,6.00085449 C13.8957645,5.99952804 13.0008547,5.10423581 13,4 L13,0 L14,1.00042725 Z"
                fill={iconColor}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
