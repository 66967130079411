import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class ExitPopupInspectionRequestApi extends ApiBase {
  static fetchRequests(exitPopupId) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/exit_popups/${exitPopupId}/inspections/requests`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static create(exitPopupId, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.post(`${baseUrl}/exit_popups/${exitPopupId}/inspections/requests`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static update(exitPopupId, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.patch(`${baseUrl}/exit_popups/${exitPopupId}/inspections/requests`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroy(exitPopupId) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/exit_popups/${exitPopupId}/inspections/requests`;
      axios.delete(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default ExitPopupInspectionRequestApi;
