import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { FolderFormApi, WeeklyReceiveContactCount } from "api/folders/form";
import { useLogging } from "hooks";
import { FormObject, convertFormsObjectFormat } from "utils/convert-forms-object-format";

export type FolderFormListProps = {
  items: FormObject[];
  weekly_receive_contact_count: WeeklyReceiveContactCount;
  nextPage: number | null;
};

type FolderFormListRequest = {
  page: number;
  with_recieve_count?: boolean;
  start_date?: object;
  end_date?: object;
  query?: string;
  folder_uid?: string;
};

const folderFormListState = atom<FolderFormListProps>({
  key: "folderFormListState",
  default: {
    items: [],
    weekly_receive_contact_count: {},
    nextPage: 1,
  },
});

export type HandleFetchFolderFormListProps = (params: FolderFormListRequest) => void;
export const useFetchFolderFormList = () => {
  const [folderFormList, setFolderFormList] = useRecoilState(folderFormListState);
  const [loading, setLoading] = useState(false);

  const { sendErrorLog } = useLogging();

  const handle: HandleFetchFolderFormListProps = useCallback(
    async (params) => {
      setLoading(true);
      try {
        const items = params.page === 1 ? [] : folderFormList.items;
        const response = await FolderFormApi.fetch(params);
        setFolderFormList({
          items: [...items, ...convertFormsObjectFormat(response.forms)],
          weekly_receive_contact_count: response.weekly_receive_contact_count ?? {},
          nextPage: response.next_page,
        });
      } catch (e) {
        sendErrorLog({
          error: e,
          message: "Failed to fetch folder form data",
        });
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [folderFormList.items],
  );

  return [handle, loading] as const;
};

export const useFolderFormList = (with_recieve_count: boolean) => {
  const [folderFormList, setFolderFormList] = useRecoilState(folderFormListState);
  const [handle] = useFetchFolderFormList();

  useEffect(() => {
    handle({
      page: 1,
      with_recieve_count: with_recieve_count,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [folderFormList, setFolderFormList] as const;
};
