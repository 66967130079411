import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class ShopifyPrivateApplicationApi extends ApiBase {
  static fetchAccounts() {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/shopify_private_applications`;
      axios.get(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static createAccount(params) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/shopify_private_applications`;
      axios.post(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroyAccount(appId) {
    let baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/shopify_private_applications/${appId}`;
      axios.delete(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default ShopifyPrivateApplicationApi;
