import { useTranslation } from "react-i18next";
import imageSearchRangeTabStyles from "stylesheets/components/partials/imageSearchRangeTab.module.scss";

import { ImageDropdownSearchRangeTabProps } from "./type";

export const ImageDropdownSearchRangeTab = ({
  theme = "lightTheme",
  fetchArticlePhotos,
  articlePhoto,
  fetchArticlePhotosOnEditor,
  displayTabList = ["all", "folder", "ab_test", "article"],
}: ImageDropdownSearchRangeTabProps) => {
  const { t } = useTranslation();

  return (
    <div className={imageSearchRangeTabStyles.searchRangeTabWrapper}>
      <div
        className={`${imageSearchRangeTabStyles.searchRangeTabs} ${imageSearchRangeTabStyles[theme]}`}
      >
        <div
          onClick={() => fetchArticlePhotos("all")}
          className={`${imageSearchRangeTabStyles.tab} ${
            displayTabList.includes("all") ? "" : imageSearchRangeTabStyles.hide
          } ${articlePhoto.searchRange === "all" ? imageSearchRangeTabStyles.active : ""}`}
        >
          {t("全て")}
        </div>
        <div
          onClick={() => fetchArticlePhotos("folder")}
          className={`${imageSearchRangeTabStyles.tab} ${
            displayTabList.includes("folder") ? "" : imageSearchRangeTabStyles.hide
          } ${articlePhoto.searchRange === "folder" ? imageSearchRangeTabStyles.active : ""}`}
        >
          {t("フォルダ内")}
        </div>
        <div
          onClick={() => fetchArticlePhotos("ab_test")}
          className={`${imageSearchRangeTabStyles.tab} ${
            displayTabList.includes("ab_test") ? "" : imageSearchRangeTabStyles.hide
          } ${articlePhoto.searchRange === "ab_test" ? imageSearchRangeTabStyles.active : ""}`}
        >
          {t("beyondページ内")}
        </div>
        <div
          onClick={() => fetchArticlePhotosOnEditor()}
          className={`${imageSearchRangeTabStyles.tab} ${
            displayTabList.includes("article") ? "" : imageSearchRangeTabStyles.hide
          } ${articlePhoto.searchRange === "article" ? imageSearchRangeTabStyles.active : ""}`}
        >
          {t("Version内")}
        </div>
      </div>
    </div>
  );
};
