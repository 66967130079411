import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { TeamMemberFolderInspectionSettingProps } from "domains";
import TeamMemberFolderInspectionSettingApi from "javascripts/api/teams/members/folders/inspectionSetting";

export const teamMemberFolderInspectionSettingState = atom<TeamMemberFolderInspectionSettingProps>({
  key: "teamMemberFolderInspectionSettingState",
  default: {} as TeamMemberFolderInspectionSettingProps,
});

export const useFetchTeamMemberFolderInspectionSetting = (folderId: number | undefined) => {
  const [setting, setSetting] = useRecoilState(teamMemberFolderInspectionSettingState);

  useEffect(() => {
    if (folderId === undefined) {
      return;
    }
    TeamMemberFolderInspectionSettingApi.fetch(folderId).then((data) => {
      setSetting(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  return [setting] as const;
};
