export const convertPlainCss = (css: string) => {
  if (!css) return;
  css = css.replace(/#sb-part-[0-9]+/g, "");
  css = css.replace(/#sb-part-([^\s]+)/g, "");
  css = css.replace(/.sb-custom-part-([^\s]+)/g, "");
  css = css.replace(/@charset "([^\s]+)";/g, "");
  return css;
};

export const buildPictureElement = (
  value: { url: string; webp_url?: string; avif_url?: string },
  targetDocument?: Document
) => {
  const doc = targetDocument ? targetDocument : document;
  const picture = doc.createElement("picture");
  if (value.webp_url) {
    const webpSource = doc.createElement("source");
    webpSource.setAttribute("type", "image/webp");
    webpSource.setAttribute("srcset", value.webp_url);
    picture.appendChild(webpSource);
  }
  if (value.avif_url) {
    const avifSource = doc.createElement("source");
    avifSource.setAttribute("type", "image/avif");
    avifSource.setAttribute("srcset", value.avif_url);
    picture.appendChild(avifSource);
  }
  const image = doc.createElement("img");
  image.src = value.url;
  picture.appendChild(image);
  return picture;
};

//If there is a contenteditable attribute in the HTML of the widget, the output does not work well in the generation process of Quill, so it needs to be removed
//Quill's generation process source code: squadbeyond/packages/frontend/src/javascripts/utils/quillSettingText.js(class CustomParts)
export const sanitizeWidgetHTML = (html: string) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  const spans = div.querySelectorAll('span[contenteditable="false"]');

  spans.forEach((span) => {
    span.removeAttribute("contenteditable");
  });

  const result = div.innerHTML;
  return result;
};
