import { useTranslation } from "react-i18next";
import { AppModal } from "components";

import appModalStyles from "stylesheets/components/partials/appModal.module.scss";
import modalDeviceLimitationStyles from "stylesheets/components/partials/modalDeviceLimitation.module.scss";

const ModalDeviceLimitation = ({ isModalOpen }: { isModalOpen: boolean }) => {
  const { t } = useTranslation();
  return (
    <AppModal
      isModalOpen={isModalOpen}
      setIsOpen={() => {}}
    >
      <div />
      <div className={appModalStyles.modalWrapper}>
        <div className={appModalStyles.modalContent}>
          <div className={modalDeviceLimitationStyles.container}>
            <div>{t("ログインセッションの有効期限が過ぎました。")}</div>
            <div>{t("トップページからログインしてください。")}</div>
            <div>
              <a href="/">{t("ログインページへ")}</a>
            </div>
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default ModalDeviceLimitation;
