import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { CustomError, UserPasswordApi, UserRegistrationApi, UserConfirmationApi } from "api";
import type {
  UserPasswordResetRequestParams,
  UserUpdatePasswordRequestParams,
  UserSignUpRequestParams,
  UserConfirmationRequestParams,
} from "api";
import { TeamMemberApi } from "api/teams/member";
import { TeamMemberApiResponseProps } from "domains";
import { useLogging, useAuthCookieManagement } from "hooks";
import TeamMemberSessionApi from "javascripts/api/teams/members/sessions";
import UserConfirmationCodeApi from "javascripts/api/users/confirmation/code";
import UserSessionApi from "javascripts/api/users/session";
import UserTeamSessionApi from "javascripts/api/users/teams/session";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";

import { mixpanelSetUserInfo } from "utils/tracking/mixpanel-analytics";

export enum AuthState {
  Unknown,
  Unauth,
  Auth,
  AuthToTeam,
}

// TODO: Add local storage
const authState = atom<AuthState>({
  key: "authState",
  default: AuthState.Unknown,
});

const redirectToState = atom<string | undefined>({
  key: "redirectToState",
  default: undefined,
});

export const useAuth = () => {
  const [state, setAuthState] = useRecoilState<AuthState>(authState);
  const redirectTo = useRecoilValue<string | undefined>(redirectToState);
  useEffect(() => {
    if (Cookies.get("js-current-team-uid")) {
      setAuthState(AuthState.AuthToTeam);
    } else if (Cookies.get("session_uid")) {
      setAuthState(AuthState.Auth);
    } else {
      setAuthState(AuthState.Unauth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, redirectTo] as const;
};

export const useAuthActions = () => {
  const [, setAuthState] = useRecoilState<AuthState>(authState);

  // TODO: 使用箇所見直して必要ないのであれば引数を削除してしまいたい
  const signInHandler = useCallback((_redirectTo: string) => {
    setAuthState(AuthState.Auth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: 使用箇所見直して必要ないのであれば引数を削除してしまいたい
  const signInToTeamHandler = useCallback((_redirectTo: string) => {
    setAuthState(AuthState.AuthToTeam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOutHandler = useCallback(() => {
    setAuthState(AuthState.Unauth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { signInHandler, signInToTeamHandler, signOutHandler } as const;
};

export const useAuthSignIn = () => {
  const [, setAuthState] = useRecoilState<AuthState>(authState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const { sendErrorLog } = useLogging();

  const handle = useCallback(async (email: string, password: string) => {
    setError(undefined);
    setLoading(true);
    try {
      await UserSessionApi.create(email, password);
      setAuthState(AuthState.Auth);
      const memberAttributes: TeamMemberApiResponseProps = await TeamMemberApi.fetchMember();
      // Identify user and register info, if they are signed in
      if (memberAttributes.user?.id) {
        mixpanel.identify(memberAttributes.user.id.toString());
        mixpanelSetUserInfo(memberAttributes);
      }

      setLoading(false);
      return true;
    } catch (e: unknown) {
      setError(e);
      setLoading(false);
      sendErrorLog({
        error: e,
        message: "Failed to sign in",
      });
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [handle, loading, error] as const;
};

export const useAuthSignInTeam = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const handle = async (teamUid: string) => {
    setLoading(true);
    try {
      await UserTeamSessionApi.signIn(teamUid);
      const memberAttributes: TeamMemberApiResponseProps = await TeamMemberApi.fetchMember();
      // Identify user and register info, if they are signed in team
      if (memberAttributes.user?.id && memberAttributes.team?.id) {
        mixpanel.identify(memberAttributes.user.id.toString());
        mixpanelSetUserInfo(memberAttributes);
      }

      window.location.href = "/";
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return [handle, loading] as const;
};

export const useResendConfirmationUserCode = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = async (params: {}) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      await UserConfirmationCodeApi.create(params);
      setLoading(false);
      return true;
    } catch (e) {
      setLoading(false);
      setError(e);
      return false;
    }
  };

  return [handle, loading, error] as const;
};

export const useAuthSignOut = () => {
  const [error, setError] = useState<any>();
  const { removeSessionFromCookie } = useAuthCookieManagement();

  const handle = async () => {
    try {
      await TeamMemberSessionApi.signOut();
      // Note: 新アプリとの認証情報共有のため、認証情報をcookieから削除する
      removeSessionFromCookie();
      window.location.replace("/");
    } catch (e) {
      setError(e);
    }
  };

  return [handle, error] as const;
};

export type UseAuthSignUpType = (params: UserSignUpRequestParams) => Promise<boolean | undefined>;
export const useAuthSignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();
  const { sendErrorLog } = useLogging();

  const handle: UseAuthSignUpType = async (params: UserSignUpRequestParams) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      await UserRegistrationApi.create(params);
      setLoading(false);
      return true;
    } catch (e: unknown) {
      setLoading(false);
      if (e instanceof CustomError) {
        setError(e);
      }
      sendErrorLog({
        error: e,
        message: "Failed to registration user",
      });
      return false;
    }
  };

  return [handle, loading, error] as const;
};

export const useResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();
  const { sendErrorLog } = useLogging();

  const handle = async (params: UserPasswordResetRequestParams) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      await UserPasswordApi.create(params);
      setLoading(false);
      return true;
    } catch (e: unknown) {
      if (e instanceof CustomError) {
        setError(e);
      }
      sendErrorLog({
        error: e,
        message: "Failed to reset password request",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return [handle, loading, error] as const;
};

export const useUpdatePassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<CustomError>();
  const { sendErrorLog } = useLogging();

  const handle = async (params: UserUpdatePasswordRequestParams) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      await UserPasswordApi.update(params);
      setLoading(false);
      return true;
    } catch (e: unknown) {
      if (e instanceof CustomError) {
        setError(e);
      }
      sendErrorLog({
        error: e,
        message: "Failed to update password",
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return [handle, loading, error] as const;
};

export const useConfirmUserCode = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = async (params: UserConfirmationRequestParams) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(undefined);

    try {
      await UserConfirmationApi.create(params);
      setLoading(false);
      return true;
    } catch (e) {
      setLoading(false);
      setError(e);
      return false;
    }
  };

  return [handle, loading, error] as const;
};
