import { useRef, useImperativeHandle, forwardRef } from "react";

import saveAnimationStyles from "stylesheets/components/partials/saveAnimation.module.scss";

export type SaveAnimationHandler = {
  handle: () => void;
};

const SaveAnimation = forwardRef<SaveAnimationHandler, {}>((_, ref) => {
  const saveAnimationRef = useRef<HTMLDivElement>(null);

  function handleAnimationEnd() {
    saveAnimationRef.current?.classList.remove(saveAnimationStyles.saved);
  }

  useImperativeHandle(ref, () => ({
    handle: () => {
      const saveAnimation = saveAnimationRef.current;
      if (saveAnimation?.classList.contains(saveAnimationStyles.saved)) {
        return;
      }
      saveAnimation?.classList.add(saveAnimationStyles.saved);
    },
  }));
  return (
    <div
      className={`${saveAnimationStyles.saveAnimation}`}
      onAnimationEnd={handleAnimationEnd}
      ref={saveAnimationRef}
    />
  );
});

export default SaveAnimation;
