import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";
import { ExitPopupInspectionRequestProps } from "domains";
import ExitPopupInspectionRequestApi from "javascripts/api/exitPopups/inspections/request";

const exitPopupInspectionRequestState = atom<ExitPopupInspectionRequestProps>({
  key: "exitPopupInspectionRequestState",
  default: {} as ExitPopupInspectionRequestProps,
});

export type HandleCreateExitPopupInspectionRequestProps = (
  exitPopupId: number,
  description: string
) => Promise<ExitPopupInspectionRequestProps>;
export const useCreateExitPopupInspectionRequest = () => {
  const [_exitPopupInspectionRequest, setExitPopupInspectionRequest] = useRecoilState(
    exitPopupInspectionRequestState
  );
  const [error, setError] = useState<any>();
  const handle: HandleCreateExitPopupInspectionRequestProps = useCallback(
    async (exitPopupId, description) => {
      setError(undefined);
      const params = {
        request: {
          description: description,
        },
      };
      try {
        const response = await ExitPopupInspectionRequestApi.create(exitPopupId, params);
        setExitPopupInspectionRequest(response);
        return response;
      } catch (e) {
        setError(e);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [handle, error] as const;
};

export type HandleDeleteExitPopupInspectionRequestProps = (
  exitPopupId: number
) => Promise<boolean | undefined>;
export const useDeleteExitPopupInspectionRequest = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<any>();
  const handle: HandleDeleteExitPopupInspectionRequestProps = async (exitPopupId) => {
    if (
      !window.confirm(t("本当に削除しますか？削除を実行すると後から元に戻すことはできません") || "")
    ) {
      return;
    }
    try {
      await ExitPopupInspectionRequestApi.destroy(exitPopupId);
      return true;
    } catch (e) {
      setError(e);
      return false;
    }
  };

  return [handle, error] as const;
};
