import i18next from "i18next";

export const contactSupportStatusNames = {
  not_supported: i18next.t("未対応"),
  in_progress: i18next.t("対応中"),
  done: i18next.t("対応完了"),
  finished: i18next.t("終了"),
};

export const contactTrackingStatusNames = {
  not_replied: i18next.t("メール未送信"),
  processed: i18next.t("メール送信中"),
  delivered: i18next.t("メール送信済み"),
  open: i18next.t("メール開封済み"),
  click: i18next.t("クリック済み"),
  dropped: i18next.t("送信失敗"),
};
