import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { TeamMemberApi } from "api/teams/member";
import { TeamMemberApiResponseProps, TeamMemberListApiResponse, TeamMemberProps } from "domains";
import { AuthState, useAuth, useLogging } from "hooks";

export const teamInvitableMemberCountState = atom({
  key: "teamInvitableMemberCountState",
  default: 0,
});

export const teamMemberListState = atom<TeamMemberProps[]>({
  key: "teamMemberListState",
  default: [],
});

const teamMemberState = atom<TeamMemberApiResponseProps>({
  key: "teamMemberState",
  default: {} as TeamMemberApiResponseProps,
});

export const useFetchTeamMemberList = () => {
  const { sendErrorLog } = useLogging();
  const [list, setList] = useRecoilState(teamMemberListState);
  const [invitableMemberCount, setInvitableMemberCount] = useRecoilState(
    teamInvitableMemberCountState,
  );

  useEffect(() => {
    TeamMemberApi.fetchMembers()
      .then((data: TeamMemberListApiResponse) => {
        setList(data.members);
        setInvitableMemberCount(data.invitable_member_count);
      })
      .catch((error) => {
        sendErrorLog({
          error,
          message: "Failed to fetch team member list",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [list, setList, invitableMemberCount, setInvitableMemberCount] as const;
};

export const useTeamMember = () => {
  const [teamMember] = useRecoilState(teamMemberState);
  return teamMember;
};

export const useFetchTeamMember = () => {
  const { sendErrorLog } = useLogging();
  const [authState] = useAuth();
  const [member, setMember] = useState({} as TeamMemberApiResponseProps);
  const [_, setTeamMemberResponse] = useRecoilState(teamMemberState);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (![AuthState.Auth, AuthState.AuthToTeam].includes(authState)) {
      return;
    }

    if (loading) {
      return;
    }
    setLoading(true);

    TeamMemberApi.fetchMember()
      .then((data: TeamMemberApiResponseProps) => {
        setTeamMemberResponse(data);
        setMember(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        sendErrorLog({
          error,
          message: "Failed to fetch team member",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return [member] as const;
};

export const useCreateTeamMember = () => {
  const [list, setList] = useRecoilState(teamMemberListState);
  const [_invitableMemberCount, setInvitableMemberCount] = useRecoilState(
    teamInvitableMemberCountState,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (params: {}) => {
      if (loading) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        const response = await TeamMemberApi.createMember(params);
        setList([...list, response.member]);
        setInvitableMemberCount(response.invitable_member_count);
        setLoading(false);
        return true;
      } catch (e) {
        setLoading(false);
        setError(e);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );

  return [handle, error, loading] as const;
};

export const useUpdateTeamMember = () => {
  const [list, setList] = useRecoilState(teamMemberListState);
  const [_invitableMemberCount, setInvitableMemberCount] = useRecoilState(
    teamInvitableMemberCountState,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const handle = useCallback(
    async (memberUid: string, params: {}) => {
      if (loading) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        const response = await TeamMemberApi.updateMember(memberUid, params);
        const newList = list.map((d: TeamMemberProps) => {
          if (d.uid === memberUid) {
            return response.member;
          } else {
            return d;
          }
        });
        setList(newList);
        setInvitableMemberCount(response.invitable_member_count);
        setLoading(false);
        return true;
      } catch (e) {
        setLoading(false);
        setError(e);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );

  return [handle, error, loading] as const;
};

export const useDeleteTeamMember = () => {
  const [list, setList] = useRecoilState(teamMemberListState);
  const [_invitableMemberCount, setInvitableMemberCount] = useRecoilState(
    teamInvitableMemberCountState,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [_error, setError] = useState<any>();

  const handle = useCallback(
    async (memberUid: string) => {
      if (!window.confirm("本当に削除しますか？")) return;
      if (loading) {
        return;
      }

      setLoading(true);
      setError(undefined);

      try {
        const response = await TeamMemberApi.destroyMember(memberUid);
        const newList = list.filter((d: TeamMemberProps) => d.uid !== memberUid);
        setList(newList);
        setInvitableMemberCount(response.invitable_member_count);

        setLoading(false);
        return response;
      } catch (e) {
        setLoading(false);
        setError(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [list],
  );

  return [handle] as const;
};
