import { useState } from "react";
import { ListItem } from "components/atoms/core/ListItem";
import { ListItemDropDownProps } from "./type";

export const ListItemDropDown = ({
  children,
  title,
  id,
  hasChevron,
  theme,
  isDisabled,
  isSelected,
  icon,
  selectedIcon,
  size,
  description,
}: ListItemDropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <ListItem
        {...{
          title,
          id,
          hasChevron,
          theme,
          isDisabled,
          isSelected,
          icon,
          selectedIcon,
          ...(size === "large" ? { size, description } : { size }),
        }}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && <div className="animate-scale-in-ver-top">{children}</div>}
    </div>
  );
};
