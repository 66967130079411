import { useTranslation } from "react-i18next";
import trashIcon from "assets/images/trash_red.svg";
import { MediaTag } from "components/atoms/media-tag";

import imageDropdownStyles from "stylesheets/components/quillEditor/imageDropdown.module.scss";

import { ImageDropdownPhotoListProps } from "./type";

export const ImageDropdownPhotoList = ({
  article,
  articlePhoto,
  displayType,
  photoWrapperRef,
  fetchArticlePhotos,
  photoOnClick,
  updateArticlePhotoPublishStatus,
}: ImageDropdownPhotoListProps) => {
  const { t } = useTranslation();

  const showArticlePhotos = () => {
    const photoComponents = articlePhoto.photos.map((photo, index) => {
      const copyPhotoUrl = (e: React.MouseEvent<HTMLDivElement>) => {
        (e.target as HTMLDivElement).querySelector("input")?.select();
        document.execCommand("copy");
      };
      switch (displayType) {
        case "displayImage":
          return (
            <div
              key={index}
              className={imageDropdownStyles.articlePhoto}
            >
              <div
                className={`${imageDropdownStyles.overlay}`}
                onMouseOver={(e) => e.currentTarget.classList.add(imageDropdownStyles.active)}
                onMouseLeave={(e) => e.currentTarget.classList.remove(imageDropdownStyles.active)}
              >
                {!photo.isOnEditor && (
                  <div
                    className={imageDropdownStyles.btnTrash}
                    onClick={() => updateArticlePhotoPublishStatus(article.uid, photo.id)}
                  >
                    <img
                      alt=""
                      src={trashIcon}
                    />
                  </div>
                )}
                <div className={imageDropdownStyles.imageActions}>
                  <div
                    className={imageDropdownStyles.btnApply}
                    onClick={() =>
                      photoOnClick({
                        url: photo.photo.url,
                        webp_url: photo.photo.webp_url,
                        avif_url: photo.photo.avif_url,
                      })
                    }
                  ></div>
                  <div
                    className={imageDropdownStyles.btnLinkCopy}
                    onClick={(e) => copyPhotoUrl(e)}
                  >
                    <input
                      type="text"
                      readOnly
                      className={imageDropdownStyles.hide}
                      value={photo.photo.url}
                    />
                  </div>
                </div>
              </div>
              <MediaTag
                value={{ url: photo.photo.url }}
                attributes={{
                  loading: "lazy",
                }}
              />
            </div>
          );
        case "displayList":
          return (
            <div
              key={index}
              className={imageDropdownStyles.articlePhotoList}
            >
              <div className={imageDropdownStyles.left}>
                <MediaTag
                  value={{ url: photo.photo.url }}
                  attributes={{
                    loading: "lazy",
                  }}
                />
                <div className={imageDropdownStyles.photoFilename}>
                  {photo.filename ? photo.filename : t("ファイル名なし")}
                </div>
              </div>
              <div className={imageDropdownStyles.right}>
                <div
                  className={imageDropdownStyles.btnApply}
                  onClick={() =>
                    photoOnClick({
                      url: photo.photo.url,
                      webp_url: photo.photo.webp_url,
                      avif_url: photo.photo.avif_url,
                    })
                  }
                ></div>
                <div
                  className={imageDropdownStyles.btnLinkCopy}
                  onClick={(e) => copyPhotoUrl(e)}
                >
                  <input
                    type="text"
                    readOnly
                    className={imageDropdownStyles.hide}
                    value={photo.photo.url}
                  />
                </div>
                {!photo.isOnEditor && (
                  <div
                    className={imageDropdownStyles.btnTrash}
                    onClick={() => updateArticlePhotoPublishStatus(article.uid, photo.id)}
                  >
                    <img
                      alt=""
                      src={trashIcon}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        default:
      }
    });
    return photoComponents;
  };

  return (
    <div
      className={imageDropdownStyles.articlePhotosWrapper}
      ref={photoWrapperRef}
    >
      {articlePhoto.photos.length === 0 ? (
        <div className={imageDropdownStyles.noImageDescription}>{t("画像がありません")}</div>
      ) : (
        <div className={imageDropdownStyles.articlePhotos}>{showArticlePhotos()}</div>
      )}
      {articlePhoto.pageNum > 0 && (
        <div
          className={`${imageDropdownStyles.btnMore}`}
          onClick={() => fetchArticlePhotos()}
        >
          {t("さらに読み込む")}
        </div>
      )}
    </div>
  );
};
