import React from "react";

type Props = {
  width: string;
  height: string;
};

export const AdcorsaIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-200.000000, -1680.000000)">
          <g transform="translate(200.000000, 1680.000000)">
            <rect
              fill="#333435"
              opacity="0"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
            <path
              d="M18.0000201,0.0987484939 L18.0000201,39.9012515 C7.89336506,38.8978119 0,30.3707031 0,20 C0,9.62929694 7.89336506,1.10218806 18.0000201,0.0987484939 Z"
              fill="#FA3B1D"
            ></path>
            <circle
              fill="#FAE601"
              cx="30"
              cy="10"
              r="10"
            ></circle>
            <path
              d="M40,40 C40,32.6362033 40,25.9695367 40,20 C28.954305,20 20,28.954305 20,40 C25.9695367,40 32.6362033,40 40,40 Z"
              fill="#FF9A02"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
