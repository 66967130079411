import React, { useState } from 'react'

import { useForm, ErrorMessage } from 'react-hook-form';

import btnStyles from 'stylesheets/components/partials/btn.module.scss'
import formStyles from 'stylesheets/components/partials/form.module.scss'
import duplicateModalStyles from 'stylesheets/components/editor/duplicateModal.module.scss'
import appModalStyles from 'stylesheets/components/partials/appModal.module.scss';
import dropdownStyles from 'stylesheets/components/partials/dropdown.module.scss'

import { AppModal } from "components";
import ErrorText from 'javascripts/components/Partials/ErrorText';

import duplicateIcon from 'assets/images/duplicate-white.svg'

import { useAlert } from 'contexts/alert';
import { useTranslation } from "react-i18next";

const DuplicateModal = ({ article, duplicateArticle }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsOpen] = useState(false);

  const alertState = useAlert();

  const { register, handleSubmit, errors, watch, setValue } = useForm();
  const onSubmit = data => {
    let params = new FormData()
    params.append('ab_test[duplicate_count]', data.duplicateCount)
    params.append('ab_test[link_type]', data.linkType)
    params.append('ab_test[is_inherit_html_tag]', data.isInheritHtmlTag)
    params.append('ab_test[is_inherit_funnel_step]', data.isInheritFunnelStep)
    alertState.showAlerts({messages: [t('を開始しました', { value: t("複製") })], theme: 'info'});
    duplicateArticle(article, params)
    setIsOpen(false)
  };


  return (
    <AppModal
      theme="darkTheme"
      width={700}
      isModalOpen={isModalOpen}
      setIsOpen={(e) => setIsOpen(e)}
    >
      <div className={`${dropdownStyles.dropdownChoice}`}>
        {t("複製")}
      </div>
      <div className={`${appModalStyles.modalWrapper}`}>
        <div className={appModalStyles.modalHeader}>
          <div className={appModalStyles.left}>
            <div
              data-test="DuplicateModal-BtnClose"
              className={`${btnStyles.btn} ${btnStyles.btnDarkThemeSub} ${btnStyles.btnSmall}`}
              onClick={() => setIsOpen(false) }
            >
              {t("閉じる")}
            </div>
          </div>
          <div className={appModalStyles.center}>
            <div className={appModalStyles.title}>
                {t("Version複製")}
            </div>
          </div>
          <div className={appModalStyles.right}>
            <div
              className={`${btnStyles.btn} ${btnStyles.btnDarkThemePrimary} ${btnStyles.btnSmall} ${appModalStyles.action}`}
              onClick={handleSubmit(onSubmit)}
            >
              {t("複製する")}
            </div>
          </div>
        </div>
        <div className={appModalStyles.modalContent}>
          <form data-test="DuplicateModal-DestinationArticleForm" onSubmit={handleSubmit(onSubmit)}>
            <div className={`${duplicateModalStyles.abTestDuplicateCountFormWrapper}`}>
              <label>{t("複製個数　※最大4つまで")}</label>
              <input name='duplicateCount'
                min={1}
                max={4}
                className={`${duplicateModalStyles.abTestDuplicateCountForm}`}
                ref={register({
                  min: 1,
                  max: 4,
                })}
                type='number'
                defaultValue={1}
                />
              <div className={`${duplicateModalStyles.spinButtons}`}>
                <div className={`${duplicateModalStyles.spinButtonArrow} ${duplicateModalStyles.spinButtonUp}`}
                  onClick={() => {
                    if (4 < parseInt(watch('duplicateCount')) + 1) {
                      return
                    }
                    setValue('duplicateCount', parseInt(watch('duplicateCount')) + 1, { shouldValidate: true })}
                  }
                >
                  <div className={`${duplicateModalStyles.up}`}>
                  </div>
                </div>
                <div className={`${duplicateModalStyles.spinButtonArrow} ${duplicateModalStyles.spinButtonDown} `}
                  onClick={() => {
                    if (parseInt(watch('duplicateCount')) - 1 < 1) {
                      return
                    }
                    setValue('duplicateCount', parseInt(watch('duplicateCount')) - 1, { shouldValidate: true })}
                  }
                >
                  <div className={`${duplicateModalStyles.down} `}>
                  </div>
                </div>
              </div>
            </div>
            <div className={formStyles.formGroup}>
              <label>{t("リンク")}</label>
              <select
                name='linkType'
                ref={register}
                className={`${formStyles.formControl} ${errors.linkType ? formStyles.invalid : ''}`}
              >
                <option value='leave_links'>{t("を残す", { value: t("リンク") })}</option>
                <option value='remove_links'>{t("を削除", { value: t("リンク") })}</option>
                <option value='remove_tracking_links'>{t("トラッキングリンクだけ削除")}</option>
              </select>
              <ErrorText text={<ErrorMessage errors={errors} name='linkType' />} size={'small'} />
            </div>
            <div className={formStyles.formGroup}>
              <label>{t("Versionのheadとbodyタグ")}</label>
              <select
                name='isInheritHtmlTag'
                ref={register}
                className={`${formStyles.formControl} ${errors.isInheritHtmlTag ? formStyles.invalid : ''}`}
              >
                <option value="true">{t("引き継ぐ")}</option>
                <option value="false">{t("引き継がない")}</option>
              </select>
              <ErrorText text={<ErrorMessage errors={errors} name='isInheritHtmlTag' />} size={'small'} />
            </div>
            <div className={formStyles.formGroup}>
              <label>{t("ステップ")}</label>
              <select
                name='isInheritFunnelStep'
                ref={register}
                className={`${formStyles.formControl} ${errors.isInheritFunnelStep ? formStyles.invalid : ''}`}
              >
                <option value="true">{t("引き継ぐ")}</option>
                <option value="false">{t("引き継がない")}</option>
              </select>
              <ErrorText text={<ErrorMessage errors={errors} name='isInheritFunnelStep' />} size={'small'} />
            </div>
          </form>
        </div>
      </div>
    </AppModal>
  )
}


DuplicateModal.propTypes = {
}

export default DuplicateModal
