import { deleteRequest, get, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import {
  DocomoAccountApiCreateAccountRequest,
  DocomoAccountApiCreateAccountResponse,
  DocomoAccountApiDestroyAccountRequest,
  DocomoAccountApiDestroyAccountResponse,
  DocomoAccountApiFetchAccountsResponse,
} from "./type";

const fetchAccounts = () =>
  get<{}, DocomoAccountApiFetchAccountsResponse>(
    {},
    `${DEFAULT_BASE_URL}/teams/ad_accounts/docomo_ad_accounts`
  );

const createAccount = (params: DocomoAccountApiCreateAccountRequest) =>
  post<DocomoAccountApiCreateAccountRequest, DocomoAccountApiCreateAccountResponse>(
    params,
    `${DEFAULT_BASE_URL}/teams/ad_accounts/docomo_ad_accounts`
  );

const destroyAccount = (params: DocomoAccountApiDestroyAccountRequest) =>
  deleteRequest<DocomoAccountApiDestroyAccountRequest, DocomoAccountApiDestroyAccountResponse>(
    params,
    `${DEFAULT_BASE_URL}/teams/ad_accounts/docomo_ad_accounts/${params.accountId}`
  );

export const DocomoAccountApi = {
  fetchAccounts,
  createAccount,
  destroyAccount,
} as const;
