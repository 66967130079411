import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class TeamDomainTagApi extends ApiBase {

  static fetchTags(domainId) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/teams/domains/${domainId}/tags`, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static create(domainId, documentProperty) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/domains/${domainId}/tags`;
      axios.post(path, { document_property: documentProperty }, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static update(domainId, tagId, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/domains/${domainId}/tags/${tagId}`;
      axios.patch(path, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

  static destroy(domainId, tagId) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      const path = `${baseUrl}/teams/domains/${domainId}/tags/${tagId}`;
      axios.delete(path, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }

}

export default TeamDomainTagApi;
