import React, { useEffect } from "react";
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import { AuthState, useAuth, useAuthCookieManagement } from "hooks";

export const AuthRouter: React.FC<{
  children?: React.ReactNode | undefined;
}> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isEmpty, copySessionToCookie, removeSessionFromCookie } = useAuthCookieManagement();

  const matchInvitation = !!useMatch("/teams/:team_uid/members/:member_uid/invitations/edit");
  const matchSignUp = !!useMatch("/users/sign_up");
  const matchChangePassword = !!useMatch("/users/forgot_password");
  const matchEditPassword = !!useMatch("/users/password/edit");
  const matchUserConfirmationShow = !!useMatch("/users/confirmation");
  const matchUserConfirmationCodeNew = !!useMatch("/users/confirmation/code/new");
  const matchTerms = !!useMatch("/terms");
  const matchPrivacy = !!useMatch("/privacy");
  const matchTopPage = !!useMatch("/");
  const isUnAuthRouteMatch: boolean =
    matchInvitation ||
    matchSignUp ||
    matchChangePassword ||
    matchEditPassword ||
    matchUserConfirmationShow ||
    matchUserConfirmationCodeNew ||
    matchTerms ||
    matchPrivacy ||
    matchTopPage ||
    false;

  const [authState] = useAuth();
  useEffect(() => {
    switch (authState) {
      case AuthState.Auth: {
        /**
         * cookie に session がある場合は、session を cookie から削除する
         * 今後 dashboard への移行に伴って、この処理は不要になります
         * 代わりに、cookie に session がない場合は、session を LocalStorage から cookie にコピーする処理を
         * 一時的に走らせる予定
         */
        if (!isEmpty) {
          removeSessionFromCookie();
        }
        if (location.pathname !== "/users/teams") {
          navigate("/users/teams");
        }
        break;
      }
      case AuthState.AuthToTeam: {
        /**
         * cookie に session がある場合は、session を cookie から削除する
         * 今後 dashboard への移行に伴って、この処理は不要になります
         * 代わりに、cookie に session がない場合は、session を LocalStorage から cookie にコピーする処理を
         * 一時的に走らせる予定
         */
        if (!isEmpty) {
          removeSessionFromCookie();
        }
        break;
      }
      case AuthState.Unauth: {
        if (!isUnAuthRouteMatch) {
          navigate("/");
        }
        break;
      }
    }
  }, [
    authState,
    location,
    isUnAuthRouteMatch,
    isEmpty,
    copySessionToCookie,
    navigate,
    removeSessionFromCookie,
  ]);
  return <React.Fragment>{props.children}</React.Fragment>;
};