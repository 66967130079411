import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, List, ListItem, Typography } from "@mui/material";

export const XConversionApiDescription: FC = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography>
        {t("Squad beyondで計測したコンバージョンデータをXに送信することができます。")}
        {t("この機能を利用することで、コンバージョン計測や最適化の精度向上が期待できます。")}
      </Typography>
      <List>
        <ListItem sx={{ display: "list-item" }}>
          <Link
            href="https://www.linebiz.com/jp/login/"
            target="_blank"
          >
            {t("WIP")}
          </Link>
        </ListItem>
      </List>
    </Box>
  );
};
