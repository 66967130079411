import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import { TextButton } from "components/atoms/text-button";
import { useDeleteFolder } from "hooks";
import type { DeleteFolderProps } from "./type";

export const DeleteFolder = ({ folder, ...buttonProps }: DeleteFolderProps) => {
  const { t } = useTranslation();
  const [handleDelete, loading] = useDeleteFolder();
  const handleClick = async () => {
    await handleDelete(folder.uid);
  };

  return (
    <TextButton
      align="left"
      onClick={handleClick}
      startIcon={
        <DeleteIcon
          sx={{ width: "20px" }}
          color="error"
        />
      }
      {...buttonProps}
    >
      <Typography sx={{ color: "error.main" }}>
        {loading ? `${t("削除中")}...` : t("フォルダを削除する")}
      </Typography>
    </TextButton>
  );
};
