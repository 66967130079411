import { post } from "api";
import { DEFAULT_BASE_URL } from "const/app";

export type UserConfirmationRequestParams = {
  email: string;
  code: string;
};

const create = async (params: UserConfirmationRequestParams) => {
  const path = `${DEFAULT_BASE_URL}/users/confirmation`;
  return post<UserConfirmationRequestParams, boolean>(params, path);
};

export const UserConfirmationApi = {
  create,
} as const;
