import React, { useState, useEffect, createContext, useContext, useRef } from "react";

import { useTranslation } from "react-i18next";
import btnStyles from "stylesheets/components/partials/btn.module.scss";
import alertStyles from "stylesheets/contexts/alert.module.scss";

type Alert = {
  messages: string[];
  theme: "danger" | "info" | "success";
  isDisplayBtnClose: boolean;
  isAutoClose: boolean;
};

type UseAlertValues = {
  alert?: Alert;
  showAlerts: (
    a: Pick<Alert, "messages" | "theme"> &
      Partial<Pick<Alert, "isAutoClose" | "isDisplayBtnClose">>,
  ) => void;
  clearAlerts: () => void;
};

const AlertContext = createContext<UseAlertValues>({
  showAlerts: () => {},
  clearAlerts: () => {},
});

const useAlert = () => useContext(AlertContext);

const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsOpen] = useState(false);
  const [alert, setAlertState] = useState<Alert>({
    messages: [],
    theme: "danger",
    isDisplayBtnClose: true,
    isAutoClose: false,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  const showAlerts = (a: Parameters<UseAlertValues["showAlerts"]>[0]) =>
    setAlertState({ ...alert, ...a });

  const clearAlerts = () => {
    setAlertState({ ...alert, messages: [] });
  };

  useEffect(() => {
    if (alert.messages.length === 0) {
      return;
    }

    setIsOpen(true);

    if (!alert.isAutoClose) {
      return;
    }

    setTimeout(() => {
      wrapperRef.current?.classList.add(alertStyles.transitionClose);
      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    }, 3000);
  }, [alert]);

  return (
    <AlertContext.Provider
      value={{
        alert,
        showAlerts,
        clearAlerts,
      }}
    >
      <div
        ref={wrapperRef}
        className={`${alertStyles.wrapper} ${isModalOpen ? alertStyles.open : alertStyles.close}`}
      >
        <div className={`${alertStyles.container} ${alertStyles[alert.theme]}`}>
          <div className={`${alertStyles.item} ${alertStyles.icon}`}>
            <div className={alertStyles.triangleWrapper}>
              <div className={alertStyles.triangle} />
              <span>!</span>
            </div>
          </div>
          <div className={`${alertStyles.item} ${alertStyles.errors} ${alertStyles.messages}`}>
            <ul>
              {alert.messages.map((message, index) => (
                <li key={index}>
                  <span dangerouslySetInnerHTML={{ __html: message }}></span>
                </li>
              ))}
            </ul>
          </div>
          {alert.isDisplayBtnClose && !alert.isAutoClose && (
            <div className={`${alertStyles.item} ${alertStyles.actions}`}>
              <div
                onClick={() => {
                  setIsOpen(false);
                }}
                className={`${btnStyles.btn} ${btnStyles.btnCancelError}`}
              >
                {t("閉じる")}
              </div>
            </div>
          )}
        </div>
      </div>
      {children}
    </AlertContext.Provider>
  );
};

export { AlertProvider, useAlert };
