import { AppDropdown } from "components/atoms/app-dropdown";
import { InfoMarkIcon } from "components/atoms/icons/info-mark-icon";
import itemWithInfoMarkStyles from "stylesheets/components/partials/itemWithInfoMark.module.scss";
import { ItemWithInfoMarkProps } from "./type";

export const ItemWithInfoMark = ({
  theme = "lightTheme",
  descriptionVerticalPosition = "center",
  descriptionHorizontalPosition = "right",
  descriptionWidth = 300,
  isMarkCenter = false,
  children,
}: ItemWithInfoMarkProps) => {
  const [label, description] = children;

  return (
    <AppDropdown
      topBottom={descriptionVerticalPosition}
      leftRight={descriptionHorizontalPosition}
      control={"hover"}
    >
      <div className={itemWithInfoMarkStyles.container}>
        <div className={itemWithInfoMarkStyles.item}>{label}</div>
        <div
          className={`${itemWithInfoMarkStyles.item} ${
            isMarkCenter ? itemWithInfoMarkStyles.iconCenter : ""
          }`}
        >
          <InfoMarkIcon theme={theme} />
        </div>
      </div>
      <div
        className={itemWithInfoMarkStyles.description}
        style={{ width: descriptionWidth }}
      >
        {description}
      </div>
    </AppDropdown>
  );
};
