import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";
import { ArticleInspectionRequestProps } from "domains";
import ArticleInspectionRequestApi from "javascripts/api/articles/inspectionRequest";
import ArticleLatestInspectionRequestApi from "javascripts/api/articles/latestInspectionRequest";

const articleLatestInspectionRequestState = atom<ArticleInspectionRequestProps>({
  key: "articleLatestInspectionRequestState",
  default: {} as ArticleInspectionRequestProps,
});

export const useFetchArticleLatestInspectionRequest = () => {
  const [_articleLatestInspectionRequest, setArticleLatestInspectionRequest] = useRecoilState(
    articleLatestInspectionRequestState
  );
  const handle = (articleUid: string) => {
    ArticleLatestInspectionRequestApi.fetchRequest(articleUid)
      .then((data) => {
        setArticleLatestInspectionRequest(data);
      })
      .catch(() => {});
  };

  return [handle] as const;
};

export type HandleCreateArticleInspectionRequestProps = (
  articleUid: string,
  description: string
) => void;
export const useCreateArticleInspectionRequest = () => {
  const [_articleLatestInspectionRequest, setArticleLatestInspectionRequest] = useRecoilState(
    articleLatestInspectionRequestState
  );
  const handle: HandleCreateArticleInspectionRequestProps = useCallback(
    async (articleUid, description) => {
      const params = {
        inspection_request: {
          description: description,
        },
      };
      await ArticleInspectionRequestApi.create(articleUid, params)
        .then((data) => {
          setArticleLatestInspectionRequest(data);
        })
        .catch(() => {});
    },
    []
  );

  return [handle] as const;
};

export type HandleDeleteArticleLatestInspectionRequestProps = (articleUid: string) => void;
export const useDeleteArticleLatestInspectionRequest = () => {
  const { t } = useTranslation();
  const [fetchArticleLatestInspectionHandle] = useFetchArticleLatestInspectionRequest();
  const handle: HandleDeleteArticleLatestInspectionRequestProps = (articleUid) => {
    if (
      !window.confirm(t("本当に削除しますか？削除を実行すると後から元に戻すことはできません") || "")
    ) {
      return;
    }
    ArticleLatestInspectionRequestApi.delete(articleUid)
      .then(() => {
        fetchArticleLatestInspectionHandle(articleUid);
      })
      .catch(() => {});
  };

  return [handle] as const;
};

export const useArticleLatestInspectionRequest = (articleUid: string) => {
  const [articleLatestInspectionRequest, setArticleLatestInspectionRequest] = useRecoilState(
    articleLatestInspectionRequestState
  );
  const [handle] = useFetchArticleLatestInspectionRequest();

  useEffect(() => {
    handle(articleUid);
  }, []);

  return [articleLatestInspectionRequest, setArticleLatestInspectionRequest] as const;
};
