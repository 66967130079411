import {
  AbTestDailyReportProps,
  AbTestProps,
  AbTestReportMaxCountProps,
  AbTestReportMinCountProps,
  AbTestReportProps,
  AbTestReportTotalCountProps,
} from "domains";
import { percentage, divide } from "./math";

export const sumUpAbTestDailyReportList = (
  abTestList: AbTestProps[],
  abTestDailyReportList: AbTestDailyReportProps[]
) => {
  const abTestReportList = abTestList.map((abTest: AbTestProps) => {
    const reportList = abTestDailyReportList.filter(
      (report: AbTestDailyReportProps) => report.ab_test_id === abTest.id
    );
    const report = reportList.reduce<Record<string, number>>(
      (r, report: AbTestDailyReportProps) => {
        r.pv += report.page_view_count;
        r.click += report.click_count;
        r.cv += report.conversion_count;
        r.adSpending += report.ad_spending;
        return r;
      },
      { pv: 0, click: 0, cv: 0, adSpending: 0 }
    );
    report.ctr = percentage(report.click, report.pv);
    report.cvr = percentage(report.cv, report.click);
    report.ctvr = percentage(report.cv, report.pv);
    report.sales = report.cv * (abTest.conversion_unit_price || 0);
    report.cpa = divide(report.adSpending, report.cv);
    report.mcpa = divide(report.adSpending, report.click);
    report.ab_test_id = abTest.id;
    return report;
  });
  return abTestReportList;
};

export const filterAbTestReportList = (
  abTestList: AbTestProps[],
  abTestReportList: AbTestReportProps[]
): AbTestReportProps[] => {
  const list = abTestList.map((abTest: AbTestProps) => {
    const report = abTestReportList.find(
      (abTestReport: AbTestReportProps) => abTestReport.ab_test_id === abTest.id
    );
    if (report === undefined) {
      return {};
    } else {
      return report;
    }
  });
  return list;
};

export const pickMinCountFromAbTestReportList = (
  abTestReportList: AbTestReportProps[]
): AbTestReportMinCountProps => {
  const minCount = abTestReportList.reduce<Record<string, number>>(
    (r, report: AbTestReportProps) => {
      if ((r.pv > report.pv || r.pv == 0) && report.pv > 0) {
        r.pv = report.pv;
      }
      if ((r.click > report.click || r.click == 0) && report.click > 0) {
        r.click = report.click;
      }
      if ((r.cv > report.cv || r.cv == 0) && report.cv > 0) {
        r.cv = report.cv;
      }
      if ((r.adSpending > report.adSpending || r.adSpending == 0) && report.adSpending > 0) {
        r.adSpending = report.adSpending;
      }
      if ((r.sales > report.sales || r.sales == 0) && report.sales > 0) {
        r.sales = report.sales;
      }
      if ((r.ctr > report.ctr || r.ctr == 0) && report.ctr > 0) {
        r.ctr = report.ctr;
      }
      if ((r.cvr > report.cvr || r.cvr == 0) && report.cvr > 0) {
        r.cvr = report.cvr;
      }
      if ((r.ctvr > report.ctvr || r.ctvr == 0) && report.ctvr > 0) {
        r.ctvr = report.ctvr;
      }
      if ((r.cpa > report.cpa || r.cpa == 0) && report.cpa > 0) {
        r.cpa = report.cpa;
      }
      if ((r.mcpa > report.mcpa || r.mcpa == 0) && report.mcpa > 0) {
        r.mcpa = report.mcpa;
      }
      return r;
    },
    {
      pv: 0,
      click: 0,
      cv: 0,
      adSpending: 0,
      sales: 0,
      ctr: 0,
      cvr: 0,
      ctvr: 0,
      cpa: 0,
      mcpa: 0,
    }
  );

  return minCount;
};

export const pickMaxCountFromAbTestReportList = (
  abTestReportList: AbTestReportProps[]
): AbTestReportMaxCountProps => {
  const maxCount = abTestReportList.reduce<Record<string, number>>(
    (r, report: AbTestReportProps) => {
      if (r.pv < report.pv) {
        r.pv = report.pv;
      }
      if (r.click < report.click) {
        r.click = report.click;
      }
      if (r.cv < report.cv) {
        r.cv = report.cv;
      }
      if (r.adSpending < report.adSpending) {
        r.adSpending = report.adSpending;
      }
      if (r.sales < report.sales) {
        r.sales = report.sales;
      }
      if (r.ctr < report.ctr) {
        r.ctr = report.ctr;
      }
      if (r.cvr < report.cvr) {
        r.cvr = report.cvr;
      }
      if (r.cvr < report.cvr) {
        r.cvr = report.cvr;
      }
      if (r.ctvr < report.ctvr) {
        r.ctvr = report.ctvr;
      }
      if (r.cpa < report.cpa) {
        r.cpa = report.cpa;
      }
      if (r.mcpa < report.mcpa) {
        r.mcpa = report.mcpa;
      }
      return r;
    },
    {
      pv: 0,
      click: 0,
      cv: 0,
      adSpending: 0,
      sales: 0,
      ctr: 0,
      cvr: 0,
      ctvr: 0,
      cpa: 0,
      mcpa: 0,
    }
  );

  return maxCount;
};

export const sumAbTestReportCount = (
  abTestReportList: AbTestReportProps[]
): AbTestReportTotalCountProps => {
  const count = abTestReportList.reduce<Record<string, number>>(
    (r, report: AbTestReportProps) => {
      r.pv += report.pv;
      r.click += report.click;
      r.cv += report.cv;
      r.adSpending += report.adSpending;
      r.sales += report.sales;
      return r;
    },
    {
      pv: 0,
      click: 0,
      cv: 0,
      adSpending: 0,
      sales: 0,
    }
  );
  count.ctr = percentage(count.click, count.pv);
  count.cvr = percentage(count.cv, count.click);
  count.ctvr = percentage(count.cv, count.pv);
  count.cpa = divide(count.adSpending, count.cv);
  count.mcpa = divide(count.adSpending, count.click);
  return count;
};
