import axios from 'axios';
import ApiBase from 'javascripts/api/apiBase';

class AbTestParameterDailyReportApi extends ApiBase {
  static fetchReports(uid, params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.get(`${baseUrl}/ab_tests/${uid}/parameter_daily_reports`, { params, withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default AbTestParameterDailyReportApi;
