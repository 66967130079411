import axios from 'axios';
import ApiBase from './apiBase';

class TeamApi extends ApiBase {
  static update(params) {
    const baseUrl = this.defaultBaseUrl();
    return new Promise(function(resolve, reject) {
      axios.patch(`${baseUrl}/teams`, params, { withCredentials: true })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
    });
  }
}

export default TeamApi;
