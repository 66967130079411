import { deleteRequest, get, post } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { SplitTestSettingsOsesResponse } from "./type";

const fetch = async (abTestUid: string): Promise<SplitTestSettingsOsesResponse> => {
  const ENDPOINT = `${DEFAULT_BASE_URL}/ab_tests/${abTestUid}/articles/split_test_settings/oses`;
  return get<{}, SplitTestSettingsOsesResponse>({}, ENDPOINT);
};

const create = async (articleUid: string, params: { os_id: number }): Promise<void> => {
  const ENDPOINT = `${DEFAULT_BASE_URL}/articles/${articleUid}/split_test_settings/oses/allowlists`;
  return post(params, ENDPOINT);
};

const destroy = async (articleUid: string, params: { os_id: number }): Promise<void> => {
  const ENDPOINT = `${DEFAULT_BASE_URL}/articles/${articleUid}/split_test_settings/oses/allowlists`;
  return deleteRequest(params, ENDPOINT);
};

export const AbTestArticleSplitTestSettingOsesApi = {
  fetch,
  create,
  destroy,
} as const;
