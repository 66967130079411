import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import {
  FodlerFormContactColumnProps,
  FodlerFormContactMasterColumnProps,
  FodlerFormContactUserColumnProps,
} from "domains";
import FolderFormContactUserColumnApi from "javascripts/api/folders/forms/contacts/userColumn";

export type FolderFormContactColumnList = {
  masterColumns: Array<FodlerFormContactColumnProps>;
  defaultColumns: Array<FodlerFormContactColumnProps>;
  userMasterColumns: Array<FodlerFormContactMasterColumnProps>;
  userColumns: Array<FodlerFormContactUserColumnProps>;
  customColumns: Array<Pick<FodlerFormContactColumnProps, "id" | "name">>;
};

const folderFormContactColumnListState = atom<FolderFormContactColumnList>({
  key: "folderFormContactColumnListState",
  default: {
    masterColumns: [],
    defaultColumns: [],
    userMasterColumns: [],
    userColumns: [],
    customColumns: [],
  },
});

export type HandleFetchFolderFormContactColumnList = (folderUid: string, formUid: string) => void;
export const useFetchFolderFormContactColumnList = () => {
  const [_folderFormContactColumnList, setFolderFormContactColumnList] = useRecoilState(
    folderFormContactColumnListState
  );

  const handle: HandleFetchFolderFormContactColumnList = useCallback(async (folderUid, formUid) => {
    try {
      const response = await FolderFormContactUserColumnApi.fetchColumns({
        folder_uid: folderUid,
        form_uid: formUid,
      });
      setFolderFormContactColumnList({
        masterColumns: response.master_columns,
        defaultColumns: response.default_columns,
        userMasterColumns: response.user_master_columns,
        userColumns: response.user_columns,
        customColumns: response.custom_columns,
      });
    } catch (e) {}
  }, []);

  return [handle] as const;
};

export type HandleCreateFolderFormContactColumn = (params: {
  folder_form_contact_user_column: Record<string, string>;
  folder_form_contact_user_master_column: Record<string, string>;
}) => void;

export const useCreateFolderFormContactColumnList = () => {
  const [folderFormContactColumnList, setFolderFormContactColumnList] = useRecoilState(
    folderFormContactColumnListState
  );
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const handle: HandleCreateFolderFormContactColumn = useCallback(
    async (params) => {
      setIsRequesting(true);
      try {
        const response = await FolderFormContactUserColumnApi.createColumns(params);
        setFolderFormContactColumnList({
          ...folderFormContactColumnList,
          userColumns: response.user_columns,
          userMasterColumns: response.user_master_columns,
        });
        setIsRequesting(false);
      } catch (e) {
        setIsRequesting(false);
      }
    },
    [folderFormContactColumnList, isRequesting]
  );

  return [handle, isRequesting] as const;
};

export const useFolderFormContactColumnList = (folderUid: string, formUid: string) => {
  const [folderFormContactColumnList, setFolderFormContactColumnList] = useRecoilState(
    folderFormContactColumnListState
  );
  const [handle] = useFetchFolderFormContactColumnList();

  useEffect(() => {
    handle(folderUid, formUid);
  }, []);

  return [folderFormContactColumnList, setFolderFormContactColumnList] as const;
};
