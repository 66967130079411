import { useTranslation } from "react-i18next";
import ErrorPanelStyles from "stylesheets/components/partials/errorPanel.module.scss";

export const ErrorPanel = () => {
  const { t } = useTranslation();
  return (
    <div className={ErrorPanelStyles.errorPanel}>
      <div className={ErrorPanelStyles.container}>
        <h1>{t("何らかのエラーが発生しました。")}🙇</h1>
        <div>{t("しばらく時間をおいてからもう一度アクセスして下さい。")}</div>
      </div>
    </div>
  );
};
