import { get, patch } from "api";
import { DEFAULT_BASE_URL } from "const/app";
import { FolderFormContactShowProps } from "domains";

export type FetchFolderFormContactResponse = {
  contacts: FolderFormContactShowProps[];
  next_page: number | null;
  total_count: number;
};

const fetch = (folderUid: string, folderFormUid: string, params: any) => {
  const path = `${DEFAULT_BASE_URL}/folders/${folderUid}/forms/${folderFormUid}/contacts`;

  return get<{}, FetchFolderFormContactResponse>(params, path);
};

const update = (folderUid: string, folderFormUid: string, params: any) => {
  const path = `${DEFAULT_BASE_URL}/folders/${folderUid}/forms/${folderFormUid}/contacts`;

  return patch<{}, any>(params, path);
};

export const FolderFormContactApi = {
  fetch,
  update,
} as const;
