import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Switch, Tooltip, Typography } from "@mui/material";
import {
  BeyondPageArticleOptionList,
  ContactButton,
  DesktopIcon,
  MobilePhoneIcon,
  TabletIcon,
  Theme,
} from "components";
import { LocalNavigation } from "components/organisms/core/LocalNavigation";
import { useAlert } from "contexts/alert";
import {
  AbTestProps,
  ArticleProps,
  ArticleSplitTestDeviceBlockListsProps,
  ColorList,
} from "domains";

import Nav from "javascripts/components/Editor/Nav";
import SaveAnimation, { SaveAnimationHandler } from "javascripts/components/Partials/SaveAnimation";

const SwitchButton: React.FC<{
  articleUid: string;
  deviceId: number;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isUpperPlan: boolean;
  onCreateDevice: Function;
  onDestroyDevice: Function;
}> = ({
  articleUid,
  deviceId,
  isChecked,
  setIsChecked,
  isUpperPlan,
  onCreateDevice,
  onDestroyDevice,
}) => {
  const { clearAlerts } = useAlert();

  type Params = {
    device_id: number;
  };
  const handleCheck = useCallback(async (params: Params) => {
    const response = await onCreateDevice(articleUid, params);
    if (response) {
      setIsChecked(false);
    }
  }, []);

  const handleUnCheck = useCallback(async (params: Params) => {
    const response = await onDestroyDevice(articleUid, params);
    if (response) {
      setIsChecked(true);
    }
  }, []);

  const handleChange = useCallback(() => {
    clearAlerts();
    const params = {
      device_id: deviceId,
    };
    if (isChecked) {
      handleCheck(params);
    } else {
      handleUnCheck(params);
    }
  }, []);

  if (!isUpperPlan) {
    return null;
  }

  return (
    <Switch
      checked={isChecked}
      sx={{
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "primary.contrastText",
        },
      }}
      onChange={handleChange}
    />
  );
};

const Article: React.FC<{
  article: ArticleProps;
  isUpperPlan: boolean;
  onCreateDevice: Function;
  onDestroyDevice: Function;
}> = ({ article, isUpperPlan, onCreateDevice, onDestroyDevice }) => {
  const [spDeviceId, pcDeviceId, tabletDeviceId] = [1, 2, 3];
  const [isSpBlocked, setIsSpBlocked] = useState<boolean>(false);
  const [isPcBlocked, setIsPcBlocked] = useState<boolean>(false);
  const [isTabletBlocked, setIsTabletBlocked] = useState<boolean>(false);

  useEffect(() => {
    const existsSpBlocklist =
      article.split_test_device_blocklists.find(
        (blocklist: ArticleSplitTestDeviceBlockListsProps) => blocklist.device_id === spDeviceId,
      ) !== undefined;
    const existsPcBlocklist =
      article.split_test_device_blocklists.find(
        (blocklist: ArticleSplitTestDeviceBlockListsProps) => blocklist.device_id === pcDeviceId,
      ) !== undefined;
    const existsTabletBlocklist =
      article.split_test_device_blocklists.find(
        (blocklist: ArticleSplitTestDeviceBlockListsProps) =>
          blocklist.device_id === tabletDeviceId,
      ) !== undefined;

    setIsSpBlocked(!existsSpBlocklist);
    setIsPcBlocked(!existsPcBlocklist);
    setIsTabletBlocked(!existsTabletBlocklist);
  }, []);

  const SwitchButtonContent: React.FC<{
    isBlocked: boolean;
    setIsBlocked: React.Dispatch<React.SetStateAction<boolean>>;
    deviceId: number;
  }> = ({ isBlocked, setIsBlocked, deviceId }) => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "46px",
        }}
      >
        <SwitchButton
          articleUid={article.uid}
          deviceId={deviceId}
          isChecked={isBlocked}
          setIsChecked={setIsBlocked}
          isUpperPlan={isUpperPlan}
          onCreateDevice={onCreateDevice}
          onDestroyDevice={onDestroyDevice}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ bgcolor: "gray" }}>
      <Box
        sx={{
          pr: 1.4,
          pb: 2,
          bgcolor: "background.primary",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            bgcolor: "background.secondary",
            borderRadius: "0 10px 10px 0",
          }}
        >
          <SwitchButtonContent
            isBlocked={isSpBlocked}
            setIsBlocked={setIsSpBlocked}
            deviceId={spDeviceId}
          />
          <SwitchButtonContent
            isBlocked={isTabletBlocked}
            setIsBlocked={setIsTabletBlocked}
            deviceId={tabletDeviceId}
          />
          <SwitchButtonContent
            isBlocked={isPcBlocked}
            setIsBlocked={setIsPcBlocked}
            deviceId={pcDeviceId}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ArticleTitle: React.FC<{
  title: string;
  abTestRate: number;
  // FIXME
  // eslint-disable-next-line react/display-name
}> = memo(({ title, abTestRate }) => {
  return (
    <Tooltip
      title={<Typography sx={{ fontSize: "15px" }}>{title}</Typography>}
      placement="top"
    >
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            fontSize: "14px",
            height: "46px",
            lineHeight: "46px",
            px: 1.4,
            py: 1,
            boxSizing: "border-box",
            bgcolor: "lightGray",
            borderRadius: "10px 0 0 10px",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              flex: 1,
              color: "gray",
              textAlign: "right",
            }}
          >
            {abTestRate}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
});

const Devices: React.FC<{
  articleList: ArticleProps[];
  onCreateDevice: Function;
  onDestroyDevice: Function;
  abTestUid: string;
  isUpperPlan: boolean;
}> = ({ articleList, onCreateDevice, onDestroyDevice, abTestUid, isUpperPlan }) => {
  const { t } = useTranslation();
  if (articleList === null) {
    return null;
  }

  // FIXME
  // eslint-disable-next-line react/display-name
  const ArticleTitlesHeader: React.FC = memo(() => {
    return (
      <Box
        sx={{
          width: "230px",
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            px: 1.4,
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              flex: 3,
              fontSize: "12px",
            }}
          >
            {t("Version")}
          </Typography>
          <Typography
            sx={{
              color: "gray",
              fontSize: "12px",
              textAlign: "right",
            }}
          >
            {t("配信割合")}
          </Typography>
        </Box>
      </Box>
    );
  });

  // FIXME
  // eslint-disable-next-line react/display-name
  const ArticlesHeader: React.FC = memo(() => {
    const { t } = useTranslation();
    const deviceTitleStyle = {
      color: "primary.contrastText",
      fontSize: "12px",
      fontWeight: "600",
    };
    const iconStyle: {
      width: string;
      height: string;
      color: ColorList;
    } = {
      color: "white",
      width: "64px",
      height: "60px",
    };
    return (
      <Box
        sx={{
          flex: 1,
          pb: 1.4,
          pr: 2,
          bgcolor: "background.primary",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <Box
          sx={{
            color: "primary.contrastText",
            fontSize: "14px",
            fontWeight: "600px",
            pt: 2,
            pl: 2,
            pb: 1.6,
          }}
        >
          {t("デバイス別")}
        </Box>
        <Typography
          sx={{
            color: "gray",
            fontSize: "14px",
            pl: 2,
            pb: 2,
          }}
        >
          {t(
            "BranchOperationでの配信設定によっては、versionが配信されなくなってしまいます。設定時にはご注意ください。",
          )}
        </Typography>
        {!isUpperPlan && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "32px",
              pl: 2,
              mb: 1.4,
            }}
          >
            <Typography
              sx={{
                color: "primary.contrastText",
                fontSize: "14px",
                borderRadius: "10px",
              }}
            >
              {t("スタンダードプラン以上でご利用可能です。")}
            </Typography>
            <ContactButton
              category={"デバイス別出し分け"}
              body={"デバイス別出し分けの利用希望"}
            />
          </Box>
        )}
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              textAlign: "center",
            }}
          >
            <Box>
              <MobilePhoneIcon
                color={iconStyle.color}
                width={iconStyle.width}
                height={iconStyle.height}
              />
              <Typography sx={deviceTitleStyle}>{t("スマートフォン")}</Typography>
            </Box>
            <Box>
              <TabletIcon
                color={iconStyle.color}
                width={iconStyle.width}
                height={iconStyle.height}
              />
              <Typography sx={deviceTitleStyle}>{t("タブレット")}</Typography>
            </Box>
            <Box>
              <DesktopIcon
                color={iconStyle.color}
                width={iconStyle.width}
                height={iconStyle.height}
              />
              <Typography sx={deviceTitleStyle}>{t("デスクトップ")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  });

  const articleTitles = articleList.map((article: ArticleProps) => {
    return (
      <ArticleTitle
        key={article.id}
        title={article.memo}
        abTestRate={article.ab_test_article.rate}
      />
    );
  });

  const articles = articleList.map((article: ArticleProps) => {
    return (
      <Article
        key={article.id}
        article={article}
        isUpperPlan={isUpperPlan}
        onCreateDevice={onCreateDevice}
        onDestroyDevice={onDestroyDevice}
      />
    );
  });

  return (
    <Theme mode={"dark"}>
      <Box
        sx={{
          display: "flex",
          m: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex" }}>
            <ArticleTitlesHeader />
            <ArticlesHeader />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ width: "230px" }}>{articleTitles}</Box>
            <Box
              sx={{
                flex: 1,
                bgcolor: "background.primary",
                minHeight: "300px",
                borderRadius: "0px 0px 10px 10px",
              }}
            >
              {articles}
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "190px" }}>
          <BeyondPageArticleOptionList
            type={"device"}
            abTestUid={abTestUid}
          />
        </Box>
      </Box>
    </Theme>
  );
};

export const AbTestArticleSplitTestSettingDevicesContainer: React.FC<{
  onFetchAbTest: Function;
  abTest?: AbTestProps;
  abTestUid: string;
  articleList: ArticleProps[];
  onCreateDevice: Function;
  createDeviceError?: Error;
  onDestroyDevice: Function;
  destroyDeviceError?: Error;
  isUpperPlan: boolean;
}> = ({
  onFetchAbTest,
  abTest,
  abTestUid,
  articleList,
  onCreateDevice,
  createDeviceError,
  onDestroyDevice,
  destroyDeviceError,
  isUpperPlan,
}) => {
  const existsAbTest = abTest && Object.keys(abTest).length > 0;
  const saveAnimationRef = useRef<SaveAnimationHandler>(null);
  const { showAlerts } = useAlert();

  useEffect(() => {
    if (createDeviceError?.message) {
      showAlerts({ messages: [createDeviceError.message], theme: "danger" });
    }
  }, [createDeviceError]);

  useEffect(() => {
    if (destroyDeviceError?.message) {
      showAlerts({
        messages: [destroyDeviceError.message],
        theme: "danger",
      });
    }
  }, [destroyDeviceError]);

  useEffect(() => {
    onFetchAbTest(abTestUid);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <LocalNavigation
        pathName={location.pathname}
        abTestUid={abTest?.uid}
        folderUid={abTest?.folder?.uid}
      />
      <Box sx={{ width: 1 }}>
        <SaveAnimation ref={saveAnimationRef} />
        {existsAbTest && (
          <>
            <Nav
              abTest={abTest}
              folder={abTest.folder}
              page={"splitTestSetting"}
              article={null}
            />
            <Devices
              articleList={articleList}
              onCreateDevice={onCreateDevice}
              onDestroyDevice={onDestroyDevice}
              abTestUid={abTestUid}
              isUpperPlan={isUpperPlan}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
