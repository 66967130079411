import { useEffect, useState } from "react";

import toggleSwitchStyles from "stylesheets/components/partials/toggleSwitch.module.scss";
import { ToggleSwitchProps } from "./type";

export const ToggleSwitch = ({
  defaultState = false,
  checkedLabel,
  uncheckedLabel,
  willCheckEvent,
  willUncheckEvent,
  theme = "blue",
  type = "normal",
  disabled = false,
}: ToggleSwitchProps) => {
  const [checked, setChecked] = useState(defaultState);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setChecked(defaultState);
  }, [defaultState]);

  const handleClick = async () => {
    if (isProcessing || disabled) {
      return;
    }
    setIsProcessing(true);

    if (checked) {
      try {
        await willUncheckEvent();
        setChecked(false);
      } catch (error) {
        setChecked(true);
      } finally {
        setIsProcessing(false);
      }
      return;
    }

    try {
      await willCheckEvent();
      setChecked(true);
    } catch (error) {
      setChecked(false);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`${toggleSwitchStyles.toggleSwitchWrapper} ${toggleSwitchStyles[type]} ${
        toggleSwitchStyles[theme]
      } ${disabled ? toggleSwitchStyles.disabled : ""}`}
    >
      <div className={`${checked ? toggleSwitchStyles.checked : toggleSwitchStyles.unchecked}`}>
        <div className={toggleSwitchStyles.checkedLabel}>{checkedLabel}</div>
        <div className={toggleSwitchStyles.mark}>{checked ? checkedLabel : uncheckedLabel}</div>
        <div className={toggleSwitchStyles.uncheckedLabel}>{uncheckedLabel}</div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
