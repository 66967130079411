import React from "react";

type Props = {
  width: string;
  height: string;
};

export const DocomoIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-200.000000, -2171.000000)">
          <g transform="translate(200.000000, 2171.000000)">
            <rect
              fill="#333435"
              opacity="0"
              x="0"
              y="0"
              width="40"
              height="40"
            ></rect>
            <circle
              stroke="#CC0033"
              strokeWidth="7"
              cx="18"
              cy="26"
              r="10.5"
            ></circle>
            <path
              d="M25,0 L32,0 C32,16.6385193 32,27.9718526 32,34 C30.2336273,34 25.3182545,40 18,40 C19.3333333,38.3756307 21.6666667,35.708964 25,32 L25,0 Z"
              fill="#CC0033"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
