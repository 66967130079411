import { post } from "api";
import { DEFAULT_BASE_URL } from "const/app";

export type UserSignUpRequestParams = {
  user: {
    email: string;
    name: string;
    company_name: string;
    password: string;
  };
};

const create = async (params: UserSignUpRequestParams) => {
  const path = `${DEFAULT_BASE_URL}/users`;
  return post<UserSignUpRequestParams, boolean>(params, path);
};

export const UserRegistrationApi = {
  create,
} as const;
